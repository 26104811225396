import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertToDateObject_MMDDYYYYFormat,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import * as XLSX from "xlsx";

import { Tab, Table, Tabs } from "@mui/material";
import {
  Operation,
  StudentListFor,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";

import Home from "../../Home/Index";

import { useMutation } from "@apollo/client";
import FileAttach from "../../../../images/BrowseFiles.svg";

import useToken from "../../../../customhooks/useToken";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";

import LoadingModal from "../../../../pages/LoadingModal";
import { ImportUpdatedStudents } from "../../../../queries/students/mutations/update";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import Validate from "../../../../images/Generate.svg";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import BulkAlterExport from "./BulkAlterExport";
import { ExcelAdmQueryType } from "../../Reports/InstitutionLevel/useExportMutation";
import InstitutionLevel from "../../../Master/Reports/InstitutionLevel/Index"

const StudentListExcel = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { token } = useToken();
  const [value, setValue] = useState(0);
  const { InstId } = useParams();

  const [records, setRecords] = useState<any[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: EMPTY_STRING,
    flag: false,
    operation: Operation.NONE,
  });
  const { USE_CLASS_KEY, USE_SECTION_KEY } = useInstitutionConfiguration();
  const { user_details } = useLoggedInUserDetails();
  const [UpdateStudent, { loading: updationLoading }] = useMutation(
    ImportUpdatedStudents,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };
  const student_schema = {
    EduateId: {
      name: "eduate_id",
    },
    AdmissionNumber: {
      name: "adm_no",
    },
    RegisterNo: {
      name: "std_reg_no",
    },
    FirstName: {
      name: "first_name",
      required: true,
    },
    MiddleName: {
      name: "middle_name",
    },
    LastName: {
      name: "last_name",
    },
    Mobile: {
      name: "std_mobile",
    },
    Email: {
      name: "std_email",
    },
    Gender: {
      name: "std_sex",
    },
    FatherName: {
      name: "fathername",
    },
    MotherName: {
      name: "mothername",
    },

    Fresher: {
      name: "std_fresher",
    },
    DateOfBirth: {
      name: "std_dob",
    },
    Class: {
      name: "class",
      required: USE_CLASS_KEY,
    },
    Section: { name: "section", required: USE_SECTION_KEY },

    Aadhar: {
      name: "std_aadhar",
    },
    SATSNumber: {
      name: "std_sts_no",
    },
    BloodGroup: {
      name: "std_blood_group",
    },
    Address: {
      name: "std_address",
    },
    City: {
      name: "std_place",
    },
    District: {
      name: "std_district",
    },
    State: {
      name: "std_state",
    },
    PinCode: {
      name: "std_pincode",
    },
    Telno: {
      name: "std_phone",
    },
    CorrespondingAddress: {
      name: "std_corr_address",
    },
    CorrespondingCity: {
      name: "std_corr_place",
    },
    CorrDistrict: {
      name: "std_corr_district",
    },
    CorrState: {
      name: "std_corr_state",
    },
    CorrPinCode: {
      name: "std_corr_pincode",
    },
    CorrTelephone: {
      name: "std_corr_phone",
    },
    Religion: {
      name: "std_religion",
    },
    Caste: {
      name: "std_caste",
    },
    SubCaste: {
      name: "std_sub_caste",
    },
    CasteGroup: {
      name: "std_caste_group",
    },
    SCST: {
      name: "std_sc_st",
    },
    Minority: {
      name: "std_caste_minority",
    },
    Area: {
      name: "std_rural_urban",
    },
    MotherTongue: {
      name: "std_mother_tongue",
    },
    AnnualIncome: {
      name: "std_annual_income",
    },
    Married: {
      name: "std_martial_status",
    },
    IdentityMarks: {
      name: "std_identity_marks",
    },
    PlaceofBirth: {
      name: "std_place_of_birth",
    },
    Nationality: {
      name: "std_nationality",
    },
    PhysicalChallenged: {
      name: "std_is_phy_challenged",
    },
    NearestPoliceStation: {
      name: "std_nearest_police_station",
    },
    NearestRailwayStation: {
      name: "std_nearest_railway_station",
    },
    Kmsfromresidence: {
      name: "std_km_from_residence",
    },
    BelongstoKashmir: {
      name: "std_belongs_to_jk",
    },
    IsKashmirBrahmin: {
      name: "std_belongs_to_jk_brahmin",
    },
    PrevTCDetails: {
      name: "std_prev_tc_details",
    },
    PrevInstitution: {
      name: "std_prev_inst",
    },
    PrevQualification: {
      name: "std_prev_quali_exam",
    },
    PervStudiedBoard: {
      name: "std_prev_studied_board",
    },
    PrevStudiedState: {
      name: "std_prev_studied_state",
    },
    PrevStudySubjects: {
      name: "std_prev_subject_studied",
    },
    PrevTotalMarks: {
      name: "std_prev_total_marks",
    },
    PrevMarksPercentage: {
      name: "std_prev_total_per",
    },
    PrevTechnicalSubjects: {
      name: "std_prev_tech_subject_studied",
    },
    PrevTechnicalMarks: {
      name: "std_prev_tech_total_mark",
    },
    PrevTechnicalPercentage: {
      name: "std_prev_tech_total_per",
    },
    tenthMarks: {
      name: "std_prev_10_std_marks",
    },
    tenthPercentage: {
      name: "std_prev_10_std_per",
    },
    tenthLanguage: {
      name: "std_prev_10_lang_medium",
    },
    twelthMarks: {
      name: "std_prev_12_std_marks",
    },
    twelthPercentage: {
      name: "std_prev_12_std_per",
    },
    BankAccountNo: {
      name: "std_bank_acct",
    },
    BankName: {
      name: "std_bank_name",
    },
    IFSC: {
      name: "std_bank_ifsc",
    },
    BankBranch: {
      name: "std_bank_branch",
    },
    CETapplicationNo: {
      name: "std_adm_appl_no",
    },
    FREEorPaymentSeat: {
      name: "std_adm_free_pymnt_seat",
    },
    STATEorINTERSTATE: {
      name: "std_adm_state_or_outside",
    },
    SeatGotThrough: {
      name: "std_adm_seat_got_through",
    },
    CategoryThrough: {
      name: "std_adm_category",
    },
    CETno: {
      name: "std_adm_cet_no",
    },
    CETrank: {
      name: "std_adm_cet_rank",
    },
    AdmEligibiltyNo: {
      name: "std_adm_eli_no",
    },
    AdmEligibiltyDate: {
      name: "std_adm_eli_date",
    },
    CETPaidReceiptNo: {
      name: "std_adm_cet_paid_rcpt_no",
    },
    CETPaidReceiptDate: {
      name: "std_adm_cet_paid_rcpt_date",
    },
    CETPaidAmount: {
      name: "std_adm_cet_paid_amt",
    },
    CETAllotedCategory: {
      name: "std_adm_cet_alloted_category",
    },
  };
  const handleButtonClick = async () => {
    try {
      await Promise.all(records.map((record) => handleClick(record.id)));
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (id: number) => {
    const filteredData = records.find((record) => record.id === id);
    if (filteredData) {
      const newData = records.map((record, index) => {
        if (id === record.id) {
          return {
            ...record,
            isChecked: !record?.isChecked,
          };
        }
        return record;
      });
      setRecords(newData);
    }
  };
  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      student_schema &&
        // eslint-disable-next-line
        Object.keys(student_schema).map((key) => {
          // @ts-ignore
          let keySchema = student_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleAddStudent = async () => {
    for (const record of records.filter(
      (record) => !record.isValidatedAndError
    )) {
      try {
        await UpdateStudent({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            student_id: record.EduateId,
            input: {
              std_basic_details: {
                std_sex: record.Gender,
                std_email: record.Email,
                std_mobile: record.Mobile,
                std_father_name: record.FatherName,
                std_mother_name: record.MotherName,
                std_fresher:
                  record.Fresher === "YES"
                    ? true
                    : record.Fresher === "NO"
                    ? false
                    : record.Fresher === "Yes"
                    ? true
                    : record.Fresher === "No"
                    ? false
                    : record.Fresher === "yes"
                    ? true
                    : record.Fresher === "no"
                    ? false
                    : true,
                std_dob: toIsoDate(
                  convertToDateObject_MMDDYYYYFormat(record.DateOfBirth)
                ),
              },
              student_details: {
                std_blood_group: record.BloodGroup,
                std_aadhar: record.Aadhar,
                std_sts_no: record.SATSNumber,
                std_address: record.Address,
                std_place: record.City,
                std_district: record.District,
                std_state: record.State,
                std_phone: record.Telno,
                std_pincode: record.PinCode,
                std_corr_address: record.CorrespondingAddress,
                std_corr_place: record.CorrespondingCity,
                std_corr_district: record.CorrDistrict,
                std_corr_state: record.CorrState,
                std_corr_phone: record.CorrTelephone,
                std_corr_pincode: record.CorrPinCode,
                std_religion: record.Religion,
                std_caste: record.Caste,
                std_sub_caste: record.SubCaste,
                std_caste_group: record.CasteGroup,
                std_sc_st: record.SCST,
                std_caste_minority:
                  record.Minority === "Yes"
                    ? true
                    : record.Minority === "No"
                    ? false
                    : true,
                std_rural_urban: record.Area,
                std_mother_tongue: record.MotherTongue,
                std_annual_income: record.AnnualIncome,
                std_martial_status: record.Married,
                std_place_of_birth: record.PlaceofBirth,
                std_nationality: record.Nationality,
                std_is_phy_challenged:
                  record.PhysicalChallenged === "Yes"
                    ? true
                    : record.PhyChallenged === "No"
                    ? false
                    : true,
                std_nearest_police_station: record.NearestPoliceStation,
                std_nearest_railway_station: record.NearestRailwayStation,
                std_km_from_residence: record.Kmsfromresidence,
                std_belongs_to_jk:
                  record.BelongstoKashmir === "Yes"
                    ? true
                    : record.BelongsToJK === "No"
                    ? false
                    : true,
                std_belongs_to_jk_brahmin:
                  record.IsKashmirBrahmin === "Yes"
                    ? true
                    : record.BelongtoJKBrahmin === "No"
                    ? false
                    : true,
              },
              student_other_details: {
                std_prev_tc_details: record.PrevTCDetails,
                std_prev_inst: record.PrevInstitution,
                std_prev_quali_exam: record.PrevQualification,
                std_prev_studied_board: record.PervStudiedBoard,
                std_prev_studied_state: record.PrevStudiedState,
                std_prev_subject_studied: record.PrevStudySubjects,
                std_prev_total_marks: record.PrevTotalMarks,
                std_prev_total_per: record.PrevMarksPercentage,
                std_prev_tech_subject_studied: record.PrevTechnicalSubjects,
                std_prev_tech_total_mark: record.PrevTechnicalMarks,
                std_prev_tech_total_per: record.PrevTechnicalPercentage,
                std_prev_10_std_marks: record.tenthMarks,
                std_prev_10_std_per: record.tenthPercentage,
                std_prev_10_lang_medium: record.tenthLanguage,
                std_prev_12_std_marks: record.twelthMarks,
                std_prev_12_std_per: record.twelthPercentage,
                std_bank_name: record.BankName,
                std_bank_branch: record.BankBranch,
                std_bank_acct: record.BankAccountNo,
                std_bank_ifsc: record.IFSC,
                std_adm_appl_no: record.CETapplicationNo,
                std_adm_free_pymnt_seat: record.FREEorPaymentSeat,
                std_adm_state_or_outside: record.STATEorINTERSTATE,
                std_adm_seat_got_through: record.SeatGotThrough,
                std_adm_category: record.CategoryThrough,
                std_adm_cet_no: record.CETno,
                std_adm_cet_rank: record.CETrank,
                std_adm_eli_no: record.AdmEligibiltyNo,
                std_adm_eli_date: toIsoDate(record.AdmEligibiltyDate),
                std_adm_cet_paid_rcpt_no: record.CETPaidReceiptNo,
                std_adm_cet_paid_rcpt_date: toIsoDate(
                  record.CETPaidReceiptDate
                ),
                std_adm_cet_paid_amt: Number(record.CETPaidAmount),
                std_adm_cet_alloted_category: record.CETAllotedCategory,
              },
            },
          },
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Students Imported Successfully",
              operation: Operation.UPDATE,
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleClear = () => {
    setRecords([]);
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = "";
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);
          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index: number) => setValue(index);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Bulk Data Alter</Title>

      <div className="bulk-data-alter">
        <div className="bulk-data-alter__tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Import" value={0} {...a11yProps(0)} />
            <Tab label="Export" value={1} {...a11yProps(1)} />
          </Tabs>
        </div>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          className="bulk-data-alter__swipable">
          {/* values for Attendance */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div className="row g-0 bulk-data-alter__select">
              <div className="col-6">
                <div className="bulk-data-alter__file-upload">
                  <div className="bulk-data-alter__file-upload--file-name">
                    <span id="file-name"></span>
                  </div>
                  <div className="bulk-data-alter__file-upload--browse">
                    <label htmlFor="input">
                      <img src={FileAttach} alt="/" /> Choose Excel File
                    </label>
                    <input
                      id="input"
                      type="file"
                      name="file"
                      onChange={() => displayFileName()}
                      className="bulk-data-alter__file-upload--input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bulk-data-alter__tableblock">
              <TableContainer className="bulk-data-alter__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{TableHeaders.SLNO}</TableCell>
                      {student_schema &&
                        Object.keys(student_schema)?.map((key, index) => {
                          // @ts-ignore
                          return (
                            <TableCell key={index} id="td-center">
                              {key}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {records
                      .filter((record) => !record?.errors)
                      .map((record, index) => (
                        <TableRow key={index}>
                          <TableCell id="td-center">{index + 1}</TableCell>
                          {student_schema && // eslint-disable-next-line
                            Object.keys(student_schema).map((key, index) => {
                              // @ts-ignore
                              const fieldSchema = student_schema[key];

                              if (
                                fieldSchema.required ||
                                fieldSchema.required === undefined
                              )
                                return (
                                  <TableCell key={index}>
                                    {record[key]}
                                  </TableCell>
                                );
                            })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Button onClick={handleAddStudent} mode="excel">
              Import Students
            </Button>
            <Button onClick={handleButtonClick}>
              <img src={Validate} alt="/" />
              Validate
            </Button>

            <Button mode="back" onClick={() => navigate(-1)} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
           
        <InstitutionLevel
          reportType={ExcelAdmQueryType.BULK_ALTER_LIST}
          forModal={true}
        /> 
            {/* <BulkAlterExport reportType={ExcelAdmQueryType.BULK_ALTER_LIST} /> */}
          </TabPanel>
        </SwipeableViews>
      </div>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={updationLoading} />
    </>
  );
};

export default StudentListExcel;
