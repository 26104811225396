import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import Home from "../Home/Index";
import Assign from "./Assign";
import AssignImage from "../../../images/Assign.svg";
import { ViewDetails } from "../../../styles/ModalStyles";
import Modal from "react-modal";
import View from "./View";
import Close from "../../../images/Close.svg";
import { AcdSubjectType, TableHeaders } from "../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Title } from "../../../stories/Title/Title";
import useAcademicSubjects, {
  SubjectEdges,
} from "../hooks/useAcademicSubjects";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { payloadTypes } from "../../../context/reducer";
import { AppContext } from "../../../context/context";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useAcademicTableJson from "../json/useAcademicTable";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { AllocateTeachersDrawer } from "../../../styles/DrawerStyles";
import EyeWhite from "../../../images/EyeWhite.svg";
export enum TeachersAssociationPageFrom {
  INST_LEVEL = "INST_LEVEL",
  DAILY_ACTIVITIES = "DAILY_ACTIVITIES",
  SUBJECT_TO_TEACHERS = "SUBJECT_TO_TEACHERS",
  NONE = "NONE",
}
interface Props {
  pageFrom: TeachersAssociationPageFrom;
}
export interface SubjDrawerDetails {
  subj_code: string;
  subj_name: string;
  subj_id: number;
  pageFrom: TeachersAssociationPageFrom;
}
interface SubjectDetails {
  id: number;
  subj_code: string;
  subj_board_code: string;
  subj_desc: string;
  elective_or_nonElective: boolean;
  teachers_count: number;
  subject_status: string;
}
const Index = ({ pageFrom }: Props) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const { SubjectAllocationToTeachers } = useAcademicTableJson();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [subjectDetails, setSubjectDetails] = useState<SubjDrawerDetails>({
    subj_code: "",
    subj_id: 0,
    subj_name: "",
    pageFrom: TeachersAssociationPageFrom.NONE,
  });
  const [view, setView] = useState(false);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [subjects, setSubjects] = useState<SubjectEdges[]>([]);

  const [subjectsList, setSubjectsList] = useState<SubjectDetails[]>([]);

  const { subjects: SubjectsInstLevel } = useAcademicSubjects(
    0,
    0,
    0,
    EMPTY_STRING,
    ROWS_PER_PAGE,
    AcdSubjectType.GENERAL
  );

  const [assignDrawer, setAssignDrawer] = useState(false);

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  const dynamicHeaders: TableHeaderProps[] =
    SubjectAllocationToTeachers.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        return (
          <>
            {params.row.teachers_count > 0 ? (
              <button
                className="allocate-teacher__table--view"
                onClick={() => {
                  setSubjectDetails({
                    subj_code: params.row.sub_code,
                    subj_id: params.row.sub_id,
                    subj_name: params.row.sub_desc,
                    pageFrom: pageFrom,
                  });
                  setView(!view);
                  dispatch({
                    type: payloadTypes.SET_SUBJECT_ID,
                    payload: {
                      subjectId: params.row.sub_id!,
                    },
                  });
                }}
              >
                View <img src={EyeWhite} alt="" />
              </button>
            ) : (
              <button
                className="allocate-teacher__table--assign"
                onClick={() => {
                  setSubjectDetails({
                    subj_code: params.row.sub_code,
                    subj_id: params.row.sub_id,
                    subj_name: params.row.sub_desc,
                    pageFrom: pageFrom,
                  });
                  setAssignDrawer(!assignDrawer);
                  dispatch({
                    type: payloadTypes.SET_SUBJECT_ID,
                    payload: {
                      subjectId: params.row.sub_id!,
                    },
                  });
                }}
              >
                Assign
                <img src={AssignImage} alt="" />
              </button>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (SubjectsInstLevel.data && !SubjectsInstLevel.loading) {
      const newData = SubjectsInstLevel.data.GetAcdSubject.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: true, // set default value of isChecked to true
          },
        })
      );

      if (endCursor) {
        const updatedNewData = newData.map((newSubject) => {
          const filteredStudent = subjects.find(
            (subject) => subject.node.id === newSubject.node.id
          );
          if (filteredStudent) {
            return {
              ...newSubject,
              node: {
                ...newSubject.node,
              },
            };
          }
          return newSubject;
        });
        if (pageFrom === TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS) {
          if (updatedNewData) {
            setSubjectsList(
              updatedNewData.map(({ node }) => ({
                id: node.id!,
                subj_code: node.subj_code,
                subj_board_code: node.subj_board_code,
                subj_desc: node.subj_desc,
                elective_or_nonElective: node.subj_is_elective,
                teachers_count: node.teacher_count,
                subject_status: EMPTY_STRING,
              }))
            );
          }
        }
        setSubjects(updatedNewData);

        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            sub_code: node.subj_code,
            sub_desc: node.subj_desc,
            board_code: node.subj_board_code,
            teachers_count: node.teacher_count,
            sub_id: node.id,
          }))
        );
      } else {
        if (pageFrom === TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS) {
          if (newData) {
            setSubjectsList(
              newData.map(({ node }) => ({
                id: node.id!,
                subj_code: node.subj_code,
                subj_board_code: node.subj_board_code,
                subj_desc: node.subj_desc,
                elective_or_nonElective: node.subj_is_elective,
                teachers_count: node.teacher_count,
                subject_status: EMPTY_STRING,
              }))
            );
          }
        }
        setSubjects(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            sub_code: node.subj_code,
            sub_desc: node.subj_desc,
            board_code: node.subj_board_code,
            teachers_count: node.teacher_count,

            sub_id: node.id,
          }))
        );
      }

      setEndCursor(SubjectsInstLevel.data.GetAcdSubject.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [SubjectsInstLevel.data, SubjectsInstLevel.loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !SubjectsInstLevel.loading) {
          SubjectsInstLevel.fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdSubject.edges;
              const pageInfo = fetchMoreResult.GetAcdSubject.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdSubject.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdSubject: {
                  edges: [...subjects, ...newEdges],
                  pageInfo,
                  totalCount: SubjectsInstLevel.data
                    ? SubjectsInstLevel.data.GetAcdSubject.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      <Home DashBoardRequired={false} />
      {pageFrom === TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS ? (
        <Title>Subject Allocation to Teachers</Title>
      ) : null}
      <div className="allocate-teacher">
        {pageFrom !== TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS ? (
          <div className="att-for-attendance__filters row g-0">
            {USE_DEPARTMENT_KEY ? (
              <div className="col-2">
                <TextField
                  label={departmentLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className="att-for-attendance__filters--textfield"
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col-2">
                <TextField
                  label={branchLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className="att-for-attendance__filters--textfield"
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col-2">
                <TextField
                  label={classLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className="att-for-attendance__filters--textfield"
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col-2">
                <TextField
                  label={semesterLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className="att-for-attendance__filters--textfield"
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col-2">
                <TextField
                  label={sectionLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className="att-for-attendance__filters--textfield"
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div
          className={
            pageFrom !== TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS
              ? `allocate-teacher__tableblock `
              : `allocate-teacher__tableblock--without-filters `
          }
        >
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <AllocateTeachersDrawer
        anchor="right"
        open={assignDrawer}
        onClose={() => setAssignDrawer(!assignDrawer)}
      >
        <Assign setModal={setAssignDrawer} subjectDetails={subjectDetails} />
      </AllocateTeachersDrawer>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={view}
        style={ViewDetails}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View setModal={setView} subjectDetails={subjectDetails} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setView(!view)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
