//React
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

//Apollo Client
import { useMutation } from "@apollo/client";

//Material Ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

//HTML Hocs
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";

//Styles
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../styles/ModalStyles";

//Images
import closeImg from "../../../images/Close.svg";
import Settings from "../../../images/Settings.svg";

//Custom Hooks
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import useBookEntryOptions from "../customHooks/useBookEntryOptions";
import useToken from "../../../customhooks/useToken";

//Components
import Home from "../Home/Index";

//Modals
import LoadingModal from "../../../pages/LoadingModal";

//Enums and Types and Constants
import { Keys } from "../../../utils/Enum.keys";
import { LibraryTitles } from "../../../Types/Titles";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import {
  PageNumbers,
  BooksReportType,
  UserType,
  Operation,
  Direction,
  SortBy,
  LibBookMasterQueryType,
  LibraryConfigKey,
} from "../../../utils/Enum.types";

//Util Functions
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../utils/UtilFunctions";
import MessageModal from "../../../pages/MessageModal";
import { UpdateLibBooksAccessionNumber } from "../../../queries/Library/MasterData/AccessNo/mutations/update";
import { msgType } from "../../../utils/Form.types";
import { GetLibBookMaster } from "../../../queries/Library/BookEntry/list/byInstId";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../json/useLibraryTableJson";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { BookMasterEdge } from "../../../Types/Library/BookEntry/paginationTypes";
//Jsons
const { Library } = require("../../../json/title.json");

//Interface Types

interface ItemsType {
  id: number;
  book_access_no: string;
  book_title: string;
  publication: string;
  authors: string;
  subjects: string;
  visited: boolean;
}

const EditAccessionNo = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { Library_Table } = useLibraryTableJson();
  const { user_details } = useLoggedInUserDetails();
  const [searchBook, setSearchBook] = useState("");
  const [AccessionNumber, setAccessionNumber] = useState("");
  const [books, setBooks] = useState<BookMasterEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [saveModal, setSaveModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState(false);
  const [items, setItems] = useState<ItemsType[]>([]);
  const [EditMode, setEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const saveRef = useRef<HTMLButtonElement>(null);
  const { configData: accessionNoEdit } = useSwConfigData(
    LibraryConfigKey.LIB_BOOKS_ACCESSNO_EDITABLE
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [UpdateAccessionNo, { loading: updationLoading }] = useMutation(
    UpdateLibBooksAccessionNumber,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.ALLOT_STUDENT_REGISTER_NO
  );

  const {
    bookEntries: {
      queryData: { data: Books, loading: BooksLoading, fetchMore },
    },
  } = useBookEntryOptions(
    searchBook,
    ROWS_PER_PAGE,
    BooksReportType.GENERAL,
    UserType.USER
  );
  const totalCount = Books ? Books.GetLibBookMaster.totalCount : 0;

  const clear = () => {
    setSearchBook("");
  };

  const onEdit = (item: ItemsType) => {
    setEditMode({
      status: true,
      rowKey: item.id,
    });
    if (item.book_access_no) {
      setAccessionNumber(item.book_access_no);
    }
  };

  useEffect(() => {
    if (Books && !BooksLoading) {
      const data = Books?.GetLibBookMaster?.edges?.map(({ node }) => {
        return {
          id: node.id,
          book_access_no: node.book_access_no,
          book_title: node.book_title,
          publication: node.classification.publisher.publisher_name,
          authors: node.classification.author.author_name,
          subjects: node.classification.subject.subject_desc,
          visited: false,
        };
      });
      setItems(data);
    }
  }, [Books, BooksLoading]);

  const HandleSubmit = (type: string) => {
    if (accessionNoEdit.data?.GetSwConfigVariables[0].config_boolean_value) {
      const accession_data = items?.map((node) => ({
        lib_book_master_id: node.id,
        book_access_no: node.book_access_no,
      }));
      UpdateAccessionNo({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          accession_data,
        },
        refetchQueries: [
          {
            query: GetLibBookMaster,
            variables: {
              token,
              input: {
                book_query_type: LibBookMasterQueryType.BOOK_BY_INST_ID,
                ids: [Number(InstId)],
              },
              first: ROWS_PER_PAGE,
              sortBy: SortBy.ACCESS_NO,
              direction: Direction.ASC,
              access_no: searchBook,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (type === "nextPage") {
            setSaveModal(!saveModal);
          } else
            setMessage({
              message: "Sucessfully Updated AccessionNumber",
              flag: true,
              operation: Operation.UPDATE,
            });
        }
      });
    } else {
      setMessage({
        flag: true,
        message: "Please Enable Configuration Before Editing Accession Number",
        operation: Operation.NONE,
      });
    }
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.AccesionNumberEdit.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        if (header.field === "book_access_no") {
          const index = params.row.id - 1;

          return (
            <>
              {(EditMode.status && EditMode.rowKey === params.row.nodeId) ||
              (index === 0 && EditMode.status === false) ? (
                <input
                  type="text"
                  value={AccessionNumber}
                  autoFocus={
                    (EditMode.status &&
                      EditMode.rowKey === params.row.nodeId) ||
                    index === 0
                  }
                  onFocus={() => {
                    if (index === 0) {
                      onEdit(params.row.node);
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    items[index].book_access_no = e.target.value;
                    items[index].visited = true;
                    setItems(items);
                    setAccessionNumber(e.target.value);
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      items[index].book_access_no = AccessionNumber;
                      items[index].visited = true;
                      setItems(items);

                      if (index !== items.length - 1) {
                        onEdit(items[index + 1]);
                      }
                      if (books.length - 1 === index) {
                        saveRef?.current?.focus();
                      }
                    }
                  }}
                />
              ) : (
                <>{params.row.book_access_no}</>
              )}
            </>
          );
        }
        return params.value;
      },
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !BooksLoading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetLibBookMaster?.edges;
                const pageInfo = fetchMoreResult.GetLibBookMaster.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetLibBookMaster.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetLibBookMaster: {
                    edges: [...books, ...newEdges],
                    pageInfo,
                    totalCount: Books ? Books.GetLibBookMaster.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && items.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [items]
  );

  useEffect(() => {
    if (Books && !BooksLoading) {
      const newData = Books.GetLibBookMaster?.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = books.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setBooks(updatedNewData);
      } else {
        setBooks(newData);
      }
      setEndCursor(Books.GetLibBookMaster?.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [Books, BooksLoading]);
  const tableData = items?.map((node, index) => ({
    id: index + 1,
    book_access_no: node.book_access_no,
    book_title: node.book_title,
    publication: node.publication,
    authors: node.authors,
    subjects: node.subjects,
    node: node,
    nodeId: node.id,
  }));
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>
            {Library.Titles.AccessionNumber.map(
              (title: LibraryTitles, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.EditAccessionNumber}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <form>
        <div className="row g-0">
          <div className="col-2 edit-accno__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchBook(e.target.value);
              }}
              value={searchBook}
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col"></div>

          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className="container__list--addnew"
            />
          </div>
        </div>
      </form>

      <div className="edit-accno__tableblock">
        <StyledDatagrid
          columns={columns}
          rows={tableData}
          rowHeight={TABLE_ROW_HEIGHT}
          hideFooter
          onCellClick={(params) => {
            if (params.field === "book_access_no") {
              onEdit(params.row.node);
            }
          }}
        />
      </div>
      <div className="row g-0">
        <div className="col">
          <div className="button-left">
            {items.length > 0 && (
              <>
                <Button
                  mode="save"
                  buttonref={saveRef!}
                  onClick={HandleSubmit}
                />
                <Button mode="clear" />
              </>
            )}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
        <div className="col student-total-count flex-end">
          Total Books : &nbsp;<b>{totalCount}</b>
        </div>
      </div>

      <LoadingModal flag={updationLoading} />
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={saveModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <Label>
          Navigating to next page will save the current Register Numbers
        </Label>
        <div>
          <Button mode="yes" onClick={() => HandleSubmit("nextPage")} />
          <Button mode="cancel" onClick={() => setSaveModal(false)} />
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {/* <ConfigurationSettings
              pageNumber={PageNumbers.ALLOT_STUDENT_REGISTER_NO}
            /> */}
          </div>
          <div className="modal-flex__image">
            <img
              src={closeImg}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default EditAccessionNo;
