import { useLazyQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../context/context";
import { StudentDetails } from "../../../customhooks/useStudentDatabyId";
import useToken from "../../../customhooks/useToken";
import { AcctVoucherAnonymousAdjustmentDetailsByNode } from "../../../queries/common";
import { Title } from "../../../stories/Title/Title";
import { nodevars } from "../../../Types/Accounting";
import { formatter, toStandardDate } from "../../../utils/UtilFunctions";
import { Button } from "../../../stories/Button/Button";
import { GetAcctVoucherAnonymousNodeDetails } from "../hooks/useAcctVoucherDetailsByDates";
import { DebitOrCredit } from "../../../utils/Enum.types";
interface d {
  adjustment_details: {
    v_no: string;
    adjusted_amt: number;
    student_details: StudentDetails;
    voucher_master_details: {
      v_date: string;
    };
  }[];
}
interface ff {
  nodes: d[];
}
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: GetAcctVoucherAnonymousNodeDetails | null;
}
const SplitReceiptDetails = ({ setModal, selectedRow }: Props) => {
  const { format } = formatter;
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [GetVoucherDetailsByVoucherId, { data }] = useLazyQuery<ff, nodevars>(
    AcctVoucherAnonymousAdjustmentDetailsByNode
  );
  useEffect(() => {
    if (state.unkownVoucherAdjustmentDetailsId) {
      GetVoucherDetailsByVoucherId({
        variables: { token, ids: [state.unkownVoucherAdjustmentDetailsId] },
      });
    }
  }, [
    state.unkownVoucherAdjustmentDetailsId,
    GetVoucherDetailsByVoucherId,
    token,
  ]);
  return (
    <>
      <Title>Social welfare / Agent Adjustment Details</Title>
      <div className="unknown-bank-trans__receiptDetails">
        <div className="unknown-bank-trans__receiptDetails--tableblock">
          <TableContainer className="unknown-bank-trans__receiptDetails--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sl</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Receipt No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Received Amount</TableCell>
                  <TableCell>Adjusted Amount</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {selectedRow ? (
                  <TableRow>
                    <TableCell className="unknown-bank-trans__table--date">
                      1
                    </TableCell>
                    <TableCell className="unknown-bank-trans__table--date">
                      {toStandardDate(
                        selectedRow.voucher_master_details.v_date
                      )}
                    </TableCell>
                    <TableCell className="unknown-bank-trans__table--receipt-no">
                      {selectedRow.voucher_master_details.v_no}
                    </TableCell>
                    <TableCell>
                      {`${
                        selectedRow.voucher_master_details.acct_voucher_details.find(
                          (d) => d.vo_cr_db === DebitOrCredit.DEBIT
                        )?.acct_ldgr.ldgr_desc
                      } (${
                        selectedRow.voucher_master_details.v_transcation_type
                      }-${selectedRow.voucher_master_details.v_no})`}
                    </TableCell>

                    <TableCell
                      className="unknown-bank-trans__table--amount font-green"
                      id="td-right"
                    >
                      {format(selectedRow.received_amt)}
                    </TableCell>
                    <TableCell
                      className="unknown-bank-trans__table--amount font-green"
                      id="td-right"
                    ></TableCell>
                  </TableRow>
                ) : null}
                {data?.nodes[0].adjustment_details.map((d, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        id="td-center"
                        className="unknown-bank-trans__receiptDetails--table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="unknown-bank-trans__receiptDetails--table--receipt-no">
                        {toStandardDate(d.voucher_master_details.v_date)}
                      </TableCell>
                      <TableCell className="unknown-bank-trans__receiptDetails--table--receipt-no">
                        {d.v_no}
                      </TableCell>
                      <TableCell>{`${
                        d.student_details.first_name +
                        " " +
                        d.student_details.middle_name +
                        " " +
                        d.student_details.last_name
                      }`}</TableCell>
                      <TableCell
                        className="unknown-bank-trans__receiptDetails--table--amount"
                        id="td-right"
                      ></TableCell>
                      <TableCell
                        className="unknown-bank-trans__receiptDetails--table--amount"
                        id="td-right"
                      >
                        {format(d.adjusted_amt)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {selectedRow ? (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={4}></TableCell>
                    <TableCell
                      className="unknown-bank-trans__receiptDetails--table--amount totalcount"
                      id="td-right"
                    >
                      {format(selectedRow.received_amt)}
                    </TableCell>
                    <TableCell
                      className="unknown-bank-trans__receiptDetails--table--amount totalcount"
                      id="td-right"
                    >
                      {format(selectedRow.adjusted_amt)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="unknown-bank-trans__receiptDetails--table--amount total"
                      id="td-right"
                      colSpan={5}
                    >
                      Balance Amount
                    </TableCell>
                    <TableCell
                      className="unknown-bank-trans__receiptDetails--table--amount balance-count"
                      id="td-right"
                    >
                      {format(selectedRow.bal_amt)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              ) : null}
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default SplitReceiptDetails;
