import React, { ReactNode, useEffect, useRef, useState } from "react";
import Home from "../Home/Index";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { FineSlabTitleProps } from "../../../Types/Titles";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Link from "../../../images/Link.svg";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { TableHeaderProps } from "../../../Types/Tables";

import { Operation, ReturnType, UserType } from "../../../utils/Enum.types";
import useFineSlabOptions from "../customHooks/useFineSlabOptions";
import { toStandardCase } from "../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import {
  FineAssociation,
  msgType,
  responseType,
} from "../../../utils/Form.types";
import { Keys } from "../../../utils/Enum.keys";
import MessageModal from "../../../pages/MessageModal";
import { AssociateLibFine } from "../../../queries/Library/FineSlab/mutation/new";
import { ByNode as FineSlabById } from "../../../queries/Library/Node";
import { fineSlabType } from "../../../Types/Library/Types";
import { GetLibFineByInstId } from "../../../queries/Library/FineSlab/list/byInstId";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import {
  EMPTY_STRING,
  MULTI_SELECT_LIMIT_TAGS,
} from "../../../utils/constants";
import Pointer from "../../../images/Pointer.svg";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../json/useLibraryTableJson";
import {
  formClasses,
  RequiredAutoComplete,
} from "../../../styles/AutocompleteStyles";

const { Library } = require("../../../json/title.json");
const formLabels = () => {
  return [...new Array(5).keys()].map((i) => {
    return {
      label1: "From Date",
      label2: "To date",
      label3: "Amount Per Day",
      id: (Math.random() + 1).toString(),
    };
  });
};
const arr = [...formLabels()];
const Association = () => {
  const { Library_Table } = useLibraryTableJson();
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [fineSlabArray, setFineSlabArray] = useState<fineSlabType[]>([]);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [fineId, setFineId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [formData, setFormData] = useState<FineAssociation>({
    fineId: 0,
    fineApplyFor: [],
  });

  //useRefs
  const fineNameRef = useRef<HTMLSelectElement>(null);
  const fineInputNameRef = fineNameRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const fineApplyForRef = useRef<HTMLSelectElement>(null);
  const fineApplyForInputRef = fineApplyForRef?.current?.childNodes[0]
    ?.childNodes[0]?.childNodes[0] as HTMLInputElement;
  const {
    fines: {
      finesDropdown,
      queryData: { data, error },
    },
  } = useFineSlabOptions(ReturnType.BOTH);

  const tableList = data?.GetLibFineByInstId?.filter(
    (label) => label.lib_fine_for_staff || label.lib_fine_for_student
  );

  //Queries
  const [GetFineSlabById, { data: FineSlabData, loading: FineSlabLoading }] =
    useLazyQuery(FineSlabById, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  //Mutations
  const [AssociateFine, { loading: associationLoading }] = useMutation(
    AssociateLibFine,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //To filter options when associated
  const createFineOptionsList = finesDropdown?.filter(
    (data) => !tableList?.map((label) => label.id).includes(Number(data.value))
  );
  const HandleSubmit = () => {
    if (operation === Operation.CREATE) {
      AssociateFine({
        variables: {
          token,
          id: formData.fineId,
          inst_id: InstId,
          user_details,
          for_student: formData?.fineApplyFor.includes(
            toStandardCase(UserType.STUDENT)
          )
            ? true
            : false,
          for_staff: formData?.fineApplyFor.includes(
            toStandardCase(UserType.EMPLOYEE)
          )
            ? true
            : false,
        },
        refetchQueries: [
          {
            query: GetLibFineByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Fine Association Added successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (operation === Operation.UPDATE) {
      AssociateFine({
        variables: {
          token,
          id: fineId,
          inst_id: InstId,
          user_details,
          for_student: formData?.fineApplyFor.includes(
            toStandardCase(UserType.STUDENT)
          )
            ? true
            : false,
          for_staff: formData?.fineApplyFor.includes(
            toStandardCase(UserType.EMPLOYEE)
          )
            ? true
            : false,
        },
        refetchQueries: [
          {
            query: GetLibFineByInstId,
            variables: {
              token,
              inst_id: InstId!,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Fine Association Updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    AssociateFine({
      variables: {
        token,
        id: fineId,
        inst_id: InstId,
        user_details,
        for_student: false,
        for_staff: false,
      },
      refetchQueries: [
        {
          query: GetLibFineByInstId,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Fine Association Deleted successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleClear = () => {
    setFormData({
      fineId: 0,
      fineApplyFor: [],
    });
    setFineId(0);
    setFineSlabArray([]);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setOperation(Operation.CREATE);
      //enableAdd and enableEdit should stay false when you close the success modal
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(
    () => {
      if (FineSlabData && !FineSlabLoading) {
        const {
          id,
          lib_slab1_from,
          lib_slab1_to,
          lib_slab1_fine,
          lib_fine_for_staff,
          lib_fine_for_student,
          lib_slab2_from,
          lib_slab2_to,
          lib_slab2_fine,
          lib_slab3_from,
          lib_slab3_to,
          lib_slab3_fine,
          lib_slab4_from,
          lib_slab4_to,
          lib_slab4_fine,
          lib_slab5_from,
          lib_slab5_to,
          lib_slab5_fine,
        } = FineSlabData.nodes[0] || "";
        const item1 = {
          fromDay: lib_slab1_from,
          toDay: lib_slab1_to,
          amtPerDay: lib_slab1_fine,
        };
        fineSlabArray[0] = item1;
        const item2 = {
          fromDay: lib_slab2_from,
          toDay: lib_slab2_to,
          amtPerDay: lib_slab2_fine,
        };
        fineSlabArray[1] = item2;
        const item3 = {
          fromDay: lib_slab3_from,
          toDay: lib_slab3_to,
          amtPerDay: lib_slab3_fine,
        };
        fineSlabArray[2] = item3;
        const item4 = {
          fromDay: lib_slab4_from,
          toDay: lib_slab4_to,
          amtPerDay: lib_slab4_fine,
        };
        fineSlabArray[3] = item4;
        const item5 = {
          fromDay: lib_slab5_from,
          toDay: lib_slab5_to,
          amtPerDay: lib_slab5_fine,
        };
        fineSlabArray[4] = item5;
        setFineSlabArray([...fineSlabArray]);
        if (operation === Operation.UPDATE) {
          let users = [];
          if (lib_fine_for_staff) {
            users.push(UserType.EMPLOYEE);
          }
          if (lib_fine_for_student) {
            users.push(UserType.STUDENT);
          }
          setFormData({
            fineId: id,
            fineApplyFor: users,
          });
        }
      }
    },
    // eslint-disable-next-line
    [FineSlabData, FineSlabLoading, operation]
  );
  useEffect(() => {
    if (operation === Operation.CREATE) {
      fineInputNameRef?.focus();
    }
  }, [operation, fineInputNameRef]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Library.FineSlab.FineAssociation.Association.map(
          (title: FineSlabTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {operation === Operation.CREATE
                  ? title.CREATE
                  : operation === Operation.UPDATE && formData.fineId
                  ? title.UPDATE
                  : title.GENERAL}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="fine-assoc">
        <div className="row g-0 fine-assoc__details">
          <div className="col label-grid">
            <Label>Fine Name</Label>
            <RequiredAutoComplete
              className={formClasses.inputRoot}
              options={
                operation === Operation.CREATE
                  ? createFineOptionsList
                  : finesDropdown
              }
              isOptionEqualToValue={(option) =>
                Number((option as responseType)?.value) === formData.fineId
              }
              openOnFocus
              ref={fineNameRef!}
              forcePopupIcon
              value={
                finesDropdown?.find(({ value }) => value === formData.fineId) ??
                null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setFormData((prevValues) => ({
                    ...prevValues,
                    fineId: Number((newValue as responseType)?.value)!,
                  }));
                  setOperation(Operation.CREATE);
                  setFineId((newValue as responseType)?.value);
                  GetFineSlabById({
                    variables: {
                      token,
                      ids: [(newValue as responseType)?.value],
                    },
                  });
                } else {
                  setFormData((prevValues) => ({
                    ...prevValues,
                    fineId: 0,
                  }));
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && formData.fineId > 0) {
                  fineApplyForInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setFormData((prevValues) => ({
                    ...prevValues,
                    fineId: 0,
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-1">
            <img src={Link} alt="/" className="fine-assoc__link-image" />
          </div>
          <div className="col fine-assoc__details--multiselect label-grid">
            <Label>Fine Apply for</Label>
            <RequiredAutoComplete
              className={formClasses.inputRoot}
              limitTags={MULTI_SELECT_LIMIT_TAGS}
              id="multiple-limit-tags"
              options={Object.values(UserType)
                .filter(
                  (label) =>
                    label !== UserType.USER &&
                    label !== UserType.EDUATE &&
                    label !== UserType.PARENT
                )
                ?.map((label) => toStandardCase(label))}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={
                        formData.fineApplyFor?.find((label) => label === option)
                          ? true
                          : false
                      }
                    />
                    {(option as ReactNode)}
                  </li>
                );
              }}
              disableCloseOnSelect
              openOnFocus
              multiple
              forcePopupIcon
              onChange={(e, newValue) => {
                setFormData((prevValues) => ({
                  ...prevValues,
                  fineApplyFor: newValue ? [...(newValue as string[])] : [],
                }));
              }}
              value={formData.fineApplyFor}
              ref={fineApplyForRef!}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className="row g-0 fine-assoc__tableblock">
          <div className="col h-100 booktype-left">
            <Title variant="subtitle1">
              {Library.FineSlab.FineAssociation.AssociatedFine}
            </Title>

            {error ? (
              error.message
            ) : tableList && tableList?.length > 0 ? (
              <TableContainer className="fine-assoc__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Library_Table.FineSlab.Association.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableList?.map((row, index: number) => {
                      return (
                        <TableRow
                          key={index}
                          className={row.id === fineId ? "selected-row" : ""}
                        >
                          <TableCell
                            id={row.id === fineId ? "" : "td-center"}
                            className="fine-slab__frame--table--slno"
                          >
                            {row.id === fineId && (
                              <img
                                src={Pointer}
                                alt="/"
                                className="pointer-icon"
                              />
                            )}
                            {index + 1}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              setFormData({
                                fineId: 0,
                                fineApplyFor: [],
                              });
                              setFineId(row.id);
                              setOperation(Operation.CREATE);
                              GetFineSlabById({
                                variables: {
                                  token,
                                  ids: [row.id],
                                },
                              });
                            }}
                          >
                            {row.lib_fine_desc}
                          </TableCell>
                          <TableCell
                            className="fine-slab__frame--table--desc"
                            // onClick={() => {
                            //   setFormData({
                            //     fineId: 0,
                            //     fineApplyFor: [],
                            //   });
                            //   setOperation(Operation.CREATE);
                            //   GetFineSlabById({
                            //     variables: {
                            //       token,
                            //       ids: [row.id],
                            //     },
                            //   });
                            // }}
                          >
                            {row.lib_fine_for_staff
                              ? toStandardCase(UserType.EMPLOYEE) + " "
                              : ""}

                            {row.lib_fine_for_student
                              ? toStandardCase(UserType.STUDENT)
                              : ""}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="fine-slab__frame--table--actions"
                          >
                            <img
                              src={EditProfile}
                              alt="/"
                              onClick={() => {
                                setOperation(Operation.UPDATE);
                                setFineId(row.id);
                                GetFineSlabById({
                                  variables: {
                                    token,
                                    ids: [row.id],
                                  },
                                });
                                fineApplyForInputRef?.focus();
                              }}
                            />
                            &nbsp;
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => {
                                setFineId(row.id);
                                setDeleteModal(!deleteModal);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <b className="Sorry, no results"></b>
            )}
          </div>
          <div className="col h-100 booktype-right">
            <Title variant="subtitle1">
              {Library.FineSlab.FineAssociation.FineList}
            </Title>
            {FineSlabData && fineId ? (
              <div className="fine-assoc__fine-list">
                {arr.map((row, index) => (
                  <React.Fragment key={index}>
                    <div className="fine-slab__formlabels">
                      <div className="fine-slab__formlabels--columns">
                        <Label>{index + 1})</Label>
                      </div>
                      <div className="fine-slab__formlabels--columns">
                        <Label>{row.label1}</Label>
                        <Input
                          type="number"
                          name={"formDay" + index}
                          value={fineSlabArray[index]?.fromDay ?? EMPTY_STRING}
                          disabled
                        />
                      </div>
                      <div className="fine-slab__formlabels--columns">
                        <Label>{row.label2}</Label>
                        <Input
                          name={"toDay" + index}
                          type="number"
                          value={fineSlabArray[index]?.toDay ?? EMPTY_STRING}
                          disabled
                        />
                      </div>
                      <div>
                        <Label>{row.label3}</Label>
                        <Input
                          name={"amtPerDay" + index}
                          type="number"
                          value={
                            fineSlabArray[index]?.amtPerDay ?? EMPTY_STRING
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <b>Select a fine</b>
            )}
          </div>
        </div>
      </div>
      <div className="button-left">
        {operation ? (
          <>
            <Button mode="save" type="submit" onClick={HandleSubmit} />
            <Button mode="clear" onClick={handleClear} />
            <Button mode="back" onClick={() => navigate(-1)} />
          </>
        ) : (
          <Button
            mode="addnew"
            autoFocus
            onClick={() => setOperation(Operation.CREATE)}
          />
        )}
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={associationLoading} />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={fineId}
      />
    </>
  );
};

export default Association;
