import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";

import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { userDetails } from "../../../../Types/Accounting/other";
import { useNavigate } from "react-router-dom";
import FileUpload from "./FileUpload";
import {
  FileUploadParams,
  InstitutionConfigurationTypes,
} from "../../../../utils/Enum.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";

interface Result {
  AddMstInstLogo: {
    id: number;
  };
}
interface variables {
  token: string;
  inst_id: number | string;
  user_details: userDetails;
  file_type: string;
  file_name: string;
}

const InstLogoUpload = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_BRANCH_LEVEL_LOGOS
  );
  const isBranchLogoEnabled =
    configData.data &&
    configData.data.GetSwConfigVariables.length &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Institution Logo</Title>
      <div className="inst-logo">
        <div className="inst-logo__tabs">
          <Tabs value={value} onChange={handleTabChange}>
            <Tab label="Inst Logo" value={0} {...a11yProps(0)} />
            <Tab label="Chairman's Signature" value={1} {...a11yProps(1)} />
            <Tab label="Principal's Signature" value={2} {...a11yProps(2)} />
            <Tab label="Photo 1" value={3} {...a11yProps(3)} />
            <Tab label="Photo 2" value={4} {...a11yProps(4)} />
            <Tab label="Photo 3" value={5} {...a11yProps(5)} />
            {isBranchLogoEnabled && (
              <Tab label="Branch Logo" value={6} {...a11yProps(6)} />
            )}
          </Tabs>
        </div>
        <div className="inst-logo__tabpanel">
          <TabPanel value={value} index={0} dir={theme.direction}>
            <FileUpload file_type={FileUploadParams.INST_LOGO} />
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <FileUpload file_type={FileUploadParams.CHAIRMAN_SIGNATURE} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <FileUpload file_type={FileUploadParams.PRINCIPAL_SIGNATURE} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <FileUpload file_type={FileUploadParams.PHOTO_1} />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <FileUpload file_type={FileUploadParams.PHOTO_2} />
          </TabPanel>
          <TabPanel value={value} index={5} dir={theme.direction}>
            <FileUpload file_type={FileUploadParams.PHOTO_3} />
          </TabPanel>
          {isBranchLogoEnabled && (
            <TabPanel value={value} index={6} dir={theme.direction}>
              <FileUpload file_type={FileUploadParams.BRANCH_LOGO} />
            </TabPanel>
          )}
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default InstLogoUpload;
