import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";

import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import { NameOfTheDay } from "../../../../utils/UtilFunctions";
import Home from "../../Home/Index";
import Avatar from "../../../../images/Avatar.svg";
import { Button } from "../../../../stories/Button/Button";
import {
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
  UserRightsModalStyles,
} from "../../../../styles/ModalStyles";
import {
  DocumentAcknowledgement,
  InstitutionConfigurationTypes,
  InstitutionType,
  Operation,
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import StudentList from "../List";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";

import { useNavigate, useParams } from "react-router-dom";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../../context/context";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, responseType } from "../../../../utils/Form.types";

import { payloadTypes } from "../../../../context/reducer";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { userDetails } from "../../../../Types/Accounting/other";
import AddDocType from "../../Components/Modals/AddDocType";
import DocsUploadComponent from "../Registration/DocsUploadComponent";
import StudentSelectAutocomplete from "../../../Components/StudentSelectAutocomplete";
import useToken from "../../../../customhooks/useToken";
import { useLazyQuery } from "@apollo/client";
import {
  GetInstDocumentsData,
  GetInstDocumentVars,
} from "../../../Print/Documents/Types";
import { GetInstDocuments } from "../../../Print/Documents/queries";
import { GetStudentDocsByStudentId } from "../../../../queries/students/list/byId";
import PrintDocuments from "../../../Print/Documents/Print";
import Settings from "../../../../images/Settings.svg";
import Configurations from "../../../Print/Documents/Configurations";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useSwConfigInstType from "../../../Academics/hooks/useSwConfigInstType";

export enum imageFileType {
  png = "png",
  jpg = "jpg",
  jpeg = "jpeg",
}
interface GetStudentDocsByStudentIdDetails {
  id: number;
  std_doc_type: string;
  std_doc_filename: string;
  std_doc_download: boolean;
  std_doc_collected: boolean;
  std_doc_original: boolean;
  student_id: number;
  date_of_collection: string;
  date_of_return: string;
  is_doc_returned: boolean;
  std_doc_remarks: string;
}
interface GetEmpDocsDetails {
  id: number;
  emp_doc_type: string;
  emp_doc_filename: string;
  emp_doc_download: boolean;
  emp_doc_collected: boolean;
  emp_doc_original: boolean;
  date_of_collection: string;
  date_of_return: string;
  is_doc_returned: boolean;
  pr_emp_id: number;
  inst_id: number;
}
export interface GetStudentDocsByStudentIdData {
  GetStudentDocsByStudentId: GetStudentDocsByStudentIdDetails[];
}
export interface GetEmpDocsData {
  GetEmpDocs: GetEmpDocsDetails[];
}
export interface GetStudentDocsByStudentIdVars {
  token: string;
  student_id: number;
}
export interface GetEmpDocsVars {
  token: string;
  pr_emp_id: number;
}
export interface DeleteVars {
  token: string;
  inst_id: string | number;
  user_details: userDetails;
  document_id: number;
}

const Index = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  const { institutionType } = useSwConfigInstType();

  const [printAcknowledgement, setPrintAcknowledgement] = useState(false);
  const { dispatch, state } = useContext(AppContext);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_STUDENT_DOCUMENT_COLLECTION
  );
  const isDocCollectionEnabled =
    configData &&
    configData.data &&
    configData.data.GetSwConfigVariables.length &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  const [addDocTypeModal, setAddDocTypeModal] = useState(false);
  const [configuration, setConfiguration] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  // eslint-disable-next-line

  const [profileImage, setProfileImage] = useState("");

  const { studentData, studentFormData } = useStudentDatabyId();

  const { InstDetails } = useInstDetails(1);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setProfileImage("");
  };

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setProfileImage);
    } else {
      setProfileImage("");
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  const AutocompleteChange = (newValue: responseType | null) => {
    setProfileImage("");
  };

  const [GetInstDocumentData, { data: DocumentsList }] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments, {
    variables: {
      token,
      inst_id: InstId!,
      custom_doc_name: DocumentAcknowledgement.STD_DOC_ACK,
    },
  });

  useEffect(() => {
    if (token && InstId!) {
      GetInstDocumentData();
    }
    // eslint-disable-next-line
  }, [token, GetInstDocumentData]);
  const customDocID =
    DocumentsList && DocumentsList.GetInstDocuments.map((res) => res.id);
  const [GetDocuments, { data: documentsData }] = useLazyQuery<
    GetStudentDocsByStudentIdData,
    GetStudentDocsByStudentIdVars
  >(GetStudentDocsByStudentId, {
    variables: {
      student_id: state.studentId,
      token,
    },
  });

  useEffect(() => {
    if (token && InstId && state.studentId) {
      GetDocuments();
    }
  }, [token, InstId, GetDocuments, documentsData, state.studentId]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Student Documents</Title>
      <div className="student-documents">
        <div className="row g-0 student-documents__details">
          <div className="col">
            <div className="student-documents__details--flex">
              <StudentSelectAutocomplete
                queryType={StudentReportType.GENERAL}
                onChange={AutocompleteChange}
              />
            </div>

            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                label="Register Number"
                className="student-documents__details--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.std_reg_no}
                disabled
              />
            )}

            <TextField
              label={branchLabel}
              className="student-documents__details--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.branch}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              className="student-documents__details--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.std_name}
              disabled
            />
            <TextField
              label="Father Name"
              className="student-documents__details--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.std_father_name}
              disabled
            />
            <TextField
              label={classLabel}
              className="student-documents__details--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.class}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              type="date"
              label="Date"
              className="student-documents__details--textfield--date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={TODAY_DATE}
              disabled
            />
            <Label variant="present-day">{NameOfTheDay(TODAY_DATE)}</Label>

            <TextField
              label="Academic Year"
              className="student-documents__details--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.acd_yr}
              disabled
            />
            <TextField
              label={categoryLabel}
              className="student-documents__details--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.category}
              disabled
            />
          </div>
          <div className="col-1  student-documents__details--image">
            {profileImage === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={profileImage} alt="/" />
            )}
          </div>
        </div>
        <div className="student-documents__tableblock">
          <DocsUploadComponent type={Operation.CREATE} />
        </div>
        <Button mode="clear" onClick={handleClear} />

        {state.studentId && isDocCollectionEnabled ? (
          <Button
            mode="print"
            onClick={() => setPrintAcknowledgement(!printAcknowledgement)}>
            Acknowledge Receipt
          </Button>
        ) : null}

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              queryType={StudentReportType.GENERAL}
              studentListFor={StudentListFor.GENERAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addDocTypeModal}
        style={UserRightsModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AddDocType
              setModalFlag={setAddDocTypeModal}
              setMessage={setMessage}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setAddDocTypeModal(!addDocTypeModal)}
            />
          </div>
        </div>
      </Modal>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printAcknowledgement}
        style={PrintModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PrintDocuments
              setModal={setPrintAcknowledgement}
              selectedId={Number(customDocID)}
              selectedStudents={[state.studentId]}
              pageType={DocumentAcknowledgement.STD_DOC_ACK}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Settings}
              alt=""
              onClick={() => setConfiguration(!configuration)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        style={PrintConfigModalStyles}
        isOpen={configuration}>
        <Configurations
          setModal={setConfiguration}
          pageType={DocumentAcknowledgement.STD_DOC_ACK}
        />
      </Modal>
    </>
  );
};

export default Index;
