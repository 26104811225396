import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { useTheme } from "@mui/material/styles";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { Tab, Tabs } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import SectionTree from "./SectionTree";
import SectionInterchangeTree from "./SectionInterchangeTree";
const Index = () => {
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  const handleChangeIndex = (index: number) => setValue(index);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {value === 0 ? "Section Allocation" : "Section Interchange"}
      </Title>
      <div className="section-allocation">
        <div className="section-allocation__tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Allocate" value={0} {...a11yProps(0)} />
            <Tab label="Interchange" value={1} {...a11yProps(1)} />
          </Tabs>
        </div>
        <div className="section-allocation__swipable">
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            className="bulk-data-alter__swipable"
          >
            {/* values for Attendance */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <SectionTree />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <SectionInterchangeTree />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </>
  );
};

export default Index;
