import React, { useEffect, useState, useContext } from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import { Form, Formik } from "formik";

import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import InputHoc from "../../../../components/common/Input/Index";
import { Title } from "../../../../stories/Title/Title";
import { LibraryLabelNameProps } from "../../../../Types/Labels";
import { LibraryTitleProps } from "../../../../Types/Titles";
import Modal from "react-modal";
import LibraryImport from "../../Imports/MasterImport";
import Close from "../../../../images/Close.svg";

import EditProfile from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";

import { useNavigate, useParams } from "react-router-dom";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  LIBRARY_ROWS_PER_PAGE,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import { BookCategoryType, msgType } from "../../../../utils/Form.types";
import { BookCategoryValidation } from "../../../../utils/validationRules";
import DeleteModal from "../../../../pages/DeleteModal";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddLibBookCategory } from "../../../../queries/Library/MasterData/BookCategory/mutations/new";
import { UpdateLibBookCategory } from "../../../../queries/Library/MasterData/BookCategory/mutations/update";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  removeMoreSpace,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import { DeleteLibBookCategoryById } from "../../../../queries/Library/MasterData/BookCategory/mutations/delete";
import {
  BookCategoryMediaEdge,
  BookCategoryNode,
  GetLibBookCategoriesByInstIdData,
  GetLibBookCategoriesByInstIdVars,
} from "../../../../Types/Library/MasterData/BookCategory/paginationTypes";
import { GetLibBookCategoriesByInstId } from "../../../../queries/Library/MasterData/BookCategory/list/byInstId";
import { ByNode as BookCategoryById } from "../../../../queries/Library/Node";
import useToken from "../../../../customhooks/useToken";
import { AntSwitch } from "../../../../pages/Switch";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Constants";
import { CategoryHeader } from "../../../Accounts/common/HeaderConsts";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../customHooks/useServerDateandTime";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
import { AppContext } from "../../../../context/context";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { LibraryMasters } from "../../enums/Enum.types";

const { Library } = require("../../../../json/title.json");

interface Props {
  type: string;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
export interface BookCategoryNodeData {
  nodes: BookCategoryNode[];
}
export interface BookCategoryNodeVars {
  token: string;
  ids: number[] | number;
}
const Index = ({ type, modalFlag, setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { Library_Table } = useLibraryTableJson();
  const { LibraryFormLabels } = useLibraryformJson();
  const { user_details } = useLoggedInUserDetails();
  const [deleteModal, setDeleteModal] = useState(false);
  const { state } = useContext(AppContext);
  const [sortBy] = useState(SortBy.CATEGORY_DESC);
  const [direction] = useState(Direction.ASC);
  const [searchData, setSearchData] = useState("");
  const [operation, setoperation] = useState(Operation.CREATE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [bookCategoryId, setBookCategoryId] = useState(0);
  const [categoryList, setCategoryList] = useState<BookCategoryMediaEdge[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [importModal, setImportModal] = useState(false);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const nameRef = document.getElementsByName(
    "category_desc"
  )[0] as HTMLInputElement;
  const [formData, setFormData] = useState<BookCategoryType>({
    category_desc: "",
    category_abbrevation: "",
    category_is_active: true,
    category_sl: 0,
  });
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { serverDate } = useServerDateandTime();

  const [rowsPerPage, setRowsPerPage] = useState<number | null>(
    LIBRARY_ROWS_PER_PAGE
  );
  //Queries
  const [GetCategoryData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetLibBookCategoriesByInstIdData,
    GetLibBookCategoriesByInstIdVars
  >(GetLibBookCategoriesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: ROWS_PER_PAGE,
      sortBy,
      direction,
      categoryName: searchData,
      after: null,
    },
  });

  const [
    GetBookCategory,
    { data: BookCategoryData, loading: BookCategoryLoading },
  ] = useLazyQuery<BookCategoryNodeData, BookCategoryNodeVars>(
    BookCategoryById
  );

  //Mutations
  const [AddBookCategory, { loading: creationLoading }] = useMutation(
    AddLibBookCategory,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateBookCategory, { loading: updationLoading }] = useMutation(
    UpdateLibBookCategory,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteBookCategory, { loading: deleteLoading }] = useMutation(
    DeleteLibBookCategoryById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const categoryData = data?.GetLibBookCategoriesByInstId.edges.map(
    (data, index) => ({
      SlNo: index + 1,
      Name: data.node.category_desc,
      Abbrevation: data.node.category_abbrevation,
      Status: data.node.category_is_active,
    })
  );
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Book Category");

    const dataRows = categoryData
      ? categoryData?.map((item) => [
          item.SlNo,
          item.Name,
          item.Abbrevation,
          item.Status ? "Active" : "InActive",
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 12;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 22;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          if (configLogo) {
            worksheet.addImage(imageV, "D1:D3");
          }

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:D2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:D3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Book Category List List";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("C4:C4");
                  const mergedDate: Cell = worksheet.getCell(D4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("D4:D4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;

                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < CategoryHeader.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = CategoryHeader[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(LIBRARY_ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  //Methods
  const handleClear = () => {
    setFormData({
      category_desc: "",
      category_abbrevation: "",
      category_is_active: false,
      category_sl: 0,
    });
    setoperation(Operation.CREATE);
    nameRef?.focus();
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setoperation(Operation.CREATE);
      //incase if user clicks back the modal will open which should not happen and if he clicks the back in message it should close
    }
    setMessage({
      flag: !message.flag,
      message: "",
      operation: Operation.NONE,
    });
  };

  const HandleSubmit = () => {
    const input = {
      category_desc: removeMoreSpace(formData.category_desc),
      category_abbrevation: removeMoreSpace(formData.category_abbrevation),
      category_is_active: formData.category_is_active,
      category_sl: 0,
    };
    if (operation === Operation.CREATE) {
      AddBookCategory({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibBookCategoriesByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              categoryName: "",
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (type === PageFor.MODAL) {
            setModalFlag?.(!modalFlag);
          }
          setMessage({
            flag: !message.flag,
            message: "BookCategory Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateBookCategory({
        variables: {
          token,
          id: bookCategoryId,
          input,
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetLibBookCategoriesByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              categoryName: "",
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "BookCategory Updated Successfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };

  const HandleDelete = (id: number) => {
    DeleteBookCategory({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibBookCategoriesByInstId,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            sortBy,
            direction,
            categoryName: "",
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "BookCategory Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  useEffect(() => {
    if (
      BookCategoryData &&
      !BookCategoryLoading &&
      operation === Operation.UPDATE
    ) {
      const {
        category_desc,
        category_abbrevation,
        category_is_active,
        category_sl,
      } = BookCategoryData.nodes[0];
      setFormData({
        category_desc,
        category_abbrevation,
        category_is_active,
        category_sl,
      });
    }
  }, [BookCategoryData, BookCategoryLoading, operation]);

  useEffect(
    () => {
      if (type === PageFor.MODAL) {
        setoperation(Operation.CREATE);
      }
    },
    // eslint-disable-next-line
    [type]
  );
  useEffect(() => {
    if (token) {
      GetCategoryData();
    }
  }, [token, GetCategoryData]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges =
                  fetchMoreResult.GetLibBookCategoriesByInstId?.edges;
                const pageInfo =
                  fetchMoreResult.GetLibBookCategoriesByInstId.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetLibBookCategoriesByInstId.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetLibBookCategoriesByInstId: {
                    edges: [...categoryList, ...newEdges],
                    pageInfo,
                    totalCount: data
                      ? data.GetLibBookCategoriesByInstId.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);

  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Book_Category.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      headerName: TableHeaders.ACTION,
      renderCell: (params: GridRenderCellParams) => {
        const categoryId = params.row.category_id;

        return (
          <>
            <img
              src={EditProfile}
              alt="/"
              onClick={() => {
                GetBookCategory({
                  variables: {
                    token,
                    ids: categoryId,
                  },
                }).then(({ data }) => {
                  //enableSave should always stay true if edit is clicked
                  if (data) {
                    setBookCategoryId(categoryId);
                    setoperation(Operation.UPDATE);
                  }
                });
              }}
            />
            &nbsp;
            <img
              src={Delete}
              alt="/"
              onClick={() => {
                setBookCategoryId(categoryId);
                setoperation(Operation.DELETE);
                setDeleteModal(!deleteModal);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading && excelFlag === false) {
      const newData = data.GetLibBookCategoriesByInstId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newMedia) => {
          const filteredStudent = categoryList.find(
            (media) => media.node.id === newMedia.node.id
          );
          if (filteredStudent) {
            return {
              ...newMedia,
              node: {
                ...newMedia.node,
              },
            };
          }
          return newMedia;
        });
        setCategoryList(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name: node.category_desc,
            abbr: node.category_abbrevation,
            status: node.category_is_active ? "Active" : "Inactive",
            category_id: node.id,
          }))
        );
      } else {
        setCategoryList(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name: node.category_desc,
            abbr: node.category_abbrevation,
            status: node.category_is_active ? "Active" : "Inactive",
            category_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetLibBookCategoriesByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  if (error) return <>{error}</>;
  return (
    <>
      {type === PageFor.MODAL ? null : <Home DashBoardRequired={false} />}
      <Title>
        {Library.Titles.BookCategory.map(
          (title: LibraryTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.Book_Category}</React.Fragment>
            );
          }
        )}
      </Title>

      <Formik
        initialValues={formData}
        validationSchema={BookCategoryValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                type === PageFor.MODAL
                  ? "library__frame--modal"
                  : "library__frame"
              }
            >
              <div className="library__frame--row">
                <div className="h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">
                      {Library.Titles.BookCategory.map(
                        (title: LibraryTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="library__frame--block">
                    {LibraryFormLabels.Book_Category.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              inputref={nameRef!}
                              values={
                                formData[label.inputName] === 0
                                  ? EMPTY_STRING
                                  : formData[label.inputName]
                              }
                              type={label.dataType}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    {operation === Operation.UPDATE &&
                      LibraryFormLabels.Media.CheckBoxType.map(
                        (label: LibraryLabelNameProps, index: React.Key) => {
                          return (
                            <FormGroup key={index}>
                              <FormControlLabel
                                label={label.LabelName}
                                onKeyDown={handleFormEvent}
                                control={
                                  <AntSwitch
                                    checked={
                                      formData.category_is_active ? true : false
                                    }
                                    onClick={(e: React.MouseEvent) => {
                                      setFormData({
                                        ...formData,
                                        category_is_active: (
                                          e.target as HTMLInputElement
                                        ).checked,
                                      });
                                    }}
                                  />
                                }
                                labelPlacement="start"
                              />
                            </FormGroup>
                          );
                        }
                      )}
                  </div>
                </div>
                <div className="h-100 booktype-right">
                  <div className="row g-0 library__frame--title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">
                        {Library.Titles.BookCategory.map(
                          (title: LibraryTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {title.List}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                    </div>
                    <div className="col-3">
                      <Input
                        id="search"
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchData(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={`library__frame--block `}>
                    <StyledDatagrid
                      columns={columns}
                      rows={rows}
                      rowHeight={TABLE_ROW_HEIGHT}
                      disableRowSelectionOnClick
                      hideFooter
                    />
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                <Button mode="save" type="submit" />
                <Button mode="clear" type="button" onClick={handleClear} />
                {type === PageFor.GENERAL ? (
                  <>
                    <Button
                      mode="back"
                      type="button"
                      onClick={() => navigate(-1)}
                    />
                    <Button
                      mode="excel"
                      type="button"
                      onClick={() => setImportModal(!importModal)}
                    >
                      Import Library Category
                    </Button>
                    <Button
                      mode="excel"
                      type="button"
                      onClick={() => {
                        setRowsPerPage(null);
                        setExcelFlag(true);
                      }}
                    >
                      Export
                    </Button>
                  </>
                ) : (
                  <Button
                    mode="cancel"
                    type="button"
                    onClick={() => setModalFlag?.(false)}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LibraryImport MasterType={LibraryMasters.BOOK_CATEGORY} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deleteLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={bookCategoryId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
