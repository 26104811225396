import { Label } from "../../../../stories/Label/Label";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Button } from "../../../../stories/Button/Button";
import { useParams } from "react-router";
import {
  Direction,
  InstitutionConfigurationTypes,
  InstitutionType,
  Operation,
  PageFor,
  PageLabel,
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
  StudentListFor,
  StudentReportType,
  StudentStatus,
  UserType,
  YesNo,
} from "../../../../utils/Enum.types";
import { studentValidation } from "../../../../utils/validationRules";
import {
  msgType,
  optionsType,
  responseType,
  studentBasicDetails,
} from "../../../../utils/Form.types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AddStudent } from "../../../../queries/students/mutations/new";
import { updateStudentById } from "../../../../queries/students/mutations/update";
import Input from "../../../../components/common/Input/Index";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import InputStory from "../../../../stories/Input/Input";
import {
  handleFormEvent,
  handleMUISelectEvent,
  removeMoreSpace,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";

import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useNotAlloactedIdFromInst from "../../../../customhooks/useNotAlloactedIdFromInst";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  StudentFormLabelsType,
  StudentsTitlesType,
} from "../../../../Types/Student/json";
import { BranchListVarsByInstId } from "../../../../Types/Student";
import {
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";

import LoadingModal from "../../../../pages/LoadingModal";
import Home from "../../Home/Index";
import {
  GetStudentEnquiresByEnquiryId,
  GetStudents,
} from "../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import {
  GetBranchWiseAdmissionNumber,
  GetInstWiseAdmissionNumber,
} from "../../../../queries/institution/configuration/query";
import {
  AddmissionNumberByBranchId,
  AddmissionNumberByInstId,
} from "../../configurations/institutionconfiguration/InstitutionNeeds";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import DownArrow from "../../../../images/DownArrow.svg";
import { Title } from "../../../../stories/Title/Title";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import useDropdownData from "../../../../customhooks/useDropdownData";
import { Keys } from "../../../../utils/Enum.keys";
import MessageModal from "../../../../pages/MessageModal";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import StudentEnquiry from "../../../Enquiry/StudentEnquiry";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import Fetch from "../../../../images/Fetch.svg";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { AppContext } from "../../../../context/context";
import { GetTransportStudent } from "../../../Transport/queries/list";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
  GlobalPageConfigData,
} from "../../../../Types/configtypes";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import dayjs from "dayjs";
import { GetSwConfigVariables } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import useStudentProfilePicUpload from "../../../../customhooks/useStudentProfilePicUpload";
import {
  FormAutocomplete,
  formClasses,
  RequiredAutoComplete,
} from "../../../../styles/AutocompleteStyles";
import { AntSwitch } from "../../../../pages/Switch";
import StudentList from "../List";
import { payloadTypes } from "../../../../context/reducer";
import { GetStudentDetails } from "../../../../queries/common";

const { Students } = require("../../../../json/title.json");
const { StudentFormLabels } = require("../../../../json/config.json");

interface props {
  operation: Operation;
  step: number;
}

export const studentRegStepHeader = () => {
  return [
    "Basic Details",
    "Personal Details",
    "Academic Details",
    "Student Parent Association",
    "Student Documents Upload",
    "Fee Demand",
  ];
};

const Registration = ({ operation, step }: props) => {
  const { token } = useToken();
  const stepsHeader = studentRegStepHeader();
  const { dispatch, state } = useContext(AppContext);
  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.INSTITUTION_USE_ADM_NUMBER,
    InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME,
    InstitutionConfigurationTypes.ENABLE_LAST_NAME,
    InstitutionConfigurationTypes.STD_ADM_NUMBER_EDIT,
    InstitutionConfigurationTypes.ENABLE_STUDENT_LOGIN,
  ]);
  const { studentFormData, studentData } = useStudentDatabyId();
  const { serverDate } = useServerDateandTime();
  const navigate = useNavigate();
  const { InstId, studentId } = useParams();

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();
  const [formData, setFormData] = useState<studentBasicDetails>({
    first_name: "",
    middle_name: "",
    last_name: "",
    std_status: StudentStatus.CUR,
    studying: YesNo.YES,
    sex: "",
    reg_number: "",
    adm_number: "",
    doa: "",
    fresher: YesNo.YES,
    fin_yr_id: 0,
    acd_yr_id: 0,
    dob: "",
    email: "",
    mobile: "",
    father_name: "",
    mother_name: "",
    firebaseid: "",
    dept_id: 0,
    branch_id: 0,
    class_id: 0,
    semester_id: 0,
    category_id: 0,
    section_id: 0,
    std_curr_fin_yr: "",
    std_profile_filename: "",
    std_curr_acd_yr: "",
    std_sts_no: "",
    std_father_mobile: "",
    std_mother_mobile: "",
    std_primary_login: "",
    std_father_email: "",
    std_mother_email: "",
    std_guardian_name: "",
    std_guardian_email: "",
    std_guardian_mobile: "",
  });
  const [studentModal, setStudentModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [addSiblings, setAddSiblings] = useState(false);

  const [admNumber, setadmNumber] = useState("");
  const [studentEnquiryModal, setStudentEnquiryModal] = useState(false);
  //useRefs
  const imageRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLSelectElement>(null);
  const statusInputRef = statusRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const studyingRef = useRef<HTMLSelectElement>(null);
  const studyingInputRef = studyingRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const fresherRef = useRef<HTMLSelectElement>(null);
  const fresherInputRef = fresherRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[0]?.childNodes[0] as HTMLInputElement;
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const semesterRef = useRef<HTMLSelectElement>(null);
  const semesterInputRef = semesterRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const dateOfAdmissionRef = useRef<HTMLInputElement>(null);
  const { InstDetails } = useInstDetails(1);
  const dobRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const primaryContactRef = useRef<HTMLSelectElement>(null);
  const primaryContactInputRef = primaryContactRef.current?.childNodes[0]
    ?.childNodes[0]?.childNodes[0] as HTMLSelectElement;

  const { component, setFile, uploadFile, file } = useStudentProfilePicUpload(
    operation,
    formData.std_profile_filename,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE
  );

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues: studentBasicDetails) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const [createStudent, { loading: creationLoading }] = useMutation(
    AddStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const { user_details } = useLoggedInUserDetails();
  const [updateStudent, { loading: updationLoading }] = useMutation(
    updateStudentById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
    masterIds,
  } = useDropdownData(
    formData.dept_id,
    formData.branch_id,
    formData.class_id,
    formData.semester_id
  );
  const { NotAllocatedDeptId, NotAllocatedSectionId, NotAllocatedCategoryId } =
    useNotAlloactedIdFromInst();
  //Category By Institution
  const { categoryDropDown } = useInstMasterDataByInstId();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [GetBranchAdmissionDetails] = useLazyQuery<
    AddmissionNumberByBranchId,
    BranchListVarsByInstId
  >(GetBranchWiseAdmissionNumber, {
    variables: { inst_id: InstId!, token, branch_id: formData.branch_id },
    fetchPolicy: "network-only",
  });
  const [GetEnquiryData] = useLazyQuery(GetStudentEnquiresByEnquiryId, {
    variables: {
      token,
      id: state.studentEnquiryId,
    },
  });
  const [GetConfigdata, { data }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId ? InstId : state.InstId,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: "INSTITUTION_TYPE",
        int_value: 1,
      },
    },
  });

  const stsNoFlag = data && data.GetSwConfigVariables[0].config_string_value;

  const [GetInstAdmissionDetails] = useLazyQuery<
    AddmissionNumberByInstId,
    BranchListVarsByInstId
  >(GetInstWiseAdmissionNumber, {
    variables: { inst_id: InstId!, token, branch_id: formData.branch_id },
    fetchPolicy: "network-only",
  });

  const {
    PredefinedData: { dropDown: Fresher },
  } = usePredefinedDataByType(PredefinedDataTypes.YESNO, EMPTY_STRING);
  const {
    PredefinedData: { dropDown: Studying },
  } = usePredefinedDataByType(PredefinedDataTypes.YESNO, EMPTY_STRING);
  const {
    PredefinedData: { dropDown: STUDENT_STATUS },
  } = usePredefinedDataByType(PredefinedDataTypes.STUDENT_STATUS, EMPTY_STRING);
  const {
    PredefinedData: { dropDown: Gender },
  } = usePredefinedDataByType(PredefinedDataTypes.GENDER, EMPTY_STRING);
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      mobile: mobileNo,
    }));
  };

  const ParentType = [
    { label: "Father", value: "FATHER" },
    { label: "Mother", value: "MOTHER" },
    { label: "Guardian", value: "GUARDIAN" },
    { label: "Student", value: "STUDENT" },
  ];
  const submitForm = async () => {
    //When mobile number is fetched from enquiry it will have + so that is a total of 13 character
    if (
      stsNoFlag !== InstitutionType.SCHOOL &&
      !(
        formData.mobile.length === 12 ||
        formData.mobile.length === 13 ||
        formData.email
      )
    ) {
      alert("Please fill any one the field, Email/Mobile No:");
      return;
    }
    if (
      state.ActiveFinYr &&
      dayjs(formData.doa).isAfter(state.ActiveFinYr.fin_end_date)
    ) {
      alert("Due Date should be within Current Financial Year Date");
      return;
    }
    const doa = formData.doa
      ? new Date(formData.doa).toISOString()
      : new Date(serverDate).toISOString();
    const dob = formData.dob
      ? new Date(formData.dob).toISOString()
      : new Date(serverDate).toISOString();
    let studying,
      fresher = null;
    // eslint-disable-next-line
    formData.studying === YesNo.YES ? (studying = true) : (studying = false);
    // eslint-disable-next-line
    formData.fresher === YesNo.YES ? (fresher = true) : (fresher = false);

    if (studentId !== "0") {
      try {
        const uploadResult = await uploadFile(
          studentData && studentData.data && studentData.data.nodes.length
            ? studentData.data.nodes[0].id
            : 0
        );
        if (uploadResult) {
          updateStudent({
            variables: {
              token,
              id: studentId,
              inst_id: InstId!,
              user_details,
              input: {
                first_name: removeMoreSpace(formData.first_name),
                middle_name: removeMoreSpace(formData.middle_name),
                last_name: removeMoreSpace(formData.last_name),
                std_studying: studying,
                std_status: formData.std_status,
                std_sex: formData.sex,
                std_reg_no: removeMoreSpace(formData.reg_number),
                std_adm_no: formData.adm_number,
                std_doa: doa,
                std_fresher: fresher,
                std_dob: dob,
                std_email: removeMoreSpace(formData.email.toLowerCase()),
                std_mobile:
                  formData.mobile.length === 12
                    ? `+${formData.mobile}`
                    : EMPTY_STRING,
                std_father_mobile: formData.std_father_mobile
                  ? `+${formData.std_father_mobile}`
                  : EMPTY_STRING,
                std_mother_mobile: formData.std_mother_mobile
                  ? `+${formData.std_mother_mobile}`
                  : EMPTY_STRING,
                std_father_name: removeMoreSpace(formData.father_name),
                std_mother_name: removeMoreSpace(formData.mother_name),
                std_father_email: formData.std_father_email
                  ? removeMoreSpace(formData.std_father_email)
                  : EMPTY_STRING,
                std_mother_email: formData.std_mother_email
                  ? removeMoreSpace(formData.std_mother_email)
                  : EMPTY_STRING,
                std_guardian_name: formData.std_guardian_name
                  ? removeMoreSpace(formData.std_guardian_name)
                  : " ",
                std_guardian_mobile: formData.std_guardian_mobile
                  ? `+${formData.std_guardian_mobile}`
                  : EMPTY_STRING,
                std_guardian_email: formData.std_guardian_email
                  ? removeMoreSpace(formData.std_guardian_email)
                  : " ",
                std_primary_login: formData.std_primary_login
                  ? formData.std_primary_login
                  : "STUDENT",
                std_profile_filename:
                  file && InstDetails.data
                    ? `${InstDetails.data?.nodes[0]?.inst_name}/students/${
                        studentData &&
                        studentData.data &&
                        studentData.data.nodes.length
                          ? studentData.data.nodes[0].id
                          : 0
                      }/std_profile_pic/std_profile_pic`
                    : formData.std_profile_filename,
              },
            },
            refetchQueries: [
              {
                query: GetStudentDetails,
                variables: {
                  ids: studentId ?? EMPTY_STRING,
                  token,
                },
              },
            ],
          }).then(({ data }) => {
            if (data) {
              operation === Operation.CREATE
                ? navigate(
                    `/${InstId}/admissions/students/2/${studentId}/personalDetails`
                  )
                : navigate(
                    `/${InstId}/admissions/student/2/${studentId}/personalDetails`
                  );
            }
          });
        } else {
          setMessage({
            flag: true,
            operation: Operation.NONE,
            message: "Image upload failed",
          });
        }
      } catch (error) {
        setMessage({
          flag: true,
          operation: Operation.NONE,
          message: `${error}`,
        });
      }
    } else {
      createStudent({
        variables: {
          token,
          inst_id: InstId!,
          user_details: {
            user_type: UserType.EMPLOYEE,
            id: state.empLoginId,
          },
          flags: {
            is_adm_num_edited: !(formData.adm_number === admNumber),
            use_adm_num_as_is: false,
          },
          enquiry_std_id: state.studentEnquiryId,
          sibling_std_id: state.siblingId ? state.siblingId : 0,
          input: {
            inst_id: InstId,
            dept_id: formData.dept_id ? formData.dept_id : NotAllocatedDeptId,
            section_id: NotAllocatedSectionId,
            branch_id: masterIds.branchId,
            class_id: masterIds.classId,
            semester_id: masterIds.semesterId,
            category_id: formData.category_id
              ? formData.category_id
              : NotAllocatedCategoryId,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            std_sts_no: formData.std_sts_no,
            first_name: removeMoreSpace(formData.first_name),
            middle_name: removeMoreSpace(formData.middle_name),
            last_name: removeMoreSpace(formData.last_name),
            std_studying: studying,
            std_status: formData.std_status,
            std_sex: formData.sex,
            std_reg_no: removeMoreSpace(formData.reg_number),
            std_adm_no: formData.adm_number,
            std_doa: doa,
            std_fresher: fresher,
            std_dob: dob,
            std_email: removeMoreSpace(formData.email),
            std_mobile:
              formData.mobile.length === 12
                ? `+${formData.mobile}`
                : EMPTY_STRING,
            std_father_name: removeMoreSpace(formData.father_name),
            std_mother_name: removeMoreSpace(formData.mother_name),
            std_father_email: formData.std_father_email
              ? removeMoreSpace(formData.std_father_email)
              : EMPTY_STRING,
            std_mother_email: formData.std_mother_email
              ? removeMoreSpace(formData.std_mother_email)
              : EMPTY_STRING,
            std_father_mobile: formData.std_father_mobile
              ? `+${formData.std_father_mobile}`
              : EMPTY_STRING,
            std_guardian_name: formData.std_guardian_name
              ? removeMoreSpace(formData.std_guardian_name)
              : " ",
            std_guardian_mobile: formData.std_guardian_mobile
              ? `+${formData.std_guardian_mobile}`
              : EMPTY_STRING,
            std_guardian_email: formData.std_guardian_email
              ? removeMoreSpace(formData.std_guardian_email)
              : " ",
            std_primary_login: formData.std_primary_login
              ? formData.std_primary_login
              : "STUDENT",
            std_mother_mobile: formData.std_mother_mobile
              ? `+${formData.std_mother_mobile}`
              : EMPTY_STRING,
            std_profile_filename:
              file && InstDetails.data
                ? `${InstDetails.data?.nodes[0]?.inst_name}/students/check/std_profile_pic/std_profile_pic`
                : EMPTY_STRING,
          },
        },
        refetchQueries: [
          {
            query: GetStudents,
            variables: {
              admNo: "",
              after: null,
              status: "",
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: "",
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)],
                std_query_type: studentQueryTypes.BY_INST_ID,
              },
            },
          },
          {
            query: GetStudents,
            variables: {
              admNo: EMPTY_STRING,
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              status: "",
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                std_query_type: studentQueryTypes.NOT_REGISTERED_BY_INST_ID,
                ids: [Number(InstId)],
              },
            },
          },
          {
            query: GetStudents,
            variables: {
              admNo: "",
              after: null,
              status: "",
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: "",
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)],
                std_query_type: studentQueryTypes.SECTION_NOT_ALLOC_BY_INST_ID,
              },
            },
          },
          {
            query: GetTransportStudent,
            variables: {
              token,
              student_id: state.studentId,
            },
          },
        ],
      }).then(async ({ data }) => {
        if (data) {
          try {
            const uploadResult = await uploadFile(data.AddStudent.id);
            if (uploadResult) {
              navigate(
                `/${InstId}/admissions/students/2/${data.AddStudent.id}/personalDetails`
              );
              dispatch({
                type: payloadTypes.SET_SIBLING_ID,
                payload: { siblingId: 0 },
              });
            } else {
              setMessage({
                flag: true,
                operation: Operation.NONE,
                message: "Image upload failed",
              });
            }
          } catch (err) {
            setMessage({
              flag: true,
              operation: Operation.NONE,
              message: `${err}`,
            });
          }
        }
      });
    }
  };

  const dateDifference = (date1: string, date2: string) => {
    const from_date = new Date(date1);
    const to_date = new Date(date2);

    const birthYear = from_date.getFullYear();
    const birthMonth = from_date.getMonth();
    const birthDay = from_date.getDate();

    const currentYear = to_date.getFullYear();
    const currentMonth = to_date.getMonth();
    const currentDay = to_date.getDate();

    let years = currentYear - birthYear;
    let months = currentMonth - birthMonth;
    let days = currentDay - birthDay;

    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    if (days < 0) {
      const tempDate = new Date(currentYear, currentMonth - 1, 0);
      days += tempDate.getDate();
      months--;
    }

    return { years, months, days };
  };
  const handleClear = () => {
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
      std_status: StudentStatus.CUR,
      studying: YesNo.YES,
      sex: "",
      reg_number: "",
      adm_number: "",
      doa: "",
      fresher: YesNo.YES,
      fin_yr_id: 0,
      acd_yr_id: 0,
      dob: "",
      email: "",
      mobile: "",
      father_name: "",
      mother_name: "",
      firebaseid: "",
      dept_id: 0,
      branch_id: 0,
      class_id: 0,
      semester_id: 0,
      category_id: 0,
      section_id: 0,
      std_curr_fin_yr: "",
      std_profile_filename: "",
      std_curr_acd_yr: "",
      std_father_mobile: "",
      std_mother_mobile: "",
    });
    setFile(null);
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (studentId !== "0") {
      if (!studentData.loading && studentData.data) {
        const empMobileWithoutPlus =
          studentData.data?.nodes[0].std_mobile.replace("+", "");
        const studentProfiePicUrl = `${
          InstDetails.data?.nodes[0]?.inst_name
        }/students/${
          state.studentId ? state.studentId : studentId
        }/std_profile_pic/std_profile_pic`;
        setFormData({
          first_name: studentData && studentData.data.nodes[0].first_name,
          middle_name: studentData && studentData.data.nodes[0].middle_name,
          last_name: studentData && studentData.data.nodes[0].last_name,
          std_status: studentData && studentData.data.nodes[0].std_status,
          studying:
            studentData && studentData.data.nodes[0].std_studying
              ? YesNo.YES
              : YesNo.NO,
          sex: studentData && studentData.data.nodes[0].std_sex,
          reg_number: studentData && studentData.data.nodes[0].std_reg_no,
          adm_number: studentData && studentData.data.nodes[0].std_adm_no,
          doa: toInputStandardDate(
            studentData && studentData.data.nodes[0].std_doa!
          ),
          fresher:
            studentData && studentData.data.nodes[0].std_fresher
              ? YesNo.YES
              : YesNo.NO,
          fin_yr_id: studentData && studentData.data.nodes[0].fin_yr.id,
          acd_yr_id: studentData && studentData.data.nodes[0].acd_yr.id,
          dob: toInputStandardDate(
            studentData && studentData.data.nodes[0].std_dob
          ),
          email:
            studentData && studentData.data.nodes[0].std_email.toLowerCase(),
          mobile: empMobileWithoutPlus,
          father_name: studentData && studentData.data.nodes[0].std_father_name,
          mother_name: studentData && studentData.data.nodes[0].std_mother_name,
          firebaseid: "",
          dept_id: studentData && studentData.data.nodes[0].dept.id,
          branch_id: studentData && studentData.data.nodes[0].branch.id,
          class_id: studentData && studentData.data.nodes[0].class.id,
          semester_id: studentData && studentData.data.nodes[0].semester.id,
          category_id: studentData && studentData.data.nodes[0].category.id,
          section_id: studentData && studentData.data.nodes[0].section.id,
          std_curr_fin_yr: "",
          std_curr_acd_yr: "",
          std_profile_filename: studentProfiePicUrl,
          std_father_mobile:
            studentData &&
            studentData.data.nodes[0].std_father_mobile.replace("+", ""),
          std_mother_mobile:
            studentData &&
            studentData.data.nodes[0].std_mother_mobile.replace("+", ""),
          std_guardian_mobile:
            studentData &&
            studentData.data.nodes[0].std_guardian_mobile.replace("+", ""),
          std_guardian_email:
            studentData && studentData.data.nodes[0].std_guardian_email,
          std_guardian_name:
            studentData && studentData.data.nodes[0].std_guardian_name,
          std_primary_login:
            studentData && studentData.data.nodes[0].std_primary_login,
          std_mother_email:
            studentData && studentData.data.nodes[0].std_mother_email,
          std_father_email:
            studentData && studentData.data.nodes[0].std_father_email,
        });
      }
    }
  }, [studentData.loading, studentData.data, studentId, InstDetails.data]);
  useEffect(() => {
    if (state.siblingId && studentId === "0") {
      if (!studentData.loading && studentData.data) {
        const updatedFields = {
          father_name: studentData.data.nodes[0].std_father_name,
          mother_name: studentData.data.nodes[0].std_mother_name,
          std_father_mobile:
            studentData.data.nodes[0].std_father_mobile.replace("+", ""),
          std_mother_mobile:
            studentData.data.nodes[0].std_mother_mobile.replace("+", ""),
          std_guardian_mobile:
            studentData.data.nodes[0].std_guardian_mobile.replace("+", ""),
          std_guardian_email: studentData.data.nodes[0].std_guardian_email,
          std_guardian_name: studentData.data.nodes[0].std_guardian_name,
          std_primary_login: studentData.data.nodes[0].std_primary_login,
          std_mother_email: studentData.data.nodes[0].std_mother_email,
          std_father_email: studentData.data.nodes[0].std_father_email,
        };

        setFormData((prevFormData) => ({
          ...prevFormData,
          ...updatedFields, // Update only these fields
        }));
      }
    }
  }, [studentData.loading, studentData.data, state.siblingId]);

  useEffect(() => {
    if (addSiblings === true) {
      setStudentModal(!studentModal);
    }
  }, [addSiblings]);

  useEffect(() => {
    if (studentId === "0") {
      if (
        configData.data &&
        !configData.loading &&
        formData.branch_id &&
        useAdmNumber === "B"
      ) {
        GetBranchAdmissionDetails().then(({ data }) => {
          if (data) {
            setFormData({
              ...formData,
              adm_number: data.GetBranchWiseAdmissionNumber,
              doa: toInputStandardDate(serverDate),
            });
            setadmNumber(data.GetBranchWiseAdmissionNumber);
          }
        });
      }
      if (useAdmNumber === "I") {
        GetInstAdmissionDetails().then(({ data }) => {
          if (data) {
            setFormData({
              ...formData,
              adm_number: data.GetInstWiseAdmissionNumber,
              doa: toInputStandardDate(serverDate),
            });
            setadmNumber(data.GetInstWiseAdmissionNumber);
          }
        });
      }
    } // eslint-disable-next-line
  }, [
    configData.data,
    GetInstAdmissionDetails,
    configData.loading,
    GetBranchAdmissionDetails,
    formData.branch_id,
    studentId,
    serverDate,
  ]);
  useEffect(() => {
    if (token && InstId) {
      GetConfigdata();
    }
  }, [token, GetConfigdata, InstId]);
  useEffect(
    () => {
      if (state.studentEnquiryId !== 0)
        GetEnquiryData().then(({ data }) => {
          if (data) {
            const {
              first_name,
              middle_name,
              last_name,
              std_sex,
              std_father_name,
              std_mother_name,
              std_email,
              std_mobile,
            } = data.node;
            setFormData({
              ...formData,
              first_name,
              middle_name,
              last_name,
              sex: std_sex,
              doa: toInputStandardDate(serverDate),
              email: std_email,
              mobile: std_mobile,
              father_name: std_father_name,
              mother_name: std_mother_name,
            });
          }
        });
    },
    // eslint-disable-next-line
    [state.studentEnquiryId, GetEnquiryData]
  );

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let useAdmNumber = "";
    let enableMiddleName = false;
    let enableLastName = false;
    let enableStdNumEdit = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.INSTITUTION_USE_ADM_NUMBER:
            useAdmNumber = item.config_string_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME:
            enableMiddleName = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_LAST_NAME:
            enableLastName = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.STD_ADM_NUMBER_EDIT:
            enableStdNumEdit = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      useAdmNumber,
      enableMiddleName,
      enableLastName,
      enableStdNumEdit,
    };
  };
  const { useAdmNumber, enableStdNumEdit, enableMiddleName, enableLastName } =
    filterDataByConfigKey(configData.data?.GetSwConfigVariables!);

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="studentregistration">
        <Stepper alternativeLabel activeStep={step - 1}>
          {stepsHeader.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Formik
          initialValues={formData}
          validationSchema={studentValidation}
          onSubmit={submitForm}
          validateOnChange
          enableReinitialize>
          {(meta) => {
            return (
              <Form className="studentregistration__form">
                <Title>
                  {Students.BasicDetails.Titles.map(
                    (title: StudentsTitlesType, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          {operation === Operation.UPDATE
                            ? title.Update
                            : title.Create}
                        </React.Fragment>
                      );
                    }
                  )}
                </Title>
                <div className="studentregistration__form--details">
                  <div className="row g-0 studentregistration__basic-data">
                    <div className="col">
                      {StudentFormLabels.Labels.Registration.filter(
                        (label: StudentFormLabelsType) =>
                          label.inputName === "adm_number"
                      ).map(
                        (label: StudentFormLabelsType, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                onKeyDown={handleFormEvent}
                                LabelName={label.LabelName}
                                name={label.inputName}
                                autoFocus={label.autoFocus}
                                values={formData[label.inputName]}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                required={label.required}
                                type={label.dataType}
                                maxLength={label.maxLength}
                                className={label.className}
                                disabled={!enableStdNumEdit}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                      {stsNoFlag === InstitutionType.SCHOOL
                        ? StudentFormLabels.Labels.Registration.filter(
                            (label: StudentFormLabelsType) =>
                              label.inputName === "std_sts_no"
                          ).map(
                            (
                              label: StudentFormLabelsType,
                              index: React.Key
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <Input
                                    onKeyDown={handleFormEvent}
                                    LabelName={label.LabelName}
                                    name={label.inputName}
                                    autoFocus={label.autoFocus}
                                    values={formData[label.inputName]}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      meta.handleChange(e);
                                      handleValueChange(e);
                                    }}
                                    required={label.required}
                                    type={label.dataType}
                                    maxLength={label.maxLength}
                                  />
                                </React.Fragment>
                              );
                            }
                          )
                        : null}
                      {StudentFormLabels.Labels.BasicDetails.filter(
                        (label: StudentFormLabelsType) => {
                          return (
                            (label.inputName === "middle_name" &&
                              enableMiddleName) ||
                            (label.inputName === "last_name" &&
                              enableLastName) ||
                            (label.inputName !== "middle_name" &&
                              label.inputName !== "last_name")
                          );
                        }
                      ).map(
                        (label: StudentFormLabelsType, index: React.Key) => (
                          <React.Fragment key={index}>
                            <Input
                              onKeyDown={handleFormEvent}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              autoFocus={label.autoFocus}
                              values={formData[label.inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              required={label.required}
                              type={label.dataType}
                              maxLength={label.maxLength}
                              className={label.className}
                              disabled={label.disabled}
                            />
                          </React.Fragment>
                        )
                      )}
                      {operation === Operation.UPDATE ? (
                        <>
                          {StudentFormLabels.Labels.USNNumber.map(
                            (
                              label: StudentFormLabelsType,
                              index: React.Key
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <Input
                                    onKeyDown={handleFormEvent}
                                    LabelName={label.LabelName}
                                    name={label.inputName}
                                    autoFocus={label.autoFocus}
                                    values={formData[label.inputName]}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      meta.handleChange(e);
                                      handleValueChange(e);
                                    }}
                                    required={label.required}
                                    type={label.dataType}
                                    maxLength={label.maxLength}
                                    max={label.max}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      ) : null}
                      {stsNoFlag !== InstitutionType.SCHOOL ? (
                        <div className="label-grid">
                          <Label>Mobile</Label>
                          <PhoneInput
                            country={"in"}
                            value={formData.mobile}
                            onChange={handleNumberChange}
                            inputProps={{
                              required: false,
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                            }}
                          />
                        </div>
                      ) : null}
                      {/* {stsNoFlag === InstitutionType.SCHOOL ? (
                        <div className="label-grid">
                          <Label>Add Sibling</Label>
                          <FormControlLabel
                            label=""
                            control={
                              <AntSwitch
                                checked={addSiblings}
                                onClick={() => {
                                  setAddSiblings(!addSiblings);
                                }}
                              />
                            }
                          />
                        </div>
                      ) : null}
                      {stsNoFlag === InstitutionType.SCHOOL &&
                      addSiblings &&
                      state.siblingId ? (
                        <>
                          <Input
                            onKeyDown={handleFormEvent}
                            LabelName="Selected Sibling"
                            values={
                              studentFormData &&
                              studentFormData.first_name +
                                " " +
                                studentFormData.middle_name +
                                " " +
                                studentFormData.last_name
                            }
                            disabled
                          />
                        </>
                      ) : null} */}
                      {stsNoFlag !== InstitutionType.SCHOOL
                        ? StudentFormLabels.Labels.EMail.map(
                            (
                              label: StudentFormLabelsType,
                              index: React.Key
                            ) => (
                              <React.Fragment key={index}>
                                <Input
                                  onKeyDown={handleFormEvent}
                                  LabelName={label.LabelName}
                                  name={label.inputName}
                                  autoFocus={label.autoFocus}
                                  values={formData[label.inputName]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    meta.handleChange(e);
                                    handleValueChange(e);
                                  }}
                                  required={label.required}
                                  type={label.dataType}
                                  maxLength={label.maxLength}
                                  className={label.className}
                                  disabled={label.disabled}
                                  inputRef={emailRef!}
                                />
                              </React.Fragment>
                            )
                          )
                        : null}
                      {StudentFormLabels.Labels.FatherData.map(
                        (label: StudentFormLabelsType, index: React.Key) => (
                          <React.Fragment key={index}>
                            <Input
                              onKeyDown={handleFormEvent}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              autoFocus={label.autoFocus}
                              values={formData[label.inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              required={label.required}
                              type={label.dataType}
                              maxLength={label.maxLength}
                              className={label.className}
                              disabled={state.siblingId ? true : false}
                              inputRef={emailRef!}
                            />
                          </React.Fragment>
                        )
                      )}
                      <div className="label-grid">
                        <Label>Father Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.std_father_mobile}
                          onChange={(newMobile) => {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              std_father_mobile: newMobile,
                            }));
                          }}
                          disabled={state.siblingId ? true : false}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              handleMUISelectEvent(e);
                            }
                          }}
                        />
                      </div>
                      {StudentFormLabels.Labels.MotherData.map(
                        (label: StudentFormLabelsType, index: React.Key) => (
                          <React.Fragment key={index}>
                            <Input
                              onKeyDown={handleFormEvent}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              autoFocus={label.autoFocus}
                              values={formData[label.inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              required={label.required}
                              type={label.dataType}
                              maxLength={label.maxLength}
                              className={label.className}
                              disabled={state.siblingId ? true : false}
                            />
                          </React.Fragment>
                        )
                      )}
                      <div className="label-grid">
                        <Label>Mother Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.std_mother_mobile}
                          onChange={(newMobile) => {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              std_mother_mobile: newMobile,
                            }));
                          }}
                          disabled={state.siblingId ? true : false}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              handleMUISelectEvent(e);
                            }
                          }}
                        />
                      </div>
                      {StudentFormLabels.Labels.GuardianData.map(
                        (label: StudentFormLabelsType, index: React.Key) => (
                          <React.Fragment key={index}>
                            <Input
                              onKeyDown={handleFormEvent}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              autoFocus={label.autoFocus}
                              values={formData[label.inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              required={label.required}
                              type={label.dataType}
                              maxLength={label.maxLength}
                              className={label.className}
                              disabled={state.siblingId ? true : false}
                            />
                          </React.Fragment>
                        )
                      )}
                      <div className="label-grid">
                        <Label>Guardian Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.std_guardian_mobile}
                          onChange={(newMobile) => {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              std_guardian_mobile: newMobile,
                            }));
                          }}
                          disabled={state.siblingId ? true : false}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (stsNoFlag === InstitutionType.SCHOOL) {
                                if (primaryContactInputRef) {
                                  primaryContactInputRef.focus();
                                }
                              } else if (dobRef.current) {
                                dobRef.current.focus();
                              }
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col">
                      {stsNoFlag === InstitutionType.SCHOOL && (
                        <div className="label-grid">
                          <Label>Primary Contact</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            options={
                              stsNoFlag === InstitutionType.SCHOOL
                                ? ParentType.filter(
                                    (d) => d.label !== "Student"
                                  )
                                : ParentType!
                            }
                            ref={primaryContactRef!}
                            value={
                              (ParentType &&
                                ParentType.find(
                                  ({ value }) =>
                                    value === formData.std_primary_login
                                )!) ??
                              EMPTY_OPTIONSTYPE_OBJECT
                            }
                            disabled={state.siblingId ? true : false}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    std_primary_login: (newValue as optionsType)
                                      ?.value!,
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    std_primary_login: EMPTY_STRING,
                                  };
                                });
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (operation === Operation.UPDATE) {
                                  statusInputRef?.focus();
                                } else {
                                  dobRef.current?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    std_primary_login: "",
                                  };
                                });
                              }
                            }}
                            openOnFocus
                            freeSolo={false}
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={(e) => {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      sex: e.target.value!,
                                    };
                                  });
                                }}
                                fullWidth
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                        </div>
                      )}

                      <div className="date-input">
                        {StudentFormLabels.Labels.DOB.map(
                          (label: StudentFormLabelsType, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                <Input
                                  onKeyDown={handleFormEvent}
                                  inputRef={dobRef!}
                                  LabelName={label.LabelName}
                                  name={label.inputName}
                                  autoFocus={label.autoFocus}
                                  values={formData[label.inputName]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    meta.handleChange(e);
                                    handleValueChange(e);
                                  }}
                                  required={label.required}
                                  type={label.dataType}
                                  maxLength={label.maxLength}
                                  max={toInputStandardDate(serverDate)}
                                />
                                <div className="date-input--label">
                                  {formData.dob ? (
                                    <span>
                                      (
                                      {` ${
                                        dateDifference(formData.dob, serverDate)
                                          .years
                                      } Years,
                                       ${
                                         dateDifference(
                                           formData.dob,
                                           serverDate
                                         ).months
                                       } Months`}
                                      )
                                    </span>
                                  ) : null}
                                </div>
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                      <div className="label-grid">
                        <Label>Gender</Label>
                        <RequiredAutoComplete
                          className={formClasses.inputRoot}
                          options={Gender!}
                          value={
                            Gender?.find(
                              ({ value }) => value === formData.sex
                            )! ?? EMPTY_OPTIONSTYPE_OBJECT
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  sex: (newValue as optionsType)?.value!,
                                };
                              });
                            } else {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  sex: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (operation === Operation.UPDATE) {
                                if (statusInputRef) {
                                  statusInputRef.focus();
                                }
                              } else if (fresherInputRef) {
                                fresherInputRef.focus();
                              }
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  sex: "",
                                };
                              });
                            }
                          }}
                          openOnFocus
                          freeSolo={false}
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              onChange={(e) => {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    sex: e.target.value!,
                                  };
                                });
                              }}
                              fullWidth
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      </div>
                      {operation === Operation.UPDATE ? (
                        <>
                          <div className="label-grid">
                            <Label>Status</Label>
                            <FormAutocomplete
                              className={formClasses.inputRoot}
                              options={STUDENT_STATUS}
                              value={
                                STUDENT_STATUS?.find(
                                  ({ value }) => value === formData.std_status
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_status: (newValue as optionsType)
                                        ?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_status: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              ref={statusRef!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  studyingInputRef?.focus();
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      std_status: "",
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        std_status: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={formClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>

                          <div className="label-grid">
                            <Label>Studying</Label>
                            <FormAutocomplete
                              className={formClasses.inputRoot}
                              options={Studying!}
                              value={
                                Studying?.find(
                                  ({ value }) => value === formData.studying
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      studying: (newValue as optionsType)
                                        ?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      studying: EMPTY_STRING,
                                    };
                                  });
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  fresherInputRef?.focus();
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      studying: "",
                                    };
                                  });
                                }
                              }}
                              ref={studyingRef!}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        studying: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  className={formClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : null}
                      <div className="label-grid">
                        <Label>Fresher</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={Fresher}
                          value={
                            Fresher?.find(
                              ({ value }) => value === formData.fresher
                            )! ?? EMPTY_OPTIONSTYPE_OBJECT
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  fresher: (newValue as optionsType)?.value!,
                                };
                              });
                            } else {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  fresher: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          ref={fresherRef!}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (operation === Operation.UPDATE) {
                                dateOfAdmissionRef?.current?.focus();
                              } else if (USE_DEPARTMENT_KEY) {
                                departmentInputRef?.focus();
                              } else if (USE_BRANCH_KEY) {
                                branchInputRef?.focus();
                              } else if (USE_CLASS_KEY) {
                                classInputRef?.focus();
                              } else if (USE_SEMESTER_KEY) {
                                semesterInputRef?.focus();
                              } else if (USE_CATEGORY_KEY) {
                                categoryInputRef?.focus();
                              } else dateOfAdmissionRef?.current?.focus();
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  fresher: "",
                                };
                              });
                            }
                          }}
                          openOnFocus
                          freeSolo={false}
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    fresher: e.target.value!,
                                  };
                                });
                              }}
                              required
                              fullWidth
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      </div>
                      {USE_DEPARTMENT_KEY ? (
                        <div className="label-grid">
                          <Label>{departmentLabel}</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            options={departmentDropDown!}
                            ref={departmentRef!}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.dept_id) {
                                  if (USE_BRANCH_KEY) {
                                    branchInputRef?.focus();
                                  } else if (USE_CLASS_KEY) {
                                    classInputRef?.focus();
                                  } else if (USE_SEMESTER_KEY) {
                                    semesterInputRef?.focus();
                                  } else if (USE_CATEGORY_KEY) {
                                    categoryInputRef?.focus();
                                  } else dateOfAdmissionRef?.current?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    dept_id: 0,
                                    branch_id: 0,
                                    class_id: 0,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            openOnFocus
                            disabled={studentId !== "0" ? true : false}
                            value={
                              departmentDropDown?.find(
                                ({ value }) =>
                                  Number(value) === formData.dept_id
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    dept_id: Number(
                                      (newValue as responseType)?.value!
                                    ),
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    dept_id: 0,
                                    branch_id: 0,
                                    class_id: 0,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            popupIcon={
                              <img
                                src={DownArrow}
                                alt="/"
                                className="down-arrow-autocomplete"
                              />
                            }
                            renderInput={(params) => (
                              <TextField
                                required={
                                  !formData?.dept_id && USE_DEPARTMENT_KEY
                                }
                                {...params}
                                fullWidth
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                        </div>
                      ) : null}
                      {USE_BRANCH_KEY ? (
                        <div className="label-grid">
                          <Label>{branchLabel}</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            popupIcon={
                              <img
                                src={DownArrow}
                                alt="/"
                                className="down-arrow-autocomplete"
                              />
                            }
                            ref={branchRef!}
                            options={branchDropDown!}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.branch_id) {
                                  if (USE_CLASS_KEY) {
                                    classInputRef?.focus();
                                  } else if (USE_SEMESTER_KEY) {
                                    semesterInputRef?.focus();
                                  } else if (USE_CATEGORY_KEY) {
                                    categoryInputRef?.focus();
                                  } else dateOfAdmissionRef?.current?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    branch_id: 0,
                                    class_id: 0,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            openOnFocus
                            disabled={studentId !== "0" ? true : false}
                            value={
                              branchDropDown?.find(
                                ({ value }) => value === formData.branch_id
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    branch_id: (newValue as responseType)
                                      .value!,
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    branch_id: 0,
                                    class_id: 0,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required={
                                  !formData?.branch_id && USE_BRANCH_KEY
                                }
                                {...params}
                                fullWidth
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                        </div>
                      ) : null}
                      {USE_CLASS_KEY ? (
                        <div className="label-grid">
                          <Label>{classLabel}</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            popupIcon={
                              <img
                                src={DownArrow}
                                alt="/"
                                className="down-arrow-autocomplete"
                              />
                            }
                            options={classDropDown!}
                            ref={classRef!}
                            openOnFocus
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.class_id) {
                                  if (USE_SEMESTER_KEY) {
                                    semesterInputRef?.focus();
                                  } else if (USE_CATEGORY_KEY) {
                                    categoryInputRef?.focus();
                                  } else dateOfAdmissionRef?.current?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    class_id: 0,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            disabled={studentId !== "0" ? true : false}
                            value={
                              classDropDown?.find(
                                ({ value }) => value === formData.class_id
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    class_id: (newValue as responseType)
                                      ?.value!,
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    class_id: 0,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required={!formData?.class_id && USE_CLASS_KEY}
                                {...params}
                                className={formClasses.formControlRoot}
                                fullWidth
                              />
                            )}
                          />
                        </div>
                      ) : null}

                      {USE_SEMESTER_KEY ? (
                        <div className="label-grid">
                          <Label>{semesterLabel}</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            popupIcon={
                              <img
                                src={DownArrow}
                                alt="/"
                                className="down-arrow-autocomplete"
                              />
                            }
                            options={semesterDropDown!}
                            ref={semesterRef!}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.semester_id) {
                                  if (USE_CATEGORY_KEY) {
                                    categoryInputRef?.focus();
                                  } else dateOfAdmissionRef?.current?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            openOnFocus
                            disabled={studentId !== "0" ? true : false}
                            value={
                              semesterDropDown?.find(
                                ({ value }) => value === formData.semester_id
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    semester_id: (newValue as responseType)
                                      ?.value!,
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    semester_id: 0,
                                  };
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required={
                                  !formData?.semester_id && USE_SEMESTER_KEY
                                }
                                {...params}
                                fullWidth
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                        </div>
                      ) : null}
                      {operation === Operation.UPDATE ? (
                        <>
                          {USE_SECTION_KEY ? (
                            <div className="label-grid">
                              <Label>{sectionLabel}</Label>
                              <RequiredAutoComplete
                                className={formClasses.inputRoot}
                                popupIcon={
                                  <img
                                    src={DownArrow}
                                    alt="/"
                                    className="down-arrow-autocomplete"
                                  />
                                }
                                options={sectionDropDown!}
                                openOnFocus
                                disabled={studentId !== "0" ? true : false}
                                value={
                                  sectionDropDown?.find(
                                    ({ value }) => value === formData.section
                                  )! ?? null
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        section: (newValue as responseType)
                                          .value!,
                                      };
                                    });
                                  } else {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        section: 0,
                                      };
                                    });
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.BACKSPACE) {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        section: 0,
                                      };
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    required={
                                      !formData?.section_id && USE_SECTION_KEY
                                    }
                                    {...params}
                                    className={formClasses.formControlRoot}
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : null}
                      {USE_CATEGORY_KEY ? (
                        <div className="label-grid">
                          <Label>{categoryLabel}</Label>
                          <RequiredAutoComplete
                            className={formClasses.inputRoot}
                            popupIcon={
                              <img
                                src={DownArrow}
                                alt="/"
                                className="down-arrow-autocomplete"
                              />
                            }
                            options={categoryDropDown!}
                            ref={categoryRef!}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.category_id) {
                                  dateOfAdmissionRef?.current?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    category_id: 0,
                                  };
                                });
                              }
                            }}
                            openOnFocus
                            disabled={studentId !== "0" ? true : false}
                            value={
                              categoryDropDown?.find(
                                ({ value }) => value === formData.category_id
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    category_id: (newValue as responseType)
                                      .value!,
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    category_id: 0,
                                  };
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required={
                                  !formData?.category_id && USE_CATEGORY_KEY
                                }
                                {...params}
                                fullWidth
                                className={formClasses.formControlRoot}
                              />
                            )}
                          />
                        </div>
                      ) : null}

                      <div className="label-grid">
                        <Label>DOA</Label>
                        <InputStory
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER) {
                              imageRef?.current?.focus();
                            }
                          }}
                          value={formData.doa}
                          name="doa"
                          inputRef={dateOfAdmissionRef!}
                          max={toInputStandardDate(serverDate)}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleValueChange(e);
                            meta.handleChange(e);
                          }}
                          type="date"
                          required={formData.doa ? false : true}
                        />
                      </div>

                      {/* <div className="registration__data--logo-grid">
                        <Label>Upload Image</Label>
                        <InputStory
                          value={file?.name}
                          disabled
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleImagePreview(e)
                          }
                        />
                        <label className="student-documents__browse">
                          <img
                            src={FileAttach}
                            alt="/"
                            className="browse-icon"
                          />
                          Browse
                          <input
                            id="student-documents__browse--inputTag"
                            type="file"
                            className="assignment-creation__fileattach--input"
                            ref={imageRef!}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImagePreview(e)}
                            accept="image/png,image/jpeg"
                          />
                        </label>
                        <Button
                          mode="upload"
                          type="button"
                          onClick={() => {
                            if (file) {
                              handleUploadAndDownloadFile(
                                file,
                                filename,
                                setProgress,
                                false
                              );
                            }
                            if (!file) {
                              alert("Please Select A image ");
                            }
                          }}
                        />
                      </div>
                      {file ? (
                        <>
                          <img
                            src={
                              Operation.UPDATE === operation &&
                              formData.std_profile_filename !== EMPTY_STRING
                                ? getDownloadUrl(
                                    formData.std_profile_filename,
                                    setImageString,
                                    false
                                  )
                                : imageString
                            }
                            alt="/"
                            className="image-preview"
                          />
                          <div className="modal-flex__image">
                            <img
                              src={Close}
                              alt="/"
                              onClick={() => setFile(null)}
                            />
                          </div>
                          <div className="label-grid">
                            <Label>Uploading In Progress</Label>
                            <progress value={progress}></progress>
                          </div>
                        </>
                      ) : null}
                      {ComponentWarning.mediaSizeComponent} */}
                      {component}
                    </div>
                  </div>
                </div>

                <Button mode="save-continue" type="submit" />
                <Button
                  type="button"
                  onClick={() => setStudentEnquiryModal(!studentEnquiryModal)}>
                  <img src={Fetch} alt="/" />
                  Fetch Student Enquiries
                </Button>
                <Button
                  mode="clear"
                  type="button"
                  onClick={() => {
                    setFile(null);
                    handleClear();
                  }}
                />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    if (operation === Operation.UPDATE) {
                      navigate(
                        `/${InstId}/masters/studentlist/${studentId}/studentbasicdata`
                      );
                    } else {
                      navigate(`/${InstId}/admissions/students`);
                    }
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={creationLoading || updationLoading} />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentEnquiryModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentEnquiry
              pageType={PageFor.MODAL}
              setModalFlag={setStudentEnquiryModal}
              queryType={StdEnqQueryType.STD_ENQ_NOT_ADMITTED}
              shouldDispatchId={true}
            />
            <Button
              mode="cancel"
              onClick={() => setStudentEnquiryModal(!studentEnquiryModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentEnquiryModal(!studentEnquiryModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              queryType={StudentReportType.CURR_STUDENTS}
              studentListFor={StudentListFor.GENERAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
              isSibling={true}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Registration;
