import { ExcelReportPageType } from "../../../../utils/Enum.types";
import { VoucherQueryTypes } from "../../../Accounts/common/Enum.types";
import { StatesProvider } from "./GlobalStates/StatesProvider";
import MainPage from "./MainPage";
import { ExcelAdmQueryType } from "./useExportMutation";
export interface ExportProps {
  reportType: ExcelAdmQueryType | VoucherQueryTypes | string;
  pageType?: ExcelReportPageType;
  forVouchers?: boolean;
  forModal?: boolean;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({
  reportType,
  pageType,
  forModal,
  setModalFlag,
}: ExportProps) => {
  return (
    <StatesProvider>
      <MainPage
        reportType={reportType}
        pageType={pageType}
        forModal={forModal}
        setModalFlag={setModalFlag!}
      />
    </StatesProvider>
  );
};

export default Index;
