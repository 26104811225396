import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

import { useLazyQuery, useMutation } from "@apollo/client";

import EditProfile from "../../../images/EditProfile.svg";
import Close from "../../../images/Close.svg";
import Delete from "../../../images/Delete.svg";

import {
  Direction,
  LedgersForType,
  LicenseTypes,
  Operation,
  SortBy,
  TableHeaders,
  YesNo,
} from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { msgType } from "../../../utils/Form.types";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import { GetSwLeaveLdgrs } from "../../HR/queries/leaveledger/list";
import { DeleteSwLeaveLdgrById } from "../../HR/queries/leaveledger/mutation";
import { Title } from "../../../stories/Title/Title";
import { HRTitleProps } from "../../../Types/Titles";
import { Button } from "../../../stories/Button/Button";
import { AddLedgerModalStyles } from "../../../styles/ModalStyles";
import NewLeaveLedger from "../../HR/Payroll/LeaveLedger/NewLeaveLedger";
import MessageModal from "../../../pages/MessageModal";
import DeleteModal from "../../../pages/DeleteModal";
import Home from "../Home/Index";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { TableHeaderProps } from "../../../utils/types";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import useHRtable from "../../HR/json/useHRtable";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";

const { HRTitles } = require("../../HR/json/title.json");
export interface GetSwLeaveLdgrsDetails {
  id: number;
  leave_desc: string;
  leave_idx: number;
  leave_type: string;
  leave_short_code: string;
  leave_is_system_defined: boolean;
  leave_is_ldgr_active: boolean;
  leave_is_earned: boolean;
  leave_only_for_women: boolean;
  leave_is_credited: boolean;
  leave_carry_forwarded: boolean;
  leave_for_salary_not_used: boolean;
  is_lwp_ldgr: boolean;
}

export interface GetSwLeaveLdgrsData {
  GetSwLeaveLdgrs: GetSwLeaveLdgrsDetails[];
}
export interface GetSwLeaveLdgrsVars {
  token: string;
}

const Index = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [deleteModal, setDeleteModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const { HR_Table } = useHRtable();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [id, setId] = useState(0);
  //useStates
  const [addLeaveLedger, setAddLeaveLedger] = useState(false);

  const [GetSwLeaveLedgers, { data, error }] = useLazyQuery<
    GetSwLeaveLdgrsData,
    GetSwLeaveLdgrsVars
  >(GetSwLeaveLdgrs, {
    variables: {
      token,
    },
  });

  const [DeleteLeave] = useMutation(DeleteSwLeaveLdgrById, {
    onError: (e) => {
      setDeleteModal(!deleteModal);
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      });
    },
  });

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handleDelete = (id: number) => {
    DeleteLeave({
      variables: {
        token,
        id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwLeaveLdgrs,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.LEAVE_DESC,
            name: "",
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDeleteModal(!deleteModal);
        setMessage({
          flag: true,
          message: "Successfully deleted leave",
          operation: Operation.DELETE,
        });
      }
    });
  };

  useEffect(() => {
    if (token) {
      GetSwLeaveLedgers();
    }
  }, [token, GetSwLeaveLedgers]);

  const dynamicHeaders: TableHeaderProps[] = HR_Table.LeaveLedger.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      headerName: TableHeaders.ACTION,
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.is_lwp_ldgr ? null : (
              <>
                <img
                  src={EditProfile}
                  alt="/"
                  onClick={() => {
                    setId(params.row.leaveLedgerId);
                    setOperation(Operation.UPDATE);
                    setAddLeaveLedger(!addLeaveLedger);
                  }}
                />
                <img
                  src={Delete}
                  alt="/"
                  onClick={() => {
                    setId(params.row.leaveLedgerId);
                    setDeleteModal(!deleteModal);
                  }}
                />
              </>
            )}
          </>
        );
      },
    },
  ];
  const rows: GridValidRowModel[] =
    (data?.GetSwLeaveLdgrs || []).map((response, index) => {
      return {
        id: index + 1,
        description: response.leave_desc,
        short_code: response.leave_short_code,
        leave_type: response.leave_type,
        only_for_women: response.leave_only_for_women ? YesNo.YES : YesNo.NO,
        is_credited: response.leave_is_credited ? YesNo.YES : YesNo.NO,
        leave_carry_forwarded: response.leave_carry_forwarded
          ? YesNo.YES
          : YesNo.NO,
        leave_is_earned: response.leave_is_earned ? YesNo.YES : YesNo.NO,
        is_lwp_ldgr: response.is_lwp_ldgr,
      };
    }) || [];

  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>
        {HRTitles.MasterData.LeaveLedger.Titles.map(
          (title: HRTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.Eduate_Title}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="payroll-masterdata__leave-ledger">
        <div className="row g-0">
          <div className="col"></div>

          <div className="col-1">
            <Button
              mode="addnew"
              className="payroll-masterdata__leave-ledger--button"
              autoFocus
              onClick={() => setAddLeaveLedger(!addLeaveLedger)}
            />
          </div>
        </div>
        <div className="payroll-masterdata__leave-ledger--tableblock">
          {error ? (
            <b className="nodata">{error?.message}</b>
          ) : data?.GetSwLeaveLdgrs?.length ? (
            <StyledDatagrid
              columns={columns}
              rows={rows}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
            />
          ) : (
            <b className="nodata">No data please try adding...</b>
          )}
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addLeaveLedger}
        style={AddLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NewLeaveLedger
              operation={operation}
              setModalFlag={setAddLeaveLedger}
              modalFlag={addLeaveLedger}
              eduateOrHr={LedgersForType.EDUATE}
              id={id}
              setId={setId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddLeaveLedger(!addLeaveLedger)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        id={id}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Index;
