import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import Avatar from "../../../../images/Avatar.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import { Button } from "../../../../stories/Button/Button";
import {
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  StudentAcctReportType,
  StudentListFor,
} from "../../../../utils/Enum.types";
import { useNavigate } from "react-router-dom";
import View from "./View";
import Close from "../../../../images/Close.svg";
import DownloadFiles from "../../../../images/DocumentsDownload.svg";
import Edit from "../../../../images/Edit.svg";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import {
  EMPTY_STRING,
  LateFeePayment,
  NumberOfAdmissionNumberToBeDisplayed,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  formatter,
  getFileName,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { DEFAULT_TIME } from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import { Keys } from "../../../../utils/Enum.keys";
import { payloadTypes } from "../../../../context/reducer";
import { msgType, responseType } from "../../../../utils/Form.types";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { GetStudentDocsByStudentId } from "../../../../queries/students/list/byId";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import StudentList from "../../common/StudentList";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { LateFeePermission } from "../../queries/latefeepayment/mutation";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useUploadComponent from "../../../../customhooks/useUploadComponent";
import {
  GetStudentDocsByStudentIdData,
  GetStudentDocsByStudentIdVars,
} from "../../../Master/Student/Documents/Index";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import TextArea from "../../../../stories/TextArea/TextArea";
import { Label } from "../../../../stories/Label/Label";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
const PermissionLetter = () => {
  // usestates
  const [studentModal, setStudentModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [admNo, setAdmNo] = useState("");
  const [remarks, setRemarks] = useState("");
  const [dueDate, setDueDate] = useState(TODAY_DATE);
  const [permissionDesc, setPermissionDesc] = useState("");
  // eslint-disable-next-line
  const [imageString, setImageString] = useState("");
  const [profileImage, setProfileImage] = useState("");

  // params
  const navigate = useNavigate();

  // context
  const { state, dispatch } = useContext(AppContext);
  const { format } = formatter;

  // hooks
  const { token } = useToken();

  const { studentData, studentFormData } = useStudentDatabyId();

  const { InstDetails } = useInstDetails(1);

  const { user_details } = useLoggedInUserDetails();

  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.DEMAND_RAISED
  );
  const {
    USE_CATEGORY_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const [GetDocuments, { data }] = useLazyQuery<
    GetStudentDocsByStudentIdData,
    GetStudentDocsByStudentIdVars
  >(GetStudentDocsByStudentId);

  // mutations
  const [LateFee, { loading }] = useMutation(LateFeePermission, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const { component, file, setFile, uploadFile } = useUploadComponent(
    `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/permission_letters/${permissionDesc}`,
    Operation.CREATE,
    EMPTY_STRING,
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE
  );
  // functions
  const handleClose = () => {
    setImageString("");
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });
    setFile(null);
    setRemarks("");
    setDueDate(TODAY_DATE);
    setMessage({ flag: false, message: "", operation: Operation.NONE });
  };

  const handleLateFeepayment = async () => {
    if (state.studentId) {
      try {
        const uploadResult = await uploadFile();
        if (uploadResult) {
          LateFee({
            variables: {
              token,
              student_id: state.studentId,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              input: {
                new_due_date: toIsoDate(dueDate),
                remarks,
                upload_image: {
                  std_doc_type: LateFeePayment,
                  std_doc_filename: `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/permission_letters/${permissionDesc}`,
                  std_doc_download: false,
                  std_doc_collected: true,
                  std_doc_original: false,
                  date_of_collection: toIsoDate(TODAY_DATE),
                  std_doc_remarks: EMPTY_STRING,
                },
              },
              inst_id: state.InstId,
              user_details,
            },
            refetchQueries: [
              {
                query: GetStudentDocsByStudentId,
                variables: {
                  student_id: state.studentId,
                  token,
                },
              },
            ],
          }).then(({ data }) => {
            if (data) {
              setMessage({
                flag: true,
                message: "Uploaded Permission Letter",
                operation: Operation.CREATE,
              });
            }
          });
        } else {
          setMessage({
            flag: true,
            message: "Image upload failed",
            operation: Operation.NONE,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMessage({
        flag: true,
        message: `Student not found`,
        operation: Operation.NONE,
      });
    }
  };
  useEffect(() => {
    if (state.studentId) {
      GetDocuments({
        variables: {
          student_id: state.studentId,
          token,
        },
      });
    }
  }, [state.studentId, GetDocuments, token]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setProfileImage);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Permission Letter for Late Fee Payment</Title>
      <div className="late-fee-payment">
        <div className="late-fee-payment__details row g-0">
          <div className="col">
            <div className="late-fee-payment__details--image-flex">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={studentAddmissionNumber}
                value={
                  studentAddmissionNumber?.find(({ label }) =>
                    studentData.data
                      ? label === studentData.data?.nodes[0].std_adm_no
                      : label === admNo
                  )!
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setAdmNo("");
                  }
                }}
                disabled={state.studentId ? true : false}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission Number"
                    autoFocus
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              className="late-fee-payment__details--textfield"
              label="Reg No."
              value={studentFormData.reg_number}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="late-fee-payment__details--textfield"
              label={classLabel}
              value={studentFormData.class}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="late-fee-payment__details--textfield"
              label="Name"
              value={studentFormData.std_name}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            {USE_DEPARTMENT_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={`${departmentLabel}`}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.dept}
                disabled
              />
            )}
            <TextField
              className="late-fee-payment__details--textfield"
              label={branchLabel}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.branch}
              disabled
            />
          </div>
          <div className="col">
            {USE_SEMESTER_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={semesterLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.semester}
                disabled
              />
            )}
            {USE_SECTION_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={sectionLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.section}
                disabled
              />
            )}
            {USE_CATEGORY_KEY && (
              <TextField
                className="late-fee-payment__details--textfield"
                label={categoryLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.category}
                disabled
              />
            )}
          </div>
          <div className="col-1 late-fee-payment__details--image">
            <img src={profileImage ? profileImage : Avatar} alt="/" />
          </div>
        </div>

        <div className="late-fee-payment__details--additional row g-0">
          <div className="col">
            <TextField
              className="late-fee-payment__details--textfield"
              label="Previous Permission Date"
              value={
                studentData.data &&
                new Date(
                  studentData.data?.nodes[0].acct_std_demand.new_due_date
                ).getTime() === new Date(DEFAULT_TIME).getTime()
                  ? EMPTY_STRING
                  : toStandardDate(
                      studentData.data?.nodes[0].acct_std_demand.new_due_date!
                    )
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="late-fee-payment__details--textfield--date"
              label="New Due Date"
              value={toInputStandardDate(dueDate)}
              onChange={(e) => setDueDate(e.target.value)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    min: TODAY_DATE,
                  },
                },
              }}
              type="date"
            />
            <TextField
              className="late-fee-payment__details--textfield"
              label="Permission Desc."
              value={permissionDesc}
              onChange={(e) => setPermissionDesc(e.target.value)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </div>
          <div className="col">
            <TextField
              className="late-fee-payment__details--textfield"
              label="Letter Ref No."
              value={file?.name}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <div className="late-fee-payment__details--textfield--label-grid">
              <Label>Remarks</Label>
              <TextArea rows={3} onChange={(e) => setRemarks(e.target.value)} />
            </div>
          </div>
          <div className="col">
            <TextField
              className="late-fee-payment__details--textfield--balance"
              label="Balance Amount"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={format(
                studentData.data
                  ? studentData.data?.nodes[0].acct_std_demand.std_demand_bal
                  : 0
              )}
            />

            {component}
          </div>
          <div className="col-1">
            <TextField
              className="late-fee-payment__details--textfield"
              label="No. of Letters"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                studentData.data?.nodes[0].acct_std_demand.no_of_letters ?? 0
              }
              disabled
            />
          </div>
        </div>
        <div className="row g-0 late-fee-payment__tableblock">
          <div className="col-5 h-100">
            <TableContainer className="late-fee-payment__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="late-fee-payment__table--groupheader">
                    <TableCell colSpan={3}>List of Letters</TableCell>
                  </TableRow>
                  <TableRow className="late-fee-payment__table--subheader">
                    <TableCell>Sl</TableCell>
                    <TableCell>Ack. Name</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.studentId
                    ? data?.GetStudentDocsByStudentId.filter(
                        (d) => d.std_doc_type === LateFeePayment
                      ).map((doc, index) => {
                        return (
                          <TableRow key={doc.id}>
                            <TableCell id="td-center">{index + 1}</TableCell>
                            <TableCell>
                              {getFileName(doc.std_doc_filename)}
                            </TableCell>
                            <TableCell>
                              {toStandardDate(
                                studentData.data
                                  ? studentData.data.nodes[0].acct_std_demand
                                      .new_due_date!
                                  : EMPTY_STRING
                              )}
                            </TableCell>
                            <TableCell
                              className="student-documents__table--preview"
                              id="td-center">
                              <img
                                src={DownloadFiles}
                                alt="/"
                                onClick={() => {
                                  setImageString("");
                                  getDownloadUrl(
                                    doc.std_doc_filename,
                                    true,
                                    setImageString
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <Button mode="save" disabled={!file} onClick={handleLateFeepayment} />
        <Button mode="view" onClick={() => setViewModal(!viewModal)} />
        <Button mode="clear" onClick={handleClose} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <>
              <StudentList
                pageType={PageFor.MODAL}
                studentListFor={StudentListFor.ACCOUNTS}
                queryType={StudentAcctReportType.DEMAND_RAISED}
                setStudentModal={setStudentModal}
              />
            </>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* viewModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <>
              <View />
              <Button mode="cancel" onClick={() => setViewModal(!viewModal)} />
            </>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setViewModal(!viewModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default PermissionLetter;
