import { useEffect, useState } from "react";
import useSwConfigData from "../../../../../../customhooks/useSwConfigData";
import { InstitutionPrintConfig } from "../../../../Enum.types";
const usePrintConfig = () => {
  const [copies, setCopies] = useState(0);
  const {
    configData: { data: AdmissionNumber },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_ADMISSION_NUMBER);
  const {
    configData: { data: CashDetails },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_CASH_BANK_DETAILS);
  const {
    configData: { data: ClassDetails },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_CLASS_NAME);
  const {
    configData: { data: CustomerName },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_CUSTOMER_NAME);
  const {
    configData: { data: FatherName },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_FATHER_NAME);
  const {
    configData: { data: FeeBalance },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_FEE_BALANCE);
  const {
    configData: { data: Logo },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_INST_LOGO);
  const {
    configData: { data: InstName },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_INST_NAME);
  const {
    configData: { data: InstAdress },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_INST_ADDRESS);
  const {
    configData: { data: PrintNarrations },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_NARRATIONS);
  const {
    configData: { data: PrintRegNo },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_REGISTER_NUMBER);
  const {
    configData: { data: SectionName },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_SECTION_NAME);

  const {
    configData: { data: ReceiptHeader },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_RECEIPT_HEADER);

  const {
    configData: { data: ReceiptFooter },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_RECEIPT_FOOTER);
  const {
    configData: { data: transactionNumber },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_TRANSACTION_NUMBER);
  const {
    configData: { data: ReceiptFooter2 },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_RECEIPT_FOOTER_2);
  const {
    configData: { data: NoOfCopies, loading },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_NUMBER_COPIES);

  const {
    configData: { data: PrintCommonTitle },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_COMMON_TITLE);

  const {
    configData: { data: EnableInstStdLabel },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_INST_STD_LABEL);
  const {
    configData: { data: PrintInstOrBranchLabel },
  } = useSwConfigData(InstitutionPrintConfig.PRINT_INST_OR_BRANCH_NAME);

  useEffect(() => {
    if (NoOfCopies && !loading) {
      const { config_string_value } = NoOfCopies.GetSwConfigVariables[0];
      setCopies(Number(config_string_value));
    }
  }, [NoOfCopies, loading]);

  return {
    USE_ADM_NO:
      AdmissionNumber &&
      AdmissionNumber.GetSwConfigVariables.length &&
      AdmissionNumber.GetSwConfigVariables[0].config_boolean_value,
    USE_CASH_DETAILS:
      CashDetails &&
      CashDetails.GetSwConfigVariables.length &&
      CashDetails.GetSwConfigVariables[0].config_boolean_value,
    USE_CLASS_NAME:
      ClassDetails &&
      ClassDetails.GetSwConfigVariables.length &&
      ClassDetails.GetSwConfigVariables[0].config_boolean_value,
    USE_CUSTOMER_NAME:
      CustomerName &&
      CustomerName.GetSwConfigVariables.length &&
      CustomerName.GetSwConfigVariables[0].config_boolean_value,
    USE_FATHER_NAME:
      FatherName &&
      FatherName.GetSwConfigVariables.length &&
      FatherName.GetSwConfigVariables[0].config_boolean_value,
    USE_FEE_BALANCE:
      FeeBalance &&
      FeeBalance.GetSwConfigVariables.length &&
      FeeBalance.GetSwConfigVariables[0].config_boolean_value,
    USE_LOGO:
      Logo &&
      Logo.GetSwConfigVariables.length &&
      Logo.GetSwConfigVariables[0].config_boolean_value,
    USE_INST_NAME:
      InstName &&
      InstName.GetSwConfigVariables.length &&
      InstName.GetSwConfigVariables[0].config_boolean_value,
    USE_INST_STD_LABEL:
      EnableInstStdLabel &&
      EnableInstStdLabel.GetSwConfigVariables.length &&
      EnableInstStdLabel.GetSwConfigVariables[0].config_boolean_value,
    USE_INST_ADDRESS:
      InstAdress &&
      InstAdress.GetSwConfigVariables.length &&
      InstAdress.GetSwConfigVariables[0].config_boolean_value,
    USE_NARRATIONS:
      PrintNarrations &&
      PrintNarrations.GetSwConfigVariables.length &&
      PrintNarrations.GetSwConfigVariables[0].config_boolean_value,
    USE_REG_NO:
      PrintRegNo &&
      PrintRegNo.GetSwConfigVariables.length &&
      PrintRegNo.GetSwConfigVariables[0].config_boolean_value,
    USE_SECTION_NAME:
      SectionName &&
      SectionName.GetSwConfigVariables.length &&
      SectionName.GetSwConfigVariables[0].config_boolean_value,

    USE_ENABLE_COMMON_TITLE:
      PrintCommonTitle &&
      PrintCommonTitle.GetSwConfigVariables.length &&
      PrintCommonTitle.GetSwConfigVariables[0].config_boolean_value,
    USE_PRINT_COMMON_TITLE:
      PrintCommonTitle &&
      PrintCommonTitle.GetSwConfigVariables.length &&
      PrintCommonTitle.GetSwConfigVariables[0]?.config_string_value,
    USE_RECEIPT_HEADER:
      ReceiptHeader &&
      ReceiptHeader.GetSwConfigVariables.length &&
      ReceiptHeader.GetSwConfigVariables[0].config_string_value,
    USE_RECEIPT_FOOTER:
      ReceiptFooter &&
      ReceiptFooter.GetSwConfigVariables.length &&
      ReceiptFooter.GetSwConfigVariables[0].config_string_value,
    USE_TRANSACTION_NUMBER:
      transactionNumber &&
      transactionNumber.GetSwConfigVariables.length &&
      transactionNumber.GetSwConfigVariables[0].config_boolean_value,
    USE_RECEIPT_FOOTER_2:
      ReceiptFooter2 &&
      ReceiptFooter2.GetSwConfigVariables.length &&
      ReceiptFooter2.GetSwConfigVariables[0].config_string_value,
    USE_NO_COPIES: copies,
    PRINT_INST_OR_BRANCH_NAME:
      PrintInstOrBranchLabel &&
      PrintInstOrBranchLabel.GetSwConfigVariables.length &&
      PrintInstOrBranchLabel.GetSwConfigVariables[0].config_string_value,
  };
};
export default usePrintConfig;
