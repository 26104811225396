import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  AddAcdSemesterYr,
  UpdateAcdSemesterYr,
} from "../../../../queries/institution/mutations/update";
import React, { useContext, useMemo, useState } from "react";
import { msgType, responseType } from "../../../../utils/Form.types";
import { Button } from "../../../../stories/Button/Button";
import InstitutionTabs from "../../routes/InstitutionTabs";
import { Operation, TableHeaders } from "../../../../utils/Enum.types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import Add from "../../../../images/Add.svg";
import Update from "../../../../images/Update.svg";

import Modal from "react-modal";
import Close from "../../../../images/Close.svg";

import LoadingModal from "../../../../pages/LoadingModal";
import useToken from "../../../../customhooks/useToken";
import { CategoryList } from "../../../../Types/Student";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { Keys } from "../../../../utils/Enum.keys";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  AssignComponentProp,
  TreeType,
} from "../../../Academics/DailyActivities/Home";
import useMstInstTreeByQueryType from "../../../../customhooks/general/useMstInstTreeByQueryType";
import { EditModalCustomStyles } from "../../../../styles/ModalStyles";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import { HYPHEN } from "../../../HR/constants";
import MessageModal from "../../../../pages/MessageModal";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import { GetAcdInstTree } from "../../../../queries/common";
import { InstTreeQueryType } from "../../../HR/Types/masterDataTypes";
import { AppContext } from "../../../../context/context";

const SemAcdConfig = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  // eslint-disable-next-line
  const [item, setitems] = useState<CategoryList[]>([]);
  //flags for sucess model
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const { activeAcademicYearData } = useActiveAcademicYear();

  const [classSelected, setClassSelected] = useState<responseType | null>(null);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [configurationModal, setConfigurationModal] = useState(false);
  const [semId, setSemId] = useState(0);
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    0
  );

  const { data, loading } = useMstInstTreeByQueryType(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    0,
    toIsoDate(TODAY_DATE),
    0
  );

  const { departmentLabel, branchLabel, classLabel } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const [NewSemConfig, { loading: creationLoading }] = useMutation(
    AddAcdSemesterYr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateSemConfig, { loading: updateLoading }] = useMutation(
    UpdateAcdSemesterYr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }

    return final + 1;
  }, [USE_DEPARTMENT_KEY, USE_BRANCH_KEY, USE_CLASS_KEY, USE_SEMESTER_KEY]);
  const HandleNewSemCOnfig = () => {
    if (operation === Operation.CREATE) {
      NewSemConfig({
        variables: {
          token,
          user_details,
          inst_id: InstId!,
          acd_yr_id:
            activeAcademicYearData &&
            activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
          semester_ids: [semId],
          input: {
            acd_sem_st_date: toIsoDate(startDate),
            acd_sem_end_date: toIsoDate(endDate),
          },
        },
        refetchQueries: [
          {
            query: GetAcdInstTree,
            variables: {
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(TODAY_DATE),
              id: 0,
              inst_id: InstId ? InstId : 0,
              query_type: InstTreeQueryType.TREE_BY_INST_ID,
              testNameId: 0,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Sem Configuration Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      updateSemConfig({
        variables: {
          token,
          user_details,
          inst_id: InstId!,
          acd_yr_id:
            activeAcademicYearData &&
            activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
          acd_semester_id: semId,
          input: {
            acd_sem_st_date: toIsoDate(startDate),
            acd_sem_end_date: toIsoDate(endDate),
          },
        },
        refetchQueries: [
          {
            query: GetAcdInstTree,
            variables: {
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              date_of_attendance: toIsoDate(TODAY_DATE),
              id: 0,
              inst_id: InstId ? InstId : 0,
              query_type: InstTreeQueryType.TREE_BY_INST_ID,
              testNameId: 0,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Sem Configuration Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const AssignComponent = ({
    id,
    start_date,
    end_date,
  }: AssignComponentProp) => (
    <>
      {start_date &&
      end_date &&
      toStandardDate(start_date) !== toStandardDate(DEFAULT_TIME) ? (
        <button
          className="daily-activities__home--table--view-button"
          onClick={() => {
            setConfigurationModal(!configurationModal);
            setSemId(id);
            setOperation(Operation.UPDATE);
            setStartDate(toInputStandardDate(start_date));
            setEndDate(toInputStandardDate(end_date));
          }}
        >
          Update <img src={Update} alt="" />
        </button>
      ) : (
        <button
          className="daily-activities__home--table--view-button"
          onClick={() => {
            setConfigurationModal(!configurationModal);
            setSemId(id);
            setOperation(Operation.CREATE);
            setStartDate(EMPTY_STRING);
            setEndDate(EMPTY_STRING);
          }}
        >
          Add <img src={Add} alt="" />
        </button>
      )}
    </>
  );
  let idsSet = new Set();

  const { semesterLabel } = useInstLabels();

  const modifiedArrayForTable: TreeType[] = data
    ? data.GetAcdInstTree.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.flatMap((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          filterThis: false,
                          sem_start_date_level_1: level1.sem_start_date,
                          sem_end_date_level_1: level1.sem_end_date,
                          sem_start_date_level_2: level2.sem_start_date,
                          sem_end_date_level_2: level2.sem_end_date,
                          sem_start_date_level_3: level3.sem_start_date,
                          sem_end_date_level_3: level3.sem_end_date,
                          sem_start_date_level_4: level4.sem_start_date,
                          sem_end_date_level_4: level4.sem_end_date,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count,
                        level5_name: EMPTY_STRING,
                        level1_id: level1.acd_level_1_id,
                        level2_id: level2.acd_level_2_id,
                        level3_id: level3.acd_level_3_id,
                        level4_id: level4.acd_level_4_id,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),
                        sem_start_date_level_1: level1.sem_start_date,
                        sem_end_date_level_1: level1.sem_end_date,
                        sem_start_date_level_2: level2.sem_start_date,
                        sem_end_date_level_2: level2.sem_end_date,
                        sem_start_date_level_3: level3.sem_start_date,
                        sem_end_date_level_3: level3.sem_end_date,
                        sem_start_date_level_4: level4.sem_start_date,
                        sem_end_date_level_4: level4.sem_end_date,
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level5_name: EMPTY_STRING,
                    level1_id: level1.acd_level_1_id,
                    level2_id: level2.acd_level_2_id,
                    level3_id: level3.acd_level_3_id,
                    level4_id: 0,
                    level5_id: 0,

                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                    sem_start_date_level_1: level1.sem_start_date,
                    sem_end_date_level_1: level1.sem_end_date,
                    sem_start_date_level_2: level2.sem_start_date,
                    sem_end_date_level_2: level2.sem_end_date,
                    sem_start_date_level_3: level3.sem_start_date,
                    sem_end_date_level_3: level3.sem_end_date,
                    sem_start_date_level_4: EMPTY_STRING,
                    sem_end_date_level_4: EMPTY_STRING,
                  };

                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level5_name: EMPTY_STRING,
                level1_id: level1.acd_level_1_id,
                level2_id: level2.acd_level_2_id,
                level3_id: 0,
                level4_id: 0,
                level5_id: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                sem_start_date_level_1: level1.sem_start_date,
                sem_end_date_level_1: level1.sem_end_date,
                sem_start_date_level_2: level2.sem_start_date,
                sem_end_date_level_2: level2.sem_end_date,
                sem_start_date_level_3: EMPTY_STRING,
                sem_end_date_level_3: EMPTY_STRING,
                sem_start_date_level_4: EMPTY_STRING,
                sem_end_date_level_4: EMPTY_STRING,
              };

              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level5_name: EMPTY_STRING,
            level1_id: level1.acd_level_1_id,
            level2_id: 0,
            level3_id: 0,
            level4_id: 0,
            level5_id: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
            sem_start_date_level_1: level1.sem_start_date,
            sem_end_date_level_1: level1.sem_end_date,
            sem_start_date_level_2: EMPTY_STRING,
            sem_end_date_level_2: EMPTY_STRING,
            sem_start_date_level_3: EMPTY_STRING,
            sem_end_date_level_3: EMPTY_STRING,
            sem_start_date_level_4: EMPTY_STRING,
            sem_end_date_level_4: EMPTY_STRING,
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];
  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setConfigurationModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <InstitutionTabs />

      <div className="row g-0">
        <div className="col">
          <Title>{semesterLabel}</Title>
        </div>
      </div>

      <div className="masterconfig">
        <div className="row g-0 masterconfig__details frame-space">
          <div className="col h-100 booktype-left">
            <div className="sem-config">
              <div className="row g-0 sem-config__select">
                {USE_DEPARTMENT_KEY ? (
                  <div className="col-2">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={departmentDropDown}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          departmentSelected
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (departmentSelected) {
                            handleMUISelectEvent(e);
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setDepartmentSelected(null);
                        }
                      }}
                      openOnFocus
                      value={departmentSelected}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setDepartmentSelected(newValue as responseType);
                        } else {
                          setDepartmentSelected(null);
                        }
                        setBranchSelected(null);
                        setClassSelected(null);
                        setSemesterSelected(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={departmentLabel}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          id="outlined Departments"
                          className={labelClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                ) : null}
                {USE_BRANCH_KEY ? (
                  <div className="col-2">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={branchDropDown}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          branchSelected
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (branchSelected) {
                            handleMUISelectEvent(e);
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setBranchSelected(null);
                        }
                      }}
                      openOnFocus
                      value={branchSelected}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setBranchSelected(newValue as responseType);
                        } else {
                          setBranchSelected(null);
                        }
                        setClassSelected(null);
                        setSemesterSelected(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={branchLabel}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          className={labelClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                ) : null}
                {USE_CLASS_KEY ? (
                  <div className="col-2">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={classDropDown}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          classSelected
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (classSelected) {
                            handleMUISelectEvent(e);
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setClassSelected(null);
                        }
                      }}
                      openOnFocus
                      value={classSelected}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setClassSelected(newValue as responseType);
                        } else {
                          setClassSelected(null);
                        }
                        setSemesterSelected(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={classLabel}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          className={labelClasses.formControlRoot}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                ) : null}
                {USE_SEMESTER_KEY ? (
                  <div className="col-2">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={semesterDropDown}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          semesterSelected
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (semesterSelected) {
                            handleMUISelectEvent(e);
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setSemesterSelected(null);
                        }
                      }}
                      openOnFocus
                      value={semesterSelected}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSemesterSelected(newValue as responseType);
                        } else {
                          setSemesterSelected(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={semesterLabel}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          fullWidth
                          className={labelClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                ) : null}
              </div>
              <div className="sem-config__tableblock">
                <TableContainer className="sem-config__table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {USE_DEPARTMENT_KEY ? (
                          <TableCell>{departmentLabel}</TableCell>
                        ) : null}
                        {USE_BRANCH_KEY ? (
                          <TableCell>{branchLabel}</TableCell>
                        ) : null}
                        {USE_CLASS_KEY ? (
                          <TableCell>{classLabel}</TableCell>
                        ) : null}
                        {USE_SEMESTER_KEY ? (
                          <TableCell>{semesterLabel}</TableCell>
                        ) : null}
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>{TableHeaders.ACTION}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                        if (data) {
                          return (
                            <TableRow key={i}>
                              {data.level1_name !== EMPTY_STRING ? (
                                <TableCell rowSpan={data.level1_rowspan}>
                                  {data.level1_name}
                                </TableCell>
                              ) : null}
                              {data &&
                              (data.level2_name ||
                                data.level2_name !== EMPTY_STRING) ? (
                                <TableCell rowSpan={data.level2_rowspan}>
                                  {data.level2_name}
                                </TableCell>
                              ) : null}
                              {data && data.level3_name !== EMPTY_STRING ? (
                                <TableCell rowSpan={data.level3_span}>
                                  {data.level3_name}
                                </TableCell>
                              ) : null}
                              {data && data.level4_name !== EMPTY_STRING ? (
                                <TableCell rowSpan={data.level4_span}>
                                  {data.level4_name}
                                </TableCell>
                              ) : null}
                              <TableCell
                                className="lesson-planner__table--total"
                                id="td-center"
                              >
                                {last_level - 1 === 1 &&
                                data.level1_name &&
                                data.sem_start_date_level_1
                                  ? toStandardDate(
                                      data.sem_start_date_level_1
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(
                                        data.sem_start_date_level_1
                                      )
                                    : HYPHEN
                                  : last_level - 1 === 2 &&
                                    data.level2_name &&
                                    data.sem_start_date_level_2
                                  ? toStandardDate(
                                      data.sem_start_date_level_2
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(
                                        data.sem_start_date_level_2
                                      )
                                    : HYPHEN
                                  : last_level - 1 === 3 &&
                                    data.level3_name &&
                                    data.sem_start_date_level_3
                                  ? toStandardDate(
                                      data.sem_start_date_level_3
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(
                                        data.sem_start_date_level_3
                                      )
                                    : HYPHEN
                                  : last_level - 1 === 4 &&
                                    data.level4_name &&
                                    data.sem_start_date_level_4
                                  ? toStandardDate(
                                      data.sem_start_date_level_4
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(
                                        data.sem_start_date_level_4
                                      )
                                    : HYPHEN
                                  : HYPHEN}
                              </TableCell>

                              <TableCell
                                className="lesson-planner__table--total "
                                id="td-center"
                              >
                                {last_level - 1 === 1 &&
                                data.level1_name &&
                                data.sem_end_date_level_1
                                  ? toStandardDate(
                                      data.sem_end_date_level_1
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(data.sem_end_date_level_1)
                                    : HYPHEN
                                  : last_level - 1 === 2 &&
                                    data.level2_name &&
                                    data.sem_end_date_level_2
                                  ? toStandardDate(
                                      data.sem_end_date_level_2
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(data.sem_end_date_level_2)
                                    : HYPHEN
                                  : last_level - 1 === 3 &&
                                    data.level3_name &&
                                    data.sem_end_date_level_3
                                  ? toStandardDate(
                                      data.sem_end_date_level_3
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(data.sem_end_date_level_3)
                                    : HYPHEN
                                  : last_level - 1 === 4 &&
                                    data.level4_name &&
                                    data.sem_end_date_level_4
                                  ? toStandardDate(
                                      data.sem_end_date_level_4
                                    ) !== toStandardDate(DEFAULT_TIME)
                                    ? toStandardDate(data.sem_end_date_level_4)
                                    : HYPHEN
                                  : HYPHEN}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="daily-activities__home--table--actions"
                              >
                                {last_level - 1 === 1 && data.level1_name && (
                                  <AssignComponent
                                    id={data.level1_id}
                                    start_date={data.sem_start_date_level_1}
                                    end_date={data.sem_end_date_level_1}
                                  />
                                )}
                                {last_level - 1 === 2 && data.level2_name && (
                                  <AssignComponent
                                    id={data.level2_id}
                                    start_date={data.sem_start_date_level_2}
                                    end_date={data.sem_end_date_level_2}
                                  />
                                )}
                                {last_level - 1 === 3 && data.level3_name && (
                                  <AssignComponent
                                    id={data.level3_id}
                                    start_date={data.sem_start_date_level_3}
                                    end_date={data.sem_end_date_level_3}
                                  />
                                )}
                                {last_level - 1 === 4 && data.level4_name && (
                                  <AssignComponent
                                    id={data.level4_id}
                                    start_date={data.sem_start_date_level_4}
                                    end_date={data.sem_end_date_level_4}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return null;
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="masterconfig__buttons">
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>

      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1">
              {operation === Operation.CREATE
                ? "Add Semester Date"
                : "Update Semester Date"}
            </Title>
            <div className="row g-0">
              <div className="col-3">
                <TextField
                  value={toInputStandardDate(startDate)}
                  className="vacation-entry__block--textfield"
                  type="date"
                  label="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    // input: {
                    //   inputProps: {
                    //     min: toInputStandardDate(TODAY_DATE),
                    //     max: toInputStandardDate(
                    //       academicYearResponse.data?.GetAcdYrsByInstId[0]
                    //         .acd_end_date!
                    //     ),
                    //   },
                    // },
                  }}
                />
              </div>
              <div className="col-3">
                <TextField
                  className="vacation-entry__block--textfield"
                  type="date"
                  label="End Date"
                  value={toInputStandardDate(endDate)}
                  onChange={(e) => setEndDate(e.target.value)}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    // input: {
                    //   inputProps: {
                    //     min: toInputStandardDate(TODAY_DATE),
                    //     max: toInputStandardDate(
                    //       academicYearResponse.data?.GetAcdYrsByInstId[0]
                    //         .acd_end_date!
                    //     ),
                    //   },
                    // },
                  }}
                />
              </div>
            </div>
            <Button mode="save" onClick={HandleNewSemCOnfig} />

            <Button
              mode="cancel"
              type="button"
              onClick={() => {
                setConfigurationModal(!configurationModal);
              }}
            />
          </div>

          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updateLoading || loading} />
    </>
  );
};

export default SemAcdConfig;
