import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import useAcdInstSessions, {
  GetAcdInstSessionsList,
} from "../hooks/useAcdInstSessions";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { Operation } from "../../../utils/Enum.types";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { reOrderProcess } from "../../../utils/UtilFunctions";
import { Button } from "../../../stories/Button/Button";

const { Academics_Table } = require("../json/table.json");
interface Props {
  fetchFormDataForUpdate: (
    node: GetAcdInstSessionsList,
    operationFrmTable: Operation.UPDATE | Operation.DELETE
  ) => void;
  hanldeSaveDragAndDrop: (localItems: GetAcdInstSessionsList[]) => void;
}
const SessionList = ({
  fetchFormDataForUpdate,
  hanldeSaveDragAndDrop,
}: Props) => {
  const [searchData, setSearchData] = useState("");
  const [localItems, setLocalItems] = useState<GetAcdInstSessionsList[]>([]);
  // eslint-disable-next-line
  const [items, setItems] = useState<GetAcdInstSessionsList[]>([]);
  const [reOrder, setReOrder] = useState(false);
  const {
    SessionsQueryResult: { data, error },
  } = useAcdInstSessions();
  useEffect(() => {
    if (data) {
      setLocalItems(data.GetAcdInstSessions);
    }
  }, [data]);

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: GetAcdInstSessionsList[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setItems(temp);
      return temp;
    });
  };
  const convertTo12HourFormat = (time: string): string => {
    let [hours, minutes] = time.split(":");
    let hoursNumber = parseInt(hours, 10);
    const period = hoursNumber >= 12 ? "PM" : "AM";
    hoursNumber = hoursNumber % 12 || 12;
    return `${hoursNumber}:${minutes} ${period}`;
  };

  return (
    <div className="col h-100 booktype-right">
      <div className="row g-0 sessions__frame--title">
        <div className="col-3"></div>
        <div className="col">
          <Title variant="subtitle1">Session</Title>
        </div>
        <div className="col-3"></div>
      </div>
      <div className="sessions__frame--block">
        {error ? (
          <b className="nodata">{error.message}</b>
        ) : data && data.GetAcdInstSessions.length === 0 ? (
          <b className="nodata">Sorry, no results.</b>
        ) : (
          <TableContainer className="sessions__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.SessionAllocation.Table_Headers.filter(
                    (th: TableHeaderProps) =>
                      (th.labelName !== "" && reOrder === false) || reOrder
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragAndDrop}>
                <Droppable
                  droppableId="droppable"
                  direction="vertical"
                  isDropDisabled={reOrder === false}>
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}>
                      {localItems?.map((response, index: number) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}>
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <TableRow
                                key={index}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}>
                                {reOrder ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="reorder-accountledger__tableblock--table--slno">
                                    =
                                  </TableCell>
                                ) : null}

                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  id="td-center"
                                  className="reorder-accountledger__tableblock--table--slno">
                                  {index + 1}
                                </TableCell>

                                <TableCell
                                  {...draggableProvided.dragHandleProps}>
                                  {response.session_desc}
                                </TableCell>
                                <TableCell
                                  {...draggableProvided.dragHandleProps}>
                                  {convertTo12HourFormat(response.start_time)}
                                </TableCell>
                                <TableCell
                                  {...draggableProvided.dragHandleProps}>
                                  {convertTo12HourFormat(response.end_time)}
                                </TableCell>
                                <TableCell>
                                  <img
                                    src={EditProfile}
                                    alt="/"
                                    onClick={() => {
                                      fetchFormDataForUpdate(
                                        response,
                                        Operation.UPDATE
                                      );
                                    }}
                                  />
                                  &nbsp;
                                  <img
                                    src={Delete}
                                    alt="/"
                                    onClick={() => {
                                      fetchFormDataForUpdate(
                                        response,
                                        Operation.DELETE
                                      );
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        )}
      </div>
      {reOrder === false ? (
        <Button mode="reorder" onClick={() => setReOrder(!reOrder)} />
      ) : (
        <>
          <Button
            mode="save-order"
            onClick={() => hanldeSaveDragAndDrop(localItems)}
          />
          <Button
            mode="cancel"
            onClick={() => {
              if (data) setLocalItems(data.GetAcdInstSessions);
              setReOrder(!reOrder);
            }}
          />
        </>
      )}
    </div>
  );
};

export default SessionList;
