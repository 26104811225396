import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/context";
import { GetStudentDetails } from "../queries/common";
import {
  AcademicYear,
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  FinancialYear,
  SectionList,
  SemesterList,
} from "../Types/Student";
import {
  academic_details,
  IObjectKeys,
  std_details,
} from "../utils/Form.types";
import {
  acct_std_demand,
  acct_std_demand_details,
} from "../Modules/Accounts/hooks/useStudentsbyDemandAmount";
import useToken from "./useToken";
import { InstitutionNode } from "./useInstitutionsByCustId";
import { StudentPassoutById } from "../queries/students/list/byId";
import useSwConfigData from "./useSwConfigData";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
} from "../utils/Enum.types";
import useInstitutionConfiguration from "./useInstitutionConfiguration";
import useInstDetails from "./general/useInstDetails";

export interface parentData extends IObjectKeys {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  parent_address: string;
  parent_place: string;
  parent_state: string;
  parent_pin: string;
  parent_email: string;
  parent_mobile: string;
  parent_occup: string;
  parent_qualification: string;
  parent_aadhar: string;
  parent_type: string;
}
export interface Parents {
  relationship: string;
  parent: parentData;
}
interface perstudentDetamdDetails extends acct_std_demand {
  demand_details: acct_std_demand_details[];
}
export interface StudentDetails {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  std_reg_no: string;
  std_adm_no: string;
  acd_yr: AcademicYear;
  fin_yr: FinancialYear;
  std_father_name: string;
  std_father_mobile: string;
  std_mother_email: string;
  std_father_email: string;
  std_guardian_name: string;
  std_guardian_mobile: string;
  std_guardian_email: string;
  std_primary_login: string;
  std_mother_mobile: string;
  std_studying: boolean;
  std_sex: string;
  std_doa: string;
  std_fresher: boolean;
  std_mobile: string;
  std_status: string;
  std_email: string;
  std_mother_name: string;
  std_dob: string;
  std_profile_filename: string;
  class: ClassList;
  acd_class: ClassList;
  branch: BranchesList;
  dept: DepartmentList;
  semester: SemesterList;
  acd_semester: SemesterList;

  section: SectionList;
  acd_section: SectionList;
  category: CategoryList;
  std_other_details: academic_details;
  std_details: std_details;
  parents: Parents[];
  acct_std_demand: perstudentDetamdDetails;
  inst_details: InstitutionNode;
  std_sts_no?: string;
}
export interface StdPassedOutDetails {
  branch_details: BranchesList;
  class_details: ClassList;
  branch_id: number;
  class_id: number;
  std_name: string;
  std_passout_yr: string;
  std_mobile: string;
  std_email: string;
  inst_id: string | number;
}
export interface StudentData {
  nodes: StudentDetails[];
}
export interface StudentDetailsvars {
  token: string;
  ids: number[] | number;
}
export interface StudentPaasedOutData {
  node: StdPassedOutDetails;
}
export interface StudentPassedOutVars {
  token: string;
  id: number;
}
export interface Istudentform extends IObjectKeys {
  std_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  reg_number: string;
  branch: string;
  father_name: string;
  mother_name: string;
  class: string;
  dept: string;
  semester: string;
  section: string;
  category: string;
  adm_no: string;
  acd_yr: string;
  std_dob: string;
  std_doa: string;
  std_mobile: string;
  std_profile_filename: string;
  fresher: boolean;
  email: string;
  branch_id?: number;
}
export interface IstudentPassedOutform extends IObjectKeys {
  std_name: string;
  branch: string;
  class: string;
}

const useStudentDatabyId = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { studentId } = useParams();

  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { USE_SECTION_KEY, USE_SEMESTER_KEY, USE_CLASS_KEY } =
    useInstitutionConfiguration();

  const [allocationId, setAllocationId] = useState(0);
  const [studentEntryId, setStudentEntryId] = useState(0);

  const allocationLevel = configData.data
    ? configData.data.GetSwConfigVariables[0].config_string_value
    : "";
  const [studentFormData, setStudentFormData] = useState<Istudentform>({
    branch: "",
    category: "",
    class: "",
    dept: "",
    father_name: "",
    mother_name: "",
    reg_number: "",
    section: "",
    semester: "",
    std_name: "",
    acd_yr: "",
    std_dob: "",
    std_sex: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    std_mobile: "",
    adm_no: "",
    std_doa: "",
    std_profile_filename: "",
    std_status: "",
    fresher: false,
    email: "",
    branch_id: 0,
  });
  const [studentPassedOutFormData, setStudentPassedOutFormData] =
    useState<IstudentPassedOutform>({
      std_name: "",
      branch: "",
      class: "",
      branch_id: 0,
      class_id: 0,
    });
  const [GetStudentDetailsByIds, GetStudentDetailsByIdsQuery] = useLazyQuery<
    StudentData,
    StudentDetailsvars
  >(GetStudentDetails, {
    fetchPolicy: "network-only",
  });

  const [GetPassOutStudent] = useLazyQuery<
    StudentPaasedOutData,
    StudentPassedOutVars
  >(StudentPassoutById);

  const handleClear = () => {
    setStudentFormData({
      branch: "",
      category: "",
      class: "",
      dept: "",
      father_name: "",
      mother_name: "",
      reg_number: "",
      section: "",
      semester: "",
      std_name: "",
      acd_yr: "",
      std_dob: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      std_mobile: "",
      adm_no: "",
      std_doa: "",
      std_profile_filename: "",
      std_status: "",
      fresher: false,
      email: "",
      branch_id: 0,
    });
  };
  useEffect(() => {
    if (!state.studentId || !studentId || !state.siblingId) {
      handleClear();
    }
  }, [state.studentId, studentId, state.siblingId]);

  useEffect(() => {
    if (state.aluminiStudentId || state.passOutStdIdForChallan) {
      GetPassOutStudent({
        variables: {
          id: state.aluminiStudentId || state.passOutStdIdForChallan,
          token,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setStudentPassedOutFormData({
            std_name: data.node.std_name,
            branch: data.node.branch_details.branch_desc,
            class: data.node.class_details.class_desc,
            branch_id: data.node.branch_id,
            class_id: data.node.class_id,
          });
        }
      });
    }
  }, [
    state.aluminiStudentId,
    GetPassOutStudent,
    token,
    state.passOutStdIdForChallan,
  ]);

  useEffect(() => {
    if (
      (state.studentId ||
        studentId ||
        state.stdIdForChallan ||
        state.siblingId ||
        state.receiptStudentId) &&
      token
    ) {
      GetStudentDetailsByIds({
        variables: {
          token,
          ids: [
            state.siblingId
              ? state.siblingId
              : state.studentId
              ? state.studentId
              : state.stdIdForChallan
              ? state.stdIdForChallan
              : state.receiptStudentId
              ? state.receiptStudentId
              : Number(studentId),
          ],
        },
      }).then(({ data, error }) => {
        if (data && data.nodes) {
          const studentProfiePicUrl = `${
            InstDetails.data?.nodes[0]?.inst_name
          }/students/${
            state.studentId ? state.studentId : studentId
          }/std_profile_pic/std_profile_pic`;

          const {
            branch,
            category,
            class: c,
            dept,
            first_name,
            last_name,
            middle_name,
            section,
            std_adm_no,
            semester,
            std_reg_no,
            std_father_name,
            std_mother_name,
            acd_yr,
            std_dob,
            std_doa,
            std_mobile,
            std_status,
            std_fresher,
            acd_class,
            acd_section,
            acd_semester,
            std_email,
          } = data.nodes[0];
          setStudentFormData({
            branch: branch.branch_desc,
            category: category.cat_desc,
            class: c.class_desc,
            dept: dept.dept_desc,
            father_name: std_father_name,
            mother_name: std_mother_name,
            reg_number: std_reg_no,
            section: section.section_desc,
            semester: semester.sem_desc,
            std_name: first_name + " " + middle_name + " " + last_name,
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
            adm_no: std_adm_no,
            acd_yr: acd_yr.acd_yr,
            std_dob: std_dob,
            std_doa: std_doa,
            std_mobile: std_mobile,
            std_profile_filename: studentProfiePicUrl,
            std_status: std_status,
            fresher: std_fresher,
            email: std_email,
            branch_id: branch.id,
          });
          setAllocationId(
            allocationLevel === AcdSubjectAllocationData.SECTION
              ? acd_section.id
              : allocationLevel === AcdSubjectAllocationData.SEMESTER
              ? acd_semester.id
              : allocationLevel === AcdSubjectAllocationData.CLASS
              ? acd_class.id
              : 0
          );

          setStudentEntryId(
            USE_SECTION_KEY
              ? acd_section.id
              : USE_SEMESTER_KEY
              ? acd_semester.id
              : USE_CLASS_KEY
              ? acd_class.id
              : 0
          );
        }
      });
    }
  }, [
    state.studentId,
    state.siblingId,
    state.receiptStudentId,
    studentId,
    allocationLevel,
    GetStudentDetailsByIds,
    token,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    state.stdIdForChallan,
  ]);

  return {
    studentData: GetStudentDetailsByIdsQuery,
    studentFormData,
    studentPassedOutFormData,
    allocationLevel,
    allocationId,
    studentEntryId,
  };
};

export default useStudentDatabyId;
