import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../components/common/Input/Index";
import { Button } from "../../../stories/Button/Button";
import Modal from "react-modal";
import {
  DebitOrCredit,
  Direction,
  Operation,
  PageFor,
  PageNumbers,
  PredefinedDataTypes,
  SortBy,
} from "../../../utils/Enum.types";
import FeeledgerTreeView from "./TreeView";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddAcctLdgr,
  UpdateAcctLdgrById,
} from "../queries/Accountingledgers/mutations/Index";

import { useNavigate, useParams } from "react-router-dom";
import {
  GetAcctLdgrs,
  GetAcctLdgrsForNonDemand,
  GetAcctStdFeeLdgrsByInstIdByType,
} from "../queries/FeeLedgers/query/Byid";

import { Form, Formik } from "formik";
import { feeLedgerValidation } from "../../../utils/validationRules";
import { AccLedgerByNodeId } from "../queries/GroupLedgers/query/ById";
import { RefsByTagName, removeMoreSpace } from "../../../utils/UtilFunctions";
import { LedgerTitles } from "../../../Types/Titles";
import Home from "../Home/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
} from "../../../styles/ModalStyles";
import ConfigurationSettings from "../../Master/configurations/general/Index";

import LoadingModal from "../../../pages/LoadingModal";
import { TextField } from "@mui/material";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import DownArrow from "../../../images/DownArrow.svg";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import { Keys } from "../../../utils/Enum.keys";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import useGroupLedgerData from "../hooks/useGroupLedgerData";

import Close from "../../../images/Close.svg";
import Settings from "../../../images/Settings.svg";

import DeleteStudentLedgers from "./DeleteStudentLedgers";
import ReorderFeeLedger from "./ReorderFeeLedger";
import {
  AcctGroupLdgrQueryType,
  AcctLedgerQueryType,
} from "../common/QueryTypes";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useBillbooks from "../hooks/useBillbooks";
import { GetStdVoucherBooks } from "../queries/Vouchers/query";
import { InstitutionAcctConfigurationTypes } from "../common/Enum.types";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  FormAutocomplete,
  formClasses,
  SelectAutocomplete,
} from "../../../styles/AutocompleteStyles";
import InputNumber from "../../../components/common/Input/InputNumber";
const { AccountsTitles } = require("../json/title.json");

interface Props {
  pageFor: PageFor;
  setFeeledgerModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageFor, setFeeledgerModal }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  //useStates for formData
  const [feeLedgerName, setFeeLedgerName] = useState("");
  const [feeLedgerId, setFeeLedgerId] = useState<responseType | null>(null);

  const [feeShortName, setFeeShortName] = useState("");
  const [feeAmount, setFeeAmount] = useState(0);

  const [feetype, setFeeType] = useState("");
  const [ledgerId, setLedgerId] = useState(0);
  const [billBook, setBillBook] = useState<responseType | null>(null);
  //useStates for Add and Edit
  const [enableAdd, setEnableAdd] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reOrderModal, setReOrderModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //useRefs
  const feeLedgerNameRef = useRef<HTMLInputElement>(null);
  const generalRef = useRef<HTMLSelectElement>(null);
  const generalInputRef = RefsByTagName(generalRef);
  const billbookRef = useRef<HTMLSelectElement>(null);
  const billBookInputRef = RefsByTagName(billbookRef);
  const saveRef = useRef<HTMLButtonElement>(null);
  const defaultFeeAmount = useRef<HTMLInputElement>(null);

  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);
  //Condition to show form
  const showForm =
    (!enableEdit && !enableAdd) || (feeLedgerId && enableEdit) || enableAdd
      ? true
      : false;

  const { user_details } = useLoggedInUserDetails();

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.FEE_LEDGER_PAGE);
  //useState for Select while Editing
  const [searchData, setSearchData] = useState("");

  const { groupLedgers } = useGroupLedgerData(
    searchData,
    AcctGroupLdgrQueryType.GROUP_STD_LDGR_BY_INST_ID
  );
  const { configData } = useSwConfigData(
    InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS
  );

  const { config_boolean_value } =
    configData.data?.GetSwConfigVariables[0] || {};
  const { voucherBooks } = useBillbooks();
  //useLazyQuery to get FeeLedgers for Selct field while editing
  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
    ROWS_PER_PAGE
  );
  //useLazyQuery for setting data in field while editing
  const [GetFeeledger] = useLazyQuery(AccLedgerByNodeId);

  const [AddFeeLedger, { loading: creationLoading }] = useMutation(
    AddAcctLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateFeeLedger, { loading: updationLoading }] = useMutation(
    UpdateAcctLdgrById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleFeeLedger = () => {
    if (enableAdd) {
      AddFeeLedger({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          input: {
            ldgr_desc: removeMoreSpace(feeLedgerName),
            inst_id: InstId,
            acct_group_ldgr_id: ledgerId,
            ldgr_std_fee_short_desc: removeMoreSpace(feeShortName),
            ldgr_std_fee_Type: feetype,
            ldgr_is_std_fee_ldgr: true,
            ldgr_ob: 0,
            ldgr_total_cr: 0,
            ldgr_total_db: 0,
            ldgr_cb: 0,
            ldgr_imprest_ob: 0,
            ldgr_std_fee: feeAmount,
            cr_or_db: DebitOrCredit.CREDIT,
            default_receipt_book_id: config_boolean_value ? billBook?.value : 0,
          },
        },
        refetchQueries: [
          {
            query: GetAcctStdFeeLdgrsByInstIdByType,
            variables: {
              token,
              inst_id: InstId,
            },
          },
          {
            query: GetAcctLdgrsForNonDemand,
            variables: {
              token,
              input: {
                acct_ldgr_query_type:
                  AcctLedgerQueryType.ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND,
                ids: [InstId],
              },
            },
          },
          {
            query: GetStdVoucherBooks,
            variables: {
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              inst_id: InstId!,
              token,
            },
          },
          {
            query: GetAcctLdgrs,
            variables: {
              token,
              id: null,
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              input: {
                acct_ldgr_query_type: AcctLedgerQueryType.ACCT_LDGRS_BY_INST_ID,
                ids: [InstId],
              },
              sortBy: SortBy.LDGR_DESC,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetAcctLdgrs,
            variables: {
              token,
              id: null,
              after: null,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              input: {
                acct_ldgr_query_type: AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
                ids: [Number(InstId)],
              },
              orderBy: [
                {
                  direction: Direction.ASC,
                  field: "INDEX",
                },
                { direction: Direction.DESC, field: SortBy.LDGR_DESC },
              ],
            },
            fetchPolicy: "network-only",
          },
          {
            query: GetAcctLdgrs,
            variables: {
              token,
              id: null,
              after: null,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              input: {
                acct_ldgr_query_type: AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
                ids: [Number(InstId)],
              },
              orderBy: [
                {
                  direction: Direction.ASC,
                  field: "INDEX",
                },
                { direction: Direction.DESC, field: SortBy.LDGR_DESC },
              ],
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "FeeLedger Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (enableEdit && feeLedgerId) {
      UpdateFeeLedger({
        variables: {
          token,
          id: feeLedgerId.value,
          inst_id: InstId!,
          user_details,
          input: {
            ldgr_desc: removeMoreSpace(feeLedgerName),
            inst_id: InstId,
            acct_group_ldgr_id: ledgerId,
            ldgr_ob: 0,
            ldgr_std_fee_short_desc: removeMoreSpace(feeShortName),
            ldgr_std_fee_Type: feetype,
            cr_or_db: DebitOrCredit.CREDIT,
            default_receipt_book_id: billBook?.value,
            ldgr_std_fee: feeAmount,
          },
        },
        refetchQueries: [
          {
            query: GetAcctStdFeeLdgrsByInstIdByType,
            variables: {
              token,
              inst_id: InstId,
            },
          },
          {
            query: GetStdVoucherBooks,
            variables: {
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              inst_id: InstId!,
              token,
            },
          },
          {
            query: AccLedgerByNodeId,
            variables: {
              token,
              id: feeLedgerId.value,
            },
          },
          {
            query: GetAcctLdgrs,
            variables: {
              token,
              direction: Direction.ASC,
              input: {
                acct_ldgr_query_type: AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
                ids: [InstId],
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "FeeLedger Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    } else {
      if (ledgerId === null) {
        setMessage({
          flag: true,
          message: "Please select fee legder",
          operation: Operation.NONE,
        });
      }
    }
  };

  const handleClear = () => {
    setFeeShortName("");
    setFeeType("");
    setLedgerId(0);
    setFeeLedgerName("");
    setFeeLedgerId(null);
    setBillBook(null);
    setFeeAmount(0);
  };
  useEffect(() => {
    if (!enableEdit) return;
    if (feeLedgerId) {
      GetFeeledger({
        variables: {
          id: feeLedgerId.value,
          token,
        },
      })
        .then(({ data }) => {
          const {
            acct_group_ldgr_id,
            ldgr_std_fee_short_desc,
            ldgr_std_fee_Type,
            ldgr_desc,
            default_receipt_book_id,
            ldgr_std_fee,
          } = data.node;
          const res = voucherBooks.responseType?.find(
            (book) => book.value === default_receipt_book_id
          );

          setFeeShortName(ldgr_std_fee_short_desc);
          setFeeType(ldgr_std_fee_Type);
          setLedgerId(acct_group_ldgr_id);
          setFeeLedgerName(ldgr_desc);
          setFeeAmount(ldgr_std_fee);
          res && setBillBook(res);
        })
        .catch(() => console.log("Error"));
    }
  }, [feeLedgerId, enableEdit, GetFeeledger, voucherBooks.responseType, token]);
  const {
    PredefinedData: { dropDown: studentFeeTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.STUDENT_FEE_TYPE,
    EMPTY_STRING
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    //enableAdd and enableEdit should stay false when you close the success modal
    if (message.operation !== Operation.NONE && message.flag) {
      setEnableAdd(false);
      setEnableEdit(false);
      handleClear();
      setFeeledgerModal && setFeeledgerModal(false);
      
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (pageFor === PageFor.MODAL) setEnableAdd(!enableAdd);

    // eslint-disable-next-line
  }, [pageFor]);

  return (
    <>
      {pageFor === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.FeeLedger.map(
              (title: LedgerTitles, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {enableAdd
                      ? title.CREATE
                      : enableEdit
                      ? title.UPDATE
                      : title.GENERAL}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        {pageFor === PageFor.GENERAL ? (
          <div className="configuration-settings">
            {USE_CONFIG_KEY && (
              <img
                src={Settings}
                alt="/"
                id="settings-icon"
                onClick={() => setConfigurationModal(!configurationModal)}
              />
            )}
          </div>
        ) : null}
      </div>
      <Formik
        initialValues={{
          feeLedgerName,
          feeShortName,
          feetype,
          ledgerId,
        }}
        validationRules={feeLedgerValidation}
        onSubmit={handleFeeLedger}
      >
        {(meta) => {
          return (
            <Form className="feeledger">
              <div className="row g-0 feeledger__block">
                <div
                  className={
                    pageFor === PageFor.GENERAL
                      ? "col account-frames feeledger__block--tree"
                      : EMPTY_STRING
                  }
                >
                  {enableEdit && (
                    <div className="label-grid">
                      <Label variant="LabelPrimary">
                        <b>Select Ledger</b>
                      </Label>
                      {feeLedgerId ? (
                        <SelectAutocomplete
                          className={formClasses.inputRoot}
                          options={acctLedgers.responseType!}
                          openOnFocus
                          freeSolo
                          value={feeLedgerId}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              feeLedgerNameRef?.current?.focus();
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFeeLedgerId(null);
                            }
                          }}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFeeLedgerId(newValue as responseType);
                              setBillBook(null);
                            } else {
                              setFeeLedgerId(null);
                            }
                          }}
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              autoFocus
                              {...params}
                              fullWidth
                              onChange={(e) => setSearchData(e.target.value)}
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      ) : (
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={acctLedgers.responseType!}
                          openOnFocus
                          freeSolo
                          value={feeLedgerId}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              feeLedgerNameRef?.current?.focus();
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFeeLedgerId(null);
                            }
                          }}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFeeLedgerId(newValue as responseType);
                              setBillBook(null);
                            } else {
                              setFeeLedgerId(null);
                            }
                          }}
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              autoFocus
                              {...params}
                              fullWidth
                              onChange={(e) => setSearchData(e.target.value)}
                              className={formClasses.formControlRoot}
                            />
                          )}
                        />
                      )}
                    </div>
                  )}
                  {showForm ? (
                    <>
                      <Input
                        LabelName="Fee Ledger Name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFeeLedgerName(e.target.value);
                          meta.handleChange(e);
                        }}
                        name="feeLedgerName"
                        autoFocus={!enableEdit || false!}
                        values={feeLedgerName}
                        inputRef={feeLedgerNameRef!}
                        required
                        disabled={!enableAdd && !enableEdit}
                      />
                      <Input
                        LabelName="Ledger Short Name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFeeShortName(e.target.value);
                          meta.handleChange(e);
                        }}
                        name="feeShortName"
                        autoFocus={!enableEdit}
                        values={feeShortName}
                        disabled={!enableAdd && !enableEdit}
                      />
                      <div className="label-grid">
                        <Label>Group Ledger</Label>

                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={groupLedgers.responseType}
                          openOnFocus
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              generalInputRef?.focus();
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setLedgerId(0);
                            }
                          }}
                          value={
                            groupLedgers.responseType?.find(
                              ({ value }) => value === ledgerId
                            )! ?? null
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setLedgerId((newValue as responseType)?.value!);
                            } else {
                              setLedgerId(0);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              className={formClasses.formControlRoot}
                            />
                          )}
                          disabled={!enableAdd && !enableEdit}
                        />
                      </div>

                      <div className="label-grid">
                        <Label>Ledger Type</Label>
                        <FormAutocomplete
                          className={formClasses.inputRoot}
                          options={studentFeeTypes!}
                          ref={generalRef!}
                          openOnFocus
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER && feetype) {
                              e.preventDefault();
                              if (config_boolean_value) {
                                if (billBookInputRef) {
                                  billBookInputRef.focus();
                                }
                              } else if (defaultFeeAmount.current) {
                                defaultFeeAmount.current.focus();
                              }
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFeeType(EMPTY_STRING);
                            }
                          }}
                          value={
                            studentFeeTypes?.find(
                              ({ value }) => value === feetype
                            )! ?? null
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFeeType((newValue as optionsType).value!);
                            } else {
                              setFeeType(EMPTY_STRING);
                            }
                          }}
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              className={formClasses.formControlRoot}
                            />
                          )}
                          disabled={!enableAdd && !enableEdit}
                        />
                      </div>
                      {config_boolean_value ? (
                        <div className="label-grid">
                          <Label>Bill Book Type</Label>
                          <FormAutocomplete
                            className={formClasses.inputRoot}
                            ref={billbookRef}
                            options={voucherBooks.responseType}
                            openOnFocus
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER && feetype) {
                                e.preventDefault();
                                if (defaultFeeAmount.current) {
                                  defaultFeeAmount.current.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) setBillBook(null);
                            }}
                            value={billBook}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setBillBook(newValue as responseType);
                              } else {
                                setBillBook(null);
                              }
                            }}
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                required
                                className={formClasses.formControlRoot}
                              />
                            )}
                            disabled={!enableAdd && !enableEdit}
                          />
                        </div>
                      ) : null}
                      <div className="label-grid">
                        <Label> Default Fee Amount</Label>
                        <InputNumber
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFeeAmount(parseInt(e.target.value));
                            meta.handleChange(e);
                          }}
                          name="amount"
                          type="number"
                          autoFocus={!enableEdit}
                          value={feeAmount}
                          disabled={!enableAdd && !enableEdit}
                          inputRef={defaultFeeAmount!}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="LedgerEditWarning">
                      Please Select the FeeLedger to be edited
                    </div>
                  )}
                </div>
                {pageFor === PageFor.GENERAL && (
                  <div className="col account-frames feeledger__block--tree">
                    <FeeledgerTreeView />
                  </div>
                )}
              </div>
              <div
                className={
                  pageFor === PageFor.GENERAL
                    ? "feeledger__buttons"
                    : EMPTY_STRING
                }
              >
                {enableAdd || enableEdit ? (
                  <>
                    <Button
                      mode="save"
                      buttonref={saveRef!}
                      type="submit"
                      disabled={message.flag || creationLoading}
                    />

                    {pageFor === PageFor.GENERAL ? (
                      <>
                        <Button
                          mode="clear"
                          type="button"
                          onClick={() => handleClear()}
                        />
                        <Button
                          mode="back"
                          onClick={() => {
                            handleClear();
                            setEnableAdd(false);
                            setEnableEdit(false);
                          }}
                        />
                      </>
                    ) : (
                      <Button
                        mode="cancel"
                        type="button"
                        onClick={() => setFeeledgerModal?.(false)}
                      />
                    )}
                  </>
                ) : (
                  pageFor === PageFor.GENERAL && (
                    <>
                      <Button
                        mode="addnew"
                        type="button"
                        onClick={() => {
                          setMessage({
                            message: "",
                            flag: false,
                            operation: Operation.NONE,
                          });
                          setEnableAdd(true);
                        }}
                        // onClick={() => {
                        //   setMessage({
                        //   message: "",
                        //     flag: false,
                        //     operation: Operation.NONE,
                        //   });
                        //   setEnableAdd(true);
                        // });
                        //   setEnableAdd(!enableAdd);
                        // }
                        autoFocus
                      />

                      <Button
                        mode="edit"
                        type="button"
                        onClick={(e: React.MouseEvent) => {
                          handleClear();
                          setEnableEdit(!enableEdit);

                          e.preventDefault();
                        }}
                      />
                      <Button
                        mode="delete"
                        onClick={() => setDeleteModal(!deleteModal)}
                      />
                      <Button
                        mode="reorder"
                        onClick={() => setReOrderModal(!reOrderModal)}
                      />
                      <Button mode="back" onClick={() => navigate(-1)} />
                    </>
                  )
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.FEE_LEDGER_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DeleteStudentLedgers setModal={setDeleteModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      {/* ReOrder Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={reOrderModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ReorderFeeLedger setReOrderModal={setReOrderModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setReOrderModal(!reOrderModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
