import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import {
  DebitOrCredit,
  NonDemand,
  PageNumbers,
  ReceiptTypes,
  PageFor,
  VoucherBookKey,
  StudentListFor,
  StudentAcctReportType,
  BankOrCash,
  Direction,
  SortBy,
  Operation,
  ModuleName,
  InstitutionType,
} from "../../../../utils/Enum.types";
import AluminiStudentList from "../../../Master/Student/AluminiStudentList";
import Modal from "react-modal";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
  EditModalCustomStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  PassoutStudentData,
  PassOutStudentDetails,
} from "../../../../Types/Accounting";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import {
  DateRange,
  formatter,
  handleMUISelectEvent,
  NameOfTheDay,
  toggleFullSceen,
  RefsByTagName,
  toInputStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  emptyMessageType,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import { AddAcctChallanMasterAndDetails } from "../../queries/receipts/mutation/Index";
import dayjs from "dayjs";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import FeeLedger from "../../FeeLedger/Index";
import { PassOutStudentNode } from "../../../../queries/common";
import { ClassListByNodes } from "../../../../Types/Student";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import ConfigurationSettings from "../../../Master/configurations/general/Index";
import Avatar from "../../../../images/Avatar.svg";
import Enter from "../../../../images/Enter.svg";
import EditProfile from "../../../../images/EditProfile.svg";
import AddPassoutStudentModal from "../../../Master/Student/AddPassOutStudentModal";

import LoadingModal from "../../../../pages/LoadingModal";
import Settings from "../../../../images/Settings.svg";
import Enlarge from "../../../../images/Enlarge.svg";
import Edit from "../../../../images/Edit.svg";
import DeleteImg from "../../../../images/Delete.svg";
import Close from "../../../../images/Close.svg";

import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import DownArrow from "../../../../images/DownArrow.svg";
import { msgType, responseType } from "../../../../utils/Form.types";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import { GroupsWithSum, recepitsw, TotalInterface } from "../FeeReceipt/Index";
import MessageModal from "../../../../pages/MessageModal";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../context/reducer";
import Delete from "../FeeReceipt/modals/Delete";
import useVoucherNumber, { VDetails } from "../../hooks/useVoucherNumber";
import {
  GetAcctVouchers,
  GetVoucherNumber,
} from "../../queries/Vouchers/query";
import StudentList from "../../common/StudentList";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import useAcctLdgrsForCaptation from "../../hooks/useAcctLdgrsForCaptation";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import FeeReceiptPrint from "../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import { Data } from "../../../Print/Accounts/FeeReceipts/Standard/Index";
import { GetClassesByNodes } from "../../../../queries/institution/classes/list/byid";
import { BranchDetailsByNode } from "../../../../queries/institution/branch/list/byid";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../../common/Enum.types";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import Configurations from "../../../Configurations/PerModuleConfiguration";
import { CreatedByNamesDataVars } from "../../../Channel/Types";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import MultipleReceipts, {
  IMultipleReceipts,
} from "../FeeReceipt/modals/MultipleReceipts";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import ChallanPrint from "../../../Print/Accounts/Challan/Index";
import { BankDetails } from "./CreateChallan";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
import useSwConfigInstType from "../../../Academics/hooks/useSwConfigInstType";
interface Props {
  type: NonDemand;
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectStudent: boolean;
}
interface itemList {
  account_ledger_id: number;
  feeAmount: number;
  sl_no: number;
  cr_db: string;
  vo_key: string;
  vo_book_desc: string;
  v_no: string;
}

export interface extendedResponseType extends responseType {
  defaultAmt: number;
}
const NonDemandChallan = ({
  type,
  pageType,
  setModalFlag,
  selectStudent,
}: Props) => {
  const { format } = formatter;
  let DefaultDate = new Date();
  const { Accounts_Table } = useAcctTableJson();
  const dates = DateRange(DefaultDate.toString());
  const { InstId, studentId } = useParams<{
    InstId: string;
    studentId: string;
  }>();
  const { institutionType } = useSwConfigInstType();

  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const [imageString, setImageString] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [printModal, SetPrintModal] = useState(false);
  const [printConfig, setPrintConfigModal] = useState(false);
  const [rcptDate, setRcptDate] = useState("");
  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [transaction_no, set_transaction_no] = useState("");

  const [v_date, set_v_Date] = useState("");
  const navigate = useNavigate();
  //use States for modal
  const [studentModal, setStudentModal] = useState(false);
  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bankDetailsForPrint, setBankDetailsForPrint] = useState<BankDetails>({
    account_no: 0,
    bank_name: "",
  });
  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE
  );

  const [searchData, setsearchData] = useState("");

  const [admNo, setAdmNo] = useState("");
  const [multipleReceiptsModal, setMultipleRecepitsModal] = useState(false);

  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.GENERAL
  );
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feeDescRef);
  const dueDateRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const [id, setId] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [feeAmount, setFeeAmount] = useState(0);
  const [account_ledger_id, SetAccountLedgerId] =
    useState<extendedResponseType | null>(null);
  const [aluminiStudentClassName, setAuminiClassName] = useState("");
  const [aluminiStudentBranchName, setAuminiBranchName] = useState("");
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [ledgerList, setLedgerList] = useState<extendedResponseType[]>([]);
  const [multiplereceipts, setMultipleReceipts] = useState<IMultipleReceipts[]>(
    []
  );
  const [aluminiStudentDetails, setAluminiStudentDetails] =
    useState<PassoutStudentData>({
      branch_id: 0,
      class_id: 0,
      id: 0,
      inst_id: 0,
      std_email: "",
      std_mobile: "",
      std_name: "",
      std_passout_yr: "",
      branch_name: "",
      class_name: "",
    });
  //use state for arrays
  const [items, setItems] = useState<itemList[]>([]);
  const [receipts, setreceipts] = useState<recepitsw[]>([]);
  const [voucherKeys, setVoucherKeys] = useState<string[]>([]);

  const [generatedVoucherNumbers, setVoucherNumbers] = useState<VDetails[]>([]);
  let totalAmount = 0;
  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
    InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    let hideCaptationFee = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE:
            hideCaptationFee = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
      hideCaptationFee,
    };
  };
  const { editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );
  const { user_details } = useLoggedInUserDetails();

  const {
    configData: { data: multipleBillBooks },
  } = useSwConfigData(InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS);

  //use queries

  const { InstDetails } = useInstDetails(1);
  const { studentData, studentFormData } = useStudentDatabyId();
  const { NonDemandCapLedgers } = useAcctLdgrsForCaptation(
    AcctLedgerQueryType.ALL_STD_FEE_ACCT_LDGRS_FOR_NON_DEMAND
  );

  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );

  const {
    data: serverDateData,
    loading: serverDateLoading,
    serverDate,
  } = useServerDateandTime();

  const { voucherNumber } = useVoucherNumber(
    multipleBillBooks?.GetSwConfigVariables[0].config_boolean_value
      ? (voucherKeys as VoucherBookKey[])!
      : VoucherBookKey.CHALLAN
  );
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  //lazy queries

  const [GetAluminiStudent] = useLazyQuery<
    PassOutStudentDetails,
    CreatedByNamesDataVars
  >(PassOutStudentNode);
  const [GetAluminiStudentBranchDetails] = useLazyQuery(BranchDetailsByNode, {
    variables: {
      token,
      branch_id: aluminiStudentDetails.branch_id,
    },
  });
  const [GetAluminiStudentClassDetails] = useLazyQuery<
    ClassListByNodes,
    CreatedByNamesDataVars
  >(GetClassesByNodes, {
    variables: {
      token,
      ids: [aluminiStudentDetails.class_id],
    },
  });

  //use mutation
  const [
    GenerateStudentNonDemandReceipts,
    { loading: GenerateStudentNonDemandReceiptsLoading },
  ] = useMutation(AddAcctChallanMasterAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const groups = items.reduce((acc: Record<string, itemList[]>, item) => {
    const groupId: string = item.v_no || "";
    const group = acc[groupId] || [];

    // Loop through generatedVoucherNumbers to find a match
    generatedVoucherNumbers.forEach((voucher) => {
      if (item?.vo_key === voucher.vo_book_key) {
        // Update the voucher number
        item.v_no = voucher.vo_number;
      }
    });
    return {
      ...acc,
      [groupId]: [...group, item],
    };
  }, {});

  const groupsWithSum: GroupsWithSum = Object.entries(groups).reduce(
    (acc, [groupId, group]) => ({
      ...acc,
      [groupId]: group.reduce(
        (total: TotalInterface, item) => ({
          sum: total.sum + (item.feeAmount || 0),
          vo_key: item.vo_key,
          vo_number: item.v_no!,
        }),
        { sum: 0, vo_key: "", vo_number: "" }
      ),
    }),
    {}
  );
  const itemsVoBookDescSet = useMemo(() => {
    return new Set(items.map((item) => item.vo_book_desc));
  }, [items]);
  const groupedItems = Object.entries(groups).map(([groupId, items]) => ({
    groupId,
    items,
  }));

  const HandleItems = (e: React.FormEvent) => {
    e.preventDefault();
    const itemAlreadyExists = items.filter(
      (d) =>
        account_ledger_id && d.account_ledger_id === account_ledger_id.value
    );
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      SetAccountLedgerId(null);
      setFeeAmount(0);

      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }
    const updatedData = ledgerList?.map((id) => {
      if (account_ledger_id && id.value === account_ledger_id.value) {
        const newData = {
          ...id,
          value: id.value,
          label: id.label,
          isChecked: !id.isChecked,
        };
        return newData;
      }
      return id;
    });
    setLedgerList(updatedData);
    if (account_ledger_id && account_ledger_id.value && feeAmount) {
      const ledgerKey = NonDemandCapLedgers.data?.GetAcctLdgrsForNonDemand.find(
        ({ id }) => account_ledger_id && account_ledger_id.value === id
      )?.voucher_book_details;
      setItems((items) => [
        ...items,
        {
          sl_no: items.length + 1,
          account_ledger_id: account_ledger_id ? account_ledger_id.value : 0,
          feeAmount,
          cr_db: DebitOrCredit.CREDIT,
          vo_key: ledgerKey?.vo_book_key!,
          vo_book_desc: ledgerKey?.vo_book_desc!,
          v_no: "",
        },
      ]);
    }

    SetAccountLedgerId(null);
    setFeeAmount(0);
    feeDescInputRef?.select();
    feeDescInputRef?.focus();
  };
  const handleDebitItems = () => {
    if (transactionBookLedgerId)
      setreceipts((items) => [
        ...items,
        {
          acct_ldgr_id: transactionBookLedgerId.value,
        },
      ]);
  };
  const handleItemEdit = (feeAmount: number, acct_ldgr_id: number) => {
    const ledger = ledgerList.find((d) => d.value === acct_ldgr_id);
    SetAccountLedgerId(ledger!);
    setFeeAmount(feeAmount);
  };
  const HandleEditItem = (sl_number: number, e: React.FormEvent) => {
    e.preventDefault();
    const updatedData = items.map((item) => {
      if (item.sl_no === sl_number) {
        const newData = {
          ...item,
          feeAmount,
          account_ledger_id: account_ledger_id ? account_ledger_id.value : 0,
        };
        return newData;
      }
      return item;
    });
    setEdit(!edit);
    setItems(updatedData);
    setFeeAmount(0);
    SetAccountLedgerId(null);
  };

  const handleItemDeleted = (i: number) => {
    items.splice(i, 1);
    setItems((items) => [...items]);
  };

  // eslint-disable-next-line
  items.map((item) => (totalAmount += item.feeAmount));
  const handleStudentReceipts = () => {
    if (
      generatedVoucherNumbers.length > 1 &&
      multipleBillBooks?.GetSwConfigVariables[0]?.config_boolean_value
    ) {
      alert("multiple bill books cannot be under one bank transaction");
      return;
    }
    if (multipleBillBooks?.GetSwConfigVariables[0].config_boolean_value) {
      if (transactionBookLedgerId)
        GenerateStudentNonDemandReceipts({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input: groupedItems.map(({ groupId, items }, index) => {
              const keys = Object.entries(groupsWithSum)?.find(
                ([id, num]: [string, TotalInterface]) => id === groupId
              )?.[1]?.vo_key!;
              const totalAmount = Object.entries(groupsWithSum).find(
                ([id, num]: [string, TotalInterface]) => id === groupId
              )?.[1].sum!;
              return {
                acct_voucher_master: {
                  fin_yr: state.ActiveFinYr
                    ? state.ActiveFinYr.fin_yr
                    : EMPTY_STRING,
                  v_type: ReceiptTypes.STUDENT_RECEIPT,
                  v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
                  v_date: dayjs(rcptDate).format(),
                  v_total_cr_amt: totalAmount,
                  v_total_db_amt: totalAmount,
                  v_std_anonymous_deposit_adjusted: false,
                  v_std_deposit_adjusted: false,
                  v_std_receipt_anonymous: false,
                  v_reconciled: false,
                  v_reconciled_date: dayjs(DEFAULT_TIME).format(),
                  v_std_receipt: type === NonDemand.ROLE,
                  v_std_enquiry: false,
                  enquiry_student_id: 0,
                  v_std_non_demand_receipt:
                    type === NonDemand.ROLE ? true : false,
                  v_std_refund_deposit: false,
                  v_std_refund: false,
                  v_book_type: keys,
                  v_std_deposit: false,
                  v_std_demand_receipt: false,
                  v_std_scholarship_deposit: false,

                  student_id: state.studentId ? state.studentId : 0,
                  passout_student_id: state.aluminiStudentId
                    ? state.aluminiStudentId
                    : 0,
                  v_std_passout_receipt: type === NonDemand.NON_ROLE,
                  class_id: aluminiStudentDetails.class_id
                    ? aluminiStudentDetails.class_id
                    : studentData.data?.nodes[0].class.id,
                  semester_id: studentData.data?.nodes[0].semester.id,
                  v_std_amt_receipt: totalAmount,
                  v_std_amt_deposit: 0,
                  v_std_amt_fine: 0,
                  v_std_amt_total: totalAmount,
                  v_std_amt_refunded: 0,
                  v_std_amt_adjusted: 0,
                  v_transcation_type: "Bank Book",
                  v_transcation_cash_or_bank: BankOrCash.BANK,
                  v_transcation_no: EMPTY_STRING,
                  v_transcation_date: dayjs(rcptDate).format(),
                  v_transcation_narration: remarks,
                  party_bill_date: dayjs(DEFAULT_TIME).format(),
                  annx_yesno: false,
                  acct_ldgr_id_cr: items[0].account_ledger_id,
                  acct_ldgr_id_db: receipts[0].acct_ldgr_id,
                },
                acct_voucher_db: receipts.map((item, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum,
                  vo_cr_total: 0,
                  vo_db_total: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,

                  acct_ldgr_id: transactionBookLedgerId.value,
                })),
                acct_voucher_cr: items.map((item, index) => ({
                  vo_sl_no: index + 1,
                  vo_cr_db: DebitOrCredit.CREDIT,
                  vo_cr: item.feeAmount,
                  vo_db: 0,
                  vo_cr_total: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,
                  vo_db_total: 0,
                  acct_ldgr_id: item.account_ledger_id,
                })),
              };
            }),
          },
          refetchQueries: [
            {
              query: GetAcctVouchers,
              variables: {
                after: null,
                direction: Direction.ASC,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                first: ROWS_PER_PAGE,
                sortBy: SortBy.V_DATE,
                token,
                name: EMPTY_STRING,
                input: {
                  inst_id: InstId!,
                  voucher_query_type: VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS,
                  vo_end_date: dayjs(dates?.lastDay!).format(),
                  acct_ldgr_id: 0,
                  vo_start_date: dayjs(dates?.firstDay!).format(),
                  vo_type: EMPTY_STRING,
                },
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            const { v_no, v_date } = data.AddAcctChallanMasterAndDetails;

            setTableData(
              items
                .filter((item) => item.feeAmount > 0)
                .map((item) => ({
                  particular: NonDemandCapLedgers.responseType.find(
                    (res) => item.account_ledger_id === res.value
                  )?.label!,
                  amount: item.feeAmount,
                }))
            );
            set_v_Date(v_date);
            set_vo_no(v_no);
            set_transaction_no(transaction_no);
            setMultipleReceipts(data.AddAcctChallanMasterAndDetails);
            setMultipleRecepitsModal(!multipleReceiptsModal);
            setMessage({
              message: `sucessfully raised Challan for student ${studentFormData?.std_name}`,
              flag: true,
              operation: Operation.CREATE,
            });
          }
        });
      else {
        setMessage({
          flag: true,
          message: "Transaction Ledger id not found",
          operation: Operation.NONE,
        });
      }
    } else {
      GenerateStudentNonDemandReceipts({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,
              v_type: ReceiptTypes.STUDENT_RECEIPT,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
              v_date: dayjs(rcptDate).format(),
              v_total_cr_amt: totalAmount,
              v_total_db_amt: totalAmount,
              v_std_deposit_adjusted: false,
              v_reconciled: false,
              v_std_anonymous_deposit_adjusted: false,

              v_reconciled_date: dayjs(DEFAULT_TIME).format(),
              v_std_receipt: type === NonDemand.ROLE,
              v_std_enquiry: false,
              enquiry_student_id: 0,
              v_std_non_demand_receipt: type === NonDemand.ROLE ? true : false,
              v_std_refund: false,
              v_book_type: VoucherBookKey.CHALLAN,
              v_std_deposit: false,
              v_std_demand_receipt: false,
              v_std_scholarship_deposit: false,

              student_id: state.studentId ? state.studentId : 0,
              passout_student_id: state.aluminiStudentId
                ? state.aluminiStudentId
                : 0,
              v_std_passout_receipt: type === NonDemand.NON_ROLE,
              class_id: aluminiStudentDetails.class_id
                ? aluminiStudentDetails.class_id
                : studentData.data?.nodes[0].class.id,
              semester_id: studentData.data?.nodes[0].semester.id,
              v_std_amt_receipt: totalAmount,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: totalAmount,
              v_std_amt_refunded: 0,
              v_std_amt_adjusted: 0,
              v_transcation_type: "Bank Book",
              v_transcation_cash_or_bank: BankOrCash.BANK,
              v_std_refund_deposit: false,
              v_std_receipt_anonymous: false,
              v_transcation_no: EMPTY_STRING,
              v_transcation_date: dayjs(rcptDate).format(),
              v_transcation_narration: remarks,
              party_bill_date: dayjs(DEFAULT_TIME).format(),
              annx_yesno: false,
              acct_ldgr_id_cr: items[0].account_ledger_id,
              acct_ldgr_id_db: receipts[0].acct_ldgr_id,
            },
            acct_voucher_db: receipts.map((dbData, index) => ({
              vo_cr_db: DebitOrCredit.DEBIT,
              vo_sl_no: index + 1,
              vo_cr: 0,
              vo_db: totalAmount,
              vo_cr_total: 0,
              vo_db_total: totalAmount,
              acct_ldgr_id: dbData.acct_ldgr_id,
            })),
            acct_voucher_cr: items.map((crData, index) => ({
              vo_cr_db: crData.cr_db,
              vo_sl_no: index + 1,
              vo_cr: crData.feeAmount,
              vo_db: 0,
              vo_cr_total: totalAmount,
              vo_db_total: 0,
              acct_ldgr_id: crData.account_ledger_id,
            })),
          },
        },
        refetchQueries: [
          {
            query: GetAcctVouchers,
            variables: {
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              sortBy: SortBy.V_DATE,
              token,
              name: EMPTY_STRING,
              input: {
                inst_id: InstId!,
                voucher_query_type: VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS,
                vo_end_date: dayjs(dates?.lastDay!).format(),
                acct_ldgr_id: 0,
                vo_start_date: dayjs(dates?.firstDay!).format(),
                vo_type: EMPTY_STRING,
              },
            },
          },
          {
            query: GetVoucherNumber,
            variables: {
              token,
              inst_id: InstId!,
              vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          const { v_no, v_date, id } = data.AddAcctChallanMasterAndDetails;
          dispatch({
            type: payloadTypes.SET_RECEPIT_ID,
            payload: { receiptId: id },
          });
          setTableData(
            items
              .filter((item) => item.feeAmount > 0)
              .map((item) => ({
                particular: NonDemandCapLedgers.responseType.find(
                  (res) => item.account_ledger_id === res.value
                )?.label!,
                amount: item.feeAmount,
              }))
          );
          set_v_Date(v_date);
          set_vo_no(v_no);
          setBankDetailsForPrint({
            account_no:
              data.AddAcctChallanMasterAndDetails.acct_ldgr_db_details
                .ldgr_bank_ac_no,
            bank_name:
              data.AddAcctChallanMasterAndDetails.acct_ldgr_db_details
                .ldgr_bank_name,
          });
          !printModal ? SetPrintModal(!printModal) : handleClear();
          setMessage({
            message: `Successfully generated Challan for student ${studentData.data?.nodes[0].first_name}`,
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const HandleSelectFeeType = (id: number) => {
    const filterdData = ledgerList?.find(({ value }) => value === id);

    if (filterdData) SetAccountLedgerId(filterdData);
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleClear = () => {
    if (printModal === false) {
      dispatch({
        type: payloadTypes.SET_ALUMINI_STUDENT_ID,
        payload: {
          aluminiStudentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: 0,
        },
      });
    }

    setItems([]);
    setTransactionLedgerId(null);
    setAdmNo("");
    setImageString(EMPTY_STRING);
    setRemarks(EMPTY_STRING);
    set_vo_no(EMPTY_STRING);
    set_v_Date(serverDate);
    setsearchData(EMPTY_STRING);
    setAdmNo(EMPTY_STRING);
    setRemarks(EMPTY_STRING);
    setFeeAmount(0);
    SetAccountLedgerId(null);
  };
  useEffect(() => {
    if (state.aluminiStudentId && type === NonDemand.NON_ROLE) {
      GetAluminiStudent({
        variables: { ids: [state.aluminiStudentId], token },
      }).then(({ data }) => {
        if (data) {
          setAluminiStudentDetails({
            branch_id: data?.nodes[0].branch_id!,
            class_id: data?.nodes[0].class_id!,
            id: data?.nodes[0].id!,
            inst_id: data?.nodes[0].inst_id!,
            std_email: data?.nodes[0].std_email!,
            std_mobile: data?.nodes[0].std_mobile!,
            std_name: data?.nodes[0].std_name!,
            std_passout_yr: data?.nodes[0].std_passout_yr!,
            branch_name: data.nodes[0].branch_details
              ? data.nodes[0].branch_details.branch_desc
              : EMPTY_STRING,
            class_name: data.nodes[0].branch_details
              ? data.nodes[0].branch_details.branch_desc
              : EMPTY_STRING,
          });
        }
      });
    }
  }, [state.aluminiStudentId, type, GetAluminiStudent, token]);
  useEffect(() => {
    if (aluminiStudentDetails.branch_id) {
      GetAluminiStudentBranchDetails().then(({ data }) => {
        if (data) {
          setAuminiBranchName(data?.node.branch_desc!);
        }
      });
    }
  }, [aluminiStudentDetails.branch_id, GetAluminiStudentBranchDetails]);

  useEffect(() => {
    if (aluminiStudentDetails.class_id) {
      GetAluminiStudentClassDetails().then(({ data }) => {
        if (data) {
          setAuminiClassName(data?.nodes[0].class_desc!);
        }
      });
    }
  }, [aluminiStudentDetails.class_id, GetAluminiStudentClassDetails]);

  useEffect(() => {
    if (transactionBookLedgerId && transactionBookLedgerId.value > 0) {
      handleDebitItems();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);

  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line

      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  const voucherNumbers = useMemo(() => {
    if (
      multipleBillBooks?.GetSwConfigVariables[0].config_boolean_value &&
      voucherNumber.data &&
      !voucherNumber.loading &&
      state.studentId
    ) {
      return multipleBillBooks?.GetSwConfigVariables[0].config_boolean_value
        ? voucherNumber.multipleVoucherNumber
        : [voucherNumber.data.GetVoucherNumber.vo_number];
    }
    return [];
  }, [
    multipleBillBooks?.GetSwConfigVariables,
    voucherNumber.data,
    voucherNumber.loading,
    voucherNumber.multipleVoucherNumber,
    state.studentId,
  ]);

  useEffect(() => {
    if (items.length) {
      setVoucherKeys([
        ...new Set(items.map((item) => item.vo_key)),
      ] as string[]);
    }
  }, [items]);

  useEffect(() => {
    setVoucherNumbers(voucherNumbers as VDetails[]);
  }, [voucherNumbers]);

  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  const itemsIdSet = new Set(
    items.map(({ account_ledger_id }) => account_ledger_id)
  );
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}

      <div className="row g-0">
        <div className="col">
          <Title>Non Demand Student Challan</Title>
        </div>
        <div className="configuration-settings">
          {pageType === PageFor.GENERAL ? (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          ) : (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 non-demandfee-payable"
            : "row g-0 non-demandfee-payable--modal"
        }
      >
        {type === NonDemand.ROLE ? (
          <div className="col-4 ">
            <div className="non-demandfee-payable__grid">
              {selectStudent ? (
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={studentAddmissionNumber}
                  value={
                    state.studentId
                      ? studentAddmissionNumber?.find(
                          ({ value }) => value === state.studentId
                        )!
                      : null
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      if (state.studentId) {
                        feeDescInputRef?.select();
                        feeDescInputRef?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                      setAdmNo("");
                    }
                  }}
                  openOnFocus
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      handleClear();
                    }
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setAdmNo(e.target.value)}
                      label="Admission Number"
                      autoFocus
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="Admission No."
                  className="demand-fee__textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={studentFormData.adm_no}
                  disabled
                />
              )}

              {pageType === PageFor.GENERAL ? (
                <img
                  className="data-fetch-icon"
                  src={Edit}
                  alt="/"
                  onClick={() => setStudentModal(!studentModal)}
                />
              ) : null}
            </div>
            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                label="Register Number"
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.reg_number}
                disabled
              />
            )}
            {USE_CATEGORY_KEY && (
              <TextField
                label={categoryLabel}
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.category}
                disabled
              />
            )}
          </div>
        ) : null}
        {type === NonDemand.ROLE ? null : (
          <div className="col-4">
            <div className="non-demandfee-payable__grid">
              <TextField
                label="Email"
                className="demand-fee__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={
                  state.aluminiStudentId
                    ? aluminiStudentDetails.std_email
                    : EMPTY_STRING
                }
                disabled
              />

              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              label="Mobile"
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                state.aluminiStudentId
                  ? aluminiStudentDetails.std_mobile
                  : EMPTY_STRING
              }
              disabled
            />
            <TextField
              label="Passout Year"
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                state.aluminiStudentId
                  ? aluminiStudentDetails.std_passout_yr
                  : EMPTY_STRING
              }
              disabled
            />
          </div>
        )}

        <div className="col">
          <TextField
            label="Name"
            className="demand-fee__textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              type === NonDemand.ROLE && studentData.data
                ? studentFormData.std_name
                : state.aluminiStudentId
                ? aluminiStudentDetails.std_name
                : EMPTY_STRING
            }
            disabled
          />

          <TextField
            label={branchLabel}
            className="demand-fee__textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              type === NonDemand.ROLE && studentData.data
                ? studentFormData.branch
                : state.aluminiStudentId
                ? aluminiStudentBranchName
                : EMPTY_STRING
            }
            disabled
          />
          <TextField
            label={classLabel}
            className="demand-fee__textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              type === NonDemand.ROLE && studentData.data
                ? studentFormData.class
                : state.aluminiStudentId
                ? aluminiStudentClassName
                : EMPTY_STRING
            }
            disabled
          />
        </div>
        <div className="col-3 ">
          <div className="non-demandfee-payable__label-grid">
            <TextField
              type="date"
              label="Date"
              className="demand-fee__textfield--date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                },
              }}
              value={toInputStandardDate(rcptDate)}
              onChange={(e) => setRcptDate(e.target.value)}
              disabled={editDate ? false : true}
            />
            <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>
          </div>
          <div className="non-demandfee-payable__label-gridbtn">
            <Button
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
              mode="ledger"
            />

            {type === NonDemand.NON_ROLE ? (
              <Button
                mode="passout-students"
                onClick={() => setOpenModal(!openModal)}
                autoFocus
              />
            ) : null}
          </div>
        </div>
        <div className="col-1 non-demandfee-payable__image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 non-demandfee-payable__tableblock"
            : "row g-0 non-demandfee-payable__tableblock--modal"
        }
      >
        <TableContainer className="non-demandfee-payable__table g-0">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.Receipts.NonDemand.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="non-demandfee-payable__table--select-row">
                <TableCell
                  id="td-center"
                  className="non-demandfee-payable__table--slno"
                >
                  {items.length + 1}
                </TableCell>
                <TableCell className="non-demandfee-payable__table--desc">
                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    isOptionEqualToValue={(option) => {
                      if (account_ledger_id) {
                        return (
                          (option as responseType).value ===
                          account_ledger_id.value
                        );
                      } else {
                        return false;
                      }
                    }}
                    options={
                      NonDemandCapLedgers.data
                        ? NonDemandCapLedgers.data.GetAcctLdgrsForNonDemand.filter(
                            (ele) => ele
                          )
                            .filter(
                              ({ id }) =>
                                (edit === false &&
                                  itemsIdSet.has(id) === false) ||
                                edit
                            )
                            .map((demand) => ({
                              label: demand.ldgr_desc,
                              value: demand.id,
                              defaultAmt: demand.ldgr_std_fee,
                            }))
                        : []
                    }
                    openOnFocus
                    autoHighlight
                    ref={feeDescRef}
                    disabled={
                      !state.studentId || !state.aluminiStudentId ? false : true
                    }
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (account_ledger_id && dueDateRef.current) {
                          dueDateRef.current?.select();
                          dueDateRef.current?.focus();
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        SetAccountLedgerId(null);
                        setFeeAmount(0);
                      }
                    }}
                    value={account_ledger_id}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        const data = newValue as extendedResponseType;
                        SetAccountLedgerId({
                          label: data.label,
                          value: data.value,
                          defaultAmt: data.defaultAmt,
                          isChecked: true,
                        });
                        setFeeAmount(data.defaultAmt);
                      } else {
                        SetAccountLedgerId(null);
                        setFeeAmount(0);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => setsearchData(e.target.value)}
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </TableCell>
                <TableCell
                  id="td-right"
                  className="non-demandfee-payable__table--balance"
                >
                  <input
                    value={feeAmount}
                    id="td-right"
                    disabled={account_ledger_id === null}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFeeAmount(Number(e.target.value))
                    }
                    ref={dueDateRef}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER && feeAmount) {
                        buttonRef.current?.focus();
                      }
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className="non-demandfee-payable__table--actions"
                >
                  <button
                    ref={buttonRef}
                    onClick={(e: React.FormEvent) =>
                      edit ? HandleEditItem(id, e) : HandleItems(e)
                    }
                  >
                    {edit ? "Save" : <img src={Enter} alt="/" />}
                  </button>
                </TableCell>
              </TableRow>
              {multipleBillBooks?.GetSwConfigVariables[0].config_boolean_value
                ? [...itemsVoBookDescSet].map((v_book_desc, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell colSpan={8}>
                            <b>{v_book_desc}</b>
                          </TableCell>
                        </TableRow>
                        {items
                          .filter((item) => item.vo_book_desc === v_book_desc)
                          .map((v_item) => {
                            return (
                              <TableRow key={index}>
                                <TableCell id="td-center">
                                  {index + 1}
                                </TableCell>
                                <TableCell>
                                  {
                                    NonDemandCapLedgers.responseType.find(
                                      (data) =>
                                        data.value === v_item.account_ledger_id
                                    )?.label
                                  }
                                </TableCell>
                                <TableCell
                                  id="td-right"
                                  className="non-demandfee-payable__table--balance"
                                >
                                  {format(v_item.feeAmount)}
                                </TableCell>
                                <TableCell id="td-center">
                                  <img
                                    src={EditProfile}
                                    alt="/"
                                    onClick={() => {
                                      handleItemEdit(
                                        v_item.feeAmount,
                                        v_item.account_ledger_id
                                      );
                                      setEdit(!edit);
                                      HandleSelectFeeType(
                                        v_item.account_ledger_id
                                      );
                                      setId(v_item.sl_no);
                                    }}
                                  />

                                  {items.length ? (
                                    <img
                                      id="delete-profile"
                                      src={DeleteImg}
                                      alt="/"
                                      onClick={() => handleItemDeleted(index)}
                                    />
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </React.Fragment>
                    );
                  })
                : items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell id="td-center">{index + 1}</TableCell>
                        <TableCell>
                          {
                            NonDemandCapLedgers.responseType.find(
                              (data) => data.value === item.account_ledger_id
                            )?.label
                          }
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="non-demandfee-payable__table--balance"
                        >
                          {format(item.feeAmount)}
                        </TableCell>
                        <TableCell id="td-center">
                          <img
                            src={EditProfile}
                            alt="/"
                            onClick={() => {
                              handleItemEdit(
                                item.feeAmount,
                                item.account_ledger_id
                              );
                              setEdit(!edit);
                              HandleSelectFeeType(item.account_ledger_id);
                              setId(item.sl_no);
                            }}
                          />

                          {items.length ? (
                            <img
                              id="delete-profile"
                              src={DeleteImg}
                              alt="/"
                              onClick={() => handleItemDeleted(index)}
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} className="total">
                  Total :
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalAmount)}
                </TableCell>
                <TableCell id="tfoot-td"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 non-demandfee-payable__receiptblock"
            : "row g-0 non-demandfee-payable__receiptblock--modal"
        }
      >
        <div className="col account-frames h-100">
          <div className="label-grid">
            <Label>Challan No.</Label>
            <Input
              value={
                generatedVoucherNumbers
                  .map((number) => number.vo_number)
                  .join(", ")
                  ? generatedVoucherNumbers
                      .map((number) => number.vo_number)
                      .join(", ")
                  : voucherNumber.data
                  ? voucherNumber.data.GetVoucherNumber.vo_number
                  : EMPTY_STRING
              }
              disabled
            />
          </div>
          <div className="label-grid">
            <Label> Mode of Transaction</Label>
            <Input disabled value="Bank Book" />
          </div>
        </div>

        <div className="col account-frames h-100">
          <div className="student-fee-receipt__receiptblock--frame--bankdetails">
            <Label> Bank Transaction Type</Label>
            <Input disabled value="Challan" />
          </div>
          <div className="student-fee-receipt__receiptblock--frame--bankdetails">
            <Label> Bank Ledgers</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={BankLedgers.responseType!}
              openOnFocus
              autoHighlight
              value={transactionBookLedgerId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTransactionLedgerId(newValue as responseType);
                  setreceipts([]);
                } else {
                  setTransactionLedgerId(null);
                  setreceipts([]);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (
                  transactionBookLedgerId &&
                  transactionBookLedgerId.value &&
                  e.key === Keys.ENTER
                ) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setTransactionLedgerId(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  onChange={(e) => setsearchData(e.target.value)}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className="col account-frames label-grid h-100">
          <Label>Remarks :</Label>
          <TextArea
            textAreaRef={remarksRef}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === Keys.ENTER) {
                saveButtonRef.current?.focus();
              }
            }}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            rows={3}
          />
        </div>
      </form>
      <div
        className={
          pageType === PageFor.GENERAL ? "non-demandfee-payable__buttons" : ""
        }
      >
        <Button
          mode="save"
          disabled={!items.length || !transactionBookLedgerId}
          onClick={handleStudentReceipts}
          buttonref={saveButtonRef}
        />
        {/* <Button mode="delete" onClick={() => setDeleteModal(!deleteModal)} /> */}

        {/* <Button mode="previous-receipt" /> */}
        <Button
          mode="clear"
          disabled={type !== NonDemand.NON_ROLE && !state.studentId}
          onClick={handleClear}
        />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} type="button" />
        ) : (
          <Button
            mode="cancel"
            type="button"
            onClick={() => setModalFlag(false)}
          />
        )}
      </div>
      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {type === NonDemand.NON_ROLE ? (
              <AluminiStudentList
                type={PageFor.MODAL}
                setStudentModal={setStudentModal}
              />
            ) : (
              <>
                <StudentList
                  pageType={PageFor.MODAL}
                  studentListFor={StudentListFor.ACCOUNTS}
                  queryType={StudentAcctReportType.GENERAL}
                  setStudentModal={setStudentModal}
                />
              </>
            )}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setStudentModal(!studentModal);
              }}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* fee ledger Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setFeeledgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
            />
          </div>
        </div>
      </Modal>
      {/* Pass out student Modal */}
      <AddPassoutStudentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        operation={Operation.CREATE}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={multipleReceiptsModal}
        style={DeleteLedgerModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MultipleReceipts items={multiplereceipts} type={type} />
            <Button
              mode="cancel"
              onClick={() => {
                setMultipleRecepitsModal(!multipleReceiptsModal);
                handleClear();
              }}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setMultipleRecepitsModal(!multipleReceiptsModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete
              v_type={VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS}
              setModalFlag={setDeleteModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ChallanPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={transaction_no}
              setModal={SetPrintModal}
              bankDetailsForPrint={bankDetailsForPrint}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_ALUMINI_STUDENT_ID,
                  payload: {
                    aluminiStudentId: 0,
                  },
                });
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });
                handleClear();
                SetPrintModal(!printModal);
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.CHALLAN_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentNonDemandReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default NonDemandChallan;
