import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";
import { Step, StepLabel, Stepper, TextField } from "@mui/material";
import { Form, Formik } from "formik";

import { Label } from "../../../../stories/Label/Label";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../components/common/Input/Index";

import { GetSwConfigStudentDataEntryByInstId } from "../../../../queries/institution/configuration/query";

import { UpdateStudentOtherDetailsByStudentId } from "../../../../queries/students/mutations/update";

import {
  Operation,
  PredefinedDataTypes,
  StudentRegistrationFieldNames,
  StudentRegitrationForms,
  YesNo,
} from "../../../../utils/Enum.types";
import {
  academic_details,
  optionsType,
  studentPreviousSchoolData,
} from "../../../../utils/Form.types";
import {
  GetSwConfigStudentDataEntryByInstIdData,
  GetSwConfigStudentDataEntryByInstIdVars,
} from "../../../../Types/Student/Configuration";
import { StudentsTitlesType } from "../../../../Types/Student/json";

import {
  handleFormEvent,
  handleMUISelectEvent,
  removeMoreSpace,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { previousSchoolSchema } from "../../../../utils/validationRules";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import LoadingModal from "../../../../pages/LoadingModal";
import Home from "../../Home/Index";
import StudentAcademicDetails from "../StudentPreview/StudentAcademicDetails";

import DownArrow from "../../../../images/DownArrow.svg";
import { Title } from "../../../../stories/Title/Title";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import { DEFAULT_TIME, EMPTY_STRING } from "../../../../utils/constants";
import { GetStudentDetails } from "../../../../queries/common";
import { Keys } from "../../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { studentRegStepHeader } from "./NxtBasicDetails";
import { userDetails } from "../../../../Types/Accounting/other";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
const { Students } = require("../../../../json/title.json");

interface props {
  type?: Operation;
  step: number;
}
interface UpdateVars {
  token: string;
  student_id: string;
  inst_id: string;
  user_details: userDetails;
  input: academic_details;
}
const NxtPrevInstData = ({ step, type }: props) => {
  //Use Navigate useParams and others
  const { token } = useToken();
  const { InstId, studentId } = useParams();
  const stepsHeader = studentRegStepHeader();

  const navigate = useNavigate();

  //useStates for Datas
  const [formData, setFormData] = useState<studentPreviousSchoolData>({
    std_prev_tc_details: "",
    std_prev_inst: "",
    std_prev_quali_exam: "",
    std_prev_studied_board: "",
    std_prev_studied_state: "",
    std_prev_subject_studied: "",
    std_prev_total_marks: "",
    std_prev_total_per: 0,
    std_prev_tech_subject_studied: "",
    std_prev_tech_total_mark: 0,
    std_prev_tech_total_per: 0,
    std_prev_10_std_marks: 0,
    std_prev_10_std_per: 0,
    std_prev_10_lang_medium: "",
    std_prev_12_std_marks: 0,
    std_prev_12_std_per: 0,
    std_bank_name: "",
    std_bank_branch: "",
    std_bank_acct: "",
    std_bank_ifsc: "",
    std_adm_appl_no: "",
    std_adm_free_pymnt_seat: "",
    std_adm_state_or_outside: "",
    std_adm_seat_got_through: "",
    std_adm_category: "",
    std_adm_cet_no: "",
    std_adm_cet_rank: "",
    std_adm_eli_no: "",
    std_adm_eli_date: toIsoDate(DEFAULT_TIME),
    std_adm_cet_paid_rcpt_no: "",
    std_adm_cet_paid_rcpt_date: toIsoDate(DEFAULT_TIME),
    std_adm_cet_paid_amt: 0,
    std_adm_cet_alloted_category: "",
    std_10_board_name: "",
    std_10_grade_system: "",
    std_10_inst_name: "",
    std_10_lang_medium: "",
    std_10_reg_no: "",
    std_10_scored_marks: 0,
    std_10_scored_percentage: 0,
    std_10_state: "",
    std_10_total_marks: 0,
    std_10_year: "",
    std_12_course_name: "",
    std_12_grade_system: "",
    std_12_inst_name: "",
    std_12_lang_medium: "",
    std_12_reg_no: "",
    std_12_scored_marks: 0,
    std_12_scored_percentage: 0,
    std_12_state: "",
    std_12_total_marks: 0,
    std_12_year: "",
    std_challan_no: "",
    std_discipline_1: "",

    std_discipline_2: "",
    std_fee_amount: 0,
    std_fee_payment_date: "",
    std_kk_reservation: YesNo.NO,
    std_language_1: "",
    std_language_2: "",
    std_open_elective: "",
    std_prev_scored_marks: 0,
    std_program_name: "",
    std_ug_course_name: "",
    std_ug_doa: "",
    std_ug_doc: "",
    std_ug_grade_system: "",
    std_ug_inst_name: "",
    std_ug_lang_medium: "",
    std_ug_reg_no: "",
    std_ug_scored_marks: 0,
    std_ug_scored_percentage: 0,
    std_ug_state: "",
    std_ug_total_marks: 0,
    std_ug_univ_name: "",
    std_kannada_medium: "",
  });
  const dateTypeArray: string[] = [
    StudentRegistrationFieldNames.ADM_CET_PAID_RCPT_DATE,
    StudentRegistrationFieldNames.ADM_ELI_DATE,
  ];
  //Queries
  const [GetStudentConfig, { data: GetStudentSwConfig }] = useLazyQuery<
    GetSwConfigStudentDataEntryByInstIdData,
    GetSwConfigStudentDataEntryByInstIdVars
  >(GetSwConfigStudentDataEntryByInstId, {
    variables: { token, inst_id: InstId! },
  });
  const { user_details } = useLoggedInUserDetails();
  const { studentData } = useStudentDatabyId();
  //Mutations

  const [updatePreviousSchool, { loading: updationLoading }] = useMutation<
    { UpdateStudentOtherDetailsByStudentId: { id: number } },
    UpdateVars
  >(UpdateStudentOtherDetailsByStudentId);

  //custom Hooks
  const {
    PredefinedData: { dropDown: FreeOrPaymentSeat },
  } = usePredefinedDataByType(
    PredefinedDataTypes.FREEPAYMENTSEAT,
    EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: SeatGotThrough },
  } = usePredefinedDataByType(
    PredefinedDataTypes.SEATGOTTHROUGH,
    formData.std_adm_seat_got_through
  );
  const {
    PredefinedData: { dropDown: States },
  } = usePredefinedDataByType(
    PredefinedDataTypes.STATE,
    formData.std_prev_studied_state
  );
  const {
    PredefinedData: { dropDown: StateOrInterState },
  } = usePredefinedDataByType(
    PredefinedDataTypes.STATEORINTERSTATE,
    EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: Categories },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CATEGORY,
    formData.std_adm_category
  );

  //Methods
  const PreviousInstitutionDetailsBlock1 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PREVIOUS_INST_DATA &&
        data_field_block_number === 1 &&
        data_field_enable
    );
  const PreviousInstitutionDetailsBlock1AddedProps =
    PreviousInstitutionDetailsBlock1?.map((data) => {
      if (data.data_field_name === "std_prev_tc_details") {
        return { ...data, data_focus: "date" };
      }
      return data;
    });
  const PreviousInstitutionDetailsBlock2 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PREVIOUS_INST_DATA &&
        data_field_block_number === 2 &&
        data_field_enable
    );
  const PreviousInstitutionDetailsBlock3 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PREVIOUS_INST_DATA &&
        data_field_block_number === 3 &&
        data_field_enable
    );
  const PreviousInstBlock3 = PreviousInstitutionDetailsBlock3?.map((data) => {
    if (dateTypeArray.includes(data.data_field_name)) {
      return { ...data, data_type: "date" };
    }
    return data;
  });
  const PreviousInstitutionDetailsBlock4 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PREVIOUS_INST_DATA &&
        data_field_block_number === 4 &&
        data_field_enable
    );
  const PreviousInstBlock4 = PreviousInstitutionDetailsBlock4?.map((data) => {
    if (dateTypeArray.includes(data.data_field_name)) {
      return { ...data, data_type: "date" };
    }
    return data;
  });

  const PreviousInstitutionDetailsBlock5 =
    GetStudentSwConfig?.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_block_number, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PREVIOUS_INST_DATA &&
        data_field_block_number === 5 &&
        data_field_enable
    );
  const PreviousInstBlock5 = PreviousInstitutionDetailsBlock5?.map((data) => {
    if (dateTypeArray.includes(data.data_field_name)) {
      return { ...data, data_type: "date" };
    }
    return data;
  });

  const {
    PredefinedData: { dropDown: YESNO },
  } = usePredefinedDataByType(PredefinedDataTypes.YESNO, EMPTY_STRING);

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues: studentPreviousSchoolData) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const updateForm = async () => {
    const cet_receipt_date = formData.std_adm_eli_date
      ? new Date(formData.std_adm_eli_date).toISOString()
      : EMPTY_STRING;
    const eligibility_date = formData.std_adm_cet_paid_rcpt_date
      ? new Date(formData.std_adm_cet_paid_rcpt_date).toISOString()
      : EMPTY_STRING;
    await updatePreviousSchool({
      variables: {
        token,
        student_id: studentId!,
        inst_id: InstId!,
        user_details,
        input: {
          std_prev_tc_details: removeMoreSpace(formData.std_prev_tc_details),
          std_prev_inst: removeMoreSpace(formData.std_prev_inst),
          std_prev_quali_exam: removeMoreSpace(formData.std_prev_quali_exam),
          std_prev_studied_board: removeMoreSpace(
            formData.std_prev_studied_board
          ),
          std_prev_studied_state: removeMoreSpace(
            formData.std_prev_studied_state
          ),
          std_prev_subject_studied: removeMoreSpace(
            formData.std_prev_subject_studied
          ),
          std_prev_total_per: formData.std_prev_total_per
            ? formData.std_prev_total_per
            : 0,
          std_prev_tech_subject_studied: formData.std_prev_tech_subject_studied,
          std_prev_tech_total_mark: formData.std_prev_tech_total_mark
            ? formData.std_prev_tech_total_mark
            : 0,
          std_prev_tech_total_per: formData.std_prev_tech_total_per
            ? formData.std_prev_tech_total_per
            : 0,
          std_10_scored_marks: formData.std_10_scored_marks
            ? formData.std_10_scored_marks
            : 0,
          std_10_scored_percentage: formData.std_10_scored_percentage
            ? formData.std_10_scored_percentage
            : 0,
          std_10_lang_medium: formData.std_10_lang_medium,
          std_12_scored_marks: formData.std_12_scored_marks
            ? formData.std_12_scored_marks
            : 0,
          std_12_scored_percentage: formData.std_12_scored_percentage
            ? formData.std_12_scored_percentage
            : 0,
          std_bank_name: removeMoreSpace(formData.std_bank_name),
          std_bank_branch: removeMoreSpace(formData.std_bank_branch),
          std_bank_acct: formData.std_bank_acct,
          std_bank_ifsc: formData.std_bank_ifsc,
          std_adm_appl_no: formData.std_adm_appl_no,
          std_adm_free_pymnt_seat: removeMoreSpace(
            formData.std_adm_free_pymnt_seat
          ),
          std_adm_state_or_outside: removeMoreSpace(
            formData.std_adm_state_or_outside
          ),
          std_adm_seat_got_through: removeMoreSpace(
            formData.std_adm_seat_got_through
          ),
          std_adm_category: formData.std_adm_category,
          std_adm_cet_no: formData.std_adm_cet_no,
          std_adm_cet_rank: formData.std_adm_cet_rank,
          std_adm_eli_no: formData.std_adm_eli_no,
          std_adm_eli_date: eligibility_date,
          std_adm_cet_paid_rcpt_no: formData.std_adm_cet_paid_rcpt_no,
          std_adm_cet_paid_rcpt_date: cet_receipt_date,
          std_adm_cet_paid_amt: formData.std_adm_cet_paid_amt
            ? formData.std_adm_cet_paid_amt
            : 0,
          std_adm_cet_alloted_category: formData.std_adm_cet_alloted_category,
          std_prev_scored_marks: formData.std_prev_scored_marks,
          std_10_board_name: formData.std_10_board_name,
          std_10_grade_system: formData.std_10_grade_system,
          std_10_inst_name: formData.std_10_inst_name,
          std_10_reg_no: formData.std_10_reg_no,
          std_10_state: formData.std_10_state,
          std_10_total_marks: formData.std_10_total_marks,
          std_10_year: formData.std_10_year,
          std_12_course_name: formData.std_12_course_name,
          std_12_grade_system: formData.std_12_grade_system,
          std_12_inst_name: formData.std_12_inst_name,
          std_12_lang_medium: formData.std_12_lang_medium,
          std_12_reg_no: formData.std_12_reg_no,
          std_12_state: formData.std_12_state,
          std_12_total_marks: formData.std_12_total_marks,
          std_12_year: formData.std_12_year,
          std_challan_no: formData.std_challan_no,
          std_discipline_1: formData.std_discipline_1,
          std_discipline_2: formData.std_discipline_2,
          std_fee_amount: formData.std_fee_amount,
          std_fee_payment_date: formData.std_fee_payment_date,
          std_kk_reservation: formData.std_kk_reservation === YesNo.YES,
          std_language_1: formData.std_language_1,
          std_language_2: formData.std_language_2,
          std_open_elective: formData.std_open_elective,
          std_program_name: formData.std_program_name,
          std_ug_course_name: formData.std_ug_course_name,
          std_ug_doa: formData.std_ug_doa,
          std_ug_doc: formData.std_ug_doc,
          std_ug_grade_system: formData.std_ug_grade_system,
          std_ug_inst_name: formData.std_ug_inst_name,
          std_ug_lang_medium: formData.std_ug_lang_medium,
          std_ug_reg_no: formData.std_ug_reg_no,
          std_ug_scored_marks: formData.std_ug_scored_marks,
          std_ug_scored_percentage: formData.std_ug_scored_percentage,
          std_ug_state: formData.std_ug_state,
          std_ug_total_marks: formData.std_ug_total_marks,
          std_ug_univ_name: formData.std_ug_univ_name,
          std_kannada_medium: formData.std_kannada_medium === YesNo.YES,
        },
      },
      refetchQueries: [
        {
          query: GetStudentDetails,
          variables: {
            token,
            ids: studentId,
          },
        },
      ],
    });
    if (type === Operation.UPDATE) {
      navigate(
        `/${InstId}/admissions/students/${studentId}/NxtPreviousschooldata`
      );
    } else {
      navigate(`/${InstId}/admissions/students/4/${studentId}/nxtDefaultDocs`);
    }
  };
  //useEffect
  useEffect(() => {
    if (!studentData.loading && studentData.data) {
      const {
        std_prev_tc_details,
        std_prev_inst,
        std_prev_quali_exam,
        std_prev_studied_board,
        std_prev_studied_state,
        std_prev_subject_studied,
        std_prev_total_per,
        std_prev_tech_subject_studied,
        std_adm_free_pymnt_seat,
        std_prev_tech_total_mark,
        std_prev_tech_total_per,
        std_bank_name,
        std_bank_branch,
        std_bank_acct,
        std_bank_ifsc,
        std_adm_appl_no,
        std_adm_state_or_outside,
        std_adm_seat_got_through,
        std_adm_category,
        std_adm_cet_no,
        std_adm_cet_rank,
        std_adm_eli_no,
        std_adm_eli_date,
        std_adm_cet_paid_rcpt_no,
        std_adm_cet_paid_rcpt_date,
        std_adm_cet_paid_amt,
        std_adm_cet_alloted_category,
        std_10_board_name,
        std_10_grade_system,
        std_10_inst_name,
        std_10_lang_medium,
        std_10_reg_no,
        std_10_scored_marks,
        std_10_scored_percentage,
        std_10_state,
        std_10_total_marks,
        std_10_year,
        std_12_course_name,
        std_12_grade_system,
        std_12_inst_name,
        std_12_lang_medium,
        std_12_reg_no,
        std_12_scored_marks,
        std_12_scored_percentage,
        std_12_state,
        std_12_total_marks,
        std_12_year,
        std_challan_no,
        std_discipline_1,
        std_discipline_2,
        std_fee_amount,
        std_fee_payment_date,
        std_kk_reservation,
        std_language_1,
        std_language_2,
        std_open_elective,
        std_prev_scored_marks,
        std_program_name,
        std_ug_course_name,
        std_ug_doa,
        std_ug_doc,
        std_ug_grade_system,
        std_ug_inst_name,
        std_ug_lang_medium,
        std_ug_reg_no,
        std_ug_scored_marks,
        std_ug_scored_percentage,
        std_ug_state,
        std_ug_total_marks,
        std_ug_univ_name,
        std_kannada_medium,
      } = studentData.data.nodes[0].std_other_details;
      setFormData({
        std_prev_tc_details,
        std_prev_inst,
        std_prev_quali_exam,
        std_prev_studied_board,
        std_prev_studied_state,
        std_prev_subject_studied,
        std_prev_total_per,
        std_prev_tech_subject_studied,
        std_prev_tech_total_mark,
        std_prev_tech_total_per,
        std_bank_name,
        std_bank_branch,
        std_bank_acct,
        std_bank_ifsc,
        std_adm_appl_no,
        std_adm_free_pymnt_seat,
        std_adm_state_or_outside,
        std_adm_seat_got_through,
        std_adm_category,
        std_adm_cet_no,
        std_adm_cet_rank,
        std_adm_eli_no,
        std_adm_eli_date: std_adm_eli_date
          ? toIsoDate(std_adm_eli_date)
          : EMPTY_STRING,
        std_adm_cet_paid_rcpt_no,
        std_adm_cet_paid_rcpt_date: std_adm_cet_paid_rcpt_date
          ? toIsoDate(std_adm_cet_paid_rcpt_date)
          : EMPTY_STRING,
        std_adm_cet_paid_amt,
        std_adm_cet_alloted_category,
        std_10_board_name,
        std_10_grade_system,
        std_10_inst_name,
        std_10_lang_medium,
        std_10_reg_no,
        std_10_scored_marks,
        std_10_scored_percentage,
        std_10_state,
        std_10_total_marks,
        std_10_year,
        std_12_course_name,
        std_12_grade_system,
        std_12_inst_name,
        std_12_lang_medium,
        std_12_reg_no,
        std_12_scored_marks,
        std_12_scored_percentage,
        std_12_state,
        std_12_total_marks,
        std_12_year,
        std_challan_no,
        std_discipline_1,
        std_discipline_2,
        std_fee_amount,
        std_fee_payment_date,
        std_kk_reservation: std_kk_reservation ? YesNo.YES : YesNo.NO,
        std_language_1,
        std_language_2,
        std_open_elective,
        std_prev_scored_marks,
        std_program_name,
        std_ug_course_name,
        std_ug_doa,
        std_ug_doc,
        std_ug_grade_system,
        std_ug_inst_name,
        std_ug_lang_medium,
        std_ug_reg_no,
        std_ug_scored_marks,
        std_ug_scored_percentage,
        std_ug_state,
        std_ug_total_marks,
        std_ug_univ_name,
        std_kannada_medium: std_kannada_medium ? YesNo.YES : YesNo.NO,
      });
    }
  }, [studentData.loading, studentData.data]);

  useEffect(() => {
    if (token) {
      GetStudentConfig();
    }
  }, [token, GetStudentConfig]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div
        className={
          type === Operation.UPDATE ? "previous-inst-update" : "previous-inst"
        }>
        {type === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        {type === Operation.UPDATE && (
          <Title>
            {Students.PreviousInstitutionDetails.Titles.map(
              (title: StudentsTitlesType, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.Update}</React.Fragment>
                );
              }
            )}
          </Title>
        )}

        <StudentAcademicDetails />

        <Formik
          initialValues={formData}
          validationSchema={previousSchoolSchema}
          onSubmit={updateForm}
          enableReinitialize>
          {(meta) => {
            return (
              <Form
                className={
                  type === Operation.UPDATE ? "h-30" : "previous-inst__form"
                }>
                <div className="previous-inst__form--details">
                  <div className="details">
                    <h4>Current Academic Details</h4>
                  </div>
                  {PreviousInstitutionDetailsBlock1 &&
                  PreviousInstitutionDetailsBlock1.length ? (
                    <div className="col studentregistration__span-2">
                      {PreviousInstitutionDetailsBlock1AddedProps?.map(
                        (previousInstBlock1, index) => {
                          if (
                            previousInstBlock1.data_field_list_data_key ===
                            PredefinedDataTypes.STATE
                          ) {
                            return (
                              <div className="label-grid" key={index}>
                                <Label>
                                  {previousInstBlock1.data_field_label}
                                </Label>
                                <FormAutocomplete
                                  className={formClasses.inputRoot}
                                  options={States!}
                                  onKeyDown={(e) => {
                                    if (e.key === Keys.ENTER) {
                                      e.preventDefault();
                                      handleMUISelectEvent(e);
                                    }
                                    if (e.key === Keys.BACKSPACE) {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_prev_studied_state: EMPTY_STRING,
                                        };
                                      });
                                    }
                                  }}
                                  value={
                                    States?.find(
                                      ({ value }) =>
                                        value ===
                                        formData.std_prev_studied_state
                                    )! ?? null
                                  }
                                  onChange={(e, newValue) => {
                                    if (newValue) {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_prev_studied_state: (
                                            newValue as optionsType
                                          )?.value!,
                                        };
                                      });
                                    } else {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_prev_studied_state: "",
                                        };
                                      });
                                    }
                                  }}
                                  openOnFocus
                                  freeSolo
                                  popupIcon={<img src={DownArrow} alt="/" />}
                                  forcePopupIcon
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={(e) => {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_prev_studied_state:
                                              e.target.value!,
                                          };
                                        });
                                      }}
                                      fullWidth
                                      className={formClasses.formControlRoot}
                                    />
                                  )}
                                />
                              </div>
                            );
                          }
                          if (
                            previousInstBlock1.data_field_name ===
                            StudentRegistrationFieldNames.STD_KK_RESERVATION
                          ) {
                            return (
                              <div className="label-grid" key={index}>
                                <Label>
                                  {previousInstBlock1.data_field_label}
                                </Label>
                                <FormAutocomplete
                                  className={formClasses.inputRoot}
                                  options={YESNO}
                                  onKeyDown={(e) => {
                                    if (e.key === Keys.ENTER) {
                                      e.preventDefault();
                                      handleMUISelectEvent(e);
                                    }
                                    if (e.key === Keys.BACKSPACE) {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_kk_reservation: EMPTY_STRING,
                                        };
                                      });
                                    }
                                  }}
                                  value={
                                    YESNO?.find(
                                      ({ value }) =>
                                        value === formData.std_kk_reservation
                                    )! ?? null
                                  }
                                  onChange={(e, newValue) => {
                                    if (newValue) {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_kk_reservation: (
                                            newValue as optionsType
                                          )?.value!,
                                        };
                                      });
                                    } else {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_kk_reservation: "",
                                        };
                                      });
                                    }
                                  }}
                                  openOnFocus
                                  freeSolo
                                  popupIcon={<img src={DownArrow} alt="/" />}
                                  forcePopupIcon
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={(e) => {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_kk_reservation: e.target.value!,
                                          };
                                        });
                                      }}
                                      fullWidth
                                      className={formClasses.formControlRoot}
                                    />
                                  )}
                                />
                              </div>
                            );
                          }
                          if (
                            previousInstBlock1.data_field_name ===
                            StudentRegistrationFieldNames.STD_KANNADA_MEDIUM
                          ) {
                            return (
                              <div className="label-grid" key={index}>
                                <Label>
                                  {previousInstBlock1.data_field_label}
                                </Label>
                                <FormAutocomplete
                                  className={formClasses.inputRoot}
                                  options={YESNO}
                                  onKeyDown={(e) => {
                                    if (e.key === Keys.ENTER) {
                                      e.preventDefault();
                                      handleMUISelectEvent(e);
                                    }
                                    if (e.key === Keys.BACKSPACE) {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_kannada_medium: EMPTY_STRING,
                                        };
                                      });
                                    }
                                  }}
                                  value={
                                    YESNO?.find(
                                      ({ value }) =>
                                        value === formData.std_kannada_medium
                                    )! ?? null
                                  }
                                  onChange={(e, newValue) => {
                                    if (newValue) {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_kannada_medium: (
                                            newValue as optionsType
                                          )?.value!,
                                        };
                                      });
                                    } else {
                                      setFormData((prevValues) => {
                                        return {
                                          ...prevValues,
                                          std_kannada_medium: "",
                                        };
                                      });
                                    }
                                  }}
                                  openOnFocus
                                  freeSolo
                                  popupIcon={<img src={DownArrow} alt="/" />}
                                  forcePopupIcon
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={(e) => {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_kannada_medium: e.target.value!,
                                          };
                                        });
                                      }}
                                      fullWidth
                                      className={formClasses.formControlRoot}
                                    />
                                  )}
                                />
                              </div>
                            );
                          }
                          return (
                            <React.Fragment key={index}>
                              <Input
                                LabelName={previousInstBlock1.data_field_label}
                                values={
                                  formData[
                                    previousInstBlock1.data_field_name
                                  ] === 0
                                    ? ""
                                    : formData[
                                        previousInstBlock1.data_field_name
                                      ]
                                }
                                onKeyDown={handleFormEvent}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                name={previousInstBlock1.data_field_name}
                                autoFocus={previousInstBlock1.data_focus}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                  ) : null}
                  {PreviousInstitutionDetailsBlock2 &&
                  PreviousInstitutionDetailsBlock2.length ? (
                    <div className="row g-0 frame-space">
                      <div className="details">
                        <h4>Previous Institution Details</h4>
                      </div>
                      <div className="col studentregistration__span-2">
                        {PreviousInstitutionDetailsBlock2?.map((pd2, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                LabelName={pd2.data_field_label}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                values={formData[pd2.data_field_name]}
                                onKeyDown={handleFormEvent}
                                name={pd2.data_field_name}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  {PreviousInstBlock3 && PreviousInstBlock3.length ? (
                    <div className="row g-0 frame-space">
                      <div className="details">
                        <h4>10th Details</h4>
                      </div>
                      <div className="col studentregistration__span-2">
                        {PreviousInstBlock3?.map(
                          (previousInstBlock3, index) => {
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.FREEPAYMENTSEAT
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={FreeOrPaymentSeat!}
                                    value={
                                      FreeOrPaymentSeat?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_free_pymnt_seat
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_free_pymnt_seat:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.STATEORINTERSTATE
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={StateOrInterState!}
                                    value={
                                      StateOrInterState?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_state_or_outside
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_state_or_outside:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.SEATGOTTHROUGH
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={SeatGotThrough!}
                                    value={
                                      SeatGotThrough?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_seat_got_through
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_seat_got_through:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.CATEGORY
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={Categories!}
                                    value={
                                      Categories?.find(
                                        ({ value }) =>
                                          value === formData.std_adm_category
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_category: e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            return (
                              <React.Fragment key={index}>
                                <Input
                                  LabelName={
                                    previousInstBlock3.data_field_label
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleValueChange(e);
                                    meta.handleChange(e);
                                  }}
                                  values={
                                    formData[
                                      previousInstBlock3.data_field_name
                                    ] === 0
                                      ? ""
                                      : formData[
                                          previousInstBlock3.data_field_name
                                        ]
                                  }
                                  onKeyDown={handleFormEvent}
                                  name={previousInstBlock3.data_field_name}
                                  type={previousInstBlock3.data_type}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                  {PreviousInstBlock4 && PreviousInstBlock4.length ? (
                    <div className="row g-0 frame-space">
                      <div className="details">
                        <h4>12th Details</h4>
                      </div>
                      <div className="col studentregistration__span-2">
                        {PreviousInstBlock4?.map(
                          (previousInstBlock3, index) => {
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.FREEPAYMENTSEAT
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={FreeOrPaymentSeat!}
                                    value={
                                      FreeOrPaymentSeat?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_free_pymnt_seat
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_free_pymnt_seat:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.STATEORINTERSTATE
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={StateOrInterState!}
                                    value={
                                      StateOrInterState?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_state_or_outside
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_state_or_outside:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.SEATGOTTHROUGH
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={SeatGotThrough!}
                                    value={
                                      SeatGotThrough?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_seat_got_through
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_seat_got_through:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.CATEGORY
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={Categories!}
                                    value={
                                      Categories?.find(
                                        ({ value }) =>
                                          value === formData.std_adm_category
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_category: e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            return (
                              <React.Fragment key={index}>
                                <Input
                                  LabelName={
                                    previousInstBlock3.data_field_label
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleValueChange(e);
                                    meta.handleChange(e);
                                  }}
                                  values={
                                    formData[
                                      previousInstBlock3.data_field_name
                                    ] === 0
                                      ? ""
                                      : formData[
                                          previousInstBlock3.data_field_name
                                        ]
                                  }
                                  onKeyDown={handleFormEvent}
                                  name={previousInstBlock3.data_field_name}
                                  type={previousInstBlock3.data_type}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                  {PreviousInstBlock5 && PreviousInstBlock5.length ? (
                    <div className="row g-0 frame-space">
                      <div className="details">
                        <h4>UG Details</h4>
                      </div>
                      <div className="col studentregistration__span-2">
                        {PreviousInstBlock5?.map(
                          (previousInstBlock3, index) => {
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.FREEPAYMENTSEAT
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={FreeOrPaymentSeat!}
                                    value={
                                      FreeOrPaymentSeat?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_free_pymnt_seat
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_free_pymnt_seat: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_free_pymnt_seat:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.STATEORINTERSTATE
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={StateOrInterState!}
                                    value={
                                      StateOrInterState?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_state_or_outside
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_state_or_outside: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_state_or_outside:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.SEATGOTTHROUGH
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={SeatGotThrough!}
                                    value={
                                      SeatGotThrough?.find(
                                        ({ value }) =>
                                          value ===
                                          formData.std_adm_seat_got_through
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through:
                                              EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_seat_got_through: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_seat_got_through:
                                                e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            if (
                              previousInstBlock3.data_field_list_data_key ===
                              PredefinedDataTypes.CATEGORY
                            ) {
                              return (
                                <div className="label-grid" key={index}>
                                  <Label>
                                    {previousInstBlock3.data_field_label}
                                  </Label>
                                  <FormAutocomplete
                                    className={formClasses.inputRoot}
                                    options={Categories!}
                                    value={
                                      Categories?.find(
                                        ({ value }) =>
                                          value === formData.std_adm_category
                                      )! ?? null
                                    }
                                    openOnFocus
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: (
                                              newValue as optionsType
                                            )?.value!,
                                          };
                                        });
                                      } else {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: EMPTY_STRING,
                                          };
                                        });
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.ENTER) {
                                        e.preventDefault();
                                        handleMUISelectEvent(e);
                                      }
                                      if (e.key === Keys.BACKSPACE) {
                                        setFormData((prevValues) => {
                                          return {
                                            ...prevValues,
                                            std_adm_category: "",
                                          };
                                        });
                                      }
                                    }}
                                    freeSolo
                                    popupIcon={<img src={DownArrow} alt="/" />}
                                    forcePopupIcon
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onChange={(e) => {
                                          setFormData((prevValues) => {
                                            return {
                                              ...prevValues,
                                              std_adm_category: e.target.value!,
                                            };
                                          });
                                        }}
                                        fullWidth
                                        className={formClasses.formControlRoot}
                                      />
                                    )}
                                  />
                                </div>
                              );
                            }
                            return (
                              <React.Fragment key={index}>
                                <Input
                                  LabelName={
                                    previousInstBlock3.data_field_label
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleValueChange(e);
                                    meta.handleChange(e);
                                  }}
                                  values={
                                    formData[
                                      previousInstBlock3.data_field_name
                                    ] === 0
                                      ? ""
                                      : formData[
                                          previousInstBlock3.data_field_name
                                        ]
                                  }
                                  onKeyDown={handleFormEvent}
                                  name={previousInstBlock3.data_field_name}
                                  type={previousInstBlock3.data_type}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                {studentData.data && type === Operation.UPDATE ? (
                  <Button type="submit" mode="save" />
                ) : (
                  <Button mode="save-continue" type="submit" />
                )}
                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    if (type === Operation.UPDATE) {
                      navigate(
                        `/${InstId}/admissions/students/${studentId}/previousschooldata`
                      );
                    } else {
                      navigate(
                        `/${InstId}/admissions/students/2/${studentId}/nxtDefaultDocs`
                      );
                    }
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <LoadingModal flag={updationLoading} />
    </>
  );
};

export default NxtPrevInstData;
