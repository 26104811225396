import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { msgType, responseType } from "../../../../../utils/Form.types";
import {
  GetAcdReportCardsData,
  GetAcdReportCardsVars,
  GetAcdReportTestsData,
  GetAcdReportTestsNode,
  GetAcdReportTestsVars,
} from "../../../types/Progresscard";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcdReportCards,
  GetAcdReportTests,
} from "../../../queries/reportcard";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import { Direction, Operation } from "../../../../../utils/Enum.types";
import { ROWS_PER_PAGE } from "../../../../../utils/constants";
import { isOptionEqualToValue } from "../../../../../utils/UtilFunctions";
import { Keys } from "../../../../../utils/Enum.keys";
import { AddAcdReportTests } from "../../../queries/reportcard/mutation";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import Enter from "../../../../../images/Enter.svg";
import Delete from "../../../../../images/Delete.svg";
import useTests, { GetAcdTestNameDetails } from "../../../hooks/useTests";
import MessageModal from "../../../../../pages/MessageModal";
import LoadingModal from "../../../../../pages/LoadingModal";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../../styles/AutocompleteStyles";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../../styles/AutocompleteListStyles";
interface Props {
  operation: Operation;
}
const AllocateTest = ({ operation }: Props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [searchText, setSearchText] = useState("");
  const [deletedItems, setDeletedItems] = useState<GetAcdTestNameDetails[]>([]);

  const [reportSelected, setReportSelected] = useState<responseType | null>(
    null
  );
  const [testId, setTestId] = useState<responseType | null>(null);

  const [items, setItems] = useState<GetAcdTestNameDetails[]>([]);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const testRef = useRef<HTMLSelectElement>(null);
  const testInputRef = testRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const [GetReportCardData, { data: reportList }] = useLazyQuery<
    GetAcdReportCardsData,
    GetAcdReportCardsVars
  >(GetAcdReportCards, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      first: ROWS_PER_PAGE,
      after: null,
      orderBy: {
        direction: Direction.ASC,
      },
    },
  });
  useEffect(() => {
    if (token) {
      GetReportCardData();
    }
  }, [token, GetReportCardData]);

  const [
    GetAcdReportTestDetails,
    { data: TestDetails, loading, error, fetchMore },
  ] = useLazyQuery<GetAcdReportTestsData, GetAcdReportTestsVars>(
    GetAcdReportTests,
    {
      variables: {
        token,
        inst_id: Number(InstId),
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        acd_report_card_id: reportSelected ? reportSelected.value : 0,
      },
    }
  );
  useEffect(() => {
    if (token && InstId!) {
      GetAcdReportTestDetails();
    }
  }, [token, GetAcdReportTestDetails]);

  const reportDropDown = reportList
    ? reportList?.GetAcdReportCards.edges.map((list) => ({
        label: list.node.report_name,
        value: list.node.id,
      }))
    : [];

  const { acdTests } = useTests(false, 0);

  const [AllocateTests, { loading: creationLoading }] = useMutation(
    AddAcdReportTests,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  useEffect(() => {
    if (acdTests.data && !acdTests.loading) {
      const filteredResult = acdTests.data.GetAcdTestName.filter((el) => {
        return !items.find((element) => {
          return element.id === el.id;
        });
      });
      setFilteredTests(
        filteredResult.map((edge) => ({
          value: edge.id,
          // eslint-disable-next-line
          label: edge.test_name,
          isChecked: false,
        }))
      );
    }
  }, [acdTests.data, acdTests.loading, searchText, items]);

  const [filteredtests, setFilteredTests] = useState<responseType[]>([]);

  const handleAllocateSubjects = (operation: Operation) => {
    if (items.length === 0) {
      alert("Please Select Subjects");
      testInputRef?.select();
      testInputRef?.focus();
      return;
    }
    AllocateTests({
      variables: {
        token,
        inst_id: Number(InstId),
        user_details,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        acd_report_card_id: reportSelected ? reportSelected.value : 0,
        input: items.map((test, index) => ({
          idx: index + 1,
          test_name_id: test.id,
        })),
      },

      refetchQueries: [
        {
          query: GetAcdReportTests,
          variables: {
            user_details,

            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            acd_report_card_id: reportSelected ? reportSelected.value : 0,
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && operation === Operation.CREATE) {
        setMessage({
          flag: true,
          message: "Successfully allocted Tests ",
          operation: Operation.CREATE,
        });
      } else if (operation === Operation.UPDATE && data) {
        setMessage({
          flag: true,
          message: "Successfully Updated Tests ",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleItems = () => {
    const filteredTest =
      acdTests.data &&
      acdTests.data.GetAcdTestName.find((test) => test.id === testId?.value);

    const itemAlreadyExists = items.filter((d) => d.id === testId?.value);
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate Test entry");
      setTestId(null);

      testInputRef?.select();
      testInputRef?.focus();
      return;
    }

    const updatedData = filteredtests?.map((id) => {
      if (id.value === testId?.value) {
        const newData = {
          ...id,
          value: id.value,
          label: id.label,
          isChecked: !id.isChecked,
        };
        return newData;
      }
      return id;
    });
    setFilteredTests(updatedData);
    if (testId?.value && filteredTest) {
      setItems((items) => [
        ...items,
        {
          id: filteredTest.id,
          test_name: filteredTest.test_name,
          show_marks_in_grade: filteredTest.show_marks_in_grade,
          test_type_id: filteredTest.test_type_id,
          is_marks_derived: filteredTest.is_marks_derived,
          is_non_academic: filteredTest.is_non_academic,
          show_marks_in_std_login: filteredTest.show_marks_in_std_login,
          test_type_details: filteredTest.test_type_details,
          add_test_remarks: filteredTest.add_test_remarks,
        },
      ]);
    }
    setTestId(null);
    setSearchText("");
    testInputRef?.select();
    testInputRef?.focus();
  };
  const handleClose = () => {
    if (
      message.operation !== Operation.NONE &&
      message.flag &&
      operation === Operation.UPDATE
    ) {
      setItems([]);
    }
    if (message.operation !== Operation.NONE && message.flag) {
      setItems([]);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handleItemDeleted = (ids: number) => {
    const updatedItems = items.filter(({ id }) => id !== ids);
    const filteredupdatedData = filteredtests.map((test) => {
      if (test.value === ids) {
        const newData = {
          ...test,
          isChecked: false,
        };
        return newData;
      }
      return test;
    });
    setFilteredTests(filteredupdatedData);
    setItems(updatedItems);
    if (operation === Operation.UPDATE) {
      const deletedItem = items.find((data) => data.id === ids);
      setDeletedItems((prevDeletedItems) =>
        deletedItem ? [...prevDeletedItems, deletedItem] : prevDeletedItems
      );
    }
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Allocate Test</Title>
      <div className="report-card-test">
        <div className="report-card-test__filters row g-0">
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={reportDropDown!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, reportSelected)
              }
              value={reportSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setReportSelected(newValue as responseType);
                } else {
                  setReportSelected(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setReportSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Report Name"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        </div>
        <div className="report-card-test__tableblock">
          <TableContainer className="report-card-test__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sl No</TableCell>
                  <TableCell>Test Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="report-card-test__table--row">
                  <TableCell id="td-center" className="report-card-test__table--slno"> {items.length + 1}</TableCell>
                  <TableCell>
                    <FormAutocomplete
                      ref={testRef}
                      className={formClasses.inputRoot}
                      // disabled={!state}
                      value={testId}
                      options={filteredtests.filter(
                        ({ isChecked }) => !isChecked
                      )}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option as responseType, testId)
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (testId?.value) {
                            buttonRef.current?.focus();
                          }
                        } else if (e.key === Keys.BACKSPACE) {
                          setTestId(null);
                        }
                      }}
                      // disabled={
                      //   !classSelected.value ||
                      //   !semesterSelected.value ||
                      //   !sectionSelected.value
                      // }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          const data = newValue as responseType;
                          setTestId({
                            label: data.label,
                            value: data.value,
                            isChecked: true,
                          });
                        } else {
                          setTestId(null);
                        }
                      }}
                      openOnFocus
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setSearchText(e.target.value)}
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </TableCell>
                  {/* <TableCell>
                    {
                      subjects.data?.GetAcdSubject.edges.find(
                        (d) => d.node.id === subjectId?.value
                      )?.node.subj_board_code!
                    }
                  </TableCell>

                  <TableCell>
                    {subjects.data?.GetAcdSubject.edges.find(
                      (d) => d.node.id === subjectId?.value
                    )?.node.subj_is_core_subject
                      ? "Core"
                      : subjectId?.value &&
                        subjects.data?.GetAcdSubject.edges.find(
                          (d) => d.node.id === subjectId?.value
                        )?.node.subj_is_lang
                      ? "Language"
                      : subjectId?.value &&
                        subjects.data?.GetAcdSubject.edges.find(
                          (d) => d.node.id === subjectId?.value
                        )?.node.subj_is_elective
                      ? "Elective"
                      : subjectId?.value &&
                        subjects.data?.GetAcdSubject.edges.find(
                          (d) => d.node.id === subjectId?.value
                        )?.node.subj_is_lab
                      ? "Lab"
                      : EMPTY_STRING}
                  </TableCell> */}
                  <TableCell id="td-center" className="report-card-test__table--actions" >
                    <button
                      disabled={!testId?.value}
                      ref={buttonRef}
                      onClick={(e: React.FormEvent) => handleItems()}
                    >
                      <img alt="/" src={Enter} />
                    </button>
                  </TableCell>
                </TableRow>
                {items.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        className="allocatesubjects__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.test_name}</TableCell>

                      <TableCell
                        className="allocatesubjects__table--actions"
                        id="td-center"
                      >
                        {operation !== Operation.DELETE &&
                        operation === Operation.CREATE ? (
                          <img
                            src={Delete}
                            alt="/"
                            onClick={() => handleItemDeleted(item.id)}
                          />
                        ) : null}
                        {operation !== Operation.DELETE &&
                        operation === Operation.UPDATE ? (
                          <img
                            src={Delete}
                            alt="/"
                            onClick={() => handleItemDeleted(item.id)}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {operation === Operation.CREATE && (
          <Button
            mode="save"
            disabled={!items.length}
            onClick={() => {
              handleAllocateSubjects(operation);
            }}
          />
        )}
        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={acdTests.loading || loading} />
    </>
  );
};

export default AllocateTest;
