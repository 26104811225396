import React, { useEffect, useRef, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import {
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import Actions from "../../../images/Enter.svg";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { msgType, responseType } from "../../../utils/Form.types";
import DownArrow from "../../../images/DownArrow.svg";

import { Keys } from "../../../utils/Enum.keys";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import Modal from "react-modal";
import View from "./View";
import {
  isOptionEqualToValue,
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
  Operation,
} from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddAcdFeedbackFromDetails,
  DeleteAcdFeedbackFromDetails,
  UpdateAcdFeedbackFromDetails,
} from "../queries/teacher_association/mutations";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import { EMPTY_STRING, TODAY_DATE } from "../../../utils/constants";
import MessageModal from "../../../pages/MessageModal";
import {
  GetAcdFeedbackFormDetails,
  GetAcdFeedbackForms,
} from "../queries/general";
import {
  GetAcdFeedbackFormsData,
  GetAcdFeedbackFormsVars,
  GetAcdFeedbackFromDetailsData,
  GetAcdFeedbackFromDetailsVars,
} from "../types/subject/Subject";
import { AntSwitch } from "../../../pages/Switch";
import {
  GetAcdBranchesByInstIdData,
  GetAcdClassByInstIdData,
  GetAcdSemesterByInstIdData,
} from "../types/genreal";
import { DepartmentListVarsByInstId } from "../../../Types/Student";
import { GetAcdSemestersByInstId } from "../queries/instdetails/semester/byId";
import { GetAcdClassesByInstId } from "../queries/instdetails/class/byId";
import { GetAcdBranchesByInstId } from "../queries/instdetails/branch/byId";
import useAcdDropDownData from "../hooks/useAcdDropDownData";
import {
  formClasses,
  RequiredAutoComplete,
} from "../../../styles/AutocompleteStyles";
const Index = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [view, setView] = useState(false);
  const [isAcademic, setIsAcademic] = useState(false);
  const [entries, setEntries] = useState<string[]>([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentInput, setCurrentInput] = useState<string>("");
  const { branchLabel, classLabel, semesterLabel, sectionLabel } =
    useInstLabels();
  const [unmappedClasses, setUnMappedClasses] = useState<responseType[]>([]);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const [operation, setOperation] = useState(Operation.CREATE);
  const { user_details } = useLoggedInUserDetails();

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const subjectAllocationLevel =
    configData && configData.data?.GetSwConfigVariables[0].config_string_value;
  const { activeAcademicYearData } = useActiveAcademicYear();
  const navigate = useNavigate();
  const classRefList = useRef<HTMLInputElement[]>([]);
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [semId, setSemId] = useState<responseType | null>(null);
  const [formSelected, setFormSelected] = useState<responseType | null>(null);

  const feeDescInputRef = RefsByTagName(feeDescRef);

  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const [GetSemestersByInstId, { data }] = useLazyQuery<
    GetAcdSemesterByInstIdData,
    DepartmentListVarsByInstId
  >(GetAcdSemestersByInstId);
  useEffect(() => {
    if (InstId && token) {
      GetSemestersByInstId({
        variables: {
          token,
          inst_id: InstId,
        },
      });
    }
  }, [InstId, token, GetSemestersByInstId]);
  const semDropDown =
    data &&
    data.GetAcdSemestersByInstId.map((data) => ({
      label: data.sem_desc,
      value: data.id,
    }));
  const [GetClassesByInstId, { data: classData }] = useLazyQuery<
    GetAcdClassByInstIdData,
    DepartmentListVarsByInstId
  >(GetAcdClassesByInstId);
  const classDropDown =
    classData &&
    classData.GetAcdClassesByInstId.map((data) => ({
      label: data.class_desc,
      value: data.id,
    }));
  useEffect(() => {
    if (InstId && token) {
      GetClassesByInstId({
        variables: {
          token,
          inst_id: InstId,
        },
      });
    }
  }, [InstId, token, GetClassesByInstId]);
  const [GetBranchesByInstId, { data: branchData }] = useLazyQuery<
    GetAcdBranchesByInstIdData,
    DepartmentListVarsByInstId
  >(GetAcdBranchesByInstId);
  useEffect(() => {
    if (InstId && token) {
      GetBranchesByInstId({
        variables: {
          token,
          inst_id: InstId,
        },
      });
    }
  }, [InstId, token, GetBranchesByInstId]);
  const branchDropDown =
    branchData &&
    branchData.GetAcdBranchesByInstId.map((data) => ({
      label: data.branch_desc,
      value: data.id,
    }));
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const AddClassRefs = (e: HTMLInputElement) => {
    if (e && !classRefList.current.includes(e)) {
      classRefList.current.push(e);
    }
  };
  const [formName, setFormName] = useState("");
  const [GetAcdFeedbackFrom] = useLazyQuery<
    GetAcdFeedbackFromDetailsData,
    GetAcdFeedbackFromDetailsVars
  >(GetAcdFeedbackFormDetails, {
    variables: {
      acd_yr_id: activeAcdId ? activeAcdId : 0,
      inst_id: InstId!,
      token,
      form_name: formSelected ? formSelected.label : EMPTY_STRING,
    },
  });

  const [GetAcdFeedbackFormDropDown, { data: FormDropDown }] = useLazyQuery<
    GetAcdFeedbackFormsData,
    GetAcdFeedbackFormsVars
  >(GetAcdFeedbackForms, {
    variables: {
      acd_yr_id: activeAcdId ? activeAcdId : 0,
      inst_id: InstId!,
      token,
      allotted_level: subjectAllocationLevel!,
      allotted_id:
        subjectAllocationLevel === AcdSubjectAllocationData.CLASS
          ? classId?.value!
          : subjectAllocationLevel === AcdSubjectAllocationData.SEMESTER
          ? semId?.value!
          : 0!,
      today_date: toIsoDate(TODAY_DATE),
      query_type:
        operation === Operation.DELETE
          ? "ALL_FORMS"
          : "ON_GOING_AND_FUTURE_FORMS",
      pr_emp_id: 0,
    },
  });

  const formDropDown = FormDropDown
    ? FormDropDown.GetAcdFeedbackForms.map((data) => ({
        label: data.form_name,
        value: data.id,
      }))
    : [];
  useEffect(() => {
    if ((branchId || classId || semId) && subjectAllocationLevel) {
      GetAcdFeedbackFormDropDown();
    }
  }, [
    branchId,
    classId,
    semId,
    subjectAllocationLevel,
    GetAcdFeedbackFormDropDown,
  ]);
  useEffect(() => {
    if (
      (branchId || classId || semId) &&
      formSelected &&
      subjectAllocationLevel
    ) {
      GetAcdFeedbackFrom().then(({ data }) => {
        if (data && data.GetAcdFeedbackFormDetails) {
          setEntries(
            data.GetAcdFeedbackFormDetails.map((res) => res.rating_factor)
          );
          setStartDate(
            toInputStandardDate(
              data.GetAcdFeedbackFormDetails[0].from_name_details.form_st_date
            )
          );
          setEndDate(
            toInputStandardDate(
              data.GetAcdFeedbackFormDetails[0].from_name_details.form_end_date
            )
          );
          setIsAcademic(
            data.GetAcdFeedbackFormDetails[0].from_name_details.is_academic
          );
        }
      });
    }
  }, [
    branchId,
    classId,
    semId,
    formSelected,
    subjectAllocationLevel,
    GetAcdFeedbackFrom,
  ]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [AddFeedBack] = useMutation(AddAcdFeedbackFromDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [UpdateFeedBack] = useMutation(UpdateAcdFeedbackFromDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteFeedBack] = useMutation(DeleteAcdFeedbackFromDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleAddOrUpdateEntry = () => {
    if (currentInput.trim() !== "" && entries.length < 15) {
      if (editIndex !== null) {
        const updatedEntries = [...entries];
        updatedEntries[editIndex] = currentInput;
        setEntries(updatedEntries);
        setEditIndex(null);
      } else {
        setEntries([...entries, currentInput]);
      }
      setCurrentInput("");
    } else if (entries.length >= 15) {
      alert("You cannot add more than 15 entries.");
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInput(event.target.value);
  };
  const handleEdit = (index: number) => {
    setCurrentInput(entries[index]);
    setEditIndex(index);
  };
  const HandleSubmit = () => {
    AddFeedBack({
      variables: {
        token,
        inst_id: InstId,
        acd_yr_id: activeAcdId,

        user_details,
        allotted_level: subjectAllocationLevel,
        allotted_ids: unmappedClasses
          .filter((res) => res.isChecked)
          .map((data) => data.value),
        input: {
          form_name: formName,
          form_st_date: toIsoDate(startDate),
          form_end_date: toIsoDate(endDate),
          is_academic: isAcademic,
          form_details: entries.map((data, idx) => ({
            rating_factor: data,
            idx: idx + 1,
          })),
        },
      },
      refetchQueries: [
        {
          query: GetAcdFeedbackFormDetails,
          variables: {
            acd_yr_id: activeAcdId ? activeAcdId : 0,
            inst_id: InstId!,
            token,
            form_name: formSelected ? formSelected.label : EMPTY_STRING,
          },
        },
        {
          query: GetAcdFeedbackForms,
          variables: {
            acd_yr_id: activeAcdId ? activeAcdId : 0,
            inst_id: InstId!,
            token,
            allotted_level: subjectAllocationLevel!,
            allotted_id:
              subjectAllocationLevel === AcdSubjectAllocationData.CLASS
                ? classId?.value!
                : subjectAllocationLevel === AcdSubjectAllocationData.SEMESTER
                ? semId?.value!
                : 0!,
            today_date: toIsoDate(TODAY_DATE),
            query_type: "ON_GOING_AND_FUTURE_FORMS",
            pr_emp_id: 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "FeedBack Updated Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const HandleUpdate = () => {
    UpdateFeedBack({
      variables: {
        token,
        inst_id: InstId,
        acd_yr_id: activeAcdId,
        user_details,
        input: {
          form_name: formSelected?.label,
          form_st_date: toIsoDate(startDate),
          form_end_date: toIsoDate(endDate),
          is_academic: true,
          form_details: entries.map((data, idx) => ({
            rating_factor: data,
            idx: idx + 1,
          })),
        },
      },
      refetchQueries: [
        {
          query: GetAcdFeedbackFormDetails,
          variables: {
            acd_yr_id: activeAcdId ? activeAcdId : 0,
            inst_id: InstId!,
            token,
            form_name: formSelected ? formSelected.label : EMPTY_STRING,
          },
        },
        {
          query: GetAcdFeedbackForms,
          variables: {
            acd_yr_id: activeAcdId ? activeAcdId : 0,
            inst_id: InstId!,
            token,
            allotted_level: subjectAllocationLevel!,
            allotted_id:
              subjectAllocationLevel === AcdSubjectAllocationData.CLASS
                ? classId?.value!
                : subjectAllocationLevel === AcdSubjectAllocationData.SEMESTER
                ? semId?.value!
                : 0!,
            today_date: toIsoDate(TODAY_DATE),
            query_type: "ON_GOING_AND_FUTURE_FORMS",
            pr_emp_id: 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "FeedBack Submitted Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const HandleDelete = () => {
    DeleteFeedBack({
      variables: {
        token,
        inst_id: InstId,
        acd_yr_id: activeAcdId,
        user_details,
        form_name: formSelected?.label,
      },
      refetchQueries: [
        {
          query: GetAcdFeedbackFormDetails,
          variables: {
            acd_yr_id: activeAcdId ? activeAcdId : 0,
            inst_id: InstId!,
            token,
            form_name: formSelected ? formSelected.label : EMPTY_STRING,
          },
        },
        {
          query: GetAcdFeedbackForms,
          variables: {
            acd_yr_id: activeAcdId ? activeAcdId : 0,
            inst_id: InstId!,
            token,
            allotted_level: subjectAllocationLevel!,
            allotted_id:
              subjectAllocationLevel === AcdSubjectAllocationData.CLASS
                ? classId?.value!
                : subjectAllocationLevel === AcdSubjectAllocationData.SEMESTER
                ? semId?.value!
                : 0!,
            today_date: toIsoDate(TODAY_DATE),
            query_type: "ON_GOING_AND_FUTURE_FORMS",
            pr_emp_id: 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "FeedBack Deleted Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setClassId(null);
      setBranchId(null);
      setSemId(null);
      setEntries([]);
      setUnMappedClasses([]);
      setFormSelected(null);
      setOperation(Operation.CREATE);
      setFormName(EMPTY_STRING);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleDelete = (index: number) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
    if (editIndex !== null && editIndex >= index) {
      setEditIndex((prev) => (prev === index ? null : prev! - 1));
    }
  };

  const handleSelectionOfUnmappedClass = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempClass = unmappedClasses.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUnMappedClasses(tempClass);
    } else {
      let tempClass = unmappedClasses.map((user) =>
        user.label === name ? { ...user, isChecked: checked } : user
      );
      setUnMappedClasses(tempClass);
    }
  };
  const {
    classDropDown: classList,
    semesterDropDown,
    sectionDropDown,
  } = useAcdDropDownData(
    0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    0
  );
  useEffect(() => {
    if (branchId) {
      setUnMappedClasses(
        classList &&
          classList.map((data) => ({
            label: data.label,
            value: data.value,
            isChecked: false,
          }))
      );
    }
  }, [branchId, classList]);
  useEffect(() => {
    if (classId) {
      setUnMappedClasses(
        semesterDropDown &&
          semesterDropDown.map((data) => ({
            label: data.label,
            value: data.value,
            isChecked: false,
          }))
      );
    }
  }, [classId, semesterDropDown]);
  useEffect(() => {
    if (semId) {
      setUnMappedClasses(
        sectionDropDown &&
          sectionDropDown.map((data) => ({
            label: data.label,
            value: data.value,
            isChecked: false,
          }))
      );
    }
  }, [semId, sectionDropDown]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {operation === Operation.UPDATE
          ? "Update Feed Back"
          : operation === Operation.CREATE
          ? "Add Feed Back"
          : "Delete Feed Back"}
      </Title>
      <div className="feed-back">
        <div className="feed-back__details row g-0">
          <div className="col feed-back__details--left">
            <div className="feed-back__details--left--grid">
              {operation === Operation.CREATE && (
                <>
                  <Label>Form Name</Label>
                  <Input
                    autoFocus
                    value={formName}
                    onChange={(e) => {
                      setFormName(e.target.value);
                    }}
                  />
                </>
              )}
              {subjectAllocationLevel === AcdSubjectAllocationData.CLASS &&
              operation === Operation.CREATE ? (
                <>
                  <Label>{branchLabel}</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={branchDropDown!}
                    // disabled={!disabled || items?.length >= 1}
                    // ref={branchRef}
                    openOnFocus
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    value={branchId}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, branchId)
                    }
                    // onKeyDown={(e: React.KeyboardEvent) => {
                    //   if (e.key === Keys.ENTER) {
                    //     if (operation === Operation.CREATE && branchId) {
                    //       USE_CATEGORY_KEY
                    //         ? categoryInputRef?.focus()
                    //         : classAllSelectRef?.current?.focus();
                    //     }
                    //     if (operation !== Operation.CREATE && branchId) {
                    //       editClassInputRef?.focus();
                    //     }
                    //   }
                    //   if (e.key === Keys.BACKSPACE) {
                    //     setBranchId(null);
                    //   }
                    // }}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setBranchId({
                          label: (newValue as responseType).label,
                          value: (newValue as responseType).value,
                          isChecked: true,
                        });
                      } else {
                        setBranchId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : subjectAllocationLevel ===
                  AcdSubjectAllocationData.SEMESTER &&
                operation === Operation.CREATE ? (
                <>
                  <Label>{classLabel}</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={
                      subjectAllocationLevel ===
                      AcdSubjectAllocationData.SEMESTER
                        ? classDropDown!
                        : classList!
                    }
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    openOnFocus
                    value={classId}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, classId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setClassId({
                          label: (newValue as responseType).label,
                          value: (newValue as responseType).value,
                          isChecked: true,
                        });
                      } else {
                        setClassId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : subjectAllocationLevel === AcdSubjectAllocationData.SECTION &&
                operation === Operation.CREATE ? (
                <>
                  <Label>{semesterLabel}</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={semesterDropDown!}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    openOnFocus
                    value={semId}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSemId({
                          label: (newValue as responseType).label,
                          value: (newValue as responseType).value,
                          isChecked: true,
                        });
                      } else {
                        setSemId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : null}

              {subjectAllocationLevel === AcdSubjectAllocationData.CLASS &&
              (operation === Operation.UPDATE ||
                operation === Operation.DELETE) ? (
                <>
                  <Label>{classLabel}</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={
                      subjectAllocationLevel === AcdSubjectAllocationData.CLASS
                        ? classDropDown!
                        : classList!
                    }
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    openOnFocus
                    value={classId}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, classId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setClassId({
                          label: (newValue as responseType).label,
                          value: (newValue as responseType).value,
                          isChecked: true,
                        });
                      } else {
                        setClassId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : subjectAllocationLevel ===
                  AcdSubjectAllocationData.SEMESTER &&
                (operation === Operation.UPDATE ||
                  operation === Operation.DELETE) ? (
                <>
                  <Label>{semesterLabel}</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={semDropDown!}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    openOnFocus
                    value={semId}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSemId({
                          label: (newValue as responseType).label,
                          value: (newValue as responseType).value,
                          isChecked: true,
                        });
                      } else {
                        setSemId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : subjectAllocationLevel === AcdSubjectAllocationData.SECTION &&
                (operation === Operation.UPDATE ||
                  operation === Operation.DELETE) ? (
                <>
                  <Label>{semesterLabel}</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={semesterDropDown!}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    openOnFocus
                    value={semId}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSemId({
                          label: (newValue as responseType).label,
                          value: (newValue as responseType).value,
                          isChecked: true,
                        });
                      } else {
                        setSemId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : null}
              {(operation === Operation.UPDATE ||
                operation === Operation.DELETE) && (
                <>
                  <Label>Form Name</Label>
                  <RequiredAutoComplete
                    className={formClasses.inputRoot}
                    options={formDropDown!}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    openOnFocus
                    value={formSelected}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setFormSelected({
                          label: (newValue as responseType).label,
                          value: (newValue as responseType).value,
                          isChecked: true,
                        });
                      } else {
                        setFormSelected(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              )}

              <div></div>
              <div className="feed-back__details--left--flex">
                <TextField
                  value={toInputStandardDate(startDate)}
                  className="vacation-entry__block--textfield"
                  // InputProps={{
                  //   inputProps: {
                  //     min: toInputStandardDate(TODAY_DATE),
                  //     max: toInputStandardDate(
                  //       academicYearResponse.data?.GetAcdYrsByInstId[0]
                  //         .acd_end_date!
                  //     ),
                  //   },
                  // }}
                  type="date"
                  label="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
                <TextField
                  className="vacation-entry__block--textfield"
                  type="date"
                  label="End Date"
                  value={toInputStandardDate(endDate)}
                  // InputProps={{
                  //   inputProps: {
                  //     min: toInputStandardDate(TODAY_DATE),
                  //     max: toInputStandardDate(
                  //       academicYearResponse.data?.GetAcdYrsByInstId[0]
                  //         .acd_end_date!
                  //     ),
                  //   },
                  // }}
                  onChange={(e) => setEndDate(e.target.value)}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
                <FormControlLabel
                  label="Is Academic"
                  labelPlacement="start"
                  control={
                    <AntSwitch
                      checked={isAcademic}
                      onClick={() => {
                        setIsAcademic(!isAcademic);
                      }}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="col feed-back__details--right h-100">
            <Title variant="subtitle2">
              Select{" "}
              {subjectAllocationLevel === AcdSubjectAllocationData.CLASS
                ? classLabel
                : subjectAllocationLevel === AcdSubjectAllocationData.SEMESTER
                ? semesterLabel
                : sectionLabel}
            </Title>
            {operation === Operation.CREATE && (
              <div className="feedemand__categorydetails">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="allSelect"
                  // ref={classAllSelectRef}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    //   if (e.key === Keys.ENTER) {
                    //     if (classRefList.current.length > 0) {
                    //       classRefList.current[0].focus();
                    //     }
                    //     if (classAllSelectRef.current?.checked) {
                    //       feeDescInputRef?.focus();
                    //     }
                    //   }
                  }}
                  checked={
                    !unmappedClasses?.some((user) => user?.isChecked !== true)
                  }
                  onChange={handleSelectionOfUnmappedClass}
                />
                <label className="all-select">All Select</label>
                <div className="feedemand__checkboxes">
                  {unmappedClasses &&
                    unmappedClasses.map((user, index) => (
                      <div className="form-check" key={index}>
                        <input
                          ref={AddClassRefs}
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER) {
                              const isVisited = unmappedClasses.filter(
                                ({ isChecked }) => isChecked
                              ).length;

                              if (
                                classRefList.current[index + 1] &&
                                index !==
                                  unmappedClasses.filter((f) => f.isChecked)
                                    .length -
                                    1
                              ) {
                                classRefList.current[index + 1].focus();
                              }
                              if (
                                index ===
                                  unmappedClasses.filter((f) => f.isChecked)
                                    .length -
                                    1 &&
                                isVisited > 0
                              ) {
                                feeDescInputRef?.focus();
                              }
                            }
                          }}
                          type="checkbox"
                          className="form-check-input"
                          name={user.label}
                          checked={user?.isChecked || false}
                          onChange={handleSelectionOfUnmappedClass}
                        />
                        <label className="form-check-label ms-2">
                          {user.label}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="feed-back__total">
          {operation !== Operation.DELETE && (
            <>
              <span className="feed-back__total--left">{entries.length}</span>
              <span>/</span>
              <span className="feed-back__total--right">15</span>
            </>
          )}
        </div>

        <div className="feed-back__tableblock">
          {operation !== Operation.DELETE && (
            <TableContainer className="feed-back__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl</TableCell>
                    <TableCell>Rating Factor Description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="feed-back__table--select">
                    <TableCell id="td-center">{entries.length + 1}</TableCell>
                    <TableCell className="feed-back__table--input">
                      <Input
                        value={currentInput}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            handleAddOrUpdateEntry();
                          }
                        }}
                        disabled={entries.length >= 15}
                      />
                    </TableCell>
                    <TableCell id="td-center">
                      <img
                        src={Actions}
                        alt=""
                        onClick={handleAddOrUpdateEntry}
                        style={{
                          cursor:
                            entries.length >= 15 ? "not-allowed" : "pointer",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  {entries.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell id="td-center">{index + 1}</TableCell>
                      <TableCell>{entry}</TableCell>
                      <TableCell id="td-center">
                        <img
                          src={Edit}
                          alt="Edit"
                          onClick={() => handleEdit(index)}
                        />
                        <img
                          src={Delete}
                          alt="Delete"
                          onClick={() => handleDelete(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <Button
          mode="save"
          onClick={() => {
            operation === Operation.CREATE
              ? HandleSubmit()
              : operation === Operation.UPDATE
              ? HandleUpdate()
              : HandleDelete();
          }}
        />
        <Button mode="edit" onClick={() => setOperation(Operation.UPDATE)} />
        <Button mode="view" onClick={() => setView(!view)} />
        <Button mode="delete" onClick={() => setOperation(Operation.DELETE)} />

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal isOpen={view} ariaHideApp={false} style={StudentModalStyles}>
        <View setModalFlag={setView} />
      </Modal>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;
