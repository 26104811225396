import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDropdownData from "../../customhooks/useDropdownData";
import useInstitutionConfiguration from "../../customhooks/useInstitutionConfiguration";
import useToken from "../../customhooks/useToken";
import { GetStudentEnquires } from "../../queries/students/list/newApi";
import { Button } from "../../stories/Button/Button";
import Input from "../../stories/Input/Input";
import { Title } from "../../stories/Title/Title";
import { pageInfo } from "../../Types/pagination";
import { TableHeaderProps } from "../../Types/Tables";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../utils/constants";
import { Keys } from "../../utils/Enum.keys";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  InstitutionDetails,
  Operation,
  PageFor,
  SortBy,
  StdEnqQueryType,
  YesNo,
} from "../../utils/Enum.types";
import { msgType, responseType } from "../../utils/Form.types";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  sixMonthsAgo,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../utils/UtilFunctions";
import Modal from "react-modal";
import {
  ModalCustomStyles,
  StudentModalStyles,
  WaiveOffModalStyles,
} from "../../styles/ModalStyles";
import Edit from "../../images/EditProfile.svg";
import Delete from "../../images/Delete.svg";
import BasicDetailsEnquiry from "./BasicDetails";
import MessageModal from "../../pages/MessageModal";
import { DeleteStudentEnquiryById } from "../../queries/students/mutations/update";
import DeleteModal from "../../pages/DeleteModal";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Close from "../../images/Close.svg";
import Eduate from "../../images/Eduate_Logo_image.png";
import EduateLogo from "../../images/Eduate_Logo_image.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Success from "../../images/PositiveConfirmation.svg";
import {
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../../Types/Student";
import { AppContext } from "../../context/context";
import { payloadTypes } from "../../context/reducer";
import StudentDetails from "../Enquiry/FollowUp/StudentDetails";

import { CheckIfInstConfigComplete } from "../../queries/institution/configuration/query";
import { InstIdVars } from "../HR/Types/masterDataTypes";
import useActiveAcademicYear from "../Academics/hooks/useActiveAcademicYear";

import {
  Actions,
  SeekingBranch,
  SeekingCategory,
  SeekingClass,
  SeekingDepartment,
  SeekingSemester,
} from "./utils/constants";
import { follow_up_details } from "../../customhooks/useEnquiryStudentDetails";
import { Label } from "../../stories/Label/Label";
import Enquirystudentreceipt from "../Accounts/StudentDetails/FeeReceipt/Enquirystudentreceipt";
import useServerDateandTime from "../Library/customHooks/useServerDateandTime";
import ApplicationStatus from "../../images/ApplicationStatus.svg";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  I4_CELL,
  TABLE_HEADER_CSS,
} from "../Library/Constants";
import useInstLogoDetails from "../../customhooks/useInstLogoDetails";
import { InstitutionNode } from "../../customhooks/useInstitutionsByCustId";
import useInstDetails from "../../customhooks/general/useInstDetails";
import useInstitutionDetailsDataByInstId from "../../customhooks/useInstitutionDetailsDataByInstId";
import StudentTotalCount from "../Master/Student/Components/StudentTotalCount";
import useSwConfigData from "../../customhooks/useSwConfigData";
import { InstitutionAcctConfigurationTypes } from "../Accounts/common/Enum.types";
import useMasterTableJson from "../../json/useMasterTableJson";
import useInstLabels from "../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import ApplicationFeeChallan from "../Accounts/StudentDetails/Challan/ApllicationFeeChallan";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../styles/AutocompleteListStyles";

export interface GetStudentsEnquiryVars {
  name: string;
  lastFollowUpStatus: string | null;
  boughtApplication: boolean | null;
  admitted: boolean | null;
  branchID: number | null;
  classID: number | null;
  first: number | null;
  after: string | null;
  sortBy: SortBy;
  direction: Direction;
  token: string;
  today_date: string;
  enq_query_type: StdEnqQueryType;
  inst_id: string | number;
  pr_emp_id: number;
  enq_start_date: string;
  enq_end_date: string;
  id?: number | null;
}
export interface GetStudentsEnquiryNode {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  std_father_name: string;
  std_mother_name: string;
  std_previous_school: string;
  std_previous_place: string;
  std_email: string;
  std_sex: string;
  std_mobile: string;
  counselled_by: string;
  std_nationality: string;
  staff_remarks: string;
  admitted: boolean;
  last_follow_up_made_date: string;
  last_follow_up_status: string;
  next_follow_up_date: string;
  bought_application: boolean;
  follow_up_count: number;
  follow_up_details: follow_up_details[];
  admitted_date: string;
  enq_remarks: string;
  enq_date: string;
  enq_join_status: string;
  inst_id: number;
  dept_id: number;
  branch_id: number;
  class_id: number;
  semester_id: number;
  section_id: number;
  fin_yr_id: number;

  category_id: number;
  dept_details: DepartmentList;
  branch_details: BranchesList;
  class_details: ClassList;
  semester_details: SemesterList;
  section_details: SectionList;
  category_details: CategoryList;
  inst_details: InstitutionNode;
  reference_through: string;
  isChecked?: boolean;
}
export interface GetStudentsEnquiryEdges {
  node: GetStudentsEnquiryNode;
}
export interface GetStudentsEnquiryList {
  totalCount: number;
  pageInfo: pageInfo;
  edges: GetStudentsEnquiryEdges[];
}
export interface GetStudentsEnquiryData {
  GetStudentEnquires: GetStudentsEnquiryList;
}
export interface modalType {
  operation: Operation;
  flag: boolean;
}

export interface CheckIfInstConfigCompleteData {
  CheckIfInstConfigComplete: boolean;
}
export interface CheckIfInstConfigCompleteVars {
  CheckIfInstConfigCompleteData: boolean;
}

export interface YesNoOptionsType {
  label: YesNo;
  value: boolean;
}
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  queryType: StdEnqQueryType;
  shouldDispatchId: boolean;
  startDate?: string;
  endDate?: string;
}
const StudentEnquiry = ({
  pageType,
  setModalFlag,
  queryType,
  shouldDispatchId,
  startDate,
  endDate,
}: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { dispatch, state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { configData: enableChallanReceipt } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT
  );
  const { Masters_Table } = useMasterTableJson();
  const navigate = useNavigate();
  //Styles

  //useStates
  const [searchData, setSearchData] = useState("");
  const [enquiryDetailsModal, setEnquiryDetailsModal] = useState(false);
  const [feeReceiptModal, setFeeReceiptModal] = useState(false);
  const [challanFeeModal, setChallanFeeModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  const YesNoBooleanOptions: YesNoOptionsType[] = [
    {
      label: YesNo.YES,
      value: true,
    },
    {
      label: YesNo.NO,
      value: false,
    },
  ];
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<GetStudentsEnquiryEdges[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [enquiryId, setEnquiryId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [studentDetailsModal, setStudentDetailsModal] = useState(false);

  const [classSelected, setClassSelected] = useState<responseType | null>(null);

  const [applicationBoughtSelected, setApplicationBoughtSelected] =
    useState<YesNoOptionsType | null>(null);
  const [admittedSelected, setAdmittedSelected] =
    useState<YesNoOptionsType | null>(null);
  const [operation, setOperation] = useState(Operation.NONE);
  const { configData: enableEnqAppFee } = useSwConfigData(
    InstitutionAcctConfigurationTypes.ENABLE_APPLICATION_FEE
  );
  const [sDate, setSDate] = useState(serverDate);
  const [eDate, setEDate] = useState(serverDate);

  useEffect(() => {
    if (pageType === PageFor.MODAL) {
      if (serverDate) {
        setEDate(serverDate);
        setSDate(sixMonthsAgo(serverDate));
      }
    } else {
      if (serverDate && startDate && endDate) {
        setSDate(startDate);
        setEDate(endDate);
      }
    }
  }, [serverDate, pageType]);
  const [GetStudentEnquiry, { data: StudentEnquiryData, loading, fetchMore }] =
    useLazyQuery<GetStudentsEnquiryData, GetStudentsEnquiryVars>(
      GetStudentEnquires,
      {
        variables: {
          name: searchData,
          lastFollowUpStatus: EMPTY_STRING,
          boughtApplication: applicationBoughtSelected
            ? applicationBoughtSelected.value
            : null,
          admitted: admittedSelected ? admittedSelected.value : null,
          branchID: branchSelected ? branchSelected.value : null,
          classID: classSelected ? classSelected.value : null,
          first: rowsPerPage,
          after: null,
          sortBy: SortBy.ENQ_DATE,
          direction: Direction.ASC,

          token,
          pr_emp_id: 0,
          enq_start_date:
            pageType === PageFor.MODAL
              ? toIsoDate(sDate)
              : toIsoDate(startDate!),
          enq_end_date:
            pageType === PageFor.MODAL ? toIsoDate(eDate) : toIsoDate(endDate!),
          enq_query_type: queryType,
          today_date: toIsoDate(serverDate),
          inst_id: InstId!,
        },
        fetchPolicy: "network-only",
      }
    );

  const { InstDetails } = useInstDetails(1);
  //useRefs

  const classRef = useRef<HTMLSelectElement>(null);
  const { user_details } = useLoggedInUserDetails();
  const [CheckConfigForStudentAddition] = useLazyQuery<
    CheckIfInstConfigCompleteData,
    InstIdVars
  >(CheckIfInstConfigComplete, {
    variables: {
      token,
      inst_id: InstId!,
    },
    fetchPolicy: "network-only",
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteEnquiry] = useMutation(DeleteStudentEnquiryById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();
  const { branchDropDown } = useInstitutionDetailsDataByInstId(
    InstitutionDetails.BRANCH
  );
  const { classDropDown } = useDropdownData(
    0,
    branchSelected ? branchSelected?.value : 0,
    classSelected ? classSelected?.value : 0,
    0,
    0
  );
  const clear = () => {
    setBranchSelected(null);
    setClassSelected(null);
    setAdmittedSelected(null);
    setApplicationBoughtSelected(null);
    setSearchData(EMPTY_STRING);
  };
  const { departmentLabel, branchLabel, classLabel, semesterLabel } =
    useInstLabels();

  const InstConfigForFilter = (data: TableHeaderProps) => {
    switch (data.labelName) {
      case SeekingDepartment:
        if (USE_DEPARTMENT_KEY === false) {
          return false;
        }
        return true;
      case SeekingBranch:
        if (USE_BRANCH_KEY === false) {
          return false;
        }
        return true;
      case SeekingClass:
        if (USE_CLASS_KEY === false) {
          return false;
        }
        return true;
      case SeekingSemester:
        if (USE_SEMESTER_KEY === false) {
          return false;
        }
        return true;
      case SeekingCategory:
        if (USE_CATEGORY_KEY === false) {
          return false;
        }
        return true;
      default:
        return true;
    }
  };
  const enquiryHeader = [
    "Sl no",
    "Name",
    "Previous Studied Institution",
    `Seeking ${departmentLabel}`,
    `Seeking ${branchLabel}`,
    `Seeking ${classLabel}`,
    `Seeking ${semesterLabel}`,
    "Seeking Category",
    "Mobile No",
    "E-Mail",
    "Admitted",
  ];

  const studentCol = [
    { title: "Sl", field: "slNo" },
    { title: "Name", field: "Name" },
    { title: "Previous Studied Institution", field: "Inst" },
    USE_DEPARTMENT_KEY
      ? { title: `Seeking ${departmentLabel}`, field: "Dept" }
      : null,
    USE_BRANCH_KEY
      ? { title: `Seeking ${branchLabel}`, field: "Branch" }
      : null,
    USE_CLASS_KEY ? { title: `Seeking ${classLabel}`, field: "Class" } : null,
    USE_SEMESTER_KEY
      ? { title: `Seeking ${semesterLabel}`, field: "Sem" }
      : null,
    USE_CATEGORY_KEY ? { title: "Seeking Category", field: "Category" } : null,
    { title: "Mobile No", field: "Mobile" },
    { title: "E-Mail", field: "Email" },
    { title: "Admitted", field: "Admitted" },
  ];
  const stdData = StudentEnquiryData?.GetStudentEnquires.edges.map(
    (data, index) => ({
      slNo: index + 1,
      Name: `${data.node.first_name} ${data.node.middle_name} ${data.node.last_name}`,
      Inst: data.node.std_previous_school,

      Branch: data.node.branch_details.branch_desc
        ? data.node.branch_details.branch_desc
        : "-",
      Class: data.node.class_details.class_desc
        ? data.node.class_details.class_desc
        : "-",

      Mobile: data.node.std_mobile,
      Email: data.node.std_email,
      Admitted: data.node.admitted ? YesNo.YES : YesNo.NO,
    })
  );

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(EduateLogo)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0); // Set text color to white
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_name}`,
                    80,
                    startY
                  );
                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );
                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_place},${InstDetails.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 19
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Enquiry Students List`, 120, startY + 19);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 19
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  // doc.addImage(result2, "JPEG", 80, 5, 55, 55);
                }
                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: stdData,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Enquiry Students List");

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.slNo,
          item.Name,
          item.Inst,
          item.Branch,
          item.Class,
          item.Mobile,
          item.Email,
          item.Admitted,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 25;
    worksheet.getColumn(11).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          const lastHeaderColumn = String.fromCharCode(
            65 + enquiryHeader.length - 1
          );
          const imageCellRange = `${lastHeaderColumn}1:${lastHeaderColumn}3`;

          // Add the image to the specified cell range
          worksheet.addImage(imageV, imageCellRange);

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:K2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:K3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Enquiry Students List";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:H4");
                  const mergedDate: Cell = worksheet.getCell(I4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("I4:K4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");
                  let Char = FIRST_INDEX;
                  for (let i = 0; i < enquiryHeader.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = enquiryHeader[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Enquiry Students List");
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  const StudentEnquiryTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {Masters_Table.StudentsEnquiry.Table_Headers.filter(
            (data: TableHeaderProps) => {
              if (pageType === PageFor.MODAL) {
                if (data.labelName === Actions) {
                  return false;
                } else return InstConfigForFilter(data);
              }
              if (pageType === PageFor.GENERAL) {
                return InstConfigForFilter(data);
              }
              return true;
            }
          ).map((label: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={label.className}>
                {label.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  const chooseTitleAccordingToQueryType = (queryType: string) => {
    switch (queryType) {
      case StdEnqQueryType.STD_ENQ_ALL:
        return "Enquired Student Details";
      case StdEnqQueryType.STD_ENQ_ADMITTED:
        return "Admitted Students";
      case StdEnqQueryType.STD_ENQ_INTERESTED:
        return "Interested Students";
      case StdEnqQueryType.STD_ENQ_NOT_INTERESTED:
        return "Not Interested Students";
      case StdEnqQueryType.STD_ENQ_NOT_DECIDED:
        return "Not Decided Students";
      case StdEnqQueryType.STD_ENQ_TO_FOLLOW_UP:
        return "To FollowUp Students";
      case StdEnqQueryType.STD_ENQ_TODAY_FOLLOW_UP:
        return "Today's Follow Up Students";
      case StdEnqQueryType.STD_ENQ_CALLED_TODAY:
        return "Today's Student Callers";
      case StdEnqQueryType.STD_ENQ_BOUGHT_APP:
        return "Application Bought Students";
      case StdEnqQueryType.STD_ENQ_NOT_ADMITTED:
        return "Not Admitted Students";
      default:
        break;
    }
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleDelete = (id: number) => {
    DeleteEnquiry({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudentEnquires,
          variables: {
            name: searchData,
            lastFollowUpStatus: null,
            boughtApplication: null,
            admitted: null,
            status: null,
            deptID: null,
            branchID: null,
            classID: null,
            semesterID: null,
            categoryID: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            first: ROWS_PER_PAGE,
            after: null,
            today_date: toIsoDate(serverDate),
            sortBy: SortBy.FIRST_NAME,
            direction: Direction.ASC,
            token,
            enq_query_type: StdEnqQueryType.STD_ENQ_ALL,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setEnquiryId(0);
        setMessage({
          flag: true,
          message: "Enquiry Deleted Successfully ",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudentEnquires.edges;
            const pageInfo = fetchMoreResult.GetStudentEnquires.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudentEnquires.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudentEnquires: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: StudentEnquiryData?.GetStudentEnquires.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (
      !activeAcademicYearData.loading &&
      activeAcademicYearData.data &&
      state.ActiveFinYr &&
      serverDate
    ) {
      GetStudentEnquiry();
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveFinYr,
    GetStudentEnquiry,
    serverDate,
    searchData,
  ]);
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    if (pdfFlag && rowsPerPage === null && !loading) downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading]);
  useEffect(() => {
    if (
      StudentEnquiryData &&
      !loading &&
      excelFlag === false &&
      pdfFlag === false
    ) {
      const newData = StudentEnquiryData?.GetStudentEnquires.edges;
      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(StudentEnquiryData?.GetStudentEnquires.pageInfo.endCursor!);
    } // eslint-disable-next-line
  }, [StudentEnquiryData, loading, excelFlag, pdfFlag]);

  return (
    <>
      {pageType === PageFor.MODAL && (
        <div className="row g-0">
          <div className="col">
            <Title>{chooseTitleAccordingToQueryType(queryType)}</Title>
          </div>
        </div>
      )}
      <div
        className={
          pageType === PageFor.GENERAL
            ? "student-enquiry"
            : "student-enquiry--modal"
        }
      >
        <form className="row g-0 student-enquiry__options">
          <div className="col-1  student-enquiry__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}

          {queryType === StdEnqQueryType.STD_ENQ_ALL ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={YesNoBooleanOptions}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    applicationBoughtSelected
                  )
                }
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setApplicationBoughtSelected(null);
                  }
                }}
                openOnFocus
                value={applicationBoughtSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setApplicationBoughtSelected(newValue as YesNoOptionsType);
                    setHasNextPage(true);
                  } else {
                    setApplicationBoughtSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Application Bought"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}

          {queryType === StdEnqQueryType.STD_ENQ_ALL ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={YesNoBooleanOptions}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, admittedSelected)
                }
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setAdmittedSelected(null);
                  }
                }}
                openOnFocus
                value={admittedSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setAdmittedSelected(newValue as YesNoOptionsType);
                    setHasNextPage(true)
                  } else {
                    setAdmittedSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Admitted"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}

          {pageType === PageFor.MODAL ? (
            <div className="col student-enquiry__options--filters">
              <TextField
                type="date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                label="Start Date"
                value={toInputStandardDate(sDate)}
                onChange={(e) => setSDate(e.target.value)}
                className="transport-assign-route__datablock--textfield--date"
              />
              <TextField
                type="date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                label="End Date"
                value={toInputStandardDate(eDate)}
                onChange={(e) => setEDate(e.target.value)}
                className="transport-assign-route__datablock--textfield--date"
              />{" "}
            </div>
          ) : (
            <div className="col"></div>
          )}
          <div className="col-2 buttons-flex">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className="student-clear-button"
            />
            {pageType === PageFor.MODAL ? null : (
              <Button
                mode="addnew"
                type="button"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  CheckConfigForStudentAddition().then(({ data }) => {
                    if (data?.CheckIfInstConfigComplete) {
                      setEnquiryDetailsModal(!enquiryDetailsModal);
                      setOperation(Operation.CREATE);
                    } else {
                      setMessage({
                        message: "Institution Configuration not yet completed",
                        flag: true,
                        operation: Operation.NONE,
                      });
                    }
                  });
                }}
                autoFocus
                className="student-add-button"
              />
            )}
          </div>
        </form>
        <div
          className={
            pageType === PageFor.GENERAL
              ? "student-enquiry__frame"
              : "student-enquiry__frame--modal"
          }
        >
          {!students?.length ? (
            <b className="nodata">Sorry, No Students Found</b>
          ) : (
            <TableContainer
              className="student-enquiry__frame--table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <StudentEnquiryTableHeader />
                <TableBody>
                  {students?.map((response, index: number) => (
                    <TableRow
                      key={index}
                      onClick={() => {
                        if (shouldDispatchId) {
                          dispatch({
                            type: payloadTypes.SET_STD_ENQUIRY_ID,
                            payload: {
                              studentEnquiryId: response.node.id,
                            },
                          });
                          setModalFlag(false);
                        }
                      }}
                    >
                      <TableCell
                        id="td-center"
                        className="student-enquiry__frame--table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="student-enquiry__frame--table--inst">
                        {toStandardDate(response.node.enq_date)}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          dispatch({
                            type: payloadTypes.SET_STD_ENQUIRY_ID,
                            payload: { studentEnquiryId: response.node.id },
                          });
                          setStudentDetailsModal(!studentDetailsModal);
                        }}
                      >
                        {`${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`}
                      </TableCell>

                      <TableCell className="student-enquiry__frame--table--inst">
                        {response.node.std_previous_school}
                      </TableCell>

                      {USE_BRANCH_KEY ? (
                        <TableCell className="student-enquiry__frame--table--desc">
                          {response.node.branch_details.branch_desc
                            ? response.node.branch_details.branch_desc
                            : "-"}
                        </TableCell>
                      ) : null}
                      {USE_CLASS_KEY ? (
                        <TableCell className="student-enquiry__frame--table--desc">
                          {response.node.class_details.class_desc
                            ? response.node.class_details.class_desc
                            : "-"}
                        </TableCell>
                      ) : null}

                      <TableCell className="student-enquiry__frame--table--mobile">
                        {response.node.std_mobile}
                      </TableCell>
                      <TableCell className="student-enquiry__frame--table--email">
                        {response.node.std_email}
                      </TableCell>
                      <TableCell
                        className="student-enquiry__frame--table--status"
                        id="td-center"
                      >
                        {response.node.admitted ? YesNo.YES : YesNo.NO}
                      </TableCell>
                      {pageType === PageFor.GENERAL ? (
                        <TableCell
                          className="student-enquiry__frame--table--action"
                          id="td-center"
                        >
                          <img
                            src={Edit}
                            alt="/"
                            onClick={() => {
                              setEnquiryId(response.node.id);
                              setEnquiryDetailsModal(!enquiryDetailsModal);
                              setOperation(Operation.UPDATE);
                            }}
                          />
                          <img
                            src={Delete}
                            alt="/"
                            onClick={() => {
                              setEnquiryId(response.node.id);
                              setDeleteModal(!deleteModal);
                            }}
                          />
                          {response.node.bought_application ||
                          (enableEnqAppFee.data &&
                            enableEnqAppFee.data.GetSwConfigVariables.length >
                              0 &&
                            enableEnqAppFee.data.GetSwConfigVariables[0]
                              .config_boolean_value === false) ? null : (
                            <img
                              src={ApplicationStatus}
                              alt="/"
                              onClick={() => {
                                dispatch({
                                  type: payloadTypes.SET_STD_ENQUIRY_ID,
                                  payload: {
                                    studentEnquiryId: response.node.id,
                                  },
                                });
                                setFeeReceiptModal(!feeReceiptModal);
                              }}
                            />
                          )}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {pageType === PageFor.GENERAL ? (
              <>
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setPdfFlag(true);
                  }}
                  mode="pdf"
                />
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setExcelFlag(true);
                  }}
                  mode="export"
                />

                <Button mode="back" onClick={() => navigate(-1)} />
              </>
            ) : (
              <Button mode="cancel" onClick={() => setModalFlag(false)} />
            )}
          </div>
          <div className="col-2 student-enquiry__total">
            <StudentTotalCount
              totalCount={StudentEnquiryData?.GetStudentEnquires.totalCount!}
            />
          </div>
        </div>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={enquiryDetailsModal}
          ariaHideApp={false}
          style={ModalCustomStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <BasicDetailsEnquiry
                setEnquiryDetailsModal={setEnquiryDetailsModal}
                setWarningModal={setWarningModal}
                enquiryId={enquiryId}
                operation={operation}
              />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                onClick={() => setEnquiryDetailsModal(!enquiryDetailsModal)}
              />
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={warningModal}
          ariaHideApp={false}
          style={WaiveOffModalStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flexdata h-100">
              <b className="student-enquiry__success">
                <img src={Success} alt="/" />
                Enquiry Details Saved
              </b>
              <div>
                <Label>
                  Do You wish to Collect Application Fee for the student?
                </Label>
              </div>
              {enableChallanReceipt?.data?.GetSwConfigVariables[0]
                .config_boolean_value && (
                <div style={{ display: "flex", margin: "10px" }}>
                  <Button
                    onClick={() => {
                      setWarningModal(false);
                      setEnquiryDetailsModal(false);
                      setFeeReceiptModal(!feeReceiptModal);
                    }}
                  >
                    Fee Receipt
                  </Button>
                  <Button
                    onClick={() => {
                      // keep this flag false always
                      setWarningModal(false);
                      setEnquiryDetailsModal(false);
                      setChallanFeeModal(!feeReceiptModal);
                    }}
                  >
                    Challan
                  </Button>
                </div>
              )}
              {!enableChallanReceipt?.data?.GetSwConfigVariables[0]
                .config_boolean_value && (
                <>
                  <Button
                    mode="yes"
                    autoFocus
                    onClick={() => {
                      // keep this flag false always
                      setWarningModal(false);
                      setEnquiryDetailsModal(false);
                      setFeeReceiptModal(!feeReceiptModal);
                    }}
                  />
                  <Button
                    mode="no"
                    onClick={() => {
                      setWarningModal(!warningModal);
                      setEnquiryDetailsModal(false);
                    }}
                  />
                </>
              )}
              {enableChallanReceipt?.data?.GetSwConfigVariables[0]
                .config_boolean_value && (
                <Button
                  mode="cancel"
                  onClick={() => {
                    setWarningModal(!warningModal);
                    setEnquiryDetailsModal(false);
                  }}
                />
              )}
            </div>
            <div className="modal-fleximage">
              <img
                src={Close}
                alt="/"
                onClick={() => setWarningModal(!warningModal)}
              />
            </div>
          </div>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={feeReceiptModal}
          ariaHideApp={false}
          style={StudentModalStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <Enquirystudentreceipt
                pageType={PageFor.MODAL}
                setFeeReceiptModal={setFeeReceiptModal}
                studentSelected={true}
              />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                onClick={() => setFeeReceiptModal(!feeReceiptModal)}
              />
            </div>
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={challanFeeModal}
          ariaHideApp={false}
          style={StudentModalStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <ApplicationFeeChallan
                pageType={PageFor.MODAL}
                setFeeReceiptModal={setChallanFeeModal}
                studentSelected={true}
              />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                onClick={() => setChallanFeeModal(!challanFeeModal)}
              />
            </div>
          </div>
        </Modal>

        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />
        <DeleteModal
          handleDelete={handleDelete}
          id={enquiryId}
          modalFlag={deleteModal}
          setModalFlag={setDeleteModal}
        />
        {/* <LoadingModal flag={StudentEnquiryData.loading} /> */}
      </div>
      {/* student-enquiry-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentDetailsModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDetails setStudentDetailsModal={setStudentDetailsModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentDetailsModal(!studentDetailsModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentEnquiry;
