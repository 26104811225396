import React, { useContext, useEffect, useState } from "react";
import {
  Direction,
  InstitutionConfigurationTypes,
  InstitutionType,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdStdAttOverAllData,
  GetAcdStdAttOverAllNode,
} from "../../../hooks/useAcdStudentAttendance";
import { GetAcdStdAttOverAllVars } from "../../../types/holidays";
import { GetAcdStdAttOverAll } from "../../../queries/holidays/list";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import { DateRange, toIsoDate } from "../../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useToken from "../../../../../customhooks/useToken";
import {
  EMPTY_STRING,
  getPercentageColor,
  getTextPercentageColor,
} from "../../../../../utils/constants";
import { payloadTypes } from "../../../../../context/reducer";
import { Drawer } from "@mui/material";
import { CustomDrawerProps } from "../../../DailyActivities/Attendance/Index";
import ViewPerStdAttendance from "../../../DailyActivities/Attendance/ViewPerStdAttendance";
import Input from "../../../../../stories/Input/Input";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import Modal from "react-modal";
import { ViewDetails } from "../../../../../styles/ModalStyles";
import ViewAllSubjects from "../../../../Master/Student/Dashboard/Academics/ViewAllSubjects";
import Close from "../../../../../images/Close.svg";
import { PerStudentAttDrawer } from "../../../../../styles/DrawerStyles";
import useSwConfigInstType from "../../../hooks/useSwConfigInstType";
import { TableHeaderProps } from "../../../../../utils/types";
import useAcademicTableJson from "../../../json/useAcademicTable";

type GridValidRowModel = {
  id: number;
  std_name: string;
  std_id?: number | null;
  std_adm_no: string;
  std_reg_no: string;
  total_present: number | string;
  total_absent: number | string;
  total_percentage: number | string;
};
const Attendance = () => {
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const { token } = useToken();
  const { CompleteReportsAttendance } = useAcademicTableJson();
  const [date, setDate] = useState(new Date());
  const [selectedStudentDetails, setSelectedStudentDetails] = useState<
    any | null
  >(null);
  const [
    selectedStudentDetailsForSession,
    setSelectedStudentDetailsForSession,
  ] = useState<any | null>(null);
  const { entryId } = useParams();
  const { state, dispatch } = useContext(AppContext);
  const { firstDay } = DateRange(date.toString()) || {};
  const { serverDate } = useServerDateandTime();
  const [searchText, setSearchText] = useState("");
  const [GetOverAllAttendance, { data: OverAllData }] = useLazyQuery<
    GetAcdStdAttOverAllData,
    GetAcdStdAttOverAllVars
  >(GetAcdStdAttOverAll, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: entryId ? Number(entryId) : 0,
        entry_level,
        inst_id: state.InstId!,
        cal_month: toIsoDate(firstDay!),
        subj_master_id: 0,
        session_id: 0,
        student_id: 0,
        att_query_type: "OVER_ALL_ATT_AT_ENTRY_LEVEL",
        date_of_attendance: toIsoDate(serverDate),
      },
      token,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      after: null,
      first: null,
      name: EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  useEffect(() => {
    if (
      entry_level &&
      token &&
      entryId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetOverAllAttendance();
    }
  }, [
    GetOverAllAttendance,
    entry_level,
    token,
    entryId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
  ]);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );

  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  //Headers
  const dynamicHeaders: TableHeaderProps[] =
    CompleteReportsAttendance.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        const percentage = parseFloat(params.row.total_percentage);
        const cellBackgroundColor = getPercentageColor(percentage);
        const textPercentageColor = getTextPercentageColor(percentage);

        const percentageValue = `${percentage}%`;
        if (header.field === "total_percentage") {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                color: textPercentageColor,
                backgroundImage: `linear-gradient(to right, ${cellBackgroundColor} ${percentageValue}, transparent ${percentageValue})`,
              }}
            >
              {params.row.total_percentage}%
            </div>
          );
        } else return params.value;
      },
    }));

  const columns: GridColDef[] = [...dynamicHeaders];

  //rows
  const rows: GridValidRowModel[] =
    (OverAllData?.GetAcdStdAttOverAll.edges || []).map((response, index) => {
      const studentNode = response.node as GetAcdStdAttOverAllNode;

      return {
        id: index + 1,
        std_adm_no: studentNode.student_details.std_adm_no,
        std_reg_no: studentNode.student_details.std_reg_no,
        std_id: studentNode.student_details.id,

        std_name:
          studentNode.student_details.first_name +
          studentNode.student_details.middle_name +
          studentNode.student_details.last_name,
        total_session: response.node.total_session,
        total_present: response.node.total_session_present,
        total_absent: response.node.total_session_absent,
        total_percentage: response.node.over_all_per,
      };
    }) || [];
  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "std_name") {
      const selectedRow = rows.find((row) => row.id === params.id);

      if (selectedRow) {
        const stdId = selectedRow.std_id;

        if (enablePerSessionAtt) {
          setSelectedStudentDetailsForSession(
            !selectedStudentDetailsForSession
          );
          dispatch({
            type: payloadTypes.SET_STUDENT_ID,
            payload: {
              studentId: stdId!,
            },
          });
        } else {
          setSelectedStudentDetails(selectedRow);
          dispatch({
            type: payloadTypes.SET_STUDENT_ID,
            payload: {
              studentId: stdId!,
            },
          });
        }
      }
    }
  };
  const filteredRows = searchText
    ? rows.filter((row) =>
        row.std_name.toLowerCase().includes(searchText.toLowerCase())
      )
    : rows;
  const CustomDrawer = ({ open, onClose, children }: CustomDrawerProps) => {
    return (
      <PerStudentAttDrawer anchor="right" open={open} onClose={onClose}>
        {children}
      </PerStudentAttDrawer>
    );
  };
  return (
    <>
      <div className="acd-reports__attendance">
        <div className="row g-0 acd-reports__attendance--select">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search.."
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div className="col flex-end">
            <div className="student-total-count">
              Total Students{" "}
              <b>
                {OverAllData ? OverAllData.GetAcdStdAttOverAll.totalCount : 0}
              </b>
            </div>
          </div>
        </div>
        <div className={`acd-reports__attendance--tableblock `}>
          <StyledDatagrid
            columns={columns}
            rows={filteredRows}
            rowHeight={TABLE_ROW_HEIGHT}
            // experimentalFeatures={{ columnGrouping: true }}
            // columnGroupingModel={columnGroupingModel}
            onCellClick={(params: GridCellParams) => {
              handleCellClick(params);
            }}
            hideFooter

            //   onSortModelChange={}
          />

          <CustomDrawer
            open={selectedStudentDetails}
            onClose={() => setSelectedStudentDetails(!selectedStudentDetails)}
          >
            <ViewPerStdAttendance
              selectedDate={date}
              setSelectedDate={setDate}
              setPerStudentAttendance={setSelectedStudentDetails}
              perStudentAttendance={selectedStudentDetails}
              pageType={PageFor.REPORT}
            />
          </CustomDrawer>
          <Modal
            shouldCloseOnOverlayClick={true}
            isOpen={selectedStudentDetailsForSession}
            ariaHideApp={false}
            style={ViewDetails}
          >
            <div className="modal-flex h-100">
              <div className="modal-flex__data h-100">
                <ViewAllSubjects
                  setModalFlag={setSelectedStudentDetailsForSession}
                  pageType={PageFor.REPORT}
                />
              </div>
              <div className="modal-flex__image">
                <img
                  src={Close}
                  alt=""
                  onClick={() =>
                    setSelectedStudentDetailsForSession(
                      !selectedStudentDetailsForSession
                    )
                  }
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Attendance;
