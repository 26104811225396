import React from "react";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { TextField } from "@mui/material";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../utils/Enum.types";
import useDisplayAutoCompleteTag from "../hooks/useDisplayAutoCompleteTag";

const AllotedLevelConfigs = () => {
  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY } = useInstitutionConfiguration();
  const { allotedID } = useParams();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { InstConfigDetails } = useInstConfigByEntryId(allotedID!);

  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  const {
    acd_branch_desc,
    acd_class_desc,
    acd_dept_desc,
    acd_section_desc,
    acd_semester_desc,
  } = InstConfigDetails.data?.GetAcdInstConfigNames ?? {
    acd_branch_desc: "",
    acd_class_desc: "",
    acd_dept_desc: "",
    acd_section_desc: "",
    acd_semester_desc: "",
  };

  return (
    <div className="row g-0 test-planner__details--create-test--select">
      {USE_DEPARTMENT_KEY ? (
        <div className="col-2">
          <TextField
            autoFocus
            disabled
            className="test-planner__details--create-test--textfield"
            label={departmentLabel}
            value={acd_dept_desc}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
        </div>
      ) : null}
      {USE_BRANCH_KEY ? (
        <div className="col-2">
          <TextField
            label={branchLabel}
            disabled
            value={acd_branch_desc}
            fullWidth
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            className="test-planner__details--create-test--textfield"
          />
        </div>
      ) : null}
      {displayClass ? (
        <div className="col-2">
          <TextField
            value={acd_class_desc}
            disabled
            label={classLabel}
            fullWidth
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            className="test-planner__details--create-test--textfield"
          />
        </div>
      ) : null}
      {displaySemester ? (
        <div className="col-2">
          <TextField
            value={acd_semester_desc}
            disabled
            label={semesterLabel}
            fullWidth
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            className="test-planner__details--create-test--textfield"
          />
        </div>
      ) : null}
      {displaySection ? (
        <div className="col-2">
          <TextField
            value={acd_section_desc}
            label={sectionLabel}
            disabled
            fullWidth
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            className="test-planner__details--create-test--textfield"
          />
        </div>
      ) : null}

      <div className="col"></div>
    </div>
  );
};

export default AllotedLevelConfigs;
