import React, { useState } from "react";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { PageFor, TableHeaders } from "../../../utils/Enum.types";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { TextField } from "@mui/material";
import Compare from "../../../images/Compare.svg";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import Modal from "react-modal";
import EditActivity from "./Edit";
import { EditModalCustomStyles } from "../../../styles/ModalStyles";
import Close from "../../../images/Close.svg";
import { TableHeaderProps } from "../../../utils/types";
import useAcctTableJson from "../json/useAcctTableJson";

const data = [
  {
    date: "10-05-2024",
    admno: "MH001",
    stdname: "Kavya",
    miscellaneous: "Book",
    remark: "Cleared all his due",
    Status: "True",
  },
  {
    date: "10-05-2024",
    admno: "MH001",
    stdname: "Kavya",
    miscellaneous: "Book",
    remark: "Cleared all his due",
    Status: "True",
  },
];
interface props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageType, setModalFlag }: props) => {
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const { InstId } = useParams();
  const [editModal, setEditModal] = useState(false);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Miscelleaous_Index.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        if (header.field === "action") {
          return (
            <>
              {" "}
              <img src={Edit} alt="" onClick={() => setEditModal(!editModal)} />
              <img src={Delete} alt="" />{" "}
            </>
          );
        }
        return params.value;
      },
    }));

  const columns: GridColDef[] = [
  ];
  const rows: GridValidRowModel[] =
    (data || []).map((response, index) => {
      return {
        id: index + 1,
        date: response.date,
        adm_no: response.admno,
        std_name: response.stdname,
        miscellaneous: response.miscellaneous,
        remark: response.remark,
        status: response.Status,
      };
    }) || [];
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <div className="miscellaneous__title">
        <Title>Miscellaneous Activity</Title>
        {pageType === PageFor.MODAL && (
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        )}
      </div>
      <div
        className={
          pageType === PageFor.MODAL ? "miscellaneous__modal" : "miscellaneous"
        }
      >
        <div className="miscellaneous__filters row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={["All"]}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={labelClasses.formControlRoot}
                  label="Employee Grade"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="col miscellaneous__filters--flex">
            <TextField
              className="miscellaneous__filters--textfield"
              label="Start Date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <img src={Compare} alt="" />
            <TextField
              className="miscellaneous__filters--textfield"
              label="End Date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </div>

          <div className="col-1 flex-end">
            <Button
              mode="addnew"
              onClick={() => navigate(`/${InstId}/accounts/miscellaneous/add`)}
            />
          </div>
        </div>
        <div className={`miscellaneous__datablock `}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
          />
        </div>
        <Button mode="clear" />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </div>
      <Modal
        isOpen={editModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <EditActivity setModal={setEditModal} />
      </Modal>
    </>
  );
};

export default Index;
