import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import { PageFor, StudentSearchField } from "../../../utils/Enum.types";
import StudentCompleteRecepit from "../StudentDetails/CompleteFeeReceipt/Index";
import Close from "../../../images/Close.svg";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import { Button } from "../../../stories/Button/Button";
import useDropdownData from "../../../customhooks/useDropdownData";
import { responseType } from "../../../utils/Form.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../utils/Enum.keys";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import {
  formatter,
  getModifiedScrollHeight,
  handleMUISelectEvent,
} from "../../../utils/UtilFunctions";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import { useNavigate } from "react-router-dom";

import {
  AcctLedgerQueryType,
  DepositLedgerQueryType,
} from "../common/QueryTypes";
import useStudentDepositDetails, {
  DepositEdge,
} from "../hooks/useStudentDepositDetails";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useAcctTableJson from "../json/useAcctTableJson";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";

const SearchFields = [
  StudentSearchField.ADMISSION_NUMBER,
  StudentSearchField.NAME,
];
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const DepositDetails = ({ pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [searchData, setSearchData] = useState("");

  const { format } = formatter;
  const [searchType, setSearchType] = useState(StudentSearchField.NAME);

  const [searchAdm, setSearchAdm] = useState("");
  const [studentCompleteFeeReceipt, setStudentCompleteFeeReceipt] =
    useState(false);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [acctLdgrSelected, setacctLdgrSelected] = useState<responseType | null>(
    null
  );

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<DepositEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { Accounts_Table } = useAcctTableJson();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  const { acctLedgers } = useAcctLedgerData(
    "",
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  const { categoryDropDown } = useInstMasterDataByInstId();

  const { StudentDepositData } = useStudentDepositDetails(
    DepositLedgerQueryType.ALL_DEPOSIT_LDGRS_FOR_ALL_STDS,
    searchData,
    acctLdgrSelected ? acctLdgrSelected.label : EMPTY_STRING,
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0
  );

  const { data, fetchMore, loading } = StudentDepositData;

  const totals = students.reduce(
    (acc, item) => {
      acc.std_deposit_ob += item.node.deposit_ob;
      acc.std_deposit_amt += item.node.deposit_amt;
      acc.std_deposit_total += item.node.deposit_total;
      acc.std_deposit_adjusted += item.node.deposit_adjusted;
      acc.std_deposit_refunded += item.node.deposit_refunded;
      acc.std_deposit_bal += item.node.deposit_bal;

      return acc;
    },
    {
      std_deposit_ob: 0,
      std_deposit_amt: 0,
      std_deposit_total: 0,
      std_deposit_adjusted: 0,
      std_deposit_refunded: 0,
      std_deposit_bal: 0,
    }
  );
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDeposits.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDeposits.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDeposits.edges.filter(
              ({
                node: {
                  student_details: { id },
                },
              }) =>
                newEdges.findIndex(
                  ({
                    node: {
                      student_details: { id: newId },
                    },
                  }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDeposits: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctStdDeposits.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDeposits.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) =>
              student.node.student_details.id ===
              newStudent.node.student_details.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDeposits.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Fee Deposit Details </Title>
      <div
        className={`advance-fee-list${
          pageType === PageFor.GENERAL ? "" : "--modal"
        }`}
      >
        <form className="row g-0 advance-fee-list__select">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (searchType === StudentSearchField.ADMISSION_NUMBER) {
                  setSearchAdm(e.target.value);
                  setSearchData("");
                } else {
                  setSearchData(e.target.value);
                  setSearchAdm("");
                }
              }}
              value={searchData !== "" ? searchData : searchAdm}
            />
          </div>
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={SearchFields}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) handleMUISelectEvent(e);
              }}
              onChange={(e, newValue) =>
                setSearchType(newValue as StudentSearchField)
              }
              value={searchType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search By"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                openOnFocus
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={acctLedgers.responseType}
              openOnFocus
              value={acctLdgrSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setacctLdgrSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setacctLdgrSelected(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setacctLdgrSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Account Ledgers  "
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        </form>
        <div className="advance-fee-list__tableblock">
          <TableContainer
            className="advance-fee-list__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.AdvanceFeeDepositList.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((data, index) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={() => {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: data.node.student_details.id,
                          },
                        });
                        setStudentCompleteFeeReceipt(
                          !studentCompleteFeeReceipt
                        );
                      }}
                    >
                      <TableCell
                        id="td-center"
                        className="advance-fee-list__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="advance-fee-list__table--admno">
                        {data.node.student_details.std_adm_no}
                      </TableCell>
                      <TableCell className="advance-fee-list__table--admno">
                        {data.node.student_details.std_reg_no}
                      </TableCell>
                      <TableCell>
                        {data.node.student_details.first_name +
                          " " +
                          data.node.student_details.middle_name +
                          " " +
                          data.node.student_details.last_name}
                      </TableCell>
                      <TableCell className="advance-fee-list__table--desc">
                        {data.node.branch.branch_desc}
                      </TableCell>
                      <TableCell className="advance-fee-list__table--desc">
                        {data.node.class.class_desc}
                      </TableCell>
                      <TableCell className="advance-fee-list__table--desc">
                        {data.node.acct_ldgr_details.ldgr_desc}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="advance-fee-list__table--amount"
                      >
                        {format(data.node.deposit_ob)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="advance-fee-list__table--amount"
                      >
                        {format(data.node.deposit_amt)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="advance-fee-list__table--amount"
                      >
                        {format(data.node.deposit_total)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="advance-fee-list__table--amount"
                      >
                        {format(data.node.deposit_adjusted)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="advance-fee-list__table--amount"
                      >
                        {format(data.node.deposit_refunded)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="advance-fee-list__table--amount"
                      >
                        {format(data.node.deposit_bal)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} className="total">
                    Total :
                  </TableCell>
                  <TableCell className="totalcount advance-fee-list__table--amount">
                    {format(totals.std_deposit_ob)}
                  </TableCell>
                  <TableCell className="totalcount advance-fee-list__table--amount">
                    {format(totals.std_deposit_amt)}
                  </TableCell>
                  <TableCell className="totalcount advance-fee-list__table--amount">
                    {format(totals.std_deposit_total)}
                  </TableCell>
                  <TableCell className="totalcount advance-fee-list__table--amount">
                    {format(totals.std_deposit_adjusted)}
                  </TableCell>
                  <TableCell className="totalcount advance-fee-list__table--amount">
                    {format(totals.std_deposit_refunded)}
                  </TableCell>
                  <TableCell className="totalcount advance-fee-list__table--amount">
                    {format(totals.std_deposit_bal)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            {pageType === PageFor.GENERAL ? (
              <Button mode="back" onClick={() => navigate(-1)} />
            ) : (
              <Button mode="cancel" onClick={() => setModalFlag(false)} />
            )}
          </div>
          <div className="advance-fee-list__total col-2">
            <StudentTotalCount
              totalCount={data?.GetAcctStdDeposits.totalCount!}
            />
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentCompleteFeeReceipt}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentCompleteRecepit
              PageType={PageFor.MODAL}
              setModalFlag={setStudentCompleteFeeReceipt}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() =>
                setStudentCompleteFeeReceipt(!studentCompleteFeeReceipt)
              }
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DepositDetails;
