import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import ExcelJS, { Cell } from "exceljs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { UpdateStudentsRollNum } from "../../../queries/students/mutations/update";
import useToken from "../../../customhooks/useToken";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import { msgType, responseType } from "../../../utils/Form.types";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  PageNumbers,
  SortBy,
  StudentReportType,
  StudentsTableType,
} from "../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import Eduate from "../../../images/Eduate_Logo_image.png";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import { StudentTablesTitleType } from "../../../Types/Student/json";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Settings from "../../../images/Settings.svg";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Input from "../../../stories/Input/Input";
import { Keys } from "../../../utils/Enum.keys";
import { Button } from "../../../stories/Button/Button";
import StudentTotalCount from "./Components/StudentTotalCount";
import { GetStudents } from "../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../utils/studentqueryEnum.types";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useMasterTableJson from "../../../json/useMasterTableJson";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import useAcdDropDownData from "../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentAcdNode,
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";

const RollNo = () => {
  const { InstId } = useParams();

  const { token } = useToken();
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();
  const { state } = useContext(AppContext);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [searchData, setSearchData] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [configurationModal, setConfigurationModal] = useState(false);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [EditMode, setEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { configData: ConfigLogo } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    ConfigLogo?.data?.GetSwConfigVariables[0].config_boolean_value;
  const { user_details } = useLoggedInUserDetails();
  const [UpdateStudentRollNums, { loading: updationLoading }] = useMutation(
    UpdateStudentsRollNum,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.ACD_STD_GENERAL,
    EMPTY_STRING,
    []
  );

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.ALLOT_STUDENT_REGISTER_NO
  );

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
  };
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const onEdit = (item: StudentAcdNode) => {
    setEditMode({
      status: true,
      rowKey: item.id,
    });

    if (item.std_reg_no) {
      setRollNumber(item.std_reg_no);
    }
  };

  const incrementNumber = (item: StudentAcdNode) => {
    onEdit(item);
    setRollNumber((Number(rollNumber) + 1).toString());
  };

  const HandleSubmit = () => {
    const std_roll_data = students?.map(({ node }) => ({
      student_id: node.id,
      std_roll_no: node.std_reg_no ? node.std_reg_no : 0,
    }));
    UpdateStudentRollNums({
      variables: {
        token,
        std_roll_data,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: null,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              std_query_type: studentQueryTypes.BY_INST_ID,
              ids: [Number(InstId)],
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Sucessfully Alloted Register Number",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const RegisterNoHeaders = [
    "SlNo",
    "Admission No",
    "Name",
    USE_DEPARTMENT_KEY ? departmentLabel : null,
    USE_BRANCH_KEY ? branchLabel : null,
    USE_CLASS_KEY ? classLabel : null,
    "Register No",
  ];
  const studentCol = [
    { title: "Sl", field: "SlNo" },
    { title: "Admission No", field: "AdmNo" },
    { title: "Name", field: "Name" },
    USE_DEPARTMENT_KEY ? { title: departmentLabel, field: "Dept" } : null,
    USE_BRANCH_KEY ? { title: branchLabel, field: "Branch" } : null,
    USE_CLASS_KEY ? { title: classLabel, field: "Class" } : null,
    { title: "Register No", field: "RegNo" },
  ];
  const stdData = students.map(({ node }, index) => ({
    SlNo: index + 1,
    AdmNo: node.std_adm_no,
    Name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
    Dept: USE_DEPARTMENT_KEY ? node.acd_dept.dept_desc : null,
    Branch: USE_BRANCH_KEY ? node.acd_branch.branch_desc : null,
    Class: USE_CLASS_KEY ? node.acd_class.class_desc : null,
    RegNo: node.std_reg_no,
  }));

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text("Students Roll Number", 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);

                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: stdData,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Students Roll Number");

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.SlNo,
          item.AdmNo,
          item.Name,
          item.Dept,
          item.Branch,
          item.Class,
          item.RegNo,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          if (configLogo) {
            worksheet.addImage(imageV, "G1:G3");
          }
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, 7);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:G2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:G3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Student Roll Number";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell(F4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("F4:G4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < RegisterNoHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = RegisterNoHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Students Roll Number");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(
    () => {
      if (AcademicsStudentData.data && !AcademicsStudentData.loading) {
        const newData = AcademicsStudentData.data.GetAcdStudents.edges.map(
          (edge) => {
            return {
              ...edge,
              node: {
                ...edge.node,
                isChecked: true,
                std_reg_no: edge.node.std_roll_no
                  ? edge.node.std_roll_no.toString()
                  : EMPTY_STRING,
              },
            };
          }
        );

        if (endCursor) {
          const updatedNewData = newData.map((newStudent) => {
            const filteredStudent = students.find(
              (student) => student.node.id === newStudent.node.id
            );
            if (filteredStudent) {
              return {
                ...newStudent,
                node: {
                  ...newStudent.node,
                },
              };
            }
            return newStudent;
          });
          setStudents(updatedNewData);
        } else {
          setStudents(newData);
        }
        setEndCursor(
          AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor
        );
      }
    },
    // eslint-disable-next-line
    [
      AcademicsStudentData.data,
      AcademicsStudentData.loading,
      AcademicsStudentData.fetchMore,
    ]
  );
  const itemsSet = new Set(students.map(({ node }) => node.std_reg_no));
  const clearRegNos = () => {
    setStudents(
      students.map((item) => ({
        ...item,
        node: {
          ...item.node,
          inc_no: "",
          std_reg_no: "",
        },
      }))
    );
    setRollNumber("");
  };
  const setRollNoAndIncrementNo = (index: number) => {
    if (
      !itemsSet.has(rollNumber) ||
      rollNumber === students[index].node.std_reg_no
    ) {
      students[index].node.std_reg_no = rollNumber;
      students[index].node.visited = true;
      if (index === students.length - 1) {
        setEditMode({
          rowKey: -1,
          status: false,
        });
      } else if (index !== students.length - 1) {
        if (students[index + 1].node.std_reg_no) {
          onEdit(students[index + 1].node);
          setRollNumber(students[index + 1].node.std_reg_no);
        } else {
          incrementNumber(students[index + 1].node);
        }
      }
    } else {
      setMessage({
        message: `Duplicate Roll Number  at ${index + 1} for ${
          rollNumber ===
          `${students[index].node.first_name} ${students[index].node.middle_name} ${students[index].node.last_name}`
        }`,
        flag: true,
        operation: Operation.NONE,
      });
    }
  };
  const filterInstConfigHeaders = (title: StudentTablesTitleType) => {
    const departmentCheck =
      title.labelName === `${departmentLabel}` && USE_DEPARTMENT_KEY;
    const branchCheck = title.labelName === `${branchLabel}` && USE_BRANCH_KEY;
    const classCheck = title.labelName === `${classLabel}` && USE_CLASS_KEY;

    const otherThanInstMasters =
      title.labelName !== `${departmentLabel}` &&
      title.labelName !== `${branchLabel}` &&
      title.labelName !== `${classLabel}` &&
      title.labelName !== "Register Number";

    return (
      title.type === StudentsTableType.ALLOT_REGISTER_NUMBER &&
      (departmentCheck || branchCheck || classCheck || otherThanInstMasters)
    );
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData.loading) {
        AcademicsStudentData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents
              ? prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                )
              : [];

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>Allot Roll Number for Students</Title>
        </div>

        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <div className="allot-regno">
        <form className="row g-0 allot-regno__options">
          <div className="col-1 allot-regno__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Branches"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                    if (!USE_SECTION_KEY && !USE_SEMESTER_KEY) {
                      setRowsPerPage(null);
                    }
                  } else {
                    setClassSelected(null);
                    if (!USE_SECTION_KEY || !USE_SEMESTER_KEY) {
                      setRowsPerPage(ROWS_PER_PAGE);
                    }
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Classes"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                options={semesterDropDown!}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                    if (!USE_SECTION_KEY) {
                      setRowsPerPage(null);
                    }
                  } else {
                    setSemesterSelected(null);
                    if (!USE_SECTION_KEY) {
                      setRowsPerPage(ROWS_PER_PAGE);
                    }
                  }
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Semesters"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={sectionDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true);
                    setRowsPerPage(null);
                  } else {
                    setSectionSelected(null);
                    setRowsPerPage(ROWS_PER_PAGE);
                  }
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Sections"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}

          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className="allot-regno__options--button"
            />
          </div>
        </form>
        <div className="allot-regno__tableblock-for-rollno">
          {!students.length ? (
            <b className="nodata">Sorry, No Students Found</b>
          ) : (
            <TableContainer
              className="allot-regno__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Masters_Table.Students.Table_Headers.filter(
                      (title: StudentTablesTitleType) =>
                        filterInstConfigHeaders(title)
                    )
                      .filter((title: StudentTablesTitleType) =>
                        rowsPerPage ? title.labelName !== "Roll Number" : title
                      )
                      ?.map(
                        (title: StudentTablesTitleType, index: React.Key) => {
                          return (
                            <TableCell key={index}>{title.labelName}</TableCell>
                          );
                        }
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students &&
                    students.length &&
                    students.map(({ node }, index: number) => (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="allot-regno__table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="allot-regno__table--admno">
                          {node.std_adm_no}
                        </TableCell>
                        <TableCell>{`${node.first_name} ${node.middle_name} ${node.last_name}`}</TableCell>
                        {USE_DEPARTMENT_KEY ? (
                          <TableCell className="allot-regno__table--desc">
                            {node.acd_dept.dept_desc}
                          </TableCell>
                        ) : null}
                        {USE_BRANCH_KEY ? (
                          <TableCell className="allot-regno__table--desc">
                            {node.acd_branch.branch_desc}
                          </TableCell>
                        ) : null}
                        {USE_CLASS_KEY ? (
                          <TableCell className="allot-regno__table--desc">
                            {node.acd_class.class_desc}
                          </TableCell>
                        ) : null}
                        {rowsPerPage ? null : (
                          <TableCell
                            className="allot-regno__table--input editCell"
                            onClick={() => onEdit(node)}
                          >
                            {EditMode.status && EditMode.rowKey === node.id ? (
                              <input
                                type="text"
                                value={rollNumber}
                                autoFocus={
                                  EditMode.status && EditMode.rowKey === node.id
                                }
                                onFocus={(e) => e.target.select()}
                                onBlur={() => {
                                  setEditMode({
                                    rowKey: -1,
                                    status: false,
                                  });
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  if (!isNaN(Number(e.target.value)))
                                    setRollNumber(e.target.value);
                                }}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === Keys.ENTER) {
                                    setRollNoAndIncrementNo(index);
                                  }
                                }}
                              />
                            ) : (
                              <>{node.std_reg_no}</>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {students.length > 0 && (
              <>
                <Button mode="save" type="submit" onClick={HandleSubmit} />
                {!rowsPerPage && (
                  <Button
                    type="button"
                    onClick={() => {
                      setStudents(
                        students.map((student, index) => ({
                          ...student,
                          node: {
                            ...student!.node!,
                            std_reg_no: (index + 1).toString(),
                          },
                        }))
                      );
                    }}
                  >
                    Roll Numbers in Alphabetical Order
                  </Button>
                )}
                <Button mode="clear" type="button" onClick={clearRegNos} />
              </>
            )}
            <Button
              onClick={downloadPdf}
              mode="pdf"
              disabled={classSelected && classSelected.value ? false : true}
            />
            <Button
              onClick={downloadExcel}
              mode="export"
              disabled={classSelected && classSelected.value ? false : true}
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 allot-regno__total">
            <StudentTotalCount
              totalCount={
                AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount
                  : 0
              }
            />
          </div>
        </div>
      </div>
      <LoadingModal flag={updationLoading || AcademicsStudentData.loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default RollNo;
