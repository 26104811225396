import { gql } from "@apollo/client";

export const GetAcdTestType = gql`
  query GetAcdTestType($token: String!, $inst_id: ID!) {
    GetAcdTestType(token: $token, inst_id: $inst_id) {
      id
      test_type_name
      ext_max_marks
      ext_min_marks
      int_max_marks
      int_min_marks
    }
  }
`;
export const GetAcdTestName = gql`
  query GetAcdTestName(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $for_planning: Boolean!
    $alloted_level: String!
    $allotted_id: ID!
    $test_type_id: ID!
  ) {
    GetAcdTestName(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      for_planning: $for_planning
      alloted_level: $alloted_level
      allotted_id: $allotted_id
      test_type_id: $test_type_id
    ) {
      id
      test_name
      show_marks_in_grade
      add_test_remarks
      test_type_id
      is_marks_derived
      is_non_academic
      test_type_details {
        test_type_name
      }
    }
  }
`;

export const GetAcdTestTypeById = gql`
  query GetAcdTestTypeById($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcdTestType {
        id
        test_type_name
        ext_max_marks
        ext_min_marks
        int_max_marks
        int_min_marks
      }
    }
  }
`;
export const GetAcdTestNameById = gql`
  query GetAcdTestNameById($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcdTestName {
        id
        test_name
        test_type_id
        show_marks_in_grade
        is_marks_derived
        is_non_academic
        show_marks_in_std_login
        add_test_remarks
        test_type_details {
          test_type_name
        }
      }
    }
  }
`;
export const GetAcdFeedbackFormEmpClassWiseRating = gql`
  query GetAcdFeedbackFormEmpClassWiseRating(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $pr_emp_id: ID!
    $form_name_id: ID!
    $entry_level: String!
    $entry_id: ID!
  ) {
    GetAcdFeedbackFormEmpClassWiseRating(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      form_name_id: $form_name_id
      entry_level: $entry_level
      entry_id: $entry_id
    ) {
      id
      num_questions
      rating_1
      rating_2
      rating_3
      rating_4
      rating_5
      rating_6
      rating_7
      rating_8
      rating_9
      rating_10
      rating_11
      rating_12
      rating_13
      rating_14
      rating_15
      inst_id
      acd_yr_id
      pr_emp_id
      acd_class_id
      acd_semester_id
      acd_section_id
      subj_master_id
      form_name_id
      subject_details {
        subj_code
        subj_desc
      }
    }
  }
`;
export const GetAcdFeedbackDetailsReport = gql`
  query GetAcdFeedbackDetailsReport(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $pr_emp_id: ID!
    $form_name_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $subj_master_id: ID!
  ) {
    GetAcdFeedbackDetailsReport(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      form_name_id: $form_name_id
      entry_level: $entry_level
      entry_id: $entry_id
      subj_master_id: $subj_master_id
    ) {
      id
      num_questions
      excellent_count
      good_count
      average_count
      fair_count
      poor_count
      inst_id
      acd_yr_id
      pr_emp_id
      acd_class_id
      acd_semester_id
      acd_section_id
      subj_master_id
      form_name_id
      fb_form_detail_id
      fb_details {
        id
        rating_factor
        from_name_details {
          form_name
          form_st_date
          form_end_date
        }
      }
    }
  }
`;
export const GetAcdFeedbackStdRemarks = gql`
  query GetAcdFeedbackStdRemarks(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $pr_emp_id: ID!
    $subj_master_id: ID!
    $form_name_id: ID!
    $fb_form_detail_id: ID!
  ) {
    GetAcdFeedbackStdRemarks(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      form_name_id: $form_name_id
      entry_level: $entry_level
      entry_id: $entry_id
      subj_master_id: $subj_master_id
      fb_form_detail_id: $fb_form_detail_id
    )
  }
`;
export const GetAcdTestTypeDetailsById = gql`
  query GetAcdTestTypeDetailsById($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcdTestType {
        id
        test_type_name
        test_type_idx
        ext_max_marks
        ext_min_marks
        int_max_marks
        int_min_marks
      }
    }
  }
`;
export const GetElectiveSubjDetailsForTest = gql`
  query nodes($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcdSubjectMaster {
        id
        subj_code
        subj_desc
        subj_idx
        teacher_count
        subj_board_code
        subj_is_lang
        subj_is_lab
        subj_is_elective
        subj_is_non_academic
        subj_is_core_subject
        subj_internals_avialed
        subj_apptitude_avialed
        subj_marks_in_grades
      }
    }
  }
`;

export const GetAcdTestClass = gql`
  query GetAcdTestClass(
    $token: String!
    $acd_yr_id: ID!
    $input: AcdTestQueryData!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: AcdTestClassesOrder
  ) {
    GetAcdTestClass(
      token: $token
      acd_yr_id: $acd_yr_id
      input: $input
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          test_start_date
          test_end_date
          apply_same_marks_all_subj
          subj_total_max_marks
          subj_total_min_marks
          marks_entered
          enable_teachers_comments
          test_name_details {
            test_name
            id
            test_type_details {
              test_type_name
            }
          }
          class_subject_details {
            id
            subj_total_max_marks
            subj_total_min_marks

            test_date
            subj_sl
            elective_subj_sl
            subj_is_elective
            elective_main_subj_master_id
            subj_master_id
            subject_master_details {
              id
              subj_code
              subj_desc
              subj_idx
              subj_board_code
              subj_is_lang
              subj_is_lab
              subj_is_elective
              subj_is_non_academic
              subj_is_core_subject
              subj_internals_avialed
              subj_apptitude_avialed
              subj_marks_in_grades
            }
          }
        }
      }
    }
  }
`;

export const GetAcdTestClassesForDerived = gql`
  query GetAcdTestClassesForDerived(
    $token: String!
    $acd_yr_id: ID!
    $input: AcdTestQueryData!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: AcdTestClassesOrder
  ) {
    GetAcdTestClass(
      token: $token
      acd_yr_id: $acd_yr_id
      input: $input
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          test_start_date
          test_end_date
          apply_same_marks_all_subj
          subj_total_max_marks
          subj_total_min_marks
          marks_entered
          avg_best_of_num
          out_of
          derived_method
          test_name_details {
            test_name
            id
            test_type_details {
              test_type_name
            }
          }
          class_subject_details {
            id
            subj_total_max_marks
            subj_total_min_marks
            test_date
            subj_sl
            subject_master_details {
              id
              subj_code
              subj_desc
              subj_idx
              subj_board_code
              subj_is_lang
              subj_is_lab
              subj_is_elective
              subj_is_non_academic
              subj_is_core_subject
              subj_internals_avialed
              subj_apptitude_avialed
              subj_marks_in_grades
            }
          }
          derive_tests {
            id
            derive_test_id
          }
        }
      }
    }
  }
`;

export const GetTestConducteDetailsByNode = gql`
  query GetTestConducteDetailsByNode($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcdTestClasses {
        id
        test_start_date
        test_end_date
        apply_same_marks_all_subj
        subj_total_max_marks
        subj_total_min_marks
        subj_total_max_marks
        subj_total_min_marks
        derived_method
        test_name_details {
          test_name
          add_test_remarks
          test_type_details {
            test_type_name
          }
          id
        }
        class_subject_details {
          test_date
          id
          subj_total_max_marks
          subj_sl
          subj_total_min_marks
          enable_internal_test
          subject_master_details {
            id
            subj_code
            subj_desc
            subj_idx
            subj_board_code
            subj_is_lang
            subj_is_lab
            subj_is_elective
            subj_is_non_academic
            subj_is_core_subject
            subj_internals_avialed
            subj_apptitude_avialed
            subj_marks_in_grades
          }
        }
      }
    }
  }
`;
export const GetStdTestMarks = gql`
  query GetStdTestMarks(
    $token: String!
    $acd_yr_id: ID!
    $input: StdMarksQuery!
    $after: Cursor
    $first: Int
    $admNo: String
    $per_std_subj_allocation: Boolean!
    $orderBy: AcdTestMarksConsoliateOrder
  ) {
    GetStdTestMarks(
      token: $token
      acd_yr_id: $acd_yr_id
      input: $input
      after: $after
      first: $first
      orderBy: $orderBy
      per_std_subj_allocation: $per_std_subj_allocation
      where: {
        or: [
          {
            hasMstStudentWith: {
              or: [
                { stdAdmNoContainsFold: $admNo }
                { firstNameContainsFold: $admNo }
                { middleNameContainsFold: $admNo }
                { lastNameContainsFold: $admNo }
              ]
            }
          }
        ]
      }
    ) {
      totalCount
      edges {
        node {
          id
          subj_marks_scored_total_1
          subj_marks_scored_total_2
          subj_marks_scored_total_3
          subj_marks_scored_total_4
          subj_marks_scored_total_5
          subj_marks_scored_total_6
          subj_marks_scored_total_7
          subj_marks_scored_total_8
          subj_marks_scored_total_9
          subj_marks_scored_total_10
          subj_marks_scored_total_11
          subj_marks_scored_total_12
          subj_marks_scored_total_13
          subj_marks_scored_total_14
          subj_marks_scored_total_15
          subj_marks_scored_total_16
          subj_marks_scored_total_17
          subj_marks_scored_total_18
          subj_marks_scored_total_19
          subj_marks_scored_total_20
          subj_marks_scored_total_21
          subj_marks_scored_total_22
          subj_marks_scored_total_23
          subj_marks_scored_total_24
          subj_marks_scored_total_25
          elective_subj_id_1
          elective_subj_id_2
          elective_subj_id_3
          elective_subj_id_4
          elective_subj_id_5
          elective_subj_id_6
          elective_subj_id_7
          elective_subj_id_8
          elective_subj_id_9
          elective_subj_id_10
          elective_subj_id_11
          elective_subj_id_12
          elective_subj_id_13
          elective_subj_id_14
          elective_subj_id_15
          elective_subj_id_16
          elective_subj_id_17
          elective_subj_id_18
          elective_subj_id_19
          elective_subj_id_20
          elective_subj_id_21
          elective_subj_id_22
          elective_subj_id_23
          elective_subj_id_24
          elective_subj_id_25
          subj_percentage_1
          subj_percentage_2
          subj_percentage_3
          subj_percentage_4
          subj_percentage_5
          subj_percentage_6
          subj_percentage_7
          subj_percentage_8
          subj_percentage_9
          subj_percentage_10
          subj_percentage_11
          subj_percentage_12
          subj_percentage_13
          subj_percentage_14
          subj_percentage_15
          subj_percentage_16
          subj_percentage_17
          subj_percentage_18
          subj_percentage_19
          subj_percentage_20
          subj_percentage_21
          subj_percentage_22
          subj_percentage_23
          subj_percentage_24
          subj_percentage_25
          scored_grade_1
          scored_grade_2
          scored_grade_3
          scored_grade_4
          scored_grade_5
          scored_grade_6
          scored_grade_7
          scored_grade_8
          scored_grade_9
          scored_grade_10
          scored_grade_11
          scored_grade_12
          scored_grade_13
          scored_grade_14
          scored_grade_15
          scored_grade_16
          scored_grade_17
          scored_grade_18
          scored_grade_19
          scored_grade_20
          scored_grade_21
          scored_grade_22
          scored_grade_23
          scored_grade_24
          scored_grade_25
          teacher_comments_1
          teacher_comments_2
          teacher_comments_3
          teacher_comments_4
          teacher_comments_5
          teacher_comments_6
          teacher_comments_7
          teacher_comments_8
          teacher_comments_9
          teacher_comments_10
          teacher_comments_11
          teacher_comments_12
          teacher_comments_13
          teacher_comments_14
          teacher_comments_15
          teacher_comments_16
          teacher_comments_17
          teacher_comments_18
          teacher_comments_19
          teacher_comments_20
          teacher_comments_21
          teacher_comments_22
          teacher_comments_23
          teacher_comments_24
          teacher_comments_25
          total_max_marks
          total_min_marks
          total_scored_marks
          total_percent
          overall_grade
          pass_fail
          rank_allotted_level
          rank_entry_level
          test_remarks

          student_details {
            first_name
            middle_name
            last_name
            std_reg_no
            std_adm_no
            id
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GetStdTestMarksBySubjId = gql`
  query GetStdTestMarksBySubjId(
    $token: String!
    $acd_yr_id: ID!
    $inst_id: ID!
    $subj_master_id: ID!
    $acd_test_class_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $after: Cursor
    $first: Int
    $name: String
    $per_std_subj_allocation: Boolean!
    $orderBy: AcdTestMarksIndivualOrder
  ) {
    GetStdTestMarksBySubjId(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      subj_master_id: $subj_master_id
      acd_test_class_id: $acd_test_class_id
      entry_level: $entry_level
      entry_id: $entry_id
      after: $after
      first: $first
      orderBy: $orderBy
      per_std_subj_allocation: $per_std_subj_allocation
      where: {
        or: [
          {
            hasMstStudentWith: {
              or: [
                { firstNameContainsFold: $name }
                { middleNameContainsFold: $name }
                { lastNameContainsFold: $name }
                { stdAdmNoContainsFold: $name }
                { stdRegNoContainsFold: $name }
              ]
            }
          }
        ]
      }
    ) {
      edges {
        node {
          id
          marks_max_total
          marks_min_total
          marks_scored_tot
          marks_num_to_100
          scored_grade
          teacher_comments
          subj_result
          rank_allotted_level
          rank_entry_level
          is_std_present
          inst_id
          acd_yr_id
          student_id
          acd_class_id
          acd_semester_id
          acd_section_id
          acd_test_class_id
          subj_master_id
          subject_details {
            id
            subj_code
            subj_desc
            subj_idx
            subj_board_code
            subj_is_lang
            subj_is_lab
            subj_is_elective
            subj_is_non_academic
            subj_is_core_subject
            subj_internals_avialed
            subj_apptitude_avialed
            subj_marks_in_grades
          }
          student_details {
            id
            first_name
            middle_name
            last_name
            std_reg_no
            std_adm_no
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GetStdTestMarksByStudentId = gql`
  query GetStdTestMarksByStudentId(
    $token: String!
    $acd_yr_id: ID!
    $inst_id: ID!
    $student_id: ID!
    $acd_test_class_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: AcdTestMarksIndivualOrder
    $per_std_subj_allocation: Boolean!
  ) {
    GetStdTestMarksByStudentId(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      student_id: $student_id
      acd_test_class_id: $acd_test_class_id
      entry_level: $entry_level
      entry_id: $entry_id
      after: $after
      first: $first
      orderBy: $orderBy
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      edges {
        node {
          id
          marks_max_total
          marks_min_total
          marks_scored_tot
          marks_num_to_100
          subj_result
          rank_entry_level
          rank_allotted_level
          inst_id
          acd_yr_id
          student_id
          acd_class_id
          acd_semester_id
          acd_section_id
          acd_test_class_id
          subj_master_id
          scored_grade
          teacher_comments

          is_std_present
          subject_details {
            id
            subj_code
            subj_desc
            subj_idx
            subj_board_code
            subj_is_lang
            subj_is_lab
            subj_is_elective
            subj_is_non_academic
            subj_is_core_subject
            subj_internals_avialed
            subj_apptitude_avialed
            subj_marks_in_grades
          }
          student_details {
            id
            first_name
            middle_name
            last_name
          }
          acd_test_class_details {
            enable_teachers_comments
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GetAcdTestMarksStatus = gql`
  query GetAcdTestMarksStatus(
    $token: String!
    $acd_yr_id: ID!
    $input: AcdReferanceIds!
    $query_type: AcdTestMarksStatusQueryType!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdTestMarksStatus(
      token: $token
      acd_yr_id: $acd_yr_id
      input: $input
      query_type: $query_type
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      id
      no_subjects
      is_marks_entry_completed
      is_marks_entry_started
      is_report_generated
      # marks_entry_start_date
      # marks_entry_end_date
      is_non_academic
      publish_results
      acd_test_class_id
      num_of_std_test_taken
      is_test_remarks_added
      test_class_details {
        id
        test_start_date
        test_end_date
        apply_same_marks_all_subj
        subj_total_max_marks
        subj_total_min_marks
        derived_method
        derived_test_count
        out_of
        avg_best_of_num
        test_name_details {
          test_name
          test_type_id
          add_test_remarks
          test_type_details {
            test_type_name
          }
          is_marks_derived
          id
        }
        class_subject_details {
          id
          subj_total_max_marks
          subj_total_min_marks
          subject_master_details {
            id
            subj_code
            subj_desc
            subj_idx
            subj_board_code
            subj_is_lang
            subj_is_lab
            subj_is_elective
            subj_is_non_academic
            subj_is_core_subject
            subj_internals_avialed
            subj_apptitude_avialed
            subj_marks_in_grades
          }
        }
      }
    }
  }
`;

export const GetAcdTestStudentSubjectPerformance = gql`
  query GetAcdTestStudentSubjectPerformance(
    $token: String!
    $acdYrId: ID!
    $studentId: ID!
    $subjMasterId: ID!
    $queryType: AcdTestMarksStatusQueryType!
    $input: AcdReferanceIds!
  ) {
    GetAcdTestStudentSubjectPerformance(
      token: $token
      acd_yr_id: $acdYrId
      student_id: $studentId
      subj_master_id: $subjMasterId
      query_type: $queryType
      input: $input
    ) {
      test_max_marks_scored
      test_avg_marks_scored
      student_marks_scored
    }
  }
`;
export const GetAcdStudentsCountForTest = gql`
  query GetAcdStudentsCountForTest(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $input: AcdReferanceIds!
    $acd_subj_combo_id: ID!
  ) {
    GetAcdStudentsCountForTest(
      token: $token
      acd_yr_id: $acd_yr_id
      input: $input
      inst_id: $inst_id
      acd_subj_combo_id: $acd_subj_combo_id
    ) {
      total_students
      total_present
      total_absent
      att_percentage
      completed_session
    }
  }
`;
export const GetAcdStudentMarksStatistics = gql`
  query GetAcdStudentMarksStatistics(
    $token: String!
    $acd_yr_id: ID!
    $inst_id: ID!
    $student_id: ID!
    $acd_test_class_ids: [ID!]!
    $subj_master_ids: [ID!]!
    $pr_emp_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdStudentMarksStatistics(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
      student_id: $student_id
      acd_test_class_ids: $acd_test_class_ids
      subj_master_ids: $subj_master_ids
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      acd_test_class_id
      acd_test_name
      test_total_max_marks
      test_total_min_marks
      test_top_percentage
      test_avg_percentage
      test_std_total_percentage
      test_std_total_marks_scored
      att_percentage
      test_start_date
      test_end_date
      test_type_name
      marks_details {
        subj_master_id
        subj_desc
        subj_code
        std_marks_scored
        marks_percentage
        subj_max_marks_scored
        subj_avg_marks_scored
        subj_total_max_marks
        subj_total_min_marks
        is_std_present
      }
    }
  }
`;
export const GetAcdStudentsMarksStatisticsAtEntryLevel = gql`
  query GetAcdStudentsMarksStatisticsAtEntryLevel(
    $token: String!
    $acd_yr_id: ID!
    $inst_id: ID!
    $student_ids: [ID!]!
    $input: AcdReferanceIds!
    $acd_subj_combo_id: ID!
  ) {
    GetAcdStudentsMarksStatisticsAtEntryLevel(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      input: $input
      student_ids: $student_ids
      acd_subj_combo_id: $acd_subj_combo_id
    ) {
      student_id
      acd_test_class_id
      test_start_date
      test_end_date
      test_type_name
      acd_test_name
      test_total_max_marks
      test_total_min_marks
      test_top_percentage
      test_avg_percentage
      test_std_total_percentage
      test_std_total_marks_scored
      att_percentage
      test_remarks
      student_details {
        std_adm_no
        first_name
        middle_name
        last_name
        branch_desc
        class_desc
        section_desc
        std_profile_filename
        std_father_name
        std_mother_name
      }
      marks_details {
        subj_master_id
        subj_desc
        subj_code
        std_marks_scored
        marks_percentage
        subj_total_max_marks
        subj_total_min_marks
        subj_max_marks_scored
        subj_avg_marks_scored
        is_std_present
      }
    }
  }
`;
export const GetAcdTestClassSubjects = gql`
  query GetAcdTestClassSubjects(
    $token: String!
    $acd_test_classes_id: ID!
    $inst_id: ID!
    $acd_yr_id: ID!
    $pr_emp_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $query_type: AcdTestClassSubjQueryType!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdTestClassSubjects(
      token: $token
      acd_test_classes_id: $acd_test_classes_id
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      entry_level: $entry_level
      entry_id: $entry_id
      query_type: $query_type
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      subj_total_max_marks
      subj_total_min_marks
      subj_master_id
      subj_is_elective
      test_class_details {
        test_start_date
        test_end_date
        enable_teachers_comments
        is_non_academic
        add_test_remarks
        test_name_details {
          add_test_remarks
          test_name
          show_marks_in_grade
          test_type_details {
            test_type_name
          }
        }
      }
      subject_master_details {
        id
        subj_code
        subj_desc
        subj_is_non_academic
      }
    }
  }
`;
export const GetAcdTestMarksStatusByNode = gql`
  query GetAcdTestMarksStatusByNode($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcdTestMarksStatus {
        id
        is_report_generated
      }
    }
  }
`;
