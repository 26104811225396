import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctLdgrMonthlyDataByInstIdData,
  GetAcctLdgrMonthlyDataByInstIdDetails,
  GetAcctLdgrMonthlyDataByInstIdVars,
  GetAcctLdgrYearlyDataByInstIdDetails,
  GetAcctRegisterMonthlyDataByInstIdData,
  GetAcctRegisterMonthlyDataByInstIdDetails,
  GetAcctRegisterMonthlyDataByInstIdVars,
  GetAcctRegisterYearlyDataByInstIdDetails,
} from "../../../../Types/Accounting";
import {
  GetAcctLdgrMonthlyDataByInstId,
  GetAcctRegisterMonthlyDataByInstId,
} from "../../queries/Accountingledgers/List/ById";
import { AppContext } from "../../../../context/context";
import { useParams } from "react-router-dom";
import {
  formatter,
  getHeaderRowStyle,
  handleDbOrCr,
  MonthName,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
import {
  BankOrCash,
  DebitOrCredit,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  ReceiptTypes,
} from "../../../../utils/Enum.types";

import Enlarge from "../../../../images/Enlarge.svg";
import Pointer from "../../../../images/Pointer.svg";
import useToken from "../../../../customhooks/useToken";
import { Title } from "../../../../stories/Title/Title";
import { EMPTY_STRING } from "../../../../utils/constants";
import { payloadTypes } from "../../../../context/reducer";
import { GroupLedgerRPTypes } from "../../common/QueryTypes";
import { Button } from "../../../../stories/Button/Button";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import { header } from "../../common/HeaderConsts";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  LedgersOrRegisters,
  OtherAccountLedgerBooks,
} from "../../../../utils/types";
import useAcctTableJson from "../../json/useAcctTableJson";

interface Props {
  acctldgrYearlyData: GetAcctLdgrYearlyDataByInstIdDetails[];
  registerYearlyData: GetAcctRegisterYearlyDataByInstIdDetails[];
  type: LedgersOrRegisters;
  completeDetailsModal: boolean;
  setCompleteDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const belongsAccountLedgerBooks = (type: LedgersOrRegisters) => {
  let typeToBankOrCash = type as BankOrCash;
  const bankOrCashValues = Object.values(BankOrCash);

  return bankOrCashValues.includes(typeToBankOrCash);
};
const MonthlyTotal = ({
  acctldgrYearlyData,
  type,
  registerYearlyData,
  setCompleteDetailsModal,
  completeDetailsModal,
}: Props) => {
  const { format } = formatter;
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();
  const { Accounts_Table } = useAcctTableJson();
  const { serverDate } = useServerDateandTime();
  const [acctLedgerMonthData, setAcctLedgerMonthlyData] = useState<
    GetAcctLdgrMonthlyDataByInstIdDetails[]
  >([]);
  const [registerItems, setRegisterItems] = useState<
    GetAcctRegisterMonthlyDataByInstIdDetails[]
  >([]);

  const { InstDetails } = useInstDetails(1);

  const [GetMonthlyLedgersData] = useLazyQuery<
    GetAcctLdgrMonthlyDataByInstIdData,
    GetAcctLdgrMonthlyDataByInstIdVars
  >(GetAcctLdgrMonthlyDataByInstId);
  const [GetMonthlyRegistersData] = useLazyQuery<
    GetAcctRegisterMonthlyDataByInstIdData,
    GetAcctRegisterMonthlyDataByInstIdVars
  >(GetAcctRegisterMonthlyDataByInstId);

  const monthlyData = acctLedgerMonthData?.map((item, index) => ({
    SlNo: index + 1,
    Month: MonthName(item.acct_ldgr_month_date),
    Debit: item.acct_ldgr_month_db,
    Credit: item.acct_ldgr_month_cr,
    Balance:
      format(Math.abs(item.acct_ldgr_month_cb)) +
      " " +
      handleDbOrCr(
        item.acct_group_ldgr_details.gr_ldgr_rp as GroupLedgerRPTypes,
        item.acct_ldgr_month_cb
      ),
  }));
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.MONTHLY_TOTAL);

    const dataRows = monthlyData
      ? monthlyData?.map((item) => [
          item.SlNo,
          item.Month,
          item.Debit,
          item.Credit,
          item.Balance,
        ])
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 33;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 35;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          // const imageV = workbook.addImage({
          //   base64: result,
          //   extension: EDUATE_IMG_FORMAT,
          // });

          // worksheet.addImage(imageV, "H1:H1");
          worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;
          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:E2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:E3");

          const mergedHeader: Cell = worksheet.getCell(C4_CELL);
          mergedHeader.value = ExcelPageHeader.MONTHLY_TOTAL;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("C4:D4");
          const mergedDate: Cell = worksheet.getCell(E4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("E4:E4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : EMPTY_STRING;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:B4");

          let Char = FIRST_INDEX;

          for (let i = 0; i < header.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 5);
            rowData.value = header[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }

          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.RIGHT };
              cell.font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };
              row.getCell(2).alignment = {
                horizontal: ExcelAlignment.CENTER,
              };
            });
          });

          const footerRow = worksheet.addRow([]);
          footerRow.getCell(2).value = ExcelFooterHeader.TOTAL;
          footerRow.getCell(2).font = {
            name: ExcelFont.ARIAL,
            size: 11,
          };
          footerRow.getCell(3).value = totalDebitAmount;
          footerRow.getCell(4).value = totalCreditAmount;
          footerRow.getCell(5).value =
            Math.abs(
              acctLedgerMonthData[acctLedgerMonthData.length - 1]
                ?.acct_ldgr_month_cb
            ) +
            " " +
            handleDbOrCr(
              acctLedgerMonthData[acctLedgerMonthData.length - 1]
                ?.acct_group_ldgr_details.gr_ldgr_rp as GroupLedgerRPTypes,
              acctLedgerMonthData[acctLedgerMonthData.length - 1]
                ?.acct_ldgr_month_cb
            );

          for (let i = 2; i <= 6; i++) {
            footerRow.getCell(i).alignment = {
              horizontal: ExcelAlignment.RIGHT,
            };
          }

          worksheet.addConditionalFormatting({
            ref: `C${footerRow.number}:E${footerRow.number}`,
            rules: FOOTER_CSS,
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.MONTHLY_TOTAL);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
  };

  useEffect(() => {
    if (
      state.accountLedgerId &&
      (belongsAccountLedgerBooks(type) ||
        type === OtherAccountLedgerBooks.GROUP_LEDGER)
    ) {
      GetMonthlyLedgersData({
        variables: {
          acct_ldgr_id: state.accountLedgerId,
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      }).then(({ data }) => {
        if (data) {
          setAcctLedgerMonthlyData(data.GetAcctLdgrMonthlyDataByInstId);
        }
      });
    }
  }, [
    state.ActiveFinYr,
    GetMonthlyLedgersData,
    InstId,
    state.accountLedgerId,
    acctldgrYearlyData,
    token,
    type,
  ]);
  useEffect(() => {
    if (type === OtherAccountLedgerBooks.REGISTER && state.register) {
      GetMonthlyRegistersData({
        variables: {
          token,
          inst_id: Number(InstId)!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          register_type: state.register.value,
        },
      }).then(({ data }) => {
        if (data) {
          setRegisterItems(data.GetAcctRegisterMonthlyDataByInstId);
        }
      });
    }
  }, [
    state.ActiveFinYr,
    GetMonthlyRegistersData,
    setRegisterItems,
    InstId,
    token,
    type,
    state.registerType,
  ]);
  let totalDebitAmount = 0;
  let totalCreditAmount = 0;
  let registerTotalDebitAmount = 0;
  let registerTotalCreditAmount = 0;
  let registerNoOfTransactions = 0;

  // eslint-disable-next-line
  acctLedgerMonthData?.map((item) => {
    totalDebitAmount += item.acct_ldgr_month_db;
    totalCreditAmount += item.acct_ldgr_month_cr;
  });
  // eslint-disable-next-line
  registerItems?.map((item) => {
    registerNoOfTransactions += item.register_month_voucher_count;
    registerTotalDebitAmount += item.register_month_db;
    registerTotalCreditAmount += item.register_month_cr;
  });
  const showDbOrCr = (transactionType: DebitOrCredit) => {
    if (state.register) {
      switch (state.register.value) {
        case ReceiptTypes.STUDENT_RECEIPT:
        case ReceiptTypes.RCPT:
        case ReceiptTypes.JOURNAL:
          if (transactionType === DebitOrCredit.DEBIT) {
            return true;
          } else {
            return false;
          }
        case ReceiptTypes.PYMT:
          if (transactionType === DebitOrCredit.CREDIT) {
            return true;
          } else {
            return false;
          }
      }
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="bank-book__table-section--transaction-details--table--title">
        <Title variant="subtitle1">
          {Accounts_Table.Reports.AccountLedgerBooks.MonthlyTotal.Title.map(
            (title: TableHeaderProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>{title.labelName}</React.Fragment>
              );
            }
          )}
        </Title>

        <div>
          {completeDetailsModal ? null : (
            <img
              src={Enlarge}
              alt="/"
              onClick={() => setCompleteDetailsModal(!completeDetailsModal)}
            />
          )}
        </div>
      </div>
      <TableContainer className="bank-book__table-section--monthly-total--table">
        <Table stickyHeader>
          <TableHead>
            {(belongsAccountLedgerBooks(type) ||
              type === OtherAccountLedgerBooks.GROUP_LEDGER) && (
              <TableRow>
                {Accounts_Table.Reports.AccountLedgerBooks.MonthlyTotal.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            )}
            {type === OtherAccountLedgerBooks.REGISTER && (
              <TableRow className="bank-book__table-section--monthly-total--table--sub-header">
                {Accounts_Table.Reports.AccountLedgerBooks.MonthlyTotalRegister.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>O.B</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>

              {belongsAccountLedgerBooks(type) && acctldgrYearlyData ? (
                <TableCell
                  id="td-right"
                  className="bank-book__table-section--monthly-total--table--amount">
                  {acctldgrYearlyData[0]?.acct_ldgr_year_ob === 0
                    ? "-"
                    : acctldgrYearlyData[0] &&
                      acctldgrYearlyData[0].acct_ldgr_year_ob
                    ? format(Math.abs(acctldgrYearlyData[0]?.acct_ldgr_year_ob))
                    : "" +
                      " " +
                      handleDbOrCr(
                        acctldgrYearlyData[0]?.acct_group_ldgr_details
                          .gr_ldgr_rp as GroupLedgerRPTypes,
                        acctldgrYearlyData[0]?.acct_ldgr_year_ob
                      )}
                </TableCell>
              ) : (
                <TableCell
                  id="td-right"
                  className="bank-book__table-section--monthly-total--table--amount">
                  {/* {registerYearlyData&& registerYearlyData[0]?.acct_ldgr_year_ob === 0
                ? "-"
                : format(Math.abs(registerYearlyData[0]?.acct_ldgr_year_ob)) +
                  " " +
                  handleDbOrCr(
                    registerYearlyData[0]?.acct_group_ldgr_details
                      .gr_ldgr_rp as GroupLedgerRPTypes,
                    registerYearlyData[0]?.acct_ldgr_year_ob
                  )} */}
                </TableCell>
              )}
            </TableRow>
            {(belongsAccountLedgerBooks(type) ||
              type === OtherAccountLedgerBooks.GROUP_LEDGER) &&
              acctLedgerMonthData?.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    className={
                      item.acct_ldgr_month_date?.toString() ===
                      state.date?.toString()
                        ? "selected-row"
                        : EMPTY_STRING
                    }
                    onClick={() => {
                      dispatch({
                        type: payloadTypes.SET_DATE,
                        payload: {
                          date: item.acct_ldgr_month_date,
                        },
                      });
                    }}>
                    <TableCell
                      className={
                        completeDetailsModal
                          ? "bank-book__table-section--monthly-total--table--month--tr"
                          : "bank-book__table-section--monthly-total--table--month"
                      }>
                      {item.acct_ldgr_month_date?.toString() ===
                      state.date?.toString() ? (
                        <img src={Pointer} alt="/" className="pointer-icon" />
                      ) : (
                        EMPTY_STRING
                      )}
                      {MonthName(item.acct_ldgr_month_date)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--monthly-total--table--amount">
                      {item.acct_ldgr_month_db === 0
                        ? "-"
                        : format(item.acct_ldgr_month_db)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--monthly-total--table--amount">
                      {item.acct_ldgr_month_cr === 0
                        ? "-"
                        : format(item.acct_ldgr_month_cr)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--monthly-total--table--amount">
                      {item.acct_ldgr_month_cb === 0
                        ? "-"
                        : format(Math.abs(item.acct_ldgr_month_cb)) +
                          " " +
                          handleDbOrCr(
                            item.acct_group_ldgr_details
                              .gr_ldgr_rp as GroupLedgerRPTypes,
                            item.acct_ldgr_month_cb
                          )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {type === OtherAccountLedgerBooks.REGISTER &&
              registerItems.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={() => {
                      dispatch({
                        type: payloadTypes.SET_DATE,
                        payload: {
                          date: item.register_month_date,
                        },
                      });
                    }}>
                    <TableCell
                      id="td-left"
                      className="bank-book__table-section--monthly-total--table--month">
                      {MonthName(item.register_month_date)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--monthly-total--table--amount">
                      {item.register_month_voucher_count}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--monthly-total--table--amount">
                      {showDbOrCr(DebitOrCredit.DEBIT)
                        ? format(item.register_month_db)
                        : "-"}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--monthly-total--table--amount">
                      {showDbOrCr(DebitOrCredit.CREDIT)
                        ? format(item.register_month_cr)
                        : "-"}
                    </TableCell>
                    {/* <TableCell
                      id="td-right"
                      className="bank-book__table-section--monthly-total--table--amount"
                    >
                      {item.acct_ldgr_month_cb === 0
                        ? "-"
                        : format(Math.abs(item.acct_ldgr_month_cb)) +
                          " " +
                          handleDbOrCr(
                            item.acct_group_ldgr_details
                              .gr_ldgr_rp as GroupLedgerRPTypes,
                            item.acct_ldgr_month_cb
                          )}
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            {(belongsAccountLedgerBooks(type) ||
              type === OtherAccountLedgerBooks.GROUP_LEDGER) && (
              <TableRow>
                <TableCell colSpan={1} className="total">
                  Total:
                </TableCell>
                <TableCell className="totalcount bank-book__table-section--monthly-total--table--amount">
                  {format(totalDebitAmount)}
                </TableCell>
                <TableCell className="totalcount bank-book__table-section--monthly-total--table--amount">
                  {format(totalCreditAmount)}
                </TableCell>
                <TableCell className="totalcount bank-book__table-section--monthly-total--table--amount">
                  {acctLedgerMonthData[acctLedgerMonthData.length - 1] &&
                  acctLedgerMonthData[acctLedgerMonthData.length - 1]
                    .acct_ldgr_month_ob
                    ? format(
                        Math.abs(
                          acctLedgerMonthData[acctLedgerMonthData.length - 1]
                            ?.acct_ldgr_month_cb
                        )
                      )
                    : "" +
                      " " +
                      handleDbOrCr(
                        acctLedgerMonthData[acctLedgerMonthData.length - 1]
                          ?.acct_group_ldgr_details
                          .gr_ldgr_rp as GroupLedgerRPTypes,
                        acctLedgerMonthData[acctLedgerMonthData.length - 1]
                          ?.acct_ldgr_month_cb
                      )}
                </TableCell>
              </TableRow>
            )}
            {type === OtherAccountLedgerBooks.REGISTER && (
              <TableRow>
                <TableCell colSpan={1} className="total">
                  Total:
                </TableCell>
                <TableCell className="totalcount bank-book__table-section--monthly-total--table--amount">
                  {registerNoOfTransactions}
                </TableCell>
                <TableCell className="totalcount bank-book__table-section--monthly-total--table--amount">
                  {showDbOrCr(DebitOrCredit.DEBIT)
                    ? format(registerTotalDebitAmount)
                    : "-"}
                </TableCell>
                <TableCell className="totalcount bank-book__table-section--monthly-total--table--amount">
                  {showDbOrCr(DebitOrCredit.CREDIT)
                    ? format(registerTotalCreditAmount)
                    : "-"}
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {completeDetailsModal && (
        <>
          <Button
            mode="cancel"
            onClick={() => setCompleteDetailsModal(!completeDetailsModal)}
          />
        </>
      )}
    </>
  );
};

export default MonthlyTotal;
