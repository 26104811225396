import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
} from "../../../Types/configtypes";
import { GetSwConfigVariables } from "../../../queries/institution/configuration/query/SoftwreConfig";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { SwConfigQueryType } from "../../HR/enums/Enum.types";
import { AppContext } from "../../../context/context";
import { EMPTY_STRING } from "../../../utils/constants";

const useSwConfigInstType = () => {
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { token } = useToken();

  const [GetConfigData, { data, loading, error }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId || state.InstId,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: "INSTITUTION_TYPE",
        int_value: 1,
      },
    },
  });

  const institutionType = data
    ? (data.GetSwConfigVariables[0].config_string_value as string)
    : EMPTY_STRING;

  useEffect(() => {
    if (token && InstId) {
      GetConfigData();
    }
  }, [token, InstId, GetConfigData]);

  return {
    institutionType,
    loading,
    error,
    data,
  };
};

export default useSwConfigInstType;
