import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Title } from "../../../stories/Title/Title";

import { Button } from "../../../stories/Button/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Modal from "react-modal";
import Close from "../../../images/Close.svg";
import Edit from "../../../images/EditProfile.svg";
import DeleteIcon from "../../../images/Delete.svg";

import Input from ".././../../stories/Input/Input";

import DataFetchIcon from "../../../images/Add.svg";

import Home from "../Home/Index";
import useAcctLedgerData from "../../Accounts/hooks/useAcctLedgerData";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { AcctLedgerQueryType } from "../../Accounts/common/QueryTypes";
import { msgType, responseType } from "../../../utils/Form.types";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../styles/TooltipStyles";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddTransportRouteFee,
  DeleteTransportRouteFee,
  UpdateTransportRouteFee,
} from "../queries/mutations";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../utils/Enum.types";
import LoadingModal from "../../../pages/LoadingModal";
import { GetTransportRouteFee } from "../queries/list";
import { TableHeaderProps } from "../../../Types/Tables";
import FeeLedger from "../../Accounts/FeeLedger/Index";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import { InstitutionCustomStyles } from "../../../styles/ModalStyles";
import {
  GetTransportRouteFeeData,
  GetTransportRouteFeeVars,
  TransportRouteFeeEdge,
} from "../paginationTypes";
import DeleteModal from "../../../pages/DeleteModal";
import { Keys } from "../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useTransportTableJson from "../json/useTransportTableJson";
import { AppContext } from "../../../context/context";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";

const StudentList = () => {
  const { Transport_Table } = useTransportTableJson();
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { state } = useContext(AppContext);

  const [ledgers, setLedgers] = useState<TransportRouteFeeEdge[]>([]);

  const [ledgerModal, setLedgerModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [accountLedger, setAccountLedger] = useState<responseType | null>(null);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [localId, setLocalId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [searchAcctLedger, setSearchAcctldgrs] = useState("");

  //refs
  const amountRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  const { acctLedgers } = useAcctLedgerData(
    searchAcctLedger,
    AcctLedgerQueryType.TRANSPORT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  const [GetTransportFee, { data, loading: LedgerLoading, fetchMore }] =
    useLazyQuery<GetTransportRouteFeeData, GetTransportRouteFeeVars>(
      GetTransportRouteFee
    );

  const [AddRouteFee, { loading }] = useMutation(AddTransportRouteFee, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateRouteFee, { loading: UpdateLoading }] = useMutation(
    UpdateTransportRouteFee,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteStudentRoute] = useMutation(DeleteTransportRouteFee, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const filterItemByReference = (id: number) => {
    const res = ledgers?.find((item) => item.node.id === id);
    if (res) {
      setAccountLedger({
        label: res.node.acct_ldgr_details.ldgr_desc,
        value: res.node.acct_ldgr_id,
      });
      setAmount(res.node.route_fee);
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetTransportRouteFee.edges;
            const pageInfo = fetchMoreResult.GetTransportRouteFee.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetTransportRouteFee.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetTransportRouteFee: {
                edges: [...ledgers, ...newEdges],
                pageInfo,
                totalCount: data?.GetTransportRouteFee.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const HandleSubmit = () => {
    AddRouteFee({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: {
          route_fee: amount,
          acct_ldgr_id: accountLedger ? accountLedger.value : null,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      },
      refetchQueries: [
        {
          query: GetTransportRouteFee,
          variables: {
            token,
            after: null,
            name: EMPTY_STRING,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            sortBy: SortBy.ACCT_LDGR_ID,
            inst_id: InstId,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "SuccessFully Added Amount to Transport Ledger",
          operation: Operation.CREATE,
        });
      }
    });
    if (operation === Operation.UPDATE) {
      UpdateRouteFee({
        variables: {
          token,
          id: localId,
          inst_id: InstId,
          user_details,
          input: {
            route_fee: amount,
            acct_ldgr_id: accountLedger ? accountLedger.value : null,
          },
        },
        refetchQueries: [
          {
            query: GetTransportRouteFee,
            variables: {
              token,
              after: null,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              direction: Direction.ASC,
              sortBy: SortBy.ACCT_LDGR_ID,
              inst_id: InstId,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "SuccessFully Updated  Transport Ledger",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: Number) => {
    DeleteStudentRoute({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetTransportRouteFee,
          variables: {
            token,
            after: null,
            name: EMPTY_STRING,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            sortBy: SortBy.ACCT_LDGR_ID,
            inst_id: InstId,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Deleted Student Associated Route",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleClear = () => {
    setAccountLedger(EMPTY_RESPONSETYPE_OBJECT);
    setAmount(0);
    setSearchAcctldgrs("");
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token) {
      GetTransportFee({
        variables: {
          token,
          after: null,
          name: EMPTY_STRING,
          first: ROWS_PER_PAGE,
          direction: Direction.ASC,
          sortBy: SortBy.ACCT_LDGR_ID,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      });
    }
  }, [token, GetTransportFee, state.ActiveFinYr, InstId]);
  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetTransportRouteFee.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = ledgers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newVoucher;
        });
        setLedgers(updatedNewData);
      } else {
        setLedgers(newData);
      }
      setEndCursor(data.GetTransportRouteFee.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>Create Transport Ledger</Title>
        </div>
      </div>
      <div className="transport-ledger">
        <div className="row g-0 transport-ledger__options">
          <div className="col-3 transport-ledger__options--image-flex">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={acctLedgers.responseType || []}
              openOnFocus
              value={accountLedger}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (accountLedger?.value) {
                    amountRef?.current?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setAccountLedger(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setAccountLedger(newValue as responseType);
                  setHasNextPage(true);
                } else setAccountLedger(null);
              }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="Transport Ledger"
                  fullWidth
                  onChange={(e) => setSearchAcctldgrs(e.target.value)}
                  className={labelClasses.formControlRoot}
                         slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                />
              )}
            />
            <CustomTooltip
              title="Add Ledger"
              arrow
              placement="top"
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor: TOOLTIP_COLORS.VARIABLE,
                    "& .MuiTooltip-arrow": {
                      color: TOOLTIP_COLORS.VARIABLE,
                    },
                  },
                },
              }}
            >
              <img
                src={DataFetchIcon}
                alt="/"
                className="data-fetch-icon"
                onClick={() => setLedgerModal(!ledgerModal)}
              />
            </CustomTooltip>
          </div>
        </div>
        <div className="row g-0 transport-ledger__amount">
          <div className="col-2 ">
            <Input
              className="transport-ledger__amount--input"
              placeholder="Enter Amount"
              inputRef={amountRef}
              value={amount}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (amount) {
                    saveRef?.current?.focus();
                  }
                }
              }}
              onChange={(e) => setAmount(Number(e.target.value))}
            />
          </div>
          <div className="col-1  transport-ledger__amount--button">
            <Button mode="save" buttonref={saveRef} onClick={HandleSubmit} />
          </div>
        </div>
        <div className="transport-ledger__tableblock">
          <TableContainer
            className="transport-ledger__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Transport_Table.MasterData.TransLedger.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell className={th.className} key={index}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {ledgers?.map((res, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="transport-ledger__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {res.node.acct_ldgr_details.ldgr_desc}
                      </TableCell>
                      <TableCell
                        className="transport-ledger__table--ledger"
                        id="td-right"
                      >
                        {res.node.route_fee}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="transport-ledger__table--actions"
                      >
                        <img
                          src={Edit}
                          alt="/"
                          onClick={() => {
                            filterItemByReference(res.node.id);
                            setLocalId(res.node.id);
                            setOperation(Operation.UPDATE);
                          }}
                        />
                        <img
                          src={DeleteIcon}
                          alt="/"
                          onClick={() => {
                            setDeleteId(res.node.id);
                            setDeleteModal(!deleteModal);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 parentlist__total">
            <div className="student-total-count">
              Total Ledgers :<b>{data?.GetTransportRouteFee.totalCount!}</b>
            </div>
          </div>
        </div>
      </div>

      {/* ledger-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={ledgerModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setLedgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setLedgerModal(!ledgerModal)}
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={deleteId}
      />
      <LoadingModal flag={loading || UpdateLoading || LedgerLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentList;
