import { useLazyQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StudentDetailsvars } from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import Home from "../../Home/Index";

import { GetStudentDetails } from "../../../../queries/common";
import { ListParentStudentAssocByParentId } from "../../../../queries/parent/list/byId";
import { studentAcademicYearFormDetails } from "../../../../Types/Accounting";
import {
  parentStudentAssociationByParentIdData,
  parentStudentAssociationByParentIdVars,
} from "../../../../Types/Student";
import { StudentData } from "../../../Master/parent/ParentStudentAssociation";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { ParentByNodeId } from "../../../../queries/parent/list";
import { EMPTY_STRING } from "../../../../utils/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaders } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import ParentDetails from "./parentDetails";
import ParentPreviewTabs from "./parentPreviewTabs";
import useInstLabels from "../../../../customhooks/general/useInstLabels";

const ParentAssociationPreview = () => {
  const { InstId, parentId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();

  const [studentList, setStudentList] = useState<
    studentAcademicYearFormDetails[]
  >([
    {
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    },
  ]);

  // eslint-disable-next-line
  const [ParentStudentList, setParentStudentList] = useState<
    studentAcademicYearFormDetails[]
  >([
    {
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    },
  ]);

  const [GetStudentsAcademicDetails, { data: studentAcademicData }] =
    useLazyQuery<StudentData, StudentDetailsvars>(GetStudentDetails);

  const [
    GetParentStudentAssoc,
    {
      data: ParentStudentAssociationData,
      loading: ParentStudentAssociationLoading,
    },
  ] = useLazyQuery<
    parentStudentAssociationByParentIdData,
    parentStudentAssociationByParentIdVars
  >(ListParentStudentAssocByParentId, {
    variables: {
      token,
      parent_id: parentId!,
    },
  });
  const [GetParentDetails, { data }] = useLazyQuery(ParentByNodeId, {
    variables: { token, id: parentId },
  });

  useEffect(() => {
    //UseEffect for update only to display added student list

    if (token) {
      GetParentStudentAssoc().then(({ data }) => {
        if (data && data.GetParentStudentAssociByParentId) {
          const studentIds = data.GetParentStudentAssociByParentId.map(
            (response) => response.student_id
          );

          GetStudentsAcademicDetails({
            variables: {
              token,
              ids: studentIds,
            },
          })
            .then(({ data }) => {
              data?.nodes.map(
                (
                  studentData,
                  index: number
                  // eslint-disable-next-line
                ) => {
                  setStudentList((prevValues) => [
                    {
                      std_id: studentIds[index],
                      std_adm_no: studentData.std_adm_no,
                      first_name: studentData.first_name,
                      middle_name: studentData.middle_name,
                      last_name: studentData.last_name,
                      std_reg_no: studentData.std_reg_no,
                      std_father_name: studentData.std_father_name,
                      branch_desc: studentData.branch.branch_desc,
                      class_desc: studentData.class.class_desc,
                      dept_desc: studentData.dept.dept_desc,
                      cat_desc: studentData.category.cat_desc,
                    },
                    ...prevValues,
                  ]);
                  setParentStudentList((prevValues) => [
                    {
                      std_id: studentIds[index],
                      std_adm_no: studentData.std_adm_no,
                      first_name: studentData.first_name,
                      middle_name: studentData.middle_name,
                      last_name: studentData.last_name,
                      std_reg_no: studentData.std_reg_no,
                      std_father_name: studentData.std_father_name,
                      branch_desc: studentData.branch.branch_desc,
                      class_desc: studentData.class.class_desc,
                      dept_desc: studentData.dept.dept_desc,
                      cat_desc: studentData.category.cat_desc,
                    },
                    ...prevValues,
                  ]);
                }
              );
            })
            .catch(({ error }) => console.log(error));
          // setStudentList([studentDetails]);
        }
      });
    }

    // eslint-disable-next-line
  }, [
    ParentStudentAssociationData,
    GetParentStudentAssoc,
    token,
    ParentStudentAssociationLoading,
    parentId,
    GetStudentsAcademicDetails,
    studentAcademicData,
  ]);

  useEffect(() => {
    if (token) {
      GetParentDetails();
    }
  }, [token, GetParentDetails, parentId]);
  const { branchLabel, classLabel } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <ParentPreviewTabs />
      <div className="parent-preview">
        <ParentDetails />
        <div className="row g-0 ">
          <div className="col-4">
            <div className="label-grid">
              <Label>Parent Name:</Label>
              <Input
                disabled
                value={
                  data
                    ? data?.node?.first_name +
                      " " +
                      data?.node.middle_name +
                      " " +
                      data?.node.last_name
                    : EMPTY_STRING
                }
              />
            </div>
          </div>
        </div>
        <div className="parent-preview__tableblock">
          <TableContainer className="parent-std-association__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="parent-std-association__table--slno">
                    {TableHeaders.SLNO}
                  </TableCell>
                  <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
                  <TableCell className="parent-std-association__table--desc">
                    {branchLabel}
                  </TableCell>
                  <TableCell className="parent-std-association__table--desc">
                    {classLabel}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentList
                  ?.filter(
                    (x: studentAcademicYearFormDetails) => x.first_name !== ""
                  )
                  ?.map(
                    (
                      response: studentAcademicYearFormDetails,
                      index: number
                    ) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>

                          <TableCell>
                            {response.first_name +
                              " " +
                              response.middle_name +
                              " " +
                              response.last_name}
                          </TableCell>
                          <TableCell>{response.branch_desc}</TableCell>
                          <TableCell>{response.class_desc}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode="edit"
          onClick={() =>
            navigate(
              `/${InstId}/admissions/parents/${parentId}/updateparentAssosiation`
            )
          }
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};
export default ParentAssociationPreview;
