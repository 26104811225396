import { initialState, InitialStateType } from "./context";
import { Actions } from "./types";
export enum payloadTypes {
  SET_STUDENT_ID = "SET_STUDENT_ID",
  SET_USER = "SET_USER",
  SET_INST_ID = "SET_INST_ID",
  SET_CLAIMS = "SET_CLAIMS",
  SET_AUTH_ID = "SET_AUTH_ID",
  SET_ACCOUNT_MAIN_ID = "SET_ACCOUNT_MAIN_ID",
  SET_ALUMINI_STUDENT_ID = "SET_ALUMINI_STUDENT_ID",
  SET_RECEPIT_ID = "SET_RECEPIT_ID",
  SET_RECEIPT_STUDENT_ID = "SET_RECEIPT_STUDENT_ID",
  SET_ACCOUNT_LEDGER_ID = "SET_ACCOUNT_LEDGER_ID",
  SET_REGISTER_TYPE = "SET_REGISTER_TYPE",
  SET_DATE = "SET_DATE",
  SET_FEE_RECEIPT_BOOK_NAME = "SET_FEE_RECEIPT_BOOK_NAME",
  SET_BANK_RECONCILATION_NAME = "SET_BANK_RECONCILATION_NAME",
  SET_PARENT_ID = "SET_PARENT_ID",
  SET_TEST_CONDUCT_ID = "TEST_CONDUCT_ID",
  SET_STAFF_ID = "SET_STAFF_ID",
  SET_CLASSIFICATION_ID = "SET_CLASSIFICATION_ID",
  SET_BOOK_ID = "SET_BOOK_ID",
  RESET = "RESET",
  SET_CHANGE_OF_BRANCH_STUDENT_ID = "SET_CHANGE_OF_BRANCH_STUDENT_ID",
  SET_STD_ENQUIRY_ID = "SET_STD_ENQUIRY_ID",
  SET_EMPLOYEE_ID = "SET_EMPLOYEE_ID",
  SET_SUBJECT_ID = "SET_SUBJECT_ID",
  SET_LEAVE_LEDGER_ID = "SET_LEAVE_LEDGER_ID",
  SET_ACD_DEPT_ID = "SET_ACD_DEPT_ID",
  SET_ACD_BRANCH_ID = "SET_ACD_BRANCH_ID",
  SET_ACD_CLASS_ID = "SET_ACD_CLASS_ID",
  SET_ACD_SEM_ID = "SET_ACD_SEM_ID",
  SET_ACD_SECTION_ID = "SET_ACD_SECTION_ID",
  SET_ALLOTED_ID = "SET_ALLOTED_ID",
  SET_CHANNEL_ID = "SET_CHANNEL_ID",
  SET_ENTRY_ID = "SET_ENTRY_ID",
  SET_ENTRY_LEVEL = "SET_ENTRY_LEVEL",
  SET_SW_ACCT_LDGR_ID = "SET_SW_ACCT_LDGR_ID",
  SET_EMP_LOGIN_ID = "SET_EMP_LOGIN_ID",
  SET_STD_ADM_NO = "SET_STD_ADM_NO",
  SET_CUST_ID = "SET_CUST_ID",
  SET_UNKOWN_ADJUSTMENT_DETAILS_ID = "SET_UNKOWN_ADJUSTMENT_DETAILS_ID",
  SET_UNKOWN_RECEIPT_ID = "SET_UNKOWN_RECEIPT_ID",
  SET_SEARCH_EMPLOYEE_ID = "SET_SEARCH_EMPLOYEE_ID",
  SET_CAMPUS_ID = "SET_CAMPUS_ID",
  SET_MK_CK_ID = "SET_MK_CK_ID",
  SET_MKCK_CREATED_ID = "SET_MKCK_CREATED_ID",
  SET_LEDGER = "SET_LEDGER",
  SET_REGISTER = "SET_REGISTER",
  SET_EMP_INST_ID = "SET_EMP_INST_ID",
  SET_EDUATE_ID = "SET_EDUATE_ID",
  SET_CHANNEL_NAME = "SET_CHANNEL_NAME",
  SET_ACTIVE_FIN_YR = "SET_ACTIVE_FIN_YR",
  SET_SUB_USER_TYPE = "SET_SUB_USER_TYPE",
  SET_SELECTED_SUB = "SET_SELECTED_SUB",
  SET_ACTIVE_ACD_YR = "SET_ACTIVE_ACD_YR",
  SET_STD_ID_FOR_CHALLAN = "SET_STD_ID_FOR_CHALLAN",
  SET_ENQ_STD_ID_FOR_CHALLAN = "SET_ENQ_STD_ID_FOR_CHALLAN",
  SET_PASSOUT_STD_ID_FOR_CHALLAN = "SET_PASSOUT_STD_ID_FOR_CHALLAN",
  SET_INST_ID_FOR_CAMPUS = "SET_INST_ID_FOR_CAMPUS",
  SET_TEMPLATE_ID = "SET_TEMPLATE_ID",
  SET_ACD_SUB_GROUP_ID = "SET_ACD_SUB_GROUP_ID",
  SET_ACD_CAT_ID = "SET_ACD_CAT_ID",
  SET_ACD_COMBO_ID = "SET_ACD_COMBO_ID",
  USER_RIGHTS_EMP_ID = "USER_RIGHTS_EMP_ID",
  SET_DEPOSIT_ID = "SET_DEPOSIT_ID",
  SET_FORM_ID = "SET_FORM_ID",
  SET_TEACHER_ID = "SET_TEACHER_ID",
  SET_FIN_ID = "SET_FIN_ID",
  SET_AGENT_ID = "SET_AGENT_ID",
  SET_SIBLING_ID = "SET_SIBLING_ID",
}
export const reducer = (state: InitialStateType, action: Actions) => {
  switch (action.type) {
    case payloadTypes.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case payloadTypes.SET_INST_ID:
      return { ...state, InstId: action.payload.InstId };

    case payloadTypes.SET_STUDENT_ID:
      return {
        ...state,
        studentId: action.payload.studentId,
      };
    case payloadTypes.SET_FIN_ID:
      return {
        ...state,
        finId: action.payload.finId,
      };

    case payloadTypes.SET_CLAIMS:
      return { ...state, claims: action.payload.claims };
    // case payloadTypes.SET_AUTH_ID:
    //   return { ...state, authId: action.payload.authId };
    case payloadTypes.SET_ACCOUNT_MAIN_ID:
      return { ...state, account_main_id: action.payload.account_main_id };
    case payloadTypes.SET_ALUMINI_STUDENT_ID:
      return { ...state, aluminiStudentId: action.payload.aluminiStudentId };
    case payloadTypes.SET_RECEPIT_ID:
      return { ...state, receiptId: action.payload.receiptId };
    case payloadTypes.SET_RECEIPT_STUDENT_ID:
      return { ...state, receiptStudentId: action.payload.receiptStudentId };
    case payloadTypes.SET_ACCOUNT_LEDGER_ID:
      return { ...state, accountLedgerId: action.payload.accountLedgerId };
    case payloadTypes.SET_REGISTER_TYPE:
      return { ...state, registerType: action.payload.registerType };
    case payloadTypes.SET_DATE:
      return { ...state, date: action.payload.date };

    case payloadTypes.SET_FEE_RECEIPT_BOOK_NAME:
      return {
        ...state,
        receiptBookName: action.payload.receiptBookName,
      };
    case payloadTypes.SET_BANK_RECONCILATION_NAME:
      return {
        ...state,
        bankReconcilationName: action.payload.bankReconcilationName,
      };

    case payloadTypes.SET_PARENT_ID:
      return {
        ...state,
        parentId: action.payload.parentId,
      };
    case payloadTypes.SET_TEST_CONDUCT_ID:
      return {
        ...state,
        testConductId: action.payload.testConductId,
      };
    case payloadTypes.SET_CLASSIFICATION_ID:
      return {
        ...state,
        classificationId: action.payload.classificationId,
      };
    case payloadTypes.SET_BOOK_ID:
      return {
        ...state,
        bookId: action.payload.bookId,
      };
    case payloadTypes.RESET:
      return {
        ...initialState,
        claims: state.claims,
        user: state.user,
        ActiveFinYr: state.ActiveFinYr,
        ActiveAcdYr: state.ActiveAcdYr,
        // authId: state.authId,
      };
    // case "PAYROLL_REPORT":
    //   return {
    //     ...state,
    //     payRollReportName: action.payRollReportName,
    //   };
    case payloadTypes.SET_CHANGE_OF_BRANCH_STUDENT_ID:
      return {
        ...state,
        changeOfBranchStudentId: action.payload.changeOfBranchStudentId,
      };
    case payloadTypes.SET_STD_ENQUIRY_ID:
      return {
        ...state,
        studentEnquiryId: action.payload.studentEnquiryId,
      };
    case payloadTypes.SET_EMPLOYEE_ID:
      return {
        ...state,
        employeeId: action.payload.employeeId,
      };
    case payloadTypes.SET_SUBJECT_ID:
      return {
        ...state,
        subjectId: action.payload.subjectId,
      };
    case payloadTypes.SET_LEAVE_LEDGER_ID:
      return {
        ...state,
        leaveLedgerId: action.payload.leaveLedgerId,
      };
    case payloadTypes.SET_ACD_DEPT_ID:
      return {
        ...state,
        acdDeptId: action.payload.acdDeptId,
      };
    case payloadTypes.SET_ACD_BRANCH_ID:
      return {
        ...state,
        acdBranchId: action.payload.acdBranchId,
      };
    case payloadTypes.SET_ACD_CLASS_ID:
      return {
        ...state,
        acdClassId: action.payload.acdClassId,
      };
    case payloadTypes.SET_ACD_SEM_ID:
      return {
        ...state,
        acdSemesterId: action.payload.acdSemesterId,
      };
    case payloadTypes.SET_ACD_SECTION_ID:
      return {
        ...state,
        acdSectionId: action.payload.acdSectionId,
      };
    case payloadTypes.SET_ALLOTED_ID:
      return {
        ...state,
        allotedId: action.payload.allotedId,
      };
    case payloadTypes.SET_CHANNEL_ID:
      return {
        ...state,
        channelId: action.payload.channelId,
      };
    case payloadTypes.SET_ENTRY_ID:
      return {
        ...state,
        entryId: action.payload.entryId,
      };
    case payloadTypes.SET_EMP_LOGIN_ID:
      return {
        ...state,
        empLoginId: action.payload.empLoginId,
      };
    case payloadTypes.SET_STD_ADM_NO:
      return {
        ...state,
        stdAdmNo: action.payload.stdAdmNo,
      };
    case payloadTypes.SET_CUST_ID:
      return {
        ...state,
        custId: action.payload.custId,
      };
    case payloadTypes.SET_UNKOWN_RECEIPT_ID:
      return {
        ...state,
        unknownReceiptId: action.payload.unknownReceiptId,
      };
    case payloadTypes.SET_UNKOWN_ADJUSTMENT_DETAILS_ID:
      return {
        ...state,
        unkownVoucherAdjustmentDetailsId:
          action.payload.unkownVoucherAdjustmentDetailsId,
      };
    case payloadTypes.SET_SEARCH_EMPLOYEE_ID:
      return {
        ...state,
        searchEmployeeId: action.payload.searchEmployeeId,
      };
    case payloadTypes.SET_CAMPUS_ID:
      return {
        ...state,
        campusId: action.payload.campusId,
      };
    case payloadTypes.SET_MK_CK_ID:
      return {
        ...state,
        mkckId: action.payload.mkckId,
      };
    case payloadTypes.SET_MKCK_CREATED_ID:
      return {
        ...state,
        createdMkckId: action.payload.createdMkckId,
      };
    case payloadTypes.SET_LEDGER:
      return {
        ...state,
        ledger: action.payload.ledger,
      };

    case payloadTypes.SET_REGISTER:
      return {
        ...state,
        register: action.payload.register,
      };
    case payloadTypes.SET_EDUATE_ID:
      return {
        ...state,
        eduateId: action.payload.eduateId,
      };
    case payloadTypes.SET_CHANNEL_NAME:
      return {
        ...state,
        channelName: action.payload.channelName,
      };

    case payloadTypes.SET_ACTIVE_FIN_YR:
      return {
        ...state,
        ActiveFinYr: action.payload.ActiveFinYr,
      };
    case payloadTypes.SET_ACTIVE_ACD_YR:
      return {
        ...state,
        ActiveAcdYr: action.payload.ActiveAcdYr,
      };
    case payloadTypes.SET_STD_ID_FOR_CHALLAN:
      return {
        ...state,
        stdIdForChallan: action.payload.stdIdForChallan,
      };
    case payloadTypes.SET_ENQ_STD_ID_FOR_CHALLAN:
      return {
        ...state,
        enqStdIdForChallan: action.payload.enqStdIdForChallan,
      };
    case payloadTypes.SET_PASSOUT_STD_ID_FOR_CHALLAN:
      return {
        ...state,
        passOutStdIdForChallan: action.payload.passOutStdIdForChallan,
      };
    case payloadTypes.SET_INST_ID_FOR_CAMPUS:
      return {
        ...state,
        instIdForCampus: action.payload.instIdForCampus,
      };
    case payloadTypes.SET_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload.templateId,
      };

    case payloadTypes.SET_ACD_SUB_GROUP_ID:
      return {
        ...state,
        acdGrpId: action.payload.acdGrpId,
      };
    case payloadTypes.SET_ACD_CAT_ID:
      return {
        ...state,
        acdCatId: action.payload.acdCatId,
      };
    case payloadTypes.SET_ACD_COMBO_ID:
      return {
        ...state,
        acdCombinationId: action.payload.acdCombinationId,
      };
    case payloadTypes.USER_RIGHTS_EMP_ID:
      return {
        ...state,
        userRightsEmpId: action.payload.userRightsEmpId,
      };
    case payloadTypes.SET_DEPOSIT_ID:
      return {
        ...state,
        depositId: action.payload.depositId,
      };
    case payloadTypes.SET_FORM_ID:
      return {
        ...state,
        formId: action.payload.formId,
      };

    case payloadTypes.SET_TEACHER_ID:
      return {
        ...state,
        teacherId: action.payload.teacherId,
      };
    case payloadTypes.SET_AGENT_ID:
      return {
        ...state,
        agentId: action.payload.agentId,
      };
    case payloadTypes.SET_SIBLING_ID:
      return {
        ...state,
        siblingId: action.payload.siblingId,
      };
    default:
      return state;
  }
};
