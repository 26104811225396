import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaders } from "../../../../../utils/Enum.types";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";

import { responseType } from "../../../../../utils/Form.types";

import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import { Button } from "../../../../../stories/Button/Button";

import useTestDetails from "../../../hooks/useTestDetails";
import { toStandardDate } from "../../../../../utils/UtilFunctions";
import { TableHeaderProps } from "../../../../../utils/types";
import useAcademicTableJson from "../../../json/useAcademicTable";
interface Props {
  testConductId: responseType | null;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewSubjects = ({ setModal }: Props) => {
  const { testConductDetails } = useTestDetails();
  const { Test_ViewSubjects } = useAcademicTableJson();

  const dynamicHeaders: TableHeaderProps[] =
    Test_ViewSubjects.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    // {
    //   field: "id",
    //   headerName: TableHeaders.SLNO,
    //   headerAlign: HEADER_TEXT_ALIGN,
    //   cellClassName: "td-sl-no",
    //   align: SLNO_TEXT_ALIGN,
    // },
    // {
    //   field: "sub_code",
    //   headerName: TableHeaders.SUBJECT_CODE,
    //   headerAlign: HEADER_TEXT_ALIGN,
    //   cellClassName: "td-sub-code",
    // },
    // {
    //   field: "sub_name",
    //   headerName: TableHeaders.SUBJECT_DESCRIPTION,
    //   headerAlign: HEADER_TEXT_ALIGN,
    //   cellClassName: "td-sub-type",
    //   flex: 1,
    //   hideable: false,
    // },
    // {
    //   field: "test_date",
    //   headerName: TableHeaders.DATE,
    //   headerAlign: HEADER_TEXT_ALIGN,
    //   cellClassName: "td-date",
    // },
  ];

  const rows: GridValidRowModel[] =
    (testConductDetails.data?.node.class_subject_details || []).map(
      (response, index) => {
        return {
          id: index + 1,
          sub_code: response.subject_master_details.subj_code,
          sub_name: response.subject_master_details.subj_desc,
          test_date: toStandardDate(response.test_date),
        };
      }
    ) || [];

  return (
    <div className="acd-reports__view-sub">
      <div className="acd-reports__view-sub--title">
        <Title>
          Test/Exam Dates(
          {testConductDetails.data?.node.test_name_details.test_name})
        </Title>
        <img src={Close} alt="/" onClick={() => setModal(false)} />
      </div>
      <div className={`acd-reports__view-sub--tableblock `}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          hideFooter
        />
      </div>
      <Button mode="cancel" onClick={() => setModal(false)} />
    </div>
  );
};

export default ViewSubjects;
