import { ApolloError } from "@apollo/client";
import dayjs from "dayjs";
import { DropResult } from "react-beautiful-dnd";
import { HolidayDate } from "../Modules/Academics/Reports/Attendance/ByStudentId";
import { GroupLedgerRPTypes } from "../Modules/Accounts/common/QueryTypes";
import { GetAcctVoucherMasterByDatesData } from "../Modules/Accounts/hooks/useAcctVoucherDetailsByDates";
import { YesNoOptionsType } from "../Modules/Enquiry/StudentEnquiry";

import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  BUTTON,
  DEBOUNCE_TIME_INTERVAL,
  EMPTY_STRING,
  Friday,
  imageFormats,
  INPUT,
  Monday,
  Saturday,
  SELECT,
  Sunday,
  TEXTAREA,
  Thursday,
  TODAY_DATE,
  Tuesday,
  Wednesday,
} from "./constants";
import {
  DebitOrCredit,
  Operation,
  ReceiptTypes,
  TagNames,
  TimeComparison,
} from "./Enum.types";
import { msgType, optionsType, responseType } from "./Form.types";
import { Actions } from "../context/types";
import { payloadTypes } from "../context/reducer";
import { initialState, InitialStateType } from "../context/context";
import { INCREMENT_DECREMENT } from "../Modules/Enquiry/Dashboard/Index";
import Pdf from "../images/DownloadPdf.svg";
import Doc from "../images/WordDocument.svg";
import Xls from "../images/Excel.svg";
import Image from "../images/DownloadImage.svg";
import Audio from "../images/DownloadAudio.svg";
import { GetAcctChallansData } from "../Modules/Accounts/StudentDetails/Challan/Index";

export const handleFormEvent = (event: any) => {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    const elementNotDisabled = Object.entries(form.elements).filter(
      (x: any) =>
        x[1].disabled !== true &&
        (x[1].tagName === INPUT ||
          x[1].tagName === SELECT ||
          x[1].tagName === TEXTAREA ||
          x[1].tagName === BUTTON)
    );
    if (form.elements[index + 1].disabled) {
      const nextNotDisabledElement = elementNotDisabled.find(
        (x: any) => x[0] > index + 1
      );
      form.elements[
        nextNotDisabledElement ? nextNotDisabledElement[0] : 0
      ].focus();
    }
    if (form.elements[index + 1].name === "search") {
      form.elements[index + 2].focus();
    } else form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const convertIsoToIst = (isoDate: string) => {
  const date = new Date(isoDate);

  // Get the UTC hours and minutes
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();

  // Calculate total UTC minutes
  const totalUTCMinutes = utcHours * 60 + utcMinutes;

  // Indian Standard Time offset in minutes (UTC+5:30)
  const ISTOffset = 5 * 60 + 30;

  // Calculate total minutes for IST
  const totalISTMinutes = totalUTCMinutes + ISTOffset;

  // Calculate hours and minutes for IST
  const ISTHours = Math.floor(totalISTMinutes / 60);
  const ISTMinutes = totalISTMinutes % 60;

  // Adjust the date to IST
  date.setUTCHours(ISTHours);
  date.setUTCMinutes(ISTMinutes);

  // Format the date in IST
  const ISTDate = date.toISOString().replace("Z", "");
  return {
    istDateTime: ISTDate,
    istDate: ISTDate.split("T")[0],
    istTime: ISTDate.split("T")[1].split(".")[0],
  };
};
export const handleSelectEvent = (event: any) => {
  const form = event.target.form;
  const index = Array?.prototype?.indexOf?.call(form, event.target);
  form.elements[index].focus();
  if (form.elements[index].value && form.elements[index].value !== "Select")
    handleFormEvent(event);
};
export const handleMUISelectEvent = (event: any) => {
  event.preventDefault();
  const form = event.target.form;

  const notDisabledElements: any[] = Object.values(form.elements).filter(
    (x: any) =>
      x.disabled !== true &&
      (x.tagName === INPUT ||
        x.tagName === SELECT ||
        x.tagName === TEXTAREA ||
        (x.tagName === BUTTON && !x.classList.contains("MuiButtonBase-root")))
  );
  const customIndex = notDisabledElements.indexOf(
    notDisabledElements.find((elem) => elem === event.target)
  );

  notDisabledElements[customIndex + 1].focus();
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
export const ToTitleCase = (inputString: string) => {
  return inputString.trim().replace(/^\w/, (c) => c.toUpperCase());
};

export const reOrderProcess = (result: DropResult, temp: any) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.index === destination.index) return;

  const [movedItem] = temp.splice(source.index, 1);
  temp.splice(destination.index, 0, movedItem);
};

export const matchYoutubeUrl = (url: string) => {
  var youtubeRegex =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(youtubeRegex)) {
    return true;
  }
  return false;
};

export const getApiErrorMessage = (
  creationError: ApolloError,
  updationError?: ApolloError,
  deletionError?: ApolloError,
  generalError?: ApolloError,
  ById?: ApolloError
): string => {
  if (creationError) {
    return creationError.message;
  }
  if (updationError) {
    return updationError.message;
  }
  if (deletionError) {
    return deletionError.message;
  }
  if (generalError) {
    return generalError.message;
  }
  if (ById) {
    return ById.message;
  }
  return EMPTY_STRING;
};

export const toStandardCase = (string: string) => {
  return string?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );
};
export const removeUnderscore = (string: string) => {
  return string?.replace(/_/g, " ");
};
export const removeMoreSpace = (string: string) => {
  return string.replace(/ +/g, " ").trim();
};

export const PrecisionFunction = (data: number): number => {
  return Number(data.toFixed(2));
};

export const debounce = (cb: () => void, delay = DEBOUNCE_TIME_INTERVAL) => {
  let timeout: NodeJS.Timeout;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb();
    }, delay);
  };
};

export const toFixed = (value: number, decimals: number): number => {
  return Number.parseFloat(value.toFixed(decimals));
};

export const formatter = new Intl.NumberFormat("en-IN");

export const formatterDecimal = new Intl.NumberFormat("en-IN", {
  maximumFractionDigits: 2,
});
const { format } = formatter;

export const toStandardDate = (date: string) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return "Invalid Date";
  }
  return parsedDate.format("DD-MM-YYYY");
};
export const toInputStandardDate = (day: string) => {
  return day ? dayjs(day).format("YYYY-MM-DD") : EMPTY_STRING;
};

export const toIsoDate = (date: string) => dayjs(date).format();

export const GetnumberOfDaysInMonth = (date: string) =>
  dayjs(date).daysInMonth();
export const excelDateToJSDate = (serial: number) => {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400; // 86400 seconds per day
  const dateInfo = new Date(utcValue * 1000);
  const fractionalDay = serial - Math.floor(serial) + 0.0000001;

  let totalSeconds = Math.floor(86400 * fractionalDay);
  const seconds = totalSeconds % 60;
  totalSeconds -= seconds;

  const hours = Math.floor(totalSeconds / (60 * 60));
  const minutes = Math.floor(totalSeconds / 60) % 60;

  return new Date(
    dateInfo.getFullYear(),
    dateInfo.getMonth(),
    dateInfo.getDate(),
    hours,
    minutes,
    seconds
  );
};
export const formatExcelDate = (serial: number) => {
  const jsDate = excelDateToJSDate(serial);
  const isoDate = dayjs(jsDate).format();
  const dateMonthYear = dayjs(jsDate).format("DD MMMM YYYY");
  return { isoDate, dateMonthYear };
};

export const convertToDateObject_MMDDYYYYFormat = (dateString: string) => {
  const delimiter = dateString.includes("/") ? "/" : "-";
  const [dayStr, month, year] = dateString.split(delimiter).map(Number);
  const day = dayStr < 10 ? `0${dayStr}` : dayStr;
  return new Date(`${month}/${day}/${year}`).toString();
};

export const GetLastDateInMonth = (date: string) => {
  var month = new Date(date).getMonth() + 1;
  var year = new Date(date).getFullYear();
  return new Date(year, month, 0).toString();
};

export const sundaysInMonth = (month: number, year: number) => {
  var days = new Date(year, month, 0).getDate();

  var sundays = [8 - new Date(month + "/01/" + year).getDay()];
  if (new Date(month + "/01/" + year).getDay() === 0) {
    sundays.push(new Date(month + "/01/" + year).getDate());
  }

  for (var i = sundays[0] + 7; i <= days; i += 7) {
    sundays.push(i);
  }
  return sundays.sort();
};

export interface DateDetails {
  date: string | Date;
  dateFormat: Date;
  day: string;
  day_num: number;
}
export const getAllDaysInMonth = (month: number, year: number) => {
  const date = new Date(year, month, 1);
  const dates: DateDetails[] = [];
  while (date.getMonth() === month) {
    dates.push({
      date: dayjs(date).format("MMM D"),
      dateFormat: new Date(date),
      day: date.toLocaleString("en-US", { weekday: "short" }),
      day_num: Number(dayjs(date).format("D")),
    });
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

export const handleClear = () => window.location.reload();

export const MonthName = (date: string) =>
  new Date(date).toLocaleString("en-us", { month: "long", year: "numeric" });

export const DateRange = (
  date: string
): { firstDay: string; lastDay: string } | undefined => {
  if (date && dayjs(date).isValid()) {
    var firstDay = toIsoDate(dayjs(date).startOf("month").toString());
    var lastDay = toIsoDate(dayjs(date).endOf("month").toString());
    return { firstDay, lastDay };
  }
};

export const getDateRangeByMonth = (year: number, month: number) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  return { startDate, endDate };
};
export const NameOfTheDay = (date: string) => {
  const data = new Date(date);
  return new Date(data).toLocaleDateString("en-IN", {
    weekday: "long",
  });
};

// full screen
export const toggleFullSceen = (): void => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};
function isInteger(value: string) {
  return /^\d+$/.test(value);
}
export const NosInCharactersIncrement = (registerNumber: string) => {
  if (!registerNumber) return "";
  if (registerNumber?.length > 0) {
    // Splitting string to an array
    const myArray = registerNumber.split("");
    // Initializing firstIndex and lastIndex for numbers nearest from top of array
    let firstIndex = -1;
    let lastIndex = -1;

    // For loop to find firstIndex and lastIndex of the last found number
    for (let i = myArray.length - 1; i >= 0; i--) {
      if (isInteger(myArray[i]) && lastIndex === -1) {
        lastIndex = i + 1;
      }
      if (lastIndex >= 0 && !isInteger(myArray[i])) {
        firstIndex = i + 1;
        break;
      }
      if (lastIndex > 0 && firstIndex === -1 && i === 0) {
        firstIndex = 0;
      }
    }

    // Get the number part as a string
    const numberString = myArray.slice(firstIndex, lastIndex).join("");

    // Increment the number
    const incrementedNumber = (parseInt(numberString) + 1).toString();

    // Compute the zero padding to add before the number
    const zeroPadding = "0".repeat(
      Math.max(0, numberString.length - incrementedNumber.length)
    );

    // Create the new register number with the incremented number
    const newRegisterNumber =
      myArray.slice(0, firstIndex).join("") +
      zeroPadding +
      incrementedNumber +
      myArray.slice(lastIndex).join("");

    return newRegisterNumber;
  } else {
    return "";
  }
};

export const filterByReference = (arr1: any[], arr2: any[]) => {
  // array 1 length should be always greater than 2nd array
  let res = [];
  res = arr1.filter((el) => {
    return !arr2.find((element) => {
      return element.id === el.id;
    });
  });
  return res;
};

//pagination text
interface ArrayDataType {
  from: Number;
  to: Number;
  count: Number;
}

export const defaultLabelDisplayedRows = ({
  from,
  to,
  count,
}: ArrayDataType) => {
  return `Showing ${from}–${to} of ${
    count !== -1 ? count : `more than ${to} `
  } Entries`;
};

export const getDueDate = (date: string, noOfDays: number) => {
  return new Date(new Date(date).getTime() + noOfDays * 86400000);
};
export const GetNoOfDaysDelayed = (date: Date) => {
  const toDay = new Date(TODAY_DATE);
  var ONE_DAY = 1000 * 60 * 60 * 24;

  var date1_ms = date.getTime();
  var date2_ms = toDay.getTime();

  var difference_ms = date2_ms - date1_ms;

  return difference_ms / ONE_DAY < 0 ? 0 : Math.round(difference_ms / ONE_DAY);
};

export const filterVouchersByType = (
  voucherType: ReceiptTypes,
  VoucherDetails: GetAcctVoucherMasterByDatesData
) => {
  if (voucherType && VoucherDetails) {
    switch (voucherType) {
      case ReceiptTypes.STUDENT_RECEIPT:
        const filteredStudentsReceipts =
          VoucherDetails?.GetAcctVouchers.edges.filter(
            (edge) =>
              edge.node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
              edge.node.v_std_receipt &&
              !(edge.node.v_std_enquiry || edge.node.v_std_passout_receipt)
          );
        return filteredStudentsReceipts;
      case ReceiptTypes.PYMT:
        const filteredPaymentVoucher =
          VoucherDetails?.GetAcctVouchers.edges.filter(
            (edge) => edge.node.v_type === ReceiptTypes.PYMT
          );
        return filteredPaymentVoucher;
      default:
        return [];
    }
  }
};
export const filterVouchersByTypeForChallan = (
  voucherType: ReceiptTypes,
  VoucherDetails: GetAcctChallansData
) => {
  if (voucherType && VoucherDetails) {
    switch (voucherType) {
      case ReceiptTypes.STUDENT_RECEIPT:
        const filteredStudentsReceipts =
          VoucherDetails?.GetAcctChallans.edges.filter(
            (edge) =>
              edge.node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
              edge.node.v_std_receipt &&
              !(edge.node.v_std_enquiry || edge.node.v_std_passout_receipt)
          );
        return filteredStudentsReceipts;
      case ReceiptTypes.PYMT:
        const filteredPaymentVoucher =
          VoucherDetails?.GetAcctChallans.edges.filter(
            (edge) => edge.node.v_type === ReceiptTypes.PYMT
          );
        return filteredPaymentVoucher;
      default:
        return [];
    }
  }
};
export interface days {
  monthName: string;
  dates: string;
  weekDay: string;
  daythWeek: number;
  startDate: number;
}

export const handleDbOrCr = (item: GroupLedgerRPTypes, amt: number) => {
  switch (item) {
    case GroupLedgerRPTypes.BankAndCash:
    case GroupLedgerRPTypes.FixedAsset:
    case GroupLedgerRPTypes.CurrentAsset:
      return amt > 0 ? DebitOrCredit.DEBIT : DebitOrCredit.CREDIT;
    case GroupLedgerRPTypes.Liability:
    case GroupLedgerRPTypes.Expense:
    case GroupLedgerRPTypes.Income:
      return amt > 0 ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT;

    default:
      return EMPTY_STRING;
  }
};
//Util Function for Excel
export const getHeaderRowStyle = () => {
  const headerRowStyle = [
    {
      font: { bold: true, size: 22, name: "Century Gothic" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "e5e7eb" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
    },
    {
      font: {
        bold: true,
        size: 13,
        name: "Arial",
        color: { argb: "000000" },
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD9D9D9" },
      },
      alignment: { horizontal: "center" },
    },
  ];
  return headerRowStyle;
};
export const getDatesByMonth = (acd_st_date: string, acd_end_date: string) => {
  interface days {
    monthName: string;
    dates: string;
    weekDay: string;
    occurrence: number;
  }
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [startYear, startMonth] = acd_st_date.split("-").map(Number);
  const [endYear, endMonth] = acd_end_date.split("-").map(Number);
  const datesByMonth: Record<string, days[]> = {};

  for (let year = startYear; year <= endYear; year++) {
    let startMonthIndex = 0;
    let endMonthIndex = 11;
    if (year === startYear) {
      startMonthIndex = startMonth - 1;
    }
    if (year === endYear) {
      endMonthIndex = endMonth - 1;
    }
    for (let month = startMonthIndex; month <= endMonthIndex; month++) {
      const monthName = new Date(year, month).toLocaleDateString("en-us", {
        month: "long",
        timeZone,
      });
      const dates: days[] = [];
      const occurrenceCounter: Record<string, number> = {};
      for (let day = 1; day <= 31; day++) {
        const date = new Date(Date.UTC(year, month, day));
        if (
          date.getMonth() === month &&
          date >= new Date(acd_st_date) &&
          date <= new Date(acd_end_date)
        ) {
          const weekDay = date.toLocaleDateString("en-us", {
            weekday: "long",
            timeZone,
          });
          if (!occurrenceCounter[weekDay]) {
            occurrenceCounter[weekDay] = 1;
          }
          const occurrence = occurrenceCounter[weekDay]++;
          dates.push({
            monthName,
            dates: date.toISOString().slice(0, 10),
            weekDay,
            occurrence,
          });
        }
      }
      if (!datesByMonth[monthName]) {
        datesByMonth[monthName] = [];
      }
      datesByMonth[monthName].push(...dates);
    }
  }

  const datesByMonthAndWeekday: Record<string, Record<string, days[]>> = {};
  for (const month in datesByMonth) {
    const dates = datesByMonth[month];
    for (const date of dates) {
      const weekDay = date.weekDay;
      if (!datesByMonthAndWeekday[month]) {
        datesByMonthAndWeekday[month] = {};
      }
      if (!datesByMonthAndWeekday[month][weekDay]) {
        datesByMonthAndWeekday[month][weekDay] = [];
      }
      datesByMonthAndWeekday[month][weekDay].push(date);
    }
  }

  return datesByMonthAndWeekday;
};

export const getOrdinalSuffix = (num: number): string => {
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return "th";
  } else if (lastDigit === 1) {
    return "st";
  } else if (lastDigit === 2) {
    return "nd";
  } else if (lastDigit === 3) {
    return "rd";
  } else {
    return "th";
  }
};

export const generateDatesBetween = (start: Date, end: Date): Date[] => {
  const dates = [];
  const current = new Date(start);

  while (current <= end) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  return dates;
};

export const getTileClassName = (date: Date, holidayDates: HolidayDate[]) => {
  const holidayDate = holidayDates.find((hd) => {
    return (
      hd.date.getDate() === date.getDate() &&
      hd.date.getMonth() === date.getMonth() &&
      hd.date.getFullYear() === date.getFullYear()
    );
  });

  if (holidayDate) {
    if (holidayDate.duration === "full day") {
      return "full-day";
    } else if (holidayDate.duration === "half day") {
      return "half-day";
    }
  }

  return EMPTY_STRING;
};
//Shiva: Whenever an value is selected in responseTypes options the autocomplete is showing warning the value is invalid
export const isOptionEqualToValue = (
  optionData: responseType | optionsType | YesNoOptionsType,
  valueData: responseType | optionsType | YesNoOptionsType | null
) => {
  if (valueData) {
    return optionData.value === valueData.value;
  } else {
    return false;
  }
};

export const roundOff = (num: number) => {
  return Math.round(num);
};
export const RefsByTagName = (
  ref: React.RefObject<HTMLSelectElement | HTMLInputElement>
) => {
  return ref?.current?.getElementsByTagName(
    TagNames.INPUT
  )[0] as HTMLInputElement;
};
export const RefsByClassName = (
  ref: React.RefObject<HTMLSelectElement | HTMLInputElement>
) => {
  return ref?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
};

export const findStartAndEndDate = (
  dates: string[]
): {
  startDate: Date;
  endDate: Date;
} => {
  // Convert date strings to Date objects
  const dateObjects = dates.map((dateString) => {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  });

  // Find earliest start date and latest end date
  const startTimestamp = Math.min(...dateObjects.map((date) => date.getTime()));
  const endTimestamp = Math.max(...dateObjects.map((date) => date.getTime()));

  return {
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
  };
};
export const debounce2 = <F extends (...args: any[]) => void>(
  func: F,
  wait: number
) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  const debounced = (...args: Parameters<F>) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, wait);
  };
  return debounced as (...args: Parameters<F>) => void;
};
export const getWeekByDayNum = (day: number) => {
  switch (day) {
    case 0:
      return Sunday;
    case 1:
      return Monday;
    case 2:
      return Tuesday;
    case 3:
      return Wednesday;
    case 4:
      return Thursday;
    case 5:
      return Friday;
    case 6:
      return Saturday;
    default:
      break;
  }
};
export const getMonthDateAndDay = (date: string) => {
  const month = new Date(date).getMonth();
  const day = new Date(date).getDate();
  const week = new Date(date).getDay();
  const year = new Date(date).getFullYear();

  let weekString = "";
  switch (week) {
    case 0:
      weekString = "Sunday";
      break;
    case 1:
      weekString = "Monday";
      break;
    case 2:
      weekString = "Tuesday";
      break;
    case 3:
      weekString = "Wednesday";
      break;
    case 4:
      weekString = "Thursday";
      break;
    case 5:
      weekString = "Friday";
      break;
    case 6:
      weekString = "Saturday";
      break;
  }
  return { month, day, week, year, weekString };
};
export const emptyDaysByWeek = (week: string) => {
  let numberOfEmpty = -1;
  let numberOfEmptyToLast = -1;
  switch (week) {
    case Sunday:
      numberOfEmpty = 6;
      numberOfEmptyToLast = 0;
      break;
    case Monday:
      numberOfEmpty = 0;
      numberOfEmptyToLast = 6;
      break;
    case Tuesday:
      numberOfEmpty = 1;
      numberOfEmptyToLast = 5;

      break;
    case Wednesday:
      numberOfEmpty = 2;
      numberOfEmptyToLast = 4;
      break;
    case Thursday:
      numberOfEmpty = 3;
      numberOfEmptyToLast = 3;
      break;
    case Friday:
      numberOfEmpty = 4;
      numberOfEmptyToLast = 2;
      break;
    case Saturday:
      numberOfEmpty = 5;
      numberOfEmptyToLast = 1;
      break;
    default:
      break;
  }
  return { numberOfEmpty, numberOfEmptyToLast };
};
export const clearGlobalStates = (
  dispatch: React.Dispatch<Actions>,
  state: InitialStateType
) => {
  dispatch({
    type: payloadTypes.RESET,
    payload: {
      resetState: {
        ...initialState,
        ActiveFinYr: state.ActiveFinYr,
        ActiveAcdYr: state.ActiveAcdYr,
        claims: state.claims,
        user: state.user,
      },
    },
  });
};
export const handleZeros = (e: string) => {
  switch (e) {
    case "1":
      return "";
    case "2":
      return "0";
    case "3":
      return "00";
    case "4":
      return "000";
    case "5":
      return "0000";
    default:
      return "";
  }
};
export const handleDate = (
  date: Date,
  setDate: React.Dispatch<React.SetStateAction<Date>>,
  acdStartDate: string,
  acdEndDate: string,
  setMessage: React.Dispatch<React.SetStateAction<msgType>>,
  type: INCREMENT_DECREMENT
) => {
  const academicYearEndDate = new Date(acdEndDate);
  const academicYearStartDate = new Date(acdStartDate);
  if (type === INCREMENT_DECREMENT.INCREMENT) {
    const nextDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );

    if (nextDate.getTime() < academicYearEndDate.getTime()) {
      setDate(nextDate);
    } else {
      setMessage({
        message: "Reached the End of Academic Year",
        flag: true,
        operation: Operation.NONE,
      });
    }
  } else {
    const prevDate = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      date.getDate()
    );

    if (academicYearStartDate.getTime() < prevDate.getTime()) {
      setDate(prevDate);
    } else {
      setMessage({
        message: "Reached the Start of Academic Year",
        flag: true,
        operation: Operation.NONE,
      });
    }
  }
};
export const getFileName = (url: string): string => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};
export const timeDifference = (startDate: string, endDate: string) => {
  const date = Math.abs(
    new Date(endDate).getTime() - new Date(startDate).getTime()
  );
  const totalDays = Math.ceil(date / (1000 * 60 * 60 * 24));
  return totalDays;
};

export function getDatesBetween(startDate: string, endDate: string) {
  var dates = [];

  // Parse the start and end dates
  var start = new Date(startDate);
  var end = new Date(endDate);

  // Iterate through each month
  var currentDate = new Date(start);

  while (currentDate <= end) {
    dates.push({
      label: MonthName(currentDate.toString()),
      value: new Date(MonthName(currentDate.toString())),
    });

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
}

export const convertToK = (number: number) => {
  if (number >= 1000 && number <= 99999) {
    const roundedNumber = (number / 1000).toFixed(1);
    return roundedNumber.endsWith(".0")
      ? roundedNumber.slice(0, -2) + "K"
      : roundedNumber + "K";
  } else if (number >= 100000 && number <= 9999999) {
    const roundedNumber = (number / 100000).toFixed(1);
    return roundedNumber.endsWith(".0")
      ? roundedNumber.slice(0, -2) + "L"
      : roundedNumber + "L";
  } else if (number >= 10000000) {
    const roundedNumber = (number / 10000000).toFixed(1);
    return roundedNumber.endsWith(".0")
      ? roundedNumber.slice(0, -2) + "Cr"
      : roundedNumber + "Cr";
  } else {
    return number.toString(); // Ensure non-matching numbers are returned as strings.
  }
};

export const getFileIcon = (filename: string) => {
  const extension = filename.split(".").pop()?.toLowerCase();
  switch (extension) {
    case "pdf":
      return `${Pdf}`;
    case "doc":
    case "docx":
      return `${Doc}`;
    case "xls":
    case "xlsx":
      return `${Xls}`;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return `${Image}`;
    case "mp3":
    case "wav":
      return `${Audio}`;
    default:
      return "";
  }
};
export const handleDownloadFiles = (filename: string, url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const getModifiedScrollHeight = (scrollHeight: number) => {
  const percentage = 75;
  const hundred = 100;
  return percentage * (scrollHeight / hundred);
};

export const getDynamicIntervals = (maxNumber: number) => {
  if (maxNumber < 10) {
    return [0, 2, 4, 6, 8, 10];
  }
  let roundingFactor = 1;

  while (roundingFactor * 10 <= maxNumber) {
    roundingFactor *= 10;
  }

  const roundedMax = Math.ceil(maxNumber / roundingFactor) * roundingFactor;
  const intervalCount = 5;
  const intervalSize = Math.ceil(roundedMax / intervalCount);

  const intervals = Array.from(
    { length: intervalCount + 1 },
    (_, index) => index * intervalSize
  );

  intervals[intervalCount] = roundedMax;

  return intervals;
};
export function compareTimes(time1: string, time2: string): TimeComparison {
  const [hour1, minute1] = time1.split(":").map(Number);
  const [hour2, minute2] = time2.split(":").map(Number);

  if (hour1 === hour2 && minute1 === minute2) {
    return TimeComparison.EQUAL;
  }

  if ((hour1 === 0 && hour2 === 0) || hour1 === hour2) {
    if (minute1 > minute2) {
      return TimeComparison.GREATER;
    } else if (minute1 < minute2) {
      return TimeComparison.LESSER;
    }
  }

  if (hour1 > hour2 || (hour1 === 0 && hour2 === 23)) {
    return TimeComparison.GREATER;
  } else {
    return TimeComparison.LESSER;
  }
}

export const convertMinsToHours = (minutes: number) => {
  const hours = Math.trunc(minutes / 60);
  const rem_minutes = minutes % 60;

  return `${hours ? hours + "hrs" : ""} ${
    rem_minutes ? rem_minutes + " mins" : ""
  }`;
};

export const convertMinsToHoursString = (minutes: number) => {
  const hours = Math.trunc(minutes / 60);
  const rem_minutes = minutes % 60;

  return `${hours.toString().length === 1 ? "0" + hours : hours}:${
    rem_minutes.toString().length === 1 ? "0" + rem_minutes : rem_minutes
  }`;
};

export const convertHoursMin = (duration: string) => {
  const hrsConvertedtoMins = Number(duration.split(":")[0]) * 60;
  const mins = Number(duration.split(":")[1]);
  return hrsConvertedtoMins + mins;
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const showNegativeInBrackets = (amount: number) => {
  if (amount > 0) {
    return format(amount);
  } else {
    return `(${format(amount)})`;
  }
};

export const sixMonthsAgo = (date: string) => {
  const today = new Date(date);
  const sixMonthsAgoDate = new Date(
    today.getFullYear(),
    today.getMonth() - 6,
    today.getDate()
  );
  return sixMonthsAgoDate.toISOString().slice(0, 10); // Return date in "yyyy-mm-dd" format
};

export const PercentageGraphScale = [
  ">=80%",
  "70-79%",
  "60-69%",
  "50-59%",
  "40-49%",
  "<40%",
];

export const checkIfImage = (filename: string) => {
  const fileArray = filename.split(".");
  const fileLength = filename.split(".").length;

  return imageFormats.includes(fileArray[fileLength - 1]);
};

export const toStandardMonth = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", { month: "short", year: "numeric" });
};
