export enum UserType {
  USER = "USER",
  CASHIER = "CASHIER",
  EDUATE = "EDUATE",
  PARENT = "PARENT",
  EMPLOYEE = "EMPLOYEE",
  OWNER = "OWNER",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
  SYS_ADMIN = "SYS_ADMIN",
  ADM_EXECUTIVE = "ADM_EXECUTIVE",
  CLERK = "CLERK",
  ACCOUNTANT = "ACCOUNTANT",
}

export enum Operation {
  UPDATE = "UPDATE",
  CREATE = "CREATE",
  DELETE = "DELETE",
  VIEW = "VIEW",
  NONE = "NONE",
  CLEAR = "CLEAR",
  CLOSE = "CLOSE",
  PRIMARY = "PRIMARY",
}

export enum StudentType {
  ELIGIBLE = "ELIGIBLE",
  NOT_ELIGIBLE = "NOT_ELIGIBLE",
}

export enum SortBy {
  TIME_ZONE = "TIME_ZONE",
  CUST_NAME = "CUST_NAME",
  INST_NAME = "INST_NAME",
  V_DATE = "V_DATE",
  FIRST_NAME = "FIRST_NAME",
  STD_REG_NO = "STD_REG_NO",
  BOOK_ATTRIBUTE = "BOOK_ATTRIBUTE",
  AUTHOR_NAME = "AUTHOR_NAME",
  CATEGORY_DESC = "CATEGORY_DESC",
  DEPT_DESC = "DEPT_DESC",
  LIB_BOOK_CLASSIFICATION_ID = "LIB_BOOK_CLASSIFICATION_ID",
  CLASSIFICATION_DESC = "CLASSIFICATION_DESC",
  KEYWORD = "KEYWORD",
  LANGUAGE = "LANGUAGE",
  MEDIA_DESC = "MEDIA_DESC",
  PUBLISHER_NAME = "PUBLISHER_NAME",
  SUBJECT_DESC = "SUBJECT_DESC",
  VENDOR_NAME = "VENDOR_NAME",
  DONOR_NAME = "DONOR_NAME",
  STD_NAME = "STD_NAME",
  CREATED_AT = "CREATED_AT",
  BOOK_TITLE = "BOOK_TITLE",
  ISSUE_DATE = "ISSUE_DATE",
  LIB_BOOK_RACK_NO = "LIB_BOOK_RACK_NO",
  LIB_BOOK_RACK_DESC = "LIB_BOOK_RACK_DESC",
  PUR_BILL_NO = "PUR_BILL_NO",
  ACCESS_NO = "ACCESS_NO",
  LDGR_DESC = "LDGR_DESC",
  INDEX = "INDEX",
  SAL_LDGR_DESC = "SAL_LDGR_DESC",
  SAL_LDGR_SHORT_DESC = "SAL_LDGR_SHORT_DESC",
  SAL_LDGR_SL = "SAL_LDGR_SL",
  EMP_FIRST_NAME = "EMP_FIRST_NAME",
  DESIGNATION_DESC = "DESIGNATION_DESC",
  SAL_STR_DESC = "SAL_STR_DESC",
  GRADE_DESC = "GRADE_DESC",
  LEAVE_DESC = "LEAVE_DESC",
  JOB_TYPE_DESC = "JOB_TYPE_DESC",
  HOLIDAY_DESC = "HOLIDAY_DESC",
  HOLIDAY_DATE = "HOLIDAY_DATE",
  EMP_SAL_MK_MONTH = "EMP_SAL_MK_MONTH",
  ATT_SAL_MONTH = "ATT_SAL_MONTH",
  GR_LDGR_RCPT_INDEX_POSITION = "GR_LDGR_RCPT_INDEX_POSITION",
  GR_LDGR_DESC = "GR_LDGR_DESC",
  GR_LDGR_PYMT_INDEX_POSITION = "GR_LDGR_PYMT_INDEX_POSITION",
  STUDENT_ID = "STUDENT_ID",
  STD_NE_DATE = "STD_NE_DATE",
  SUBJ_CODE = "SUBJ_CODE",
  ROUTE_DESC = "ROUTE_DESC",
  ROUTE_STAGE_DESC = "ROUTE_STAGE_DESC",
  ACCT_LDGR_ID = "ACCT_LDGR_ID",
  TOTAL_DAYS = "TOTAL_DAYS",
  STD_ADM_NO = "STD_ADM_NO",
  SECTION_ID = "SECTION_ID",
  TEST_START_DATE = "TEST_START_DATE",
  STD_ROLL_NO = "STD_ROLL_NO",
  VOUCHER_NO = "VOUCHER_NO",
  ENQ_DATE = "ENQ_DATE",
  SESSION_DESC = "SESSION_DESC",
  SUBJ_SL = "SUBJ_SL",
  DEMO_ENQ_NAME = "DEMO_ENQ_NAME",
  CONCESSION_VOUCH_DATE = "CONCESSION_VOUCH_DATE",
  RANK_ALLOTTED_LEVEL = "RANK_ALLOTTED_LEVEL",
  DEPT_NAME = "DEPT_NAME",
  GROUP_NAME = "GROUP_NAME",
  SUBJ_CAT_NAME = "SUBJ_CAT_NAME",
  SUBJ_COMBO_NAME = "SUBJ_COMBO_NAME",
  ACTIVITY_NAME = "ACTIVITY_NAME",
  STD_DOB = "STD_DOB",
  MSG_TPL_DESC = "MSG_TPL_DESC",
  AGENT_NAME = "AGENT_NAME",
}

export enum SalaryStructreEarnType {
  FLAT_RATE = "FLR",
  USER_DEFINED_VALUE = "UDV",
  USER_DEFINED_MONTHLY = "UDM",
}
export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ParentType {
  FATHER = "Father",
  MOTHER = "Mother",
  GUARDIAN = "GUARDIAN",
}

export enum StudentStatus {
  CUR = "CUR",
  TC = "TC",
  DET = "DET",
  NE = "NE",
  NXT = "NXT",
  SOA = "SOA",
  CXLD = "CXLD",
  PASS = "PASS",
}

export enum YesNo {
  YES = "Yes",
  NO = "No",
}

export enum EduateUserType {
  EDUATE_USER = "EDUATE_USER",
  EDUATE_MANAGER = "EDUATE_MANAGER",
  EDUATE_ADMIN = "EDUATE_ADMIN",
  EDUATE_SYS_ADMIN = "EDUATE_SYS_ADMIN",
}

export enum InstitutionConfigurationTypes {
  INSTITUTION_USE_DEPARTMENT = "INSTITUTION_USE_DEPARTMENT",
  INSTITUTION_USE_BRANCH = "INSTITUTION_USE_BRANCH",
  INSTITUTION_USE_CLASS = "INSTITUTION_USE_CLASS",
  INSTITUTION_USE_SEMESTER = "INSTITUTION_USE_SEMESTER",
  INSTITUTION_USE_SECTION = "INSTITUTION_USE_SECTION",
  INSTITUTION_USE_CATEGORY = "INSTITUTION_USE_CATEGORY",
  INSTITUTION_USE_ADM_NUMBER = "STD_ADMISSION_NUMBER",
  SUBJECT_ALLOCATION_LEVEL = "SUBJECT_ALLOCATION_LEVEL",
  ENABLE_STUDENT_LOGIN = "ENABLE_STUDENT_LOGIN",
  ENABLE_PARENT_LOGIN = "ENABLE_PARENT_LOGIN",
  ENABLE_EMPLOYEE_LOGIN = "ENABLE_EMPLOYEE_LOGIN",
  STD_ADM_NUMBER_EDIT = "STD_ADM_NUMBER_EDIT",
  USE_EDUATE_LOGO_IN_FILES = "USE_EDUATE_LOGO_IN_FILES",
  DEFAULT_PASSWORD = "DEFAULT_PASSWORD",
  ENABLE_MIDDLE_NAME = "ENABLE_MIDDLE_NAME",
  ENABLE_LAST_NAME = "ENABLE_LAST_NAME",
  ATTACHMENT_FILE_SIZE = "ATTACHMENT_FILE_SIZE",
  ATTACHMENT_MEDIA_SIZE = "ATTACHMENT_MEDIA_SIZE",
  INST_BANNER_1 = "INST_BANNER_1",
  INST_BANNER_2 = "INST_BANNER_2",
  STD_REG_NUMBER_AUTO_INCREMENT = "STD_REG_NUMBER_AUTO_INCREMENT",
  EDIT_STD_BRANCH_DETAILS = "EDIT_STD_BRANCH_DETAILS",
  INACTIVITY_TIMEOUT = "INACTIVITY_TIMEOUT",
  CREATE_ANONYMOUS_STUDENT = "CREATE_ANONYMOUS_STUDENT",
  ENABLE_SCHOLARSHIP = "ENABLE_SCHOLARSHIP",
  PAYMENT_GATEWAY = "PAYMENT_GATEWAY",
  ENABLE_PER_SESSION_ATTENDANCE = "ENABLE_PER_SESSION_ATTENDANCE",
  ENABLE_MY_CAMPUS_FEEDS = "ENABLE_MY_CAMPUS_FEEDS",
  ENABLE_ANNOUNCEMENTS = "ENABLE_ANNOUNCEMENTS",
  ENABLE_ASSIGNMENTS_CHANNELS = "ENABLE_ASSIGNMENTS_CHANNELS",
  ENABLE_CLASS_LEVEL_CHANNELS = "ENABLE_CLASS_LEVEL_CHANNELS",
  ENABLE_CHALLAN_RECEIPT = "ENABLE_CHALLAN_RECEIPT",
  LESSON_STATUS_OPTIONS = "LESSON_STATUS_OPTIONS",
  FIVE_ES = "FIVE_ES",
  CHALLAN_RECONCILIATION_WITHIN_DAYS = "CHALLAN_RECONCILIATION_WITHIN_DAYS",
  ENABLE_STD_FEE_RECEIPT = "ENABLE_STD_FEE_RECEIPT",
  CONSIDER_BRANCH_CHRONOLOGICAL_ORDER = "CONSIDER_BRANCH_CHRONOLOGICAL_ORDER",
  CONSIDER_CLASS_CHRONOLOGICAL_ORDER = "CONSIDER_CLASS_CHRONOLOGICAL_ORDER",
  ENABLE_PRINT_STD_FEE_RECEIPT_AFTER_CHALLAN_RECONCILE = "ENABLE_PRINT_STD_FEE_RECEIPT_AFTER_CHALLAN_RECONCILE",
  ACADEMICS_POSTING_LEVEL = "ACADEMICS_POSTING_LEVEL",
  ENABLE_SUBJECT_GROUPING = "ENABLE_SUBJECT_GROUPING",
  ENABLE_PER_STD_SUBJECT_ALLOCATION = "ENABLE_PER_STD_SUBJECT_ALLOCATION",
  DASHBOARD_REFRESH_TIMEOUT = "DASHBOARD_REFRESH_TIMEOUT",
  ENABLE_TEACHER_DAILY_DIARY = "ENABLE_TEACHER_DAILY_DIARY",
  ENABLE_PAYMENT_DUE_PAGE = "ENABLE_PAYMENT_DUE_PAGE",
  ENABLE_FEED_BACK = "ENABLE_FEED_BACK",
  ENABLE_INDIVIDUAL_CHANNELS = "ENABLE_INDIVIDUAL_CHANNELS",
  ENABLE_TEACHER_TO_EDIT_TEST_MARKS = "ENABLE_TEACHER_TO_EDIT_TEST_MARKS",
  ENABLE_TEACHER_TO_EDIT_ATTENDANCE = "ENABLE_TEACHER_TO_EDIT_ATTENDANCE",
  OPTION_TO_MARK_ALL_STD_ABSENT = "OPTION_TO_MARK_ALL_STD_ABSENT",
  ENABLE_STUDENT_DOCUMENT_COLLECTION = "ENABLE_STUDENT_DOCUMENT_COLLECTION",
  ENABLE_BRANCH_LEVEL_LOGOS = "ENABLE_BRANCH_LEVEL_LOGOS",
  MINIMUM_STUDENT_FEE = "MINIMUM_STUDENT_FEE",
  ENABLE_LESSON_PLANNER = "ENABLE_LESSON_PLANNER",
  ENABLE_TO_MANAGE_VACANT_SEATS = "ENABLE_TO_MANAGE_VACANT_SEATS",
  OPTION_SEARCH_STD = "OPTION_SEARCH_STD",
}

export enum ParentQueryType {
  PARENTS_BY_INST_ID = "PARENTS_BY_INST_ID",
  FATHER = "FATHER",
  MOTHER = "MOTHER",
  GUARDIAN = "GUARDIAN",
}
export enum ExportType {
  BOTH = "BOTH",
  GRADES = "GRADES",
  MARKS = "MARKS",
}
export enum PrEmpDailyDiaryQueryType {
  DIARY_NOTES = "DIARY_NOTES",
  PREV_DAY_DIARY_NOTES = "PREV_DAY_DIARY_NOTES",
  LAST_MON_DIARY_NOTES = "LAST_MON_DIARY_NOTES",
  LAST_TUE_DIARY_NOTES = "LAST_TUE_DIARY_NOTES",
  LAST_WED_DIARY_NOTES = "LAST_WED_DIARY_NOTES",
  LAST_THUR_DIARY_NOTES = "LAST_THUR_DIARY_NOTES",
  LAST_FRI_DIARY_NOTES = "LAST_FRI_DIARY_NOTES",
  LAST_SAT_DIARY_NOTES = "LAST_SAT_DIARY_NOTES",
  LAST_SUN_DIARY_NOTES = "LAST_SUN_DIARY_NOTES",
}

export enum InstitutionDetails {
  INSTITUTION = "INSTITUTION",
  DEPARTMENT = "DEPARTMENT",
  BRANCH = "BRANCH",
  CLASS = "CLASS",
  SEMESTER = "SEMESTER",
  SECTION = "SECTION",
  CATEGORY = "CATEGORY",
  FINANCIAL_YEAR = "FINANCIAL_YEAR",
  TABLE_NONE = "TABLE_NONE",
  COMPLETEDETAILS = "COMPLETEDETAILS",
}

export enum PageFor {
  MODAL = "MODAL",
  GENERAL = "GENERAL",
  PRINT = "PRINT",
  DASHBOARD = "DASHBOARD",
  REPORT = "REPORT",
  ADMIN = "ADMIN",
}
export enum SectionAllocationType {
  ALLOCATION = "ALLOCATION",
  NOTALLOCATED = "NOTALLOCATED",
}
export enum BookListType {
  GENERAL_LIST = "GENERAL_LIST",
  DAMAGED = "DAMAGED",
  BY_CLASSIFICATION = "BY_CLASSIFICATION",
  ISSUED_BY_CLASSIFICATION = "ISSUED_BY_CLASSIFICATION",
  ON_SHELF = "ON_SHELF",
}

//accounting enums

export enum FeeLedgerReceipt {
  RECEIPT = "RECEIPT",
  MANAGEMENT_FUND_RECEIPT = "MANAGEMENT_FUND_RECEIPT",
  GOVERNMENT_FEE_RECEIPT = "GOVERNEMNT_FEE_RECEIPT",
  ADVANCE_DEPOSITS_RECEIPT = "ADVANCE_DEPOSITS_RECEIPT",
}

export enum BankOrCash {
  CASH = "C",
  BANK = "B",
  OTHERS = "O",
  STD = "STD",
  ADJ = "ADJ",
  ALL = "ALL",
  DEPOSIT = "DEPOSIT",
}

export enum SundryCreditororDebitor {
  SUNDRAY_CREDITOR = "SC",
  SUNDRAY_DEBITOR = "SD",
}

export enum DebitOrCredit {
  DEBIT = "Dr",
  CREDIT = "Cr",
}

export enum BankAccountType {
  SAVINGS = "S",
  CURRENT = "C",
  OD = "OD",
}

export enum NonDemand {
  ROLE = "ROLE",
  NON_ROLE = "NON_ROLE",
}

export enum CompleteFeeReceipt {
  PARTICULAR = "PARTICULAR",
  ALL_YEARS = "ALL YEARS",
}

export enum UserOptions {
  TOGGLE = "Toggle",
  INPUT = "Input",
  RADIO_BUTTON = "Radio Button",
}

export enum TableHeaders {
  ICON = "Arrow",
  SLNO = "Sl",
  NAME = "Name",
  PLACE = "Place",
  EDUATE_ID = "Eduate Id",
  EMPLOYEE_ID = "Employee Id",
  TITLE = "Title",
  CONTACT_PERSON = "Contact Person",
  MOBILE = "Mobile",
  TELEPHONE = "Telephone",
  ACTION = "Actions",
  PARENT_TYPE = "Parent Type",
  BOOKS_ISSUED = "Books Issued",
  ADMISSION_NUMBER = "Adm No.",
  FATHER_NAME = "Father Name",
  MOTHER_NAME = "Mother Name",
  REGISTER_NUMBER = "Reg No.",
  ADDRESS = "Address",
  STATUS = "Status",
  EMAIL = "Email",
  DOB = "Date of Birth",
  MOTHER_TONGUE = "Mother Toungue",
  RELIGION = "Religion",
  CASTE = "Caste",
  GENDER = "Gender",
  YEAR = "Year",
  START_DATE = "Start Date",
  END_DATE = "End Date",
  STD_STATUS = "Student Status",
  LOGIN_STATUS = "Login Status",
  DESCRIPTION = "Description",
  FEE_DESCRIPTION = "Fee Description",
  DUE_DATE = "Due Date",
  STUDENT_NAME = "Student Name",
  FRESHER = "Fresher",
  DEMAND_ID = "Demand Id",
  DEMAND_AMOUNT = "Demand ",
  OB = "O.B",
  DEMAND = "Demand",
  CONCESS = "Concession",
  PAYABLE = "Payable",
  PAID = "Paid",
  BALANCE = "Balance",
  PARTICULAR = "Particular",
  RECEIVABLE = "Receivable",
  RECEIVED = "Received",
  RECEIPT_AMOUNT = "Rcpt Amt.",
  FEE_AMOUNT = "Fee Amount",
  TOTAL_AMOUNT = "Total Amount",
  REFUND = "Refund",
  OPEN_BALANCE = "Open. Bal",
  RECEIPT_NUMBER = "Rcpt No.",
  DATE = "Date",
  FINE = "Fine",
  DEPOSITS = "Deposits",
  DEMAND_FEE = "Dmd Fee.",
  NON_DEMAND_FEE = "Non Dmd Fee.",
  TOTAL = "Total",
  THROUGH = "Through",
  REFUNDED = "Refunded",
  CLOSING_BALANCE = "Cls. Bal.",
  ADJUSTED = "Adjusted",
  FEE_DEMAND = "Fee Demand",
  FEE_COLLECTED = "Fee Collected",
  REFUND_AMOUNT = "Ref Amt.",
  DEMAND_OPEN_BALANCE = "O B",
  DEMAND_CONCESSION = " Concession",
  DEMAND_TOTAL = " Total",
  DEMAND_PAID = "Collected",
  DEMAND_REFUNDS = " Refunds",
  DEMAND_BALANCE = " Balance",
  DEPOSIT_OB = "Deposit OB",
  DEPOSIT_AMOUNT = "Deposit Amount",
  DEPOSIT_TOTAL = "Deposit Total",
  DEPOSIT_ADJUSTED = "Deposit Adjusted",
  DEPOSIT_REFUNDED = "Deposit Refunded",
  DEPOSIT_BALANCE = "Deposit Balance",
  DEMAND_YEAR = "Demand Year",
  RAISE_DEMAND = "Raise Demand",
  DEMAND_NUMBER = "Demand No ",
  DEMAND_DATE = "Demand Date",
  PASSOUT_YEAR = "Pass Out Year",
  ID = "ID",
  INDEX_NO = "INDEX NO",
  TYPE = "TYPE",
  VALUE1 = "VALUE1",
  VALUE2 = "VALUE2",
  VALUE3 = "VALUE3",
  VALUE4 = "VALUE4",

  LABEL = "Label",
  NUMBER = "Number",
  AMOUNT = "Amount",

  CODE = "Code",
  SHORT_DESCRIPTION = "Short Description",
  LANGUAGE = "Language",
  PRACTICAL = "Practical",
  NON_ACADEMIC = "Non Academic",
  IS_GRADE = "Is Grade",

  SUBJECT_DESCRIPTION = "Subject Description",
  SUBJECT_CODE = "Subject Code",
  ADMISSION_DATE = "Admission Date",
  AGE = "Age",
  TOTAL_SESSION = "Total Sessions",
  TOTAL_PRESENT = "Total Present",
  TOTAL_ABSENT = "Total Absent",
  TOTAL_PERCENTAGE = "Total Percentage",
}

export enum ReceiptTypes {
  STUDENT_RECEIPT = "S",
  PYMT = "P",
  RCPT = "R",
  CONTRA = "C",
  JOURNAL = "J",
  ONLINE_STD_RECEIPT = "ONLINE_STD_RECEIPT",
  NONE = "NONE",
}

export enum StudentListFields {
  STD_ADMISSION_NO = "std_adm_no",
  STD_REG_NO = "std_reg_no",
  STD_NAME = "std_name",
  STD_FATHER_NAME = "std_father_name",
  STD_FATHER_MOBILE = "std_father_mobile",

  STD_MOTHER_NAME = "std_mother_name",
  STD_MOTHER_MOBILE = "std_mother_mobile",

  STD_EMAIL = "std_email",
  STD_MOBILE = "std_mobile",
  STD_TELEPHONE = "std_phone",
  STD_DOB = "std_dob",
  STD_SEX = "std_sex",
  STD_FRESHER = "std_fresher",
  STD_DOA = "std_doa",
  STD_AADHAR = "std_aadhar",
  STD_BLOOD_GROUP = "std_blood_group",
  STD_ADDRESS = "std_address",
  STD_PLACE = "std_place",
  STD_DISTRICT = "std_district",
  STD_STATE = "std_state",
  STD_PINCODE = "std_pincode",
  STD_PHONE = "std_phone",
  STD_CORR_ADDRESS = "std_corr_address",
  STD_CORR_PLACE = "std_corr_place",
  STD_CORR_DISTRICT = "std_corr_district",
  STD_CORR_STATE = "std_corr_state",
  STD_CORR_PINCODE = "std_corr_pincode",
  STD_CORR_PHONE = "std_corr_phone",
  STD_RELIGION = "std_religion",
  STD_CASTE = "std_caste",
  STD_SUB_CASTE = "std_sub_caste",
  STD_CASTE_GROUP = "std_caste_group",
  STD_SC_ST = "std_sc_st",
  STD_CASTE_MINORITY = "std_caste_minority",
  STD_RURAL_URBAN = "std_rural_urban",
  STD_MOTHER_TONGUE = "std_mother_tongue",
  STD_ANNUAL_INCOME = " std_annual_income",
  STD_IDENTITY_MARKS = "std_identity_marks",
  STD_PLACE_OF_BIRTH = " std_place_of_birth",
  STD_NATIONALITY = "std_nationality",
  STD_IS_PHY_CHALLENGED = "std_is_phy_challenged",
  STD_PREV_TC_DETAILS = "std_prev_tc_details",
  STD_PREV_INST = "std_prev_inst",
  STD_PREV_QUALI_EXAM = "std_prev_quali_exam",
  STD_PREV_STUDIED_BOARD = "std_prev_studied_board",
  STD_PREV_STUDIED_STATE = "std_prev_studied_state",
  STD_PREV_STUDIED_STUDIED = "std_prev_studied_studied",
  STD_PREV_TOTAL_MARKS = "std_prev_total_marks",
  STD_PREV_TOTAL_PER = "std_prev_total_per",

  DEPT_ID = "dept_id",
  BRANCH_ID = "branch_id",
  CLASS_ID = "class_id",
  SEMESTER_ID = "semester_id",
  SECTION_ID = "section_id",
  CATEGORY_ID = "category_id",
  STD_STATUS = "std_status",
  STD_DEMAND_OB = "std_demand_ob",
  STD_DEMAND_AMT = "std_demand_amt",
  STD_DEMAND_CONCESSION = "std_demand_concession",
  STD_DEMAND_TOTAL = "std_demand_receivable",
  STD_DEMAND_PAID = "std_demand_received",
  STD_DEMAND_REFUNDS = "std_demand_refunds",
  STD_DEMAND_BAL = "std_demand_bal",
  STD_DEPOSIT_OB = "std_deposit_ob",
  STD_DEPOSIT_AMT = "std_deposit_amt",
  STD_DEPOSIT_TOTAL = "std_deposit_total",
  STD_DEPOSIT_ADJUSTED = "std_deposit_adjusted",
  STD_DEPOSIT_REFUNDED = "std_deposit_refunded",
  STD_DEPOSIT_BAL = "std_deposit_bal",
}

export enum VoucherBookKey {
  STUDENT_GENERAL_RECEIPT_BOOK = "STD_REC_GEN",
  STUDENT_MANAGEMENT_RECEIPT_BOOK = "STD_REC_DON",
  STUDENT_GOVERNMENT_RECEIPT_BOOK = "STD_REC_GVT",
  STUDENT_SALE_RECEIPT_BOOK = "STD_REC_SAL",
  STUDENT_ADVANCE_RECEIPT_BOOK = "STD_REC_ADV",
  STUDENT_REFUND_RECEIPT_BOOK = "STD_REFUND",
  STUDENT_COMMAN_RECEIPT_BOOK = "STD_GENERAL",
  PAYMENT_VOUCHER_NUMBER = "PAYMENT",
  RECEIPT_VOUCHER_NUMBER = "RECEIPT",
  CONTRA_VOUCHER_NUMBER = "CONTRA",
  JOURNAL = "JOURNAL",
  CONCESSION = "CONCESSION",
  PAYMENT_MKCK = "PAYMENT_MKCK",
  CHALLAN = "CHALLAN",
}

export enum SeparatorTypes {
  SLASH = "/",
  SPACE = "  ",
  HYPHEN = "-",
}

export enum VoucherPageType {
  PAYMENT_VOUCHER = "P",
  RECEIPT_VOUCHER = "R",
  CONTRA_VOUCHER = "C",
  MODAL = "MODAL",
  JOURNAL = "J",
  STUDENT = "S",
}

export enum NonAcademicFeeType {
  APPLICATION_FEE = "Application Fee",
  EXAMINATION_FEE = "Examination Fee",
}

//Predefined Types
export enum PredefinedDataTypes {
  STATE = "STATE",
  CITY = "CITY",
  PLACE = "PLACE",
  BLOODGROUP = "BLOOD_GROUP",
  DISTRICT = "DISTRICT",
  RELIGION = "RELIGION",
  CASTE = "CASTE",
  SUBCASTE = "SUBCASTE",
  CASTE_GROUP = "CASTE_GROUP",
  SC_ST = "SC_ST",
  YESNO = "YESNO",
  RURAL_URBAN = "RURAL_URBAN",
  MOTHER_TONGUE = "MOTHER_TONGUE",
  NATIONALITY = "NATIONALITY",
  FREEPAYMENTSEAT = "FREE_PAYMENT",
  STATEORINTERSTATE = "PUR_STATE",
  SEATGOTTHROUGH = "SEAT_GOT_THROUGH",
  CATEGORY = "CATEGORY",
  MARTIAL_STATUS = "MARTIAL_STATUS",
  STUDENT_STATUS = "STUDENT_STATUS",
  STUDENT_FEE_TYPE = "STUDENT_FEE_TYPE",
  PARENT_TYPE = "PARENT_TYPE",
  STAFF_TYPE = "STAFF_TYPE",
  GENDER = "GENDER",
  CR_DB = "CR_DB",
  BANK_COLLECTION = "BANK_COLLECTION",
  BOOK_STATUS = "BOOK_STATUS",
  OCCUPATION = "OCCUPATION",
  LIB_DONOR_TYPE = "LIB_DONOR_TYPE",
  EARNING_DEDUCTION = "EARNING_DEDUCTION",
  SAL_TYPE = "SAL_TYPE",
  DEDUCTION_LEDGER = "DEDUCTION_LEDGER",
  TEST_TYPE = "TEST_TYPE",
  HOLIDAY_TYPE = "HOLIDAY_TYPE",
  HOLIDAY_FULL_HALF_DAY = "HOLIDAY_FULL_HALF_DAY",
  CERTIFICATE = "CERTIFICATE",
  LEAVE_TYPE = "LEAVE_TYPE",
  ENQ_FOLLOW_UP_STATUS = "ENQ_FOLLOW_UP_STATUS",
  ENQ_REFERENACE_FROM = "ENQ_REFERENACE_FROM",
}
export enum StudentRegistrationFieldNames {
  AADHAR = "std_aadhar",
  MINORITY = "std_caste_minority",
  PHYSICALLY_CHALLENGED = "std_is_phy_challenged",
  BLOODGROUP = "std_blood_group",
  ADDRESS = "std_address",
  CITY = "std_place",
  DISTRICT = "std_district",
  STATE = "std_state",
  CORR_ADDRESS = "std_corr_address",
  CORR_CITY = "std_corr_place",
  CORR_DISTRICT = "std_corr_district",
  CORR_STATE = "std_corr_state",
  CORR_RELIGION = "std_religion",
  CASTE = "std_caste",
  SUB_CASTE = "std_sub_caste",
  CASTE_GROUP = "std_caste_group",
  SCST = "std_sc_st",
  AREA = "std_rural_urban",
  MOTHER_TONGUE = "std_mother_tongue",
  MARRIED = "std_martial_status",
  IDENTITY_MARKS = "std_identity_marks",
  PLACE_OF_BIRTH = "std_place_of_birth",
  NATIONALITY = "std_nationality",
  NEAREST_POLICE_STATION = "std_nearest_police_station",
  NEAREST_RAILWAY_STATION = "std_nearest_railway_station",
  BELONGS_TO_JK = "std_belongs_to_jk",
  IS_KASHMIR_BRAHMIN = "std_belongs_to_jk_brahmin",
  PREVIOUS_INSTITUTION = "std_prev_inst",
  PREVIOUS_QUALIFICATION = "std_prev_quali_exam",
  PREVIOUS_STUDIED_BOARD = "std_prev_studied_board",
  PREVIOUS_STUDIED_STATE = "std_prev_studied_state",
  PREVIOUS_STUDIED_SUBJECT = "std_prev_subject_studied",
  PREVIOUS_STUDIED_SUBJECT_TECH = "std_prev_tech_subject_studied",
  BANK = "std_bank_name",
  BANK_BRANCH = "std_bank_branch",
  FREE_PAYMENT = "std_adm_free_pymnt_seat",
  STATE_OR_INTERSTATE = "std_adm_state_or_outside",
  SEAT_GOT_THROUGH = "std_adm_seat_got_through",
  ADM_CATEGORY = "std_adm_category",
  ADM_ELI_DATE = "std_adm_eli_date",
  ADM_CET_PAID_RCPT_DATE = "std_adm_cet_paid_rcpt_date",
  CET_ALLOTED_CATEGORY = "std_adm_cet_alloted_category",
  STD_IS_SPORTS_PERSON = "std_is_sports_person",
  STD_IS_NCC = "std_is_ncc",
  STD_IS_NSS = "std_is_nss",
  STD_IS_GADINADU_HORANADU = "std_is_gadinadu_horanadu",
  STD_IS_DEFENCE = "std_is_defence",
  STD_IS_POLITICAL_SUFFERER = "std_is_political_sufferer",
  STD_IS_CULTURAL = "std_is_cultural",
  STD_IS_HIV_DEVADASI = "std_is_hiv_devadasi",
  STD_IS_CHILD_OF_FREEDOM_FIGHTER = "std_is_child_of_freedom_fighter",
  STD_IS_DESTITUTE_WOMAN = "std_is_destitude_woman",
  STD_IS_CHILD_OF_FARMER = "std_is_child_of_farmer",
  STD_IS_NIRASHRITHARU = "std_is_nirashritharu",
  STD_IS_BPL_CARD = "std_is_bpl_card",
  STD_IS_NON_EMP_STATUS = "std_is_non_emp_status",
  STD_IS_KSOU_EMP = "std_is_ksou_emp",
  STD_KK_RESERVATION = "std_kk_reservation",
  STD_KANNADA_MEDIUM = "std_kannada_medium",
  STD_BANK_ACCT = "std_bank_acct",
  STD_BANK_NAME = "std_bank_name",
  STD_BANK_IFSC = "std_bank_ifsc",
  STD_BANK_BRANCH = "std_bank_branch",
  STD_ADM_APP_NO = "std_adm_appl_no",
  STD_FREE_SEAT = "std_adm_free_pymnt_seat",
  STD_STATE_OR_OUTSIDE = "std_adm_state_or_outside",
  STD_SEAT_THROUGH = "std_adm_seat_got_through",
  STD_ADM_CATEGORY = "std_adm_category",
  STD_ADM_CET_NO = "std_adm_cet_no",
  STD_CET_RANK = "std_adm_cet_rank",
  STD_ADM_ELI_NO = "std_adm_eli_no",
  STD_ADM_ELI_DATE = "std_adm_eli_date",
  STD_CET_RECIEPT_NO = "std_adm_cet_paid_rcpt_no",
  STD_CET_PAID_DATE = "std_adm_cet_paid_rcpt_date",
  STD_CET_PAID_AMOUNT = "std_adm_cet_paid_amt",
  STD_ALLOTED_CATEGORY = "std_adm_cet_alloted_category",
}

export enum PageNumbers {
  FINANCIAL_YEAR_PAGE = 1,
  ACADEMIC_YEAR_PAGE = 2,
  DEPARTMENT_PAGE = 3,
  BRANCH_PAGE = 4,
  CLASS_PAGE = 5,
  SEMESTER_PAGE = 6,
  SECTION_PAGE = 7,
  CATEGORY_PAGE = 8,

  STUDENT_LIST_PAGE = 9,
  STAFF_REGISTRATION_PAGE = 10,
  STAFF_LIST_PAGE = 11,
  GROUP_LEDGER_PAGE = 12,
  ACCOUNTING_LEDGER_PAGE = 13,
  FEE_LEDGER_PAGE = 14,
  GENERAL_FEE_LEDGER_PAGE = 15,
  FEE_DEMAND_PAGE = 16,
  FEE_DEMAND_FEE_PAYABLE_PAGE = 17,
  NON_DEMAND_FEE_PAYABLE_PAGE = 18,
  STUDENT_RECEIPT_PAGE = 19,
  APPLICATION_FEE_RECEIPT_PAGE = 20,
  EXAMINATION_FEE_RECEIPT_PAGE = 21,
  VOUCHER_PAGE = 22,
  INTERCHANGE_FEE_RECEIPT_PAGE = 23,
  ALLOT_STUDENT_REGISTER_NO = 24,
  ADVANCE_RECEIPT = 25,
  ADVANCE_ADJUSTMENT = 26,

  TEST_COUNDUCT_PAGE = 29,
  LIB_BOOK_ENTRY_LIST_PAGE = 200,
  LIB_BOOK_ISSUE_PAGE = 201,
  LIB_BOOK_SHELF_ALLOCATION_PAGE = 202,
  LIB_BOOK_RETURN_PAGE = 203,
  LIB_BOOK_REPORTS_PAGE = 204,
}
export enum transactionTypeEnum {
  BY = "BY",
  TO = "TO",
}

export enum InstConfigKey {
  INSTITUTION_TYPE = "INSTITUTION_TYPE",
  INSTITUTION_WORK_MODEL = "INSTITUTION_WORK_MODEL",
  INSTITUTION_ADMISSION_NUMBER_MODEL = "STD_ADMISSION_NUMBER",
  INSTITUTION_WORK_TYPE_SCH = "INSTITUTION_TYPE_SCH",
  INSTITUTION_WORK_TYPE_PU = "INSTITUTION_WORK_TYPE_PU",
  INSTITUTION_WORK_TYPE_DEGREE = "INSTITUTION_WORK_TYPE_DEGREE",
  INSTITUTION_WORK_TYPE_PROFF_DEGREE = "INSTITUTION_WORK_TYPE_PROFF_DEGREE",
  INSTITUTION_WORK_TYPE_DIPLOMA = "INSTITUTION_WORK_TYPE_DIPLOMA",
  INSTITUTION_WORK_TYPE_OTHERS = "INSTITUTION_WORK_TYPE_OTHERS",
  SUBJECT_ALLOCATION_LEVEL = "SUBJECT_ALLOCATION_LEVEL",
  SCHOOL = "SCH",
  PU_COLLEGE = "PU",
  DEGREE = "DEGREE",
  PROFF_DEGREE_COLLEGE = "PROFF-DEGREE",
  DIPLOMA = "DIPLOMA",
  OTHERS = "OTHERS",
  STD_LEAVE_OPTIONS = "STD_LEAVE_OPTIONS",
  LEAVE_APPROVED_BY = "LEAVE_APPROVED_BY",
  ID_CARD_LIGHT_COLORS = "ID_CARD_LIGHT_COLORS",
  ID_CARD_DARK_COLORS = "ID_CARD_DARK_COLORS",
  ID_CARD_LIGHT_TEXT_COLORS = "ID_CARD_LIGHT_TEXT_COLORS",
  ID_CARD_DARK_TEXT_COLORS = "ID_CARD_DARK_TEXT_COLORS",
}

//Assignment Status
export enum AssignmentStatus {
  SUBMITTED = "Submitted",
  NOT_SUBMITTED = "Not Submitted",
}
export enum StudentRegitrationForms {
  PERSONAL_DATA = "MST_STUDENT_DETAILS",
  PREVIOUS_INST_DATA = "MST_STUDENT_OTHER_DETAILS",
}

//configuration modal
export enum ConfigurationModal {
  MODAL = "MODAL",
  GENERAL = "GENERAL",
}

//Student Fee Receipt Modal

//Student Search Fields
export enum StudentSearchField {
  ADMISSION_NUMBER = "Admission No",
  NAME = "Name",
  REGISTER_NUMBER = "Register no.",
}
export enum AccountBookTypes {
  ACCOUNT_LEDGER_BOOK = "AccountLedgerBook",
  REGISTER_BOOK = "RegisterLedgerBook",
  GROUP_LEDGER = "GroupledgerBook",
  BANK_RECONCILATION = "BankReconcilation",
  FEE_COLLECTED = "FeeCollected",
  RECEIPT_BOOK = "RECEIPT_BOOK",
}

//Modules name for Success Messages
export enum Modules {
  INSTITUTION = "Institution",
  CUSTOMER = "Customer",
  STAFF = "Staff",
  PARENT = "Parent",
  CHANNEL = "Channel",
  PREDEFINED_DATA = "Predefined Data",
  STUDENT_CONFIGURATION = "Student Configuration",
  STAFF_CONFIGURATION = "Staff Configuration",
  FEE_DEMAND = "Fee Demand",
  ACCOUNT_LEDGER = "Account Ledger",
  GROUP_LEDGER = "Group Ledger",
  FEE_LEDGER = "Fee Ledger",
  GENERAL_FEE_LEDGER = "General Fee Ledger",
  DEPARTMENT = "Department",
  BRANCH = "Branch",
  CLASS = "Class",
  SEMESTER = "Semester",
  SECTION = "Section",
  CATEGORY = "Category",
}

//Operation Success Messages
export enum message {
  CREATED = "Created Successfully",
  UPDATED = "Updated Successfully",
  DELETE = "Deleted Successfully",
}

//menus
export enum LicenseTypes {
  ACCOUNTS = "ACCOUNTS",
  ACADEMIC = "ACADEMIC",
  LIBRARY = "LIBRARY",
  TRANSPORT = "TRANSPORT",
  ENQUIRY = "ENQUIRY",
  PAYROLL = "PAYROLL",
  EDUATE_CONFIGURATION = "EDUATE_CONFIGURATION",
  USER_RIGHTS = "USER_RIGHTS",
  INSTITUTION_CONFIG = "INSTITUTION_CONFIG",
  MASTERS = "MASTERS",
  EDUATE_GENERAL = "EDUATE_GENERAL",
  EDUATE_CUSTOMER = "EDUATE_CUSTOMER",
  ADMISSION = "ADMISSION",
}
export enum EduateModule {
  ACCOUNTS = "ACCOUNTS",
  ADMISSION = "ADMISSION",
  LIBRARY = "LIBRARY",
  TRANSPORT = "TRANSPORT",
  PAYROLL = "PAYROLL",
  ACADEMICS = "ACADEMICS",
  ENQUIRY = "ENQUIRY",
  ACADEMICSAPP = "ACADEMICS-APP",
  MESSAGEAPP = "MESSAGE-APP",
}

//export reports module type
export enum ExportModuleType {
  ACCOUNTS = "accounts",
  ADMISSIONS = "admissions",
}

//library reports

export enum PagesForLibraryReports {
  CURRENTLY_ISSUED_BOOKS = "CURRENTLY_ISSUED_BOOKS",
  CURRENTLY_RECEIVED_BOOKS = "CURRENTLY_RECEIVED_BOOKS",
  ISSUED_AND_RETURNED_BOOKS = "ISSUED_AND_RETURNED_BOOKS",
  BOOKS_OVERDUE = "BOOKS_OVERDUE",
  FINE_COLLECTION = "FINE_COLLECTION",
  BOOKS_CLEARANCE_LIST = "BOOKS_CLEARANCE_LIST",
}
export enum StudentAcctReportType {
  COMPLETELY_PAID = "COMPLETELY_PAID",
  CURR = "CURR",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  DEMAND_RAISED_NOT_PAID = "DEMAND_RAISED_NOT_PAID",
  DEMAND_RAISED = "DEMAND_RAISED",
  FEE_ADVANCE = "FEE_ADVANCE",
  FEE_RECEIPT = "FEE_RECEIPT",
  UNMAPPED_ACCT_LDGR = "UNMAPPED_ACCT_LDGR",
  MAPPED_ACCT_LDGR = "MAPPED_ACCT_LDGR",
  LEDGER_WISE = "LEDGER_WISE",
  REFUNDS = "REFUNDS",
  CONCESSION = "CONCESSION",
  PARTIALLY_OR_COMPLETELY_PAID = "PARTIALLY_OR_COMPLETELY_PAID",
  BY_NEW_DATE = "BY_NEW_DATE",
  GENERAL = "GENERAL",
  ANONYMOUS_STUDENT = "ANONYMOUS_STUDENT",
  SOCIAL_WELFARE_STUDENT = "SOCIAL_WELFARE_STUDENT",
  AGENT_STUDENT = "AGENT_STUDENT",
  ALL_ANONYMOUS_STUDENTS = "ALL_ANONYMOUS_STUDENTS",
  DEMAND_SUMMATION_CURR = "DEMAND_SUMMATION_CURR",
  DEMAND_FRESHER = "DEMAND_FRESHER",
  DEMAND_EXISTING = "DEMAND_EXISTING",
  DEMAND_NOT_RAISED = "DEMAND_NOT_RAISED",
  PASSED_OUT = "PASSED_OUT",
  BY_AGENT = "BY_AGENT",
  LEDGER_WISE_BALANCE = "LEDGER_WISE_BALANCE",
}
export enum BookReturnSearchBy {
  STUDENT = "Student",
  STAFF = "Staff",
  BOOK_ID = "Book",
}
export enum StudentReportType {
  GENERAL = "GENERAL",
  NOT_REGISTERED = "NOT_REGISTERED",
  SECTION_ALLOCATION = "SECTION_ALLOCATION",
  BY_SECTION_ID = "BY_SECTION_ID",
  ELIGIBLE = "ELIGIBLE",
  NON_ELIGIBLE = "NON_ELIGIBLE",
  LIB_BOOK_CLEARANCE = "LIB_BOOK_CLEARANCE",
  STUDENTS_BY_CHANNEL = "STUDENTS_BY_CHANNEL",
  BY_ALLOTTED_LEVEL = "BY_ALLOTTED_LEVEL",
  BY_MARKS = "BY_MARKS",
  STATUS_NXT_INST_ID = "STATUS_NXT_INST_ID",
  TRANSPORT_ENABLED = "TRANSPORT_ENABLED",
  TRANSPORT_NOT_ENABLED = "TRANSPORT_NOT_ENABLED",
  TRANSPORT_ASSIGNED = "TRANSPORT_ASSIGNED",
  TRANSPORT_NOT_ASSIGNED = "TRANSPORT_NOT_ASSIGNED",
  NXT_STUDENTS = "NXT_STUDENTS",
  NEW_ADMISSION = "NEW_ADMISSION",
  BY_RELIGION = "BY_RELIGION",
  BY_AGE = "BY_AGE",
  CURR_STUDENTS = "CURR_STUDENTS",
  BY_SUBJ_ID = "BY_SUBJ_ID",
  PASSED_OUT = "PASSED_OUT",
  BY_COMBO_ID_AT_ALLOTED_LEVEL = "BY_COMBO_ID_AT_ALLOTED_LEVEL",
  COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL = "COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL",
  CAMPUS = "CAMPUS",
  MOBILE_DEVICE = "MOBILE_DEVICE",
  STDS_BY_ADMISSION_NUMS = "STDS_BY_ADMISSION_NUMS",
  LOGIN_ACTIVE = "LOGIN_ACTIVE",
  LOGIN_INACTIVE = "LOGIN_INACTIVE",
  DEVICE_INSTALLED = "DEVICE_INSTALLED",
  DEVICE_NOT_INSTALLED = "DEVICE_NOT_INSTALLED",
  STDS_WITHOUT_REGISTER_NUMBER = "STDS_WITHOUT_REGISTER_NUMBER",
  STDS_WITHOUT_SECTION_ALLOCATED = "STDS_WITHOUT_SECTION_ALLOCATED",
  STDS_BY_AGE = "STDS_BY_AGE",
  STDS_STATUS_NEQ_CUR = "STDS_STATUS_NEQ_CUR",
  MOBILE_APP_INSTALLED = "MOBILE_APP_INSTALLED",
  MOBILE_APP_NOT_INSTALLED = "MOBILE_APP_NOT_INSTALLED",
  STDS_BY_CHANNEL_ID = "STDS_BY_CHANNEL_ID",
  STDS_NEW_ADMISSION = "STDS_NEW_ADMISSION",
  STDS_AVAILED_TRANSPORT = "STDS_AVAILED_TRANSPORT",
  STDS_NOT_AVAILED_TRANSPORT = "STDS_NOT_AVAILED_TRANSPORT",
  STDS_NOT_ASSIGNED_ROUTE = "STDS_NOT_ASSIGNED_ROUTE",
  STDS_BY_RELIGION = "STDS_BY_RELIGION",
  STDS_BY_JOINING_YEAR = "STDS_BY_JOINING_YEAR",
  ACD_STD_GENERAL = "ACD_STD_GENERAL",
  BY_ACD_INST_ID = "BY_ACD_INST_ID",
  STDS_NOT_ALLOCATED = "STDS_NOT_ALLOCATED",
  SIBLINGS = "SIBLINGS",
}
export enum AcdSubjectType {
  GENERAL = "GENERAL",
  ELECTIVE = "ELECTIVE",
  NON_ELECTIVE_SUBJECTS = "NON_ELECTIVE_SUBJECTS",
  SUBJ_BY_COMBO_ID = "SUBJ_BY_COMBO_ID",
}
export enum StudentsTableType {
  ALLOT_REGISTER_NUMBER = "ALLOT_REGISTER_NUMBER",
}

export enum StudentListType {
  UNREGISTERED_STUDENTS = "unregistered_students",
  ALL_STUDENTS = "all_students",
}

export enum StudentListFor {
  ACADEMIC = "ACADEMIC",
  ACCOUNTS = "ACCOUNTS",
  GENERAL = "GENERAL",
  LIBRARY = "LIBRARY",
  DASHBOARD = "DASHBOARD",
  DASHBOARD_GENERAL = "DASHBOARD_GENERAL",
  DASHBOARD_BIODATA = "DASHBOARD_BIODATA",
  DASHBOARD_MAILING = "DASHBOARD_MAILING",
  DASHBOARD_RELIGION = "DASHBOARD_GENERAL",
}

export enum ReturnType {
  RESPONSE_TYPE = "RESPONSE_TYPE",
  QUERY_TYPE = "QUERY_TYPE",
  BOTH = "BOTH",
  VALUE_SAME_AS_LABEL = "VALUE_SAME_AS_LABEL",
  WITH_ID = "WITH_ID",
}

export enum LibBookIssueQueryType {
  BOOKS_ISSUED_BY_STAFF_ID = "BOOKS_ISSUED_BY_STAFF_ID",
  BOOKS_ISSUED_BY_STD_ID = "BOOKS_ISSUED_BY_STD_ID",
  BOOKS_ISSUED_BY_INST_ID = "BOOKS_ISSUED_BY_INST_ID",
  BOOKS_RETURNED_BY_INST_ID = "BOOKS_RETURNED_BY_INST_ID",
  BOOKS_RETURNED_BY_STD_ID = "BOOKS_RETURNED_BY_STD_ID",
  BOOKS_RETURNED_BY_STAFF_ID = "BOOKS_RETURNED_BY_STAFF_ID",
  BOOKS_ISSUED_BY_DEPT_ID = "BOOKS_ISSUED_BY_DEPT_ID",
  BOOKS_ISSUED_BY_BRANCH_ID = "BOOKS_ISSUED_BY_BRANCH_ID",
  BOOKS_ISSUED_BY_CLASS_ID = "BOOKS_ISSUED_BY_CLASS_ID",
  BOOKS_ISSUED_BY_SEMESTER_ID = "BOOKS_ISSUED_BY_SEMESTER_ID",
  BOOKS_ISSUED_BY_SECTION_ID = "BOOKS_ISSUED_BY_SECTION_ID",
  BOOKS_RETURNED_BY_DEPT_ID = "BOOKS_RETURNED_BY_DEPT_ID",
  BOOKS_RETURNED_BY_BRANCH_ID = "BOOKS_RETURNED_BY_BRANCH_ID",
  BOOKS_RETURNED_BY_CLASS_ID = "BOOKS_RETURNED_BY_CLASS_ID",
  BOOKS_RETURNED_BY_SEMESTER_ID = "BOOKS_RETURNED_BY_SEMESTER_ID",
  BOOKS_RETURNED_BY_SECTION_ID = "BOOKS_RETURNED_BY_SECTION_ID",
  BOOKS_OVER_DUE_BY_DEPT_ID = "BOOKS_OVER_DUE_BY_DEPT_ID",
  BOOKS_OVER_DUE_BY_BRANCH_ID = "BOOKS_OVER_DUE_BY_BRANCH_ID",
  BOOKS_OVER_DUE_BY_CLASS_ID = "BOOKS_OVER_DUE_BY_CLASS_ID",
  BOOKS_OVER_DUE_BY_SEMESTER_ID = "BOOKS_OVER_DUE_BY_SEMESTER_ID",
  BOOKS_OVER_DUE_BY_SECTION_ID = "BOOKS_OVER_DUE_BY_SECTION_ID",
  BOOKS_OVER_DUE_BY_INST_ID = "BOOKS_OVER_DUE_BY_INST_ID",
  BOOKS_ISSUED_AND_RETURNED_BY_INST_ID = "BOOKS_ISSUED_AND_RETURNED_BY_INST_ID",
  BOOKS_ISSUED_AND_RETURNED_BY_DEPT_ID = "BOOKS_ISSUED_AND_RETURNED_BY_DEPT_ID",
  BOOKS_ISSUED_AND_RETURNED_BY_BRANCH_ID = "BOOKS_ISSUED_AND_RETURNED_BY_BRANCH_ID",
  BOOKS_ISSUED_AND_RETURNED_BY_CLASS_ID = "BOOKS_ISSUED_AND_RETURNED_BY_CLASS_ID",
  BOOKS_ISSUED_AND_RETURNED_BY_SEMESTER_ID = "BOOKS_ISSUED_AND_RETURNED_BY_SEMESTER_ID",
  BOOKS_ISSUED_AND_RETURNED_BY_SECTION_ID = "BOOKS_ISSUED_AND_RETURNED_BY_SECTION_ID",
  BOOKS_FINE_BY_INST_ID = "BOOKS_FINE_BY_INST_ID",
  BOOKS_FINE_BY_DEPT_ID = "BOOKS_FINE_BY_DEPT_ID",
  BOOKS_FINE_BY_BRANCH_ID = "BOOKS_FINE_BY_BRANCH_ID",
  BOOKS_FINE_BY_CLASS_ID = "BOOKS_FINE_BY_CLASS_ID",
  BOOKS_FINE_BY_SEMESTER_ID = "BOOKS_FINE_BY_SEMESTER_ID",
  BOOKS_FINE_BY_SECTION_ID = "BOOKS_FINE_BY_SECTION_ID",
  BOOKS_ISSUED_BY_ISSUED_ID = "BOOKS_ISSUED_BY_ISSUED_ID",
  BOOKS_ISSUED_BY_BOOK_ID = "BOOKS_ISSUED_BY_BOOK_ID",
  NOT_SELECTED = "NOT_SELECTED",
}
export enum BookConditionType {
  BOOK_DAMAGED = "D",
  BOOK_LOST = "L",
  GOOD = "G",
}
export enum BookReturnType {
  RENEWAL = "RENEWAL",
  RETURN = "RETURN",
}
export enum ReceiptAndPaymentPageFor {
  INCOME_EXPENDITURE = "INCOME_EXPENDITURE",
  RECEIPT_AND_PAYMENT = "RECEIPT_AND_PAYMENT",
  ASSESTS_AND_LIABILITIES = "ASSESTS_AND_LIABILITIES",
}

export enum LibBookMasterQueryType {
  BOOK_BY_INST_ID = "BOOK_BY_INST_ID",
  BOOKS_TO_ISSUE = "BOOKS_TO_ISSUE",
  BOOKS_TO_RETURN = "BOOKS_TO_RETURN",
  BOOKS_TO_CLASSIFICATION_ID = "BOOKS_TO_CLASSIFICATION_ID",
  BOOKS_TO_MEDIA_ID = "BOOKS_TO_MEDIA_ID",
  BOOKS_TO_CATEGORY_ID = "BOOKS_TO_CATEGORY_ID",
  BOOKS_TO_SUBJECT_ID = "BOOKS_TO_SUBJECT_ID",
  BOOKS_TO_PUBLISHER_ID = "BOOKS_TO_PUBLISHER_ID",
  BOOKS_TO_LANGUAGE_ID = "BOOKS_TO_LANGUAGE_ID",
  BOOKS_TO_ATTRIBUTE_ID = "BOOKS_TO_ATTRIBUTE_ID",
  BOOKS_TO_AUTHOR_ID = "BOOKS_TO_AUTHOR_ID",
  BOOKS_TO_VENDOR_ID = "BOOKS_TO_VENDOR_ID",
  BOOKS_THAT_ARE_DAMAGED = "BOOKS_THAT_ARE_DAMAGED",
  BOOKS_THAT_ARE_LOST = "BOOKS_THAT_ARE_LOST",
  BOOKS_ISSUED_TO_STUDENT = "BOOKS_ISSUED_TO_STUDENT",
  BOOKS_ISSUED_TO_STAFF = "BOOKS_ISSUED_TO_STAFF",
  BOOKS_BY_RACK_ID = "BOOKS_BY_RACK_ID",
  BOOKS_RETURNED_BY_STUDENT = "BOOKS_RETURNED_BY_STUDENT",
  BOOKS_RETURNED_BY_STAFF = "BOOKS_RETURNED_BY_STAFF",
  NOT_SELECTED = "NOT_SELECTED",
  BOOKS_ON_SHELF_BY_CLASSIFICATION_ID = "BOOKS_ON_SHELF_BY_CLASSIFICATION_ID",
  BOOKS_ISSUED_BY_CLASSIFICATION_ID = "BOOKS_ISSUED_BY_CLASSIFICATION_ID",
  BOOKS_BY_CLASSIFICATION_ID = "BOOKS_BY_CLASSIFICATION_ID",
  BOOKS_BY_DEPT_ID = "BOOKS_BY_DEPT_ID",
  BOOKS_IN_NOT_ALLOCATED_DEPT = "BOOKS_IN_NOT_ALLOCATED_DEPT",
}

export enum BooksReportType {
  GENERAL = "GENERAL",
  BOOKS_TO_BE_ISSUED = "BOOKS_TO_BE_ISSUED",
  BOOKS_ISSUED = "BOOKS_ISSUED",
  BOOKS_BY_RACK_ID = "BOOKS_BY_RACK_ID",
  BOOKS_ISSUED_AND_RETURNED = "BOOKS_ISSUED_AND_RETURNED",
  BOOKS_FINE_COLLECTION = "BOOKS_FINE_COLLECTION",
  BOOKS_OVER_DUE = "BOOKS_OVER_DUE",
  BOOKS_RETURNED = "BOOKS_RETURNED",
  BOOKS_THAT_ARE_DAMAGED = "BOOKS_THAT_ARE_DAMAGED",
  BOOKS_ON_SHELF = "BOOKS_ON_SHELF",
  BOOKS_ISSUED_BY_CLASSIFICATION = "BOOKS_ISSUED_BY_CLASSIFICATION",
  BOOKS_BY_CLASSIFICATION = "BOOKS_BY_CLASSIFICATION",
  BOOKS_BY_DEPT_ID = "BOOKS_BY_DEPT_ID",
  BOOKS_IN_NOT_ALLOCATED_DEPT = "BOOKS_IN_NOT_ALLOCATED_DEPT",
}
export enum studentData {
  name,
  admNo,
  parent,
  reg,
  id,
  className,
}

export enum StudentDashBoardFor {
  PARENT_LOGIN = "PARENT_LOGIN",
  STUDENT_LOGIN = "STUDENT_LOGIN",
}

export enum MoveDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}
export enum StudentPreviewFor {
  BASIC_DETAILS = "BASIC_DETAILS",
  PERSONAL_DETAILS = "PERSONAL_DETAILS",
  PREV_INST_DETAILS = "PREV_INST_DETAILS",
  STUDENT_DOCS = "STUDENT_DOCS",
  PARENT_DETAILS = "PARENT_DETAILS",
}
export enum AcdSubjectAllocationData {
  CLASS = "CLASS",
  SEMESTER = "SEM",
  SECTION = "SEC",
  DEPT = "DEPT",
  BRANCH = "BRANCH",
  INST = "INST",
  NONE = "NONE",
}

export enum InstDetailsTypeName {
  CLASS = "AcdInstClass",
  SEMESTER = "AcdInstSemester",
  SECTION = "AcdInstSection",
  DEPT = "AcdInstDept",
  BRANCH = "AcdInstBranch",
}

//Library Config Key
export enum LibraryConfigKey {
  LIB_BOOKS_ISSUE_LIMIT = "LIB_BOOKS_ISSUE_LIMIT",
  LIB_BOOK_NO_OF_BORROWING_DAYS = "LIB_BOOK_NO_OF_BORROWING_DAYS",
  LIB_DAMAGEBOOK_FINE_PER = "LIB_DAMAGEBOOK_FINE_PER",
  LIB_LOSTBOOK_FINE_PER = "LIB_LOSTBOOK_FINE_PER",
  LIB_BOOKS_TRANSACTION_DATE_EDITABLE = "LIB_BOOKS_TRANSACTION_DATE_EDITABLE",
  LIB_BOOKS_TRANSACTION_PERMIT_ON_HOLIDAYS = "LIB_BOOKS_TRANSACTION_PERMIT_ON_HOLIDAYS",
  LIB_BOOKS_ACCESSNO_EDITABLE = "LIB_BOOKS_ACCESSNO_EDITABLE",
  ENABLE_LIB_FEE_RECEIPT = "ENABLE_LIB_FEE_RECEIPT",
  TOAST_MSG_TIMEOUT = "TOAST_MSG_TIMEOUT",
  ENABLE_LIB_CAMPUS_MODE = "ENABLE_LIB_CAMPUS_MODE",
}

//Book Search List
export enum BookListTableHeaders {
  CLASSIFICATION_NO = "Classification No.",
  BOOK_TITLE = "Book Title",
  AUTHOR = "Author",
  PUBLISHER = "Publisher",
  MEDIA = "Media",
  CATEGORY = "Category",
  SUBJECT = "Subject",
  LANGUAGE = "Language",
  ATTRIBUTE = "Attribute",
  PUBLISHED_YEAR = "Published Year",
  EDITION = "Edition",
  VOLUME = "Volume",
  COPIES_BOUGHT = "Copies Bought",
  COPIES_AVAILABLE = "Copies Available",
  BOOKS_AVAILABLE = "Books Available to Issue",
  COPIES_ISSUED = "Copies Issued",
  COPIES_DAMAGED = "Copies Damaged",
  COPIES_LOST = "Copies Lost",
  AVG_BOOK_RATE = "Average Book Cost",
  ENABLE_LIB_FEE_RECEIPT = "ENABLE_LIB_FEE_RECEIPT",
  REFERENCE = "Reference",
}

export enum ChannelTable {
  BY_MSG_CHANNEL_ID = "BY_MSG_CHANNEL_ID",
}

export const enum ProvisionalSalaryDetailsType {
  DETAILED = "D",
  BANK = "B",
}

export const enum PayRollReportsTypes {
  PAYSLIP = "PAYSLIP",
  SALARY_STATEMENT = "SALARY_STATEMENT",
  BANK_SCROLL = "BANK_SCROLL",
  LEAVES = "LEAVES",
}

export const enum StudentsWhoLeftInstitutionTypes {
  DELETE_STUDENT = "DELETE_STUDENT",
  DELETE_DEMAND = "DELETE_DEMAND",
}

// HolidayType
export const enum HolidayType {
  GENERAL = "GEN",
  UNEXPECTED = "UNE",
  SUN = "SUN",
  WEEKEND = "WD",
  VACATION = "VH",
  EVENT = "E",
}
// export const enum WeeksEnum {
//   SUNDAY = "SUN",
//   MONDAY = "MON",
//   TUESDAY = "TUE",
//   WEDNESAY = "WED",
//   THURSDAY = "THU",
//   FRIDAY = "FRI",
//   SATURDAY = "SAT",
//   NONE = "NONE",
// }

export const enum HolidayDuration {
  FULLDAY = "FD",
  HALFDAY = "HD",
}
//Student Enquiries Query Type
export const enum StdEnqQueryType {
  STD_ENQ_ALL = "STD_ENQ_ALL",
  STD_ENQ_ADMITTED = "STD_ENQ_ADMITTED",
  STD_ENQ_NOT_ADMITTED = "STD_ENQ_NOT_ADMITTED",
  STD_ENQ_INTERESTED = "STD_ENQ_INTERESTED",
  STD_ENQ_NOT_INTERESTED = "STD_ENQ_NOT_INTERESTED",
  STD_ENQ_NOT_DECIDED = "STD_ENQ_NOT_DECIDED",
  STD_ENQ_TO_FOLLOW_UP = "STD_ENQ_TO_FOLLOW_UP",
  STD_ENQ_TODAY_FOLLOW_UP = "STD_ENQ_TODAY_FOLLOW_UP",
  STD_ENQ_CALLED_TODAY = "STD_ENQ_CALLED_TODAY",
  STD_ENQ_BOUGHT_APP = "STD_ENQ_BOUGHT_APP",
  STD_ENQ_BY_EMP_ID = "STD_ENQ_BY_EMP_ID",
}
export const enum WeeksEnum {
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
}
export const enum BookStatusEnum {
  ISSUED = "I",
  OVERDUE = "O",
  RETURNED = "R",
  DAMAGED = "D",
  LOST = "L",
}

export const enum LedgersForType {
  EDUATE = "EDUATE",
  HR = "HR",
  ACCOUNTS = "ACCOUNTS",
}

//HR
export const enum SalaryDetailsModalType {
  CONFIRM_EMP_SALARY = "CONFIRM_EMP_SALARY",
  PROVISIONAL_SALARY = "PROVISIONAL_SALARY",
}
export const enum PrMkSalQueryType {
  FOR_MK_SAL_PROCESS = "FOR_MK_SAL_PROCESS",
  APPROVED_MK_SAL_PROCESS = "APPROVED_MK_SAL_PROCESS",
}
export const enum BooksBy {
  ACCESS_NO = "ACCESS_NO",
}

export const enum ModuleName {
  PAYROLL = "PAYROLL",
  LIBRARY = "LIBRARY",
  ACCOUNTS = "ACCOUNTS",
  ACADEMICS = "ACADEMICS",
  ADMISSION = "ADMISSION",
  RECEIPT_PRINT = "RECEIPT-PRINT",
  VOUCHER_PRINT = "VOUCHER-PRINT",
  ENQUIRY = "ENQUIRY",
  CHALLAN_PRINT = "CHALLAN-PRINT",
  IDCARD_PRINT = "ID-PRINT",
  USER_RIGHTS = "USER-RIGHTS",
  CHAT = "CHAT",
}

export const enum StdStatusQueryType {
  NE_STATUS_TRUE = "NE_STATUS_TRUE",
  NE_STATUS_FALSE = "NE_STATUS_FALSE",
}

//Enum for Colors in Excel
export const enum ExcelColor {
  DARK_BLUE = "334155",
  LIGHT_BLUE = "e5e7eb",
}

//enum for Fonts in Excel
export const enum ExcelFont {
  ARIAL = "Arial Rounded MT Bold",
  COURIER_NEW = "Courier New",
  CENTURY_GOTHIC = "Century Gothic",
}

//enum For ExcelAlignment
export enum ExcelAlignment {
  RIGHT = "right",
  LEFT = "left",
  CENTER = "center",
  MIDDLE = "middle",
}
export enum ExcelFooterHeader {
  CLOSING_BALANCE = "Closing Balance",
  TOTAL = "Total :",
  SUMMATION = "Summation :",
  OPENING_BALANCE = "Opening balance:",
  GRAND_TOTAL = "Grand Total :",
  LESS_INCOME_OVER_EXPENDITURE = "LESS INCOME OVER EXPENDITURE",
}
export enum ExcelSheetsNames {
  DEMAND_SUMMARY = "DemandSummary.xlsx",
  PAYMENT_VOUCHER = "PaymentVoucher.xlsx",
  RECEIPT_BOOK = "FeeReceiptBook.xlsx",
  FEE_BALANCE = "FeeBalance.xlsx",
  DAY_WISE = "DayWiseCollection.xlsx",
  MONTHLY_TOTAL = "MonthlyTotal.xlsx",
  COMPLETE_TRANSACTION = "CompleteTransaction.xlsx",
  BOOK_REPORT = "BookReport.xlsx",
  LEDGER_WISE = "LedgerWiseReport.xlsx",
  RP_ACCOUNT = "Receipt & Payment.xlsx",
  IE_ACCOUNT = "Income & Expenditure.xlsx",
  BALANCE_SHEET = "Balance Sheet.xlsx",
  BOOK_CLEARANCE = "Book Clearance.xlsx",
  STUDENT_LIST = "Student List.xlsx",
  STUDENTS_TEST_RESULT_LIST = "Students Test Result List.xlsx",
}
export enum ExcelPageHeader {
  FEE_BALANCE = "Fee Balance Students Report",
  DATE = "As on Date :",
  YEAR = "  Fin-Year: ",
  LEDGER_WISE = "Ledger Wise Student Report",
  FEE_RECIEPT = "Fee Receipt Book",
  DAY_WISE_COLLECTION = "Day Wise Collection Report",
  BALANCE_SHEET = "Balance Sheet Excel",
  INCOME_EXPENDITURE = "Income & Expenditure Account",
  RECEIPT_PAYMENT = "Receipt & Payment Account",
  PAYMENT_VOUCHER = "Payment Voucher Register",
  MONTHLY_TOTAL = "Monthly Total",
  RECIEPT_ACCOUNT = "Receipt Account",
  PAYMENT_ACCOUNT = "Payment Account",
  INCOME_ACCOUNT = "Income Account",
  EXPENDITURE_ACCOUNT = "Expenditure Account",
  ASSETS = "Assests",
  LIABILITIES = "Liabilities",
  TRANSACTION_LIST = "Books Transaction List",
  BOOK_CLEARANCE_LIST = "Book Clearance List",
  STUDENT_LIST = "Master Student List",
  INST_CONFIG_DATA = "InstConfigData",
  STUDENTS_TEST_RESULT = "Students Test Result ",
}

export enum TagNames {
  INPUT = "input",
  TEXTAREA = "TEXTAREA",
}

export enum Gender {
  MALE = "M",
  FEMALE = "F",
  OTHERS = "O",
}
//Enums for Transportation
export enum StageDetails {
  ROUTE = "Bus Route",
  STAGE = " Stage Place",
  LEDGER = "Ledger",
  FEE = "Route Fee",
}
export enum TransportQueryType {
  BY_ROUTE_MASTER = "BY_ROUTE_MASTER",
  BY_ROUTE_DETAILS = "BY_ROUTE_DETAILS",
  ROUTE_NOT_ASSIGNED_BY_INST_ID = "ROUTE_NOT_ASSIGNED_BY_INST_ID",
  ROUTE_NOT_ASSIGNED_BY_DEPT_ID = "ROUTE_NOT_ASSIGNED_BY_DEPT_ID",
  ROUTE_NOT_ASSIGNED_BY_BRANCH_ID = "ROUTE_NOT_ASSIGNED_BY_BRANCH_ID",
  ROUTE_NOT_ASSIGNED_BY_CLASS_ID = "ROUTE_NOT_ASSIGNED_BY_CLASS_ID",
  ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID = "ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID",
  ROUTE_NOT_ASSIGNED_BY_SECTION_ID = "ROUTE_NOT_ASSIGNED_BY_SECTION_ID",
  ROUTE_ASSIGNED_BY_INST_ID = "ROUTE_ASSIGNED_BY_INST_ID",
  ROUTE_ASSIGNED_BY_DEPT_ID = "ROUTE_ASSIGNED_BY_DEPT_ID",
  ROUTE_ASSIGNED_BY_BRANCH_ID = "ROUTE_ASSIGNED_BY_BRANCH_ID",
  ROUTE_ASSIGNED_BY_CLASS_ID = "ROUTE_ASSIGNED_BY_CLASS_ID",
  ROUTE_ASSIGNED_BY_SEMESTER_ID = "ROUTE_ASSIGNED_BY_SEMESTER_ID",
  ROUTE_ASSIGNED_BY_SECTION_ID = "ROUTE_ASSIGNED_BY_SECTION_ID",
}

export enum StudentAcademicPerformance {
  comparative = "Comparative Performance",
  testWise = "Testwise Performance",
  classWise = "Class Wise Performance",
  marksDetails = "Marks Details",
  subjectWise = "subjectwise",
  studentsStats = "studentsStats",
}

export enum SwAcctLdgrOrderField {
  LDGR_DESC = "LDGR_DESC",
  GR_LDGR_DESC = "GR_LDGR_DESC",
}

export enum SwAcctLdgrQueryType {
  ALL_SW_ACCT_LDGRS = "ALL_SW_ACCT_LDGRS",
  SW_ACCT_LDGRS_BY_GRP_DESC = "SW_ACCT_LDGRS_BY_GRP_DESC",
}
export enum InstitutionPrintConfig {
  PRINT_ADMISSION_NUMBER = "PRINT_ADMISSION_NUMBER",
  PRINT_CASH_BANK_DETAILS = "PRINT_CASH_BANK_DETAILS",
  PRINT_CLASS_NAME = "PRINT_CLASS_NAME",
  PRINT_CUSTOMER_NAME = "PRINT_CUSTOMER_NAME",
  PRINT_FATHER_NAME = "PRINT_FATHER_NAME",
  PRINT_FEE_BALANCE = "PRINT_FEE_BALANCE",
  PRINT_INST_LOGO = "PRINT_INST_LOGO",
  PRINT_INST_NAME = "PRINT_INST_NAME",
  PRINT_INST_ADDRESS = "PRINT_INST_ADDRESS",
  PRINT_NARRATIONS = "PRINT_NARRATIONS",
  PRINT_REGISTER_NUMBER = "PRINT_REGISTER_NUMBER",
  PRINT_SECTION_NAME = "PRINT_SECTION_NAME",
  PRINT_COMMON_TITLE = "PRINT_COMMON_TITLE",
  PRINT_RECEIPT_FOOTER = "PRINT_RECEIPT_FOOTER",
  PRINT_RECEIPT_FOOTER_2 = "PRINT_RECEIPT_FOOTER_2",
  PRINT_NUMBER_COPIES = "PRINT_NUMBER_COPIES",
  PRINT_RECEIPT_HEADER = "PRINT_RECEIPT_HEADER",
  PRINT_ENABLE_COMMON_TITLE = "PRINT_ENABLE_COMMON_TITLE",
  PRINT_INST_STD_LABEL = "PRINT_INST_STD_LABEL",
  PRINT_RECEIPT_BY_DEFAULT = "PRINT_RECEIPT_BY_DEFAULT",
}

export enum AttendanceStatus {
  P = "P",
  A = "A",
}

export enum DeviceType {
  Installed = "Installed",
  Not_Installed = "Not_Installed",
}
export enum LoginStatus {
  Created = "Created",
  Not_Created = "Not_Created",
}
export enum LoginType {
  Created = "Created",
  Not_Created = "Not_Created",
}
export enum ReportsType {
  BIO_DATA = "BIO_DATA",
  MAILING = "MAILING",
}

export enum StudentDetailedReportType {
  STUDENT_LIST = "STUDENT_LIST",
  STUDENT_BIO_DATA = "STUDENT_BIO_DATA",
  MAILING_LABEL = "MAILING_LABEL",
  RELIGION_WISE = "RELIGION_WISE",
  CASTE_WISE = "CASTE_WISE",
  AGE_WISE = "AGE_WISE",
  YEAR_OF_JOINING = "YEAR_OF_JOINING",
}
export const enum DashBoardFor {
  GENERAL = "GENERAL",
  PROPRIETOR = "PROPRIETOR",
}
export const enum ChannelType {
  INDIVIDUAL = "INDIVIDUAL",
  INSTITUTION = "INSTITUTION",
  SECTION = "SECTION",
  SEMESTER = "SEMESTER",
  CLASS = "CLASS",
  BRANCH = "BRANCH",
  DEPARTMENT = "DEPARTMENT",
}
export const enum PageLabel {
  TRUE = "TRUE",
  FALSE = "FALSE",
}

export const enum FresherExisting {
  FRESHER = "FRESHER",
  EXISTING = "EXISTING",
}

export const enum VehicleDetailsPreviewTabs {
  BASIC_DETAILS = "BASIC_DETAILS",
  PURCHASE_DETAILS = "PURCHASE_DETAILS",
  CURRENT_DETAILS = "CURRENT_DETAILS",
  PREVIOUS_DETAILS = "PREVIOUS_DETAILS",
}
export const enum PreviewVehiclePreviousDetails {
  INSURANCE = "INSURANCE",
  FITNESS = "FITNESS",
  ROADTAX = "ROADTAX",
  EMISSIONTEST = "EMISSIONTEST",
}
export const enum TransportMasters {
  VEHICLE_TYPE = "VEHICLE TYPE",
  FUEL_USED = "FUEL USED",
  TYRES_USED = "TYRES USED",
  SEATING_CAPACITY = "SEATING CAPACITY",
  MANUFACTURER = "MANUFACTURER",
  MANUFACTURER_MODEL = "MANUFACTURER MODEL",
  NUMBER_PLATE = "NUMBER PLATE",
  VEHICLE_BODY_TYPE = "VEHICLE BODY TYPE",
  TYRE_SIZE = "TYRE SIZE",
  DESIGNATION = "DESIGNATION",
  LICENSE_TYPE = "LICENSE TYPE",
  FUEL_BRAND = "FUEL BRAND",
  ITEM_GROUP = "ITEM GROUP",
  ITEM_CATEGORY = "ITEM CATEGORY",
  UNIT_OF_MEASUREMENT = "UNIT OF MEASUREMENT",
}

export const enum InstDetails {
  DEFAULT_DEPT_LABEL = "DEFAULT_DEPT_LABEL",
  DEFAULT_BRANCH_LABEL = "DEFAULT_BRANCH_LABEL",
  DEFAULT_CLASS_LABEL = "DEFAULT_CLASS_LABEL",
  DEFAULT_SEMESTER_LABEL = "DEFAULT_SEMESTER_LABEL",
  DEFAULT_SECTION_LABEL = "DEFAULT_SECTION_LABEL",
  DEFAULT_CATEGORY_LABEL = "DEFAULT_CATEGORY_LABEL",
}
export const enum STAFFDETAILS {
  BASIC_DETAILS = "BASIC DETAILS",
  STAFF_DOCUMENTS = "STAFF DOCUMENTS",
}

export const enum MsgChannelType {
  MSGS_BY_CHANNEL_ID = "MSGS_BY_CHANNEL_ID",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  MY_CAMPUS_FEEDS = "MY_CAMPUS_FEEDS",
}

export const enum AttendanceOverViewPageFor {
  ADMIN = "ADMIN",
  TEACHER = "TEACHER",
  DAILY_ACTIVITIES = "DAILY ACTIVITIES",
}

export const enum AcctOnlinePaymentQueryType {
  ON_PY_RECORDS = "ON_PY_RECORDS",
}

export const enum TimeComparison {
  EQUAL = "EQUAL",
  GREATER = "GREATER",
  LESSER = "LESSER",
}

export const enum DailyDiaryQuestions {
  QUESTION_1 = "QUESTION_1",
  QUESTION_2 = "QUESTION_2",
  QUESTION_3 = "QUESTION_3",
  QUESTION_4 = "QUESTION_4",
  QUESTION_5 = "QUESTION_5",
  QUESTION_6 = "QUESTION_6",
  QUESTION_7 = "QUESTION_7",
  QUESTION_8 = "QUESTION_8",
  QUESTION_9 = "QUESTION_9",
  QUESTION_10 = "QUESTION_10",
}

export const enum ConfigListKey {
  ACD_TEST_MARKS_DERIVED_METHOD = "ACD_TEST_MARKS_DERIVED_METHOD",
}

export const enum TestMarksDerivedMethod {
  AVERAGE_OF_BEST_OF = "Average of Best of",
}

export const enum AcctStdConcessionQueryType {
  STD_CONCESSIONS = "STD_CONCESSIONS",
  ADD_CONCESSION_LOG = "ADD_CONCESSION_LOG",
  EDIT_CONCESSION_LOG = "EDIT_CONCESSION_LOG",
}
export const enum AccessType {
  DEV = "DEV",
  PROD = "PROD",
}

export const enum TestWiseReportPageType {
  CLASS_WISE = 0,
  SUB_WISE = 1,
}
export const enum Levels {
  LEVEL_1 = "LEVEL_1",
  LEVEL_2 = "LEVEL_2",
  LEVEL_3 = "LEVEL_3",
  LEVEL_4 = "LEVEL_4",
  LEVEL_5 = "LEVEL_5",
}

export const enum ToppersType {
  FIRST_RANK = "FIRST_RANK",
  SECOND_RANK = "SECOND_RANK",
  THIRD_RANK = "THIRD_RANK",
}

export const enum TransactionStatusType {
  PROCEEDING = "PROCEEDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export const enum MarksFrom {
  SUBJECT = "SUBJECT",
  COMBINATION = "COMBINATION",
}
export const enum FileUploadParams {
  INST_LOGO = "INST_LOGO",
  PRINCIPAL_SIGNATURE = "PRINCIPAL_SIGNATURE",
  CHAIRMAN_SIGNATURE = "CHAIRMAN_SIGNATURE",
  PHOTO_1 = "PHOTO_1",
  PHOTO_2 = "PHOTO_2",
  PHOTO_3 = "PHOTO_3",
  BRANCH_LOGO = "BRANCH_LOGO",
}
// export const COLUMN_VISIBILITY_KEY_FOR_ACCOUNTS =
//   "COLUMN_VISIBILITY_KEY_FOR_ACCOUNTS";
// export const COLUMN_VISIBILITY_KEY_FOR_ACADEMICS =
//   "COLUMN_VISIBILITY_KEY_FOR_ACADEMICS";
// export const COLUMN_VISIBILITY_KEY_FOR_ADMISSIONS =
//   "COLUMN_VISIBILITY_KEY_FOR_ADMISSIONS";
// export const COLUMN_VISIBILITY_KEY_FOR_LIBRARY =
//   "COLUMN_VISIBILITY_KEY_FOR_LIBRARY";

export const enum ColumnVisibilityFor {
  FOR_ACCOUNTS = "FOR_ACCOUNTS",
  FOR_ACADEMICS = "FOR_ACADEMICS",
  FOR_ADMISSIONS = "FOR_ADMISSIONS",
  FOR_LIBRARY = "FOR_LIBRARY",
  FOR_PASS_ACD = "FOR_PASS_ACD",
  FOR_PASS_ACC = "FOR_PASS_ACC",
  FOR_LOGIN_STATUS = "FOR_LOGIN_STATUS",
  FOR_ALUMINI = "FOR_ALUMINI",
  RESERVATION = "RESERVATION",
  FOR_DELETE = "FOR_DELETE",
  FOR_PRINT = "FOR_PRINT",
  FEE_BALANCE_REPORT = "FEE_BALANCE_REPORT",
  DEVICE_DETAILS = "DEVICE_DETAILS",
  BOOK_SEARCH = "BOOK_SEARCH",
  FOR_AGENTS = "FOR_AGENTS",
  FOR_AGENTS_ASSOCIATION = "FOR_AGENTS_ASSOCIATION",
  SELECT_STD_AGENTS = "SELECT_STD_AGENTS",
}

export const enum SubjectType {
  CORE = "Core",
  ELECTIVE = "Elective",
  LANGUAGE = "Language",
}
export enum attendanceReportType {
  ABSENT_LIST = "ABSENT_LIST",
  SHORTAGE_OF_ATT = "SHORTAGE_OF_ATT",
  OVERALL = "OVERALL",
  DAILY_ATT = "DAILY_ATT",
  MONTHLY = "MONTHLY",
  MARKED = "MARKED",
}

export const enum TeacherType {
  GLOBAL_TEACHER = "GLOBAL_TEACHER",
  CLASS_TEACHER = "CLASS_TEACHER",
  SUBJECT_TEACHER = "SUBJECT_TEACHER",
  ONLY_TEACHER = "ONLY_TEACHER",
}

export const enum ChatConfigKeys {
  ENABLE_MY_CAMPUS_FEEDS = "ENABLE_MY_CAMPUS_FEEDS",
  ENABLE_ANNOUNCEMENTS = "ENABLE_ANNOUNCEMENTS",
  ENABLE_CLASS_LEVEL_CHANNELS = "ENABLE_CLASS_LEVEL_CHANNELS",
  ENABLE_INDIVIDUAL_CHANNELS = "ENABLE_INDIVIDUAL_CHANNELS",
  ENABLE_TO_SEND_BIRTHDAY_MESSAGES = "ENABLE_TO_SEND_BIRTHDAY_MESSAGES",
}

export enum HRS_MINS {
  HRS = "HRS",
  MINS = "MINS",
}

export const enum DocumentAcknowledgement {
  STD_DOC_ACK = "STD_DOC_ACK",
  GENERAL = "GENERAL",
  MODAL = "MODAL",
}

export enum SortByFilterType {
  STUDENT = "STUDENT",
}

export enum StudentOrderFields {
  FIRST_NAME = "FIRST_NAME",
  MIDDLE_NAME = "MIDDLE_NAME",
  LAST_NAME = "LAST_NAME",
  STD_REG_NO = "STD_REG_NO",
  STD_ADM_NO = "STD_ADM_NO",
  STD_DOB = "STD_DOB",
  DEPT_ID = "DEPT_ID",
  ACD_DEPT_ID = "ACD_DEPT_ID",
  BRANCH_ID = "BRANCH_ID",
  ACD_BRANCH_ID = "ACD_BRANCH_ID",
  CLASS_ID = "CLASS_ID",
  ACD_CLASS_ID = "ACD_CLASS_ID",
  SEMESTER_ID = "SEMESTER_ID",
  ACD_SEMESTER_ID = "ACD_SEMESTER_ID",
  SECTION_ID = "SECTION_ID",
  ACD_SECTION_ID = "ACD_SECTION_ID",
  CATEGORY_ID = "CATEGORY_ID",
  STD_ROLL_NO = "STD_ROLL_NO",
}

export const enum ExcelReportPageType {
  STUDENT = "STUDENT",
  VOUCHER = "VOUCHER",
}
export const enum InstitutionType {
  SCHOOL = "SCH",
}

export const enum PrimaryContact {
  FATHER = "FATHER",
  MOTHER = "MOTHER",
  GUARDIAN = "GUARDIAN",
  STUDENT = "STUDENT",
}
