import React, { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../../utils/UtilFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";
import { useMutation } from "@apollo/client";
import { GetAcdSubjectGroups } from "../../../../queries/customallocation/groups/queries";
import {
  AcdSubjectGroupDetails,
  AcdSubjectGroupEdge,
} from "../../../../Types/Groups/queries";
import {
  DELETE,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import Modal from "react-modal";
import {
  AccountingLedgerModalStyles,
  StudentModalStyles,
  ViewPickedSubjectModalStyles,
} from "../../../../styles/ModalStyles";
import View from "./View";
import EditAssociated from "./Add";
import useGroups from "../customHooks/useGroups";
import { DeleteAcdSubjectGroup } from "../../../../queries/customallocation/groups/mutations";
import {
  DeleteAcdSubjectGroupData,
  DeleteAcdSubjectGroupVars,
} from "../../../../Types/Groups/mutations";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import MessageModal from "../../../../pages/MessageModal";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
const Index = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();

  const [searchData, setSearchData] = useState("");
  const [groups, setGroups] = useState<AcdSubjectGroupEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string>("");
  const [deleteId, setDeleteId] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [view, setView] = useState(false);
  const [message, setMessage] = useState(emptyMessageType);
  const [subjects, setSubjects] = useState<AcdSubjectGroupDetails[]>([]);
  const { dispatch } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();

  const [DeleteGroup] = useMutation<
    DeleteAcdSubjectGroupData,
    DeleteAcdSubjectGroupVars
  >(DeleteAcdSubjectGroup, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const { data, fetchMore, loading, error } = useGroups(
    searchData,
    ROWS_PER_PAGE
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdSubjectGroups.edges;
            const pageInfo = fetchMoreResult.GetAcdSubjectGroups.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdSubjectGroups.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdSubjectGroups: {
                edges: [...groups, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcdSubjectGroups.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdSubjectGroups.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newGroup) => {
          const filteredStudent = groups.find(
            (group) => group.node.id === newGroup.node.id
          );
          if (filteredStudent) {
            return {
              ...newGroup,
              node: {
                ...newGroup.node,
              },
            };
          }
          return newGroup;
        });
        setGroups(updatedNewData);
      } else {
        setGroups(newData);
      }
      setHasNextPage(data.GetAcdSubjectGroups.pageInfo.hasNextPage);
      setEndCursor(data.GetAcdSubjectGroups.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteGroup({
      variables: {
        id,
        inst_id: InstId!,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdSubjectGroups,
          variables: {
            token,
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.GROUP_NAME,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteAcdSubjectGroup) {
        setMessage({
          flag: true,
          message: `Group deleted successfully`,
          operation: Operation.CREATE,
        });
      }
    });
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>List of Groups with Associated Subjects</Title>
      <div className="groups-list">
        <div className="row g-0">
          <div className="col-2">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col"></div>
          <div className="col-1 flex-end">
            <Button
              mode="addnew"
              autoFocus
              onClick={() =>
                navigate(`/${InstId}/academics/customallocation/groups/add`)
              }
            />
          </div>
        </div>
        <div className="groups-list__frame">
          <TableContainer
            className="groups-list__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{TableHeaders.SLNO}</TableCell>
                  <TableCell>Group Names</TableCell>
                  <TableCell>Subject Names and Subject Code</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((item, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        className="groups-list__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        className="groups-list__table--name"
                        onClick={() => {
                          setSubjects(item.node.subj_group_details);
                          setView(!view);
                        }}
                      >
                        {item.node.group_name}
                      </TableCell>
                      <TableCell>
                        {
                          <ul>
                            {item.node.subj_group_details.map((subj) => {
                              return (
                                <li>
                                  {`${subj.subject_details.subj_desc} `}
                                  <span className="font-grey">
                                    ({subj.subject_details.subj_code})
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        }
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="groups-list__table--actions"
                      >
                        <img
                          src={Edit}
                          alt=""
                          onClick={() => {
                            dispatch({
                              type: payloadTypes.SET_ACD_SUB_GROUP_ID,
                              payload: { acdGrpId: item.node.id },
                            });
                            setEdit(!edit);
                          }}
                        />
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => {
                            setDeleteId(item.node.id);
                            setSubjects(item.node.subj_group_details);
                            setDeleteModal(!deleteModal);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
          <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        isOpen={view}
        ariaHideApp={false}
        style={ViewPickedSubjectModalStyles}
      >
        <View
          subjects={subjects}
          setModal={setView}
          operation={Operation.VIEW}
        />
      </Modal>
      <Modal isOpen={edit} ariaHideApp={false} style={StudentModalStyles}>
        <EditAssociated pageType={PageFor.MODAL} setModal={setEdit} />
      </Modal>
      <Modal
        isOpen={deleteModal}
        ariaHideApp={false}
        style={AccountingLedgerModalStyles}
      >
        <div className="delete-groups">
          <View
            setModal={setDeleteModal}
            operation={Operation.DELETE}
            subjects={subjects}
          />
          <span>Do you really want to delete these records?</span>
          <br />
          <span>
            Please enter <b className="nodata">'DELETE'</b> to confirm the
            deletion.
          </span>
          <Input
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.target.value);
            }}
          />
          <Button
            autoFocus
            disabled={inputValue !== DELETE}
            onClick={() => {
              handleDelete(deleteId);
              setInputValue("");
            }}
            mode="delete"
          />
          <Button
            onClick={() => {
              setInputValue("");
              setDeleteModal(!deleteModal);
            }}
            mode="cancel"
          />
        </div>
      </Modal>
      <MessageModal
        handleClose={() => {
          setSubjects([]);
          setDeleteId(0);
          setMessage(emptyMessageType);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;
