import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import { generalTypes, msgType } from "../../../../utils/Form.types";

import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";

import Input from "../../../../components/common/Input/Index";

import { AddInstBranch } from "../../../../queries/institution/mutations/new";
import {
  ReOrderBranch,
  updateInstBranch,
} from "../../../../queries/institution/mutations/update";
import InstitutionTabs from "../../routes/InstitutionTabs";

import { Form, Formik } from "formik";
import { branchValidation } from "../../../../utils/validationRules";
import { Operation, PageNumbers } from "../../../../utils/Enum.types";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import {
  removeMoreSpace,
  reOrderProcess,
} from "../../../../utils/UtilFunctions";
import {
  BranchDetailsByNode,
  ByDeptIDs,
} from "../../../../queries/institution/branch/list/byid";
import { ByNode as DeptByDeptId } from "../../../../queries/institution/department/list/byid";
import { ByClassIds } from "../../../../queries/institution/sem/list/byid";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { InstitutionDetailsFormLabelsType } from "../../../../Types/Institution/json";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../../styles/ModalStyles";
import { ByBranchIds } from "../../../../queries/institution/classes/list/byid";

import { Breadcrumbs } from "@mui/material";
import LoadingModal from "../../../../pages/LoadingModal";
import Edit from "../../../../images/EditProfile.svg";
import DeleteImg from "../../../../images/Delete.svg";
import { NavLink } from "react-router-dom";
import { DeleteInstBranchById } from "../../../../queries/institution/mutations/delete";

import DeleteModal from "../../../../pages/DeleteModal";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import Settings from "../../../../images/Settings.svg";
import ConfigurationSettings from "../../configurations/general/Index";
import Close from "../../../../images/Close.svg";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import {
  BranchesList,
  BranchesListDataByDeptId,
  BranchListVarsByDeptId,
} from "../../../../Types/Student";
import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import TreeView from "../TreeView";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";

const { BranchFormlabels } = require("../../../../json/config.json");

const Branch = () => {
  //useParam, GlobalStates and useNavigate
  const { InstId, deptId } = useParams();

  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();

  const [addModal, setAddModal] = useState(false);
  const { USE_ADMISSIONS } = useUserRightsByEmpId();
  //useStates for Data
  const [formData, setFormData] = useState<generalTypes>({
    branch_desc: "",
    shortdescription: "",
    indexnumber: "0",
  });

  const [branchId, setBranchid] = useState(0);

  // eslint-disable-next-line
  const [item, setitems] = useState<BranchesList[]>([]);
  const [localItems, setLocalItems] = useState<BranchesList[]>([]);

  //useStates for Flags
  const [deleteModal, setDeleteModal] = useState(false);
  const [reOrder, setReOrder] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  //Query
  const [GetDepartments, { data: DeptByNodeId, loading: DeptByNodeIdLoading }] =
    useLazyQuery(DeptByDeptId, {
      variables: { dept_id: deptId, token },
    });
  //useRefs
  const nameRef = document.getElementsByName(
    "branch_desc"
  )[0] as HTMLInputElement;

  //flags for sucess model
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [configurationModal, setConfigurationModal] = useState(false);

  //Lazy Queries
  const [ClassData] = useLazyQuery(ByBranchIds);
  const [SemesterData] = useLazyQuery(ByClassIds);
  const [BranchDataById, { data, loading }] = useLazyQuery(BranchDetailsByNode);

  //Mutations
  const [NewBranch, { loading: creationLoading }] = useMutation(AddInstBranch, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateBranch, { loading: updationLoading }] = useMutation(
    updateInstBranch,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [ReOrderbranch, { loading: reorderLoading }] = useMutation(
    ReOrderBranch,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [DeleteBranch, { loading: deleteLoading }] = useMutation(
    DeleteInstBranchById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //customHooks
  const [GetBranchData, { data: branchData, loading: branchLoading }] =
    useLazyQuery<BranchesListDataByDeptId, BranchListVarsByDeptId>(ByDeptIDs);

  const {
    USE_DEPARTMENT_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.BRANCH_PAGE);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };
  const HandleSubmit = async () => {
    await NewBranch({
      variables: {
        token,
        input: {
          inst_id: InstId,
          dept_id: deptId,
          branch_desc: removeMoreSpace(formData.branch_desc),
          branch_short_desc: removeMoreSpace(formData.shortdescription),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: ByDeptIDs,
          variables: {
            token,
            dept_ids: [Number(deptId)!],
          },
        },
        {
          query: GetInstMasterData,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Branch Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setFormData({
      branch_desc: "",
      shortdescription: "",
      indexnumber: "0",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const HandleEdit = async () => {
    await UpdateBranch({
      variables: {
        token,
        branch_id: branchId,
        branch_desc: removeMoreSpace(formData.branch_desc),
        branch_short_desc: removeMoreSpace(formData.shortdescription),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: ByDeptIDs,
          variables: {
            token,
            dept_ids: [deptId!],
          },
        },
        {
          query: BranchDetailsByNode,
          variables: { branch_id: branchId, token },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Branch Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setFormData({
      branch_desc: "",
      shortdescription: "",
      indexnumber: "0",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const HandleDelete = (branch_id: number) => {
    setDeleteModal(!deleteModal);
    DeleteBranch({
      variables: {
        token,
        branch_id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: ByDeptIDs,
          variables: {
            token,
            dept_ids: [Number(deptId)!],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Branch Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setOperation(Operation.CREATE);
  };
  const handleSaveOrder = () => {
    // eslint-disable-next-line
    ReOrderbranch({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.id,
          branch_idx: index + 1,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: ByDeptIDs,
          variables: {
            token,
            dept_ids: [Number(deptId!)],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re-Order Successful",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setReOrder(!reOrder);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };

  const handleClear = () => {
    setFormData({
      branch_desc: "",
      shortdescription: "",
      indexnumber: "0",
    });
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };

  useEffect(() => {
    if (!loading && data && operation === Operation.UPDATE) {
      const { branch_desc, branch_short_desc } = data.node ?? "";
      setFormData({
        branch_desc: branch_desc,
        shortdescription: branch_short_desc,
        indexnumber: "0",
      });
    }
  }, [loading, data, operation]);
  useEffect(() => {
    if (branchData && !branchLoading)
      setLocalItems(
        branchData.GetBranchesByDeptIds
          ? branchData.GetBranchesByDeptIds.filter((f) => f !== null)
          : []
      );
  }, [branchData, branchLoading]);
  useEffect(() => {
    if (token) {
      GetDepartments();
    }
  }, [token, GetDepartments]);
  useEffect(() => {
    if (token && deptId) {
      GetBranchData({
        variables: {
          token,
          dept_ids: [Number(deptId)!],
        },
      });
    }
  }, [token, GetBranchData, deptId]);

  const { branchLabel } = useInstLabels();

  return (
    <>
      <InstitutionTabs />

      <div className="row g-0">
        <div className="col">
          <Title>{branchLabel}</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb" className="inst-breadcrumbs">
            <NavLink to={`/${InstId}/admissions/department`}>
              {USE_DEPARTMENT_KEY && !DeptByNodeIdLoading && DeptByNodeId
                ? DeptByNodeId.node.dept_desc + " / "
                : null}
            </NavLink>
          </Breadcrumbs>
        </div>
      </div>
      <div className="masterconfig">
        <div className="row g-0 masterconfig__details">
          <div className="col booktype-left h-100">
            <Title variant="subtitle1">List of {branchLabel}</Title>

            <TableContainer className="masterconfig__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {reOrder ? <TableCell></TableCell> : null}
                    {Masters_Table.InstitutionDetails.Branches.Table_Headers.filter(
                      ({ labelName }: TableHeaderProps) =>
                        !(labelName === "Actions" && reOrder)
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TableCell className={th.className}>
                            {th.labelName}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <DragDropContext onDragEnd={handleDragAndDrop}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {localItems?.map((response, index: number) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!reOrder}
                          >
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <TableRow
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                  >
                                    {reOrder ? (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                        id="td-center"
                                        className="institution__table--slno"
                                      >
                                        =
                                      </TableCell>
                                    ) : null}
                                    <TableCell
                                      id="td-center"
                                      {...draggableProvided.dragHandleProps}
                                      className="institution__table--slno"
                                    >
                                      {index + 1}
                                    </TableCell>

                                    {USE_CLASS_KEY ? (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                        onClick={() =>
                                          navigate(`${response.id}/class`)
                                        }
                                        className="institution__table--name"
                                      >
                                        {response?.branch_desc}
                                      </TableCell>
                                    ) : USE_SEMESTER_KEY ? (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                        onClick={async () => {
                                          const classResult = await ClassData({
                                            variables: {
                                              token,
                                              branch_id: [response.id!],
                                            },
                                          });
                                          navigate(
                                            `${response.id}/class/${classResult.data.GetClassNamesByBranchIds[0].id}/semester`
                                          );
                                        }}
                                      >
                                        {response?.branch_desc}
                                      </TableCell>
                                    ) : USE_SECTION_KEY ? (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                        onClick={async () => {
                                          const classResult = await ClassData({
                                            variables: {
                                              branch_id: [response.id!],
                                              token,
                                            },
                                          });
                                          const semesterResult =
                                            await SemesterData({
                                              variables: {
                                                token,
                                                class_id: [
                                                  classResult.data
                                                    .GetClassNamesByBranchIds[0]
                                                    ?.id,
                                                ],
                                              },
                                            });
                                          navigate(
                                            `${response.id}/class/${classResult.data.GetClassNamesByBranchIds[0].id}/semester/${semesterResult.data.GetSemesterNamesByClassIds[0].id}/section`
                                          );
                                        }}
                                      >
                                        {response.branch_desc}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        {response.branch_desc}
                                      </TableCell>
                                    )}
                                    {reOrder === false ? (
                                      <TableCell
                                        id="td-center"
                                        {...draggableProvided.dragHandleProps}
                                        className="institution__table--action"
                                      >
                                        <>
                                          {USE_ADMISSIONS.details?.edit && (
                                            <img
                                              src={Edit}
                                              alt="/"
                                              onClick={() => {
                                                setBranchid(response.id);
                                                BranchDataById({
                                                  variables: {
                                                    branch_id: response.id,
                                                    token,
                                                  },
                                                });
                                                setOperation(Operation.UPDATE);
                                                setAddModal(!addModal);
                                              }}
                                            />
                                          )}
                                          {USE_ADMISSIONS.details?.delete && (
                                            <img
                                              src={DeleteImg}
                                              alt="/"
                                              onClick={() => {
                                                setBranchid(response.id);
                                                setDeleteModal(!deleteModal);
                                              }}
                                            />
                                          )}
                                        </>
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                </React.Fragment>
                              );
                            }}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </div>

          <div className="col booktype-right h-100">
            <TreeView />
          </div>
        </div>
        <div className="masterconfig__buttons">
          <Button mode="addnew" onClick={() => setAddModal(!addModal)} 
            autoFocus/>

          {localItems?.length > 1 ? (
            reOrder ? (
              <>
                <Button
                  mode="save-order"
                  type="button"
                  onClick={handleSaveOrder}
                />

                <Button mode="clear" onClick={() => setReOrder(!reOrder)} />
              </>
            ) : (
              <Button
                mode="reorder"
                type="button"
                onClick={() => setReOrder(!reOrder)}
              />
            )
          ) : null}

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={branchId}
      />
      <LoadingModal
        flag={
          creationLoading || updationLoading || deleteLoading || reorderLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.BRANCH_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Formik
              initialValues={formData}
              validationSchema={branchValidation}
              onSubmit={
                operation === Operation.UPDATE ? HandleEdit : HandleSubmit
              }
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    <Title variant="subtitle1">
                      {operation === Operation.UPDATE
                        ? `Update ${branchLabel}`
                        : `Add ${branchLabel}`}
                    </Title>

                    {BranchFormlabels.map(
                      (
                        label: InstitutionDetailsFormLabelsType,
                        index: React.Key
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}

                    <div className="masterconfig__buttons">
                      <Button mode="save" type="submit" />

                      <Button
                        mode="clear"
                        type="button"
                        onClick={handleClear}
                      />

                      <Button
                        mode="cancel"
                        onClick={() => {
                          setAddModal(!addModal);
                          handleClear();
                        }}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image ">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setAddModal(!addModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Branch;
