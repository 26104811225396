import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../../utils/constants";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
  InstitutionType,
  Operation,
  StudentReportType,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";

import Delete from "../../../../../images/Delete.svg";

import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import useStudentsByNodeIds from "../../../../../customhooks/useStudentsByNodeIds";
import { useMutation } from "@apollo/client";
import {
  AddAcdStudentComboDetails,
  DeleteAcdStudentComboDetails,
} from "../../../../../queries/customallocation/combination/mutations";
import {
  AddAcdStudentComboDetailsData,
  AddAcdStudentComboDetailsVars,
} from "../../../../../Types/Combinations/mutations";
import MessageModal from "../../../../../pages/MessageModal";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useToken from "../../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { ComboStdAssociData } from "../../../../../Types/Combinations/queries";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import useAcdStudentsData, {
  StudentEdges,
} from "../../../hooks/useAcdStudentsData";
import DeleteModal from "../../../../../pages/DeleteModal";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import useSwConfigInstType from "../../../hooks/useSwConfigInstType";
import { TableHeaderProps } from "../../../../../utils/types";
import useAcademicTableJson from "../../../json/useAcademicTable";
interface props {
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudentIds: Set<number>;
  comboDetails: ComboStdAssociData | null;
}

const SelectedStudents = ({
  setDrawer,
  selectedStudentIds,
  comboDetails,
}: props) => {
  const { combinationId, InstId, allocationId } = useParams();
  const { institutionType } = useSwConfigInstType();

  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [searchStudent, setSearchStudent] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState(emptyMessageType);
  const [stdComboId, setStdComboId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { Selected_Students } = useAcademicTableJson();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const subjectAllocLevel = configData.data
    ? configData.data.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;

  const {
    InstConfigDetails: { data: instDetailsData },
  } = useInstConfigByEntryId(allocationId!);

  const [AddAcdSubCombo] = useMutation<
    AddAcdStudentComboDetailsData,
    AddAcdStudentComboDetailsVars
  >(AddAcdStudentComboDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteAcdSubCombo] = useMutation(DeleteAcdStudentComboDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const { data, loading: nodeLoading } =
    useStudentsByNodeIds(selectedStudentIds);

  const allocationLevel =
    configData.data && configData.data.GetSwConfigVariables.length
      ? configData.data.GetSwConfigVariables[0].config_string_value
      : EMPTY_STRING;
  const getAllotedId = () => {
    if (instDetailsData && configData) {
      switch (allocationLevel) {
        case AcdSubjectAllocationData.BRANCH:
          return instDetailsData.GetAcdInstConfigNames.acd_branch_id;
        case AcdSubjectAllocationData.CLASS:
          return instDetailsData.GetAcdInstConfigNames.acd_class_id;
        case AcdSubjectAllocationData.SEMESTER:
          return instDetailsData.GetAcdInstConfigNames.acd_semester_id;
        case AcdSubjectAllocationData.SECTION:
          return instDetailsData.GetAcdInstConfigNames.acd_section_id;
        default:
          return 0;
      }
    } else return 0;
  };

  const {
    AcademicsStudentData: { data: studentData, loading, fetchMore },
  } = useAcdStudentsData(
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_dept_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_branch_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_class_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_semester_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_section_id : 0,
    0,
    ROWS_PER_PAGE,
    getAllotedId(),
    searchStudent,
    StudentReportType.BY_COMBO_ID_AT_ALLOTED_LEVEL,
    0,
    0,
    0
  );

  const dynamicHeaders: TableHeaderProps[] =
    Selected_Students.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        if (header.field === "action") {
          return (
            <>
              <img
                src={Delete}
                alt=""
                onClick={() => {
                  // setDeleteModal(!deleteModal);
                  handleDelete(params.row.std_id);
                }}
              />
            </>
          );
        }
        return params.value;
      },
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
  ];
  const handleSubmit = () => {
    AddAcdSubCombo({
      variables: {
        alloted_level: subjectAllocLevel,
        allotted_id: allocationId!,
        acd_subj_combo_id: combinationId!,
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        inst_id: InstId!,
        student_ids: [...selectedStudentIds],
        token,
        user_details,
      },
    }).then(({ data }) => {
      if (data && data.AddAcdStudentComboDetails) {
        setMessage({
          flag: true,
          message: "Students added successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleDelete = (id: number) => {
    DeleteAcdSubCombo({
      variables: {
        alloted_level: subjectAllocLevel,
        allotted_id: allocationId!,
        acd_subj_combo_id: Number(comboDetails?.acd_subj_combo_id!),
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        inst_id: InstId!,
        student_ids: [id],
        token,
        user_details,
      },
    }).then(({ data }) => {
      if (data && data.DeleteAcdStudentComboDetails) {
        setMessage({
          flag: true,
          message: "Students Deleted successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDrawer(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents
                  ? prevResult.GetAcdStudents.edges.filter(
                      ({ node: { id } }) =>
                        newEdges.findIndex(
                          ({ node: { id: newId } }) => newId === id
                        ) !== -1
                    )
                  : [];

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: studentData
                      ? studentData.GetAcdStudents.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        // eslint-disable-next-line
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (studentData && !loading) {
      const newData = studentData.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });

        setStudents(updatedNewData);
        if (comboDetails)
          setRows(
            updatedNewData.map(({ node }, index) => ({
              id: index + 1,
              std_adm_no: node.std_adm_no,
              std_reg_no: node.std_reg_no,
              std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
              std_father: node.std_father_name,
              std_mobile: node.std_mobile,
              std_status: node.std_status,
              std_id: node.id,
            }))
          );
      } else {
        setStudents(newData);
        if (comboDetails)
          setRows(
            newData.map(({ node }, index) => ({
              id: index + 1,
              std_adm_no: node.std_adm_no,
              std_reg_no: node.std_reg_no,
              std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
              std_father: node.std_father_name,
              std_mobile: node.std_mobile,
              std_status: node.std_status,
              std_id: node.id,
            }))
          );
      }
      setEndCursor(studentData.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [loading, studentData, comboDetails]);

  useEffect(() => {
    if (data && !nodeLoading) {
      setRows(
        data && data.nodes.length
          ? data.nodes.map((node, index) => ({
              id: index + 1,
              std_adm_no: node.std_adm_no,
              std_reg_no: node.std_reg_no,
              std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
              std_father: node.std_father_name,
              std_mobile: node.std_mobile,
              std_status: node.std_status,
              std_id: node.id,
            }))
          : []
      );
    }
  }, [data, nodeLoading]);

  return (
    <>
      <div className="sub-allocation-comb__selected-students">
        <div className="sub-allocation-comb__selected-students--title">
          <Title>Selected Students for confirmation</Title>
          <img src={Close} alt="" onClick={() => setDrawer(false)} />
        </div>
        <b className="sub-allocation-comb__selected-students--b">
          Selected Combination :{" "}
          {comboDetails ? comboDetails.subj_combo_name : EMPTY_STRING}
        </b>
        <Input id="search" placeholder="Search..." />
        <div className={`sub-allocation-comb__selected-students--tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            // onCellClick={(params: GridCellParams, studentId) => {
            //   handleCellClick(params);
            // }}
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button mode="clear" />
        <Button mode="cancel" onClick={() => setDrawer(false)} />
      </div>
      <DeleteModal
        id={stdComboId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
      />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default SelectedStudents;
