import { gql } from "@apollo/client";

export const GetAcdLessonPlannerMain = gql`
  query GetAcdLessonPlannerMain(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $subj_master_id: ID!
    $allotted_level: String!
    $allotted_id: ID!
  ) {
    GetAcdLessonPlannerMain(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      subj_master_id: $subj_master_id
      allotted_level: $allotted_level
      allotted_id: $allotted_id
    ) {
      id
      chapter_name
      total_duration
      total_sub_topic
      subj_master_id
      acd_class_id
      acd_semester_id
      acd_section_id
      sub_topic_details {
        id
        sub_topic_name
        duration_in_mins
        inst_id
        lesson_main_id
        hyper_links
        ref_notes
      }
    }
  }
`;
export const GetAcdLessonSubjectObjectives = gql`
  query GetAcdLessonSubjectObjectives(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $subj_master_id: ID!
    $allotted_level: String!
    $allotted_id: ID!
  ) {
    GetAcdLessonSubjectObjectives(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      subj_master_id: $subj_master_id
      allotted_level: $allotted_level
      allotted_id: $allotted_id
    ) {
      id
      subj_objective
      learning_outcome
      ref_books
      ref_youtube_links
      ref_ppt_links
      case_studies
      research_paper_links
      other_links
      subj_master_id
      inst_id
      acd_yr_id
      acd_class_id
      acd_semester_id
      acd_section_id
    }
  }
`;
export const GetAcdChaperWiseStatus = gql`
  query GetAcdChaperWiseStatus(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $subj_master_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    GetAcdChaperWiseStatus(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      entry_level: $entry_level
      entry_id: $entry_id
      subj_master_id: $subj_master_id
      per_std_subj_allocation: $per_std_subj_allocation
    ) {
      header_data {
        subj_master_id
        total_chapters
        total_duration
        completed_percent
      }
      main_chapter_status {
        lesson_main_id
        lession_main_status_id
        status
        chapter_name
        total_sub_topics
        total_sub_topic_duration
        sub_topic_status {
          status
          sub_topic_name
          duration_in_mins
          lesson_details_id
          hyper_links
          ref_notes
        }
      }
    }
  }
`;
