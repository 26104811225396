import React, { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { TextField } from "@mui/material";
import Avatar from "../../../../../images/Avatar.svg";
import Close from "../../../../../images/Close.svg";
import Route from "../../../../../images/Route.svg";

import Modal from "react-modal";
import { WaiveOffModalStyles } from "../../../../../styles/ModalStyles";
import { Label } from "../../../../../stories/Label/Label";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
} from "../../../../Transport/paginationTypes";
import { GetTransportStudent } from "../../../../Transport/queries/list";
import useToken from "../../../../../customhooks/useToken";
import { toStandardDate } from "../../../../../utils/UtilFunctions";
import { InstitutionType, PageFor } from "../../../../../utils/Enum.types";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../../context/context";
import { EMPTY_STRING } from "../../../../../utils/constants";
import useSwConfigInstType from "../../../../Academics/hooks/useSwConfigInstType";
interface Props {
  pageType: PageFor;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ pageType, setModal }: Props) => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { studentId } = useParams();
  const [payOnline, setPayOnline] = useState(false);
  const { studentData } = useStudentDatabyId();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const { institutionType } = useSwConfigInstType();

  const [GetTransport, { data }] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: Number(studentId)!,
    },
  });
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  useEffect(() => {
    if (token) {
      GetTransport();
    }
  }, [token, GetTransport, studentId]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home />}
      <Title>Transport</Title>
      <div
        className={
          pageType === PageFor.MODAL
            ? "std-login-transport__modal"
            : "std-login-transport"
        }>
        <div className="std-login-transport__details row g-0">
          <div className="col">
            <TextField
              className="std-login-transport__details--textfield"
              label="Admission Number"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={studentData?.data?.nodes[0].std_adm_no}
            />
            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                className="std-login-transport__details--textfield"
                label="Register Number"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={studentData?.data?.nodes[0].std_reg_no}
              />
            )}
            <TextField
              className="std-login-transport__details--textfield"
              label={branchLabel}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={studentData?.data?.nodes[0].branch.branch_desc}
            />
          </div>
          <div className="col">
            <TextField
              className="std-login-transport__details--textfield"
              label="Name"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={
                studentData?.data?.nodes[0].first_name +
                " " +
                studentData?.data?.nodes[0].middle_name +
                " " +
                studentData?.data?.nodes[0].last_name
              }
            />
            <TextField
              className="std-login-transport__details--textfield"
              label="Father Name"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={studentData?.data?.nodes[0].std_father_name}
            />
            <TextField
              className="std-login-transport__details--textfield"
              label={classLabel}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={studentData?.data?.nodes[0].class.class_desc}
            />
          </div>
          <div className="col">
            <TextField
              className="std-login-transport__details--textfield--date"
              label="Date of Birth"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={toStandardDate(studentData?.data?.nodes[0].std_dob!)}
            />
            <TextField
              className="std-login-transport__details--textfield"
              label="Academic Year"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={studentData?.data?.nodes[0].acd_yr.acd_yr}
            />
            {USE_CATEGORY_KEY && (
              <TextField
                className="std-login-transport__details--textfield"
                label={categoryLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={
                  data?.GetTransportStudent?.student_details.category.cat_desc
                }
              />
            )}
          </div>
          <div className="col-1 std-login-transport__details--profile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="std-login-transport__year row g-0">
          <div className="col-2">
            <TextField
              className="std-login-transport__details--textfield"
              label="Financial Year"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={
                state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
              }
            />
          </div>
        </div>

        <div className="std-login-transport__datablock row g-0">
          <div className="col-3 std-login-transport__route">
            <div className="std-login-transport__route--title">
              <img src={Route} alt="/" />

              <Title variant="subtitle1">Your Route</Title>
            </div>

            {data ? (
              <div className="std-login-transport__route--details">
                <TextField
                  className="std-login-transport__route--textfield"
                  label="Bus Route"
                  value={
                    data?.GetTransportStudent?.route_details
                      .route_master_details.route_desc
                  }
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                />
                <TextField
                  className="std-login-transport__route--textfield"
                  label="Route Stage"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  value={
                    data?.GetTransportStudent?.route_details.route_stage_desc
                  }
                />
                <TextField
                  className="std-login-transport__route--textfield"
                  label="Ledger"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                  value={data?.GetTransportStudent?.acct_ldgr_details.ldgr_desc}
                />
                <TextField
                  className="std-login-transport__route--textfield--date"
                  label="Start Date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                />
                <TextField
                  className="std-login-transport__route--textfield--date"
                  label="End Date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  disabled
                />
              </div>
            ) : (
              <>
                <br></br>
                <br></br>
                <b className="nodata">
                  Sorry No Routes has been assigned to this student
                </b>
              </>
            )}
          </div>
        </div>
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModal?.(false)} />
        )}
      </div>
      <Modal
        isOpen={payOnline}
        ariaHideApp={false}
        style={WaiveOffModalStyles}
        onRequestClose={() => setPayOnline(!payOnline)}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1">Warning</Title>
            <div>
              <Label>Need to Integrate With Payment Gateway</Label>
            </div>
            <Button mode="cancel" onClick={() => setPayOnline(!payOnline)} />
          </div>
          <div className="modal-flex__image ">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setPayOnline(!payOnline)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
