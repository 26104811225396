import { useContext, useEffect, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import Add from "../../../images/Add.svg";
import New from "./New";

import { SubjectType } from "../types/subject/Subject";
import List, { defaultEditDetailsType } from "./List";
import { Operation } from "../../../utils/Enum.types";
import Modal from "react-modal";
import { convertMinsToHours } from "../../../utils/UtilFunctions";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import LessonsImport from "./ImportLessons";
import { AttendanceDrawer } from "../../../styles/DrawerStyles";
import AddSubjectDetail from "./AddSubjectDetail";
import { GetAcdLessonSubjectObjectives } from "../queries/lesson_planner/list";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import {
  GetAcdLessonPlannerMainVars,
  GetAcdLessonSubjectObjectivesData,
} from "../types/lessonplanner/queries";
import { AppContext } from "../../../context/context";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
interface Props {
  selectedSubject: SubjectType | null;
}

export interface totalType {
  total_chapters: number;
  total_duration: number;
}
const Create = ({ selectedSubject }: Props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { entryId, InstId } = useParams();
  const [subjectOperation, setSubjectOperation] = useState(Operation.CREATE);
  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(Number(entryId));

  const [mainChapter, setMainChapter] = useState(false);
  const [subjectDetailDrawer, setSubjectDetailDrawer] = useState(false);

  const [importModal, setImportModal] = useState(false);

  const [totals, setTotals] = useState<totalType>({
    total_chapters: 0,
    total_duration: 0,
  });
  const [GetAcdLessonSubjectObjective, { data }] = useLazyQuery<
    GetAcdLessonSubjectObjectivesData,
    GetAcdLessonPlannerMainVars
  >(GetAcdLessonSubjectObjectives, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      allotted_id: alloted.id,
      allotted_level: alloted.level,
      inst_id: InstId!,
      subj_master_id:
        selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
    },
  });
  useEffect(() => {
    if (
      token &&
      state.ActiveAcdYr &&
      alloted.id &&
      alloted.level &&
      selectedSubject
    )
      GetAcdLessonSubjectObjective();
  }, [
    GetAcdLessonSubjectObjective,
    token,
    state.ActiveAcdYr,
    alloted.id,
    alloted.level,
    selectedSubject,
  ]);
  const isSubjectDetailCreated = !!data;

  return (
    <>
      <div className="create-lesson-planner">
        {selectedSubject ? (
          <div className="create-lesson-planner__header">
            <div className="create-lesson-planner__header--selected">
              <b>{selectedSubject.subj_desc}</b>
              <span>( {selectedSubject.subj_code} )</span>
            </div>
            <div className="create-lesson-planner__header--durations">
              <span>Total Chapters -</span>
              <b className="font-blue">{totals.total_chapters}</b>
              <div className="create-lesson-planner__header--separator"></div>
              <span>Total Duration -</span>
              <b className="font-blue">
                {convertMinsToHours(totals.total_duration)}
              </b>
            </div>
            <div className="create-lesson-planner__header--buttons">
              {isSubjectDetailCreated ? (
                <Button
                  mode="view"
                  type="button"
                  onClick={() => {
                    setSubjectDetailDrawer(!subjectDetailDrawer);
                    setSubjectOperation(Operation.VIEW);
                  }}
                >
                  &nbsp; Subject Detail
                </Button>
              ) : (
                <Button
                  mode="addnew"
                  type="button"
                  onClick={() => {
                    setSubjectDetailDrawer(!subjectDetailDrawer);
                    setSubjectOperation(Operation.CREATE);
                  }}
                >
                  &nbsp; Subject Detail
                </Button>
              )}

              <Button
                mode="excel"
                type="button"
                onClick={() => setImportModal(!importModal)}
              >
                Import Template
              </Button>
              <Button onClick={() => setMainChapter(!mainChapter)}>
                <img src={Add} alt="" />
                Create Main Chapter
              </Button>
            </div>
          </div>
        ) : null}
        <List selectedSubject={selectedSubject} setTotals={setTotals} />
      </div>
      <AttendanceDrawer
        anchor="right"
        open={mainChapter}
        onClose={() => setMainChapter(!mainChapter)}
      >
        <New
          setDrawer={setMainChapter}
          operation={Operation.CREATE}
          selectedSubject={selectedSubject}
          editDetails={defaultEditDetailsType}
        />
      </AttendanceDrawer>
      <AttendanceDrawer
        anchor="right"
        open={subjectDetailDrawer}
        onClose={() => setSubjectDetailDrawer(!subjectDetailDrawer)}
      >
        <AddSubjectDetail
          setDrawer={setSubjectDetailDrawer}
          operation={subjectOperation}
          setOperation={setSubjectOperation}
          selectedSubject={selectedSubject}
          editDetails={defaultEditDetailsType}
        />
      </AttendanceDrawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LessonsImport setModalFlag={setImportModal} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Create;
