import React, { useContext, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import { Button } from "../../../../../stories/Button/Button";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from "react-modal";
import { PrintConfigModalStyles } from "../../../../../styles/ModalStyles";
import Preview from "./Preview";
import { StatesContext } from "../GlobalStates/StatesProvider";
import { payloadType } from "../GlobalStates/types";
import { optionsType } from "../../../../../utils/Form.types";
import { ExportProps } from "../Index";
import useExportMutation from "../useExportMutation";
import LoadingModal from "../../../../../pages/LoadingModal";
import MessageModal from "../../../../../pages/MessageModal";
import { emptyMessageType } from "../../../../../utils/constants";
import { Operation } from "../../../../../utils/Enum.types";

const reorder = (list: optionsType[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface Props extends ExportProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  endDate: string;
}
const Initialize = ({ setModal, reportType, endDate }: Props) => {
  const { state, dispatch } = useContext(StatesContext);
  const [loading, setLoading] = useState(false);
  const [localItems, setLocalItems] = useState<optionsType[]>(
    state.selectedFields
  );
  const [preview, setPreview] = useState(false);
  const [message, setMessage] = useState(emptyMessageType);
  const { handleSubmit } = useExportMutation(reportType, endDate);
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = reorder(
      state.selectedFields,
      result.source.index,
      result.destination.index
    );
    setLocalItems(reorderedItems);
    dispatch({
      type: payloadType.FIELDS,
      payload: {
        selectedFields: reorderedItems,
      },
    });
  };

  return (
    <>
      <div className="inst-level-report__initialize">
        <div className="inst-level-report__initialize--title">
          <Title>Export The Fields</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="inst-level-report__initialize--subtitle">
          <span>Re-order the fields to display same in the excel sheet</span>
        </div>
        <div className="inst-level-report__initialize--block">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided) => (
                <ul
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}>
                  {localItems.map((response, index) => (
                    <Draggable
                      key={response.value}
                      draggableId={response.value.toString()}
                      index={index}>
                      {(draggableProvided, snapshot) => (
                        <li
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps} // This allows dragging by the entire item
                          className={snapshot.isDragging ? "dragging" : ""}>
                          =&nbsp; {index + 1}. {response.label}
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Button
          mode="export"
          onClick={async () => {
            setLoading(true);
            try {
              const res = await handleSubmit();

              if (res.flag) {
                setLoading(false);
                setMessage(message);
              }
            } catch (e) {
              setLoading(false);
              setMessage({
                flag: true,
                message: "Failed downloading",
                operation: Operation.NONE,
              });
            }
          }}
        />
        <Button mode="preview" onClick={() => setPreview(!preview)}>
          Parameters
        </Button>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
      <Modal
        isOpen={preview}
        style={PrintConfigModalStyles}
        ariaHideApp={false}>
        <Preview setModalFlag={setPreview} reportType={reportType} />
      </Modal>

      <LoadingModal flag={loading} />
      <MessageModal
        handleClose={() => {
          setMessage(emptyMessageType);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Initialize;
