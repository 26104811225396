import React, { Key, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ParentDataTypes, previewType } from "../../../../utils/Form.types";
import { TextField } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { ParentByNodeId } from "../../../../queries/parent/list";
import { singleNodeVars } from "../../../../Types/Accounting";
import { ParentNode } from "../../../../customhooks/useParentsData";
const { StudentPreviewDetails } = require("../../../../json/config.json");

const ParentDetails = () => {
  const { parentId } = useParams();

  const { token } = useToken();

  const [GetParentDetails, { data: ParentData, loading: ParentLoading }] =
    useLazyQuery<{ node: ParentNode }, singleNodeVars>(ParentByNodeId, {
      variables: {
        id: parentId!,
        token,
      },
    });
  const [formData, setFormData] = useState<ParentDataTypes>({
    first_name: "",
    parent_address: "",
    parent_email: "",
    parent_mobile: "",
    parent_occup: "",
    parent_qualification: "",
    parent_aadhar: "",
    parent_type: "",
  });

  useEffect(() => {
    if (token) {
      GetParentDetails().then(({ data }) => {
        if (data && data.node) {
          const {
            first_name,
            parent_aadhar,
            parent_email,
            parent_mobile,
            parent_occup,
            parent_qualification,
            parent_type,
          } = data.node;
          setFormData({
            first_name: first_name,
            parent_email: parent_email.toLowerCase(),
            parent_mobile: parent_mobile,
            parent_occup: parent_occup,
            parent_qualification: parent_qualification,
            parent_aadhar: parent_aadhar,
            parent_type: parent_type,
          });
        }
      });
    }
  }, [ParentData, ParentLoading, parentId, token, GetParentDetails]);

  return (
    <>
      <div className="row g-0 parent-preview__details">
        <div className="parent-preview__span-3 col">
          {StudentPreviewDetails.ParentPersonalDetails.map(
            (label: previewType, index: Key) => {
              return (
                <React.Fragment key={index}>
                  <TextField
                    className="parent-preview__textfield"
                    label={label.LabelName}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    value={formData[label.inputName]}
                    disabled
                  />
                </React.Fragment>
              );
            }
          )}
        </div>{" "}
        <div className="col-1"></div>
      </div>
    </>
  );
};

export default ParentDetails;
