import { ParsedToken, User } from "firebase/auth";
import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import { Actions } from "./types";
import { ReceiptTypes } from "../utils/Enum.types";
import { optionsType, responseType } from "../utils/Form.types";
import { ListAcademicYears, ListFinancialYears } from "../Types/Accounting";

export type InitialStateType = {
  studentId: number;
  user: User | null;
  InstId: number | string;
  claims: ParsedToken | null;
  // authId: number;
  account_main_id: number;
  aluminiStudentId: number;
  receiptId: number;
  receiptStudentId: number;
  accountLedgerId: number;
  registerType: ReceiptTypes;
  date: string | Date;
  receiptBookName: string;
  bankReconcilationName: string;
  parentId: number;
  testConductId: number;
  staffId: number;
  classificationId: number;
  bookId: number;
  changeOfBranchStudentId: number;
  studentEnquiryId: number;
  employeeId: number;
  subjectId: number;
  leaveLedgerId: number;
  acdDeptId: number;
  acdBranchId: number;
  acdClassId: number;
  acdSemesterId: number;
  acdSectionId: number;
  allotedId: number;
  channelId: number;
  entryId: number;
  entryLevel: string;
  swAcctLdgrId: number;
  empLoginId: number;
  stdAdmNo: string;
  custId: number;
  unknownReceiptId: number;
  unkownVoucherAdjustmentDetailsId: number;
  searchEmployeeId: number;
  campusId: number;
  mkckId: number;
  createdMkckId: number;
  ledger: responseType | null;
  register: optionsType | null;
  eduateId: number;
  channelName: string;
  ActiveFinYr: ListFinancialYears | null;
  ActiveAcdYr: ListAcademicYears | null;
  subUserType: string;
  stdIdForChallan: number;
  enqStdIdForChallan: number;
  passOutStdIdForChallan: number;
  instIdForCampus: number;
  templateId: number;
  acdGrpId: number;
  acdCatId: number;
  acdCombinationId: number;
  userRightsEmpId: number;
  depositId: number;
  formId: number;
  teacherId: number;
  finId: number;
  agentId: number;
  siblingId: number;
};

export const initialState: InitialStateType = {
  studentId: 0,
  account_main_id: 0,
  aluminiStudentId: 0,
  // authId: 0,
  claims: null,
  InstId: 0,
  user: null,
  accountLedgerId: 0,
  bankReconcilationName: "",
  date: "",
  parentId: 0,
  receiptBookName: "",
  receiptId: 0,
  receiptStudentId: 0,
  registerType: ReceiptTypes.NONE,
  testConductId: 0,
  staffId: 0,
  classificationId: 0,
  bookId: 0,
  changeOfBranchStudentId: 0,
  studentEnquiryId: 0,
  employeeId: 0,
  subjectId: 0,
  leaveLedgerId: 0,
  acdBranchId: 0,
  acdClassId: 0,
  acdDeptId: 0,
  acdSectionId: 0,
  acdSemesterId: 0,
  allotedId: 0,
  channelId: 0,
  entryId: 0,
  entryLevel: "",
  swAcctLdgrId: 0,
  empLoginId: 0,
  stdAdmNo: "",
  custId: 0,
  unknownReceiptId: 0,
  unkownVoucherAdjustmentDetailsId: 0,
  searchEmployeeId: 0,
  campusId: 0,
  mkckId: 0,
  createdMkckId: 0,
  ledger: null,
  register: null,
  eduateId: 0,
  channelName: "",
  ActiveFinYr: null,
  ActiveAcdYr: null,
  subUserType: "",
  stdIdForChallan: 0,
  enqStdIdForChallan: 0,
  passOutStdIdForChallan: 0,
  instIdForCampus: 0,
  templateId: 0,
  acdGrpId: 0,
  acdCatId: 0,
  acdCombinationId: 0,
  userRightsEmpId: 0,
  depositId: 0,
  formId: 0,
  teacherId: 0,
  finId: 0,
  agentId: 0,
  siblingId: 0,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});
interface Props {
  children: React.ReactNode;
}
const AppProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ dispatch, state }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
