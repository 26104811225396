import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import Edit from "../../images/Edit.svg";
import Close from "../../images/Close.svg";
import useStudentAdmissionNumber from "../Accounts/hooks/UseStudentAdmissionNumber";
import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../utils/constants";
import {
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
} from "../../utils/Enum.types";
import { AppContext } from "../../context/context";
import { payloadTypes } from "../../context/reducer";
import { responseType } from "../../utils/Form.types";
import { Keys } from "../../utils/Enum.keys";
import Modal from "react-modal";
import { StudentModalStyles } from "../../styles/ModalStyles";
import StudentList from "../Master/Student/List";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../styles/AutocompleteListStyles";

interface Props {
  queryType: StudentReportType;

  onChange?: (newValue: responseType | null) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLDivElement> & {
      defaultMuiPrevented?: boolean;
    }
  ) => void;
}
const StudentSelectLabeledAutocomplete = ({
  queryType,
  onChange,
  onKeyDown,
}: Props) => {
  const [admNo, setAdmNo] = useState("");
  const [studentModal, setStudentModal] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const { studentAddmissionNumber } = useStudentAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    queryType,
    0
  );

  const foundStudent = studentAddmissionNumber.find(
    ({ value }) => value === state.studentId
  );

  return (
    <>
      <LabeledAutocomplete
        options={studentAddmissionNumber}
        value={foundStudent ?? null}
        openOnFocus
        onChange={(e, newValue) => {
          if (newValue) {
            dispatch({
              type: payloadTypes.SET_STUDENT_ID,
              payload: {
                studentId: Number((newValue as responseType).value),
              },
            });
          } else {
            dispatch({
              type: payloadTypes.SET_STUDENT_ID,
              payload: {
                studentId: 0,
              },
            });
            setAdmNo(EMPTY_STRING);
            if (onChange) onChange(newValue as responseType);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === Keys.BACKSPACE) {
            dispatch({
              type: payloadTypes.SET_STUDENT_ID,
              payload: {
                studentId: 0,
              },
            });
          }
          if (onKeyDown) onKeyDown(e);
        }}
        // freeSolo
        autoHighlight
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setAdmNo(e.target.value)}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            fullWidth
            label="Admission Number"
            className={labelClasses.formControlRoot}
          />
        )}
      />

      <img
        className="data-fetch-icon"
        src={Edit}
        alt="/"
        onClick={() => setStudentModal(!studentModal)}
      />
      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              queryType={queryType}
              studentListFor={StudentListFor.GENERAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentSelectLabeledAutocomplete;
