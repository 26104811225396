import Modal from "react-modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useState } from "react";

import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import useAcctVoucherDetailsByDates from "../../hooks/useAcctVoucherDetailsByDates";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { toIsoDate, toStandardDate } from "../../../../utils/UtilFunctions";
import { VoucherQueryTypes } from "../../common/Enum.types";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import InterchangeReceipt from "./InterchangeReceipt";
import { PageFor } from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const StudentAllReceipts = ({ setModalFlag }: Props) => {
  const [searchData, setSearchData] = useState("");

  const [interChangeFeeReceipt, setInterChangeFeeReceipt] = useState(false);

  const { dispatch, state } = useContext(AppContext);
  const { studentFormData } = useStudentDatabyId();
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE,
    toIsoDate(DEFAULT_TIME),
    toIsoDate(DEFAULT_TIME),
    VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS,
    searchData
  );

  return (
    <>
      <Title>Student Fee Receipts</Title>
      <div className="row g-0 ">
        <div className="col-3">
          <Input
            id="search"
            type="text"
            placeholder="Search Fee Ledgers ..."
            value={searchData}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="reorder-feeledger__tableblock">
        <TableContainer className="reorder-feeledger__tableblock--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sl No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Receipt No</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {VoucherDetails.data
                ? VoucherDetails.data.GetAcctVouchers.edges.map(
                    ({ node }, index) => {
                      return (
                        <TableRow
                          onClick={() => {
                            dispatch({
                              type: payloadTypes.SET_RECEPIT_ID,
                              payload: { receiptId: node.id },
                            });

                            setInterChangeFeeReceipt(!interChangeFeeReceipt);
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{toStandardDate(node.v_date)}</TableCell>
                          <TableCell>{node.v_no}</TableCell>
                          <TableCell>{studentFormData.std_name}</TableCell>
                          <TableCell>{node.v_std_amt_total}</TableCell>
                        </TableRow>
                      );
                    }
                  )
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="row g-0">
        <div className="col"></div>
        <div className="col-3 eduate-account-ledger__total">
          <div className="student-total-count">
            Total Ledgers:&nbsp;
            <b>
              {VoucherDetails.data
                ? VoucherDetails.data.GetAcctVouchers.totalCount
                : 0}
            </b>
          </div>
        </div>
      </div>
      <Button
        mode="cancel"
        onClick={() => {
          dispatch({
            type: payloadTypes.SET_RECEIPT_STUDENT_ID,
            payload: { receiptStudentId: 0 },
          });
          setModalFlag(false);
        }}
      />

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={interChangeFeeReceipt}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <InterchangeReceipt
              pageType={PageFor.MODAL}
              setModalFlag={setInterChangeFeeReceipt}
              handleModalClose={() => {
                setModalFlag(false);
              }}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setModalFlag(false);
                setInterChangeFeeReceipt(!interChangeFeeReceipt);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default StudentAllReceipts;
