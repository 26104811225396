import { NavLink, useParams } from "react-router-dom";
import { CustomTooltip, TOOLTIP_COLORS } from "../../../styles/TooltipStyles";
import Accounts from "../../../images/AccountsLogo.svg";
import Academics from "../../../images/AcademicsLogo.svg";
import EduateLogo from "../../../images/EduateLogo.svg";
import Chat from "../../../images/Chat.svg";
import HR from "../../../images/HR.svg";
import Fees from "../../../images/Fees.svg";
import Transport from "../../../images/Transport.svg";
import Enquiry from "../../../images/Enquiry.svg";
import UserRightsImg from "../../../images/User_Rights.svg";
import Customer from "../../../images/Customer.svg";
import Home from "../../../images/Home.svg";
import Library from "../../../images/Library.svg";
import AnimatedHamburger from "../../../images/AnimatedHamburger.svg";
import Master_Data from "../../../images/Admissions.svg";
import Settings from "../../../images/Settings.svg";
import { ParsedToken } from "firebase/auth";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../context/context";
import useUserRightsByEmpId from "../../../Modules/UserRights/hooks/useUserRightsByEmpId";
import useActiveInstModules from "../../../Modules/UserRights/hooks/useActiveInstModules";
import { GetSwEmpConfigVariables } from "../../../queries/institution/configuration/query/SoftwreConfig";
import {
  GetSwEmpConfigVariablesData,
  GetSwEmpConfigVariablesVars,
} from "../../../Types/configtypes";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  InstitutionConfigurationTypes,
  UserType,
} from "../../../utils/Enum.types";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../../Modules/UserRights/hooks/useAssignedInstbyEmpId";
import { GetStudentEnquiryEmpAssociCount } from "../../../queries/students/list/newApi";
import {
  GetStudentEnquiryEmpAssociCountData,
  GetStudentEnquiryEmpAssociCountVars,
} from "../../../Types/Student";
import { toIsoDate } from "../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Modules/Library/customHooks/useServerDateandTime";
import { handleSignOut } from "../Header";

import Search from "../../../images/Regular.svg";
interface Props {
  claims: ParsedToken;
}
const Sidebar = ({ claims }: Props) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { serverDate } = useServerDateandTime();

  const {
    USE_ACCOUNTS,
    USE_LIBRARY,
    USE_PAYROLL,
    USE_ACADEMICS,
    USE_ENQUIRY,
    USE_TRANSPORT,
    USE_MESSAGE_APP,
    USE_ADMISSIONS,
    userRights,
  } = useUserRightsByEmpId();

  const {
    USE_ACADEMICS: USE_INST_ACADEMICS,
    USE_ACCOUNTS: USE_INST_ACCOUNTS,
    USE_LIBRARY: USE_INST_LIBRARY,
    USE_MESSAGE_APP: USE_INST_CHAT,
    USE_ADMISSION: USE_INST_ADMISSION,
    USE_ENQUIRY: USE_INST_ENQUIRY,
    USE_PAYROLL: USE_INST_PAYROLL,
    USE_TRANSPORT: USE_INST_TRANSPORT,
    STD_USE_ACADEMICS,
    STD_USE_ACCOUNTS,
    STD_USE_CHAT,
    STD_USE_LIBRARY,
    STD_USE_TRANSPORT,
  } = useActiveInstModules();

  const { configData: feedsFlag } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_MY_CAMPUS_FEEDS
  );
  const { configData: announcementsFlag } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_ANNOUNCEMENTS
  );
  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );

  const isTeacher =
    MultipleInstitutions?.data?.GetInstUserAccess.edges[0]?.node?.pr_emp_details
      .emp_type === UserType.TEACHER;
  const feedsEnabled =
    feedsFlag.data &&
    feedsFlag.data.GetSwConfigVariables[0].config_boolean_value;
  const announcementsEnabled =
    announcementsFlag.data &&
    announcementsFlag.data.GetSwConfigVariables[0].config_boolean_value;

  const [GetSwModuleDetails, { data: Configdata }] = useLazyQuery<
    GetSwEmpConfigVariablesData,
    GetSwEmpConfigVariablesVars
  >(GetSwEmpConfigVariables);

  const [GetStudentEnquiryCount, { data: enquiryData }] = useLazyQuery<
    GetStudentEnquiryEmpAssociCountData,
    GetStudentEnquiryEmpAssociCountVars
  >(GetStudentEnquiryEmpAssociCount, {
    variables: {
      inst_id: InstId!,
      pr_emp_id: state.empLoginId,
      today_date: toIsoDate(serverDate),
      token,
    },
  });

  useEffect(() => {
    if (token && state.empLoginId && InstId) {
      GetStudentEnquiryCount();
    }
  }, [token, serverDate, state.empLoginId, GetStudentEnquiryCount, InstId]);
  useEffect(() => {
    if (token && state.InstId && state.empLoginId) {
      GetSwModuleDetails({
        variables: {
          token,
          inst_id: state.InstId!,
          pr_emp_id: state.empLoginId,
          input: {
            query_type: "EMP_COMPLETE_CONFIG",
            str_value: "",
            int_value: 0,
          },
        },
      });
    } // eslint-disable-next-line
  }, [token, GetSwModuleDetails, state.empLoginId]);

  return (
    <>
      <input type="checkbox" id="menu" name="" />
      <div className="sidebar g-0">
        <div className="sidebar__menu">
          <ul>
            <li>
              <img className="logo tooltip-hide" src={EduateLogo} alt="/" />
              <span className="sidebar__menu--myeduate">
                <img
                  src={EduateLogo}
                  alt="/"
                  className="logo"
                  onClick={handleSignOut}
                />
                My-Eduate
              </span>
            </li>
            <li>
              <label htmlFor="menu">
                <img
                  className="hamburger"
                  src={AnimatedHamburger}
                  alt="/"
                  id="toggle"
                />
              </label>
            </li>
            {/* {!claims.OWNER && (
              <li>
                <NavLink to={state.claims?.EMPLOYEE ? "/home" : "/"}>
                  <Tooltip
                    title="Home"
                    arrow
                    placement="right"
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    className="tooltip-hide">
                    <img src={Home} alt="/" />
                  </Tooltip>
                  <span>
                    <img src={Home} alt="/" />
                    Home
                  </span>
                </NavLink>
              </li>
            )} */}

            {/* {MultipleInstitutions.data?.GetInstUserAccess.edges[0].node
              .pr_emp_details.emp_type === UserType.TEACHER && USE_ACADEMICS ? (
              <li>
                <NavLink to={`/${state.InstId}/academics`}>
                  <Tooltip
                    title="Academics"
                    arrow
                    placement="right"
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    className="tooltip-hide">
                    <img src={Academics} alt="/" />
                  </Tooltip>
                  <span>
                    <img src={Academics} alt="/" />
                    Academics
                  </span>
                </NavLink>
              </li>
            ) : null} */}
            {claims.EDUATE ? (
              <>
                <li>
                  <NavLink to="/eduate/customer/list">
                    <CustomTooltip
                      title="Customers"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Customer} alt="/" className="tooltip-hide" />
                    </CustomTooltip>

                    <span>
                      <img src={Customer} alt="/" />
                      Customers
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/eduate/configurations">
                    <CustomTooltip
                      title="Configurations"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Settings} alt="/" className="tooltip-hide" />
                    </CustomTooltip>

                    <span>
                      <img src={Settings} alt="/" />
                      Configurations
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/demolist">
                    <CustomTooltip
                      title="Demo List"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Enquiry} alt="/" className="tooltip-hide" />
                    </CustomTooltip>

                    <span>
                      <img src={Enquiry} alt="/" />
                      Demo List
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/globalsearch">
                    <CustomTooltip
                      title="Global Search"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Search} alt="/" className="tooltip-hide" />
                    </CustomTooltip>

                    <span>
                      <img src={Search} alt="/" />
                      Global Search{" "}
                    </span>
                  </NavLink>
                </li>
              </>
            ) : null}

            {claims.EMPLOYEE ? (
              <>
                {USE_ENQUIRY.flag && USE_INST_ENQUIRY && isTeacher === false ? (
                  <li>
                    <NavLink to={`/${state.InstId}/enquiry`}>
                      <CustomTooltip
                        title="Student Enquiry"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Enquiry} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Enquiry} alt="/" />
                        Student Enquiry
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {isTeacher &&
                enquiryData &&
                enquiryData.GetStudentEnquiryEmpAssociCount &&
                USE_INST_ENQUIRY ? (
                  <li>
                    <NavLink to={`/${state.InstId}/enquiry/teacher`}>
                      <CustomTooltip
                        title="Student Enquiry"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Enquiry} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Enquiry} alt="/" />
                        Student Enquiry
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {USE_ADMISSIONS.flag && USE_INST_ADMISSION && (
                  <li>
                    <NavLink to={`/${state.InstId}/admissions`}>
                      <CustomTooltip
                        title="Admissions"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img
                          src={Master_Data}
                          alt="/"
                          className="tooltip-hide"
                        />
                      </CustomTooltip>
                      <span>
                        <img src={Master_Data} alt="/" />
                        Admissions
                      </span>
                    </NavLink>
                  </li>
                )}
                {USE_ACCOUNTS.flag && USE_INST_ACCOUNTS ? (
                  <li>
                    <NavLink
                      to={`${
                        window.location.pathname.includes("/cashierdashboard")
                          ? `/${state.InstId}/accounts/cashierdashboard`
                          : `/${state.InstId}/accounts`
                      }`}
                    >
                      <CustomTooltip
                        title="Accounts"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Accounts} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Accounts} alt="/" />
                        Accounts
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {USE_ACADEMICS.flag && USE_INST_ACADEMICS ? (
                  <li>
                    <NavLink to={`/${state.InstId}/academics`}>
                      <CustomTooltip
                        title="Academics"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Academics} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Academics} alt="/" />
                        Academics
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {USE_LIBRARY.flag && USE_INST_LIBRARY ? (
                  <li>
                    <NavLink to={`/${state.InstId}/library`}>
                      <CustomTooltip
                        title="Library"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Library} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Library} alt="/" />
                        Library
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {USE_PAYROLL.flag && USE_INST_PAYROLL ? (
                  <li>
                    <NavLink to={`/${state.InstId}/hr`}>
                      <CustomTooltip
                        title="Staff Management"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={HR} alt="/" className="tooltip-hide" />
                      </CustomTooltip>

                      <span>
                        <img src={HR} alt="/" />
                        Staff Management
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {USE_TRANSPORT.flag && USE_INST_TRANSPORT ? (
                  <li>
                    <NavLink to={`/${state.InstId}/transport`}>
                      <CustomTooltip
                        title="Transport"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Transport} alt="/" className="tooltip-hide" />
                      </CustomTooltip>

                      <span>
                        <img src={Transport} alt="/" />
                        Transport
                      </span>
                    </NavLink>
                  </li>
                ) : null}

                {userRights.data
                  ? userRights.data.GetUserRightsByEmpId.is_inst_sysadmin && (
                      <>
                        <li>
                          <NavLink to={`/${state.InstId}/rights`}>
                            <CustomTooltip
                              title="User Rights"
                              arrow
                              placement="right"
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: TOOLTIP_COLORS.VARIABLE,
                                    "& .MuiTooltip-arrow": {
                                      color: TOOLTIP_COLORS.VARIABLE,
                                    },
                                  },
                                },
                              }}
                            >
                              <img
                                src={UserRightsImg}
                                alt="/"
                                className="tooltip-hide"
                              />
                            </CustomTooltip>
                            <span>
                              <img src={UserRightsImg} alt="/" />
                              User Rights
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={`/${state.InstId}/institutionconfiguration`}
                          >
                            <CustomTooltip
                              title="Instituton Configuration"
                              arrow
                              placement="right"
                              slotProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: TOOLTIP_COLORS.VARIABLE,
                                    "& .MuiTooltip-arrow": {
                                      color: TOOLTIP_COLORS.VARIABLE,
                                    },
                                  },
                                },
                              }}
                            >
                              <img
                                src={Settings}
                                alt="/"
                                className="tooltip-hide"
                              />
                            </CustomTooltip>
                            <span>
                              <img src={Settings} alt="/" />
                              Instituton Configuration
                            </span>
                          </NavLink>
                        </li>
                      </>
                    )
                  : null}
                {USE_MESSAGE_APP.flag &&
                USE_INST_CHAT &&
                (feedsEnabled || announcementsEnabled) &&
                Configdata &&
                Configdata.GetSwEmpConfigVariables &&
                Configdata.GetSwEmpConfigVariables[0] &&
                Configdata.GetSwEmpConfigVariables[0].config_boolean_value ? (
                  <li>
                    <NavLink to={`/${state.InstId}/chat`}>
                      <CustomTooltip
                        title="Message Channel"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img
                          src={Chat}
                          alt="/"
                          className="chat-icon tooltip-hide"
                        />
                      </CustomTooltip>
                      <span>
                        <img src={Chat} alt="/" className="chat-icon" />
                        Chat
                      </span>
                    </NavLink>
                  </li>
                ) : null}
              </>
            ) : null}
            {claims.STUDENT ? (
              <>
                <li>
                  <NavLink to={"/"}>
                    <CustomTooltip
                      title="Home"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Home} alt="/" className="tooltip-hide" />
                    </CustomTooltip>
                    <span>
                      <img src={Home} alt="/" />
                      Home
                    </span>
                  </NavLink>
                </li>
                {STD_USE_ACADEMICS ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/masters/studentlist/${state.studentId}/academics`}
                    >
                      <CustomTooltip
                        title="Academics"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Academics} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Academics} alt="/" />
                        Academics
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_ACCOUNTS ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/masters/studentlist/${state.studentId}/feedetails/0/success`}
                    >
                      <CustomTooltip
                        title="Fee Details"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Fees} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Fees} alt="/" />
                        Fee Details
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_LIBRARY ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/masters/studentlist/${state.studentId}/library`}
                    >
                      <CustomTooltip
                        title="Library"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Library} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Library} alt="/" />
                        Library
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_TRANSPORT ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/masters/studentlist/${state.studentId}/transport`}
                    >
                      <CustomTooltip
                        title="Transport"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Transport} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Transport} alt="/" />
                        Transport
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_CHAT ? (
                  <li>
                    <NavLink
                      to={`${state.InstId}/masters/studentlist/${state.studentId}/chat`}
                    >
                      <CustomTooltip
                        title="Message Channel"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img
                          src={Chat}
                          alt="/"
                          className="chat-icon tooltip-hide"
                        />
                      </CustomTooltip>
                      <span>
                        <img src={Chat} alt="/" className="chat-icon" />
                        Chat
                      </span>
                    </NavLink>
                  </li>
                ) : null}
              </>
            ) : claims.PARENT &&
              window.location.pathname.includes("student") ? (
              <>
                <li>
                  <NavLink to={"/parentdashboard"}>
                    <CustomTooltip
                      title="Home"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Home} alt="/" className="tooltip-hide" />
                    </CustomTooltip>
                    <span>
                      <img src={Home} alt="/" />
                      Home
                    </span>
                  </NavLink>
                </li>
                {STD_USE_ACADEMICS ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/parentLogin/${state.studentId}/student/academics`}
                    >
                      <CustomTooltip
                        title="Academics"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Academics} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Academics} alt="/" />
                        Academics
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_ACCOUNTS ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/masters/studentlist/${state.studentId}/feedetails/0/student/success`}
                    >
                      <CustomTooltip
                        title="Fee Details"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Fees} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Fees} alt="/" />
                        Fee Details
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_LIBRARY ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/masters/studentlist/${state.studentId}/student/library`}
                    >
                      <CustomTooltip
                        title="Library"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Library} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Library} alt="/" />
                        Library
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_TRANSPORT ? (
                  <li>
                    <NavLink
                      to={`/${state.InstId}/masters/studentlist/${state.studentId}/student/transport`}
                    >
                      <CustomTooltip
                        title="Transport"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img src={Transport} alt="/" className="tooltip-hide" />
                      </CustomTooltip>
                      <span>
                        <img src={Transport} alt="/" />
                        Transport
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {STD_USE_CHAT ? (
                  <li>
                    <NavLink
                      to={`${state.InstId}/masters/studentlist/${state.studentId}/student/chat`}
                    >
                      <CustomTooltip
                        title="Message Channel"
                        arrow
                        placement="right"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                      >
                        <img
                          src={Chat}
                          alt="/"
                          className="chat-icon tooltip-hide"
                        />
                      </CustomTooltip>
                      <span>
                        <img src={Chat} alt="/" className="chat-icon" />
                        Chat
                      </span>
                    </NavLink>
                  </li>
                ) : null}
              </>
            ) : claims.OWNER ? (
              <>
                <li>
                  <NavLink
                    to={`/eduate/customer/${state.custId}/proprietor/admissions`}
                  >
                    <CustomTooltip
                      title="Admissions"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Master_Data} alt="/" className="tooltip-hide" />
                    </CustomTooltip>
                    <span>
                      <img src={Master_Data} alt="/" />
                      Admissions
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/eduate/customer/${state.custId}/proprietor/accounts`}
                  >
                    <CustomTooltip
                      title="Accounts"
                      arrow
                      placement="right"
                      slotProps={{
                        tooltip: {
                          sx: {
                            bgcolor: TOOLTIP_COLORS.VARIABLE,
                            "& .MuiTooltip-arrow": {
                              color: TOOLTIP_COLORS.VARIABLE,
                            },
                          },
                        },
                      }}
                    >
                      <img src={Accounts} alt="/" className="tooltip-hide" />
                    </CustomTooltip>
                    <span>
                      <img src={Accounts} alt="/" />
                      Accounts
                    </span>
                  </NavLink>
                </li>
              </>
            ) : null}
            {/* <li>
              <NavLink to={`/vms`}>
                <Tooltip
                  title="Vehicle Management System"
                  arrow
                  placement="right"
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                  className="tooltip-hide"
                >
                  <img src={VMS} alt="/" />
                </Tooltip>

                <span>
                  <img src={VMS} alt="/" />
                  Vehicle Management System
                </span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
