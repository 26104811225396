import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import Home from "../Home/Index";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { StudentsTitlesType } from "../../../Types/Student/json";
import Avatar from "../../../images/Avatar.svg";
import Close from "../../../images/Close.svg";
import Modal from "react-modal";
import { EMPTY_STRING } from "../../../utils/constants";
import {
  InstitutionConfigurationTypes,
  InstitutionType,
  Operation,
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
} from "../../../utils/Enum.types";
import { ResetStudentPassword } from "../../../../src/queries/students/mutations/new";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../context/context";

import { StudentModalStyles } from "../../../styles/ModalStyles";
import StudentList from "./List";
import { useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { msgType } from "../../../utils/Form.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import { toStandardDate } from "../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import StudentSelectAutocomplete from "../../Components/StudentSelectAutocomplete";
import useSwConfigInstType from "../../Academics/hooks/useSwConfigInstType";
const { Students } = require("../../../json/title.json");
const StudentResetPassword = () => {
  const { InstId } = useParams();
  const { institutionType } = useSwConfigInstType();

  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const { state } = useContext(AppContext);
  const [studentModal, setStudentModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { InstDetails } = useInstDetails(1);
  const [studentDetails, setStudentDetails] = useState({
    std_profile_filename: "",
  });
  const [imageString, setImageString] = useState("");

  const { token } = useToken();
  const { studentData } = useStudentDatabyId();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.DEFAULT_PASSWORD
  );

  const [passwordReset, { loading: updationLoading }] =
    useMutation(ResetStudentPassword);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const resetForm = async () => {
    await passwordReset({
      variables: {
        token,
        student_id: state.studentId,
        inst_id: InstId!,
        user_details,
        password: resetPassword,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Password reset Successful",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (configData.data && !configData.loading) {
      setResetPassword(
        configData.data?.GetSwConfigVariables[0].config_string_value
      );
    }
  }, [configData.data, configData.loading]);

  useEffect(() => {
    if (studentDetails.std_profile_filename !== "" && InstDetails.data) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
    if (studentData.data && InstDetails.data) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      setStudentDetails({
        std_profile_filename: studentProfiePicUrl,
      });
    }
  }, [
    studentDetails.std_profile_filename,
    studentData.data,
    state.studentId,
    InstDetails.data,
  ]);
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Students.StudentResetPassword.map(
          (title: StudentsTitlesType, index: React.Key) => {
            return <React.Fragment key={index}>{title.Title}</React.Fragment>;
          }
        )}
      </Title>
      <div className="student-reset-password">
        <div className="row g-0 student-reset-password__details">
          <div className="col">
            <div className="student-reset-password__details--imagefield">
              <StudentSelectAutocomplete
                queryType={StudentReportType.BY_ACD_INST_ID}
              />
            </div>

            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                className="student-reset-password__form--details--textfield"
                label="Register Number"
                value={
                  state.studentId
                    ? studentData.data?.nodes[0].std_reg_no
                    : EMPTY_STRING
                }
                disabled
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            )}

            <TextField
              className="student-reset-password__form--details--textfield"
              label={branchLabel}
              value={
                state.studentId
                  ? studentData.data?.nodes[0].branch.branch_desc
                  : EMPTY_STRING
              }
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </div>
          <div className="col">
            <TextField
              className="student-reset-password__form--details--textfield"
              label="Name"
              value={
                state.studentId && studentData.data
                  ? studentData.data?.nodes[0].first_name +
                    " " +
                    studentData.data?.nodes[0].middle_name +
                    " " +
                    studentData.data?.nodes[0].last_name
                  : EMPTY_STRING
              }
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              className="student-reset-password__form--details--textfield"
              label="Father Name"
              value={
                state.studentId
                  ? studentData.data?.nodes[0].std_father_name
                  : EMPTY_STRING
              }
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              className="student-reset-password__form--details--textfield"
              label={classLabel}
              value={
                state.studentId
                  ? studentData.data?.nodes[0].class.class_desc
                  : EMPTY_STRING
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="student-reset-password__form--details--textfield--date"
              label="DOB"
              value={
                state.studentId
                  ? toStandardDate(studentData.data?.nodes[0].std_dob!)
                  : EMPTY_STRING
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="student-reset-password__form--details--textfield"
              label="Academic Year"
              value={
                state.studentId
                  ? studentData.data?.nodes[0].acd_yr.acd_yr
                  : EMPTY_STRING
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
            <TextField
              className="student-reset-password__form--details--textfield"
              label={categoryLabel}
              value={
                state.studentId
                  ? studentData.data?.nodes[0].category.cat_desc
                  : EMPTY_STRING
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
          <div className="col-1 student-reset-password__details--image">
            <img src={imageString ? imageString : Avatar} alt="/" />
          </div>
        </div>
        <div className="student-reset-password__form">
          <div className="student-reset-password__form--details row g-0">
            <div className="col-4">
              <TextField
                className="student-reset-password__form--details--textfield"
                label="Email"
                value={
                  state.studentId
                    ? studentData.data?.nodes[0].std_email
                    : EMPTY_STRING
                }
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
              />
              <TextField
                className="student-reset-password__form--details--pwd"
                label="Password"
                value={resetPassword}
                onChange={(e) => setResetPassword(e.target.value)}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                disabled
                type={showPassword ? "text" : "password"}
              />
            </div>
          </div>
          <Button mode="save" onClick={resetForm} />
          <Button mode="back" onClick={() => navigate(-1)} />

          {/* student-modal */}
          <Modal
            shouldCloseOnOverlayClick={true}
            isOpen={studentModal}
            style={StudentModalStyles}
            ariaHideApp={false}>
            <div className="modal-flex h-100">
              <div className="modal-flex__data h-100">
                <StudentList
                  pageType={PageFor.MODAL}
                  studentListFor={StudentListFor.GENERAL}
                  queryType={StudentReportType.GENERAL}
                  setStudentModal={setStudentModal}
                  studentModal={studentModal}
                  label={PageLabel.TRUE}
                />
              </div>
              <div className="modal-flex__image">
                <img
                  onClick={() => setStudentModal(!studentModal)}
                  src={Close}
                  alt="/"
                  className="modal-close-icon"
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>

      <LoadingModal flag={updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentResetPassword;
