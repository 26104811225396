import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { TextField } from "@mui/material";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { GetMobileDevicesDetails } from "../../../queries/institution/configuration/query";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import { useParams } from "react-router-dom";
import { InstitutionType, UserType } from "../../../utils/Enum.types";
import { EMPTY_STRING, NOTALLOCATED } from "../../../utils/constants";
import Close from "../../../images/Close.svg";
import { toStandardDate } from "../../../utils/UtilFunctions";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import Avatar from "../../../images/Avatar.svg";
import { Button } from "../../../stories/Button/Button";
import MobileDevice from "../../../images/Installed_Devices.svg";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useSwConfigInstType from "../../Academics/hooks/useSwConfigInstType";

interface Props {
  deviceNo: number;
  setMessageModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const MobileDeviceModal = ({ setMessageModal, deviceNo }: Props) => {
  const { token } = useToken();
  const { InstId, studentId } = useParams();
  const { state } = useContext(AppContext);
  const { InstDetails } = useInstDetails(1);
  const { institutionType } = useSwConfigInstType();

  const studentProfiePicUrl = `${
    InstDetails.data?.nodes[0]?.inst_name
  }/students/${
    studentId ? studentId : state.studentId
  }/std_profile_pic/std_profile_pic`;
  const { studentFormData, studentData } = useStudentDatabyId();

  const [GetMobileDetails, { data: MobileData }] = useLazyQuery(
    GetMobileDevicesDetails
  );
  useEffect(() => {
    if (token && state.studentId) {
      GetMobileDetails({
        variables: {
          token,
          inst_id: Number(InstId!),
          user_details: {
            user_type: UserType.STUDENT,
            id: state.studentId,
          },
        },
      });
    }
  }, [GetMobileDetails, token, state.studentId]);
  const [imageString, setImageString] = useState("");
  useEffect(() => {
    if (studentFormData.std_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [
    studentFormData.std_profile_filename,
    studentFormData,
    studentFormData.data,
  ]);
  const { categoryLabel } = useInstLabels();
  return (
    <>
      <div className="std-device-details__drawer">
        <div className="std-device-details__drawer--title">
          <Title>Individual Student Device Details</Title>
          <img src={Close} alt="/" onClick={() => setMessageModal(false)} />
        </div>
        <div className="std-device-details__drawer--details row g-0">
          <div className="col">
            <TextField
              className="preview__details--textfield"
              label="Admission Number"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentData && studentData.data?.nodes[0].std_adm_no}
              disabled
            />
            {institutionType !== InstitutionType.SCHOOL && (
              <TextField
                className="preview__details--textfield"
                label="Register Number"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.reg_number}
                disabled
              />
            )}

            <TextField
              className="preview__details--textfield--date"
              label="DOB"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={toStandardDate(studentFormData.std_dob!)}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="preview__details--textfield"
              label="Name"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                studentFormData.first_name +
                " " +
                studentFormData.middle_name +
                " " +
                studentFormData.last_name
              }
              disabled
            />
            <TextField
              className="preview__details--textfield"
              label="Father Name"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.std_father_name}
              disabled
            />
            {studentFormData.cat_desc !== NOTALLOCATED && (
              <TextField
                className="preview__details--textfield"
                label={categoryLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={studentFormData.cat_desc}
                disabled
              />
            )}
          </div>

          <div className="col-2 preview__image h-100">
            {imageString ? (
              <img src={imageString} alt="/" />
            ) : (
              <img src={Avatar} alt="/" />
            )}
          </div>
        </div>
        <div className="std-device-details__drawer--total">
          <span>Total Devices Logged in </span>
          <div className="std-device-details__drawer--total--t">
            <b>{deviceNo}</b>
          </div>
        </div>
        <ul className="std-device-details__drawer--ul">
          {MobileData
            ? MobileData.GetMobileDevicesDetails.map(
                (data: any, index: React.Key) => {
                  const dateObj = new Date(data.last_login_date);
                  const isoTime = dateObj
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0];
                  const date = dateObj.toLocaleDateString();

                  return (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <div>
                        <img src={MobileDevice} alt="" /> <b>{data.brand}</b>
                        &nbsp;
                        <span>{data.model}</span>
                      </div>

                      <b>
                        Last accessed :- {isoTime} {date}
                      </b>
                    </li>
                  );
                }
              )
            : EMPTY_STRING}
        </ul>
        <Button mode="cancel" onClick={() => setMessageModal(false)} />
      </div>
    </>
  );
};
export default MobileDeviceModal;
