import React, { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import Avatar from "../../../images/Avatar.svg";
import Datafetch from "../../../images/Edit.svg";
import { FormControlLabel, TextField } from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
  StudentAcctReportType,
  TableHeaders,
} from "../../../utils/Enum.types";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { AntSwitch } from "../../../pages/Switch";
import TextArea from "../../../stories/TextArea/TextArea";
import { AddLedgerModalStyles } from "../../../styles/ModalStyles";
import Modal from "react-modal";
import EditActivity from "./Edit";
import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../utils/constants";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../context/context";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import { Keys } from "../../../utils/Enum.keys";
import { payloadTypes } from "../../../context/reducer";
import { msgType, responseType } from "../../../utils/Form.types";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcctMiscActivitiesData,
  GetAcctMiscActivitiesVars,
} from "../../../Types/Library/MasterData/Media/paginationTypes";
import { GetAcctMiscActivities } from "../../../queries/Library/MasterData/Media/list/byInstId";
import useToken from "../../../customhooks/useToken";
import { AddAcctStdMiscActivityLogs } from "../../../queries/Library/MasterData/Media/mutations/new";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import LoadingModal from "../../../pages/LoadingModal";
import useActiveFinancialYear from "../hooks/useActiveFinancialYear";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { TableHeaderProps } from "../../../utils/types";
import useAcctTableJson from "../json/useAcctTableJson";

const data = [
  {
    date: "10-05-2024",
    admno: "MH001",
    stdname: "Kavya",
    miscellaneous: "Book",
    remark: "Cleared all his due",
    Status: "True",
  },
  {
    date: "10-05-2024",
    admno: "MH001",
    stdname: "Kavya",
    miscellaneous: "Book",
    remark: "Cleared all his due",
    Status: "True",
  },
];
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Add = ({ pageType, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { InstDetails } = useInstDetails(1);
  const { studentId } = useParams();

  const [sortBy] = useState(SortBy.ACTIVITY_NAME);
  const [direction] = useState(Direction.ASC);

  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const { dispatch, state } = useContext(AppContext);
  const [admNo, setAdmNo] = useState("");
  const [imageString, setImageString] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [logDateSelected, setLogDateSelected] = useState("");
  const [editModal, setEditModal] = useState(false);
  const { Accounts_Table } = useAcctTableJson();
  const { studentData, studentFormData } = useStudentDatabyId();
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.FEE_RECEIPT
  );
  const [status, setStatus] = useState(false);
  const [comments, setComments] = useState("");
  const [miscActivitySelected, setMiscActivitySelected] =
    useState<responseType | null>(null);
  const [GetMiscData, { data: MiscData }] = useLazyQuery<
    GetAcctMiscActivitiesData,
    GetAcctMiscActivitiesVars
  >(GetAcctMiscActivities, {
    variables: {
      token,
      inst_id: InstId!,
      first: null,
      sortBy,
      direction,
      activityName: EMPTY_STRING,
      after: null,
    },
  });

  const [AddMiscData, { loading: creationLoading }] = useMutation(
    AddAcctStdMiscActivityLogs,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const miscDataDropDown =
    MiscData &&
    MiscData.GetAcctMiscActivities.edges.map((res) => ({
      label: res.node.activity_name,
      value: res.node.id,
    }));

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Miscellaneous_Add.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        if (header.field === "action") {
          return (
            <>
              <img src={Edit} alt="" onClick={() => setEditModal(!editModal)} />
              <img src={Delete} alt="" />
            </>
          );
        }
        return params.value;
      },
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
  ];
  const rows: GridValidRowModel[] =
    (data || []).map((response, index) => {
      return {
        id: index + 1,
        date: response.date,
        adm_no: response.admno,
        std_name: response.stdname,
        miscellaneous: response.miscellaneous,
        remark: response.remark,
        status: response.Status,
      };
    }) || [];
  const HandleSubmit = () => {
    AddMiscData({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        fin_yr_id:
          ActiveFinancicalYear.data &&
          ActiveFinancicalYear.data.GetFinYrActiveByInstId.id,
        student_id: state.studentId,
        log_date: toIsoDate(logDateSelected),
        input: [
          {
            last_status: status,
            comments: comments,
            misc_activity_id: miscActivitySelected?.value,
          },
        ],
      },
      // refetchQueries: [
      //   {
      //     query: GetAcctMiscActivities,
      //     variables: {
      //       token,
      //       inst_id: InstId!,
      //       first: null,
      //       sortBy,
      //       direction,
      //       activityName: searchData,
      //       after: null,
      //     },
      //   },
      // ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Activity Added Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (token) {
      GetMiscData();
    }
  }, [token, GetMiscData]);
  const { categoryLabel } = useInstLabels();

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>Miscellaneous Activity</Title>
      <div className="miscellaneous-add">
        <div className="row g-0 miscellaneous-add__details">
          <div className="col">
            <div className="miscellaneous-add__details--flex">
              {pageType === PageFor.MODAL ? (
                <TextField
                  label=" Admission Number"
                  className="student-fee-receipt__frame--textfield"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={studentData.data?.nodes[0].std_adm_no ?? EMPTY_STRING}
                  disabled
                />
              ) : (
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={studentAddmissionNumber}
                  value={
                    state.studentId
                      ? studentAddmissionNumber?.find(
                          ({ value }) => value === state.studentId
                        )
                      : null
                  }
                  isOptionEqualToValue={(option) =>
                    (option as responseType).value === state.studentId
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                      setAdmNo("");
                    }
                  }}
                  openOnFocus
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setAdmNo(e.target.value)}
                      label="Admission Number"
                      autoFocus
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      fullWidth
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              )}
              {pageType === PageFor.GENERAL && (
                <img src={Datafetch} className="data-fetch-icon" alt="" />
              )}
            </div>
            <TextField
              label="Reg No."
              className="student-fee-receipt__frame--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData.reg_number}
              disabled
            />
            <TextField
              disabled
              className="miscellaneous-add__details--textfield"
              label="Date of Birth"
              value={toStandardDate(studentFormData.std_dob)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </div>
          <div className="col">
            <TextField
              disabled
              className="miscellaneous-add__details--textfield"
              label="Name"
              value={
                studentFormData.first_name +
                " " +
                studentFormData.middle_name +
                " " +
                studentFormData.last_name
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              disabled
              className="miscellaneous-add__details--textfield"
              label="Father Name"
              value={studentFormData.father_name}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              disabled
              className="miscellaneous-add__details--textfield"
              label="Branch"
              value={studentFormData.branch}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </div>
          <div className="col">
            <TextField
              disabled
              className="miscellaneous-add__details--textfield"
              label="Class"
              value={studentFormData.class}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              disabled
              className="miscellaneous-add__details--textfield"
              label={categoryLabel}
              value={studentFormData.category}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              disabled
              className="miscellaneous-add__details--textfield"
              label="Mobile No."
              value={studentFormData.std_mobile}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </div>
          <div className="col-1 miscellaneous-add__details--image">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <div className={`miscellaneous-add__tableblock `}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
          />
        </div>
        <div className="miscellaneous-add__datablock row g-0">
          <div className="col miscellaneous-add__datablock--frame">
            <div className="label-grid">
              <Label>Date</Label>
              <Input
                type="date"
                value={logDateSelected}
                onChange={(e) => setLogDateSelected(e.target.value)}
              />
              <Label>Choose Miscellaneous Activity</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={miscDataDropDown!}
                openOnFocus
                forcePopupIcon
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    miscActivitySelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (miscActivitySelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setMiscActivitySelected(null);
                  }
                }}
                value={miscActivitySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setMiscActivitySelected(newValue as responseType);
                  } else {
                    setMiscActivitySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <FormControlLabel
              label="Status"
              labelPlacement="start"
              control={<AntSwitch onClick={() => setStatus(!status)} />}
              className="miscellaneous-add__datablock--form-labels"
            />
          </div>
          <div className="col miscellaneous-add__datablock--frame">
            <div className="label-grid">
              <Label>Remarks</Label>
              <TextArea
                rows={3}
                onChange={(e) => setComments(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Button mode="save" onClick={HandleSubmit} />

        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button
            mode="cancel"
            type="button"
            onClick={() => setModalFlag(false)}
          />
        )}
      </div>
      <Modal
        isOpen={editModal}
        style={AddLedgerModalStyles}
        ariaHideApp={false}
      >
        <EditActivity setModal={setEditModal} />
      </Modal>
      <LoadingModal flag={creationLoading} />
    </>
  );
};

export default Add;
