import React, { useContext, useEffect, useMemo, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { msgType, responseType } from "../../../../utils/Form.types";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import { useNavigate, useParams } from "react-router-dom";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  TODAY_DATE,
} from "../../../../utils/constants";
import { Operation, TableHeaders } from "../../../../utils/Enum.types";
import Finalize from "../../../../images/MarksEntryCompleted.svg";
import Publish from "../../../../images/EnquiredStudents.svg";

import {
  CustomTooltip,
  TOOLTIP_COLORS,
  TooltipForMultipleOption,
} from "../../../../styles/TooltipStyles";
import ViewImage from "../../../../images/Eye.svg";
import Edit from "../../../../images/EditProfile.svg";
import More from "../../../../images/More.svg";
import { TreeType } from "../../Reports/TestWise/View";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetAcdTestNameByIdData } from "../../Test/TestCreation/Index";
import { singleNodeVars } from "../../../../Types/Accounting";
import { GetAcdTestNameById } from "../../queries/test/query";

import useToken from "../../../../customhooks/useToken";
import { Button } from "../../../../stories/Button/Button";
import {
  FinalizeTestMarks,
  PublishTestMarks,
} from "../../queries/test/mutation";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { AppContext } from "../../../../context/context";
import MessageModal from "../../../../pages/MessageModal";
import useAcdInstTreeForTestFinalize from "../../hooks/useAcdInstTreeForTestFinalize";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import LoadingModal from "../../../../pages/LoadingModal";
import { GetAcdInstTreeForTestFinalize } from "../../queries/holidays/list";
import { InstTreeQueryType } from "../../hooks/useAcdInstTreeForTestReport";
interface AssignComponentProp {
  id: number;
  testClassId: number;
  isMarksEntryCompleted: boolean;
  is_marks_entry_started: boolean;
  is_report_generated: boolean;
  publish_results: boolean;
  per_std_subj_allocation: boolean;
}

const View = () => {
  const { token } = useToken();
  const { InstId, testId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const [queryType, setQueryType] = useState("");
  const { state } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  let idsSet = new Set();
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }
    if (USE_SECTION_KEY) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);
  const [GetTestTypeDetails, { data: TestName }] = useLazyQuery<
    GetAcdTestNameByIdData,
    singleNodeVars
  >(GetAcdTestNameById);
  useEffect(() => {
    if (testId && token) {
      GetTestTypeDetails({
        variables: { id: testId ? Number(testId) : 0, token },
      });
    }
  }, [testId, GetTestTypeDetails, token]);
  const { data: TreeData, loading: TreeDataLoading } =
    useAcdInstTreeForTestFinalize(
      departmentSelected ? departmentSelected.value : 0,
      branchSelected ? branchSelected.value : 0,
      classSelected ? classSelected.value : 0,
      semesterSelected ? semesterSelected.value : 0,
      sectionSelected ? sectionSelected.value : 0,
      testId ? Number(testId) : 0
    );

  useEffect(() => {
    if (sectionSelected) {
      setQueryType(InstTreeQueryType.TREE_BY_SECTION_ID);
    } else if (semesterSelected) {
      setQueryType(InstTreeQueryType.TREE_BY_SEMESTER_ID);
    } else if (classSelected) {
      setQueryType(InstTreeQueryType.TREE_BY_CLASS_ID);
    } else if (branchSelected) {
      setQueryType(InstTreeQueryType.TREE_BY_BRANCH_ID);
    } else if (departmentSelected) {
      setQueryType(InstTreeQueryType.TREE_BY_DEPT_ID);
    } else {
      setQueryType(InstTreeQueryType.TREE_BY_INST_ID);
    }
  }, [
    sectionSelected,
    semesterSelected,
    classSelected,
    branchSelected,
    departmentSelected,
  ]);
  const modifiedArrayForTable: TreeType[] = TreeData
    ? TreeData?.GetAcdInstTreeForTestFinalize.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.map((level5) => {
                        let result = {
                          level1: level1.acd_level_1,
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2: level2.acd_level_2,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3: level3.acd_level_3,

                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4: level4.acd_level_4,

                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5: level5.acd_level_5,

                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          filterThis: false,
                          per_std_subj_allocation:
                            level5.per_std_subj_allocation,
                          acd_test_class_id: level5.acd_test_class_id,
                          is_report_generated: level5.is_report_generated,
                          publish_results: level5.publish_results,
                          is_marks_entry_started: level5.is_marks_entry_started,
                          is_marks_entry_completed:
                            level5.is_marks_entry_completed,

                          marks_entry_start_date: level5.marks_entry_start_date,

                          marks_entry_end_date: level5.marks_entry_end_date,

                          sub_acd_level_total_count:
                            level5.sub_acd_level_total_count,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1: level1.acd_level_1,
                        level2: level2.acd_level_2,
                        level3: level3.acd_level_3,
                        level4: level4.acd_level_4,
                        level5: EMPTY_STRING,
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_id: level1.acd_level_1_id,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level2_id: level2.acd_level_2_id,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level3_id: level3.acd_level_3_id,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count
                          ? level4.sub_acd_level_total_count
                          : 1,
                        level4_id: level4.acd_level_4_id,
                        level5_name: EMPTY_STRING,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),
                        is_marks_entry_started: level4.is_marks_entry_started,
                        is_marks_entry_completed:
                          level4.is_marks_entry_completed,

                        marks_entry_start_date: level4.marks_entry_start_date,

                        marks_entry_end_date: level4.marks_entry_end_date,

                        acd_test_class_id: level4.acd_test_class_id,
                        per_std_subj_allocation: level4.per_std_subj_allocation,
                        publish_results: level4.publish_results,

                        is_report_generated: level4.is_report_generated,
                        contains_topper_details: level4.contains_topper_details,
                        sub_acd_level_total_count:
                          level4.sub_acd_level_total_count,
                      };

                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1: level1.acd_level_1,
                    level2: level2.acd_level_2,
                    level3: level3.acd_level_3,
                    level4: EMPTY_STRING,
                    level5: EMPTY_STRING,
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_id: level1.acd_level_1_id,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level2_id: level2.acd_level_2_id,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level3_id: level3.acd_level_3_id,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level4_id: 0,
                    level5_name: EMPTY_STRING,
                    level5_id: 0,
                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                    is_marks_entry_started: level3.is_marks_entry_started,
                    is_marks_entry_completed: level3.is_marks_entry_completed,

                    marks_entry_start_date: level3.marks_entry_start_date,

                    marks_entry_end_date: level3.marks_entry_end_date,
                    acd_test_class_id: level3.acd_test_class_id,
                    per_std_subj_allocation: level3.per_std_subj_allocation,

                    publish_results: level3.publish_results,

                    is_report_generated: level3.is_report_generated,
                    contains_topper_details: level3.contains_topper_details,
                    sub_acd_level_total_count: level3.sub_acd_level_total_count,
                  };
                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1: level1.acd_level_1,
                level2: level2.acd_level_2,
                level3: EMPTY_STRING,
                level4: EMPTY_STRING,
                level5: EMPTY_STRING,
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_id: level1.acd_level_1_id,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level2_id: level2.acd_level_2_id,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level3_id: 0,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level4_id: 0,
                level5_name: EMPTY_STRING,
                level5_id: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                is_marks_entry_started: level2.is_marks_entry_started,
                is_marks_entry_completed: level2.is_marks_entry_completed,

                marks_entry_start_date: level2.marks_entry_start_date,

                marks_entry_end_date: level2.marks_entry_end_date,
                acd_test_class_id: level2.acd_test_class_id,
                per_std_subj_allocation: level2.per_std_subj_allocation,

                publish_results: level2.publish_results,

                is_report_generated: level2.is_report_generated,
                contains_topper_details: level2.contains_topper_details,
                sub_acd_level_total_count: level2.sub_acd_level_total_count,
              };
              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1: level1.acd_level_1,
            level2: EMPTY_STRING,
            level3: EMPTY_STRING,
            level4: EMPTY_STRING,
            level5: EMPTY_STRING,
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_id: level1.acd_level_1_id,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level2_id: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level3_id: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level4_id: 0,
            level5_name: EMPTY_STRING,
            level5_id: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];

  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  const [FinalizeMarks, { loading }] = useMutation(FinalizeTestMarks, {
    onError: (e) =>
      setMessage({
        message: e.message,
        flag: true,
        operation: Operation.NONE,
      }),
  });
  const [PublishResults, { loading: publishLoading }] = useMutation(
    PublishTestMarks,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );
  const handleFinalize = (
    test_class: number,
    id: number,
    per_std_subj_allocation: boolean
  ) => {
    FinalizeMarks({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        date_of_finalize: toIsoDate(TODAY_DATE),
        input: {
          inst_id: InstId,
          acd_test_class_id: test_class,
          entry_id: id,
          entry_level,
          test_type_id: 0,
        },
        per_std_subj_allocation,
      },
      refetchQueries: [
        {
          query: GetAcdInstTreeForTestFinalize,
          variables: {
            inst_id: InstId ? InstId : state.InstId,
            id: Number(InstId),
            token,
            query_type: queryType,
            test_name_id: Number(testId),
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Test Marks finalized`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handlePublish = (
    test_class: number,
    id: number,
    per_std_subj_allocation: boolean
  ) => {
    PublishResults({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        input: {
          inst_id: InstId,
          acd_test_class_id: test_class,
          entry_id: id,
          entry_level,
          test_type_id: 0,
        },
        per_std_subj_allocation,
      },
      refetchQueries: [
        {
          query: GetAcdInstTreeForTestFinalize,
          variables: {
            inst_id: InstId ? InstId : state.InstId,
            id: Number(InstId),
            token,
            query_type: queryType,
            test_name_id: Number(testId),
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Test Marks finalized`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const AssignComponent = ({
    id,
    isMarksEntryCompleted,
    testClassId,
    is_marks_entry_started,
    is_report_generated,
    publish_results,
    per_std_subj_allocation,
  }: AssignComponentProp) => {
    return (
      <>
        {isMarksEntryCompleted && !is_report_generated ? (
          <CustomTooltip
            title="Finalize Marks"
            placement="top"
            slotProps={{
              tooltip: {
                sx: {
                  bgcolor: TOOLTIP_COLORS.GREY,
                  "& .MuiTooltip-arrow": {
                    color: TOOLTIP_COLORS.GREY,
                  },
                },
              },
            }}>
            <img
              src={Finalize}
              alt=""
              className="image-white"
              onClick={() => {
                handleFinalize(testClassId, id, per_std_subj_allocation);
              }}
            />
          </CustomTooltip>
        ) : null}
        {is_report_generated && !publish_results ? (
          <CustomTooltip
            title="Publish Marks"
            placement="top"
            slotProps={{
              tooltip: {
                sx: {
                  bgcolor: TOOLTIP_COLORS.GREY,
                  "& .MuiTooltip-arrow": {
                    color: TOOLTIP_COLORS.GREY,
                  },
                },
              },
            }}>
            <img
              src={Publish}
              alt=""
              className="image-white"
              onClick={() => {
                handlePublish(testClassId, id, per_std_subj_allocation);
              }}
            />
          </CustomTooltip>
        ) : null}

        {is_marks_entry_started && (
          <TooltipForMultipleOption
            placement="left-start"
            title={
              <>
                <ul>
                  <li
                    className="studentlist__table--more--interchange"
                    onClick={() => {
                      navigate(
                        `/${InstId}/academics/dailyactivities/${testClassId}/exams/${id}/updatemarks`
                      );
                    }}>
                    <img src={Edit} alt="/" />
                    &nbsp;
                    <span>Edit</span>
                  </li>

                  <li
                    className="studentlist__table--more--interchange"
                    onClick={() => {
                      navigate(
                        `/${InstId}/academics/dailyactivities/${testClassId}/exams/${id}/viewmarks`
                      );
                    }}>
                    <img src={ViewImage} alt="/" />
                    &nbsp;
                    <span>View</span>
                  </li>
                </ul>
              </>
            }>
            <img src={More} alt="/" />
          </TooltipForMultipleOption>
        )}
      </>
    );
  };
  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>View Test Details</Title>
      <div className="test-list-view">
        <div className="test-list-view__filters row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY && displayClass ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY && displaySemester ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY && displaySection ? (
            <div className="col-2">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="test-list-view__title">
          <b>{TestName ? TestName.node.test_name : EMPTY_STRING}</b>
          <span>
            (
            {TestName
              ? TestName.node.test_type_details.test_type_name
              : EMPTY_STRING}
            )
          </span>
        </div>

        <div className="test-list-view__tableblock">
          <TableContainer className="test-list-view__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {USE_DEPARTMENT_KEY ? (
                    <TableCell>{departmentLabel}</TableCell>
                  ) : null}
                  {USE_BRANCH_KEY ? <TableCell>{branchLabel}</TableCell> : null}
                  {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
                  {USE_SEMESTER_KEY ? (
                    <TableCell>{semesterLabel}</TableCell>
                  ) : null}
                  {USE_SECTION_KEY ? (
                    <TableCell>{sectionLabel}</TableCell>
                  ) : null}
                  <TableCell>Marks Entry Completed</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>{TableHeaders.ACTION}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                  if (data) {
                    return (
                      <TableRow key={i}>
                        {data.level1_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level1_rowspan}>
                            {data.level1_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        (data.level2_name ||
                          data.level2_name !== EMPTY_STRING) ? (
                          <TableCell rowSpan={data.level2_rowspan}>
                            {data.level2_name}
                          </TableCell>
                        ) : null}
                        {data && data.level3_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level3_span}>
                            {data.level3_name}
                          </TableCell>
                        ) : null}
                        {data && data.level4_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level4_span}>
                            {data.level4_name}
                          </TableCell>
                        ) : null}
                        {data.level5_name !== EMPTY_STRING ? (
                          <TableCell>{data.level5_name}</TableCell>
                        ) : null}

                        <TableCell
                          className="test-wise-view__table--actions"
                          id="td-center">
                          {data.is_marks_entry_completed ? "Yes" : "-"}
                        </TableCell>
                        <TableCell
                          className="test-wise-view__table--actions"
                          id="td-center">
                          {data.marks_entry_start_date &&
                          toStandardDate(data.marks_entry_start_date) !==
                            toStandardDate(DEFAULT_TIME)
                            ? toStandardDate(data.marks_entry_start_date)
                            : "-"}
                        </TableCell>
                        <TableCell
                          className="test-wise-view__table--actions"
                          id="td-center">
                          {data.marks_entry_end_date &&
                          toStandardDate(data.marks_entry_end_date) !==
                            toStandardDate(DEFAULT_TIME)
                            ? toStandardDate(data.marks_entry_end_date)
                            : "-"}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="test-wise-view__table--actions">
                          {last_level - 1 === 1 && data.level1_name && (
                            <AssignComponent
                              id={data.level1_id}
                              testClassId={data.acd_test_class_id!}
                              isMarksEntryCompleted={
                                data.is_marks_entry_completed!
                              }
                              is_marks_entry_started={
                                data.is_marks_entry_started!
                              }
                              per_std_subj_allocation={
                                data.per_std_subj_allocation!
                              }
                              is_report_generated={data.is_report_generated!}
                              publish_results={data.publish_results!}
                            />
                          )}
                          {last_level - 1 === 2 && data.level2_name && (
                            <AssignComponent
                              id={data.level2_id}
                              testClassId={data && data.acd_test_class_id!}
                              isMarksEntryCompleted={
                                data.is_marks_entry_completed!
                              }
                              is_marks_entry_started={
                                data.is_marks_entry_started!
                              }
                              is_report_generated={data.is_report_generated!}
                              per_std_subj_allocation={
                                data.per_std_subj_allocation!
                              }
                              publish_results={data.publish_results!}
                            />
                          )}
                          {last_level - 1 === 3 && data.level3_name && (
                            <AssignComponent
                              id={data.level3_id}
                              testClassId={data.acd_test_class_id!}
                              isMarksEntryCompleted={
                                data.is_marks_entry_completed!
                              }
                              is_marks_entry_started={
                                data.is_marks_entry_started!
                              }
                              is_report_generated={data.is_report_generated!}
                              per_std_subj_allocation={
                                data.per_std_subj_allocation!
                              }
                              publish_results={data.publish_results!}
                            />
                          )}
                          {last_level - 1 === 4 && data.level4_name && (
                            <AssignComponent
                              id={data.level4_id}
                              testClassId={data.acd_test_class_id!}
                              isMarksEntryCompleted={
                                data.is_marks_entry_completed!
                              }
                              is_marks_entry_started={
                                data.is_marks_entry_started!
                              }
                              is_report_generated={data.is_report_generated!}
                              per_std_subj_allocation={
                                data.per_std_subj_allocation!
                              }
                              publish_results={data.publish_results!}
                            />
                          )}
                          {last_level - 1 === 5 && data.level5_name && (
                            <AssignComponent
                              id={data.level5_id}
                              testClassId={data.acd_test_class_id!}
                              isMarksEntryCompleted={
                                data.is_marks_entry_completed!
                              }
                              is_marks_entry_started={
                                data.is_marks_entry_started!
                              }
                              is_report_generated={data.is_report_generated!}
                              per_std_subj_allocation={
                                data.per_std_subj_allocation!
                              }
                              publish_results={data.publish_results!}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={TreeDataLoading || loading || publishLoading} />
    </>
  );
};

export default View;
