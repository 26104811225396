import { gql } from "@apollo/client";

export const FinyearByInstId = gql`
  query GetFinYrsByInstId($inst_id: ID!, $token: String!) {
    GetFinYrsByInstId(token: $token, inst_id: $inst_id) {
      id
      fin_yr
      fin_st_date
      fin_end_date
      fin_is_curr_yr
    }
  }
`;
export const ByNode = gql`
  query FinancialYearDetailsByNode($financial_yr_id: ID!, $token: String!) {
    node(id: $financial_yr_id, token: $token) {
      id
      ... on MstInstFinYr {
        id
        inst_id
        fin_yr
        fin_st_date
        fin_end_date
      }
    }
  }
`;
export const GetFinYearByFinId = gql`
  query FinancialYearDetailsByNode($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MstInstFinYr {
        id
        inst_id
        fin_yr
        fin_st_date
        fin_end_date
      }
    }
  }
`;

export const GetFinYrActiveByInstId = gql`
  query GetFinYrActiveByInstId($token: String!, $inst_id: ID!) {
    GetFinYrActiveByInstId(token: $token, inst_id: $inst_id) {
      fin_is_curr_yr
      id
      fin_yr
      fin_st_date
      fin_end_date
    }
  }
`;
export const GetFinYrsFuture = gql`
  query GetFinYrsFuture($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetFinYrsFuture(token: $token, inst_id: $inst_id, fin_yr_id: $fin_yr_id) {
      id
      fin_yr
      fin_st_date
      fin_end_date
      fin_is_curr_yr
      inst_id
    }
  }
`;
