import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import useSwConfigData from "../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes, UserType } from "./Enum.types";
import { LogoutUser } from "../queries/DetailsBytoken";
import { useMutation } from "@apollo/client";
import useToken from "../customhooks/useToken";
import { AppContext } from "../context/context";
import { useParams } from "react-router-dom";
import { EMPTY_STRING } from "./constants";

interface IdleTimeOutHandlerProps {
  onActive: () => void;
  onIdle: () => void;
  onLogout: () => void;
  timeOutInterval?: number;
}

const IdleTimeOutHandler: React.FC<IdleTimeOutHandlerProps> = (props) => {
  const [isLogout, setLogout] = useState(false);
  let timer: NodeJS.Timeout | undefined = undefined;
  const events = ["click", "load", "keydown"];
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.INACTIVITY_TIMEOUT
  );
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();

  const [DeleteDeviceId] = useMutation(LogoutUser);
  const eventHandler = (eventType: string) => {
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", dayjs().toString());
      if (timer) {
        props.onActive();
        startTimer();
      }
    }
  };
  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [configData.data]);

  const startTimer = () => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      const lastInteractionTime = localStorage.getItem("lastInteractionTime");
      if (lastInteractionTime) {
        const diff = dayjs().diff(dayjs(lastInteractionTime));
        const timeOutInterval = props.timeOutInterval!;

        if (isLogout) {
          clearTimeout(timer);
        } else {
          if (diff < timeOutInterval) {
            startTimer();
            props.onActive();
          } else {
            props.onIdle();
            handleLogout();
          }
        }
      }
    }, props.timeOutInterval);
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, () => eventHandler(eventName));
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, () => eventHandler(eventName));
    });
  };

  const handleLogout = () => {
    if (token)
      DeleteDeviceId({
        variables: {
          token,
          inst_id: InstId,
          device_token: EMPTY_STRING,
          input: {
            id: state?.claims!.EMPLOYEE
              ? state.empLoginId
              : state?.claims!.PARENT
              ? state.parentId
              : state.studentId,
            user_type: state.claims!.EMPLOYEE
              ? UserType.EMPLOYEE
              : state.claims!.PARENT
              ? UserType.PARENT
              : UserType.STUDENT,
          },
        },
      }).then(({ data }) => {
        if (data && data.LogoutUser) {
          removeEvents();
          clearTimeout(timer);
          setLogout(true);
          props.onLogout();

          signOut(auth)
            .then(() => {
              window.location.href = "/";
            })
            .catch((error) => {
              alert(error);
            });
        }
      });
  };
  return <div></div>;
};

export default IdleTimeOutHandler;
