import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  formatter,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
  toggleFullSceen,
} from "../../../utils/UtilFunctions";
import { Title } from "../../../stories/Title/Title";

import { Button } from "../../../stories/Button/Button";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";

import Settings from "../../../images/Settings.svg";
import Close from "../../../images/Close.svg";

import Input from ".././../../stories/Input/Input";
import Modal from "react-modal";
import {
  PageNumbers,
  StudentListFields,
  PageFor,
  TableHeaders,
  StudentSearchField,
  NonDemand,
  StudentListFor,
  StudentAcctReportType,
  ColumnVisibilityFor,
  InstitutionType,
} from "../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import ConfigurationSettings from "../../Master/configurations/general/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Home from "../Home/Index";
import AccountsHome from "../Home/Index";
import FeeReceipt, { PayerType } from "../StudentDetails/FeeReceipt/Index";
import FeeRefund from "../StudentDetails/RefundAcademicFee/Index";
import DemandFeePayable from "../StudentDetails/DemandFeePayable/Index";
import NonDemandFeePayable from "../StudentDetails/NonDemandFeePayable/Index";
import StudentCompleteFeeReceipt from "../StudentDetails/CompleteFeeReceipt/Index";
import {
  ConfigurationsModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import Enlarge from "../../../images/Enlarge.svg";

import InterchangeReceipt from "../../Accounts/StudentDetails/FeeReceipt/InterchangeReceipt";
import useDropdownData from "../../../customhooks/useDropdownData";
import { Keys } from "../../../utils/Enum.keys";
import { responseType } from "../../../utils/Form.types";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../hooks/useStudentsbyDemandAmount";
import MessageListModal from "../../Channel/MessageList";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import LegendsInStudentList from "../../Master/Student/Components/LegendsInStudentList";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import {
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";
import useStudentTableJson from "../../../json/useStudentTableJson";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { FetchStudentStatusClassName } from "../../Master/Student/List";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import useSwConfigInstType from "../../Academics/hooks/useSwConfigInstType";
interface Props {
  pageType: PageFor;
  studentListFor: StudentListFor;
  queryType: StudentAcctReportType;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const acctStdFilterOptions = [
  { label: "Fresher", value: true },
  { label: "Old Student", value: false },
];
const PassedOutStudentList = ({
  pageType,
  studentListFor,
  queryType,
  setStudentModal,
}: Props) => {
  const { format } = formatter;

  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { institutionType } = useSwConfigInstType();

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [showAllStudent, setShowAllStudent] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [searchData, setSearchData] = useState("");
  const [searchAdm, setSearchAdm] = useState("");
  const [searchType, setSearchType] = useState(StudentSearchField.NAME);

  //states for modal  screens
  const [feeReceiptModal, setFeeReceiptModal] = useState(false);
  const [interChangeFeeReceipt, setInterChangeFeeReceipt] = useState(false);
  const [feeRefundModal, setFeeRefundModal] = useState(false);
  const [editdemandPerstudent, setEditdemandPerstudent] = useState(false);
  const [nonDemandModal, setNonDemandModal] = useState(false);
  const [completeFeeReceiptModal, setCompleteFeeReceiptModal] = useState(false);
  //configuration Modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.STUDENT_LIST_PAGE
  );
  const [messageModal, setMessageModal] = useState(false);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_ACCOUNTS,
  });

  const { students: StudentsData } = UseStudentsbyDemandAmount(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentAcctReportType.PASSED_OUT,
    0,
    EMPTY_STRING,
    null
  );

  const { data, fetchMore, loading } = StudentsData;
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { departmentLabel, branchLabel } = useInstLabels();

  var [studentListOptions, setStudentListOptions] = useState<readonly string[]>(
    [
      StudentListFields.STD_ADMISSION_NO,
      ...(institutionType !== InstitutionType.SCHOOL
        ? [StudentListFields.STD_REG_NO]
        : []),
      StudentListFields.STD_NAME,
      StudentListFields.STD_DEMAND_PAID,
      StudentListFields.STD_DEMAND_TOTAL,
      StudentListFields.STD_DEMAND_BAL,
      StudentListFields.STD_STATUS,
    ]
  );

  useEffect(() => {
    if (
      pageType === PageFor.MODAL &&
      queryType === StudentAcctReportType.FEE_ADVANCE
    ) {
      const options = [
        StudentListFields.STD_ADMISSION_NO,
        ...(institutionType !== InstitutionType.SCHOOL
          ? [StudentListFields.STD_REG_NO]
          : []),
        StudentListFields.STD_NAME,
        StudentListFields.CLASS_ID,
        StudentListFields.CATEGORY_ID,
        StudentListFields.STD_DEPOSIT_AMT,
        StudentListFields.STD_DEPOSIT_ADJUSTED,
        StudentListFields.STD_DEPOSIT_BAL,
        StudentListFields.STD_DEPOSIT_REFUNDED,
        StudentListFields.STD_DEPOSIT_TOTAL,
      ];
      setStudentListOptions(options);
    }
  }, [pageType, queryType]);

  //Categories

  if (USE_CATEGORY_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CATEGORY_ID
    );
  }
  if (USE_CLASS_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CLASS_ID
    );
  }

  const { branchDropDown, departmentDropDown } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchAdm("");
    setSearchData("");
    setSearchType(StudentSearchField.NAME);
  };

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable:header.hideable
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: false,
      std_mobile: false,
      is_login_created: false,
      std_roll_no: false,

      std_demand_ob: false,
      std_demand_amt: false,
      std_demand_concession: false,
      std_demand_refunds: false,
      std_deposit_ob: false,
      std_deposit_amt: false,
      std_deposit_total: false,
      std_deposit_adjusted: false,
      std_deposit_refunded: false,
      std_deposit_bal: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
    });
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.mst_student.std_adm_no,
            std_reg_no: node.mst_student.std_reg_no,
            std_name: node.mst_student.first_name,
            std_father_name: node.mst_student.std_father_name,
            std_mobile: node.mst_student.std_mobile,
            status: node.std_status,
            std_id: node.mst_student.id,
            std_mother_name: node.mst_student.std_mother_name,
            std_sex: node.mst_student.std_sex,
            std_doa: toStandardDate(node.mst_student.std_doa),
            std_dob: toStandardDate(node.mst_student.std_dob),
            std_email: node.mst_student.std_email,
            is_login_created: "",
            dept: node.mst_student.dept.dept_desc,
            branch: node.mst_student.branch.branch_desc,
            class: node.mst_student.class.class_desc,
            sem: node.mst_student.semester.sem_desc,
            sec: node.mst_student.section.section_desc,
            category: node.mst_student.category.cat_desc,
            total:
              node.std_demand_amt === 0
                ? "-"
                : format(
                    node.std_demand_ob +
                      node.std_demand_amt -
                      node.std_demand_concession
                  ),
            std_demand_received:
              node.std_demand_received === 0
                ? "-"
                : format(node.std_demand_received),
            std_demand_ob:
              node.std_demand_ob === 0 ? "-" : format(node.std_demand_ob),
            std_demand_amt:
              node.std_demand_amt === 0 ? "-" : format(node.std_demand_amt),
            std_demand_concession:
              node.std_demand_concession === 0
                ? "-"
                : format(node.std_demand_concession),
            std_demand_refunds:
              node.std_demand_refunds === 0
                ? "-"
                : format(node.std_demand_refunds),
            std_deposit_ob:
              node.std_deposit_ob === 0 ? "-0" : format(node.std_deposit_ob),
            std_deposit_amt:
              node.std_deposit_amt === 0 ? "-" : format(node.std_deposit_amt),
            std_deposit_total:
              node.std_deposit_total === 0
                ? "-"
                : format(node.std_deposit_total),
            std_deposit_adjusted:
              node.std_deposit_adjusted === 0
                ? "-"
                : format(node.std_deposit_adjusted),
            std_demand_bal:
              node.std_demand_bal === 0 ? "-" : format(node.std_demand_bal),
            std_deposit_refunded:
              node.std_deposit_refunded === 0
                ? "-"
                : format(node.std_deposit_refunded),
            std_deposit_bal:
              node.std_deposit_bal === 0 ? "-" : format(node.std_deposit_bal),
            std_type: node.mst_student.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.mst_student.std_adm_no,
            std_reg_no: node.mst_student.std_reg_no,
            std_name: node.mst_student.first_name,
            std_father_name: node.mst_student.std_father_name,
            std_mobile: node.mst_student.std_mobile,
            status: node.std_status,
            std_id: node.mst_student.id,
            std_mother_name: node.mst_student.std_mother_name,
            std_sex: node.mst_student.std_sex,
            std_doa: toStandardDate(node.mst_student.std_doa),
            std_dob: toStandardDate(node.mst_student.std_dob),
            std_email: node.mst_student.std_email,
            is_login_created: "",
            dept: node.mst_student.dept.dept_desc,
            branch: node.mst_student.branch.branch_desc,
            class: node.mst_student.class.class_desc,
            sem: node.mst_student.semester.sem_desc,
            sec: node.mst_student.section.section_desc,
            category: node.mst_student.category.cat_desc,
            total:
              node.std_demand_amt === 0
                ? "-"
                : format(
                    node.std_demand_ob +
                      node.std_demand_amt -
                      node.std_demand_concession
                  ),
            std_demand_received:
              node.std_demand_received === 0
                ? "-"
                : format(node.std_demand_received),
            std_demand_ob:
              node.std_demand_ob === 0 ? "-" : format(node.std_demand_ob),
            std_demand_amt:
              node.std_demand_amt === 0 ? "-" : format(node.std_demand_amt),
            std_demand_concession:
              node.std_demand_concession === 0
                ? "-"
                : format(node.std_demand_concession),
            std_demand_refunds:
              node.std_demand_refunds === 0
                ? "-"
                : format(node.std_demand_refunds),
            std_deposit_ob:
              node.std_deposit_ob === 0 ? "-0" : format(node.std_deposit_ob),
            std_deposit_amt:
              node.std_deposit_amt === 0 ? "-" : format(node.std_deposit_amt),
            std_deposit_total:
              node.std_deposit_total === 0
                ? "-"
                : format(node.std_deposit_total),
            std_deposit_adjusted:
              node.std_deposit_adjusted === 0
                ? "-"
                : format(node.std_deposit_adjusted),
            std_demand_bal:
              node.std_demand_bal === 0 ? "-" : format(node.std_demand_bal),
            std_deposit_refunded:
              node.std_deposit_refunded === 0
                ? "-"
                : format(node.std_deposit_refunded),
            std_deposit_bal:
              node.std_deposit_bal === 0 ? "-" : format(node.std_deposit_bal),
            std_type: node.mst_student.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
              const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcctStdDemand: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcctStdDemand.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_PASS_ACC
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_PASS_ACC,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);
  return (
    <div
      className={
        pageType === PageFor.GENERAL ? "studentlist" : "studentlist--modal"
      }>
      {pageType === PageFor.MODAL ? null : studentListFor ===
        StudentListFor.GENERAL ? (
        <Home DashBoardRequired={false} />
      ) : (
        <AccountsHome DashBoardRequired={false} />
      )}
      <div className="row g-0">
        <div className="col">
          <Title>Passed Out Students List</Title>
        </div>
        <div
          className={
            pageType === PageFor.MODAL
              ? "configuration-settings--modal"
              : "configuration-settings"
          }>
          {pageType === PageFor.MODAL ? (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          ) : (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          )}
        </div>
      </div>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 studentlist__options"
            : "row g-0 studentlist__options--modal"
        }>
        <div className="col-1 studentlist__select-flex studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (searchType === StudentSearchField.ADMISSION_NUMBER) {
                setSearchAdm(e.target.value);
                setSearchData("");
              } else {
                setSearchData(e.target.value);
                setSearchAdm("");
              }
            }}
            value={searchData !== EMPTY_STRING ? searchData : searchAdm}
            onKeyDown={handleFormEvent}
          />
        </div>

        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(null);
                }
                setBranchSelected(null);
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
                setCategorySelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setBranchSelected(null);
                }
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
                setCategorySelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}

        <div className="col"></div>
        {pageType === PageFor.MODAL ? null : (
          <div className="col-1 buttons-flex">
            <>
              <Button
                mode="clear"
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  clear();
                }}
                className="student-clear-button"
              />
            </>
          </div>
        )}
      </form>
      <div className="row g-0">
        <div className="col-5 studentlist__filters">
          {pageType === PageFor.MODAL && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAllStudent}
                    onClick={() => setShowAllStudent(!showAllStudent)}
                  />
                }
                label="All Students"
              />
            </FormGroup>
          )}
        </div>

        <div className="col"></div>
      </div>

      <div
        id={
          pageType === PageFor.MODAL
            ? "studentlist__modal--frame"
            : "studentlist__frame"
        }>
        <div className={`h100 `}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            onCellClick={(params) => {
              if (params.field === "std_name") {
                setStudentModal(false);
                if (
                  pageType === PageFor.GENERAL &&
                  studentListFor === StudentListFor.ACCOUNTS
                ) {
                  setCompleteFeeReceiptModal(!completeFeeReceiptModal);
                }
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: { studentId: params.row.std_id },
                });
              }
            }}
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
      </div>

      <div className="studentlist__buttons">
        {pageType === PageFor.GENERAL ? (
          <div className="button-left">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        ) : (
          <Button mode="cancel" onClick={() => setStudentModal(false)} />
        )}
        <LegendsInStudentList />
        <StudentTotalCount totalCount={data?.GetAcctStdDemand.totalCount!} />
      </div>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.STUDENT_LIST_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>

      {/* fee-receipt */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeReceiptModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeReceipt
              pageType={PageFor.MODAL}
              setModalFlag={setFeeReceiptModal}
              payer={PayerType.ACCOUNTING}
              studentSelected={true}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeReceiptModal(!feeReceiptModal)}
            />
          </div>
        </div>
      </Modal>

      {/* Interchange-fee-Receipt */}

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={interChangeFeeReceipt}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <InterchangeReceipt
              pageType={PageFor.MODAL}
              setModalFlag={setInterChangeFeeReceipt}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setInterChangeFeeReceipt(!interChangeFeeReceipt)}
            />
          </div>
        </div>
      </Modal>

      {/* Refund */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeRefundModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeRefund
              pageType={PageFor.MODAL}
              setModalFlag={setFeeRefundModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeRefundModal(!feeRefundModal)}
            />
          </div>
        </div>
      </Modal>

      {/* NondemandFeePayable */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={nonDemandModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NonDemandFeePayable
              type={NonDemand.ROLE}
              pageType={PageFor.MODAL}
              setModalFlag={setNonDemandModal}
              selectStudent={false}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setNonDemandModal(!nonDemandModal)}
            />
          </div>
        </div>
      </Modal>

      {/* DemandFeePayable */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={editdemandPerstudent}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DemandFeePayable
              pageType={PageFor.MODAL}
              setModalFlag={setEditdemandPerstudent}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditdemandPerstudent(!editdemandPerstudent)}
            />
          </div>
        </div>
      </Modal>

      {/* Student complete fee receipt  */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={completeFeeReceiptModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentCompleteFeeReceipt
              PageType={PageFor.MODAL}
              setModalFlag={setCompleteFeeReceiptModal}
            />
          </div>

          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setCompleteFeeReceiptModal(!completeFeeReceiptModal)
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PassedOutStudentList;
