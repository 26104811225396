import Input from "../../../../stories/Input/Input";

import { useMutation } from "@apollo/client";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import { SectionAllocationModalStyles } from "../../../../styles/ModalStyles";

import {
  getModifiedScrollHeight,
  handleFormEvent,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";

import { Button } from "../../../../stories/Button/Button";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Modal from "react-modal";
import { Operation, TableHeaders } from "../../../../utils/Enum.types";

import {
  DEFAULT_TIME,
  empType,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { Label } from "../../../../stories/Label/Label";
import useToken from "../../../../customhooks/useToken";
import Close from "../../../../images/Close.svg";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

import { SectionAllocationDrawer } from "../../../../styles/DrawerStyles";
import useEmployee, {
  empQueryTypes,
  PayRollEmpEdges,
} from "../../hooks/useEmployee";
import { UpdateEmpStatus } from "../../../../queries/students/mutations/update";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import useHRtable from "../../json/useHRtable";
import {
  GridColDef,
  GridValidRowModel,
  GridAlignment,
  GridCellParams,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import Home from "../../Home/Index";

const ActiveEmp = () => {
  const navigate = useNavigate();

  //useStates
  const { InstId } = useParams();
  const { token } = useToken();
  const { HR_Table } = useHRtable();
  const [allocateSection, setAllocateSection] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);
  const [empIdSet, setEmpIdSet] = useState<Set<number>>(new Set());

  const [empTypeSelected, setempTypeSelected] = useState<optionsType | null>(
    null
  );
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [searchData, setSearchData] = useState("");
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //useRefs
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;

  const { user_details } = useLoggedInUserDetails();
  //useMutations
  const [UpdateEmployeeStatus, { loading: updationLoading }] = useMutation(
    UpdateEmpStatus,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.GENERAL,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0
  );

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAllocateSection(!allocateSection);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    departmentInputRef?.focus();
  };

  const HandleClear = () => {
    setempTypeSelected(null);
    setSearchData("");
    setEmployees([]);
    departmentInputRef?.focus();
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    const getAllEmployeeIds = () =>
      rows
        .map((employee) => employee.nodeId)
        .filter((id) => Number.isInteger(id)) as number[];

    const updatedEmployees = employees?.map((edge) => ({
      ...edge,
      node: {
        ...edge.node,
        isChecked,
      },
    }));

    if (isChecked) {
      const allEmpIds = getAllEmployeeIds();
      setSelectedEmployees(allEmpIds);
      setEmpIdSet(new Set(allEmpIds));
    } else {
      setSelectedEmployees([]);
      setEmpIdSet(new Set());
    }

    setEmployees(updatedEmployees);
  };
  const hanldeEmployeeClick = async (
    params: GridCellParams,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (params.field === "actions") {
      const selectedRow = rows.find((row) => row.id === params.id);

      const tempSet: Set<number> = new Set(empIdSet);
      const checked = e.target.checked;

      if (selectedRow && selectedRow.nodeId) {
        if (checked) {
          tempSet.add(selectedRow.nodeId);
        } else {
          tempSet.delete(selectedRow.nodeId);
        }
        setEmpIdSet(tempSet);
        setSelectedEmployees(Array.from(tempSet));
      }

      // Update selected students
    }

    const mappedDataLeft = employees?.map((row) => {
      if (row.node.id === params.row.nodeId) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: (e.target as HTMLInputElement).checked ? true : false,
          },
        };
      } else {
        return row;
      }
    });

    setEmployees(mappedDataLeft);
  };

  const HandleActiveEmployee = () => {
    UpdateEmployeeStatus({
      variables: {
        token,
        inst_id: InstId,

        user_details,
        pr_emp_ids: employees
          .filter((student) => student.node.isChecked)
          ?.map(({ node }) => node.id),
        status: empTypeSelected?.value,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Status Updated Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        HandleClear();
      }
    });
  };

  const dynamicHeaders: TableHeaderProps[] =
    HR_Table.EmployeeStatus.ActiveEmployee.map((header) => ({
      headerName: header.headerName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [
    {
      headerName: TableHeaders.ACTION,
      align: SLNO_TEXT_ALIGN,
      field: "actions",
      headerAlign: HEADER_TEXT_ALIGN,
      renderCell: (params) => {
        const empId = params.row.nodeId || "";
        return (
          <>
            <Checkbox
              checked={empIdSet.has(Number(empId))}
              onChange={(e) => hanldeEmployeeClick(params, e)}
            />
          </>
        );
      },
      renderHeader: (params) => {
        return (
          <>
            <Checkbox
              checked={empIdSet.size === employees.length}
              onChange={(e) => handleSelectAll(e)}
            />
          </>
        );
      },
    },
    ...dynamicHeaders,
  ];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetPayRollEmp?.edges;
                const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetPayRollEmp: {
                    edges: [...employees, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp?.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = employees.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: index + 1,
            emp_id: node.emp_id,
            emp_name:
              node.emp_first_name +
              " " +
              node.emp_middle_name +
              " " +
              node.emp_last_name,
            emp_status: node.emp_status,
            job_type: node.pr_job_type_details.job_type_desc,
            experience: node.emp_experience,
            response: node,
            nodeId: node.id,
            doj:
              toStandardDate(node.emp_doj) === toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.emp_doj),
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: index + 1,
            emp_id: node.emp_id,
            emp_name:
              node.emp_first_name +
              " " +
              node.emp_middle_name +
              " " +
              node.emp_last_name,
            response: node,
            nodeId: node.id,
            emp_status: node.emp_status,
            job_type: node.pr_job_type_details.job_type_desc,
            experience: node.emp_experience,
            isChecked: node.isChecked,
            doj:
              toStandardDate(node.emp_doj) === toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.emp_doj),
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp?.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <Home DashBoardRequired={false} />

      <Title>Change Employee Status </Title>
      <div className="active-employee">
        <form className="row g-0 ">
          <div className="col-2">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>
        </form>
        <div className="active-employee__tableblock">
          {!employees?.length ? (
            <b className="nodata">Sorry, No Employees Found</b>
          ) : (
            <>
              <StyledDatagrid
                columns={columns}
                rows={rows}
                disableRowSelectionOnClick
                disableChildrenSorting
                rowHeight={TABLE_ROW_HEIGHT}
                hideFooter
              />{" "}
            </>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="make-active"
              onClick={() => setAllocateSection(!allocateSection)}
              disabled={
                !(employees?.filter((data) => data.node.isChecked).length > 0)
              }
            />
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 section-allocation__total">
            <StudentTotalCount
              totalCount={data ? data.GetPayRollEmp?.totalCount! : 0}
            />
          </div>
        </div>

        <SectionAllocationDrawer
          anchor="right"
          open={allocateSection}
          onClose={() => setAllocateSection(!allocateSection)}
        >
          <div className="active-employee__move-to">
            <Title>Selected Employees</Title>
            <div className="row g-0 active-employee__move-to--select">
              <div className="col-4  ">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={empType}
                  value={empTypeSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setempTypeSelected(newValue as optionsType);
                    } else {
                      setempTypeSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      label="Select Type"
                      required={empTypeSelected?.value ? false : true}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>{" "}
            </div>

            <div className="active-employee__move-to--tableblock">
              <TableContainer className="section-allocation__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{TableHeaders.SLNO}</TableCell>
                      <TableCell>Employee ID </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Job Type</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {employees
                      .filter((student) => student.node.isChecked)
                      .map((response, index) => {
                        return (
                          <TableRow key={response.node.id}>
                            <TableCell
                              id="td-center"
                              className="section-allocation__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="section-allocation__table--admno">
                              {response.node.emp_id}
                            </TableCell>

                            <TableCell>
                              {response.node.emp_first_name +
                                " " +
                                response.node.emp_middle_name +
                                " " +
                                response.node.emp_last_name}
                            </TableCell>
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.pr_job_type_details.job_type_desc}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="row g-0">
              <div className="col">
                <Button
                  mode="save"
                  onClick={HandleActiveEmployee}
                  disabled={empTypeSelected?.value ? false : true}
                />
                <Button
                  mode="cancel"
                  onClick={() => {
                    HandleClear();
                    setAllocateSection(!allocateSection);
                  }}
                />
              </div>
              <div className="col flex-end">
                <div className="student-total-count">
                  Selected Employees : <b>{selectedEmployees.length}</b>
                </div>
              </div>
            </div>

            <MessageModal
              modalFlag={message.flag!}
              value={message.message!}
              handleClose={handleClose}
              operation={message.operation!}
            />
            <LoadingModal flag={updationLoading} />
          </div>
        </SectionAllocationDrawer>
      </div>
    </>
  );
};

export default ActiveEmp;
