import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Title } from "../../../stories/Title/Title";

import { Button } from "../../../stories/Button/Button";
import { AppContext } from "../../../context/context";

import { TextField } from "@mui/material";

import Input from ".././../../stories/Input/Input";
import {
  StudentListFields,
  TableHeaders,
  StudentSearchField,
  StudentReportType,
  PageFor,
  ColumnVisibilityFor,
  InstitutionType,
} from "../../../utils/Enum.types";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";

import { StudentsTitlesType } from "../../../Types/Student/json";
import More from "../../../images/More.svg";

import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
// import UseSelect from "../../../customhooks/UseSelect";

import Home from "../Home/Index";
import Message from "../../../images/SendMessage.svg";
import Modal from "react-modal";
import MessageListModal from "../../Channel/MessageList";

import { Keys } from "../../../utils/Enum.keys";
import Close from "../../../images/Close.svg";
import { responseType } from "../../../utils/Form.types";
import { payloadTypes } from "../../../context/reducer";
import useAcdStudentsData, {
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsData";
import useAcdDropDownData from "../../Academics/hooks/useAcdDropDownData";
import LegendsInStudentList from "../../Master/Student/Components/LegendsInStudentList";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import { FetchStudentStatusClassName } from "../../Master/Student/List";
import {
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import useStudentTableJson from "../../../json/useStudentTableJson";
import { TableHeaderProps } from "../../../utils/types";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { TooltipForMultipleOption } from "../../../styles/TooltipStyles";
import useSwConfigInstType from "../../Academics/hooks/useSwConfigInstType";

const { Students } = require("../../../json/title.json");
interface Props {
  pageType: PageFor;
  queryType: StudentReportType;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const StudentList = ({ pageType, queryType, setModal }: Props) => {
  const { institutionType } = useSwConfigInstType();

  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_LIBRARY,
  });
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [searchData, setSearchData] = useState("");
  const [searchAdm, setSearchAdm] = useState("");
  const [searchType, setSearchType] = useState("");
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const sectionRef = useRef<HTMLSelectElement>(null);
  const sectionInputRef = sectionRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  //states for modal  screens

  const { AcademicsStudentData } = useAcdStudentsData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value,
    sectionSelected.value,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    queryType
  );
  const { data, fetchMore, loading } = AcademicsStudentData;
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  var [studentListOptions] = useState<readonly string[]>([
    StudentListFields.STD_ADMISSION_NO,
    ...(institutionType !== InstitutionType.SCHOOL
      ? [StudentListFields.STD_REG_NO]
      : []),
    StudentListFields.STD_NAME,
    StudentListFields.STD_FATHER_NAME,
    StudentListFields.CATEGORY_ID,
    StudentListFields.STD_STATUS,
  ]);

  if (USE_CLASS_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CLASS_ID
    );
  }
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );
  // const studentConfigurationFields = UseSelect(
  //   filterFieldsBasedOnInstConfig(
  //     USE_DEPARTMENT_KEY === false,
  //     USE_BRANCH_KEY === false,
  //     USE_CLASS_KEY === false,
  //     USE_SEMESTER_KEY === false,
  //     USE_SECTION_KEY === false,
  //     USE_CATEGORY_KEY === false
  //   )!,
  //   searchText
  // );

  const clear = () => {
    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSearchAdm("");
    setSearchData("");
    setSearchType("");
  };

  const SearchFields = [
    StudentSearchField.ADMISSION_NUMBER,
    StudentSearchField.NAME,
  ];

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable:header.hideable
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },
    ...(pageType === PageFor.GENERAL
      ? [
          {
            field: "actions",
            cellClassName: "td-status ",
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
            headerName: TableHeaders.ACTION,
            renderCell: (params: GridRenderCellParams) => {
              const studentId = params.row.std_id;

              return (
                <>
                  <TooltipForMultipleOption
                    onClick={() => setEnableToolTipModal(!enableToolTipModal)}
                    placement="left-start"
                    title={
                      enableToolTipModal && (
                        <>
                          <ul>
                            <li
                              onClick={() => {
                                setMessageModal(!messageModal);
                                dispatch({
                                  type: payloadTypes.SET_STUDENT_ID,
                                  payload: { studentId: studentId },
                                });
                              }}
                              className="studentlist__table--more--fee"
                            >
                              <img src={Message} alt="/" />
                              <span> Send Message</span>
                            </li>
                          </ul>
                        </>
                      )
                    }
                  >
                    <img src={More} alt="/" />
                  </TooltipForMultipleOption>
                </>
              );
            },
          },
        ]
      : []),
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: false,
      std_mobile: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
    });
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa: toStandardDate(node.std_doa),
            std_dob: toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: node.is_login_created,
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa: toStandardDate(node.std_doa),
            std_dob: toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: node.is_login_created,
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_ACADEMICS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_ACADEMICS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <div
      className={
        pageType === PageFor.GENERAL ? "studentlist" : "studentlist--modal"
      }
    >
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {Students.Titles.map(
              (title: StudentsTitlesType, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.List}</React.Fragment>
                );
              }
            )}
          </Title>
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 studentlist__options"
            : "row g-0 studentlist__options--modal"
        }
      >
        <div className="col-1 studentlist__select-flex studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (searchType === StudentSearchField.ADMISSION_NUMBER) {
                setSearchAdm(e.target.value);
                setSearchData("");
              } else {
                setSearchData(e.target.value);
                setSearchAdm("");
              }
            }}
            value={searchData !== "" ? searchData : searchAdm}
            onKeyDown={handleFormEvent}
          />
        </div>
        <div className="col-1">
          <LabeledAutocomplete
            className={labelClasses.inputRoot}
            options={SearchFields}
            onKeyDown={(e) => {
              if (e.key === Keys.ENTER) {
                e.preventDefault();
                departmentInputRef?.focus();
              }
              if (e.key === Keys.BACKSPACE) {
                setSearchType("");
              }
            }}
            openOnFocus
            value={searchType}
            onChange={(e, newValue) => {
              setSearchType(newValue as string);
              setHasNextPage(true);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search By"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className={labelClasses.formControlRoot}
              />
            )}
          />
        </div>
        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              ref={departmentRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    branchInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              ref={branchRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    classInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                  setHasNextPage(true)
                } else {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              ref={classRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected) {
                    semInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                  setHasNextPage(true)
                } else {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={semesterDropDown}
              ref={semRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (semesterSelected) {
                    sectionInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue as responseType);
                  setHasNextPage(true)
                } else {
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={semesterLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SECTION_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={sectionDropDown}
              ref={sectionRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    categoryInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue as responseType);
                  setHasNextPage(true)
                } else {
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}

        <div className="col"></div>
        <div className="col-1">
          <Button
            mode="clear"
            onClick={(e: React.FormEvent) => {
              e.preventDefault();
              clear();
            }}
            className={
              pageType === PageFor.GENERAL
                ? "student-clear-button--acd"
                : "student-clear-button--modal"
            }
          />
        </div>
      </div>

      <div
        id={
          pageType === PageFor.MODAL
            ? "studentlist__modal--lib"
            : "studentlist__frame"
        }
      >
        <div className={`h100`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            onCellClick={(params) => {
              if (params.field === "std_name" && pageType === PageFor.MODAL) {
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: params.row.std_id,
                  },
                });
                setModal(false);
              }
            }}
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
      </div>
      <div className="studentlist__buttons">
        {pageType === PageFor.GENERAL ? (
          <div className="button-left">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        ) : (
          <Button mode="cancel" onClick={() => setModal(false)} />
        )}

        <LegendsInStudentList />
        <StudentTotalCount totalCount={data?.GetAcdStudents.totalCount!} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StudentList;
