import React from "react";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../../../styles/DataGridTableStyles";

const useHRtable = () => {
  const tableJson = {
    HR_Table: {
      LoginUser: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name td-onClick",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
          hideable: false,
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Email",
          cellClassName: "td-email",
          field: "email",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "User Type",
          cellClassName: "td-desc",
          field: "user_type",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
      ],
      EnableLoginUserList: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name td-onClick",
          field: "name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
          hideable: false,
        },
        {
          headerName: "Emp-Type",
          cellClassName: "td-desc",
          field: "emp_type",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Email",
          cellClassName: "td-email",
          field: "email",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
      ],
      Attendance: {
        Monthly: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Month's",
            cellClassName: "td-name td-onClick",
            field: "months",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
            hideable: false,
          },
          {
            headerName: "Start Date",
            cellClassName: "td-date",
            field: "s_date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "End Date",
            cellClassName: "td-date",
            field: "end_date",
            headerAlign: HEADER_TEXT_ALIGN,
          },
          {
            headerName: "Status",
            cellClassName: "td-status",
            field: "status",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "General Holidays",
            cellClassName: "td-number",
            field: "general_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Unexcepted Holidays",
            cellClassName: "td-number",
            field: "unexcepected_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Vacation Holidays",
            cellClassName: "td-number",
            field: "vacation_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Weekend Holidays",
            cellClassName: "td-number",
            field: "weekend_holidays",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
        ],
      },
      LeaveLedger: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Description",
          cellClassName: "td-name",
          field: "description",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
          hideable: false,
        },
        {
          headerName: "Short Code",
          cellClassName: "td-desc",
          field: "short_code",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          headerName: "Type",
          cellClassName: "td-desc",
          field: "leave_type",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Only for Women",
          cellClassName: "td-desc",
          field: "only_for_women",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Is Credited",
          cellClassName: "td-desc",
          field: "is_credited",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Is Carry forwarded",
          cellClassName: "td-desc",
          field: "is_carry_forwarded",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Leave is Earned",
          cellClassName: "td-desc",
          field: "leave_is_earned",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
      ],
      EmployeeStatus: {
        ActiveEmployee: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Employee ID",
            field: "emp_id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            field: "emp_name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
            hideable: false,
          },
          {
            headerName: "Status",
            field: "emp_status",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Job Type",
            field: "job_type",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Experience",
            field: "experience",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Date of Joining",
            field: "doj",
            headerAlign: HEADER_TEXT_ALIGN,
          },
        ],
        SelectedEmployees: [
          {
            headerName: "Sl",
            cellClassName: "td-sl-no",
            field: "id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Employee ID",
            field: "emp_id",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
          },
          {
            headerName: "Name",
            field: "emp_name",
            headerAlign: HEADER_TEXT_ALIGN,
            flex: 1,
          },
        ],
      },
    },
  };
  return tableJson;
};

export default useHRtable;
