import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useDropdownData from "../../../../customhooks/useDropdownData";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../../hooks/useStudentsbyDemandAmount";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import {
  StudentAcctReportType,
  StudentSearchField,
} from "../../../../utils/Enum.types";
import { responseType } from "../../../../utils/Form.types";
import {
  formatter,
  getModifiedScrollHeight,
  handleMUISelectEvent,
} from "../../../../utils/UtilFunctions";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../utils/types";
import {
  GridColDef,
  GridAlignment,
  GridCellParams,
} from "@mui/x-data-grid-pro";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { FetchStudentStatusClassName } from "../../../Master/Student/List";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";

const View = () => {
  const { format } = formatter;

  const {
    USE_DEPARTMENT_KEY,
    USE_CATEGORY_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [categorySelected, setCategorySelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const { Accounts_Table } = useAcctTableJson();

  const SearchFields = [
    StudentSearchField.ADMISSION_NUMBER,
    StudentSearchField.NAME,
  ];

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [searchData, setSearchData] = useState("");
  const [searchType, setSearchType] = useState(StudentSearchField.NAME);
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );
  const { categoryDropDown } = useInstMasterDataByInstId();

  const { students: StudentsData } = UseStudentsbyDemandAmount(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value,
    sectionSelected.value,
    categorySelected.value,
    searchData,
    ROWS_PER_PAGE,
    StudentAcctReportType.BY_NEW_DATE
  );
  const { data, fetchMore, loading } = StudentsData;

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctStdDemand?.edges;
                const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcctStdDemand: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctStdDemand.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && students && students?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [students]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) =>
              student.node.mst_student.id === newStudent.node.mst_student.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel,
  } = useInstLabels();

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.ViewLateFeePayment.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        if (header.field === "status") {
          return (
            <div
              className={`td-status__cell ${FetchStudentStatusClassName(
                params.row.status
              )}`}
            >
              {params.row.status}
            </div>
          );
        } else if (header.field === "balance") {
          return (
            <div
              className={`${
                params.row.balance === 0
                  ? "studentlist__table--amount"
                  : "font-red"
              }`}
            >
              {params.row.balance}
            </div>
          );
        }
        return params.value;
      },
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  const tableData = students.map((data, index) => ({
    id: index + 1,
    name:
      data.node.mst_student.first_name +
      " " +
      data.node.mst_student.middle_name +
      " " +
      data.node.mst_student.last_name,
    adm_number: data.node.mst_student.std_adm_no,
    father_name: data.node.mst_student.std_father_name,
    reg_no: data.node.mst_student.std_reg_no,
    year: data.node.mst_student.class.class_desc,
    cat_desc: data.node.mst_student.category.cat_desc,
    demand: format(data.node.std_demand_amt),
    balance: format(data.node.std_demand_bal),
    status: data.node.mst_student.std_status,
    nodeId: data.node.id,
    node: data.node,
  }));

  return (
    <>
      <Title>Student Details</Title>
      <div className="late-fee-payment__view">
        <form className="row g-0 late-fee-payment__view--details">
          <div className="col-2">
            <Input
              placeholder="Search..."
              id="search"
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
            />
          </div>
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={SearchFields}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSearchType(newValue as StudentSearchField);
                  setHasNextPage(true);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search By"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === departmentSelected.value
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected.value) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                (option as responseType).value === branchSelected.value
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected.value) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                (option as responseType).value === classSelected.value
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected.value) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                }
                setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === semesterSelected.value
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected.value) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === sectionSelected.value
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected.value) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  (option as responseType).value === categorySelected.value
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
        </form>

        <div className="late-fee-payment__view--table">
          <StyledDatagrid
            columns={columns}
            rows={tableData}
            hideFooter
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
          />
        </div>
        <div className="col student-total-count flex-end">
          Total : &nbsp;<b>{data ? data.GetAcctStdDemand.totalCount : 0}</b>
        </div>
      </div>
    </>
  );
};

export default View;
