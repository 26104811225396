//React Packages
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";

import { TextField } from "@mui/material";
import { Form, Formik } from "formik";

import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import InputHoc from "../../../../components/common/Input/Index";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { LibraryLabelNameProps } from "../../../../Types/Labels";
import { LibraryTitleProps, LibraryTitles } from "../../../../Types/Titles";

import {
  Direction,
  Operation,
  PageFor,
  PredefinedDataTypes,
  ReturnType,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";

import { useNavigate, useParams } from "react-router-dom";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  CURRENT_PAGE,
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  LIBRARY_ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  ClassificationType,
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import { ClassificationValidation } from "../../../../utils/validationRules";
import DeleteModal from "../../../../pages/DeleteModal";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
  getModifiedScrollHeight,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { ByNode as ClassficationById } from "../../../../queries/Library/Node";
import {
  BookClassificationEdge,
  BookClassificationNode,
} from "../../../../Types/Library/MasterData/Classification/paginationTypes";
import { GetLibBookClassificationsByInstId } from "../../../../queries/Library/MasterData/Classification/list/byInstId";
import { AddLibBookClassification } from "../../../../queries/Library/MasterData/Classification/mutations/new";
import { UpdateLibBookClassification } from "../../../../queries/Library/MasterData/Classification/mutations/update";
import { DeleteLibBookClassificationById } from "../../../../queries/Library/MasterData/Classification/mutations/delete";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import {
  CustomTooltip,
  TOOLTIP_COLORS,
} from "../../../../styles/TooltipStyles";

//Images
import DownArrow from "../../../../images/DownArrow.svg";
import Close from "../../../../images/Close.svg";
import EditProfile from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import Upload from "../../../../images/Upload.svg";
import Add from "../../../../images/Add.svg";

import useLibMasterDataOptions, {
  LibMasterData,
} from "../../customHooks/useLibMasterDataOptions";
import { StudentModalStyles } from "../../../../styles/ModalStyles";

//MasterData for Modals
import Media from "../Media/Index";
import Attribute from "../Attribute/Index";
import Subjects from "../Subjects/Index";
import Publisher from "../Publishers/Index";
import Author from "../Author/Index";
import BookCategory from "../BookCategory/Index";
import Language from "../Languages/Index";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import Input from "../../../../stories/Input/Input";
import useClassification from "../../customHooks/useClassification";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../../utils/types";
import {
  FormAutocomplete,
  formClasses,
  RequiredAutoComplete,
} from "../../../../styles/AutocompleteStyles";

const { Library } = require("../../../../json/title.json");

interface Props {
  type: string;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
export interface BookClassificationNodeData {
  nodes: BookClassificationNode[];
}
export interface BookClassificationNodeVars {
  token: string;
  ids: number[] | number;
}

const Index = ({ type, modalFlag, setModalFlag }: Props) => {
  const { Library_Table } = useLibraryTableJson();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [classificationList, setClassificationList] = useState<
    BookClassificationEdge[]
  >([]);

  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { LibraryFormLabels } = useLibraryformJson();
  const { user_details } = useLoggedInUserDetails();
  const [files, setFiles] = useState<any[]>([]);
  const [searchMedia, setSearchMedia] = useState("");
  const [searchAttribute, setSearchAttribute] = useState("");
  const [searchSubject, setSearchSubject] = useState("");
  const [searchPublsiher, setSearchPublisher] = useState("");
  const [searchAuthor, setSearchAuthor] = useState("");
  const [searchBookCategory, setSearchBookCategory] = useState("");
  const [searchLanguage, setSearchLanguage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy] = useState(SortBy.CLASSIFICATION_DESC);
  const [direction] = useState(Direction.ASC);
  const [searchClassification, setSearchClassification] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [classificationId, setClassificationId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  //Modal Flags
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [addAttributeModal, setAddAttributeModal] = useState(false);
  const [addSubjectModal, setAddSubjectModal] = useState(false);
  const [addPublisherModal, setAddPublisherModal] = useState(false);
  const [addAuthorModal, setAddAuthorModal] = useState(false);
  const [addBookCategoryModal, setAddBookCategoryModal] = useState(false);
  const [addLanguageModal, setAddLanguageModal] = useState(false);
  const [mediaSelected, setMediaSelected] = useState<responseType | null>(null);
  const [attributeSelected, setAttributeSelected] =
    useState<responseType | null>(null);
  const [subjectSelected, setSubjectSelected] = useState<responseType | null>(
    null
  );
  const [publisherSelected, setPublisherSelected] =
    useState<responseType | null>(null);
  const [authorSelected, setAuthorSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [languageSelected, setLanguageSelected] = useState<responseType | null>(
    null
  );

  const [formData, setFormData] = useState<ClassificationType>({
    classification_no: "",
    classification_desc: "",
    classification_date: TODAY_DATE,
    book_published_year: "",
    book_pages: "",
    book_edition: "",
    book_volume: "",
    book_place: "",
    lib_book_classification_id: 0,
    lib_purchase_master_id: 0,
  });
  //customHooks
  const {
    PredefinedData: { dropDown: City },
  } = usePredefinedDataByType(PredefinedDataTypes.CITY, formData?.book_place);

  const { medias } = useLibMasterDataOptions(LibMasterData.MEDIA, searchMedia);

  const { attributes } = useLibMasterDataOptions(
    LibMasterData.ATTRIBUTE,
    searchAttribute
  );
  const { subjects } = useLibMasterDataOptions(
    LibMasterData.SUBJECT,
    searchSubject
  );
  const { publishers } = useLibMasterDataOptions(
    LibMasterData.PUBLISHERS,
    searchPublsiher
  );
  const { authors } = useLibMasterDataOptions(
    LibMasterData.AUTHORS,
    searchAuthor
  );
  const { bookCategories } = useLibMasterDataOptions(
    LibMasterData.CATEGORY,
    searchBookCategory
  );
  const { languages } = useLibMasterDataOptions(
    LibMasterData.LANGUAGES,
    searchLanguage
  );

  //useRefs
  const mediaRef = useRef<HTMLSelectElement>(null);

  const AttributeRef = useRef<HTMLInputElement>(null);
  const AttributeInput = AttributeRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;

  const SubjectRef = useRef<HTMLInputElement>(null);
  const SubjectInput = SubjectRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;

  const PublisherRef = useRef<HTMLInputElement>(null);
  const PublisherInput = PublisherRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;

  const AuthorRef = useRef<HTMLInputElement>(null);
  const AuthorInput = AuthorRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;

  const BookCategoryRef = useRef<HTMLInputElement>(null);
  const BookCategoryInput = BookCategoryRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;

  const LanguageRef = useRef<HTMLInputElement>(null);
  const LanguageInput = LanguageRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;

  const CityRef = useRef<HTMLInputElement>(null);

  const YearRef = useRef<HTMLInputElement>(null);
  const VolumeRef = useRef<HTMLInputElement>(null);
  const VendorRef = useRef<HTMLInputElement>(null);
  const VendorInput = VendorRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const AttachmentRef = useRef<HTMLInputElement>(null);
  const classDescRef = useRef<HTMLInputElement>(null);
  const classificationDescRef = document.getElementsByName(
    "classification_desc"
  )[0] as HTMLInputElement;
  const saveRef = useRef<HTMLButtonElement>(null);

  //Queries
  const {
    classificationsList: {
      queryData: { data, fetchMore },
    },
  } = useClassification(
    searchClassification,
    LIBRARY_ROWS_PER_PAGE,
    ReturnType.QUERY_TYPE,
    null,
    null,
    null,
    null,
    null
  );
  const [
    GetClassification,
    { data: ClassificationData, loading: ClassificationLoading },
  ] = useLazyQuery<BookClassificationNodeData, BookClassificationNodeVars>(
    ClassficationById
  );

  const totalCount = data?.totalCount;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = data
    ? data?.edges.slice(indexOfFirstrepo, indexOfLastrepo)
    : [];
  //Mutations
  const [AddClassification, { loading: creationLoading }] = useMutation(
    AddLibBookClassification,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateClassification, { loading: updationLoading }] = useMutation(
    UpdateLibBookClassification,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteClassification, { loading: deleteLoading }] = useMutation(
    DeleteLibBookClassificationById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //Methods
  const handleClear = () => {
    setFormData({
      classification_no: "",
      classification_desc: "",
      classification_date: TODAY_DATE,
      book_published_year: "",
      book_pages: "",
      book_edition: "",
      book_volume: "",
      book_place: "",
      lib_book_classification_id: 0,
      lib_purchase_master_id: 0,
    });
    classificationDescRef?.focus();
    setOperation(Operation.CREATE);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const HandleSubmit = () => {
    const input = {
      classification_no: removeMoreSpace(formData.classification_no),
      classification_desc: removeMoreSpace(formData.classification_desc),
      classification_date: toIsoDate(formData.classification_date),
      book_published_year: formData.book_published_year,
      book_pages: formData.book_pages,
      book_edition: formData.book_edition,
      book_volume: formData.book_volume,
      book_place: formData.book_place,
      lib_book_media_id: formData.lib_book_media_id,
      lib_book_category_id: formData.lib_book_category_id,
      lib_book_subject_id: formData.lib_book_subject_id,
      lib_book_publisher_id: formData.lib_book_publisher_id,
      lib_book_language_id: formData.lib_book_language_id,
      lib_book_attribute_id: formData.lib_book_attribute_id,
      lib_book_author_id: formData.lib_book_author_id,
    };
    if (operation === Operation.CREATE) {
      AddClassification({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibBookClassificationsByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: LIBRARY_ROWS_PER_PAGE,
              sortBy,
              direction,
              name: EMPTY_STRING,
              authorId: null,
              publisherId: null,
              subjectId: null,
              mediaId: null,
              keywordId: null,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (type === PageFor.MODAL) {
            setModalFlag?.(!modalFlag);
          }
          setMessage({
            flag: true,
            message: "Classification Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateClassification({
        variables: {
          token,
          id: classificationId,
          input,
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetLibBookClassificationsByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: LIBRARY_ROWS_PER_PAGE,
              sortBy,
              direction,
              name: EMPTY_STRING,
              after: null,
            },
          },
          {
            query: ClassficationById,
            variables: {
              token,
              ids: [classificationId],
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          console.log("error");
        }
        setMessage({
          flag: !message.flag,
          message: "Classification Updated Successfully",
          operation: Operation.UPDATE,
        });
      });
    }
  };

  const HandleDelete = (id: number) => {
    DeleteClassification({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibBookClassificationsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            first: LIBRARY_ROWS_PER_PAGE,
            sortBy,
            direction,
            name: EMPTY_STRING,
            authorId: null,
            publisherId: null,
            subjectId: null,
            mediaId: null,
            keywordId: null,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Classification Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleChange = (e: any) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newFile = e.target.files[i];
      newFile["id"] = Math.random();
      setFiles((prevState) => [...prevState, newFile]);
    }
  };

  //Pagination Methods

  useEffect(() => {
    if (
      ClassificationData &&
      !ClassificationLoading &&
      operation === Operation.UPDATE
    ) {
      const {
        classification_desc,
        classification_no,
        classification_date,
        book_published_year,
        book_pages,
        book_edition,
        book_volume,
        book_place,
        book_entry_date,
        lib_book_classification_id,
        lib_book_media_id,
        lib_book_category_id,
        lib_book_subject_id,
        lib_book_publisher_id,
        lib_book_language_id,
        lib_book_attribute_id,
        lib_book_author_id,
        lib_purchase_master_id,
      } = ClassificationData.nodes[0];
      setFormData({
        classification_desc,
        classification_no,
        classification_date: toInputStandardDate(classification_date),
        book_published_year,
        book_pages,
        book_edition,
        book_volume,
        book_place,
        book_entry_date:
          toInputStandardDate(book_entry_date) === DEFAULT_TIME
            ? EMPTY_STRING
            : toInputStandardDate(book_entry_date),
        lib_book_classification_id,
        lib_book_media_id,
        lib_book_category_id,
        lib_book_subject_id,
        lib_book_publisher_id,
        lib_book_language_id,
        lib_book_attribute_id,
        lib_book_author_id,
        lib_purchase_master_id,
      });
    }
  }, [ClassificationData, ClassificationLoading, operation]);

  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Classification.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      headerName: TableHeaders.ACTION,
      renderCell: (params: GridRenderCellParams) => {
        const classificationId = params.row.class_id;

        return (
          <>
            <img
              src={EditProfile}
              alt="/"
              onClick={() => {
                GetClassification({
                  variables: {
                    token,
                    ids: classificationId,
                  },
                }).then(({ data, error }) => {
                  //enableSave should always stay true if edit is clicked
                  if (data) {
                    setClassificationId(classificationId);
                    setOperation(Operation.UPDATE);
                  }
                });
                classificationDescRef.focus();
              }}
            />
            &nbsp;
            <img
              src={Delete}
              alt="/"
              onClick={() => {
                setClassificationId(classificationId);
                setOperation(Operation.DELETE);
                setDeleteModal(!deleteModal);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !ClassificationLoading) {
      const newData = data.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newMedia) => {
          const filteredStudent = classificationList.find(
            (media) => media.node.id === newMedia.node.id
          );
          if (filteredStudent) {
            return {
              ...newMedia,
              node: {
                ...newMedia.node,
              },
            };
          }
          return newMedia;
        });
        setClassificationList(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            classification_no: node.classification_no,
            classification_desc: node.classification_desc,
            classification_copies: node.classification_copies_in,
            classification_copies_available:
              node.classification_copies_avialable,
            no_of_books_available: node.classification_copies_avialable,
            class_id: node.id,
          }))
        );
      } else {
        setClassificationList(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            classification_no: node.classification_no,
            classification_desc: node.classification_desc,
            classification_copies: node.classification_copies_in,
            classification_copies_available:
              node.classification_copies_avialable,
            no_of_books_available: node.classification_copies_avialable,
            class_id: node.id,
          }))
        );
      }
      setEndCursor(data.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, !ClassificationLoading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !ClassificationLoading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges =
                fetchMoreResult.GetLibBookClassificationsByInstId.edges;
              const pageInfo =
                fetchMoreResult.GetLibBookClassificationsByInstId.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck =
                prevResult.GetLibBookClassificationsByInstId.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetLibBookClassificationsByInstId: {
                  edges: [...classificationList, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      {type === PageFor.MODAL ? null : <Home DashBoardRequired={false} />}
      <Title>
        {Library.Titles.Classification.map(
          (title: LibraryTitles, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.Classification}
              </React.Fragment>
            );
          }
        )}
      </Title>

      <Formik
        initialValues={formData}
        validationSchema={ClassificationValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                type === PageFor.MODAL
                  ? "library__frame--modal"
                  : " library__frame"
              }
            >
              <div className="library__frame--row">
                <div className="h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">
                      {Library.Titles.Classification.map(
                        (title: LibraryTitles, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="library__frame--block">
                    {LibraryFormLabels.Classification.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              values={formData[label.inputName]}
                              type={label.dataType}
                              required={label.required}
                              ref={classDescRef!}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="new-book__image-grid">
                      <Label>Media </Label>
                      <RequiredAutoComplete
                        className={formClasses.inputRoot}
                        options={medias!}
                        ref={mediaRef!}
                        value={
                          medias?.find(
                            ({ value }) => value === formData.lib_book_media_id
                          ) ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_media_id: (newValue as responseType)
                                ?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_media_id: 0,
                            }));
                          }
                        }}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            AttributeInput?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_media_id: 0,
                            }));
                          }
                        }}
                        freeSolo
                        openOnFocus
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchMedia(e.target.value);
                            }}
                            required
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <CustomTooltip
                        title="Add New Media"
                        arrow
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() => setAddMediaModal(!addMediaModal)}
                          className="data-fetch-icon"
                        />
                      </CustomTooltip>
                    </div>
                    <div className="new-book__image-grid">
                      <Label>Attribute </Label>
                      <RequiredAutoComplete
                        className={formClasses.inputRoot}
                        options={attributes!}
                        value={
                          attributes?.find(
                            ({ value }) =>
                              value === formData.lib_book_attribute_id
                          ) ?? null
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={(option as responseType).value}>
                              {(option as responseType).label}
                            </li>
                          );
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_attribute_id: (newValue as responseType)
                                ?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_attribute_id: 0,
                            }));
                          }
                        }}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            SubjectInput?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_attribute_id: 0,
                            }));
                          }
                        }}
                        freeSolo
                        openOnFocus
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchAttribute(e.target.value);
                            }}
                            ref={AttributeRef!}
                            required
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <CustomTooltip
                        title="Add New Subject"
                        arrow
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() =>
                            setAddAttributeModal(!addAttributeModal)
                          }
                          className="data-fetch-icon"
                        />
                      </CustomTooltip>
                    </div>
                    <div className="new-book__image-grid">
                      <Label>Subject</Label>
                      <RequiredAutoComplete
                        className={formClasses.inputRoot}
                        options={subjects!}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={(option as responseType).value}>
                              {(option as responseType).label}
                            </li>
                          );
                        }}
                        value={
                          subjects?.find(
                            ({ value }) =>
                              value === formData.lib_book_subject_id
                          ) ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_subject_id: (newValue as responseType)
                                ?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_subject_id: 0,
                            }));
                          }
                        }}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            PublisherInput?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_subject_id: 0,
                            }));
                          }
                        }}
                        freeSolo
                        openOnFocus
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            ref={SubjectRef!}
                            onChange={(e) => {
                              setSearchSubject(e.target.value);
                            }}
                            required
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <CustomTooltip
                        title="Add New Subject"
                        arrow
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() => setAddSubjectModal(!addSubjectModal)}
                          className="data-fetch-icon"
                        />
                      </CustomTooltip>
                    </div>
                    <div className="new-book__image-grid">
                      <Label>Publisher</Label>
                      <RequiredAutoComplete
                        className={formClasses.inputRoot}
                        options={publishers!}
                        value={
                          publishers?.find(
                            ({ value }) =>
                              value === formData.lib_book_publisher_id
                          ) ?? null
                        }
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            AuthorInput?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_publisher_id: 0,
                            }));
                          }
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={(option as responseType).value}>
                              {(option as responseType).label}
                            </li>
                          );
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_publisher_id: (newValue as responseType)
                                ?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_publisher_id: 0,
                            }));
                          }
                        }}
                        freeSolo
                        openOnFocus
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchPublisher(e.target.value);
                            }}
                            required
                            ref={PublisherRef!}
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <CustomTooltip
                        title="Add New Publisher"
                        arrow
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() =>
                            setAddPublisherModal(!addPublisherModal)
                          }
                          className="data-fetch-icon"
                        />
                      </CustomTooltip>
                    </div>
                    <div className="new-book__image-grid">
                      <Label>Author</Label>
                      <RequiredAutoComplete
                        className={formClasses.inputRoot}
                        options={
                          authors! ?? [
                            {
                              label: "",
                              value: 0,
                            },
                          ]
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={(option as responseType).value}>
                              {(option as responseType).label}
                            </li>
                          );
                        }}
                        value={
                          authors?.find(
                            ({ value }) => value === formData.lib_book_author_id
                          ) ?? null
                        }
                        openOnFocus
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            BookCategoryInput?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_author_id: 0,
                            }));
                          }
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_author_id: (newValue as responseType)
                                ?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_author_id: 0,
                            }));
                          }
                        }}
                        freeSolo
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchAuthor(e.target.value);
                            }}
                            required
                            ref={AuthorRef!}
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <CustomTooltip
                        title="Add New Author"
                        arrow
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() => setAddAuthorModal(!addAuthorModal)}
                          className="data-fetch-icon"
                        />
                      </CustomTooltip>
                    </div>
                    <div className="new-book__image-grid">
                      <Label>Book Category</Label>
                      <RequiredAutoComplete
                        className={formClasses.inputRoot}
                        options={
                          bookCategories! ?? [
                            {
                              label: "",
                              value: 0,
                            },
                          ]
                        }
                        value={
                          bookCategories?.find(
                            ({ value }) =>
                              value === formData.lib_book_category_id
                          ) ?? null
                        }
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            LanguageInput?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_category_id: 0,
                            }));
                          }
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={(option as responseType).value}>
                              {(option as responseType).label}
                            </li>
                          );
                        }}
                        freeSolo
                        openOnFocus
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_category_id: (newValue as responseType)
                                ?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_category_id: 0,
                            }));
                          }
                        }}
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchBookCategory(e.target.value);
                            }}
                            required
                            ref={BookCategoryRef!}
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <CustomTooltip
                        title="Add New Book Category"
                        arrow
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() =>
                            setAddBookCategoryModal(!addBookCategoryModal)
                          }
                          className="data-fetch-icon"
                        />
                      </CustomTooltip>
                      <Label>Language</Label>
                      <RequiredAutoComplete
                        className={formClasses.inputRoot}
                        options={languages!}
                        value={
                          languages?.find(
                            ({ value }) =>
                              value === formData.lib_book_language_id
                          ) ?? null
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={(option as responseType).value}>
                              {(option as responseType).label}
                            </li>
                          );
                        }}
                        freeSolo
                        openOnFocus
                        forcePopupIcon
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            YearRef?.current?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_language_id: 0,
                            }));
                          }
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_language_id: (newValue as responseType)
                                ?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_language_id: 0,
                            }));
                          }
                        }}
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchLanguage(e.target.value);
                            }}
                            required
                            ref={LanguageRef}
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                      <CustomTooltip
                        title="Add New Language"
                        arrow
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: TOOLTIP_COLORS.VARIABLE,
                              "& .MuiTooltip-arrow": {
                                color: TOOLTIP_COLORS.VARIABLE,
                              },
                            },
                          },
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() => setAddLanguageModal(!addLanguageModal)}
                          className="data-fetch-icon"
                        />
                      </CustomTooltip>
                    </div>
                    <div className="row g-0 ">
                      <div className="col new-book__two-column-grid">
                        <InputHoc
                          LabelName="Year"
                          name="book_published_year"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          maxLength={4}
                          inputRef={YearRef!}
                          values={formData.book_published_year}
                        />
                      </div>
                      <div className="col new-book__two-column-grid">
                        <InputHoc
                          LabelName="Number of Pages"
                          name="book_pages"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          maxLength={4}
                          values={formData.book_pages}
                        />
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col new-book__two-column-grid">
                        <InputHoc
                          LabelName="Edition"
                          name="book_edition"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          values={formData.book_edition}
                        />
                      </div>
                      <div className="col new-book__two-column-grid">
                        <InputHoc
                          LabelName="Volume"
                          name="book_volume"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          inputRef={VolumeRef!}
                          values={formData.book_volume}
                        />
                      </div>
                    </div>
                    <div className="new-book__label-grid">
                      <Label>Place</Label>
                      <FormAutocomplete
                        className={formClasses.inputRoot}
                        options={City!}
                        value={
                          City?.find(
                            ({ value }) => value === formData.book_place
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              book_place: (newValue as optionsType)?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              book_place: EMPTY_STRING,
                            }));
                          }
                        }}
                        ref={CityRef!}
                        freeSolo
                        openOnFocus
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            saveRef?.current?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              book_place: "",
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setFormData((prevValues) => ({
                                ...prevValues,
                                book_place: e.target.value!,
                              }));
                            }}
                            fullWidth
                            className={formClasses.formControlRoot}
                          />
                        )}
                      />
                    </div>
                    <div className="new-book__label-grid">
                      <Label>Attachment</Label>
                      <div className="assignment-creation__fileattach">
                        <label htmlFor="assignment-creation__inputTag">
                          <img src={Upload} alt="/" className="upload-icon" />
                          <br />
                          Attach Document
                          <input
                            id="assignment-creation__inputTag"
                            type="file"
                            ref={AttachmentRef!}
                            className="assignment-creation__fileattach--input"
                            onChange={handleChange}
                            onKeyDown={(e: React.KeyboardEvent) => {
                              if (e.key === Keys.ENTER) {
                                VendorInput?.focus();
                                e.preventDefault();
                              }
                            }}
                          />
                          <br />
                          <span id="imageName">{files[0]?.name}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-100 booktype-right">
                  <div className="row g-0 library__frame--title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">
                        {Library.Titles.Classification.map(
                          (title: LibraryTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {title.List}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                    </div>
                    <div className="col-3">
                      <Input
                        id="search"
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchClassification(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`library__frame--block`}>
                    {!currentRepo?.length ? (
                      <b className="nodata">Sorry, no results.</b>
                    ) : (
                      <StyledDatagrid
                        columns={columns}
                        rows={rows}
                        rowHeight={TABLE_ROW_HEIGHT}
                        disableRowSelectionOnClick
                        hideFooter
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                <Button mode="save" type="submit" buttonref={saveRef!} />
                <Button mode="clear" type="button" onClick={handleClear} />
                {type === PageFor.GENERAL ? (
                  <Button
                    mode="back"
                    type="button"
                    onClick={() => navigate(-1)}
                  />
                ) : (
                  <Button
                    mode="cancel"
                    type="button"
                    onClick={() => setModalFlag?.(false)}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>

      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deleteLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={classificationId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      {/* Add Media  modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addMediaModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Media
              type={PageFor.MODAL}
              modalFlag={addMediaModal}
              setModalFlag={setAddMediaModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddMediaModal(!addMediaModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* Add Attribute  modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addAttributeModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Attribute
              type={PageFor.MODAL}
              modalFlag={addAttributeModal}
              setModalFlag={setAddAttributeModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddAttributeModal(!addAttributeModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* Add Subjects  modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addSubjectModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Subjects
              type={PageFor.MODAL}
              modalFlag={addSubjectModal}
              setModalFlag={setAddSubjectModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddSubjectModal(!addSubjectModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/*Add Publisher modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addPublisherModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Publisher
              type={PageFor.MODAL}
              modalFlag={addPublisherModal}
              setModalFlag={setAddPublisherModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddPublisherModal(!addPublisherModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/*Add Author modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addAuthorModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Author
              type={PageFor.MODAL}
              modalFlag={addAuthorModal}
              setModalFlag={setAddAuthorModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddAuthorModal(!addAuthorModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* Book Category modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addBookCategoryModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BookCategory
              type={PageFor.MODAL}
              modalFlag={addBookCategoryModal}
              setModalFlag={setAddBookCategoryModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddBookCategoryModal(!addBookCategoryModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* Book Language modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addLanguageModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Language
              type={PageFor.MODAL}
              modalFlag={addLanguageModal}
              setModalFlag={setAddLanguageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddLanguageModal(!addLanguageModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
