import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import More from "../../../images/More.svg";
import RoutesNotAssigned from "../../../images/RoutesNotAssigned.svg";
import TotalRoutes from "../../../images/TotalRoutes.svg";
import TotalStages from "../../../images/TotalStages.svg";
import TotalStudents from "../../../images/TotalStudentsDashboard.svg";
import Close from "../../../images/Close.svg";
import Home from "../Home/Index";
import Input from "../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import Modal from "react-modal";
import ToStudent from "../MasterData/AssignRoute/ToStudent";
import {
  Operation,
  PageFor,
  StudentReportType,
} from "../../../utils/Enum.types";
import AssignBusRouteDetails from "../../../images/AssignBusRouteDetails.svg";

import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { responseType } from "../../../utils/Form.types";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../utils/Enum.keys";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../utils/UtilFunctions";
import { useLazyQuery } from "@apollo/client";
import { GetTransportDashBoardCounts } from "../queries/list";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import useActiveAcademicYear from "../../Academics/hooks/useActiveAcademicYear";
import { payloadTypes } from "../../../context/reducer";
import { AppContext } from "../../../context/context";
import useDropdownData from "../../../customhooks/useDropdownData";

import useInstLabels from "../../../customhooks/general/useInstLabels";
import useTransportTableJson from "../json/useTransportTableJson";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { TooltipForMultipleOption } from "../../../styles/TooltipStyles";

const Index = () => {
  const { Transport_Table } = useTransportTableJson();
  const { token } = useToken();
  const { InstId } = useParams();
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const [assignRoute, setAssignRoute] = useState(false);
  const [searchData, setSearchData] = useState("");

  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const departmentRef = useRef<HTMLSelectElement>(null);
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const sectionRef = useRef<HTMLSelectElement>(null);
  const sectionInputRef = sectionRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const { activeAcademicYearData } = useActiveAcademicYear();

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.STDS_AVAILED_TRANSPORT,
    EMPTY_STRING,
    []
  );

  const pollIntervalTime = usePollIntervalTime();

  const [GetDashBoardData, { data }] = useLazyQuery(
    GetTransportDashBoardCounts,
    {
      variables: {
        token,
        inst_id: InstId,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
      },
      pollInterval: pollIntervalTime,
    }
  );

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData?.loading) {
        AcademicsStudentData?.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: AcademicsStudentData?.data
                  ? AcademicsStudentData.data?.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (AcademicsStudentData?.data && !AcademicsStudentData?.loading) {
      const newData = AcademicsStudentData?.data
        ? AcademicsStudentData.data.GetAcdStudents.edges.map((edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true,
            },
          }))
        : [];

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(
        AcademicsStudentData?.data
          ? AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor
          : EMPTY_STRING
      );
    } // eslint-disable-next-line
  }, [AcademicsStudentData?.data, AcademicsStudentData?.loading]);
  useEffect(() => {
    if (token) {
      GetDashBoardData();
    }
  }, [token, GetDashBoardData]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Transport Dashboard</Title>
      <div className="transport-dashboard">
        <div className="row g-0 transport-dashboard__blocks">
          <div
            className="col transport-dashboard__blocks--cards"
            onClick={() => navigate(`/${InstId}/transport/student/list`)}
          >
            <div className="transport-dashboard__blocks--cards--title">
              <Title variant="subtitle1">Total Students</Title>
              <img src={More} alt="/" />
            </div>
            <div className="transport-dashboard__blocks--cards--images">
              <img src={TotalStudents} alt="/" />
              <span className="transport-dashboard__blocks--cards--total-students">
                {data?.GetTransportDashBoardCounts.total_student}
              </span>
            </div>
          </div>
          <div
            className="col transport-dashboard__blocks--cards"
            onClick={() =>
              navigate(`/${InstId}/transport/masterdata/route/add`)
            }
          >
            <div className="transport-dashboard__blocks--cards--title">
              <Title variant="subtitle1">Total Routes</Title>
              <img src={More} alt="/" />
            </div>
            <div className="transport-dashboard__blocks--cards--images">
              <img src={TotalRoutes} alt="/" />
              <span className="transport-dashboard__blocks--cards--total-routes">
                {data?.GetTransportDashBoardCounts.total_routes}
              </span>
            </div>
          </div>
          <div
            className="col transport-dashboard__blocks--cards"
            onClick={() =>
              navigate(`/${InstId}/transport/masterdata/route/details`)
            }
          >
            <div className="transport-dashboard__blocks--cards--title">
              <Title variant="subtitle1">Total Stages/Stops</Title>
              <img src={More} alt="/" />
            </div>
            <div className="transport-dashboard__blocks--cards--images">
              <img src={TotalStages} alt="/" />
              <span className="transport-dashboard__blocks--cards--total-stages">
                {data?.GetTransportDashBoardCounts.total_stages}
              </span>
            </div>
          </div>
          <div className="col transport-dashboard__blocks--cards">
            <div className="transport-dashboard__blocks--cards--title">
              <Title variant="subtitle1">Routes Not Assigned Students</Title>
              <img src={More} alt="/" />
            </div>
            <div className="transport-dashboard__blocks--cards--images">
              <img src={RoutesNotAssigned} alt="/" />
              <span className="transport-dashboard__blocks--cards--routes-not-assigned">
                {data?.GetTransportDashBoardCounts.not_assigned_total_student}
              </span>
            </div>
          </div>
        </div>
        <Title>Routes Not Assigned Student List</Title>
        <div className="row g-0 transport-dashboard__select">
          <div className="col-2">
            <Input
              id="search"
              type="text"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                ref={departmentRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      branchInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                ref={branchRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      semInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                ref={semRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      sectionInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                ref={sectionRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      categoryInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="transport-dashboard__tableblock">
          {!students?.length && !AcademicsStudentData.loading ? (
            <b className="nodata">Sorry No Students Found</b>
          ) : (
            <TableContainer
              className="transport-dashboard__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Transport_Table.Dashboard.RoutesNotAssignedStudentList.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students?.map((response, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="transport-dashboard__table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="transport-dashboard__table--admno">
                          {response.node.std_adm_no}
                        </TableCell>
                        <TableCell className="transport-dashboard__table--admno">
                          {response.node.std_reg_no}
                        </TableCell>
                        <TableCell>
                          {response.node.first_name +
                            " " +
                            response.node.middle_name +
                            " " +
                            response.node.last_name}
                        </TableCell>
                        <TableCell className="transport-dashboard__table--desc">
                          {response.node.acd_branch.branch_desc}
                        </TableCell>
                        <TableCell className="transport-dashboard__table--desc">
                          {response.node.acd_class.class_desc}
                        </TableCell>

                        <TableCell
                          className="transport-dashboard__table--desc"
                          id="td-center"
                        >
                          <TooltipForMultipleOption
                         
                            placement="left-start"
                            title={
                              <ul>
                                <li
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_STUDENT_ID,
                                      payload: {
                                        studentId: response.node.id,
                                      },
                                    });
                                    setAssignRoute(!assignRoute);
                                  }}
                                  className="studentlist__table--more--fee"
                                >
                                  <img src={AssignBusRouteDetails} alt="/" />{" "}
                                  &nbsp;
                                  <span>Assign Route to Student</span>
                                </li>
                              </ul>
                            }
                          >
                            <img src={More} alt="/" />
                          </TooltipForMultipleOption>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={assignRoute}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ToStudent
              pageType={PageFor.MODAL}
              operation={Operation.CREATE}
              setModalFlag={setAssignRoute}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setAssignRoute(!assignRoute)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
