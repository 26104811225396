import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useAcdStudentDetails from "../../../hooks/useAcdStudentDetails";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import Avatar from "../../../../../images/Avatar.svg";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";

const StudentDetailsComponent = () => {
  const { InstId, studentId } = useParams();
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  const { state } = useContext(AppContext);

  const { studentFormData } = useAcdStudentDetails();
  const [stdImage, setStudentImage] = useState("");
  const { InstDetails } = useInstDetails(0);
  const instName =
    InstDetails &&
    InstDetails.data &&
    InstDetails.data.nodes.length &&
    InstDetails.data.nodes[0].inst_name;

  useEffect(() => {
    getDownloadUrl(
      `${instName}/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`,
      false,
      setStudentImage
    );
  }, [instName, state.studentId, studentId, studentFormData.adm_no]);

  return (
    <div>
      <div className="row g-0 particular-student-results__select">
        <div className="col">
          <div className="particular-student-results__select--image-flex">
            <TextField
              className="particular-student-results__select--textfield"
              label="Admission No"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={studentFormData.adm_no}
            />
          </div>

          <TextField
            className="particular-student-results__select--textfield"
            label="Reg No"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData.reg_number}
          />
          <TextField
            className="particular-student-results__select--textfield"
            label="Date of Birth"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
          />
        </div>
        <div className="col">
          <TextField
            className="particular-student-results__select--textfield"
            label="First Name"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData.std_name}
          />
          <TextField
            className="particular-student-results__select--textfield"
            label="Father Name"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData.father_name}
          />
          <TextField
            className="particular-student-results__select--textfield"
            label={branchLabel}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData.branch}
          />
        </div>
        <div className="col">
          <TextField
            className="particular-student-results__select--textfield"
            label={classLabel}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData.class}
          />
          <TextField
            className="particular-student-results__select--textfield"
            label={categoryLabel}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData.category}
            disabled
          />
          <TextField
            className="particular-student-results__select--textfield"
            label="Academic Year"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData.acd_yr}
          />
        </div>
        <div className="col-1 h-100">
          <div className="particular-student-results__select--student-profile">
            <img src={stdImage ? stdImage : Avatar} alt="/" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetailsComponent;
