import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { EMPTY_STRING, NOTALLOCATED } from "../../../../utils/constants";
import { TextField } from "@mui/material";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import { useContext, useEffect, useState } from "react";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import Avatar from "../../../../images/Avatar.svg";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import useSwConfigInstType from "../../../Academics/hooks/useSwConfigInstType";
import { InstitutionType } from "../../../../utils/Enum.types";

const StudentAcademicDetails = () => {
  const { studentFormData, studentData } = useStudentDatabyId();
  const { studentId } = useParams();
  const { institutionType } = useSwConfigInstType();

  const { InstDetails } = useInstDetails(1);
  const { state } = useContext(AppContext);
  const studentProfiePicUrl = `${
    InstDetails.data?.nodes[0]?.inst_name
  }/students/${
    studentId ? studentId : state.studentId
  }/std_profile_pic/std_profile_pic`;
  const [studentDetails, setStudentDetails] = useState({
    std_adm_no: "",
    std_reg_no: "",
    std_dob: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    std_father_name: "",
    dept_desc: "",
    sem_desc: "",
    sec_desc: "",
    branch_desc: "",
    class_desc: "",
    cat_desc: "",
    acd_yr: "",
    std_profile_filename: "",
  });
  const [imageString, setImageString] = useState("");

  useEffect(() => {
    if (studentFormData) {
      setStudentDetails({
        dept_desc: studentFormData.dept,
        branch_desc: studentFormData.branch,
        cat_desc: studentFormData.category,
        class_desc: studentFormData.class,
        first_name: studentFormData.first_name,
        last_name: studentFormData.last_name,
        sem_desc: studentFormData.semester,
        sec_desc: studentFormData.section,
        middle_name: studentFormData.middle_name,
        std_adm_no: studentFormData.adm_no,
        std_dob: studentFormData.std_dob,
        std_father_name: studentFormData.father_name,
        std_reg_no: studentFormData.reg_number,
        acd_yr: studentFormData.acd_yr,
        std_profile_filename: studentFormData.std_profile_filename,
      });
    }
  }, [studentFormData, studentData.data]);

  useEffect(() => {
    if (studentDetails.std_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentDetails.std_profile_filename, studentFormData, studentData.data]);

  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  return (
    <>
      <div className="preview__details row g-0">
        <div className="col">
          <TextField
            className="preview__details--textfield"
            label="Admission Number"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentDetails.std_adm_no}
            disabled
          />
          {institutionType !== InstitutionType.SCHOOL && (
            <TextField
              className="preview__details--textfield"
              label="Register Number"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentDetails.std_reg_no}
              disabled
            />
          )}
          {studentDetails.branch_desc !== NOTALLOCATED && (
            <TextField
              className="preview__details--textfield"
              label={branchLabel}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentDetails.branch_desc}
              disabled
            />
          )}
        </div>
        <div className="col">
          <TextField
            className="preview__details--textfield"
            label="Name"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={
              studentDetails.first_name +
              " " +
              studentDetails.middle_name +
              " " +
              studentDetails.last_name
            }
            disabled
          />
          <TextField
            className="preview__details--textfield"
            label="Father Name"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentDetails.std_father_name}
            disabled
          />
          {studentDetails.class_desc !== NOTALLOCATED && (
            <TextField
              className="preview__details--textfield"
              label={classLabel}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentDetails.class_desc}
              disabled
            />
          )}
        </div>
        <div className="col">
          <TextField
            className="preview__details--textfield--date"
            label="DOB"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={toStandardDate(studentDetails.std_dob!)}
            disabled
          />
          <TextField
            className="preview__details--textfield"
            label="Academic Year"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentDetails.acd_yr}
            disabled
          />
          {studentDetails.cat_desc !== NOTALLOCATED && (
            <TextField
              className="preview__details--textfield"
              label={categoryLabel}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentDetails.cat_desc}
              disabled
            />
          )}
        </div>
        <div className="col">
          {studentDetails.dept_desc !== NOTALLOCATED && (
            <TextField
              className="preview__details--textfield"
              label="Department"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentDetails.dept_desc}
              disabled
            />
          )}
          {studentDetails.sem_desc !== NOTALLOCATED && (
            <TextField
              className="preview__details--textfield"
              label="Semester"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentDetails.sem_desc}
              disabled
            />
          )}

          {studentDetails.sec_desc !== NOTALLOCATED && (
            <TextField
              className="preview__details--textfield"
              label={"Section"}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentDetails.sec_desc}
              disabled
            />
          )}
        </div>
        <div className="col-1 preview__image h-100">
          {imageString ? (
            <img src={imageString} alt="/" />
          ) : (
            <img src={Avatar} alt="/" />
          )}
        </div>
      </div>
    </>
  );
};

export default StudentAcademicDetails;
