//React imports
import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";

//Material UI
import { TextField } from "@mui/material";

//HTML Hocs
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";

//Styles
import {
  ConfigurationsModalStyles,
  LibraryRepotsModalStyles,
} from "../../../../styles/ModalStyles";

//Images
import Close from "../../../../images/Close.svg";
import Settings from "../../../../images/Settings.svg";
import Eduate from "../../../../images/Eduate_Logo_image.png";

//Custom Hooks
import useDropdownData from "../../../../customhooks/useDropdownData";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useIssuedBook from "../../customHooks/useIssuedBook";

//Types
import {
  BooksReportType,
  ExcelAlignment,
  ExcelFont,
  ExcelPageHeader,
  ExcelSheetsNames,
  LibraryConfigKey,
  PageFor,
  PageNumbers,
  UserType,
} from "../../../../utils/Enum.types";

//Modals
import BookReturn from "../../BooksTransaction/BookReturn/Index";
import ConfigurationSettings from "../../../Master/configurations/general/Index";

import {
  DateRange,
  getDueDate,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  GetNoOfDaysDelayed,
  toInputStandardDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import List from "./List";
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  EMPTY_RESPONSETYPE_OBJECT,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";

//Components
import Home from "../../Home/Index";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import { Keys } from "../../../../utils/Enum.keys";
import { responseType } from "../../../../utils/Form.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
// import * as XLSXStyle from "xlsx-style";
import { useNavigate } from "react-router-dom";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  H4_CELL,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  HEADER_ALIGNMENT_RIGHT,
  TABLE_HEADER_CSS,
} from "../../Constants";
import { libHeaderData } from "../../../Accounts/common/HeaderConsts";
import useServerDateandTime from "../../customHooks/useServerDateandTime";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../context/context";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { BookIssueEdge } from "../../../../Types/Library/BookIssue/paginationTypes";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

interface props {
  type: BooksReportType;
}

const Index = ({ type }: props) => {
  const { state } = useContext(AppContext);

  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [configurationModal, setConfigurationModal] = useState(false);
  const [fromDate, setFormDate] = useState(
    toInputStandardDate(DateRange(TODAY_DATE)?.firstDay!)
  );
  const [toDate, setToDate] = useState(TODAY_DATE);
  const [bookReceivedModal, setBookReceivedModal] = useState(false);
  const [books, setBooks] = useState<BookIssueEdge[]>([]);

  //Queries
  const { configData } = useSwConfigData(
    LibraryConfigKey.LIB_BOOK_NO_OF_BORROWING_DAYS
  );

  const { config_integer_value: DELAY_DAYS_LIMIT } =
    configData.data?.GetSwConfigVariables[0] || {};
  //Custom Hooks
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_REPORTS_PAGE
  );
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );

  const {
    IssuedBooks: {
      data: IssuedBooksData,
      error: IssuedBooksError,
      loading: IssuedBooksLoading,
      fetchMore,
    },
  } = useIssuedBook(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value,
    sectionSelected.value,
    ROWS_PER_PAGE,
    type,
    fromDate,
    toDate
  );

  //refs for enter key
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const sectionRef = useRef<HTMLSelectElement>(null);
  const sectionInputRef = sectionRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const { InstDetails } = useInstDetails(1);

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.TRANSACTION_LIST);

    const dataRows = rows
      ? rows?.map((item) => [
          item.SlNo,
          item.AccessionNo,
          item.Title,
          item.IssuedTo,
          item.IssuedDate,
          item.DueDate,
          item.RecievedDate,
          item.DelayedDays,
          item.TransactionStatus,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();

    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 7;
    worksheet.getColumn(2).width = 18;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 18;
    worksheet.getColumn(6).width = 18;
    worksheet.getColumn(7).width = 18;
    worksheet.getColumn(8).width = 18;
    worksheet.getColumn(9).width = 25;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "H1:H3");
          worksheet.addRow([]);
          worksheet.mergeCells(1, 1, 1, dataRows[0].length);

          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;
          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:I2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:I3");

          const mergedHeader: Cell = worksheet.getCell(D4_CELL);
          mergedHeader.value = ExcelPageHeader.TRANSACTION_LIST;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("D4:G4");
          const mergedDate: Cell = worksheet.getCell(H4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_RIGHT;
          worksheet.mergeCells("H4:I4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:C4");

          let Char = FIRST_INDEX;

          for (let i = 0; i < libHeaderData.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 5);
            rowData.value = libHeaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }

          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.CENTER };
              cell.font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };
              row.getCell(4).alignment = {
                horizontal: ExcelAlignment.LEFT,
              };
            });
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.BOOK_REPORT);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
  };

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const getTitles = () => {
    switch (type) {
      case BooksReportType.BOOKS_ISSUED:
        return <>Currently Issued Books</>;
      case BooksReportType.BOOKS_RETURNED:
        return <>Received Books</>;
      case BooksReportType.BOOKS_ISSUED_AND_RETURNED:
        return <>Issued and Returned Books</>;
      case BooksReportType.BOOKS_OVER_DUE:
        return <>Books OverDue</>;
      case BooksReportType.BOOKS_FINE_COLLECTION:
        return <>Fine Collection</>;
      default:
        return <>Fine Collection</>;
    }
  };
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  const columns: GridColDef[] = [
    {
      headerName: "Sl",
      cellClassName: "td-sl-no",
      field: "id",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
    },
    {
      headerName: "Accession No.",
      cellClassName: "td-sl-no",
      field: "accession_no",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
    },
    {
      headerName: "Title",
      cellClassName: "td-name",
      field: "title",
      headerAlign: HEADER_TEXT_ALIGN,
      flex: 1,
      hideable: false,
    },
    {
      headerName: "Issued to",
      cellClassName: "td-desc",
      field: "issued_to",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      headerName: "Issued Date",
      cellClassName: "td-date",
      field: "issued_date",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
    },
    {
      headerName: "Due Date",
      cellClassName: "td-date",
      field: "due_date",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
    },
    ...(type !== BooksReportType.BOOKS_ISSUED &&
    type !== BooksReportType.BOOKS_OVER_DUE &&
    type !== BooksReportType.BOOKS_FINE_COLLECTION
      ? [
          {
            headerName: "Received Date",
            cellClassName: "td-date",
            field: "received_date",
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
          },
        ]
      : []),

    ...(type !== BooksReportType.BOOKS_ISSUED
      ? [
          {
            headerName: "No. of Days Delayed",
            cellClassName: "td-marks",
            field: "no_of_days_delayed",
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
          },
        ]
      : []),
    ...(type !== BooksReportType.BOOKS_ISSUED &&
    type !== BooksReportType.BOOKS_RETURNED &&
    type !== BooksReportType.BOOKS_OVER_DUE &&
    type !== BooksReportType.BOOKS_FINE_COLLECTION
      ? [
          {
            headerName: "Transaction Status",
            cellClassName: "td-status",
            field: "transaction_status",
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
          },
        ]
      : []),

    ...(type !== BooksReportType.BOOKS_ISSUED &&
    type !== BooksReportType.BOOKS_RETURNED &&
    type !== BooksReportType.BOOKS_ISSUED_AND_RETURNED &&
    type !== BooksReportType.BOOKS_OVER_DUE
      ? [
          {
            headerName: "Fine",
            cellClassName: "td-number",
            field: "fine",
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (IssuedBooksData && !IssuedBooksLoading) {
      const newData = IssuedBooksData.GetLibBookIssued.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newBook) => {
          const filteredStudent = books.find(
            (book) => book.node.id === newBook.node.id
          );
          if (filteredStudent) {
            return {
              ...newBook,
              node: {
                ...newBook.node,
              },
            };
          }
          return newBook;
        });
        setBooks(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            accession_no: node.book_master.book_access_no,
            title: node.book_master.book_title,
            issued_to:
              node.student_details.first_name +
              node.student_details.middle_name +
              node.student_details.last_name,
            issued_date: toStandardDate(node.issue_date),

            due_date: toStandardDate(node.issue_return_date),
            received_date: toStandardDate(node.issue_return_date),
            no_of_days_delayed: GetNoOfDaysDelayed(
              getDueDate(node.issue_date, DELAY_DAYS_LIMIT!)
            ),
            transaction_status: node.issue_returned ? "Returned" : "Issued",
            fine: node.issue_fine_amt,
          }))
        );
      } else {
        setBooks(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            accession_no: node.book_master.book_access_no,
            title: node.book_master.book_title,
            issued_to:
              node.student_details.first_name +
              node.student_details.middle_name +
              node.student_details.last_name,
            issued_date: toStandardDate(node.issue_date),

            due_date: toStandardDate(node.issue_return_date),
            received_date: toStandardDate(node.issue_return_date),
            no_of_days_delayed: GetNoOfDaysDelayed(
              getDueDate(node.issue_date, DELAY_DAYS_LIMIT!)
            ),
            transaction_status: node.issue_returned ? "Returned" : "Issued",
            fine: node.issue_fine_amt,
          }))
        );
      }
      setEndCursor(IssuedBooksData.GetLibBookIssued.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [IssuedBooksData, IssuedBooksLoading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !IssuedBooksLoading) {
          fetchMore({
            variables: {
              first: ROWS_PER_PAGE,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetLibBookIssued.edges;
              const pageInfo = fetchMoreResult.GetLibBookIssued.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetLibBookIssued.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetLibBookIssued: {
                  edges: [...books, ...newEdges],
                  pageInfo,
                  totalCount: IssuedBooksData
                    ? IssuedBooksData.GetLibBookIssued.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>Transaction List</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>

      <div className="library-report">
        <div className="library-report__transaction-list">
          <div className="library-report__transaction-list--tableblock">
            <List />
          </div>
        </div>
        <div className="library-report__selected-data">
          <div className="row g-0 library-report__selected-data--select-flex">
            {USE_DEPARTMENT_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={departmentDropDown}
                  openOnFocus
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      if (departmentSelected) {
                        e.preventDefault();
                        branchInputRef?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={branchDropDown}
                  openOnFocus
                  ref={branchRef!}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      if (branchSelected) {
                        e.preventDefault();
                        classInputRef?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={classDropDown}
                  openOnFocus
                  ref={classRef!}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      if (classSelected) {
                        e.preventDefault();
                        semInputRef?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={classLabel}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={semesterDropDown}
                  openOnFocus
                  ref={semRef!}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      if (semesterSelected) {
                        e.preventDefault();
                        sectionInputRef?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  value={semesterSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterSelected(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={sectionDropDown}
                  openOnFocus
                  ref={sectionRef!}
                  value={sectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionSelected(newValue as responseType);
                      setHasNextPage(true);
                    } else {
                      setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>

          <hr className="dashed" />
          <div className="library-report__selected-data--title">
            <Title variant="subtitle1">{getTitles()} </Title>
            {type !== BooksReportType.BOOKS_OVER_DUE && (
              <div className="library-report__selected-data--filterdate">
                <TextField
                  type="date"
                  className="library-report__selected-data--textfield"
                  label="Received Date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={fromDate}
                  onChange={(e) => {
                    setFormDate(e.target.value);
                  }}
                />
                <div className="vertical-line"></div>
                <TextField
                  type="date"
                  className="library-report__selected-data--textfield"
                  label="To"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
          <div className={`library-report__selected-data--tableblock`}>
            <StyledDatagrid
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              disableRowSelectionOnClick
              hideFooter
            />
          </div>

          {/* book-return modal */}
          <Modal
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            isOpen={bookReceivedModal}
            style={LibraryRepotsModalStyles}
          >
            <div className="modal-flex h-100">
              <div className="modal-flex__data h-100">
                <BookReturn
                  type={PageFor.MODAL}
                  BorrowerType={UserType.STUDENT}
                  setModalFlag={setBookReceivedModal}
                />
              </div>
              <div className="modal-flex__image">
                <img
                  src={Close}
                  alt="/"
                  onClick={() => setBookReceivedModal(!bookReceivedModal)}
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_REPORTS_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <div className="button-left">
        <Button mode="print" />
        <Button onClick={() => downloadExcel()} mode="export" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;
