import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import React, { useContext, useEffect, useState } from "react";
import Close from "../../../images/Close.svg";
import { AppContext } from "../../../context/context";
import {
  Direction,
  LicenseTypes,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import {
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../utils/constants";

import { GetInstsByCustId } from "../../../queries/institution/list/byId";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import { GetFinYrActiveByInstId } from "../../../queries/institution/financialyear/list/byid";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import EditProfile from "../../../images/EditProfile.svg";
import View from "../../../images/Eye.svg";
import Delete from "../../../images/Delete.svg";

import useToken from "../../../customhooks/useToken";
import { payloadTypes } from "../../../context/reducer";
import Modal from "react-modal";
import {
  EditModalCustomStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import EditIndex from "./EditIndex";
import {
  GetInstitutionsByCustIdData,
  GetInstitutionsVars,
  InstitutionEdges,
} from "../../../customhooks/useInstitutionsByCustId";
import ViewInstDetails from "./InstCreation/ViewInstDetails";
import useMasterTableJson from "../../../json/useMasterTableJson";
import { DeleteInstById } from "../../../queries/institution/mutations/delete";
import {
  DeleteInstByIdData,
  DeleteInstByIdVars,
} from "../../../Types/Institution/MutationType";
import { msgType } from "../../../utils/Form.types";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";

const InstitutionList = () => {
  const navigate = useNavigate();
  const { custId } = useParams<{ custId: string }>();

  const { dispatch } = useContext(AppContext);
  const { token } = useToken();

  const [searchData, setSearchData] = useState("");

  const [ActiveFinancialYear] = useLazyQuery(GetFinYrActiveByInstId);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [instId, setInstId] = useState(0);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [institutions, setInstitutions] = useState<InstitutionEdges[]>([]);

  const { Institutions } = useMasterTableJson();
  const { user_details } = useLoggedInUserDetails();

  const dynamicHeaders: TableHeaderProps[] = Institutions.Table_Headers.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "inst_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <img
            src={View}
            alt=""
            onClick={() => {
              dispatch({
                type: payloadTypes.SET_INST_ID,
                payload: {
                  InstId: params.row.instId,
                },
              });
              setViewModal(!viewModal);
            }}
          />
          &nbsp;
          <img
            src={EditProfile}
            alt=""
            onClick={() => {
              dispatch({
                type: payloadTypes.SET_INST_ID,
                payload: {
                  InstId: params.row.instId,
                },
              });
              setEditModal(!editModal);
            }}
          />
          <img
            src={Delete}
            alt=""
            onClick={() => {
              setInstId(Number(params.row.instId));
              setDeleteModal(true);
            }}
          />
        </>
      ),
    },
  ];
  const [DeleteInst, { loading: deleteLoading }] = useMutation<
    DeleteInstByIdData,
    DeleteInstByIdVars
  >(DeleteInstById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [GetInsts, { data, loading, error, fetchMore }] = useLazyQuery<
    GetInstitutionsByCustIdData,
    GetInstitutionsVars
  >(GetInstsByCustId, {
    variables: {
      customer_id: Number(custId),
      name: searchData,
      first: ROWS_PER_PAGE,
      after: null,
      orderBy: { field: SortBy.INST_NAME, direction: Direction.ASC },
      token,
    },
    fetchPolicy: "network-only",
  });

  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteInst({
      variables: {
        token,
        id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetInstsByCustId,
          variables: {
            customer_id: Number(custId),
            name: searchData,
            first: ROWS_PER_PAGE,
            after: null,
            orderBy: { field: SortBy.INST_NAME, direction: Direction.ASC },
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteInstById) {
        setMessage({
          flag: true,
          message: "Institution deleted sucessfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    setMessage(emptyMessageType);
  };

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetInstsByCustId.edges;
                const pageInfo = fetchMoreResult.GetInstsByCustId.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetInstsByCustId.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetInstsByCustId: {
                    edges: [...institutions, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetInstsByCustId.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetInstsByCustId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.instId && row.instId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setInstitutions(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            instId: node.id,
            inst_name: node.inst_name,
            inst_addr: node.inst_place,
            inst_c_person: node.inst_contact_person,
            inst_mobile: node.inst_mobile,
          }))
        );
      } else {
        setInstitutions(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            instId: node.id,
            inst_name: node.inst_name,
            inst_addr: node.inst_place,
            inst_c_person: node.inst_contact_person,
            inst_mobile: node.inst_mobile,
          }))
        );
      }
      setEndCursor(data.GetInstsByCustId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  useEffect(() => {
    if (token) GetInsts();
  }, [token, GetInsts]);
  if (error) return <>{error.message}</>;
  return (
    <>
      <Home DashBoardRequired={false} NavType={LicenseTypes.EDUATE_CUSTOMER} />
      <Title>Institutions</Title>
      <div className="row g-0">
        <div className="col-2 button-left">
          <Input
            id="search"
            type="text"
            placeholder="Search ..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
          />
        </div>
        <div className="col"></div>

        <div className="col-1">
          <Button
            autoFocus
            mode="addnew"
            onClick={() => navigate(`/eduate/${custId}/0/addInstData`)}
            className="container__list--addnew"
          />
        </div>
      </div>
      <div className={`container__list g-0 `}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          onCellClick={async (params) => {
            if (params.field === "inst_name") {
              const financialYearData = await ActiveFinancialYear({
                variables: {
                  inst_id: params.row.instId,
                  token,
                },
              });
              dispatch({
                type: payloadTypes.SET_ACTIVE_FIN_YR,
                payload: {
                  ActiveFinYr: financialYearData.data.GetFinYrActiveByInstId,
                },
              });

              if (financialYearData.data) {
                navigate(`/eduate/${params.row.instId}/instdetails`);
              } else {
                navigate(`/${params.row.instId}/admissions/financialyear`);
              }
              dispatch({
                type: payloadTypes.SET_INST_ID,
                payload: {
                  InstId: params.row.instId,
                },
              });
            }
          }}
          disableRowSelectionOnClick
          rowHeight={TABLE_ROW_HEIGHT}
        />
      </div>
      <div className="button-left">
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editModal}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EditIndex />
            <Button mode="cancel" onClick={() => setEditModal(!editModal)} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditModal(!editModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewInstDetails setModalFlag={setViewModal} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
      <DeleteModal
        id={instId}
        setModalFlag={setDeleteModal}
        modalFlag={deleteModal}
        handleDelete={handleDelete}
      />
      <LoadingModal flag={deleteLoading} />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default InstitutionList;
