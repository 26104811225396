import React, { useEffect, useState } from "react";
import More from "../../../images/More.svg";
import TotalBooksIssued from "../../../images/BooksIssued.svg";
import TotalBooksInLibrary from "../../../images/TotalBooks.svg";
import TotalBooksReturned from "../../../images/BoooksReturned.svg";
import RightArrow from "../../../images/ArrowRight.svg";

import TotalBooksLost from "../../../images/LostBooks.svg";
import TotalFineCollected from "../../../images/FineCollected.svg";
import TotalBooksDamaged from "../../../images/DamagedBooks.svg";
import BooksCategory from "../../../images/BooksCategory.svg";
import Subjects from "../../../images/Subjects.svg";
import Language from "../../../images/Language.svg";
import OverDue from "../../../images/Overdue.svg";
import BooksOnShelf from "../../../images/BooksOnShelf.svg";

import { Title } from "../../../stories/Title/Title";

import { useLazyQuery } from "@apollo/client";
import {
  GetLibBookCounters,
  GetLibBookMediaCounters,
} from "../../../queries/Library/Dashboard/new";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useAcademicYear from "../../Academics/hooks/useActiveAcademicYear";
import {
  GetLibBookCountersData,
  GetLibBookCountersVars,
  GetLibBookMediaCountersData,
  GetLibBookMediaCountersVars,
} from "../../../Types/Library/MasterData/DashBoard/paginationTypes";

import CustomizedCalendar from "../../Academics/calendar/HolidayEntry/CustomizedCalendar";
import {
  AcdYrType,
  ModalType,
} from "../../Academics/calendar/HolidayEntry/Index";
import { TODAY_DATE } from "../../../utils/constants";
import { Operation, TableHeaders } from "../../../utils/Enum.types";
import Media from "../../../images/Media.svg";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import Unassigned from "../../../images/Unassigned_Shelf_Books.svg";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
import { TableHeaderProps } from "../../../utils/types";
import useLibraryTableJson from "../json/useLibraryTableJson";

const Index = () => {
  const { InstId } = useParams();
  const { activeAcademicYearData } = useAcademicYear();
  const { token } = useToken();
  const [acdYr, setAcdYr] = useState<AcdYrType | null>(null);
  const [addModal, setAddModal] = useState<ModalType>({
    date: new Date(TODAY_DATE),
    flag: false,
    operation: Operation.NONE,
  });
  const pollIntervalTime = usePollIntervalTime();
  const { Library_Table } = useLibraryTableJson();

  const [GetDashBoardData, { data: DashBoardData }] = useLazyQuery<
    GetLibBookCountersData,
    GetLibBookCountersVars
  >(GetLibBookCounters, {
    variables: {
      acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
      inst_id: InstId!,
      token,
    },
    pollInterval: pollIntervalTime,
  });

  const booksTransaction = [
    {
      label: "Books Issued",
      image: `${TotalBooksIssued}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_books_issued_today_count
        : 0,
    },
    {
      label: "Books Restored",
      image: `${TotalBooksReturned}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_books_returned_today_count
        : 0,
    },
    {
      label: "Overdue",
      image: `${OverDue}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_books_overdue
        : 0,
    },
    {
      label: "Total Fine Collected",
      image: `${TotalFineCollected}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_fine_collected
        : 0,
    },
  ];

  const booksInformation = [
    {
      label: "Current Books Available In Shelf",
      image: `${BooksOnShelf}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_books_in_shelf
        : 0,
    },

    {
      label: "Unassigned Shelf Books",
      image: `${Unassigned}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_books_not_in_shelf
        : 0,
    },
    {
      label: "Damaged Books",
      image: `${TotalBooksDamaged}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_books_damaged
        : 0,
    },
    {
      label: "Lost Books",
      image: `${TotalBooksLost}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_books_lost
        : 0,
    },
    {
      label: "Total Books In Library",
      image: `${TotalBooksInLibrary}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_lib_book_count
        : 0,
    },
  ];

  const booksAvailable = [
    {
      label: "Total Book Categories",
      image: `${BooksCategory}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_categories
        : 0,
    },
    {
      label: "Total Subjects",
      image: `${Subjects}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_subjects
        : 0,
    },
    {
      label: "Total Languages",
      image: `${Language}`,
      value: DashBoardData
        ? DashBoardData.GetLibBookCounters.total_Languages
        : 0,
    },
    {
      label: "Total Media",
      image: `${Media}`,
      value: DashBoardData ? DashBoardData.GetLibBookCounters.total_media : 0,
    },
  ];
  const [GetMediaData, { data: MediaData }] = useLazyQuery<
    GetLibBookMediaCountersData,
    GetLibBookMediaCountersVars
  >(GetLibBookMediaCounters, {
    variables: {
      inst_id: InstId!,
      token,
    },
    pollInterval: pollIntervalTime,
  });

  let mediaTotalCount = 0;
  MediaData?.GetLibBookMediaCounters.map(
    (data) => (mediaTotalCount = data.lib_books_count + mediaTotalCount)
  );
  useEffect(() => {
    if (token) {
      GetDashBoardData();
    }
  }, [token, GetDashBoardData]);
  useEffect(() => {
    if (token) {
      GetMediaData();
    }
  }, [token, GetMediaData]);

  useEffect(() => {
    if (activeAcademicYearData.data && !activeAcademicYearData.loading) {
      setAcdYr({
        end_date: new Date(
          activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_end_date
        ),
        label: activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_yr,
        start_date: new Date(
          activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_st_date
        ),
        value: 0,
      });
    }
  }, [activeAcademicYearData.data, activeAcademicYearData.loading]);

  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Dashboard.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={params.row.status === true ? "font-green" : "font-red"}
          >
            {params.row.status === true ? "Active" : "Inactive"}
          </div>
        );
      },
    }));

  const columns: GridColDef[] = [...dynamicHeaders];
  const rows: GridValidRowModel[] =
    (MediaData?.GetLibBookMediaCounters || []).map((response, index) => {
      return {
        id: index + 1,
        name: response.media_desc,
        abbr: response.media_abbrevation,
        status: response.media_is_active,
        total: response.lib_books_count,
      };
    }) || [];
  return (
    <>
      <Title>Library Dashboard</Title>
      <div className="library-dashboard">
        <div className="library-dashboard__cards row g-0">
          <div className="library-dashboard__cards--grid col">
            <div className="library-dashboard__cards--grid--cardtitle">
              <Title variant="subtitle1">Today's Transaction</Title>
              <img src={More} alt="/" />
            </div>
            <div>
              <ul>
                {booksTransaction.map((res, index) => {
                  return (
                    <li
                      key={index}
                      className="library-dashboard__cards--grid--li"
                    >
                      <div>
                        <img
                          src={res.image}
                          alt="/"
                          className="library-dashboard__cards--grid--image"
                        />
                        <span>{res.label}</span>
                      </div>
                      <div>
                        <b>{res.value}</b>
                        <img src={RightArrow} alt="/" />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="library-dashboard__cards--grid col">
            <div className="library-dashboard__cards--grid--cardtitle">
              <Title variant="subtitle1">Complete Information of Library</Title>
              <img src={More} alt="/" />
            </div>
            <div>
              <ul>
                {booksInformation.map((res, index) => {
                  return (
                    <li
                      key={index}
                      className="library-dashboard__cards--grid--li"
                    >
                      <div>
                        <img
                          src={res.image}
                          alt="/"
                          className="library-dashboard__cards--grid--image"
                        />
                        <span>{res.label}</span>
                      </div>
                      <div>
                        <b>{res.value}</b>
                        <img src={RightArrow} alt="/" />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="library-dashboard__cards--grid col">
            <div className="library-dashboard__cards--grid--cardtitle">
              <Title variant="subtitle1">Types of Books Available</Title>
              <img src={More} alt="/" />
            </div>
            <div>
              <ul>
                {booksAvailable.map((res, index) => {
                  return (
                    <li
                      key={index}
                      className="library-dashboard__cards--grid--li"
                    >
                      <div>
                        <img
                          src={res.image}
                          alt="/"
                          className="library-dashboard__cards--grid--image"
                        />
                        <span>{res.label}</span>
                      </div>
                      <div>
                        <b>{res.value}</b>
                        <img src={RightArrow} alt="/" />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="row g-0 library-dashboard__datablock">
          <div className="col-8 library-dashboard__datablock--grid">
            <div className="library-dashboard__cards--grid--cardtitle">
              <Title variant="subtitle1">
                Unallocated / Restored Books for Shelf Allocation
              </Title>
              <div className="library-dashboard__table--totalcount">
                <span>Total Books :</span>{" "}
                <b>{MediaData?.GetLibBookMediaCounters.length}</b>
                <img src={More} alt="/" />
              </div>
            </div>
            <div className={`library-dashboard__tableblock`}>
              <StyledDatagrid
                columns={columns}
                rows={rows}
                disableRowSelectionOnClick
                disableChildrenSorting
                rowHeight={TABLE_ROW_HEIGHT}
                hideFooter
              />
            </div>
          </div>
          <div className="col library-dashboard__datablock--grid">
            <div className="library-dashboard__cards--grid--cardtitle">
              <Title variant="subtitle1">Calendar</Title>
              <img src={More} alt="/" />
            </div>
            <CustomizedCalendar
              acdYr={acdYr!}
              date={addModal.date}
              setAddmodal={setAddModal}
            />
          </div>
        </div>

        {/* <div className="library-dashboard__datablock">
          <div className="library-dashboard__datablock--grid">
            <div className="library-dashboard__datablock--grid--title-with-tabs">
              <Title variant="subtitle1">Total Books</Title>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                variant="fullWidth"
                textColor="secondary"
              >
                <Tab label="Today" value={0} {...a11yProps(0)} />

                <Tab label="Month" value={1} {...a11yProps(1)} />
              </Tabs>
              <img src={More} alt="/" />
            </div>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div className="accounts-dashboard__details--block--details">
                  <img src={TotalBooksInLibrary} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--details--total">
                      {DashBoardData?.GetLibBookCounters.total_lib_book_count}
                    </span>
                  </div>
                </div>
                <div className="accounts-dashboard__details--block--g-count">
                  <div className="accounts-dashboard__details--block--g-count--flex separator">
                    <img src={TotalBooksIssued} alt="/" />
                    <div className="accounts-dashboard__details--block--g-count--block">
                      <span className="accounts-dashboard__details--block--g-count--block--text">
                        Issued
                      </span>
                      <span className="accounts-dashboard__details--block--g-count--block--total">
                        {DashBoardData &&
                          DashBoardData.GetLibBookCounters
                            .total_lib_books_issued_today_count}
                      </span>
                    </div>
                  </div>
                  <div className="accounts-dashboard__details--block--g-count--flex">
                    <img src={TotalBooksReturned} alt="/" />
                    <div className="accounts-dashboard__details--block--g-count--block">
                      <span className="accounts-dashboard__details--block--g-count--block--text">
                        Returned
                      </span>
                      <span className="accounts-dashboard__details--block--g-count--block--total">
                        {DashBoardData &&
                          DashBoardData.GetLibBookCounters
                            .total_lib_books_returned_today_count}
                      </span>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <div className="accounts-dashboard__details--block--details">
                  <img src={TotalBooksInLibrary} alt="/" />
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--details--total">
                      {DashBoardData &&
                        DashBoardData.GetLibBookCounters.total_lib_book_count}
                    </span>
                  </div>
                </div>
                <div className="accounts-dashboard__details--block--g-count">
                  <div className="accounts-dashboard__details--block--g-count--flex separator">
                    <img src={TotalBooksIssued} alt="/" />
                    <div className="accounts-dashboard__details--block--g-count--block">
                      <span className="accounts-dashboard__details--block--g-count--block--text">
                        Issued
                      </span>
                      <span className="accounts-dashboard__details--block--g-count--block--total">
                        {DashBoardData &&
                          DashBoardData.GetLibBookCounters
                            .total_lib_books_issued_today_count}
                      </span>
                    </div>
                  </div>
                  <div className="accounts-dashboard__details--block--g-count--flex">
                    <img src={TotalBooksReturned} alt="/" />
                    <div className="accounts-dashboard__details--block--g-count--block">
                      <span className="accounts-dashboard__details--block--g-count--block--text">
                        Returned
                      </span>
                      <span className="accounts-dashboard__details--block--g-count--block--total">
                        {
                          DashBoardData?.GetLibBookCounters
                            .total_lib_books_returned_today_count
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </SwipeableViews>
          </div>

          <div className="library-dashboard__datablock--grid">
            <div className="library-dashboard__datablock--grid--title">
              <Title variant="subtitle1">Total Lost Books</Title>

              <img src={More} alt="/" />
            </div>

            <div className="library-dashboard__datablock--details">
              <img src={TotalBooksLost} alt="/" />
              <div className="library-dashboard__datablock--g-count--block">
                <span className="library-dashboard__datablock--details--total">
                  {DashBoardData?.GetLibBookCounters.total_lib_books_lost}
                </span>
              </div>
            </div>
            <div className="library-dashboard__datablock--g-count">
              <div className="library-dashboard__datablock--g-count--flex separator">
                <img src={BooksReserved} alt="/" />
                <div className="library-dashboard__datablock--g-count--block">
                  <span className="library-dashboard__datablock--g-count--block--text">
                    Reserved
                  </span>
                  <span className="library-dashboard__datablock--g-count--block--total">
                    0
                  </span>
                </div>
              </div>
              <div className="library-dashboard__datablock--g-count--flex">
                <img src={BooksOnShelf} alt="/" />
                <div className="library-dashboard__datablock--g-count--block">
                  <span className="library-dashboard__datablock--g-count--block--text">
                    OnShelf
                  </span>
                  <span className="library-dashboard__datablock--g-count--block--total">
                    0
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="library-dashboard__datablock--grid">
            <div className="library-dashboard__datablock--grid--title">
              <Title variant="subtitle1">Total Books Category</Title>

              <img src={More} alt="/" />
            </div>

            <div className="library-dashboard__datablock--details">
              <img src={BooksCategory} alt="/" />
              <div className="library-dashboard__datablock--g-count--block">
                <span className="library-dashboard__datablock--details--total">
                  0
                </span>
              </div>
            </div>
            <div className="library-dashboard__datablock--g-count">
              <div className="library-dashboard__datablock--g-count--flex separator">
                <img src={Subjects} alt="/" />
                <div className="library-dashboard__datablock--g-count--block">
                  <span className="library-dashboard__datablock--g-count--block--text">
                    Subjects
                  </span>
                  <span className="library-dashboard__datablock--g-count--block--total">
                    0
                  </span>
                </div>
              </div>
              <div className="library-dashboard__datablock--g-count--flex">
                <img src={Language} alt="/" />
                <div className="library-dashboard__datablock--g-count--block">
                  <span className="library-dashboard__datablock--g-count--block--text">
                    Language
                  </span>
                  <span className="library-dashboard__datablock--g-count--block--total">
                    0
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="library-dashboard__datablock--grid">
            <div className="library-dashboard__datablock--grid--title">
              <Title variant="subtitle1">Total Fine Collected</Title>

              <img src={More} alt="/" />
            </div>

            <div className="accounts-dashboard__details--block--details">
              <img src={TotalFineCollected} alt="/" />
              <div className="accounts-dashboard__details--block--g-count--block">
                <span className="accounts-dashboard__details--block--details--total">
                  {DashBoardData?.GetLibBookCounters.total_lib_fine_collected}{" "}
                </span>
              </div>
            </div>
            <div className="accounts-dashboard__details--block--g-count">
              <div className="accounts-dashboard__details--block--g-count--flex separator">
                <img src={OverDue} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    OverDue
                  </span>
                  <span className="accounts-dashboard__details--block--g-count--block--total">
                    {DashBoardData?.GetLibBookCounters.total_lib_books_overdue}
                  </span>
                </div>
              </div>
              <div className="accounts-dashboard__details--block--g-count--flex">
                <img src={TotalBooksDamaged} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    Damaged
                  </span>
                  <span className="accounts-dashboard__details--block--g-count--block--total">
                    {DashBoardData?.GetLibBookCounters.total_lib_books_damaged}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="row g-0 library-dashboard__tableblock">
          <div className="col library-dashboard__tableblock--grid">
            <div className="library-dashboard__datablock--grid--title">
              <Title variant="subtitle1">Overall Graph</Title>
              <img src={More} alt="/" />
            </div>
            <div className="library-dashboard__tableblock--graph">
              <ReactEcharts
                option={options}
                className="library-dashboard__tableblock--chart"
              />
            </div>
          </div>
          <div className="col library-dashboard__tableblock--grid">
            <div className="library-dashboard__datablock--grid--title">
              <Title variant="subtitle1">Media</Title>
              <img src={More} alt="/" />
            </div>
            <TableContainer className="library-dashboard__tableblock--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Library_Table.Dashboard.Media.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {MediaData?.GetLibBookMediaCounters.map((data, index) => {
                    return (
                      <TableRow>
                        <TableCell id="td-center">{index + 1}</TableCell>
                        <TableCell>{data.media_desc}</TableCell>
                        <TableCell>{data.media_abbrevation}</TableCell>
                        <TableCell
                          className={
                            data.media_is_active ? "font-green" : "font-red"
                          }
                        >
                          {data.media_is_active ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell id="td-right" className="font-green">
                          {data.lib_books_count}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={4} className="total">
                      Total Books :
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {mediaTotalCount}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Index;
