import React, { useEffect, useMemo, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { ViewPickedSubjectModalStyles } from "../../../../styles/ModalStyles";

import ToppersList from "./ToppersList";
import { useLazyQuery } from "@apollo/client";
import { GetAcdTestNameByIdData } from "../../Test/TestCreation/Index";
import { singleNodeVars } from "../../../../Types/Accounting";
import { GetAcdTestNameById } from "../../queries/test/query";
import useToken from "../../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../../utils/constants";
import useAcdInstTreeForTestReport, {
  RankInfo,
} from "../../hooks/useAcdInstTreeForTestReport";
import { responseType } from "../../../../utils/Form.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import {
  Levels,
  TableHeaders,
  ToppersType,
} from "../../../../utils/Enum.types";
import Eye from "../../../../images/EyeWhite.svg";
import Avatar from "../../../../images/Avatar.svg";

import { CustomModalProps } from "../../Dashboard/Teacher/Index";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
export interface AssignComponentProp {
  id: number;
  testClassId: number;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onRequestClose,
  contentComponent,
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={ViewPickedSubjectModalStyles}
      ariaHideApp={false}
      onRequestClose={onRequestClose}>
      {contentComponent}
    </Modal>
  );
};
export interface TreeType {
  level1: string;
  level2: string;
  level3: string;
  level4: string;
  level5: string;
  level1_name: string;
  level1_rowspan: number;
  level2_name: string;
  level2_rowspan: number;
  level3_name: string;
  level3_span: number;
  level4_name: string;
  level4_span: number;
  level5_name: string;
  level1_id: number;
  level2_id: number;
  level3_id: number;
  level4_id: number;
  level5_id: number;
  filterThis?: boolean;
  is_report_generated?: boolean;
  contains_topper_details?: boolean;
  sub_acd_level_total_count?: number;
  first_rank_info?: RankInfo[];
  second_rank_info?: RankInfo[];
  third_rank_info?: RankInfo[];
  acd_test_class_id?: number;
  is_marks_entry_started?: boolean;
  is_marks_entry_completed?: boolean;
  marks_entry_start_date?: string;
  marks_entry_end_date?: string;
  publish_results?: boolean;
  per_std_subj_allocation?: boolean;
}

const ViewTestWise = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId, testId } = useParams();

  const [firstRankToppers, setFirstRankToppers] = useState(false);
  const [secondRankToppers, setSecondRankToppers] = useState(false);
  const [thirdRankToppers, setThirdRankToppers] = useState(false);
  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { data: TreeData, loading } = useAcdInstTreeForTestReport(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    testId ? Number(testId) : 0
  );

  let idsSet = new Set();
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY && displayClass) {
      final++;
    }
    if (USE_SEMESTER_KEY && displaySemester) {
      final++;
    }
    if (USE_SECTION_KEY && displaySection) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    displayClass,
    displaySemester,
    displaySection,
  ]);

  const [GetTestTypeDetails, { data: TestName }] = useLazyQuery<
    GetAcdTestNameByIdData,
    singleNodeVars
  >(GetAcdTestNameById);
  useEffect(() => {
    if (testId && token) {
      GetTestTypeDetails({
        variables: { id: testId ? Number(testId) : 0, token },
      });
    }
  }, [testId, GetTestTypeDetails, token]);

  const modifiedArrayForTable: TreeType[] = TreeData
    ? TreeData?.GetAcdInstTreeForTestReport.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.map((level5) => {
                        let result = {
                          level1: level1.acd_level_1,
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2: level2.acd_level_2,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3: level3.acd_level_3,

                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4: level4.acd_level_4,

                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5: level5.acd_level_5,

                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          filterThis: false,

                          first_rank_info: [
                            ...level5.first_rank_info.map((res) => ({
                              ...res,
                            })),
                          ],
                          second_rank_info: [
                            ...level5.second_rank_info.map((res) => ({
                              ...res,
                            })),
                          ],
                          third_rank_info: [
                            ...level5.first_rank_info.map((res) => ({
                              ...res,
                            })),
                          ],
                          acd_test_class_id: level5.acd_test_class_id,
                          contains_topper_details:
                            level5.contains_topper_details,
                          sub_acd_level_total_count:
                            level5.sub_acd_level_total_count,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1: level1.acd_level_1,
                        level2: level2.acd_level_2,
                        level3: level3.acd_level_3,
                        level4: level4.acd_level_4,
                        level5: EMPTY_STRING,
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_id: level1.acd_level_1_id,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level2_id: level2.acd_level_2_id,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level3_id: level3.acd_level_3_id,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count
                          ? level4.sub_acd_level_total_count
                          : 1,
                        level4_id: level4.acd_level_4_id,
                        level5_name: EMPTY_STRING,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),

                        first_rank_info: [
                          ...level4.first_rank_info.map((res) => ({
                            ...res,
                          })),
                        ],
                        second_rank_info: [
                          ...level4.second_rank_info.map((res) => ({
                            ...res,
                          })),
                        ],
                        third_rank_info: [
                          ...level4.third_rank_info.map((res) => ({
                            ...res,
                          })),
                        ],
                        acd_test_class_id: level4.acd_test_class_id,
                        contains_topper_details: level4.contains_topper_details,
                        sub_acd_level_total_count:
                          level4.sub_acd_level_total_count,
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1: level1.acd_level_1,
                    level2: level2.acd_level_2,
                    level3: level3.acd_level_3,
                    level4: EMPTY_STRING,
                    level5: EMPTY_STRING,
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_id: level1.acd_level_1_id,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level2_id: level2.acd_level_2_id,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level3_id: level3.acd_level_3_id,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level4_id: 0,
                    level5_name: EMPTY_STRING,
                    level5_id: 0,
                    filterThis: !(last_level <= 4) || !(last_level <= 5),

                    first_rank_info: [
                      ...level3.first_rank_info.map((res) => ({
                        ...res,
                      })),
                    ],
                    second_rank_info: [
                      ...level3.second_rank_info.map((res) => ({
                        ...res,
                      })),
                    ],
                    third_rank_info: [
                      ...level3.third_rank_info.map((res) => ({
                        ...res,
                      })),
                    ],
                    acd_test_class_id: level3.acd_test_class_id,
                    contains_topper_details: level3.contains_topper_details,
                    sub_acd_level_total_count: level3.sub_acd_level_total_count,
                  };
                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1: level1.acd_level_1,
                level2: level2.acd_level_2,
                level3: EMPTY_STRING,
                level4: EMPTY_STRING,
                level5: EMPTY_STRING,
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_id: level1.acd_level_1_id,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level2_id: level2.acd_level_2_id,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level3_id: 0,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level4_id: 0,
                level5_name: EMPTY_STRING,
                level5_id: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                first_rank_info: [
                  ...level2.first_rank_info.map((res) => ({
                    ...res,
                  })),
                ],
                second_rank_info: [
                  ...level2.second_rank_info.map((res) => ({
                    ...res,
                  })),
                ],
                third_rank_info: [
                  ...level2.third_rank_info.map((res) => ({
                    ...res,
                  })),
                ],

                acd_test_class_id: level2.acd_test_class_id,
                contains_topper_details: level2.contains_topper_details,
                sub_acd_level_total_count: level2.sub_acd_level_total_count,
              };
              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1: level1.acd_level_1,
            level2: EMPTY_STRING,
            level3: EMPTY_STRING,
            level4: EMPTY_STRING,
            level5: EMPTY_STRING,
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_id: level1.acd_level_1_id,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level2_id: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level3_id: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level4_id: 0,
            level5_name: EMPTY_STRING,
            level5_id: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];
  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  const checkTodisplay = (level: Levels, level_string: string) => {
    switch (level) {
      case Levels.LEVEL_1:
        if (level_string === "DEPARTMENT") return true;
        if (level_string === "BRANCH") return true;
        else if (level_string === "CLASS" && displayClass) return true;
        else if (level_string === "SEM" && displaySemester) return true;
        else return displaySection;
      case Levels.LEVEL_2:
        if (level_string === "BRANCH") return true;
        else if (level_string === "CLASS" && displayClass) return true;
        else if (level_string === "SEM" && displaySemester) return true;
        else return displaySection;
      case Levels.LEVEL_3:
        if (level_string === "CLASS" && displayClass) return true;
        else if (level_string === "SEM" && displaySemester) return true;
        else return displaySection;
      case Levels.LEVEL_4:
        if (level_string === "SEM" && displaySemester) return true;
        else return displaySection;
      case Levels.LEVEL_5:
        return displaySection;
      default:
        return false;
    }
  };

  const AssignComponent = ({ id, testClassId }: AssignComponentProp) => {
    return (
      <>
        <button
          onClick={() =>
            navigate(
              `/${InstId}/academics/reports/testwise/${testId}/${id}/${testClassId}/detailedreport`
            )
          }
          className="test-wise-view__table--view">
          View Detailed Report <img src={Eye} alt="" />
        </button>
      </>
    );
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Test Wise Toppers List</Title>
      <div className="test-wise-view">
        <div className="test-wise-view__select row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY && displayClass ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY && displaySemester ? (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY && displaySection ? (
            <div className="col-2">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="test-wise-view__title">
          <b>{TestName ? TestName.node.test_name : EMPTY_STRING}</b>
          <span>
            (
            {TestName
              ? TestName.node.test_type_details.test_type_name
              : EMPTY_STRING}{" "}
            )
          </span>
        </div>
        <div className="test-wise-view__tableblock">
          <TableContainer className="test-wise-view__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {USE_DEPARTMENT_KEY ? (
                    <TableCell>{departmentLabel}</TableCell>
                  ) : null}
                  {USE_BRANCH_KEY ? <TableCell>{branchLabel}</TableCell> : null}
                  {USE_CLASS_KEY && displayClass ? (
                    <TableCell>{classLabel}</TableCell>
                  ) : null}
                  {USE_SEMESTER_KEY && displaySemester ? (
                    <TableCell>{semesterLabel}</TableCell>
                  ) : null}
                  {USE_SECTION_KEY && displaySection ? (
                    <TableCell>{sectionLabel}</TableCell>
                  ) : null}

                  <TableCell>{TableHeaders.ACTION}</TableCell>
                  <TableCell>Class wise Toppers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                  const firstRankInfo = data.first_rank_info || [];

                  const secondRankInfo = data.second_rank_info || [];
                  const thirdRankInfo = data.third_rank_info || [];
                  const firstRankFullName = firstRankInfo?.map(
                    (s) => s.first_name + s.middle_name + s.last_name
                  );
                  const firstRankStdAdmno = firstRankInfo?.map(
                    (s) => s.std_adm_no
                  );
                  const firstRankstdSection = firstRankInfo?.map(
                    (s) => s.entry_level_name
                  );
                  const secondRankFullName = secondRankInfo?.map(
                    (s) => s.first_name + s.middle_name + s.last_name
                  );
                  const secondRankStdAdmno = secondRankInfo?.map(
                    (s) => s.std_adm_no
                  );
                  const secondRankStdSection = secondRankInfo?.map(
                    (s) => s.entry_level_name
                  );
                  const thirdRankFullName = thirdRankInfo?.map(
                    (s) => s.first_name + s.middle_name + s.last_name
                  );
                  const thirdRankStdAdmno = thirdRankInfo?.map(
                    (s) => s.std_adm_no
                  );
                  const thirdRankSection = thirdRankInfo?.map(
                    (s) => s.entry_level_name
                  );

                  if (data) {
                    return (
                      <TableRow key={i}>
                        {data.level1_name !== EMPTY_STRING &&
                        checkTodisplay(Levels.LEVEL_1, data.level1) ? (
                          <TableCell rowSpan={data.level1_rowspan}>
                            {data.level1_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        (data.level2_name ||
                          data.level2_name !== EMPTY_STRING) &&
                        checkTodisplay(Levels.LEVEL_2, data.level2) ? (
                          <TableCell rowSpan={data.level2_rowspan}>
                            {data.level2_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        data.level3_name !== EMPTY_STRING &&
                        checkTodisplay(Levels.LEVEL_3, data.level3) ? (
                          <TableCell rowSpan={data.level3_span}>
                            {data.level3_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        data.level4_name !== EMPTY_STRING &&
                        checkTodisplay(Levels.LEVEL_4, data.level4) ? (
                          <TableCell rowSpan={data.level4_span}>
                            {data.level4_name}
                          </TableCell>
                        ) : null}
                        {data.level5_name !== EMPTY_STRING &&
                        checkTodisplay(Levels.LEVEL_5, data.level5) ? (
                          <TableCell>{data.level5_name}</TableCell>
                        ) : null}

                        <TableCell
                          id="td-center"
                          className="test-wise-view__table--actions">
                          {last_level - 1 === 1 && data.level1_name && (
                            <AssignComponent
                              id={data.level1_id}
                              testClassId={data.acd_test_class_id!}
                            />
                          )}
                          {last_level - 1 === 2 && data.level2_name && (
                            <AssignComponent
                              id={data.level2_id}
                              testClassId={data.acd_test_class_id!}
                            />
                          )}
                          {last_level - 1 === 3 && data.level3_name && (
                            <AssignComponent
                              id={data.level3_id}
                              testClassId={data.acd_test_class_id!}
                            />
                          )}
                          {last_level - 1 === 4 && data.level4_name && (
                            <AssignComponent
                              id={data.level4_id}
                              testClassId={data.acd_test_class_id!}
                            />
                          )}
                          {last_level - 1 === 5 && data.level5_name && (
                            <AssignComponent
                              id={data.level5_id}
                              testClassId={data.acd_test_class_id!}
                            />
                          )}
                        </TableCell>
                        <TableCell className="test-wise-view__table--toppers">
                          <ul>
                            <li>
                              <div className="test-wise-view__table--toppers--l">
                                <span>1</span>
                                {data.first_rank_info?.map((s) => {
                                  return (
                                    <>
                                      <img
                                        src={s.std_profile_filename}
                                        alt=""
                                      />
                                    </>
                                  );
                                })}

                                <div className="test-wise-view__multiple--ul--std-details">
                                  <b>
                                    {firstRankInfo
                                      ? firstRankFullName
                                          .slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}
                                  </b>
                                  <span>
                                    {firstRankStdAdmno
                                      ? firstRankStdAdmno
                                          ?.slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}{" "}
                                  </span>
                                  <span>
                                    {firstRankstdSection
                                      ? firstRankstdSection
                                          ?.slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}
                                  </span>
                                  {firstRankFullName.length > 3 ? (
                                    <span
                                      className="font-blue"
                                      onClick={() =>
                                        setFirstRankToppers(!firstRankToppers)
                                      }>
                                      + {firstRankFullName.length - 3} More
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <b className="test-wise-view__table--toppers--perc">
                                {data.first_rank_info &&
                                  data.first_rank_info.length > 0 &&
                                  data.first_rank_info[0].percent_marks_scored}
                                %
                              </b>
                            </li>
                            <li>
                              <div className="test-wise-view__table--toppers--l">
                                <span>2</span>
                                {data.second_rank_info?.map((s) => {
                                  return (
                                    <>
                                      <img
                                        src={s.std_profile_filename}
                                        alt=""
                                      />
                                    </>
                                  );
                                })}

                                <div className="test-wise-view__multiple--ul--std-details">
                                  <b>
                                    {secondRankFullName
                                      ? secondRankFullName
                                          ?.slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}
                                  </b>
                                  <span>
                                    {secondRankStdAdmno
                                      ? secondRankStdAdmno
                                          ?.slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}
                                  </span>
                                  <span>
                                    {secondRankStdSection
                                      ? secondRankStdSection
                                          ?.slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}
                                  </span>
                                  {secondRankFullName.length > 3 ? (
                                    <span
                                      className="font-blue"
                                      onClick={() =>
                                        setSecondRankToppers(!secondRankToppers)
                                      }>
                                      + {secondRankFullName.length - 3} More
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <b className="test-wise-view__table--toppers--perc">
                                {data.second_rank_info &&
                                  data.second_rank_info.length > 0 &&
                                  data.second_rank_info[0].percent_marks_scored}
                                %
                              </b>
                            </li>

                            <li>
                              <div className="test-wise-view__table--toppers--l">
                                <span>3</span>
                                {data.third_rank_info?.map((s) => {
                                  return (
                                    <>
                                      {s.std_profile_filename ? (
                                        <img
                                          src={s.std_profile_filename}
                                          alt=""
                                        />
                                      ) : (
                                        <img src={Avatar} alt="" />
                                      )}
                                    </>
                                  );
                                })}
                                <div className="test-wise-view__multiple--ul--std-details">
                                  <b>
                                    {thirdRankFullName
                                      ? thirdRankFullName
                                          ?.slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}
                                  </b>
                                  <span>
                                    {thirdRankStdAdmno
                                      ? thirdRankStdAdmno
                                          .slice(0, 3)
                                          .join(" , ")
                                      : EMPTY_STRING}
                                  </span>
                                  {thirdRankFullName.length > 3 ? (
                                    <span className="font-blue">
                                      + {thirdRankFullName.length - 3} More
                                    </span>
                                  ) : null}
                                  <span
                                    onClick={() =>
                                      setThirdRankToppers(!setThirdRankToppers)
                                    }>
                                    {thirdRankSection?.slice(0, 3)} More{" "}
                                  </span>
                                </div>
                              </div>

                              <b className="test-wise-view__table--toppers--perc">
                                {data.third_rank_info &&
                                data.third_rank_info.length > 0
                                  ? data.third_rank_info[0].percent_marks_scored
                                  : 0}
                                %
                              </b>
                            </li>
                          </ul>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <CustomModal
        isOpen={firstRankToppers}
        onRequestClose={() => setFirstRankToppers(!firstRankToppers)}
        contentComponent={
          <ToppersList
            setModal={setFirstRankToppers}
            pageType={ToppersType.FIRST_RANK}
            departmentSelected={departmentSelected}
            branchSelected={branchSelected}
            classSelected={classSelected}
            semesterSelected={semesterSelected}
            sectionSelected={sectionSelected}
          />
        }
      />

      <CustomModal
        isOpen={secondRankToppers}
        onRequestClose={() => setSecondRankToppers(!secondRankToppers)}
        contentComponent={
          <ToppersList
            setModal={setSecondRankToppers}
            pageType={ToppersType.SECOND_RANK}
            departmentSelected={departmentSelected}
            branchSelected={branchSelected}
            classSelected={classSelected}
            semesterSelected={semesterSelected}
            sectionSelected={sectionSelected}
          />
        }
      />

      <CustomModal
        isOpen={thirdRankToppers}
        onRequestClose={() => setThirdRankToppers(!thirdRankToppers)}
        contentComponent={
          <ToppersList
            setModal={setThirdRankToppers}
            pageType={ToppersType.THIRD_RANK}
            departmentSelected={departmentSelected}
            branchSelected={branchSelected}
            classSelected={classSelected}
            semesterSelected={semesterSelected}
            sectionSelected={sectionSelected}
          />
        }
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default ViewTestWise;
