import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";

import Home from "../../Home/Index";
import Modal from "react-modal";
import {
  DeleteLedgerModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import ClassWisePerformance from "./Modal/ClassWisePerformance";
import ParticularStudentResults from "./Modal/ParticularStudentResults";
import useStudentTestMarks, {
  AcdStdMarksQueryType,
  TestMarksNode,
} from "../../hooks/useStudentTestMarks";
import { EMPTY_STRING } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { toInputStandardDate } from "../../../../utils/UtilFunctions";
import { payloadTypes } from "../../../../context/reducer";
import useTestDetails from "../../hooks/useTestDetails";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../hooks/useTestStatus";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import { responseType } from "../../../../utils/Form.types";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import ParticularStudentResultsDailyAct from "./Modal/ParticularStudentResultsDailyAct";

import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
import useCombinationDetails from "../../CustomAllocation/customHooks/useCombinationDetails";
import { GetAcdComboSubjectOrderWithMarksDetails } from "../../../../queries/customallocation/combination/queries";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdComboSubjectOrderWithMarksDetailsData,
  GetAcdComboSubjectOrderWithMarksDetailsVars,
} from "../../../../Types/Combinations/queries";
import useToken from "../../../../customhooks/useToken";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";

enum TableHeaders {
  Sl = "Sl",
  Reg = "Reg No.",
  Adm = "Adm No",
  Name = "Student Name",
  Total = "Total",
  Status = "Status",
}

export enum ForReportOrDailyActivities {
  DAILY_ACT = "DAILY_ACT",
  REPORTS = "REPORTS",
}
interface Props {
  page_for: ForReportOrDailyActivities;
}

const View = ({ page_for }: Props) => {
  const navigate = useNavigate();
  const { testId, entryId, InstId } = useParams();
  const { flag } = useCheckAllocationType();
  const [classWisePerformance, setClassWisePerformance] = useState(false);
  const [particularStudentResults, setParticularStudentResults] =
    useState(false);
  const [testConductId, setTestConductId] = useState<responseType | null>(null);
  const [combinationId, setCombinationId] = useState<responseType | null>(null);
  const [combinationOptions, setCombinationOptions] = useState<responseType[]>(
    []
  );
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.ALL_TESTS,
    Number(entryId)
  );
  const { data: combinationData } = useCombinationDetails(
    AcdSubjComboQueryType.COMBO_BY_TEST_CLASS_ID
  );
  const { token } = useToken();

  const { dispatch, state } = useContext(AppContext);
  const { testMarks } = useStudentTestMarks(
    flag
      ? AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID
      : AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
    null,
    EMPTY_STRING
  );

  const { testConductDetails } = useTestDetails();
  const { InstConfigDetails } = useInstConfigByEntryId(entryId!);
  const [GetAcdSubjects, { data: comboSubjectsList }] = useLazyQuery<
    GetAcdComboSubjectOrderWithMarksDetailsData,
    GetAcdComboSubjectOrderWithMarksDetailsVars
  >(GetAcdComboSubjectOrderWithMarksDetails, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_id: testId!,
      acd_subj_combo_id: combinationId ? combinationId.value : 0,
    },
  });

  const {
    acd_dept_desc,
    acd_branch_desc,
    acd_class_desc,
    acd_semester_desc,
    acd_section_desc,
  } =
    InstConfigDetails && InstConfigDetails.data
      ? InstConfigDetails.data.GetAcdInstConfigNames
      : {
          acd_dept_desc: "",
          acd_branch_desc: "",
          acd_class_desc: "",
          acd_semester_desc: "",
          acd_section_desc: "",
        };
  const { data } = testConductDetails;

  const isSubjAcademic =
    data &&
    data.node.class_subject_details[0].subject_master_details
      .subj_is_non_academic;
  const status = statusOfSubject.data?.GetAcdTestMarksStatus?.find(
    (d) => d?.acd_test_class_id === Number(testId) || testConductId?.value
  );

  const {
    USE_SECTION_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
  } = useInstitutionConfiguration();

  useEffect(() => {
    if (testId) {
      setTestConductId(
        statusOfSubject.classTests.find(
          ({ value }) => value === Number(testId)
        ) ?? null
      );
    }
  }, [testId, statusOfSubject.classTests]);

  useEffect(() => {
    if (token) {
      GetAcdSubjects();
    }
  }, [InstId, combinationId, state.ActiveAcdYr, token, GetAcdSubjects]);

  useEffect(() => {
    if (combinationData) {
      setCombinationOptions(
        combinationData.GetComboDetails.map((combo) => ({
          label: combo.subj_combo_name,
          value: combo.id,
        }))
      );
    }
  }, [combinationData]);

  const totalMarks = flag
    ? comboSubjectsList &&
      comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.reduce(
        (acc, mark) => {
          acc.total_max_marks += mark.subj_total_max_marks;
          acc.total_min_marks += mark.subj_total_min_marks;
          return acc;
        },
        {
          total_max_marks: 0,
          total_min_marks: 0,
        }
      )
    : data &&
      data.node.class_subject_details.reduce(
        (acc, mark) => {
          acc.total_max_marks += mark.subj_total_max_marks;
          acc.total_min_marks += mark.subj_total_min_marks;
          return acc;
        },
        {
          total_max_marks: 0,
          total_min_marks: 0,
        }
      );

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Test Report</Title>
      <div className="view-test-report">
        <div className="view-test-report__options row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={departmentLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={acd_dept_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={branchLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={acd_branch_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={classLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={acd_class_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={semesterLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={acd_semester_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={sectionLabel}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={acd_section_desc}
                disabled
              />
            </div>
          ) : null}
        </div>
        <div className="view-test-report__datablock">
          <div className="row g-0">
            <div className="col-3">
              {page_for === ForReportOrDailyActivities.REPORTS ? (
                <>
                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={statusOfSubject.classTests}
                    openOnFocus
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(
                        option as responseType as responseType,
                        testConductId
                      )
                    }
                    value={testConductId}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setTestConductId(newValue as responseType);
                      } else {
                        setTestConductId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        value="Third Internals (Test Name)"
                        label="Tests"
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </>
              ) : (
                flag && (
                  <>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={combinationOptions}
                      openOnFocus
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as responseType,
                          combinationId
                        )
                      }
                      value={combinationId}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setCombinationId(newValue as responseType);
                          dispatch({
                            type: payloadTypes.SET_ACD_COMBO_ID,
                            payload: {
                              acdCombinationId: (newValue as responseType)
                                .value,
                            },
                          });
                        } else {
                          setCombinationId(null);
                          dispatch({
                            type: payloadTypes.SET_ACD_COMBO_ID,
                            payload: {
                              acdCombinationId: 0,
                            },
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Combinations"
                          className={formClasses.formControlRoot}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      )}
                    />
                  </>
                )
              )}
            </div>

            <div className="col">
              <Title>
                Test:
                {
                  statusOfSubject.classTests.find(
                    ({ value }) => value === testConductId?.value
                  )?.label
                }
              </Title>
            </div>
            <div className="col-3 view-test-report__datablock--date">
              <TextField
                className="view-test-report__options--textfield--date"
                label="Test Start Date"
                type="date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={
                  toInputStandardDate(data?.node.test_start_date!) ||
                  EMPTY_STRING
                }
              />
              <TextField
                className="view-test-report__options--textfield--date"
                label="Test End date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                type="date"
                value={
                  toInputStandardDate(data?.node.test_end_date!) || EMPTY_STRING
                }
              />
            </div>
          </div>

          {/* to be done  */}

          <div className="view-test-report__tableblock row g-0">
            {flag ? (
              combinationId ? (
                <TableContainer className="view-test-report__table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell rowSpan={2} id="td-center">
                          {TableHeaders.Sl}
                        </TableCell>

                        <TableCell rowSpan={2} id="td-center">
                          {TableHeaders.Adm}
                        </TableCell>
                        <TableCell rowSpan={2} id="td-center">
                          {TableHeaders.Reg}
                        </TableCell>

                        <TableCell rowSpan={2} id="td-center">
                          {TableHeaders.Name}
                        </TableCell>
                        {comboSubjectsList &&
                          comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.map(
                            (data, index) => {
                              return (
                                <TableCell key={index} id="td-center">
                                  <div>{data.subj_desc}</div>
                                  <div>({data.subj_code})</div>
                                </TableCell>
                              );
                            }
                          )}
                        <TableCell id="td-center">
                          {TableHeaders.Total}
                        </TableCell>
                        <TableCell id="td-center" rowSpan={2}>
                          Percentage(%)
                        </TableCell>
                        {data &&
                        data.node.test_name_details.add_test_remarks ? (
                          <TableCell id="td-center" rowSpan={2}>
                            Remarks
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow>
                        {comboSubjectsList &&
                          comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.map(
                            (th, index) => {
                              const res =
                                comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.find(
                                  (subject) =>
                                    subject.subj_master_id ===
                                    th.subj_master_id!
                                );
                              if (res)
                                return (
                                  <TableCell
                                    key={index}
                                    id="td-center"
                                    rowSpan={2}>
                                    {`${res.subj_total_max_marks!}(${
                                      res.subj_total_min_marks
                                    })`}
                                  </TableCell>
                                );

                              return <></>;
                            }
                          )}
                        <TableCell
                          id="td-center"
                          className="view-test-report__table--sticky-header">
                          {`${totalMarks?.total_max_marks}(${totalMarks?.total_min_marks})
                       `}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {testMarks.data &&
                        testMarks.data.GetStdTestMarks.edges.map(
                          (edge, index) => {
                            const { student_details } = edge.node;

                            return (
                              <TableRow key={index}>
                                <TableCell
                                  id="td-center"
                                  className="view-test-report__table--slno">
                                  {index + 1}
                                </TableCell>

                                <TableCell>
                                  {student_details.std_adm_no}
                                </TableCell>
                                <TableCell>
                                  {student_details.std_reg_no}
                                </TableCell>

                                <TableCell
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_STUDENT_ID,
                                      payload: {
                                        studentId: student_details.id,
                                      },
                                    });
                                    dispatch({
                                      type: payloadTypes.SET_STD_ADM_NO,
                                      payload: {
                                        stdAdmNo: student_details.std_adm_no,
                                      },
                                    });
                                    setParticularStudentResults(
                                      !particularStudentResults
                                    );
                                  }}>
                                  {`${student_details.first_name} ${student_details.middle_name} ${student_details.last_name}`}
                                </TableCell>
                                {comboSubjectsList &&
                                  comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.map(
                                    (subj, index) => {
                                      const fieldName = `subj_marks_scored_total_${subj.subj_sl}`;
                                      const statusField = `subj_marks_status_${subj.subj_sl}`;
                                      const subjectId = `elective_subj_id_${subj.subj_sl}`;

                                      const res =
                                        edge.node[
                                          fieldName as keyof TestMarksNode
                                        ];

                                      const resSubjectId =
                                        edge.node[
                                          subjectId as keyof TestMarksNode
                                        ];
                                      const marksEntryStatus = status && [
                                        // @ts-ignore
                                        status[statusField] as boolean,
                                      ];

                                      return (
                                        <>
                                          <TableCell
                                            id="td-center"
                                            key={index}
                                            style={{
                                              color:
                                                Number(res) >
                                                  subj.subj_total_min_marks &&
                                                Number(res) >= 0
                                                  ? ""
                                                  : "red",
                                            }}
                                            className={`
                                     ${
                                       subj.subj_master_id === resSubjectId &&
                                       marksEntryStatus &&
                                       marksEntryStatus[0]
                                         ? // @ts-ignore
                                           res
                                         : resSubjectId === 0
                                         ? // @ts-ignore
                                           res
                                         : "bg-grey"
                                     }
                                      `}>
                                            {subj.subj_master_id ===
                                              resSubjectId &&
                                            marksEntryStatus &&
                                            marksEntryStatus[0]
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : resSubjectId === 0
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : ""}
                                          </TableCell>
                                        </>
                                      );
                                    }
                                  )}

                                <TableCell
                                  align="center"
                                  className="totalcount ">
                                  {edge.node.total_scored_marks}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="totalcount">
                                  {edge.node.total_percent}
                                </TableCell>

                                {data &&
                                data.node.test_name_details.add_test_remarks ? (
                                  <TableCell
                                    align="center"
                                    className="totalcount">
                                    {edge.node.test_remarks}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <b className="nodata">Please select combination</b>
              )
            ) : (
              <TableContainer className="view-test-report__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell rowSpan={2} id="td-center">
                        {TableHeaders.Sl}
                      </TableCell>

                      <TableCell rowSpan={2} id="td-center">
                        {TableHeaders.Adm}
                      </TableCell>
                      <TableCell rowSpan={2} id="td-center">
                        {TableHeaders.Reg}
                      </TableCell>

                      <TableCell rowSpan={2} id="td-center">
                        {TableHeaders.Name}
                      </TableCell>
                      {data &&
                        data.node.class_subject_details.map((data, index) => {
                          return (
                            <>
                              <TableCell key={index} id="td-center">
                                {data.subject_master_details.subj_desc}

                                <div>
                                  ({data.subject_master_details.subj_code})
                                </div>
                              </TableCell>
                              {isSubjAcademic && (
                                <TableCell
                                  key={index}
                                  id="td-center"
                                  rowSpan={2}>
                                  Remarks (
                                  {data.subject_master_details.subj_desc})
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                      {!isSubjAcademic && (
                        <>
                          <TableCell id="td-center">
                            {TableHeaders.Total}
                          </TableCell>
                          <TableCell id="td-center" rowSpan={2}>
                            Percentage(%)
                          </TableCell>
                        </>
                      )}
                      {data && data.node.test_name_details.add_test_remarks ? (
                        <TableCell id="td-center" rowSpan={2}>
                          Remarks
                        </TableCell>
                      ) : null}
                    </TableRow>
                    <TableRow>
                      {data &&
                        !isSubjAcademic &&
                        data.node.class_subject_details.map((th, index) => {
                          const res = data?.node.class_subject_details?.find(
                            (subject) =>
                              subject.subject_master_details.id ===
                              th.subject_master_details.id!
                          );
                          return (
                            <TableCell
                              key={index}
                              id="td-center"
                              className="view-test-report__table--sticky-header">
                              {`${res?.subj_total_max_marks!}(${res?.subj_total_min_marks!})`}
                            </TableCell>
                          );
                        })}
                      {!isSubjAcademic && (
                        <TableCell
                          id="td-center"
                          className="view-test-report__table--sticky-header">
                          {`${totalMarks?.total_max_marks}(${totalMarks?.total_min_marks})
                   `}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testMarks.data &&
                      testMarks.data.GetStdTestMarks.edges.map(
                        (edge, index) => {
                          const { student_details } = edge.node;

                          return (
                            <TableRow key={index}>
                              <TableCell id="td-center">{index + 1}</TableCell>
                              <TableCell>
                                {student_details.std_adm_no}
                              </TableCell>
                              <TableCell>
                                {student_details.std_reg_no}
                              </TableCell>

                              <TableCell
                                onClick={() => {
                                  dispatch({
                                    type: payloadTypes.SET_STUDENT_ID,
                                    payload: {
                                      studentId: student_details.id,
                                    },
                                  });
                                  dispatch({
                                    type: payloadTypes.SET_STD_ADM_NO,
                                    payload: {
                                      stdAdmNo: student_details.std_adm_no,
                                    },
                                  });
                                  setParticularStudentResults(
                                    !particularStudentResults
                                  );
                                }}>
                                {`${student_details.first_name} ${student_details.middle_name} ${student_details.last_name}`}
                              </TableCell>
                              {data &&
                                data.node.class_subject_details.map(
                                  (subj, index) => {
                                    const isNonAcademic =
                                      subj.subject_master_details
                                        .subj_is_non_academic;
                                    const fieldName = isNonAcademic
                                      ? `scored_grade_${subj.subj_sl}`
                                      : `subj_marks_scored_total_${subj.subj_sl}`;

                                    const fieldNonAcd = `teacher_comments_${subj.subj_sl}`;
                                    const resNonAcd = edge.node[
                                      fieldNonAcd as keyof TestMarksNode
                                    ] as string;
                                    const subjectId = `elective_subj_id_${subj.subj_sl}`;

                                    const res = edge.node[
                                      fieldName as keyof TestMarksNode
                                    ] as string;
                                    const resSubjectId =
                                      edge.node[
                                        subjectId as keyof TestMarksNode
                                      ];

                                    return (
                                      <>
                                        {!isNonAcademic && (
                                          <TableCell
                                            id="td-center"
                                            key={index}
                                            style={{
                                              color:
                                                Number(res) >=
                                                  subj.subj_total_min_marks &&
                                                Number(res) >= 0
                                                  ? ""
                                                  : "red",
                                            }}
                                            className={`
                                           ${
                                             subj.subject_master_details.id ===
                                             resSubjectId
                                               ? // @ts-ignore
                                                 res
                                               : resSubjectId === 0
                                               ? // @ts-ignore
                                                 res
                                               : "bg-grey"
                                           }
                                            `}>
                                            {subj.subject_master_details.id ===
                                            resSubjectId
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : resSubjectId === 0
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : ""}
                                          </TableCell>
                                        )}
                                        {isNonAcademic && (
                                          <>
                                            <TableCell
                                              id="td-center"
                                              key={index}
                                              style={{
                                                color:
                                                  res !== EMPTY_STRING
                                                    ? ""
                                                    : "red",
                                              }}
                                              className={`
                                           ${
                                             subj.subject_master_details.id ===
                                             resSubjectId
                                               ? // @ts-ignore
                                                 res
                                               : resSubjectId === 0
                                               ? // @ts-ignore
                                                 res
                                               : "bg-grey"
                                           }
                                            `}>
                                              {
                                                // @ts-ignore
                                                res ? res : "Absent"
                                              }
                                            </TableCell>
                                            <TableCell
                                              id="td-center"
                                              key={index}
                                              style={{
                                                color:
                                                  res !== EMPTY_STRING
                                                    ? ""
                                                    : "red",
                                              }}
                                              className={`
                                           ${
                                             subj.subject_master_details.id ===
                                             resSubjectId
                                               ? // @ts-ignore
                                                 res
                                               : resSubjectId === 0
                                               ? // @ts-ignore
                                                 res
                                               : "bg-grey"
                                           }
                                            `}>
                                              {
                                                // @ts-ignore
                                                resNonAcd
                                              }
                                            </TableCell>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              {!isSubjAcademic && (
                                <>
                                  <TableCell
                                    align="center"
                                    className="totalcount">
                                    {edge.node.total_scored_marks}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="totalcount">
                                    {edge.node.total_percent}
                                  </TableCell>
                                </>
                              )}
                              {data &&
                              data.node.test_name_details.add_test_remarks ? (
                                <TableCell align="center">
                                  {edge.node.test_remarks}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        {/* <Button onClick={() => setClassWisePerformance(!classWisePerformance)}>
          <img src={MarkAttendance} alt="/" />
          Class Wise Performance
        </Button> */}
        {/* <Button mode="pdf" onClick={downloadExcel} /> */}
        <div className="row g-0">
          <div className="col">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-3 flex-end">
            <div className="student-total-count">
              Test Attended Students :<b>{status?.num_of_std_test_taken}</b>
              <div className="academics-test-report__datablock--grids--cards--status">
                {/* to be implemented later */}
                {/* <span className="academics-test-report__datablock--grids--cards--status--separator">
                  |
                </span>
                <div className="academics-test-report__datablock--grids--cards--status--present">
                  <b>Absent</b>
                  <span className="font-red">05</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={classWisePerformance}
        ariaHideApp={false}
        style={DeleteLedgerModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ClassWisePerformance />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setClassWisePerformance(!classWisePerformance)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={particularStudentResults}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {page_for === ForReportOrDailyActivities.REPORTS ? (
              <ParticularStudentResults
                setModalFlag={setParticularStudentResults}
                testConductId={testConductId}
              />
            ) : (
              <ParticularStudentResultsDailyAct
                modalFlag={particularStudentResults}
                testDetails={testConductId}
                setModalFlag={setParticularStudentResults}
              />
            )}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setParticularStudentResults(!particularStudentResults)
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default View;
