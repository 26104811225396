import Home from "../../Home/Index";
import AccountsHome from "../../../Accounts/Home/Index";
import { useTheme } from "@mui/material/styles";

import { Title } from "../../../../stories/Title/Title";
import ReportsList from "./Students/ReportType";

import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import GenerateReport from "./Students/GenerateReport";
import OtherOptions from "./Students/OtherOptions";
import { ExportProps } from "./Index";
import {
  ExcelReportPageType,
  ExportModuleType,
} from "../../../../utils/Enum.types";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { useEffect, useState } from "react";
import { ExcelAdmQueryType } from "./useExportMutation";

interface Props extends ExportProps {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainPage = ({ reportType, forModal, setModalFlag }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { InstId } = useParams();
  const isAccounts = window.location.pathname.includes(
    ExportModuleType.ACCOUNTS
  );
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue) {
      navigate(`/${InstId}/accounts/reports/fee/voucher/0`);
    } else {
      navigate(`/${InstId}/accounts/reports/fee/balance`);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (window.location.pathname.includes("voucher")) {
      setValue(1);
    }
  }, [window.location.pathname.includes("voucher")]);

  return (
    <>
      {isAccounts && !forModal ? (
        <AccountsHome DashBoardRequired={false} />
      ) : (
        !forModal && <Home DashBoardRequired={false} />
      )}
      {!forModal && <Title>Institution Level Report</Title>}
      <div className={`inst-level-report${forModal ? "--formodal" : ""}`}>
        {isAccounts && !forModal ? (
          <div className="inst-level-report__tabs">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Students Report" value={0} {...a11yProps(0)} />
              <Tab label="Vouchers" value={1} {...a11yProps(1)} />
            </Tabs>
          </div>
        ) : null}
        <div
          className={`inst-level-report__tabpanel${
            reportType === ExcelAdmQueryType.ADM_STUDENT_LIST
              ? "--wp"
              : forModal
              ? "--formodal"
              : ""
          }`}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div className="inst-level-report__datablock">
              {!forModal && (
                <div className="inst-level-report__lt">
                  <ReportsList
                    reportType={reportType}
                    pageType={ExcelReportPageType.STUDENT}
                    setModalFlag={setModalFlag}
                  />
                </div>
              )}
              <div
                className={`inst-level-report__md${
                  forModal ? "--formodal" : ""
                }`}>
                <GenerateReport
                  reportType={reportType}
                  pageType={ExcelReportPageType.STUDENT}
                  setModalFlag={setModalFlag}
                />
              </div>

              <OtherOptions
                reportType={reportType}
                pageType={ExcelReportPageType.STUDENT}
                setModalFlag={setModalFlag}
              />
            </div>
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <div className="inst-level-report__datablock">
              <div className="inst-level-report__lt">
                <ReportsList
                  reportType={reportType}
                  pageType={ExcelReportPageType.VOUCHER}
                  setModalFlag={setModalFlag}
                />
              </div>
              <div className="inst-level-report__md">
                <GenerateReport
                  reportType={reportType}
                  pageType={ExcelReportPageType.VOUCHER}
                  setModalFlag={setModalFlag}
                />
              </div>
              <OtherOptions
                reportType={reportType}
                pageType={ExcelReportPageType.VOUCHER}
                setModalFlag={setModalFlag}
              />
            </div>
          </TabPanel>
        </div>

        {forModal ? (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        ) : (
          <Button mode="back" onClick={() => navigate(-1)} />
        )}
      </div>
    </>
  );
};

export default MainPage;
