import {
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import { TableHeaderProps } from "../../../../Types/Tables";
import { DocsType } from "./DocsUpload";
import DownloadImage from "../../../../images/DownloadImage.svg";
import DownloadPdf from "../../../../images/DownloadPdf.svg";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { handleUploadAndDownloadFile } from "../../../../utils/Upload";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddStudentDocs,
  DeleteStudentDoc,
  UpdateStudentDoc,
} from "../../../../queries/students/mutations/new";
import Modal from "react-modal";

import {
  DocumentAcknowledgement,
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  emptyMessageType,
  imageFormats,
} from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import { GetStudentDocsByStudentId } from "../../../../queries/students/list/byId";
import { Button } from "../../../../stories/Button/Button";
import MessageModal from "../../../../pages/MessageModal";
import { GetMstInstUploadDocTypes } from "../../../../queries/students/list/newApi";
import {
  GetMstInstUploadDocTypesData,
  Vars,
} from "../MandatoryDocs/StudentDocs";
import {
  DeleteVars,
  GetStudentDocsByStudentIdData,
  GetStudentDocsByStudentIdVars,
} from "../Documents/Index";
import LoadingModal from "../../../../pages/LoadingModal";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { AntSwitch } from "../../../../pages/Switch";
import Input from "../../../../stories/Input/Input";
import Settings from "../../../../images/Settings.svg";

import {
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import PrintDocuments from "../../../Print/Documents/Print";
import { PrintModalStyles } from "../../../../styles/ModalStyles";
import {
  GetInstDocumentsData,
  GetInstDocumentVars,
} from "../../../Print/Documents/Types";
import { GetInstDocuments } from "../../../Print/Documents/queries";
interface Props {
  type: Operation;
}
const DocsUploadComponent = ({ type }: Props) => {
  const { StudentDocsPreviewTable } = useMasterTableJson();
  const [documents, setDocuments] = useState<DocsType[]>([]);
  const { serverDate } = useServerDateandTime();
  const [configuration, setConfiguration] = useState(false);

  const { InstDetails } = useInstDetails(1);
  const [message, setMessage] = useState(emptyMessageType);
  const { studentId } = useParams();
  const { token } = useToken();
  const inputFileRefs = useRef<HTMLInputElement[]>([]);
  const [selectedIndex, setSeletedIndex] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState(0);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_STUDENT_DOCUMENT_COLLECTION
  );
  const [printAcknowledgement, setPrintAcknowledgement] = useState(false);

  const isDocCollectionEnabled =
    configData &&
    configData.data &&
    configData.data.GetSwConfigVariables.length &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const [progress, setProgress] = useState(0);
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const { InstId } = useParams();
  const [UploadStudentDocuments, { loading }] = useMutation(AddStudentDocs, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateStudentDocuments, { loading: updateLoading }] = useMutation(
    UpdateStudentDoc,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteStudentDocs, { loading: deleteLoading }] = useMutation<
    { DeleteStudentDoc: boolean },
    DeleteVars
  >(DeleteStudentDoc, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [GetDocs, { data }] = useLazyQuery<GetMstInstUploadDocTypesData, Vars>(
    GetMstInstUploadDocTypes,
    {
      variables: {
        inst_id: InstId!,
        token,
      },
    }
  );

  const [GetDocuments, { data: documentsData }] = useLazyQuery<
    GetStudentDocsByStudentIdData,
    GetStudentDocsByStudentIdVars
  >(GetStudentDocsByStudentId, {
    variables: {
      student_id: studentId ? Number(studentId) : state.studentId,
      token,
    },
  });

  useEffect(() => {
    if (token && InstId && (state.studentId || studentId)) {
      GetDocs().then(({ data }) => {
        if (data && data.GetMstInstUploadDocTypes) {
          setDocuments(
            data.GetMstInstUploadDocTypes.map((item) => ({
              ...item,
              docId: 0,
              preview: null,
              fromDb: false,
              fromDbUrl: "",
              dateCollected: serverDate,
              isUpdatedFlag: false,
              remarks: EMPTY_STRING,
            }))
          );
        }
      });
    }
  }, [token, InstId, GetDocs, data, state.studentId, serverDate, studentId]);
  const [GetInstDocumentData, { data: DocumentsList }] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments, {
    variables: {
      token,
      inst_id: InstId!,
      custom_doc_name: DocumentAcknowledgement.STD_DOC_ACK,
    },
  });
  useEffect(() => {
    if (token && InstId!) {
      GetInstDocumentData();
    }
    // eslint-disable-next-line
  }, [token, GetInstDocumentData]);
  useEffect(() => {
    if (token && InstId && (state.studentId || studentId) && documents.length) {
      GetDocuments().then(({ data }) => {
        if (
          data &&
          data.GetStudentDocsByStudentId &&
          data.GetStudentDocsByStudentId.length
        ) {
          setDocuments(
            documents.map((item) => {
              const foundDocType = data.GetStudentDocsByStudentId.find(
                (doc) => doc.std_doc_type === item.document_name
              );
              if (foundDocType) {
                return {
                  ...item,
                  docId: foundDocType.id,
                  preview: null,
                  fromDb: true,
                  fromDbUrl: foundDocType.std_doc_filename,
                  collected: foundDocType.std_doc_collected,
                  isOriginal: foundDocType.std_doc_original,
                  isUpdatedFlag: foundDocType.id ? true : false,
                  dateCollected: foundDocType.date_of_collection,
                  remarks: foundDocType.std_doc_remarks,
                };
              } else return item;
            })
          );
        }
      });
    }
  }, [
    token,
    InstId,
    GetDocuments,
    documentsData,
    state.studentId,
    documents.length,
    studentId,
  ]);
  const customDocID =
    DocumentsList && DocumentsList.GetInstDocuments.map((res) => res.id);
  const viewPreview = (document: DocsType) => {
    const check: Map<string, string> = new Map([
      ["image", DownloadImage],
      ["pdf", DownloadPdf],
    ]);

    if (document.preview) {
      const file_ext =
        document.preview.name.split(".")[
          document.preview.name.split(".").length - 1
        ];
      return (
        <div className="preview">
          {imageFormats.includes(file_ext) ? (
            <img src={check.get("image")} alt="/" />
          ) : (
            <img src={check.get("pdf")} alt="/" />
          )}
          <label>{document.preview.name}</label>
        </div>
      );
    } else if (document.fromDb && document.fromDbUrl) {
      const file_ext =
        document.fromDbUrl.split(".")[document.fromDbUrl.split(".").length - 1];
      const file_name =
        document.fromDbUrl.split("/")[document.fromDbUrl.split("/").length - 1];

      return (
        <div className="preview">
          {imageFormats.includes(file_ext) ? (
            <img src={check.get("image")} alt="/" />
          ) : (
            <img src={check.get("pdf")} alt="/" />
          )}
          <label>{file_name}</label>
        </div>
      );
    }
  };

  const handleSubmit = () => {
    const updatedDocuments = documents.filter(
      (data) => data.isUpdatedFlag
      // && (data.collected || data.isOriginal)
    );

    if (updatedDocuments.length) {
      UpdateStudentDocuments({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          input: updatedDocuments.map((res) => ({
            document_id: res.docId,
            std_doc_filename: res.fromDbUrl ? res.fromDbUrl : EMPTY_STRING,
            std_doc_collected: res.collected ? true : false,
            std_doc_original: res.isOriginal ? true : false,
            date_of_collection: res.dateCollected
              ? toIsoDate(res.dateCollected)
              : toIsoDate(serverDate),
            std_doc_remarks: res.remarks ? res.remarks : EMPTY_STRING,
          })),
        },
        refetchQueries: [
          {
            query: GetStudentDocsByStudentId,
            variables: {
              student_id: studentId ? Number(studentId) : state.studentId,
              token,
            },
          },
        ],
      })
        .then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Documents Updated Successfully",
              operation: Operation.UPDATE,
            });
          }
        })
        .catch((error) => {
          console.log("Error updating documents:", error);
          setMessage({
            flag: true,
            message: `Error updating documents: ${error.message}`,
            operation: Operation.UPDATE,
          });
        });
    }

    const newDocuments = documents.filter((data) => !data.isUpdatedFlag);
    if (newDocuments.length) {
      UploadStudentDocuments({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          student_id: studentId ? studentId : state.studentId,
          input: newDocuments.map((res) => ({
            std_doc_type: res.document_name,
            std_doc_filename: res.fromDbUrl ? res.fromDbUrl : EMPTY_STRING,
            std_doc_download: false,
            std_doc_collected: res.collected ? true : false,
            std_doc_original: res.isOriginal ? true : false,
            date_of_collection: res.dateCollected
              ? toIsoDate(res.dateCollected)
              : toIsoDate(serverDate),
            std_doc_remarks: res.remarks ? res.remarks : EMPTY_STRING,
          })),
        },
        refetchQueries: [
          {
            query: GetStudentDocsByStudentId,
            variables: {
              student_id: studentId ? Number(studentId) : state.studentId,
              token,
            },
          },
        ],
      })
        .then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "New Documents Uploaded Successfully",
              operation: Operation.CREATE,
            });
          }
        })
        .catch((error) => {
          console.log("Error uploading documents:", error);
          setMessage({
            flag: true,
            message: `Error uploading documents: ${error.message}`,
            operation: Operation.CREATE,
          });
        });
    }
  };

  const handleFileChange = (
    doc: DocsType,
    e: React.ChangeEvent<HTMLInputElement>,
    indexNum: number
  ) => {
    const { files } = e.target;

    if (files && files.length) {
      const file_ext = files[0].name.split(".").pop();
      const filename = `${InstDetails.data?.nodes[0]?.inst_name}/students/${
        studentId ? studentId : state.studentId
      }/documents/${doc.document_name}.${file_ext}`;

      const final = documents.map((item, index) => {
        if (selectedIndex === index) {
          return {
            ...item,
            preview: files[0] || null,
          };
        }
        return item;
      });

      handleUploadAndDownloadFile(files[0], filename, setProgress, false)
        .then(() => {
          const docToUpdate = documents.find(
            (res) => res.doc_idx === selectedIndex + 1
          );
          if (docToUpdate && docToUpdate.isUpdatedFlag) {
            UpdateStudentDocuments({
              variables: {
                token,
                inst_id: InstId!,
                user_details,
                input: [
                  {
                    document_id: docToUpdate.docId ? docToUpdate.docId : 0,
                    std_doc_filename: filename,
                    std_doc_collected: docToUpdate.collected
                      ? docToUpdate.collected
                      : false,
                    std_doc_original: docToUpdate.isOriginal
                      ? docToUpdate.isOriginal
                      : false,
                    date_of_collection: docToUpdate.dateCollected
                      ? toIsoDate(docToUpdate.dateCollected)
                      : toIsoDate(serverDate),
                    std_doc_remarks: docToUpdate.remarks
                      ? docToUpdate.remarks
                      : EMPTY_STRING,
                  },
                ],
              },
              refetchQueries: [
                {
                  query: GetStudentDocsByStudentId,
                  variables: {
                    student_id: studentId ? Number(studentId) : state.studentId,
                    token,
                  },
                },
              ],
            }).then(({ data, errors }) => {
              inputFileRefs.current[indexNum].value = "";
              if (data) {
                setMessage({
                  flag: true,
                  message: "Successfully Uploaded File",
                  operation: Operation.CREATE,
                });
              }
              if (errors?.length) {
                setDocuments(() =>
                  documents.map((item) => {
                    if (item.id === doc.id) {
                      return {
                        ...item,
                        preview: null,
                      };
                    }
                    return item;
                  })
                );
              }
            });
          } else {
            UploadStudentDocuments({
              variables: {
                token,
                inst_id: InstId!,
                user_details,
                student_id: studentId ? studentId : state.studentId,
                input: [
                  {
                    std_doc_type: doc.document_name,
                    std_doc_filename: filename,
                    std_doc_download: false,
                    std_doc_collected:
                      docToUpdate && docToUpdate.collected
                        ? docToUpdate.collected
                        : false,
                    std_doc_original:
                      docToUpdate && docToUpdate.isOriginal
                        ? docToUpdate.isOriginal
                        : false,
                    date_of_collection:
                      docToUpdate && docToUpdate.dateCollected
                        ? toIsoDate(docToUpdate.dateCollected)
                        : toIsoDate(serverDate),
                    std_doc_remarks:
                      docToUpdate && docToUpdate.remarks
                        ? docToUpdate.remarks
                        : EMPTY_STRING,
                  },
                ],
              },
              refetchQueries: [
                {
                  query: GetStudentDocsByStudentId,
                  variables: {
                    student_id: studentId ? Number(studentId) : state.studentId,
                    token,
                  },
                },
              ],
            }).then(({ data, errors }) => {
              inputFileRefs.current[indexNum].value = "";
              if (data) {
                setMessage({
                  flag: true,
                  message: "Successfully Uploaded File",
                  operation: Operation.CREATE,
                });
              }
              if (errors?.length) {
                setDocuments(() =>
                  documents.map((item) => {
                    if (item.id === doc.id) {
                      return {
                        ...item,
                        preview: null,
                      };
                    }
                    return item;
                  })
                );
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setDocuments(() =>
            documents.map((item) => {
              if (item.id === doc.id) {
                return {
                  ...item,
                  preview: null,
                };
              }
              return item;
            })
          );
          setMessage({
            flag: true,
            message: `Error Uploading file: ${error}`,
            operation: Operation.CREATE,
          });
        });

      setDocuments(final);
    }
  };

  const handleUpload = (index: number) => {
    if (
      inputFileRefs.current &&
      inputFileRefs.current &&
      inputFileRefs.current[index]
    ) {
      setSeletedIndex(index);
      (inputFileRefs.current[index] as HTMLInputElement).click();
    }
  };

  const handleDelete = (doc: DocsType) => {
    DeleteStudentDocs({
      variables: {
        document_id: doc.docId,
        inst_id: InstId!,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudentDocsByStudentId,
          variables: {
            student_id: studentId ? Number(studentId) : state.studentId,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteStudentDoc) {
        inputFileRefs.current = [];
        setMessage({
          flag: true,
          message: "Document Deleted successfully",
          operation: Operation.DELETE,
        });
        const final: DocsType[] = documents.map((item) => {
          if (item.document_name === doc.document_name) {
            return {
              ...item,
              preview: null,
              fromDb: false,
            };
          } else return item;
        });
        setDocuments(final);
      }
    });
  };
  const AddRefs = (e: HTMLInputElement) => {
    if (
      e &&
      !inputFileRefs.current.includes(e) &&
      inputFileRefs.current.map((e) => e.name).includes(e.name) === false
    ) {
      inputFileRefs.current.push(e);
    }
  };

  useEffect(() => {
    if (state.studentId === 0 && studentId === undefined) {
      inputFileRefs.current = [];
      setDocuments([]);
    }
  }, [state.studentId, studentId]);

  useEffect(() => {
    inputFileRefs.current = [];
  }, [state.studentId]);
  return (
    <div className="student_docs__body">
      <TableContainer className="student_docs__body--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {StudentDocsPreviewTable.Table_Headers.filter(
                (th) =>
                  (th.labelName !== "Actions" && type === Operation.VIEW) ||
                  type === Operation.CREATE ||
                  type === Operation.UPDATE
              ).map((th: TableHeaderProps, index: React.Key) => {
                return (
                  <TableCell key={index} className={th.className}>
                    {th.labelName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents &&
              documents.map((doc, index) => (
                <TableRow key={doc.id}>
                  <TableCell id="td-center">{index + 1}</TableCell>
                  <TableCell>{doc.document_name}</TableCell>
                  <TableCell
                    onClick={() => {
                      if (doc.fromDb && doc.fromDbUrl) {
                        getDownloadUrl(doc.fromDbUrl, true);
                      } else if (doc.preview) {
                        const imageUrl = URL.createObjectURL(doc.preview);
                        const a = document.createElement("a");
                        a.href = imageUrl;
                        a.download = doc.preview.name;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(imageUrl);
                      }
                    }}
                  >
                    {viewPreview(doc)}
                  </TableCell>
                  {isDocCollectionEnabled && (
                    <TableCell id="td-center">
                      <FormControlLabel
                        label={doc.collected ? "Yes" : "No"}
                        disabled={type === Operation.VIEW}
                        control={
                          <AntSwitch
                            checked={doc.collected}
                            onChange={(e) => {
                              const updatedDocuments = [...documents];
                              const isCollectedChecked = e.target.checked;

                              updatedDocuments[index].collected =
                                isCollectedChecked;

                              if (!isCollectedChecked) {
                                updatedDocuments[index].isOriginal = false;
                              }

                              setDocuments(updatedDocuments);
                              setSelectedDocument(index);
                            }}
                          />
                        }
                      />
                    </TableCell>
                  )}

                  {isDocCollectionEnabled && (
                    <TableCell id="td-center">
                      <FormControlLabel
                        label={doc.isOriginal ? "Yes" : "No"}
                        control={
                          <AntSwitch
                            disabled={!doc.collected}
                            checked={doc.collected ? doc.isOriginal : false}
                            onChange={(e) => {
                              const updatedDocuments = [...documents];
                              updatedDocuments[index].isOriginal =
                                e.target.checked;
                              setDocuments(updatedDocuments);
                              setSelectedDocument(index);
                            }}
                          />
                        }
                        className={doc.isOriginal ? "font-green" : "font-red"}
                      />
                    </TableCell>
                  )}

                  {isDocCollectionEnabled && (
                    <TableCell className="student_docs__body--table--input">
                      <Input
                        type="date"
                        value={toInputStandardDate(doc.dateCollected!)}
                        disabled={type === Operation.VIEW}
                        max={toInputStandardDate(serverDate)}
                        onChange={(e) => {
                          const updatedDocuments = [...documents];
                          updatedDocuments[index].dateCollected =
                            e.target.value;
                          setDocuments(updatedDocuments);
                          setSelectedDocument(index);
                        }}
                      />
                    </TableCell>
                  )}

                  {isDocCollectionEnabled && (
                    <TableCell className="student_docs__body--table--input">
                      <Input
                        type="text"
                        value={doc.remarks!}
                        disabled={type === Operation.VIEW}
                        onChange={(e) => {
                          const updatedDocuments = [...documents];
                          updatedDocuments[index].remarks = e.target.value;
                          setDocuments(updatedDocuments);
                          setSelectedDocument(index);
                        }}
                      />
                    </TableCell>
                  )}
                  {type === Operation.CREATE || type === Operation.UPDATE ? (
                    <TableCell
                      id="td-center"
                      className="student_docs__body--table--actions"
                    >
                      <div>
                        <input
                          type="file"
                          ref={AddRefs}
                          name={doc.document_name}
                          onChange={(e) => {
                            handleFileChange(doc, e, index);
                          }}
                        />
                        <Button
                          mode={doc.fromDbUrl ? "docs-delete" : "docs-upload"}
                          onClick={() => {
                            if (doc.fromDbUrl) {
                              handleDelete(doc);
                            } else {
                              handleUpload(index);
                            }
                          }}
                        />
                      </div>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isDocCollectionEnabled && type !== Operation.VIEW ? (
        <Button mode="save" onClick={handleSubmit} />
      ) : null}
      {type === Operation.UPDATE ? (
        <Button
          mode="print"
          onClick={() => setPrintAcknowledgement(!printAcknowledgement)}
        >
          Acknowledgement
        </Button>
      ) : null}
      <LoadingModal flag={loading || deleteLoading || updateLoading} />
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printAcknowledgement}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PrintDocuments
              setModal={setPrintAcknowledgement}
              selectedId={Number(customDocID)}
              selectedStudents={[Number(studentId)!]}
              pageType={DocumentAcknowledgement.STD_DOC_ACK}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Settings}
              alt=""
              onClick={() => setConfiguration(!configuration)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DocsUploadComponent;
