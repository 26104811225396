import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Label } from "../../../../../stories/Label/Label";
import { Title } from "../../../../../stories/Title/Title";
import { AccountsTableHeaderProps } from "../../../../../Types/Tables";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../../utils/constants";
import Avatar from "../../../../../images/Avatar.svg";
import Modal from "react-modal";
import { Button } from "../../../../../stories/Button/Button";
import {
  EditModalCustomStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
} from "../../../../../styles/ModalStyles";
import Settings from "../../../../../images/Settings.svg";
import {
  DateRange,
  formatter,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import { VouchernodeData } from "../../../../../Types/Accounting";

import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  InstitutionConfigurationTypes,
  ModuleName,
  Operation,
  PageFor,
  PredefinedDataTypes,
  ReceiptTypes,
  ReturnType,
  SortBy,
  UserType,
  VoucherBookKey,
} from "../../../../../utils/Enum.types";
import {
  EnqStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../../queries/common";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../../customhooks/useToken";

import { AppContext } from "../../../../../context/context";
import useAcctLedgerData from "../../../hooks/useAcctLedgerData";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../../utils/Form.types";
import { Keys } from "../../../../../utils/Enum.keys";
import usePredefinedDataByType from "../../../../../customhooks/usePredefinedDataByType";
import {
  DeleteAcctVoucherMasterAndDetails,
  EditAcctVoucherMasterForStdRecipts,
} from "../../../queries/receipts/mutation/Index";
import { useParams } from "react-router-dom";
import MessageModal from "../../../../../pages/MessageModal";
import { AcctLedgerQueryType } from "../../../common/QueryTypes";
import { GetAcctVouchers } from "../../../queries/Vouchers/query";
import dayjs from "dayjs";
import LoadingModal from "../../../../../pages/LoadingModal";
import DeleteModal from "../../../../../pages/DeleteModal";
import Close from "../../../../../images/Close.svg";
import useEnquiryStudentDetails from "../../../../../customhooks/useEnquiryStudentDetails";
import useStudentReceiptDetails from "../../../hooks/useStudentReceiptDetails";
import Print from "../../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { Data } from "../../../../Print/Accounts/FeeReceipts/Standard/Index";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../../../common/Enum.types";
import { SwConfigQueryType } from "../../../../HR/enums/Enum.types";
import Configurations from "../../../../Configurations/PerModuleConfiguration";
import { voucherMasterDetails } from "../../../hooks/useAcctVoucherDetailsByDates";
import TextArea from "../../../../../stories/TextArea/TextArea";
import Input from "../../../../../stories/Input/Input";
import VoucherPrint from "../../../../Print/Accounts/Vouchers/VoucherPrint";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import useAcctTableJson from "../../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../../hooks/useLoggedInUserDetails";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../../queries/students/list/byId";
import usePassedOutStudent from "../../../../../customhooks/usePassedOutStudent";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../../../UserRights/hooks/useAssignedInstbyEmpId";
import { GlobalPageConfigData } from "../../../../../Types/configtypes";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../../styles/AutocompleteStyles";

interface nodevars {
  ids: number[];
  token: string;
}

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const StudentVoucherView = ({ setModalFlag }: Props) => {
  const DefaultDate = new Date();
  const { firstDay, lastDay } = DateRange(DefaultDate.toString()) || {};
  const { format } = formatter;
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [imageString, setImageString] = useState("");

  const { Accounts_Table } = useAcctTableJson();
  const { studentData, studentPassedOutFormData, studentFormData } =
    useStudentDatabyId();
  const {
    enquiryStudentData: { enquiryFormData },
  } = useEnquiryStudentDetails();

  const { passedOutStudentFormData } = usePassedOutStudent();
  const { USE_DEPARTMENT_KEY } = useInstitutionConfiguration();
  const [enqstudentReceiptDetails, setEnqStudentReceiptDetails] =
    useState<voucherMasterDetails>();
  const [printConfigModal, setPrintConfigModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [printModal, SetPrintModal] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [tableData, setTableData] = useState<Data[]>([]);

  const saveBtnRef = useRef<HTMLButtonElement>(null);
  const transRefNumberRef = useRef<HTMLInputElement>(null);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const [bankTransactionType, setBankTransactionType] =
    useState<optionsType | null>(null);
  const [rcptDate, setRcptDate] = useState("");
  const [narration, setNarration] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { InstDetails } = useInstDetails(1);

  const { studentReceiptDetails } = useStudentReceiptDetails();

  const {
    configData: { data: configData },
  } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
    InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableFeeReceiptEdit = false;
    let enableVoucherDateEdit = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            enableVoucherDateEdit = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_STD_FEE_RECEIPT:
            enableFeeReceiptEdit = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enableFeeReceiptEdit,
      enableVoucherDateEdit,
    };
  };

  const { enableFeeReceiptEdit, enableVoucherDateEdit } = filterDataByConfigKey(
    configData ? configData.GetSwConfigVariables : []
  );

  const filteredCreditTransactions = state.studentId
    ? studentReceiptDetails?.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.CREDIT
      )
    : enqstudentReceiptDetails?.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.CREDIT
      );

  const filteredDebitTransactions = state.studentId
    ? studentReceiptDetails
      ? studentReceiptDetails.acct_voucher_details.filter(
          (c) => c.vo_cr_db === DebitOrCredit.DEBIT
        )
      : []
    : enqstudentReceiptDetails
    ? enqstudentReceiptDetails.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.DEBIT
      )
    : [];
  let transactionArray =
    studentReceiptDetails?.v_std_refund ||
    studentReceiptDetails?.v_std_refund_deposit
      ? filteredDebitTransactions
      : filteredCreditTransactions;

  const { acctLedgers: cashAndBankLedgers } = useAcctLedgerData(
    EMPTY_STRING,

    studentReceiptDetails?.v_std_receipt_anonymous
      ? AcctLedgerQueryType.ACCT_LDGRS_BANK
      : AcctLedgerQueryType.CASH_AND_BANK,
    ROWS_PER_PAGE
  );

  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING,
    ReturnType.WITH_ID
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnqStudentDetailsByMasterVoucherId
  );
  const [EditStudentVoucher, { loading }] = useMutation(
    EditAcctVoucherMasterForStdRecipts,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [DeleteVoucher, { loading: deleteLoading }] = useMutation(
    DeleteAcctVoucherMasterAndDetails,

    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const showbankDetails =
    cashAndBankLedgers.data?.GetAcctLdgrs.edges.find(
      (d) =>
        transactionBookLedgerId && d.node.id === transactionBookLedgerId?.value
    )?.node.ldgr_cash_bank_other === BankOrCash.CASH
      ? false
      : true;

  const DebitItems = [transactionBookLedgerId];
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setEditModal(false);
      setModalFlag(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteVoucher({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.STUDENT_ALL_RECEIPTS,
              vo_end_date: dayjs(lastDay).format(),
              acct_ldgr_id: 0,
              vo_start_date: dayjs(firstDay).format(),
              vo_type: EMPTY_STRING,
            },
          },
        },
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.STD_DAILY_RECEIPTS,
              vo_end_date: dayjs(lastDay).format(),
              acct_ldgr_id: 0,
              vo_start_date: dayjs(firstDay).format(),
              vo_type: EMPTY_STRING,
            },
          },
        },
        {
          query: GetStdCompleteFeeDetailsByStudentID,
          variables: {
            token,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            inst_id: InstId! ?? state.InstId!,
            student_id: state.studentId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const disableOtherFieldsExceptDate =
    filteredDebitTransactions.length > 0
      ? filteredDebitTransactions[0].acct_ldgr.ldgr_cash_bank_other !==
          BankOrCash.CASH &&
        filteredDebitTransactions[0].acct_ldgr.ldgr_cash_bank_other !==
          BankOrCash.BANK
      : false;

  const cashOrBank =
    (filteredDebitTransactions.length > 0 &&
      filteredDebitTransactions[0]?.acct_ldgr.ldgr_cash_bank_other ===
        BankOrCash.BANK &&
      !transactionBookLedgerId) ||
    cashAndBankLedgers.data?.GetAcctLdgrs.edges.find(
      (f) =>
        transactionBookLedgerId && f.node.id === transactionBookLedgerId.value
    )?.node.ldgr_cash_bank_other === BankOrCash.BANK
      ? bankTransactionTypes.find(
          ({ value }) =>
            bankTransactionType && bankTransactionType.value === value
        )?.label!
      : "Cash Book";
  const v_transcation_cash_or_bank =
    (filteredDebitTransactions.length > 0 &&
      filteredDebitTransactions[0].acct_ldgr.ldgr_cash_bank_other ===
        BankOrCash.BANK &&
      !transactionBookLedgerId) ||
    cashAndBankLedgers.data?.GetAcctLdgrs.edges.find(
      (f) =>
        transactionBookLedgerId && f.node.id === transactionBookLedgerId.value
    )?.node.ldgr_cash_bank_other === BankOrCash.BANK
      ? BankOrCash.BANK
      : BankOrCash.CASH;

  const handleEditStudentReceipt = (e: React.FormEvent) => {
    e.preventDefault();
    const res = bankTransactionTypes.find(
      ({ value }) => bankTransactionType && value === bankTransactionType.value
    );

    EditStudentVoucher({
      variables: {
        token,
        inst_id: InstId,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

        voucher_master_id: studentReceiptDetails?.id,
        user_details,
        input: {
          v_date: toIsoDate(rcptDate),
          v_transcation_narration: narration,
          v_transcation_cash_or_bank,
          v_transcation_no: referenceNumber,
          v_transcation_type: res?.label ?? cashOrBank,
          acct_voucher_db: DebitItems.map((f, index) => ({
            vo_date: toIsoDate(rcptDate),
            vo_cr_db: DebitOrCredit.DEBIT,
            vo_sl_no: index + 1,
            vo_cr: 0,
            vo_db:
              filteredDebitTransactions && filteredDebitTransactions[0]?.vo_db,
            vo_cr_total: 0,
            vo_db_total:
              filteredDebitTransactions &&
              filteredDebitTransactions[0]?.vo_db_total!,
            acct_ldgr_id: f
              ? f.value
              : filteredDebitTransactions![0]?.acct_ldgr.id,
          })),
          acct_voucher_cr: filteredCreditTransactions?.map((item, index) => ({
            vo_sl_no: index + 1,
            vo_cr_db: DebitOrCredit.CREDIT,
            vo_cr: item.vo_cr,
            vo_db: 0,
            vo_cr_total: item.vo_cr_total,
            vo_db_total: 0,
            acct_ldgr_id: item.acct_ldgr_id,
          })),
        },
      },
      refetchQueries: [
        {
          query: StudentDetailsByMasterVoucherId,
          variables: { ids: [state.receiptId], token },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Successfully changed Transaction Type for student ${studentData.data?.nodes[0].first_name}`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  useEffect(() => {
    if (state.receiptId) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: [state.receiptId],
        },
      }).then(({ data }) => {
        if (data) {
          setEnqStudentReceiptDetails(data?.nodes[0]);
        }
      });
    } // eslint-disable-next-line
  }, [state.receiptId, token]);
  useEffect(() => {
    if (transactionArray) {
      setTableData(
        transactionArray.map((data) => ({
          particular: data.acct_ldgr.ldgr_desc,
          amount:
            studentReceiptDetails?.v_std_refund ||
            studentReceiptDetails?.v_std_refund_deposit
              ? data.vo_db
              : data.vo_cr,
        }))
      );
    } // eslint-disable-next-line
  }, [studentReceiptDetails, enqstudentReceiptDetails]);
  useEffect(() => {
    if (studentReceiptDetails) {
      const res = studentReceiptDetails.acct_voucher_details.find(
        (d) => d.vo_cr_db === DebitOrCredit.DEBIT
      )!;
      const bnakType = bankTransactionTypes.find(
        (d) => d.label === studentReceiptDetails.v_transcation_type
      );
      setRcptDate(studentReceiptDetails.v_date);
      setNarration(studentReceiptDetails.v_transcation_narration);
      setReferenceNumber(studentReceiptDetails.v_transcation_no);
      res &&
        setTransactionLedgerId({
          label: res.acct_ldgr.ldgr_desc,
          value: res.acct_ldgr.id,
        });
      bnakType &&
        setBankTransactionType({
          label: bnakType.label,
          value: bnakType.value,
        });
    }
  }, [studentReceiptDetails, bankTransactionTypes]);

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfilePic = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      const _ = getDownloadUrl(studentProfilePic, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  const { branchLabel, classLabel, departmentLabel, categoryLabel } =
    useInstLabels();
  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const isAdmin =
    MultipleInstitutions?.data?.GetInstUserAccess.edges[0]?.node?.pr_emp_details
      .emp_type === UserType.SYS_ADMIN;

  return (
    <>
      {studentReceiptDetails ? (
        studentReceiptDetails.v_std_receipt ? (
          <Title>
            {studentReceiptDetails.v_std_refund
              ? "Refund Fee"
              : studentReceiptDetails.v_std_deposit
              ? "Deposit Fee"
              : studentReceiptDetails.v_std_refund_deposit
              ? "Refund Deposit"
              : "Student Fee Receipt"}
          </Title>
        ) : studentReceiptDetails.v_std_enquiry ? (
          <Title>Enquiry Fee Receipt</Title>
        ) : studentReceiptDetails.v_std_passout_receipt ? (
          <Title>Alumini Fee Receipt</Title>
        ) : (
          EMPTY_STRING
        )
      ) : (
        EMPTY_STRING
      )}
      <div className="student-fee-receipt__modal">
        <div className="row g-0 student-fee-receipt__modal--details">
          <div className="col student-fee-receipt__frame">
            {state.studentId ? (
              <TextField
                label="Admission Number"
                className="student-fee-receipt__frame--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={studentFormData.adm_no}
              />
            ) : null}

            {state.studentId ? (
              <TextField
                label="Reg No."
                className="student-fee-receipt__frame--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
                value={studentFormData.reg_number}
              />
            ) : null}

            <TextField
              label={classLabel}
              className="student-fee-receipt__frame--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={
                state.studentId
                  ? studentData.data?.nodes[0].class.class_desc
                  : state.aluminiStudentId
                  ? studentPassedOutFormData.class
                  : state.studentEnquiryId
                  ? enquiryFormData.seekingClass
                  : state.aluminiStudentId
                  ? passedOutStudentFormData.class_desc
                  : EMPTY_STRING
              }
            />
          </div>
          <div className="col student-fee-receipt__frame g-0">
            <TextField
              label="Name"
              className="student-fee-receipt__frame--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                state.studentId && studentData.data
                  ? studentData.data?.nodes[0].first_name +
                    " " +
                    studentData.data?.nodes[0].middle_name +
                    " " +
                    studentData.data?.nodes[0].last_name
                  : state.aluminiStudentId
                  ? studentPassedOutFormData.std_name
                  : state.studentEnquiryId
                  ? enquiryFormData.enq_student_name
                  : EMPTY_STRING
              }
              disabled
            />
            {state.studentId || state.studentEnquiryId ? (
              <TextField
                label="Father Name"
                className="student-fee-receipt__frame--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={
                  state.studentId
                    ? studentData.data?.nodes[0].std_father_name
                    : state.studentEnquiryId
                    ? enquiryFormData.std_father_name
                    : EMPTY_STRING
                }
                disabled
              />
            ) : null}
            <TextField
              label={branchLabel}
              className="student-fee-receipt__frame--textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                state.studentId
                  ? studentData.data?.nodes[0].branch.branch_desc
                  : state.aluminiStudentId
                  ? studentPassedOutFormData.branch
                  : enquiryFormData.seekingBranch ?? EMPTY_STRING
              }
              disabled
            />
          </div>

          <div className="col student-fee-receipt__frame g-0">
            <TextField
              type="date"
              label=" Voucher Date"
              className="student-fee-receipt__frame--textfield--date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={toInputStandardDate(studentReceiptDetails?.v_date!)}
              disabled
            />
            {state.studentId ? (
              <TextField
                value={
                  studentData.data?.nodes[0].category.cat_desc ?? EMPTY_STRING
                }
                label={categoryLabel}
                className="student-fee-receipt__frame--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                disabled
              />
            ) : null}

            {USE_DEPARTMENT_KEY && (
              <TextField
                label={departmentLabel}
                className="student-fee-receipt__frame--textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={
                  state.studentId
                    ? studentData.data?.nodes[0].dept.dept_desc
                    : state.studentEnquiryId
                    ? enquiryFormData.seekingDept
                    : EMPTY_STRING
                }
                disabled
              />
            )}
          </div>
          <div className="col-1 student-fee-receipt__frame--image">
            <img src={imageString ? imageString : Avatar} alt="/" />
          </div>
        </div>

        <div className="row g-0 student-fee-receipt__tableblock--modal">
          <TableContainer className="student-fee-receipt__table g-0">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.StudentFeeReceipt.Table_Headers.map(
                    (th: AccountsTableHeaderProps, index: React.Key) => {
                      return (
                        th.type && (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        )
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionArray
                  ?.filter(
                    (crData) =>
                      (studentReceiptDetails?.v_std_refund ||
                      studentReceiptDetails?.v_std_refund_deposit
                        ? crData.vo_db
                        : crData.vo_cr) !== 0
                  )
                  ?.map((crData, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="student-fee-receipt__table--slno"
                          align="center"
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell className="student-fee-receipt__table--desc">
                          {crData.acct_ldgr.ldgr_desc}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="student-fee-receipt__table--amount"
                        >
                          {format(
                            studentReceiptDetails?.v_std_refund ||
                              studentReceiptDetails?.v_std_refund_deposit
                              ? crData.vo_db
                              : crData.vo_cr
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Fine :
                  </TableCell>
                  <TableCell
                    id="td-right"
                    className="balance-count student-fee-receipt__table__borderbottom"
                  >
                    {format(0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total :
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {filteredDebitTransactions &&
                      format(filteredDebitTransactions[0]?.vo_db_total)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0 student-fee-receipt__receiptblock--modal">
          <div className="col-4 student-fee-receipt__receiptblock--frame--modal">
            <TextField
              label="Receipt No."
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                state.studentId
                  ? studentReceiptDetails?.v_no
                  : enqstudentReceiptDetails?.v_no ?? EMPTY_STRING
              }
              disabled
              className="student-fee-receipt__receiptblock--modal--textfield"
            />
            <TextField
              label="Paid Through"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                filteredDebitTransactions?.[0]?.acct_ldgr.ldgr_desc
                  ? state.studentId
                    ? !studentReceiptDetails?.v_std_refund_deposit ||
                      !studentReceiptDetails?.v_std_refund
                      ? studentReceiptDetails?.v_transcation_type
                      : `${filteredDebitTransactions[0].acct_ldgr.ldgr_desc} (${
                          studentReceiptDetails?.v_transcation_type ?? ""
                        })`
                    : `${filteredDebitTransactions[0].acct_ldgr.ldgr_desc}  (${
                        studentReceiptDetails?.v_transcation_type ?? ""
                      })`
                  : EMPTY_STRING
              }
              className="student-fee-receipt__receiptblock--modal--textfield"
              disabled
            />

            <TextField
              label="Reference Number"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                state.studentId
                  ? studentReceiptDetails?.v_transcation_no!
                  : enqstudentReceiptDetails?.v_transcation_no ?? EMPTY_STRING
              }
              className="student-fee-receipt__receiptblock--modal--textfield"
              disabled
            />
          </div>
          <div className="col student-fee-receipt__receiptblock--frame--modal narration-grid">
            <Label>Remarks :</Label>
            <TextArea
              rows={3}
              value={
                state.studentId
                  ? studentReceiptDetails?.v_transcation_narration!
                  : enqstudentReceiptDetails?.v_transcation_narration! ??
                    EMPTY_STRING
              }
              disabled
            />
          </div>
        </div>

        {state.claims?.EMPLOYEE ? (
          <div>
            <Button mode="print" onClick={() => SetPrintModal(!printModal)}>
              &nbsp;Preview
            </Button>
            <Button
              mode="edit"
              onClick={() => setEditModal(!editModal)}
              disabled={
                enableFeeReceiptEdit === false
                  ? (studentReceiptDetails &&
                      studentReceiptDetails.v_std_refund) ||
                    state.studentEnquiryId
                    ? true
                    : false
                  : studentReceiptDetails &&
                    studentReceiptDetails.v_book_type ===
                      ReceiptTypes.ONLINE_STD_RECEIPT
                  ? true
                  : false
              }
            />
            <Button
              mode="delete"
              onClick={() => setDeleteModal(!deleteModal)}
            />
            <Button
              mode="cancel"
              type="button"
              onClick={() => setModalFlag(false)}
            />
          </div>
        ) : null}
      </div>
      {/* edit-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editModal}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Edit Receipt Date / Fee Collected Mode</Title>

            <form
              className="student-fee-receipt__receiptblock--edit"
              onSubmit={handleEditStudentReceipt}
            >
              <div className="student-fee-receipt__receiptblock--edit--text">
                <Label>
                  Previous Transaction Type : &nbsp;
                  <b>
                    {studentReceiptDetails?.v_transcation_type! ?? EMPTY_STRING}
                  </b>
                </Label>
                <TextField
                  type="date"
                  label="Date"
                  className="student-fee-receipt__receiptblock--edit--textfield--date"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    input: {
                      inputProps: {
                        min: state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                          : EMPTY_STRING,
                        max: toInputStandardDate(TODAY_DATE),
                      },
                    },
                  }}
                  value={toInputStandardDate(rcptDate)}
                  onChange={(e) => setRcptDate(e.target.value)}
                  disabled={enableVoucherDateEdit === false}
                />
              </div>
              <div className="student-fee-receipt__receiptblock--edit--label-grid">
                <Label>Transaction Ledger</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  options={cashAndBankLedgers.responseType || []}
                  openOnFocus
                  value={transactionBookLedgerId}
                  disabled={disableOtherFieldsExceptDate}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                  }}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(
                      option as responseType,
                      transactionBookLedgerId
                    )
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setTransactionLedgerId(newValue as responseType);
                      setBankTransactionType(null);
                      setReferenceNumber("");
                      // e.preventDefault();
                    } else {
                      setTransactionLedgerId(null);
                      setBankTransactionType(null);
                      setReferenceNumber("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </div>

              {showbankDetails && transactionBookLedgerId ? (
                <>
                  <div className="student-fee-receipt__receiptblock--edit--label-grid">
                    <Label>Transaction Reference Number</Label>
                    <Input
                      inputRef={transRefNumberRef!}
                      onChange={(e) => setReferenceNumber(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          handleMUISelectEvent(e);
                        }
                      }}
                      disabled={disableOtherFieldsExceptDate}
                      required
                      value={referenceNumber}
                    />
                    <Label>Bank Transaction Type</Label>
                    <FormAutocomplete
                      className={formClasses.inputRoot}
                      options={bankTransactionTypes!}
                      openOnFocus
                      freeSolo
                      autoHighlight
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(
                          option as optionsType,
                          bankTransactionType
                        )
                      }
                      value={bankTransactionType}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setBankTransactionType(newValue as optionsType);
                        } else {
                          setBankTransactionType(null);
                        }
                      }}
                      disabled={disableOtherFieldsExceptDate}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (transactionBookLedgerId && e.key === Keys.ENTER) {
                          handleMUISelectEvent(e);
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setBankTransactionType(null);
                        }
                      }}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          className={formClasses.formControlRoot}
                        />
                      )}
                    />
                  </div>
                </>
              ) : null}
              <div className="student-fee-receipt__receiptblock--edit--label-grid">
                <Label>Remarks</Label>
                <TextArea
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && !e.shiftKey) {
                      if (saveBtnRef && saveBtnRef.current) {
                        e.preventDefault();
                        saveBtnRef.current?.focus();
                      }
                    }
                  }}
                  disabled={disableOtherFieldsExceptDate}
                  rows={3}
                  value={narration}
                  required
                  onChange={(e) => setNarration(e.target.value)}
                />
              </div>

              <div>
                <Button mode="save" type="submit" buttonref={saveBtnRef} />

                <Button
                  mode="cancel"
                  onClick={() => setEditModal(!editModal)}
                />
              </div>
            </form>
            {/* this should be as it is avd */}
            <MessageModal
              modalFlag={message.flag!}
              value={message.message!}
              handleClose={handleClose}
              operation={message.operation!}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setEditModal(!editModal)} />
          </div>
        </div>
      </Modal>
      {/* delete-modal */}
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={state.receiptId}
      />
      {/* print-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={
          printModal &&
          studentReceiptDetails &&
          studentReceiptDetails.v_book_type !==
            VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK
            ? true
            : false
        }
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={tableData}
              v_date={
                studentReceiptDetails?.v_date
                  ? studentReceiptDetails?.v_date
                  : enqstudentReceiptDetails?.v_date!
              }
              v_no={
                studentReceiptDetails?.v_no
                  ? studentReceiptDetails?.v_no
                  : enqstudentReceiptDetails?.v_no!
              }
              transaction_no={
                studentReceiptDetails?.v_transcation_no
                  ? studentReceiptDetails?.v_transcation_no
                  : enqstudentReceiptDetails?.v_transcation_no!
              }
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => SetPrintModal(!printModal)}
            />
            {isAdmin ? (
              <>
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setPrintConfigModal(!printConfigModal)}
                />
              </>
            ) : null}
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={
          printModal &&
          studentReceiptDetails &&
          studentReceiptDetails.v_book_type ===
            VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK
            ? true
            : false
        }
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherPrint setModalFlag={SetPrintModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfigModal)}
            />
          </div>
        </div>
      </Modal>
      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfigModal}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={
                studentReceiptDetails &&
                studentReceiptDetails.v_book_type ===
                  VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK
                  ? ModuleName.VOUCHER_PRINT
                  : ModuleName.RECEIPT_PRINT
              }
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfigModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={deleteLoading || loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentVoucherView;
