import { useMutation, useLazyQuery } from "@apollo/client";
import Modal from "react-modal";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetAcdYrsByInstId,
  AcdYrDetailsByNode,
} from "../../../../../queries/academicyear/list/byid";
import Home from "../../../Home/Index";
import { AddInstAcdYr } from "../../../../../queries/institution/mutations/new";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import { ConfigurationsModalStyles } from "../../../../../styles/ModalStyles";

import {
  toInputStandardDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";

import dayjs from "dayjs";

import { Form, Formik } from "formik";

import { academicYear, msgType, responseType } from "../../../../../utils/Form.types";
import Input from "../../../../../components/common/Input/Index";

import { academic_validation } from "../../../../../utils/validationRules";
import { InstitutionDetailsFormLabelsType } from "../../../../../Types/Institution/json";
import useToken from "../../../../../customhooks/useToken";
import {  TextField } from "@mui/material";
import { Label } from "../../../../../stories/Label/Label";
import MessageModal from "../../../../../pages/MessageModal";
import { Keys } from "../../../../../utils/Enum.keys";
import Settings from "../../../../../images/Settings.svg";
import {
  LicenseTypes,
  Operation,
  PageFor,
  PageNumbers,
} from "../../../../../utils/Enum.types";
import useDisplayConfigIcon from "../../../../../customhooks/useDisplayConfigIcon";
import LoadingModal from "../../../../../pages/LoadingModal";
import ConfigurationSettings from "../../../../Master/configurations/general/Index";
import Close from "../../../../../images/Close.svg";
import { EMPTY_STRING } from "../../../../../utils/constants";
import Steps from "../Steps";
import { AppContext } from "../../../../../context/context";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import PrAcdYearList from "./PrAcdYearList";
import { GetPayRollAcdYrsByInstId } from "../../../../HR/queries/academicyear/query";
import { AddPayRollAcdYr } from "../../../../HR/queries/academicyear/mutations/new";
import { FormAutocomplete, formClasses } from "../../../../../styles/AutocompleteStyles";

const { Academic_Year } = require("../../../../../json/config.json");
interface Props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const PrAcdYear = ({ pageType, setModal }: Props) => {
  const { InstId, custId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [operation, setOperation] = useState(Operation.CREATE);

  const [academicYearId, setAcademicYearId] = useState(0);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [FutureYear, setFutureYear] = useState("");
  const [configurationModal, setConfigurationModal] = useState(false);

  const [formData, setFormData] = useState<academicYear>({
    AcdYear: "",
    startDate: "",
    endDate: "",
  });

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.ACADEMIC_YEAR_PAGE
  );

  const [GetacademicYearData, { data }] = useLazyQuery(AcdYrDetailsByNode, {
    variables: { token, id: academicYearId },
  });

  const [newAcademicYear, { loading: creationLoading }] = useMutation(
    AddPayRollAcdYr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const acdYrRef = useRef<HTMLSelectElement>(null);
  const acdYrInputRef = acdYrRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;

  const OldAcademicYears = () => {
    var currentYear = new Date().getFullYear();
    return [...new Array(3).keys()].map((i) => {
      const start = dayjs(`04-01-${currentYear - 1}`)
        .subtract(i, "year")
        .format();
      const end = dayjs(`03-31-${currentYear}`).subtract(i, "year").format();
      return {
        label: `${dayjs(start).format("YYYY")}-${dayjs(end).format("YYYY")}`,
        value: {
          startDate: start,
          endDate: end,
        },
      };
    });
  };

  const upcommingAcademicYears = () => {
    var currentYear = new Date().getFullYear();

    return [...new Array(5).keys()].map((i) => {
      const start = dayjs(`4-1-${currentYear}`).add(i, "year").format();
      const end = dayjs(`03-31-${currentYear + 1}`)
        .add(i, "year")
        .format();

      return {
        label: `${dayjs(start).format("YYYY")}-${dayjs(end).format("YYYY")}`,
        value: {
          startDate: start,
          endDate: end,
        },
      };
    });
  };
  const years = [...upcommingAcademicYears(), ...OldAcademicYears()];
  years.sort(
    (a, b) =>
      new Date(a.value.startDate).valueOf() -
      new Date(b.value.startDate).valueOf()
  );

  useEffect(() => {
    if (selectedAcademicYear) {
      const filteredAcademicYear = years.filter(
        ({ label }) => label === selectedAcademicYear
      );
      setFormData({
        AcdYear: filteredAcademicYear[0]?.label,
        endDate: toInputStandardDate(filteredAcademicYear[0]?.value.endDate),
        startDate: toInputStandardDate(
          filteredAcademicYear[0]?.value.startDate
        ),
      });
    } // eslint-disable-next-line
  }, [selectedAcademicYear]);

  const HandleRegister = async () => {
    // backend api call
    await newAcademicYear({
      variables: {
        token,
        inst_id: InstId,
        user_details,

        input: {
          payroll_yr: formData.AcdYear,
          payroll_st_date: toIsoDate(formData.startDate),
          payroll_end_date: toIsoDate(formData.endDate),
          payroll_is_curr_yr: false,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollAcdYrsByInstId,
          variables: { inst_id: InstId || state.InstId, token },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Successfully Added  Academic year",
          flag: true,
          operation: Operation.CREATE,
        });
    });
    setOperation(Operation.CREATE);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleclear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    acdYrInputRef?.focus();
  };
  const handleclear = () => {
    setFormData({
      AcdYear: "",
      startDate: "",
      endDate: "",
    });
    setSelectedAcademicYear("");
    setOperation(Operation.CREATE);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    //validations
    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let day = new Date().getDate();
    if (formData.startDate) {
      year = new Date(formData.startDate).getFullYear();
      month = new Date(formData.startDate).getMonth() + 1;
      day = new Date(formData.startDate).getDate();
    }
    let FutureYear = new Date(year + 1, month + 3, day)
      .toISOString()
      .split("T")[0];
    // let PreviousYear = new Date(year + 1, month, day)
    //   .toISOString()
    //   .split("T")[0];
    // setPreviousYear(PreviousYear);
    setFutureYear(FutureYear);
  }, [formData.startDate]);

  useEffect(() => {
    if (data && operation === Operation.UPDATE) {
      const { acd_yr, acd_st_date, acd_end_date } = data.node || "";

      setFormData({
        AcdYear: acd_yr,
        startDate: acd_st_date ? acd_st_date.split("T")[0] : null,
        endDate: acd_end_date ? acd_end_date.split("T")[0] : null,
      });
    }
  }, [data, operation]);

  return (
    <>
      {pageType === PageFor.GENERAL && (
        <>
          <Home
            DashBoardRequired={false}
            NavType={LicenseTypes.EDUATE_CUSTOMER}
          />
          <Steps step={4} />
        </>
      )}
      <div
        className={
          pageType === PageFor.MODAL
            ? "acd-masters-frame__modal"
            : "acd-masters-frame"
        }
      >
        <div className="row g-0 acd-masters-frame__title">
          <div className="col">
            <Title >PayRoll Academic Year</Title>
          </div>
          {USE_CONFIG_KEY && (
            <div className="configuration-settings">
              <img
                src={Settings}
                alt="/"
                id="settings-icon"
                onClick={() => setConfigurationModal(!configurationModal)}
              />
            </div>
          )}
        </div>
        <Formik
          initialValues={formData}
          validationSchema={academic_validation}
          onSubmit={HandleRegister}
          enableReinitialize
        >
          {(meta) => {
            return (
              <Form className="acd-masters-frame__form">
                <div className="row g-0 acd-masters-frame__datablock">
                  <div className="col booktype-left h-100">
                    <Title variant="subtitle1">Add PayRoll Academic Year</Title>
                    <div className="acd-masters-frame__tableblock">
                      {operation === Operation.CREATE ? (
                        <>
                          <div className="label-grid">
                            <Label>Choose Academic Year</Label>
                            <FormAutocomplete
                              className={formClasses.inputRoot}
                              ref={acdYrRef!}
                              onKeyDown={(e: any) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  if (formData.AcdYear) {
                                    startDateRef?.current?.focus();
                                  }
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setSelectedAcademicYear(EMPTY_STRING);
                                }
                              }}
                              options={years}
                              openOnFocus
                              forcePopupIcon
                              value={
                                years?.find(
                                  ({ label }) => label === selectedAcademicYear
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setSelectedAcademicYear((newValue as responseType)?.label!);
                                } else {
                                  setSelectedAcademicYear(EMPTY_STRING);
                                }
                                setFormData({
                                  AcdYear: "",
                                  startDate: "",
                                  endDate: "",
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required={!formData?.AcdYear}
                                  {...params}
                                  autoFocus
                                  fullWidth
                                  className={formClasses.formControlRoot}
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        Academic_Year.Year.map(
                          (
                            label: InstitutionDetailsFormLabelsType,
                            index: React.Key
                          ) => {
                            return (
                              <React.Fragment key={index}>
                                <Input
                                  LabelName={label.LabelName}
                                  name={
                                    operation === Operation.UPDATE &&
                                    label.inputName
                                  }
                                  type={label.dataType}
                                  values={formData[label.inputName]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    meta.handleChange(e);
                                    handleValueChange(e);
                                  }}
                                  required={label.required}
                                  autoFocus={label.autoFocus}
                                  disabled
                                />
                              </React.Fragment>
                            );
                          }
                        )
                      )}
                      {Academic_Year.StartDate.map(
                        (
                          label: InstitutionDetailsFormLabelsType,
                          index: React.Key
                        ) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                name={label.inputName}
                                LabelName={label.LabelName}
                                type={label.dataType}
                                inputRef={startDateRef!}
                                values={formData[label.inputName]}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    endDateRef?.current?.focus();
                                  }
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                required={label.required}
                                autoFocus={label.autoFocus}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                      {Academic_Year.EndDate.map(
                        (
                          label: InstitutionDetailsFormLabelsType,
                          index: React.Key
                        ) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                name={label.inputName}
                                LabelName={label.LabelName}
                                type={label.dataType}
                                inputRef={endDateRef!}
                                values={formData[label.inputName]}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    saveRef?.current?.focus();
                                  }
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                max={FutureYear}
                                required={label.required}
                                autoFocus={label.autoFocus}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <PrAcdYearList
                    onlyTable={false}
                    GetacademicYearData={GetacademicYearData}
                    setAcademicYearId={setAcademicYearId}
                    setOperation={setOperation}
                  />
                </div>

                <Button type="submit" mode="save" buttonref={saveRef} />

                <Button
                  mode="move"
                  onClick={() =>
                    navigate(`/eduate/${custId}/${InstId}/moduleConfiguration`)
                  }
                >
                  &nbsp;next
                </Button>

                <Button mode="clear" type="button" onClick={handleclear} />
                {pageType === PageFor.MODAL ? (
                  <Button mode="cancel" onClick={() => setModal(false)} />
                ) : (
                  <Button
                    mode="back"
                    onClick={() =>
                      navigate(`/eduate/${custId}/${InstId}/academicyear`)
                    }
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </div>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={creationLoading} />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.ACADEMIC_YEAR_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PrAcdYear;
