import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Avatar from "../../../../images/Avatar.svg";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { toInputStandardDate } from "../../../../utils/UtilFunctions";
import useInstLabels from "../../../../customhooks/general/useInstLabels";

const StdDetails = () => {
  const { studentFormData } = useStudentDatabyId();
  const [imageString, setImageString] = useState("");

  useEffect(() => {
    if (studentFormData.std_profile_filename !== "") {
      getDownloadUrl(
        studentFormData.std_profile_filename,
        false,
        setImageString
      );
    }
  }, [studentFormData.std_profile_filename]);
  const { categoryLabel } = useInstLabels();

  return (
    <>
      <div className="combinations-individual__details row g-0">
        <div className="col">
          <TextField
            className="combinations-individual__textfield"
            label="Adm no"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData && studentFormData.adm_no}
          />
          <TextField
            className="combinations-individual__textfield"
            label="Reg no"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData && studentFormData.reg_number}
          />
          <TextField
            className="combinations-individual__textfield"
            label="DOB"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={
              studentFormData && toInputStandardDate(studentFormData.std_dob)
            }
          />
        </div>
        <div className="col">
          <TextField
            className="combinations-individual__textfield"
            label="Name"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData && studentFormData.std_name}
          />
          <TextField
            className="combinations-individual__textfield"
            label="Father Name"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData && studentFormData.father_name}
          />
          <TextField
            className="combinations-individual__textfield"
            label={categoryLabel}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData && studentFormData.category}
          />
        </div>
        <div className="col">
          <TextField
            className="combinations-individual__textfield"
            label="Year"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={
              studentFormData && toInputStandardDate(studentFormData.std_dob)
            }
          />{" "}
          <TextField
            className="combinations-individual__textfield"
            label="Mobile no"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData && studentFormData.std_mobile}
          />{" "}
          <TextField
            className="combinations-individual__textfield"
            label="Academic Year"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            disabled
            value={studentFormData && studentFormData.acd_yr}
          />
        </div>
        <div className="col-1 combinations-individual__profile">
          {imageString ? (
            <img src={imageString} alt="" />
          ) : (
            <img src={Avatar} alt="" />
          )}
        </div>
      </div>
    </>
  );
};

export default StdDetails;
