import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionType,
  TableHeaders,
} from "../../../../../../utils/Enum.types";
import Topper from "../../../../../../images/MakeAsGlobalUser.svg";
import SecondTopper from "../../../../../../images/SecondTopper.svg";
import ThirdTopper from "../../../../../../images/ThirdTopper.svg";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../../styles/DataGridTableStyles";
import Input from "../../../../../../stories/Input/Input";
import {
  FETCH_MORE_DATA,
  getPercentageColor,
  getTextPercentageColor,
} from "../../../../../../utils/constants";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../../../hooks/useTestStatus";
import { useNavigate, useParams } from "react-router-dom";
import {
  AcdStdMarksQueryType,
  TestMarksEdge,
  TestMarksNode,
} from "../../../../hooks/useStudentTestMarksForAllotedLevel";
import useStudentTestMarksForAllotedLevel from "../../../../hooks/useStudentTestMarksForAllotedLevel";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../../../../utils/UtilFunctions";
import useTestDetailsForAllotedLevel from "../../../../hooks/useTestDetailsForAllotedLevel";
import Eduate from "../../../../../../images/Eduate_Logo_image.png";

import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_ALIGNMENT_LEFT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../../../Library/Constants";
import useInstDetails from "../../../../../../customhooks/general/useInstDetails";
import { AppContext } from "../../../../../../context/context";
import useInstLogoDetails from "../../../../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../../../../Library/customHooks/useServerDateandTime";
import { Button } from "../../../../../../stories/Button/Button";
import useSwConfigInstType from "../../../../hooks/useSwConfigInstType";

const TableView = () => {
  const navigate = useNavigate();
  const { institutionType } = useSwConfigInstType();

  const { InstDetails } = useInstDetails(1);
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { allotedID, testId } = useParams();

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<TestMarksEdge[]>([]);
  const [searchStudent, setSearchStudent] = useState("");
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.FINALIZED_TESTS,
    Number(allotedID)
  );
  const status = statusOfSubject.data?.GetAcdTestMarksStatus?.find(
    (d) => d?.acd_test_class_id === Number(testId)
  );
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const {
    testMarks: { data, fetchMore, loading },
  } = useStudentTestMarksForAllotedLevel(
    AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
    null,
    searchStudent
  );

  const { testConductDetails } = useTestDetailsForAllotedLevel();

  const dynamicHeaders =
    (testConductDetails.data?.node.class_subject_details || []).map(
      (th, index) => ({
        field: `subj_marks_scored_${index + 1}`,
        headerName: `${th.subject_master_details.subj_desc}  (${
          th.subj_total_max_marks + "/" + th.subj_total_min_marks
        })`,
        headerAlign: HEADER_TEXT_ALIGN as GridAlignment,
        align: SLNO_TEXT_ALIGN as GridAlignment,
        cellClassName: "td-marks",
        renderCell: (params: any) => {
          return (
            <div
              className={
                params.row[`subj_marks_scored_${index + 1}`] === "-"
                  ? `bg-grey`
                  : ""
              }
              style={{ width: "100%", textAlign: "center" }}>
              {params.row[`subj_marks_scored_${index + 1}`]}
            </div>
          );
        },
      })
    ) || [];
  const columns: GridColDef[] = [
    {
      headerName: "Sl No.",
      field: "id",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",

      align: SLNO_TEXT_ALIGN,
    },
    {
      headerName: "Rank No.",
      field: "rank",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-rank",

      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <>
            {params.row.rank === 1 ? (
              <img src={Topper} alt="/" />
            ) : params.row.rank === 2 ? (
              <img src={SecondTopper} alt="/" />
            ) : params.row.rank === 3 ? (
              <img src={ThirdTopper} alt="/" />
            ) : null}
          </>
        );
      },
    },
    {
      field: "std_adm_no",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      align: SLNO_TEXT_ALIGN,
    },
    ...(institutionType !== InstitutionType.SCHOOL
      ? [
          {
            headerName: TableHeaders.REGISTER_NUMBER,
            field: "std_reg_no",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
            cellClassName: "td-adm-no",
          },
        ]
      : []),
    {
      field: "std_name",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-name",
      headerName: TableHeaders.STUDENT_NAME,
      flex: 1,
      hideable: false,
    },
    ...dynamicHeaders,
    {
      field: "total_marks",
      headerName: "Total Scored Marks ",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-marks font-green",
      align: SLNO_TEXT_ALIGN,
    },

    {
      field: "total_percentage",
      headerName: "Total Percentage ",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-att-percentage",
      align: MARKS_TEXT_ALIGN,
      renderCell: (params) => {
        const percentage = parseFloat(params.value);
        const cellBackgroundColor = getPercentageColor(percentage);
        const textPercentageColor = getTextPercentageColor(percentage);

        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              color: textPercentageColor,
              backgroundColor: `${cellBackgroundColor}`,
            }}>
            {params.value}%
          </div>
        );
      },
    },
  ];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStdTestMarks.edges;
                const pageInfo = fetchMoreResult.GetStdTestMarks.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetStdTestMarks.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetStdTestMarks: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetStdTestMarks.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  const getDynamicData = (node: TestMarksNode) => {
    let marks: Record<string, string | number> = {};
    let totalMarks = 0;

    testConductDetails.data &&
      testConductDetails.data.node.class_subject_details.forEach(
        ({ subj_sl, subject_master_details }, index) => {
          const fieldName = `subj_marks_scored_total_${subj_sl}`;
          const statusField = `subj_marks_status_${subj_sl}`;
          const subjectId = `elective_subj_id_${subj_sl}`;
          const res = node[fieldName as keyof TestMarksNode];

          const resSubjectId = node[subjectId as keyof TestMarksNode];
          const marksEntryStatus = status && [
            // @ts-ignore
            status[statusField] as boolean,
          ];

          const subjMarks =
            subject_master_details.id === resSubjectId
              ? // @ts-ignore
                res >= 0
                ? Number(res)
                : "A"
              : resSubjectId === 0
              ? // @ts-ignore
                res >= 0
                ? Number(res)
                : "A"
              : "-";
          // @ts-ignore
          marks = {
            ...marks,
            [`subj_marks_scored_${index + 1}`]: subjMarks,
          }; // @ts-ignore
          totalMarks += subjMarks !== "-" ? parseFloat(subjMarks) : 0;
        }
      );

    marks["total_marks"] = totalMarks;

    return marks;
  };

  const getOrdinal = (n: number): string => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };
  const getMarksRowData = (item: TestMarksEdge, index: number): any[] => {
    const studentDetails = item.node.student_details;
    const fullName = `${studentDetails.first_name} ${studentDetails.middle_name} ${studentDetails.last_name}`;
    const rankDetails = item.node.rank_allotted_level;
    const rankString = getOrdinal(rankDetails);
    const marksData = getDynamicData(item.node);

    return [
      index + 1,
      rankString,
      studentDetails.std_adm_no,
      studentDetails.std_reg_no,
      fullName,
      ...Object.values(marksData),
    ];
  };
  const tableDataForExcelAndPdf =
    data &&
    data.GetStdTestMarks.edges.map((item: TestMarksEdge, index) =>
      getMarksRowData(item, index)
    );
  const subjectsLabels =
    testConductDetails?.subjectsOptions?.map((res) => res.label) || [];
  const StudentHeaders = [
    "SlNo",
    "Rank",
    "Admisiion No",
    "Reg No",
    "Student Name",
    ...subjectsLabels,
    "Total Marks",
  ];
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Academics Test Reports");
    const dataRows = tableDataForExcelAndPdf;
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 35;
    worksheet.getColumn(6).width = 22;
    worksheet.getColumn(7).width = 22;
    worksheet.getColumn(8).width = 22;
    worksheet.getColumn(9).width = 22;
    worksheet.getColumn(10).width = 22;
    worksheet.getColumn(11).width = 22;
    worksheet.getColumn(12).width = 22;
    worksheet.getColumn(13).width = 22;
    worksheet.getColumn(14).width = 22;
    worksheet.getColumn(15).width = 22;
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "H1:H3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(
                    1,
                    1,
                    1,
                    StudentHeaders ? StudentHeaders.length : 8
                  );

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells(`A2 :`);

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:H3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Class Wise Academic Report";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell(F4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("F4:H4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < StudentHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = StudentHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStdTestMarks.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: `${index + 1} `,
            rank: node.rank_allotted_level,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            ...getDynamicData(node),
            total_percentage: node.total_percent,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: `${index + 1}  `,
            rank: node.rank_allotted_level,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            ...getDynamicData(node),
            total_percentage: node.total_percent,
          }))
        );
      }
      setEndCursor(data.GetStdTestMarks.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <div className="class-wise-rank__t-view">
        <div className="class-wise-rank__t-view--select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchStudent(e.target.value);
              }}
            />
          </div>
          <div className="col"></div>
          <div className="col-3 flex-end">
            <div className="student-total-count">
              Total Students :{" "}
              <b>{data ? data.GetStdTestMarks.totalCount : 0}</b>
            </div>
          </div>
        </div>
        <div className={`class-wise-rank__t-view--tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
            disableRowSelectionOnClick
          />
        </div>
        {/* <Button mode="pdf" type="button" /> */}

        <Button mode="excel" type="button" onClick={downloadExcel}>
          Export
        </Button>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default TableView;
