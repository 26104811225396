import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormControlLabel, TextField } from "@mui/material";
import Modal from "react-modal";
import {
  ColumnVisibilityFor,
  Direction,
  LoginStatus,
  Operation,
  PrimaryContact,
  SortBy,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useAcdStudentsData, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsData";
import Close from "../../../../images/Close.svg";
import Edit from "../../../../images/EditProfile.svg";
import Home from "../../Home/Index";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { StudentsTitlesType } from "../../../../Types/Student/json";
import Input from "../../../../stories/Input/Input";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import { AntSwitch } from "../../../../pages/Switch";
import { useMutation } from "@apollo/client";
import {
  EnableStudentLoginStatusWithNewEmail,
  UpdateStudentLoginStatus,
} from "../../../../queries/students/mutations/new";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { GetAcdStudents } from "../../../Academics/queries/students/Index";
import useAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import { InstitutionCustomStyles } from "../../../../styles/ModalStyles";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import useStudentTableJson from "../../../../json/useStudentTableJson";
import { FetchStudentStatusClassName } from "../List";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { Label } from "../../../../stories/Label/Label";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../styles/AutocompleteStyles";
import { ParentType } from "../StudentPreview/BasicData";

const { Students } = require("../../../../json/title.json");
interface Props {
  queryType: StudentReportType;
}
const StudentList = ({ queryType }: Props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const saveRef = useRef<HTMLButtonElement>(null);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_LOGIN_STATUS,
  });
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [mobileNo, setMobileNo] = useState("");

  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [statusSelected, setStatusSelected] = useState<string>("");

  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [searchData, setSearchData] = useState("");
  const [studentId, setStudentId] = useState(0);
  const [eMail, setEmail] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");

  const [emailModal, setEmailModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();
  const { AcademicsStudentData } = useAcdStudentsData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    statusSelected === LoginStatus.Created
      ? StudentReportType.LOGIN_ACTIVE
      : statusSelected === LoginStatus.Not_Created
      ? StudentReportType.LOGIN_INACTIVE
      : queryType
  );

  const [EnableLogin, { loading: EnableLoading }] = useMutation(
    UpdateStudentLoginStatus,
    {
      onError: (e) => {
        setEmailModal(!emailModal);
      },
    }
  );
  const [UpdateEmail, { loading }] = useMutation(
    EnableStudentLoginStatusWithNewEmail,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleNumberChange = (mobileNo: string) => {
    setMobileNo(mobileNo);
  };
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { activeAcademicYearData } = useAcademicYear();

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setSearchData("");
  };

  const foundStudentData = AcademicsStudentData.data
    ? AcademicsStudentData.data.GetAcdStudents.edges.find(
        (data) => data.node.id === studentId
      )
    : null;

  const stdData = foundStudentData ? foundStudentData.node : null;

  const fullName = stdData
    ? `${stdData.first_name} ${stdData.middle_name} ${stdData.last_name}`
    : EMPTY_STRING;

  const branchName = stdData ? stdData.acd_branch.branch_desc : EMPTY_STRING;
  const className = stdData ? stdData.acd_class.class_desc : EMPTY_STRING;

  const handleSubmit = (student_id: number) => {
    const flagData = AcademicsStudentData.data?.GetAcdStudents.edges.find(
      (data) => data.node.id === student_id
    )?.node;
    EnableLogin({
      variables: {
        token,
        student_id,
        inst_id: InstId!,
        user_details,
        login_status: !flagData?.is_login_created,
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,

            after: null,
            first: ROWS_PER_PAGE,
            input: {
              acd_std_query_type: StudentAcdType.BY_ACD_INST_ID,

              ids: [Number(InstId!)],
              str_data: [""],
            },
            name: "",
            token,
            orderBy: [{ direction: Direction.ASC, field: SortBy.STD_ROLL_NO }],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: !flagData?.is_login_created
            ? "Student Login Enabled Successfully"
            : "Student Login Disabled Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleUpdate = () => {
    UpdateEmail({
      variables: {
        token,
        student_id: studentId,
        login_data: {
          std_email: eMail,
          std_mobile: !mobileNo
            ? EMPTY_STRING
            : mobileNo.includes("+")
            ? mobileNo
            : "+" + mobileNo,
          std_primary_login: primaryContact ? primaryContact : "",
        },
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            after: null,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,
            first: ROWS_PER_PAGE,
            name: "",
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [Number(InstId!)],
              acd_std_query_type: StudentAcdType.BY_ACD_INST_ID,
              str_data: [""],
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Student Details Updated Successfully",
          operation: Operation.UPDATE,
        });
        setEmailModal(!emailModal);
        setEmail(EMPTY_STRING);
      }
    });
  };

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        if (header.field === "primary_contact_phone_no") {
          return (
            <>
              {params.row.primary === PrimaryContact.FATHER ? (
                <>
                {params.row.std_father_mobile}</>
              ) : params.row.primary === PrimaryContact.MOTHER ? (
                <>
                {params.row.std_mother_mobile}
                </>
              ) : params.row.primary === PrimaryContact.GUARDIAN ? (
                <>
                {params.row.std_guardian_mobile}</>
              ) : (
                params.row.std_mobile
              )}
            </>
          );
        } else if (header.field === "primary_contact_email") {
          return (
            <>
              {params.row.primary === PrimaryContact.FATHER ? (
                <>
                {params.row.std_father_email}</>
              ) : params.row.primary === PrimaryContact.MOTHER ? (
                <>
                 {params.row.std_mother_email}
                 </>
              ) : params.row.primary === PrimaryContact.GUARDIAN ? (
                <>
                {params.row.std_guardian_email}</>
              ) : (
                params.row.std_email
              )}
            </>
          );
        }
        return params.value;
      },
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "actions",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        return (
          <>
            <FormControlLabel
              label=""
              control={
                <AntSwitch
                  checked={params.row.is_login_created}
                  onClick={() => {
                    handleSubmit(params.row.std_id);
                    setStudentId(params.row.std_id);
                    setMobileNo(params.row.std_mobile);
                  }}
                />
              }
              labelPlacement="start"
            />
            &nbsp; &nbsp;
            <img
              src={Edit}
              alt="/"
              onClick={() => {
                setStudentId(params.row.std_id);
                setMobileNo(params.row.std_mobile);
                setEmailModal(!emailModal);
              }}
            />
          </>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
      status: false,
      category: false,
    });
  useEffect(() => {
    if (AcademicsStudentData?.data && !AcademicsStudentData?.loading) {
      const newData = AcademicsStudentData.data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [AcademicsStudentData.data, AcademicsStudentData.loading, statusSelected]);
  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          AcademicsStudentData.fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: AcademicsStudentData.data
                    ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && students.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [students]);

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_LOGIN_STATUS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_LOGIN_STATUS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  const tableData = students.map((res, index) => {
    return {
      id: index + 1,
      std_adm_no: res.node.std_adm_no,
      std_reg_no: res.node.std_reg_no,
      std_name:
        res.node.first_name +
        " " +
        res.node.middle_name +
        " " +
        res.node.last_name,
      std_father_name: res.node.std_father_name,
      std_mobile: res.node.std_mobile,
      status: res.node.std_status,
      std_id: res.node.id,
      std_mother_name: res.node.std_mother_name,
      std_sex: res.node.std_sex,

      std_email: res.node.std_email,
      is_login_created: res.node.is_login_created,
      dept: res.node.acd_dept.dept_desc,
      branch: res.node.acd_branch.branch_desc,
      class: res.node.acd_class.class_desc,
      sem: res.node.acd_semester.sem_desc,
      sec: res.node.acd_section.section_desc,
      category: res.node.category.cat_desc,
      primary: res.node.std_primary_login,
      std_type: res.node.std_fresher ? "Fresher" : "Old Student",
      father_email: res.node.std_father_email,
      mother_email: res.node.std_mother_email,
      std_guardian_email: res.node.std_guardian_email,
      father_mobile: res.node.std_father_mobile,
      mother_mobile: res.node.std_mother_mobile,
      std_guardian_mobile: res.node.std_guardian_mobile,
    };
  });

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Login Status</Title>
      <div className="std-enable-login">
        <form className="row g-0 std-enable-login__options">
          <div className="col-1 studentlist__select-flex studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={Object.keys(LoginStatus)}
              openOnFocus
              value={statusSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatusSelected(newValue as LoginStatus);
                  setHasNextPage(true);
                } else {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className={"student-clear-button--acd"}
            />
          </div>
        </form>
        <div className={`std-enable-login__tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={tableData}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 std-enable-login__total">
            <StudentTotalCount
              totalCount={AcademicsStudentData.data?.GetAcdStudents.totalCount!}
            />
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={emailModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="row g-0">
              <Title variant="subtitle1">
                {Students.BasicDetails.Titles.map(
                  (title: StudentsTitlesType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>{title.Email}</React.Fragment>
                    );
                  }
                )}
              </Title>
            </div>
            <TextField
              className="std-enable-login__textfield"
              label="Name"
              value={fullName}
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              className="std-enable-login__textfield"
              label={branchLabel}
              value={branchName}
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              className="std-enable-login__textfield"
              label={classLabel}
              value={className}
              disabled
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              className="std-enable-login__textfield--email"
              label="Update E-mail"
              autoFocus
              value={eMail}
              onChange={(e) => setEmail(e.target.value)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <PhoneInput
              country={"in"}
              value={mobileNo}
              onChange={handleNumberChange}
              inputProps={{
                required: true,
                name: "Phone No.",
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  saveRef.current?.focus();
                }
              }}
            />
            <Label>Primary Contact</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={ParentType}
              value={
                ParentType.find((option) => option.value === primaryContact) ??
                EMPTY_OPTIONSTYPE_OBJECT
              }
              onChange={(e, newValue) => {
                //@ts-ignore
                setPrimaryContact(newValue ? newValue.value : "");
              }}
              openOnFocus
              freeSolo={false}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={formClasses.formControlRoot}
                />
              )}
            />
            <div className="frame-space">
              <Button
                type="submit"
                mode="save"
                buttonref={saveRef!}
                onClick={handleUpdate}
              />

              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  setEmailModal(!emailModal);
                  setEmail(EMPTY_STRING);
                }}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setEmailModal(!emailModal);
                setEmail(EMPTY_STRING);
              }}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || EnableLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentList;
