import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "../../../../stories/Button/Button";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import EduateLogo from "../../../../images/Eduate_Logo_image.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Title } from "../../../../stories/Title/Title";
import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  Direction,
  ExcelAlignment,
  ExcelFont,
  ExcelPageHeader,
  FileUploadParams,
  PredefinedDataTypes,
  SortByFilterType,
  StudentDetailedReportType,
  StudentOrderFields,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import Input from "../../../../stories/Input/Input";

import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import { studentReservationHeaders } from "../../../Accounts/common/HeaderConsts";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";

import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { optionsType, responseType } from "../../../../utils/Form.types";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../context/context";
import useFinancialYears from "../../../Academics/hooks/useFinancialYears";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import SortByAutocomplete from "../../../../components/common/Autocompletes/SortByAutocomplete";
import StatusAutocomplete from "../../../../components/common/Autocompletes/StatusAutocomplete";

interface Props {
  setRelgionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: StudentDetailedReportType;
}
const StdReligionWise = ({ setRelgionModal, type }: Props) => {
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { financialYearResponse } = useFinancialYears();

  const { state } = useContext(AppContext);
  const {
    PredefinedData: { dropDown: Religion },
  } = usePredefinedDataByType(PredefinedDataTypes.RELIGION, EMPTY_STRING);

  const [religionSelected, setReligionSelected] = useState<optionsType | null>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [acdYearSelected, setAcdYearSelected] = useState<responseType | null>(
    null
  );
  const [searchData, setSearchData] = useState("");
  const [searchAdm, setSearchAdm] = useState("");
  const { InstDetails } = useInstDetails(1);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );

  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );

  const [sortBySelected, setSortBySelected] = useState<optionsType | null>(
    null
  );

  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.STDS_BY_RELIGION,
    statusSelected ? statusSelected.value : EMPTY_STRING,
    [],
    [
      {
        direction: Direction.ASC,
        field: sortBySelected
          ? sortBySelected.value
          : StudentOrderFields.FIRST_NAME,
      },
    ],
    0,
    religionSelected ? religionSelected.value : EMPTY_STRING
  );

  const stdData = AcademicsStudentData.data
    ? AcademicsStudentData.data.GetAcdStudents.edges.map((data, index) => ({
        SlNo: index + 1,
        AdmNo: data.node.std_adm_no,
        RegNo: data.node.std_reg_no,
        Name:
          data.node.first_name +
          " " +
          data.node.middle_name +
          " " +
          data.node.last_name,
        Religion: data.node.std_details.std_religion,
      }))
    : [];

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
    sectionDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0
  );
  const { categoryDropDown } = useInstMasterDataByInstId();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData.loading) {
        AcademicsStudentData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };
  const studentCol = [
    { title: "Sl", field: "SlNo" },
    { title: "Adm No", field: "AdmNo" },
    { title: "Reg. No.", field: "RegNo" },
    { title: "Name", field: "Name" },
    { title: "Religion", field: "Religion" },
  ];

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(EduateLogo)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();

                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 19
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Religion Wise Students List`, 120, startY + 19);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 19
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  // doc.addImage(result2, "JPEG", 80, 5, 55, 55);
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col.field,
                    styles: { fontSize: 18 },
                  })),

                  body: stdData,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };
  // eslint-disable-next-line
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Reserved Students");

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.SlNo,
          item.AdmNo,
          item.RegNo,
          item.Name,
          item.Religion,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "G1:G3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0].inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:G2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:G3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Reserved Students";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell(F4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("F4:G4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < studentReservationHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = studentReservationHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                      cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails?.data?.nodes[0].inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !AcademicsStudentData.loading)
      downloadExcel();
    if (pdfFlag && rowsPerPage === null && !AcademicsStudentData.loading)
      downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, AcademicsStudentData.loading]);
  useEffect(() => {
    if (
      AcademicsStudentData.data &&
      !AcademicsStudentData.loading &&
      excelFlag === false &&
      pdfFlag === false
    ) {
      const newData = AcademicsStudentData.data
        ? AcademicsStudentData.data.GetAcdStudents.edges.map((edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true, // set default value of isChecked to true
            },
          }))
        : [];

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setHasNextPage(
        AcademicsStudentData.data.GetAcdStudents.pageInfo.hasNextPage
      );
      setEndCursor(AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    AcademicsStudentData.data,
    AcademicsStudentData.loading,
    excelFlag,
    pdfFlag,
  ]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel,
  } = useInstLabels();

  return (
    <>
      {type === StudentDetailedReportType.RELIGION_WISE ||
      type === StudentDetailedReportType.CASTE_WISE ||
      type === StudentDetailedReportType.YEAR_OF_JOINING ? null : (
        <div className="row g-0">
          <div className="col">
            <Title>Student Religion Wise List</Title>
          </div>
        </div>
      )}

      <div className="std-reservation__modal">
        <div className="row g-0 std-reservation__options">
          <div className="col-1 studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchAdm(e.target.value);
                setSearchData(e.target.value);
              }}
              value={searchData !== "" ? searchData : searchAdm}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      branchInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    id="outlined Departments"
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                ref={branchRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      semInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setCategorySelected(null);
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                ref={semRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      categoryInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={sectionDropDown}
              ref={semRef!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, sectionSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    categoryInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(null);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setSectionSelected(null);
                  setCategorySelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>

          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                ref={categoryRef!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {type === StudentDetailedReportType.YEAR_OF_JOINING ? null : (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={Religion}
                ref={categoryRef!}
                openOnFocus
                // isOptionEqualToValue={(option) =>
                //   isOptionEqualToValue(option as responseType, categorySelected)
                // }
                value={religionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setReligionSelected(newValue as optionsType);
                    setHasNextPage(true)
                  } else {
                    setReligionSelected(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setReligionSelected(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Religion"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          )}
          {type === StudentDetailedReportType.YEAR_OF_JOINING && (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={
                  financialYearResponse.data
                    ? financialYearResponse.responseType
                    : []
                }
                ref={categoryRef!}
                openOnFocus
                // isOptionEqualToValue={(option) =>
                //   isOptionEqualToValue(option as responseType, categorySelected)
                // }
                value={acdYearSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setAcdYearSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setAcdYearSelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setAcdYearSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Fin Year"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          )}
          <div className="col"></div>
        </div>

        {type === StudentDetailedReportType.RELIGION_WISE ||
        type === StudentDetailedReportType.CASTE_WISE ? (
          <div className="std-detailed-report__options row g-0">
            <div className="col-1">
              <SortByAutocomplete
                label="Sort By"
                onChange={(newValue: optionsType | null) =>
                  setSortBySelected(newValue as optionsType)
                }
                sortByType={SortByFilterType.STUDENT}
                sortByValue={EMPTY_STRING}
              />
            </div>
            <div className="col-1">
              <StatusAutocomplete
                label="Status"
                onChange={(newValue: optionsType | null) => {
                  setStatusSelected(newValue as optionsType);
                }}
                statusValue={
                  statusSelected ? statusSelected.value : EMPTY_STRING
                }
              />
            </div>
            <div className="col"></div>
          </div>
        ) : null}

        <div className="std-reservation__tableblock">
          {!students?.length ? (
            <b className="nodata"> No students Found</b>
          ) : (
            <TableContainer
              className="std-reservation__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell id="td-center">{TableHeaders.SLNO}</TableCell>
                    <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
                    <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
                    <TableCell>{TableHeaders.NAME}</TableCell>

                    {type === StudentDetailedReportType.CASTE_WISE ? (
                      <TableCell>{TableHeaders.CASTE}</TableCell>
                    ) : (
                      <TableCell>{TableHeaders.RELIGION}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students?.map((res, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="std-reservation__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="std-reservation__table--admno">
                          {res.node.std_adm_no}
                        </TableCell>
                        <TableCell className="std-reservation__table--admno">
                          {res.node.std_reg_no}
                        </TableCell>
                        <TableCell>
                          {res.node.first_name +
                            " " +
                            res.node.middle_name +
                            " " +
                            res.node.last_name}
                        </TableCell>
                        {type === StudentDetailedReportType.CASTE_WISE ? (
                          <TableCell className="std-reservation__table--email">
                            {res.node.std_details.std_caste}
                          </TableCell>
                        ) : (
                          <TableCell className="std-reservation__table--email">
                            {res.node.std_details.std_religion}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {type === StudentDetailedReportType.RELIGION_WISE && (
              <>
                <Button mode="clear" />
                {/* <Button mode="print" /> */}
              </>
            )}

            <Button
              onClick={() => {
                setRowsPerPage(null);
                setPdfFlag(true);
              }}
              disabled={classSelected && classSelected.value ? false : true}
              mode="pdf"
            />
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setExcelFlag(true);
              }}
              disabled={classSelected && classSelected.value ? false : true}
              mode="export"
            />
            {type === StudentDetailedReportType.RELIGION_WISE ? null : (
              <Button mode="cancel" onClick={() => setRelgionModal?.(false)} />
            )}
          </div>
          <div className="col-3 std-reservation__total">
            <StudentTotalCount
              totalCount={
                AcademicsStudentData?.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                  : 0
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default StdReligionWise;
