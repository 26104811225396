import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import {
  GetAcctLdgrYearlyDataByInstId,
  GetAcctRegisterYearlyDataByInstId,
} from "../../queries/Accountingledgers/List/ById";
import {
  GetAcctLdgrMonthlyDataByInstIdVars,
  GetAcctLdgrYearlyDataByInstIdData,
  GetAcctLdgrYearlyDataByInstIdDetails,
  GetAcctRegisterYearlyDataByInstIdData,
  GetAcctRegisterYearlyDataByInstIdDetails,
  GetAcctRegisterYearlyDataByInstIdVars,
} from "../../../../Types/Accounting";
import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import AccountLedger from "./AccountLedger";
import LedgerTransactionDetails from "./LedgerTransactionDetails";
import { useLazyQuery } from "@apollo/client";
import { BankOrCash, Operation, PageFor } from "../../../../utils/Enum.types";
import CompleteMonthlyTotalModal from "./Modals/CompleteMonthlyTotalModal";
import useToken from "../../../../customhooks/useToken";
import { EMPTY_STRING, emptyMessageType } from "../../../../utils/constants";
import {
  LedgersOrRegisters,
  OtherAccountLedgerBooks,
} from "../../../../utils/types";
import { belongsAccountLedgerBooks } from "./MonthlyTotal";
import { ExportAcctStdtoExcel } from "../../../../queries/xls";
import {
  ExportAcctStdtoExcelData,
  ExportAcctStdtoExcelVars,
} from "../../../Master/Reports/InstitutionLevel/useExportMutation";
import {
  DateRange,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { SortBy } from "../../../Master/Reports/InstitutionLevel/GlobalStates/types";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import { VoucherQueryTypes } from "../../common/Enum.types";
interface props {
  type: LedgersOrRegisters;
  pageType: PageFor;
  setModalFlag?: (modalFlag: boolean) => void;
}
const Index = ({ type, pageType, setModalFlag }: props) => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const dates = DateRange(state.date.toString());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState(emptyMessageType);
  const [acctldgrYearlyData, setAcctLdgrYearlyData] = useState<
    GetAcctLdgrYearlyDataByInstIdDetails[]
  >([]);
  const [registeryearlyData, setRegisterYearlyData] = useState<
    GetAcctRegisterYearlyDataByInstIdDetails[]
  >([]);

  const [GetYearlyDataOfParticularAccountingLedger] = useLazyQuery<
    GetAcctLdgrYearlyDataByInstIdData,
    GetAcctLdgrMonthlyDataByInstIdVars
  >(GetAcctLdgrYearlyDataByInstId, { fetchPolicy: "network-only" });
  const [GetAcctRegisterYearlyData] = useLazyQuery<
    GetAcctRegisterYearlyDataByInstIdData,
    GetAcctRegisterYearlyDataByInstIdVars
  >(GetAcctRegisterYearlyDataByInstId, { fetchPolicy: "network-only" });

  const [ExportAcct, { loading }] = useLazyQuery<
    ExportAcctStdtoExcelData,
    ExportAcctStdtoExcelVars
  >(ExportAcctStdtoExcel);

  useEffect(() => {
    if (
      state.ledger?.value &&
      state.ActiveFinYr &&
      type !== OtherAccountLedgerBooks.REGISTER
    )
      GetYearlyDataOfParticularAccountingLedger({
        variables: {
          acct_ldgr_id: state.ledger.value!,
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      }).then(({ data }) => {
        setAcctLdgrYearlyData(data?.GetAcctLdgrYearlyDataByInstId!);
      });
  }, [
    state.ActiveFinYr,
    state.ledger?.value,
    GetYearlyDataOfParticularAccountingLedger,
    InstId,
    token,
    setAcctLdgrYearlyData,
    type,
  ]);
  useEffect(() => {
    if (
      state.registerType &&
      state.ActiveFinYr &&
      type === OtherAccountLedgerBooks.REGISTER
    ) {
      GetAcctRegisterYearlyData({
        variables: {
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr.id!,
          register_type: state.registerType,
        },
      }).then(({ data }) => {
        setRegisterYearlyData(data?.GetAcctRegisterYearlyDataByInstId!);
      });
    }
  }, [
    state.registerType,
    state.ActiveFinYr,
    InstId,
    token,
    type,
    GetAcctRegisterYearlyData,
    setRegisterYearlyData,
  ]);

  useEffect(() => {
    if (dates && dates.firstDay && dates.lastDay) {
      setStartDate(toInputStandardDate(dates?.firstDay!));
      setEndDate(toInputStandardDate(dates?.lastDay!));
    }
  }, [dates]);
  const hanldeExport = () => {
    try {
      ExportAcct({
        variables: {
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: {
            float_data: [],
            ids: [Number(InstId), 0],
            int_data: [],
            std_status: [],
            str_data: [],
          },
          voucher_input: {
            acct_ldgr_id: state.ledger ? state.ledger.value : 0,
            vo_start_date: toIsoDate(startDate),
            vo_end_date: toIsoDate(endDate),
            vo_type: EMPTY_STRING,
            v_book_type: EMPTY_STRING,
          },
          inst_id: InstId!,
          parameters: [],
          query_type: VoucherQueryTypes.BY_DATES,
          sort_by: SortBy.STD_NAME,
          token,
        },
      }).then(async ({ data, error }) => {
        if (data && data.ExportAcctStdtoExcel) {
          const res = await getDownloadUrl(data.ExportAcctStdtoExcel, true);
          if (res) {
            setMessage({
              flag: true,
              message: "Downloading...",
              operation: Operation.CREATE,
            });
          }
        } else {
          setMessage({
            flag: true,
            message: "Failed downloading file",
            operation: Operation.NONE,
          });
        }

        if (error && error.message) {
          setMessage({
            flag: true,
            message: error.message,
            operation: Operation.NONE,
          });
        }
      });
    } catch (e) {
      setMessage({
        flag: true,
        message: "Failed to download",
        operation: Operation.NONE,
      });
    }
  };

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>
        {belongsAccountLedgerBooks(type) &&
        type === OtherAccountLedgerBooks.GROUP_LEDGER
          ? `Ledger : ${state.ledger ? state.ledger.label : EMPTY_STRING}`
          : type === BankOrCash.BANK
          ? `Bank : ${state.ledger ? state.ledger.label : EMPTY_STRING}`
          : type === BankOrCash.CASH
          ? `Cash : ${state.ledger ? state.ledger.label : EMPTY_STRING}`
          : `Register : ${
              state.register ? state.register.label : EMPTY_STRING
            }`}
      </Title>

      <div
        className={
          pageType === PageFor.GENERAL ? "bank-book" : "bank-book--modal"
        }>
        {pageType === PageFor.GENERAL ? (
          <div className="bank-book__group-ledger h-100">
            <AccountLedger type={type} />
          </div>
        ) : null}

        <div
          className={
            pageType === PageFor.GENERAL
              ? "h-100 bank-book__table-section"
              : "h-100 bank-book__table-section--modal"
          }>
          {/* {pageType === PageFor.GENERAL ? (
            <div className="css3-tabstrip">
              <ul>
                <li>
                  <NavLink
                    to={`/${InstId}/accounts/accountledgerbooks/accountingledger`}
                  >
                    <input
                      type="radio"
                      name="css3-tabstrip-0"
                      id="css3-tabstrip-0-2"
                    />
                    <label htmlFor="css3-tabstrip-0-2">Accounting Ledger</label>
                  </NavLink>
                </li>
                 To implemented in future
                <li>
                  <NavLink
                    to={`/${InstId}/accounts/accountledgerbooks/groupledgers`}
                  >
                    <input
                      type="radio"
                      name="css3-tabstrip-0"
                      id="css3-tabstrip-0-2"
                    />
                    <label htmlFor="css3-tabstrip-0-2">Group Ledgers</label>
                  </NavLink>
                </li>{" "}
                *
                <li>
                  <NavLink
                    to={`/${InstId}/accounts/accountledgerbooks/registers`}
                  >
                    <input
                      type="radio"
                      name="css3-tabstrip-0"
                      id="css3-tabstrip-0-2"
                    />
                    <label htmlFor="css3-tabstrip-0-2">Registers</label>
                  </NavLink>
                </li>
              </ul>
            </div>
          ) : null} */}

          {type !== OtherAccountLedgerBooks.FEE_COLLECTED &&
          type !== OtherAccountLedgerBooks.GROUP_LEDGER ? (
            <>
              <div className="bank-book__table-section--monthly-total">
                <CompleteMonthlyTotalModal
                  registerYearlyData={registeryearlyData}
                  acctldgrYearlyData={acctldgrYearlyData}
                  type={type}
                />
              </div>
              <div className="bank-book__table-section--transaction-details">
                <LedgerTransactionDetails type={type} />
              </div>
            </>
          ) : (
            <b className="nodata">
              Please Select Account Ledger in order to proceed
            </b>
          )}
        </div>
      </div>
      {pageType === PageFor.GENERAL ? (
        <div className="button-left">
          {type !== "REGISTER" && (
            <Button
              mode="export"
              onClick={hanldeExport}
              disabled={state.ledger === null && state.register === null}
            />
          )}
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      ) : (
        <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
      )}
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default Index;
