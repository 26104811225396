import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GetInstDocuments } from "./queries";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { GetInstDocumentVars, GetInstDocumentsData } from "./Types";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { DeleteDocument } from "./mutation";
import { msgType } from "../../../utils/Form.types";
import { Operation } from "../../../utils/Enum.types";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import { EMPTY_STRING } from "../../../utils/constants";

export interface UpdateDocumentTyps {
  id: number;
  name: string;
}
interface props {
  fetchEditDetails: (id: number, document_name: string) => void;
}
const List = ({ fetchEditDetails }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();

  const { user_details } = useLoggedInUserDetails();

  const [deleteModal, setDeleteModal] = useState(false);
  const [documentId, setDocumentId] = useState(0);
  const [documentName, setDocumentName] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetInstDocumentData, { data }] = useLazyQuery<
    GetInstDocumentsData,
    GetInstDocumentVars
  >(GetInstDocuments, {
    variables: {
      token,
      inst_id: InstId!,
      custom_doc_name: documentName ? documentName : EMPTY_STRING,
    },
  });
  useEffect(() => {
    if (token) {
      GetInstDocumentData();
    }
  }, [token, GetInstDocumentData]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl No",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,
    },
    {
      field: "document_name",
      headerName: "Document Name",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,
      flex: 1,
      hideable: false,
    },
    {
      field: "doc_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <img
              src={Edit}
              alt=""
              onClick={() => {
                fetchEditDetails(
                  params.row.document_main_id,
                  params.row.document_name
                );
              }}
            />
            <img
              src={Delete}
              alt=""
              onClick={() => {
                setDocumentId(params.row.document_main_id);
                setDeleteModal(!deleteModal);
                setDocumentName(params.row.doc_name);
              }}
            />
          </>
        );
      },
    },
  ];
  const rows: GridValidRowModel[] =
    (data?.GetInstDocuments || []).map((res, index) => {
      return {
        id: index + 1,
        document_name: res.document_name,
        document_main_id: res.id,
      };
    }) || [];
  const handleClick = (params: GridCellParams) => {
    if (params.field === "document_name") {
      navigate(
        `/${InstId}/institutionconfiguration/${params.row.document_main_id}/documents/generate`
      );
    }
  };

  const [DeleteDocumentsData, { loading: deleteLoading }] = useMutation(
    DeleteDocument,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const HandleDelete = (id: number) => {
    if (InstId)
      DeleteDocumentsData({
        variables: {
          token,
          id,
          inst_id: InstId,
          user_details,
          document_id: documentId,
        },
        refetchQueries: [
          {
            query: GetInstDocuments,
            variables: {
              token,
              inst_id: InstId!,
              custom_doc_name: documentName ? documentName : EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Document Deleted Successfully",
            operation: Operation.DELETE,
          });
          setDeleteModal(!deleteModal);
        }
      });
  };
  return (
    <>
      <div className={`document-create__table`}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          // experimentalFeatures={{ columnGrouping: true }}
          // columnGroupingModel={columnGroupingModel}
          onCellClick={(params: GridCellParams) => {
            handleClick(params);
          }}
          hideFooter
          //   onSortModelChange={}
        />
      </div>
      <LoadingModal flag={deleteLoading} />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={documentId}
      />
    </>
  );
};

export default List;
