import React, { useEffect, useState } from "react";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../utils/Enum.types";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { useMutation } from "@apollo/client";
import {
  AddInstBranchLogo,
  AddMstInstLogoAndSigns,
} from "../../../../queries/institution/mutations/new";
import { userDetails } from "../../../../Types/Accounting/other";
import { GetMstInstLogoAndSigns } from "../../../../queries/institution/list";
import useUploadComponent from "../../../../customhooks/useUploadComponent";
import { EMPTY_STRING } from "../../../../utils/constants";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { Button } from "../../../../stories/Button/Button";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { TextField } from "@mui/material";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Keys } from "../../../../utils/Enum.keys";
import useDropdownData from "../../../../customhooks/useDropdownData";

interface Result {
  AddMstInstLogo: {
    id: number;
  };
}
interface variables {
  token: string;
  inst_id: number | string;
  user_details: userDetails;
  file_type: string;
  file_name: string;
}
interface props {
  file_type: FileUploadParams;
}
const FileUpload = ({ file_type }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [imageString, setImageString] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const { InstDetails } = useInstDetails(1);
  const { USE_DEPARTMENT_KEY } = useInstitutionConfiguration();
  const { departmentLabel, branchLabel } = useInstLabels();
  const { branchDropDown, departmentDropDown } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    0,
    0
  );

  const [AddLogos, { loading }] = useMutation<Result, variables>(
    AddMstInstLogoAndSigns,
    {
      onError: (e) => {
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
      },
    }
  );
  const [AddBranchLogos, { loading: branchLoading }] = useMutation(
    AddInstBranchLogo,
    {
      onError: (e) => {
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
      },
    }
  );

  const getFilePath = () => {
    if (!InstDetails.data || !InstDetails.data.nodes.length) {
      return EMPTY_STRING;
    }

    const instName = InstDetails.data.nodes[0].inst_name;

    switch (file_type) {
      case FileUploadParams.CHAIRMAN_SIGNATURE:
        return `${instName}/signature/chairman`;
      case FileUploadParams.PRINCIPAL_SIGNATURE:
        return `${instName}/signature/principal`;
      case FileUploadParams.PHOTO_1:
        return `${instName}/signature/photo1`;
      case FileUploadParams.PHOTO_2:
        return `${instName}/signature/photo2`;
      case FileUploadParams.PHOTO_3:
        return `${instName}/signature/photo3`;
      case FileUploadParams.BRANCH_LOGO:
        return branchSelected && branchSelected.value
          ? `${instName}/logos/branch/${branchSelected.value}/logo`
          : EMPTY_STRING;
      default:
        return `${instName}/logos/logo`;
    }
  };

  const { component, uploadFile, setFile, file, filename } = useUploadComponent(
    getFilePath(),
    Operation.CREATE,
    EMPTY_STRING,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE
  );

  const handleClose = () => {
    setFile(null);
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      InstDetails.data &&
      !InstDetails.loading &&
      InstDetails.data.nodes.length
    ) {
      // eslint-disable-next-line
      const res = getDownloadUrl(
        file_type === FileUploadParams.CHAIRMAN_SIGNATURE
          ? `${InstDetails.data.nodes[0].inst_name}/signature/chairman`
          : file_type === FileUploadParams.PRINCIPAL_SIGNATURE
          ? `${InstDetails.data.nodes[0].inst_name}/signature/principal`
          : file_type === FileUploadParams.PHOTO_1
          ? `${InstDetails.data.nodes[0].inst_name}/signature/photo1`
          : file_type === FileUploadParams.PHOTO_2
          ? `${InstDetails.data.nodes[0].inst_name}/signature/photo2`
          : file_type === FileUploadParams.PHOTO_3
          ? `${InstDetails.data.nodes[0].inst_name}/signature/photo3`
          : file_type === FileUploadParams.BRANCH_LOGO
          ? `${InstDetails.data.nodes[0].inst_name}/logos/branch/${
              branchSelected && branchSelected.value
            }/logo`
          : `${InstDetails.data.nodes[0].inst_name}/logos/logo`,
        false,
        setImageString
      );
    }
  }, [InstDetails.data, InstDetails.loading, branchSelected]);
  const fileUpload = async () => {
    try {
      const uploadResult = await uploadFile();
      if (uploadResult && file && file_type === FileUploadParams.BRANCH_LOGO) {
        AddBranchLogos({
          variables: {
            token,
            inst_id: InstId!,
            branch_id: branchSelected ? branchSelected.value : 0,
            user_details,
            file_name: filename,
          },
          refetchQueries: [
            {
              query: GetMstInstLogoAndSigns,
              variables: { token, inst_id: InstId!, file_type: file_type },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Uploaded Logo",
              operation: Operation.CREATE,
            });
          }
        });
      } else if (!file) {
        setMessage({
          flag: true,
          message: "Please Select A file ",
          operation: Operation.NONE,
        });
      }

      if (uploadResult && file && file_type !== FileUploadParams.BRANCH_LOGO) {
        AddLogos({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            file_name: filename,
            file_type,
          },
          refetchQueries: [
            {
              query: GetMstInstLogoAndSigns,
              variables: { token, inst_id: InstId!, file_type: file_type },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Uploaded Logo",
              operation: Operation.CREATE,
            });
          }
        });
      } else if (!file) {
        setMessage({
          flag: true,
          message: "Please Select A file ",
          operation: Operation.NONE,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="row inst-logo__frame g-0">
        {/* <div className="col-3">
         <div className="label-grid">
           <Label>Upload Logo</Label>
           <Input disabled value={file?.name || EMPTY_STRING} />
         </div>
       </div>

       <div className="col-2 ">
         <div className="inst-logo__buttons">
           <div className="inst-logo__browse">
             <label className="inst-logo__browse--fileattach--label">
               <img src={FileAttach} alt="/" className="browse-icon" />
               Browse
               <input
                 type="file"
                 id="inst-logo__browse--fileattach--inputtag"
                 className="inst-logo__browse--fileattach--input"
                 onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                   handleImagePreview(e)
                 }
                 accept="image/png,image/jpeg"
               />
             </label>
           </div>
         </div>
         {ComponentWarning.mediaSizeComponent}
         <progress value={progress} />
       </div> */}
        {USE_DEPARTMENT_KEY && file_type === FileUploadParams.BRANCH_LOGO ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && departmentSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                } else {
                  setDepartmentSelected(null);
                }
                setBranchSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {file_type === FileUploadParams.BRANCH_LOGO && (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && branchSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        )}
        <div className="col-5">{component}</div>
        <div className="col-2">
          <Button
            disabled={!file ? true : false}
            mode="upload"
            onClick={fileUpload}
          />
        </div>
      </div>

      <div className="row g-0 inst-logo__datablock">
        {imageString && (
          <div className="col h-100">
            <img src={imageString} alt="/" className="inst-logo__image" />
          </div>
        )}
      </div>

      <LoadingModal flag={loading || branchLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default FileUpload;
