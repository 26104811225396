import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";

import LibraryDetails from "./LibraryDetails";
import {
  PageFor,
  StudentDashBoardFor,
  StudentPreviewFor,
} from "../../../../utils/Enum.types";
import Modal from "react-modal";
import { ParentStudentDashboardStyles } from "../../../../styles/ModalStyles";
import { Label } from "../../../../stories/Label/Label";
import { useLazyQuery } from "@apollo/client";
import {
  parentDashboardByParentIdData,
  parentStudentAssociationByParentIdVars,
} from "../../../../Types/Student";
import { ListParentStudentAssocByParentId } from "../../../../queries/parent/list/byId";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { useNavigate } from "react-router-dom";
import { payloadTypes } from "../../../../context/reducer";
import Home from "./Home/Index";
import Calendar from "./Calendar";
import FeeDetails from "./FeeDetails";
import Announcements from "./Announcements";
import CampusActivity from "./CampusActivity";
import useActiveInstModules from "../../../UserRights/hooks/useActiveInstModules";
import StudentDetails from "./StudentDetails";
interface Props {
  type: StudentDashBoardFor;
  previewType?: StudentPreviewFor;
}

const StudentLabelNames = [
  {
    name: "Name",
    admno: "Admission No.",
  },
];
const StudentDashboard = ({ previewType }: Props) => {
  const { token } = useToken();
  // eslint-disable-next-line
  const { dispatch, state } = useContext(AppContext);

  const navigate = useNavigate();
  const { STD_USE_LIBRARY, STD_USE_ACADEMICS, STD_USE_CHAT, STD_USE_ACCOUNTS } =
    useActiveInstModules();
  const [parentStudentsModal, setParentStudentsModal] = useState(false);

  const [GetParentAssocData, { data: ParentStudentAssociationData }] =
    useLazyQuery<
      parentDashboardByParentIdData,
      parentStudentAssociationByParentIdVars
    >(ListParentStudentAssocByParentId, {
      variables: {
        token,
        parent_id: state.parentId,
      },
    });

  const studentData = ParentStudentAssociationData
    ? ParentStudentAssociationData.GetParentStudentAssociByParentId.map(
        (response) => response.student
      )
    : [];

  useEffect(() => {
    if (token && state.parentId) {
      GetParentAssocData();
    }
  }, [token, GetParentAssocData, state.parentId]);

  return (
    <>
      <Home />
      <Title>Student Dashboard</Title>
      <div className="student-dashboard">
        <div className="student-dashboard__frame">
          <div className="row g-0 student-dashboard__frame--grid-list">
            {STD_USE_ACCOUNTS ? (
              <div className="col-3 h-100">
                <div className="student-dashboard__frame--grids">
                  <FeeDetails />
                </div>
              </div>
            ) : null}
            {STD_USE_LIBRARY ? (
              <div className="col-3 h-100">
                <div className="student-dashboard__frame--grids">
                  <LibraryDetails />
                </div>
              </div>
            ) : null}
            {STD_USE_CHAT ? (
              <div className="col-3 h-100">
                <div className="student-dashboard__frame--grids">
                  <Announcements />
                </div>
              </div>
            ) : null}
            <div className="col-3 h-100">
              <div className="student-dashboard__frame--grids">
                <StudentDetails previewType={previewType!} />
              </div>
            </div>
          </div>
          <div className="row g-0 student-dashboard__frame--bottom-details">
            {STD_USE_ACADEMICS ? (
              <>
                <div className="col-3 h-100 ">
                  <div className="student-dashboard__frame--calendar">
                    <Calendar />
                  </div>
                </div>
                {/* <div className="col-3 h-100 ">
                  <div className="student-dashboard__frame--graph">
                    <Performance />
                  </div>
                </div> */}
              </>
            ) : null}
            {STD_USE_CHAT ? (
              <div className="col-6 h-100 ">
                <div className="student-dashboard__frame--activity">
                  <CampusActivity pageType={PageFor.DASHBOARD} />
                </div>
              </div>
            ) : null}
            <div className="col-3 h-100"></div>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={parentStudentsModal}
        style={ParentStudentDashboardStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1">Profile's</Title>
            <div className="parent-dashboard__multiple-student">
              {studentData?.map((value, index) => {
                return (
                  <div
                    className="row g-0 parent-dashboard__multiple-student--block"
                    onClick={() => {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: value.id,
                        },
                      });
                      navigate(`/${value.id}/parentdashboard/student`);
                      setParentStudentsModal(!parentStudentsModal);
                    }}
                    key={index}
                  >
                    <div className="col-3 parent-dashboard__multiple-student--image">
                      {/* <img src={va.image} alt="/" /> */}
                    </div>
                    <div className="col parent-dashboard__multiple-student--details">
                      {StudentLabelNames.map((label, index) => {
                        return (
                          <ul key={index}>
                            <li>
                              <Label variant="LabelBold">{label.name}</Label>
                              <Label>
                                {value.first_name +
                                  " " +
                                  value.middle_name +
                                  " " +
                                  value.last_name}
                              </Label>
                            </li>
                            <li>
                              <Label variant="LabelBold">{label.admno}</Label>
                              <Label>{value.std_adm_no}</Label>
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setParentStudentsModal(!parentStudentsModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentDashboard;
