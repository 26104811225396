import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../../../styles/DataGridTableStyles";

const useEnquiryTable = () => {
  const tableJson = {
    FollowUp: [
      {
        headerName: "Sl",
        cellClassName: "td-sl-no",
        field: "id",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
      {
        headerName: "Date of Enquiry",
        cellClassName: "td-date",
        field: "date_of_enquiry",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
      {
        headerName: "Name",
        cellClassName: "td-name td-onClick",
        field: "name",
        headerAlign: HEADER_TEXT_ALIGN,
        flex: 1,
        hideable: false,
      },
      {
        headerName: "Email ID",
        cellClassName: "td-email",
        field: "email",
        headerAlign: HEADER_TEXT_ALIGN,
      },
      {
        headerName: "Mobile No.",
        cellClassName: "td-mobile",
        field: "mobile",
        headerAlign: HEADER_TEXT_ALIGN,
      },
      {
        headerName: "Previous Studied Institution",
        cellClassName: "td-desc",
        field: "previous_studied_institution",
        headerAlign: HEADER_TEXT_ALIGN,
      },
      {
        headerName: "Status",
        cellClassName: "td-desc",
        field: "status",
        headerAlign: HEADER_TEXT_ALIGN,
      },
      {
        headerName: "Follow Up Date",
        cellClassName: "td-date",
        field: "follow_up_date",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
      {
        headerName: "Next Follow Up Date",
        cellClassName: "td-date",
        field: "next_follow_up_date",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
      {
        headerName: "Follow Up Count",
        cellClassName: "td-number",
        field: "follow_up_count",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
    ],
    EmployeeAssociateList: [
      {
        headerName: "Sl",
        cellClassName: "td-sl-no",
        field: "id",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
      {
        headerName: "Employee Id",
        cellClassName: "td-adm-no",
        field: "emp_id",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
      {
        headerName: "Name",
        cellClassName: "td-name",
        field: "name",
        headerAlign: HEADER_TEXT_ALIGN,
        flex: 1,
        hideable: false,
      },
      {
        headerName: "Designation",
        cellClassName: "td-desc",
        field: "designation",
        headerAlign: HEADER_TEXT_ALIGN,
      },
      {
        headerName: "No of Follow-Up Students",
        cellClassName: "td-number",
        field: "no_of_followup",
        headerAlign: HEADER_TEXT_ALIGN,
      },
      {
        headerName: "No of Conversion",
        cellClassName: "td-number",
        field: "no_of_conversion",
        headerAlign: HEADER_TEXT_ALIGN,
        align: SLNO_TEXT_ALIGN,
      },
    ],
  };
  return tableJson;
};

export default useEnquiryTable;
