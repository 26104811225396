import React, { useContext, useEffect, useState } from "react";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import {
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import useStudentTableJson from "../../../../json/useStudentTableJson";
import {
  ColumnVisibilityFor,
  Direction,
  SortBy,
} from "../../../../utils/Enum.types";
import { TableHeaderProps } from "../../../../utils/types";
import { useLazyQuery } from "@apollo/client";
import { GetAcctStdDemandForAgents } from "../../../../queries/students/list/newApi";
import {
  GetAcctStdDemandData,
  StudentDemandEdges,
  vars,
} from "../../hooks/useStudentsbyDemandAmount";
import { AppContext } from "../../../../context/context";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AcctStudentQueryType } from "../../common/QueryTypes";
import {
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import { responseType } from "../../../../utils/Form.types";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import Input from "../../../../stories/Input/Input";
import { TextField } from "@mui/material";
import { Keys } from "../../../../utils/Enum.keys";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import useDropdownData from "../../../../customhooks/useDropdownData";

const AgentStudentList = () => {
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_AGENTS_ASSOCIATION,
  });

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();

  const [entryId, setEntryId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState("");
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);

  const { categoryDropDown } = useInstMasterDataByInstId();

  const [searchStudent, setSearchStudent] = useState("");

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const [GetAccountStudents, { data, loading, fetchMore }] = useLazyQuery<
    GetAcctStdDemandData,
    vars
  >(GetAcctStdDemandForAgents, {
    variables: {
      after: null,
      balance: null,
      direction: Direction.ASC,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: ROWS_PER_PAGE,
      input: {
        ids: [
          Number(InstId),
          entryId,
          categorySelected ? categorySelected.value : 0,
          state.agentId,
        ],
        std_demand_query_type: AcctStudentQueryType.BY_AGENT_ID,
      },
      name: searchStudent,
      status: "",
      stdFresher: false,
      token,
      sortBy: SortBy.STUDENT_ID,
    },
  });

  useEffect(() => {
    if (sectionSelected && USE_SECTION_KEY) {
      setEntryId(sectionSelected.value);
    } else if (semesterSelected && USE_SEMESTER_KEY) {
      setEntryId(semesterSelected.value);
    } else if (classSelected && USE_CLASS_KEY) {
      setEntryId(classSelected.value);
    } else if (branchSelected && USE_BRANCH_KEY) {
      setEntryId(branchSelected.value);
    } else if (departmentSelected && USE_DEPARTMENT_KEY) {
      setEntryId(departmentSelected.value);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionSelected,
    USE_SECTION_KEY,
    semesterSelected,
    USE_SEMESTER_KEY,
    classSelected,
    USE_CLASS_KEY,
    branchSelected,
    USE_BRANCH_KEY,
    departmentSelected,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_adm_no: true,
      std_reg_no: true,
      std_name: true,
      std_father_name: true,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: true,
      std_mobile: true,
      dept: true,
      branch: true,
      class: true,
      sem: true,
      sec: true,
      category: true,
    });

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable:header.hideable
    })
  );

  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctStdDemand?.edges;
                const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcctStdDemand: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctStdDemand.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  useEffect(() => {
    if (token && entryId && state.agentId && state.ActiveFinYr) {
      GetAccountStudents();
    }
  }, [
    token,
    GetAccountStudents,
    InstId,
    entryId,
    state.agentId,
    searchStudent,
    state.ActiveFinYr,
    categorySelected,
  ]);
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.mst_student.std_adm_no,
            std_reg_no: node.mst_student.std_reg_no,
            std_name: `${node.mst_student.first_name} ${node.mst_student.middle_name} ${node.mst_student.last_name}`,
            std_father_name: node.mst_student.std_father_name,
            std_mother_name: node.mst_student.std_mother_name,
            std_sex: node.mst_student.std_sex,
            std_doa: node.mst_student.std_doa,
            std_dob: node.mst_student.std_dob,
            std_email: node.mst_student.std_email,
            std_mobile: node.mst_student.std_mobile,
            dept: node.mst_student.dept.dept_desc,
            branch: node.mst_student.branch.branch_desc,
            class: node.mst_student.class.class_desc,
            sem: node.mst_student.semester.sem_desc,
            sec: node.mst_student.section.section_desc,
            category: node.mst_student.category.cat_desc,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.mst_student.std_adm_no,
            std_reg_no: node.mst_student.std_reg_no,
            std_name: `${node.mst_student.first_name} ${node.mst_student.middle_name} ${node.mst_student.last_name}`,
            std_father_name: node.mst_student.std_father_name,
            std_mother_name: node.mst_student.std_mother_name,
            std_sex: node.mst_student.std_sex,
            std_doa: node.mst_student.std_doa,
            std_dob: node.mst_student.std_dob,
            std_email: node.mst_student.std_email,
            std_mobile: node.mst_student.std_mobile,
            dept: node.mst_student.dept.dept_desc,
            branch: node.mst_student.branch.branch_desc,
            class: node.mst_student.class.class_desc,
            sem: node.mst_student.semester.sem_desc,
            sec: node.mst_student.section.section_desc,
            category: node.mst_student.category.cat_desc,
          }))
        );
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      <div className="row g-0 agent-std-assoc__filters">
        <div className="col-1">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchStudent(e.target.value)
            }
          />
        </div>

        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(null);
                }
                setBranchSelected(null);
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setBranchSelected(null);
                }
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                  setHasNextPage(true);
                } else {
                  setClassSelected(null);
                }
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={semesterDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue as responseType);
                  setHasNextPage(true)
                } else {
                  setSemesterSelected(null);
                }
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={semesterLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  fullWidth
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SECTION_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={sectionDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, sectionSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(null);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue as responseType);
                  setHasNextPage(true)
                } else {
                  setSectionSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CATEGORY_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={categoryDropDown}
              openOnFocus
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, categorySelected)
              }
              value={categorySelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setCategorySelected(newValue as responseType);
                  setHasNextPage(true)
                } else {
                  setCategorySelected(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setCategorySelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={categoryLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
      </div>
      <div className={`agent-std-assoc__tableblock`}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          disableRowSelectionOnClick
          hideFooter
        />
      </div>
    </>
  );
};

export default AgentStudentList;
