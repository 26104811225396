import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toggleFullSceen,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { useParams } from "react-router";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import Settings from "../../../../images/Settings.svg";
import Close from "../../../../images/Close.svg";
import MessageListModal from "../../../Channel/MessageList";
import Input from "../../../../stories/Input/Input";
import Modal from "react-modal";
import {
  PageNumbers,
  StudentListFields,
  PageFor,
  StudentReportType,
  StudentListFor,
  Operation,
  PageLabel,
  ColumnVisibilityFor,
} from "../../../../utils/Enum.types";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import ConfigurationSettings from "../../configurations/general/Index";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import Home from "../../Home/Index";
import {
  ConfigurationsModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import Enlarge from "../../../../images/Enlarge.svg";
import { Keys } from "../../../../utils/Enum.keys";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import MessageModal from "../../../../pages/MessageModal";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";

import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../../utils/types";
import useStudentTableJson from "../../../../json/useStudentTableJson";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import StatusAutocomplete from "../../../../components/common/Autocompletes/StatusAutocomplete";

import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

interface Props {
  pageType: PageFor;
  studentListFor: StudentListFor;
  queryType: StudentReportType;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  label?: PageLabel;
  isSibling?: boolean;
}
export interface columnsType {
  label: string;
  value: StudentListFields;
  visible: boolean;
}

const SiblingAssociationList = ({
  pageType,
  studentListFor,
  queryType,
  setStudentModal,
  studentModal,
  label,
  isSibling,
}: Props) => {
  const { dispatch } = useContext(AppContext);
  const { InstId } = useParams();
  const navigate = useNavigate();

  const [previewModal, setPreviewModal] = useState(false);
  const [showAllStudent, setShowAllStudent] = useState(false);
  const [operation, setoperation] = useState(Operation.CREATE);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );

  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_ADMISSIONS,
  });
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel,
  } = useInstLabels();

  const [configurationModal, setConfigurationModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.STUDENT_LIST_PAGE
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    operation === Operation.UPDATE
      ? StudentReportType.SIBLINGS
      : StudentReportType.ACD_STD_GENERAL,
    statusSelected ? statusSelected.value : EMPTY_STRING,
    []
  );

  const { data, fetchMore, loading } = AcademicsStudentData;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { categoryDropDown } = useInstMasterDataByInstId();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
    setStatusSelected(null);
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable:header.hideable
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "has_sibling",
      headerName: "Has Sibling",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${
              params.row.has_sibling === "Yes" ? "std_status--nxt" : ""
            } `}>
            {params.row.has_sibling}
          </div>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: false,
      std_mobile: false,
      is_login_created: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
    });
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });

        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_roll_no: node.std_roll_no ? node.std_roll_no : EMPTY_STRING,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_doa),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
            std_type: node.std_fresher ? "Fresher" : "Old Student",
            has_sibling: node.has_sibling ? "Yes" : "No",
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name:
              node.first_name + " " + node.middle_name + " " + node.last_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_roll_no: node.std_roll_no ? node.std_roll_no : EMPTY_STRING,

            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa:
              toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_doa),
            std_dob:
              toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
                ? EMPTY_STRING
                : toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
            std_type: node.std_fresher ? "Fresher" : "Old Student",
            has_sibling: node.has_sibling ? "Yes" : "No",
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [loading, data]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents
                  ? prevResult.GetAcdStudents.edges.filter(
                      ({ node: { id } }) =>
                        newEdges.findIndex(
                          ({ node: { id: newId } }) => newId === id
                        ) !== -1
                    )
                  : [];

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        // eslint-disable-next-line
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_ADMISSIONS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_ADMISSIONS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <div
      className={
        pageType === PageFor.GENERAL ? "studentlist" : "studentlist--modal"
      }>
      {pageType === PageFor.GENERAL && label !== PageLabel.FALSE ? (
        <Home DashBoardRequired={false} />
      ) : null}

      {label !== PageLabel.FALSE && (
        <div className="row g-0">
          <div className="col">
            <Title>
              {operation === Operation.UPDATE
                ? "Update Sibling Association"
                : "Student List for Sibling Association"}
            </Title>
          </div>
          <div className="configuration-settings">
            {pageType === PageFor.MODAL ? (
              <>
                <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
              </>
            ) : (
              <>
                {USE_CONFIG_KEY && (
                  <img
                    src={Settings}
                    alt="/"
                    id="settings-icon"
                    onClick={() => setConfigurationModal(!configurationModal)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
      <form>
        <div
          className={
            pageType === PageFor.GENERAL
              ? "row g-0 studentlist__options"
              : "row g-0 studentlist__options--modal"
          }>
          <div className="col-1 studentlist__select-flex studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(
                    option as responseType,
                    departmentSelected
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue as responseType);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && branchSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }

                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    fullWidth
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, sectionSelected)
                }
                className={labelClasses.inputRoot}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setSectionSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue as responseType);
                    setHasNextPage(true)
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={labelClasses.formControlRoot}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <StatusAutocomplete
              label="Status"
              onChange={(newValue) => {
                setStatusSelected(newValue as optionsType);
              }}
              statusValue={EMPTY_STRING}
            />
          </div>
          <div className="col"></div>
          {pageType === PageFor.MODAL ? null : (
            <div className="col-2 buttons-flex">
              <>
                <Button
                  mode="clear"
                  onClick={(e: React.FormEvent) => {
                    e.preventDefault();
                    clear();
                  }}
                  className="student-clear-button"
                />
              </>
            </div>
          )}
        </div>

        <div className="row g-0">
          <div className="col-5 studentlist__filters">
            {pageType === PageFor.MODAL && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAllStudent}
                      onClick={() => setShowAllStudent(!showAllStudent)}
                    />
                  }
                  label="All Students"
                />
              </FormGroup>
            )}
          </div>

          <div className="col"></div>
        </div>
      </form>

      <div
        id={
          pageType === PageFor.MODAL
            ? "studentlist__modal--frame"
            : pageType === PageFor.GENERAL && label === "FALSE"
            ? "studentlist__frame--report"
            : "studentlist__frame"
        }>
        <div className={`h100`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            onCellClick={(params) => {
              if (params.field === "std_name") {
                if (
                  pageType === PageFor.GENERAL &&
                  studentListFor === StudentListFor.GENERAL
                ) {
                  navigate(
                    `/${InstId}/admissions/students/${params.row.std_id}/association`
                  );
                }
                if (pageType === PageFor.MODAL) {
                  isSibling
                    ? dispatch({
                        type: payloadTypes.SET_SIBLING_ID,
                        payload: { siblingId: params.row.std_id },
                      })
                    : dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: { studentId: params.row.std_id },
                      });

                  if (studentListFor === StudentListFor.DASHBOARD) {
                    // setPreviewModal?.(!previewModal);
                  } else {
                    setStudentModal(!studentModal);
                  }
                }
              }
            }}
            disableRowSelectionOnClick
            hideFooter
          />
        </div>
      </div>
      <div className="studentlist__buttons">
        <div
          className={
            !(pageType === PageFor.GENERAL && label === "FALSE")
              ? "button-left"
              : ""
          }>
          <Button
            mode="update"
            onClick={() => {
              setoperation(Operation.UPDATE);
            }}
          />

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>

        <StudentTotalCount
          totalCount={data ? data.GetAcdStudents.totalCount! : 0}
        />
      </div>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.STUDENT_LIST_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* MessageModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      {/* preview-modal */}
      <Modal
        ariaHideApp={false}
        style={StudentModalStyles}
        isOpen={previewModal}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">student Preview</div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPreviewModal?.(!previewModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SiblingAssociationList;
