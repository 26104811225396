import React, { useContext, useEffect } from "react";
import Input from "../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Compare from "../../../images/Compare.svg";
import { Button } from "../../../stories/Button/Button";
import Avatar from "../../../images/Avatar.svg";
import {
  Direction,
  Operation,
  SortBy,
  UserType,
} from "../../../utils/Enum.types";
import { Title } from "../../../stories/Title/Title";
import useLogsTableJson from "../json/useLogsTableJson";
import { TableHeaderProps } from "../../../Types/Tables";

import { VoucherQueryTypes } from "../../Accounts/common/Enum.types";
import { EMPTY_STRING } from "../../../utils/constants";
import {
  toInputStandardDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Titles } from "../ModuleWiseDetails/Accounts";

import useAcctVoucherDetailsByDates, {
  GetAcctVoucherMasterByDatesData,
  GetAcctVoucherMasterByDatesVars,
} from "../../Accounts/hooks/useAcctVoucherDetailsByDates";
import { useLazyQuery } from "@apollo/client";
import { GetAcctVouchers } from "../../Accounts/queries/Vouchers/query";
import { AppContext } from "../../../context/context";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
interface Props {
  operation: Operation;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userid: number;
  endDate: string;
  startDate: string;
  empName: string;
  titleName: string;
}
const VoucherDetails = ({
  operation,
  setModal,
  userid,
  endDate,
  startDate,
  empName,
  titleName,
}: Props) => {
  const { Logs_Table } = useLogsTableJson();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const { token } = useToken();

  let queryType;
  switch (titleName) {
    case Titles.Contra:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_CONTRA_USER_LOG
          : VoucherQueryTypes.EDIT_CONTRA_USER_LOG;
      break;

    case Titles.Payments_Voucher:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_PAYMENT_USER_LOG
          : VoucherQueryTypes.EDIT_PAYMENT_USER_LOG;
      break;
    case Titles.Advance_Receipts:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_RECEIPT_USER_LOG
          : VoucherQueryTypes.EDIT_RECEIPT_USER_LOG;
      break;
    case Titles.Journal:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_JOURNAL_USER_LOG
          : VoucherQueryTypes.EDIT_JOURNAL_USER_LOG;
      break;
    default:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_CONTRA_USER_LOG
          : VoucherQueryTypes.EDIT_CONTRA_USER_LOG;
      break;
  }

  const [GetLedgerDetailsByDate, { data, loading, fetchMore, error }] =
    useLazyQuery<
      GetAcctVoucherMasterByDatesData,
      GetAcctVoucherMasterByDatesVars
    >(GetAcctVouchers, {
      variables: {
        after: null,
        direction: Direction.ASC,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        first: 200,
        sortBy: SortBy.V_DATE,
        token,
        v_no: EMPTY_STRING,
        deposit: null,
        vTranscationCashOrBank: null,
        partyName: EMPTY_STRING,
        vTranscationNo: EMPTY_STRING,
        vTranscationNarration: EMPTY_STRING,
        ldgrDesc: EMPTY_STRING,
        amount: null,
        input: {
          inst_id: InstId!,
          voucher_query_type: queryType,
          vo_end_date: dayjs(endDate).format(),
          acct_ldgr_id: state.accountLedgerId,
          vo_start_date: dayjs(startDate).format(),
          vo_type: EMPTY_STRING,
          student_id: 0,
          user_details: {
            user_type: UserType.EMPLOYEE,
            id: state.employeeId,
          },
        },
      },
    });

  useEffect(() => {
    if (token && state.ActiveFinYr) GetLedgerDetailsByDate();
  }, [token, state.ActiveFinYr, GetLedgerDetailsByDate, startDate, endDate]);
  return (
    <>
      <Title>
        {operation === Operation.UPDATE
          ? `Edited ${titleName} Details`
          : operation === Operation.DELETE
          ? `Deleted ${titleName} Details`
          : operation === Operation.CREATE
          ? `Added ${titleName} Details`
          : `Added ${titleName} Details`}
      </Title>
      <div className="emp-logs__filtered-data">
        <div className="emp-logs__filtered-data--select row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>

          <div className="col-4">
            <div className="emp-logs__filtered-data--select--flex">
              <TextField
                value={toInputStandardDate(startDate)}
                label="Start Date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className="emp-logs__filtered-data--textfield"
              />
              <img src={Compare} alt="" />
              <TextField
                value={endDate}
                label="End Date"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className="emp-logs__filtered-data--textfield"
              />
            </div>
          </div>
          <div className="col"></div>
          <div className="col-3">
            <div className="emp-logs__filtered-data--profile">
              <img src={Avatar} alt="" />
              <div className="emp-logs__filtered-data--profile--details">
                <span>{empName}</span>
                {/* <b>Manager</b> */}
              </div>
            </div>
          </div>
        </div>
        <div className="emp-logs__filtered-data--tableblock">
          <TableContainer className="emp-logs__filtered-data--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Logs_Table.VoucherDetails.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ? data.GetAcctVouchers.edges.map((res, index) => {
                      const utcTimestamp = new Date(res.node.created_at);
                      const utcTimestampUpdated = new Date(res.node.updated_at);
                      return (
                        <TableRow>
                          <TableCell
                            className="emp-logs__filtered-data--table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell
                            className="emp-logs__filtered-data--table--date"
                            id="td-center"
                          >
                            {toStandardDate(res.node.v_date)}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--number">
                            {res.node.v_no}
                          </TableCell>
                          <TableCell
                            className="emp-logs__filtered-data--table--date"
                            id="td-center"
                          >
                            {res.node.db_ldgr_details.ldgr_desc}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--number">
                            {res.node.party_name}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--number">
                            {res.node.v_transcation_narration}
                          </TableCell>
                          <TableCell className="emp-logs__filtered-data--table--number">
                            {res.node.v_std_amt_total}
                          </TableCell>
                          <TableCell>
                            {operation === Operation.CREATE
                              ? utcTimestamp.toLocaleString()
                              : utcTimestampUpdated.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default VoucherDetails;
