import { useLazyQuery, useMutation } from "@apollo/client";
import { TextField } from "@mui/material";
import {
  GridColDef,
  GridAlignment,
  GridCellParams,
} from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../../customhooks/useToken";
import DeleteIcon from "../../../../../images/Delete.svg";
import DeleteModal from "../../../../../pages/DeleteModal";
import LoadingModal from "../../../../../pages/LoadingModal";
import MessageModal from "../../../../../pages/MessageModal";
import {
  StudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherIdForPassedOut,
} from "../../../../../queries/common";
import Input from "../../../../../stories/Input/Input";
import { Title } from "../../../../../stories/Title/Title";
import { nodevars, VouchernodeData } from "../../../../../Types/Accounting";
import { TableHeaderProps } from "../../../../../utils/types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import { Direction, Operation, SortBy } from "../../../../../utils/Enum.types";
import { msgType } from "../../../../../utils/Form.types";
import {
  DateRange,
  formatter,
  getModifiedScrollHeight,
  toInputStandardDate,
} from "../../../../../utils/UtilFunctions";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../../hooks/useAcctVoucherDetailsByDates";

import { DeleteAcctVoucherMasterAndDetails } from "../../../queries/receipts/mutation/Index";
import { GetAcctVouchers } from "../../../queries/Vouchers/query";
import useAcctTableJson from "../../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../../context/context";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import { Button } from "../../../../../stories/Button/Button";
interface Props {
  v_type: VoucherQueryTypes;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Delete = ({ v_type, setModalFlag }: Props) => {
  console.log(v_type, "v_type");

  const { InstId } = useParams();
  const { token } = useToken();
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const DefaultDate = new Date();
  const { firstDay, lastDay } = DateRange(DefaultDate.toString()) || {};
  const [searchData, setSearchData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [voucherId, setVoucherId] = useState(0);
  const { state } = useContext(AppContext);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);

  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );
  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { user_details } = useLoggedInUserDetails();

  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE,
    endDate!,
    startDate!,
    v_type,
    searchData
  );

  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );
  const [GetStudentDetailsPassedOut] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherIdForPassedOut
  );
  const [DeleteVoucher, { loading }] = useMutation(
    DeleteAcctVoucherMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const FetchStudentDetails = (node: voucherMasterDetails) => {
    const student = studentDetails
      ? studentDetails.find((id) => id.id === node.id)
      : null;
    if (student)
      return v_type === VoucherQueryTypes.PASSOUT_STD_VOUCHERS
        ? student.passout_std_details.std_name +
            " " +
            `(${student.passout_std_details.branch_details.branch_desc} / ${student.passout_std_details.class_details.class_desc} )`
        : student.mst_student.first_name +
            " " +
            student.mst_student.middle_name +
            " " +
            student.mst_student.last_name +
            " " +
            `(${node.branch_details.branch_desc} / ${node.class_details.class_desc} / ${student.acct_voucher_details[0].acct_ldgr.ldgr_desc} / ${student.mst_student.std_adm_no})`;
    else return EMPTY_STRING;
  };
  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteVoucher({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            v_no: searchData,
            deposit: null,
            vTranscationCashOrBank: null,
            partyName: EMPTY_STRING,
            vTranscationNo: EMPTY_STRING,
            vTranscationNarration: EMPTY_STRING,
            ldgrDesc: EMPTY_STRING,
            amount: null,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS,
              vo_end_date: dayjs(endDate).format(),
              acct_ldgr_id: 0,
              student_id: 0,
              vo_start_date: dayjs(startDate).format(),
              vo_type: EMPTY_STRING,
              user_details,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.CREATE,
        });
        // setDeleteModal(!deleteModal);
        // setModal(!modal);
      }
    });
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (
      VoucherDetails.data &&
      v_type !== VoucherQueryTypes.PASSOUT_STD_VOUCHERS
    ) {
      GetStudentDetails({
        variables: {
          token,
          ids: vouchers?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, vouchers.length, token]);

  useEffect(() => {
    if (
      VoucherDetails.data &&
      v_type === VoucherQueryTypes.PASSOUT_STD_VOUCHERS
    ) {
      GetStudentDetailsPassedOut({
        variables: {
          token,
          ids: vouchers?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        console.log(data, "data");

        setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetailsPassedOut, vouchers?.length, token]);
  useEffect(() => {
    if (VoucherDetails.data && !VoucherDetails.loading && token) {
      const newData = VoucherDetails.data.GetAcctVouchers.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = vouchers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newVoucher;
        });
        setVouchers(updatedNewData);
      } else {
        setVouchers(newData);
      }
      setEndCursor(VoucherDetails.data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [VoucherDetails.data, VoucherDetails.loading, token]);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.DeleteDemandFeeReceipt.Table_Headers.map((header) => ({
      headerName: header.headerName,
      cellClassName: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
      renderCell: (params: GridCellParams) => {
        if (header.field === "action") {
          return (
            <>
              <img
                src={DeleteIcon}
                onClick={() => {
                  setVoucherId(params.row.nodeId);
                  setDeleteModal(!deleteModal);
                }}
                alt="/"
              />
            </>
          );
        }
        return params.value;
      },
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            VoucherDetails.fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVouchers?.edges;
                const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcctVouchers: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount:
                      VoucherDetails.data?.GetAcctVouchers.totalCount!,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && vouchers && vouchers?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [vouchers]
  );

  const tableData = vouchers.map((response, index) => ({
    id: index + 1,
    receipt_no: response.node.v_no,
    student_name: FetchStudentDetails(response.node),
    amount: format(response.node.v_std_amt_total),
    nodeId: response.node.id,
    node: response.node,
  }));

  return (
    <>
      <Title>
        {v_type === VoucherQueryTypes.REFUND_VOUCHERS
          ? "Delete Refund Receipts"
          : v_type === VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS
          ? "Delete Demand Receipts"
          : v_type === VoucherQueryTypes.PASSOUT_STD_VOUCHERS
          ? "Delete Non Demand Receipt (Alumini)"
          : v_type === VoucherQueryTypes.STD_NON_DEMAND_RECEIPTS
          ? "Delete Non Demand Receipts (Existing)"
          : "Delete Non Demand Receipt"}
      </Title>
      <div className="delete-demand-fee-receipt">
        <div className="row g-0 delete-demand-fee-receipt__details">
          <div className="col-3">
            <Input
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search Receipt no..."
              id="search"
              autoFocus
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <TextField
              type="date"
              label="Start Date."
              value={toInputStandardDate(startDate!)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              onChange={(e) => setStartDate(e.target.value)}
              className="delete-demand-fee-receipt__details--textfield"
            />
          </div>
          <div className="col-2">
            <TextField
              type="date"
              label="End Date."
              value={toInputStandardDate(endDate!)}
              onChange={(e) => setEndDate(e.target.value)}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              className="delete-demand-fee-receipt__details--textfield"
            />
          </div>
        </div>
        <div className="delete-demand-fee-receipt__tableblock">
          {!vouchers?.length && !VoucherDetails.loading ? (
            <b className="nodata">Sorry No Results</b>
          ) : (
            <StyledDatagrid
              columns={columns}
              rows={tableData}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
            />
          )}
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        </div>
        <div className="col flex-end">
          <div className="student-total-count">
            Total Receipts : <b>{vouchers.length}</b>
          </div>
        </div>
      </div>
      <LoadingModal flag={loading} />

      <DeleteModal
        modalFlag={deleteModal!}
        setModalFlag={setDeleteModal!}
        handleDelete={HandleDelete}
        id={voucherId}
      />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Delete;
