import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Home from "../Home/Index";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import Attendance from "./Attendance/Index";
import SessionAssignments from "./SessionAssignments/ViewDetails";

import SessionAttendance from "./Attendance/SessionAttendance";

import Assignments from "./Assignments/Index";
import Exam from "./Exam/Index";
import Channels from "./Channels/Index";
import SubjectDetails from "./SubjectDetails/Index";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { GetAcdInstDetailsByNodeId } from "../queries/instdetails/section/byId";
import { EMPTY_STRING } from "../../../utils/constants";
import { useParams } from "react-router-dom";
import { TabPanel, a11yProps } from "../../../styles/Tabs";
import {
  AttendanceOverViewPageFor,
  InstitutionConfigurationTypes,
} from "../../../utils/Enum.types";
import InstDetails from "./InstDetails";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useAcdSubjectDetails from "../hooks/useAcdSubjectDetails";

import LessonStatus from "./LessonStatus/Index";
import StudentList from "./StudentList/Index";
import { ChannelQueryType } from "../../Accounts/common/Enum.types";
interface AcdSectionNode {
  section_desc: string;
  class_desc: string;
  sem_desc: string;
}
interface GetAcdInstDetailsByNodeIdData {
  node: AcdSectionNode;
}
interface GetAcdInstDetailsByNodeIdVars {
  token: string;
  id: string;
}

const Index = () => {
  const theme = useTheme();
  const { token } = useToken();
  const { entryId, subjectId } = useParams();
  const [value, setValue] = useState(0);
  const [subject_id, setSubject_Id] = useState(0);
  const { configData: AssignmentFlag } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_ASSIGNMENTS_CHANNELS
  );
  const { configData: ChannelFlag } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_CLASS_LEVEL_CHANNELS
  );
  const { USE_CLASS_KEY, USE_SECTION_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();

  const { data: subjectData } = useAcdSubjectDetails(subject_id);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const { configData: LessonPlannerData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_LESSON_PLANNER
  );
  const enableLessonPlanner =
    LessonPlannerData.data &&
    LessonPlannerData.data.GetSwConfigVariables.length &&
    LessonPlannerData.data.GetSwConfigVariables[0].config_boolean_value;
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const [GetInstMasterDetails, { data }] = useLazyQuery<
    GetAcdInstDetailsByNodeIdData,
    GetAcdInstDetailsByNodeIdVars
  >(GetAcdInstDetailsByNodeId, {
    variables: {
      token,
      id: entryId!,
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => setValue(index);
  useEffect(() => {
    if (token) {
      GetInstMasterDetails();
    }
  }, [token, GetInstMasterDetails]);
  useEffect(() => {
    if (subjectId) {
      setSubject_Id(Number(subjectId));
    }
  }, [subjectId]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        Daily Activities:&nbsp;
        {USE_SECTION_KEY
          ? data?.node.section_desc
          : USE_SEMESTER_KEY
          ? data?.node.sem_desc
          : USE_CLASS_KEY
          ? data?.node.class_desc
          : EMPTY_STRING}
        &nbsp;
        {enablePerSessionAtt && subjectData && subjectData.node
          ? `(${subjectData.node.subj_desc} (${subjectData.node.subj_code}))`
          : EMPTY_STRING}
      </Title>
      <div className="daily-activities">
        <div className="row g-0 daily-activities__select-block">
          <div className="col">
            <InstDetails />
            <div className="daily-activities__tabs">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Attendance" value={0} {...a11yProps(0)} />
                {AssignmentFlag.data &&
                AssignmentFlag.data.GetSwConfigVariables[0]
                  .config_boolean_value ? (
                  <Tab label="Assignments" value={1} {...a11yProps(1)} />
                ) : null}
                <Tab label="Test/Exam" value={2} {...a11yProps(2)} />
                {ChannelFlag.data &&
                ChannelFlag.data.GetSwConfigVariables[0]
                  .config_boolean_value ? (
                  <Tab label="Channels" value={3} {...a11yProps(3)} />
                ) : null}
                <Tab label="Subject Details" value={4} {...a11yProps(4)} />
                {/* <Tab label="Student List" value={5} {...a11yProps(5)} /> */}

                {enableLessonPlanner && (
                  <Tab label="Lesson Status" value={6} {...a11yProps(6)} />
                )}
              </Tabs>
            </div>
          </div>
          {/* {enablePerSessionAtt && (
            <div className="col-2">
              <Autocomplete
                className={labelClasses.inputRoot}
                options={
                  SubjectData
                    ? SubjectData.GetAcdSubjectForEntryLevel.map(
                        ({
                          subject_details: { subj_code, id, subj_desc },
                        }) => ({
                          label: `${subj_desc} (${subj_code})`,
                          value: id,
                        })
                      )
                    : []
                }
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                    label="Select Subject"
                           slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                  />
                )}
              />
            </div>
          )} */}
          {/* <div className="daily-activities__select-block--teacher-details">
              <div className="daily-activities__select-block--teacher-details--image">
                <img src={Avatar} alt="" />
              </div>
              <div className="daily-activities__select-block--teacher-details--name">
                <b>Shravan</b>
                <span>(EMP001)</span>
              </div>
            </div> */}
        </div>

        <div className="row g-0 daily-activities__swipable">
          <div className="col h-100">
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              className="h-100"
            >
              {/* values for Attendance */}
              <TabPanel value={value} index={0} dir={theme.direction}>
                {enablePerSessionAtt ? (
                  <SessionAttendance
                    pageType={AttendanceOverViewPageFor.DAILY_ACTIVITIES}
                    selectedSubject={subject_id}
                  />
                ) : (
                  <Attendance
                    pageType={AttendanceOverViewPageFor.DAILY_ACTIVITIES}
                  />
                )}
              </TabPanel>
              {/* values for Assignments */}
              <TabPanel value={value} index={1} dir={theme.direction}>
                {enablePerSessionAtt ? <SessionAssignments /> : <Assignments />}
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Exam />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <Channels channel={ChannelQueryType.ENTRYLEVEL} />
              </TabPanel>
              <TabPanel value={value} index={4} dir={theme.direction}>
                <SubjectDetails />
              </TabPanel>
              <TabPanel value={value} index={5} dir={theme.direction}>
                <StudentList />{" "}
              </TabPanel>
              <TabPanel value={value} index={6} dir={theme.direction}>
                <LessonStatus />
              </TabPanel>

              {/* <TabPanel value={value} index={6} dir={theme.direction}>
                <Announcements />
              </TabPanel> */}
            </SwipeableViews>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
