import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../../../Types/Student";
import {
  AcdSubjectAllocationData,
  Direction,
  InstitutionConfigurationTypes,
  SortBy,
  StudentReportType,
} from "../../../utils/Enum.types";
import { StudentAcdType } from "../../../utils/studentAcdEnum.types";
import useToken from "../../../customhooks/useToken";
import useAcademicYear from "./useActiveAcademicYear";
import { GetAcdStudentsForAdmissions } from "../queries/students/Index";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { AppContext } from "../../../context/context";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

export interface MstStudentMiscData {
  id: number;
  lib_book_count: number;
  student_id: number;
  availed_transport: boolean;
  availed_hostel: boolean;
  mobile_app_count: number;
}
export interface StudentAcdNode {
  first_name: string;
  id: number;
  last_name: string;
  middle_name: string;
  std_adm_no: string;
  std_email: string;
  std_father_name: string;
  std_fresher: boolean;
  std_mobile: string;
  std_mother_name: string;
  std_reg_no: string;
  std_status: string;
  std_doa: string;
  std_dob: string;
  url: string;
  std_joining_acd_yr: string;
  std_profile_filename: string;
  is_login_created: boolean;
  has_sibling: boolean;
  category: CategoryList;
  acd_dept: DepartmentList;
  acd_branch: BranchesList;
  acd_class: ClassList;
  acd_semester: SemesterList;
  acd_section: SectionList;
  fin_yr: {
    fin_yr: string;
    fin_st_date: string;
    fin_end_date: string;
    fin_is_curr_yr: boolean;
  };
  // acd_indivual_marks: GetAcdStdMarksByIdDetails[];
  isChecked: boolean;
  std_details: {
    std_phone: string;
    std_mother_tongue: string;
    std_religion: string;
    std_address: string;
    std_district: string;
    std_state: string;
    std_place: string;
    std_caste: string;
    std_caste_group: string;
  };
  ext_marks?: number;
  int_marks?: number;
  scored_grade?: string;
  teacher_comments?: string;
  is_present?: boolean;
  att_status?: string;
  comments?: string;
  approved_by?: string;
  std_roll_no: string;
  std_sex?: string;
  std_remarks?: string;
  inc_no?: string;
  visited: boolean;
  misc_data: {
    is_transaction_started: boolean;
    is_tc_issued: boolean;
    lib_book_count: number;
    mobile_app_count: number;
    availed_transport: boolean;
    availed_hostel: boolean;
    is_route_assgined: boolean;
    per_std_subj_allocation: boolean;
  };
}
export interface StudentPageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}
export interface StudentEdges {
  node: StudentAcdNode;
}
export interface StudentListEdges {
  first_name: string;
  id: number;
  last_name: string;
  middle_name: string;
  std_adm_no: string;
  std_email: string;
  std_father_name: string;
  std_fresher: boolean;
  std_mobile: string;
  std_mother_name: string;
  std_reg_no: string;
  std_status: string;
  std_doa: string;
  isChecked: boolean;
  is_present?: boolean;
  ext_marks?: number;
  int_marks?: number;
  scored_grade?: string;
  teacher_comments?: string;
  att_status?: string;
  comments?: string;
  approved_by?: string;
}
export interface GetAcdStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
interface input {
  acd_std_query_type: string;
  ids: number[] | string[] | any;
  str_data: string[];
  int_data: number[];
}
export interface GetStudentAcdForAdmissionData {
  GetAcdStudents: GetAcdStudentsList;
}
export interface AcdStudentsForAdmissionvars {
  name: string;
  after: string | null;
  status: string;
  orderBy?: {
    direction: string;
    field: string;
  }[];
  first: number | null;
  token: string;
  acd_yr_id: number;
  input: input;
}

export interface AcdStudentsvarsForCount {
  after: string | null;
  orderBy?: {
    direction: string;
    field: string;
  }[];
  first: number | null;
  token: string;
  acd_yr_id: number;
  input: input;
}
const useAcdStudentsForAdmission = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  rowsPerPage: number | null,
  allotedId: number,
  name: string,
  queryType: StudentReportType | string,
  status: string,
  ageValues: number[],
  orderBy?: {
    field: string;
    direction: Direction;
  }[],
  channelId?: number | string,
  religion?: string,
  subjectId?: number,
  testConductId?: number,
  instIdForCampus?: number
) => {
  const { token } = useToken();
  const { InstId, allotedID } = useParams();
  const { state } = useContext(AppContext);

  const [entryId, setEntryId] = useState(0);

  const [ids, setIds] = useState<number[]>([]);

  const [str_data, setstr_data] = useState<string[]>([]);
  const [acd_std_query_type, setacd_std_query_type] = useState("");

  const { activeAcademicYearData } = useAcademicYear();
  const acdYearID =
    activeAcademicYearData.data &&
    activeAcademicYearData.data.GetAcdYrActiveByInstId.id;

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    USE_DEPARTMENT_KEY,
  } = useInstitutionConfiguration();

  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetStudentAcdForAdmissionData,
    AcdStudentsForAdmissionvars
  >(GetAcdStudentsForAdmissions, {
    variables: {
      after: null,
      acd_yr_id:
        state.instIdForCampus && acdYearID
          ? acdYearID
          : state.ActiveAcdYr
          ? state.ActiveAcdYr.id
          : 0,
      status,
      first: rowsPerPage,
      name,
      token,
      orderBy: orderBy
        ? orderBy
        : [
            {
              direction: Direction.ASC,
              field: SortBy.STD_ROLL_NO,
            },
          ],
      input: {
        ids,
        acd_std_query_type,
        str_data,
        int_data: ageValues ? ageValues : [],
      },
    },
  });

  useEffect(() => {
    if (sectionId && USE_SECTION_KEY) {
      setEntryId(sectionId);
    } else if (semesterId && USE_SEMESTER_KEY) {
      setEntryId(semesterId);
    } else if (classId && USE_CLASS_KEY) {
      setEntryId(classId);
    } else if (branchId && USE_BRANCH_KEY) {
      setEntryId(branchId);
    } else if (departmentId && USE_DEPARTMENT_KEY) {
      setEntryId(departmentId);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionId,
    semesterId,
    classId,
    branchId,
    departmentId,
    InstId,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
  ]);

  useEffect(() => {
    switch (queryType) {
      case StudentReportType.GENERAL:
        if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_SECTION_ID);
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.BY_ACD_DEPT_ID);
        } else {
          setIds(instIdForCampus ? [instIdForCampus] : [Number(InstId)]);
          setacd_std_query_type(StudentAcdType.BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.LOGIN_ACTIVE:
        if (sectionId) {
          setIds([sectionId]);
          setstr_data([AcdSubjectAllocationData.SECTION]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setstr_data([AcdSubjectAllocationData.SEMESTER]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (classId) {
          setIds([classId]);
          setstr_data([AcdSubjectAllocationData.CLASS]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (branchId) {
          setIds([branchId]);
          setstr_data([AcdSubjectAllocationData.BRANCH]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else if (departmentId) {
          setIds([departmentId]);
          setstr_data([AcdSubjectAllocationData.DEPT]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        } else {
          setIds([Number(InstId)]);
          setstr_data([AcdSubjectAllocationData.INST]);
          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_ACTIVE_AT_ENTRY_LEVEL
          );
        }
        break;
      case StudentReportType.LOGIN_INACTIVE:
        if (sectionId) {
          setIds([sectionId]);

          setstr_data([AcdSubjectAllocationData.SECTION]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setstr_data([AcdSubjectAllocationData.SEMESTER]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (classId) {
          setIds([classId]);
          setstr_data([AcdSubjectAllocationData.CLASS]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (branchId) {
          setIds([branchId]);
          setstr_data([AcdSubjectAllocationData.BRANCH]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else if (departmentId) {
          setIds([departmentId]);
          setstr_data([AcdSubjectAllocationData.DEPT]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        } else {
          setIds([Number(InstId)]);
          setstr_data([AcdSubjectAllocationData.INST]);

          setacd_std_query_type(
            StudentAcdType.LOGIN_STATUS_INACTIVE_AT_ENTRY_LEVEL
          );
        }
        break;
      case StudentReportType.PASSED_OUT:
        if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(
            StudentAcdType.ACD_PASSED_STDS_BY_ACD_BRANCH_ID
          );
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.ACD_PASSED_STDS_BY_ACD_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.ACD_PASSED_STDS_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.BY_ALLOTTED_LEVEL:
        if (allotedId && configData.data && !configData.loading) {
          setIds([allotedId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(StudentAcdType.BY_ALLOTTED_LEVEL);
        }
        break;
      case StudentReportType.BY_MARKS:
        if (
          allotedId &&
          configData.data &&
          !configData.loading &&
          testConductId! &&
          subjectId!
        ) {
          setIds([allotedId, testConductId, subjectId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(StudentAcdType.ACD_STD_MARKS_BY_SUBJECT);
        }
        break;

      case StudentReportType.LIB_BOOK_CLEARANCE:
        if (Number(InstId) || instIdForCampus) {
          setIds(instIdForCampus ? [instIdForCampus] : [Number(InstId)]);
          setacd_std_query_type(StudentAcdType.LIB_BOOK_CLEARANCE_BY_INST_ID);
        }
        break;

      case StudentReportType.TRANSPORT_NOT_ASSIGNED:
        if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_NOT_ASSIGNED_BY_SECTION_ID
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID
          );
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.ROUTE_NOT_ASSIGNED_BY_INST_ID);
        }
        break;
      case StudentReportType.TRANSPORT_ASSIGNED:
        if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_ASSIGNED_BY_ACD_SECTION_ID
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(
            StudentAcdType.ROUTE_ASSIGNED_BY_ACD_SEMESTER_ID
          );
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_DEPT_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.ROUTE_ASSIGNED_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.NXT_STUDENTS:
        if (semesterId && categoryId) {
          setIds([semesterId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_SEMESTER_ID_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setIds([classId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_CLASS_ID_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setIds([branchId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_BRANCH_ID_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setIds([departmentId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.NXT_STUDENTS_BY_ACD_DEPT_ID_AND_CATEGORY_ID
          );
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_DEPT_ID);
        } else if (categoryId) {
          setIds([categoryId]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_CATEGORY_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.NXT_STUDENTS_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.CURR_STUDENTS:
        if (sectionId && categoryId) {
          setIds([sectionId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_SECTION_AND_CATEGORY_ID
          );
        } else if (semesterId && categoryId) {
          setIds([semesterId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_SEMESTER_AND_CATEGORY_ID
          );
        } else if (classId && categoryId) {
          setIds([classId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_CLASS_AND_CATEGORY_ID
          );
        } else if (branchId && categoryId) {
          setIds([branchId, categoryId]);
          setacd_std_query_type(
            StudentAcdType.CUR_BY_ACD_BRANCH_AND_CATEGORY_ID
          );
        } else if (departmentId && categoryId) {
          setIds([departmentId, categoryId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_DEPT_AND_CATEGORY_ID);
        } else if (sectionId) {
          setIds([sectionId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_SECTION_ID);
        } else if (semesterId) {
          setIds([semesterId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_DEPT_ID);
        } else if (categoryId) {
          setIds([categoryId]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_CATEGORY_ID);
        } else {
          setIds([Number(InstId)]);
          setacd_std_query_type(StudentAcdType.CUR_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.BY_COMBO_ID_AT_ALLOTED_LEVEL:
        if (
          allotedId &&
          configData.data &&
          !configData.loading &&
          state.acdCombinationId
        ) {
          setIds([Number(InstId), allotedId, state.acdCombinationId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(StudentAcdType.BY_COMBO_ID_AT_ALLOTED_LEVEL);
        }
        break;
      case StudentReportType.COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL:
        if (allotedId && configData.data && !configData.loading) {
          setIds([Number(InstId), allotedId]);
          setstr_data([
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          ]);
          setacd_std_query_type(
            StudentAcdType.COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL
          );
        }
        break;

      case StudentReportType.STDS_WITHOUT_REGISTER_NUMBER:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.STDS_WITHOUT_REGISTER_NUMBER);
        break;

      case StudentReportType.STDS_WITHOUT_SECTION_ALLOCATED:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.STDS_WITHOUT_SECTION_ALLOCATED);
        break;
      case StudentReportType.STDS_NOT_ALLOCATED:
        setIds([Number(InstId), Number(allotedID)!, 0]);
        setacd_std_query_type(StudentReportType.STDS_WITHOUT_SECTION_ALLOCATED);
        break;
      case StudentReportType.STDS_STATUS_NEQ_CUR:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.STDS_STATUS_NEQ_CUR);
        break;
      case StudentReportType.STDS_BY_AGE:
        setIds([Number(InstId), entryId, categoryId]);

        setacd_std_query_type(StudentReportType.STDS_BY_AGE);
        break;

      case StudentReportType.MOBILE_APP_INSTALLED:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.MOBILE_APP_INSTALLED);
        break;
      case StudentReportType.MOBILE_APP_NOT_INSTALLED:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.MOBILE_APP_NOT_INSTALLED);
        break;
      case StudentReportType.STDS_BY_CHANNEL_ID:
        setIds([Number(InstId), Number(channelId)]);
        setacd_std_query_type(StudentReportType.STDS_BY_CHANNEL_ID);
        break;
      case StudentReportType.STDS_NEW_ADMISSION:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.STDS_NEW_ADMISSION);
        break;
      case StudentReportType.STDS_AVAILED_TRANSPORT:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.STDS_AVAILED_TRANSPORT);
        break;
      case StudentReportType.STDS_NOT_AVAILED_TRANSPORT:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.STDS_NOT_AVAILED_TRANSPORT);
        break;
      case StudentReportType.STDS_BY_RELIGION:
        setIds([Number(InstId), entryId, categoryId]);
        if (religion) setstr_data([religion]);
        setacd_std_query_type(StudentReportType.STDS_BY_RELIGION);
        break;
      case StudentReportType.STDS_BY_JOINING_YEAR:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.STDS_BY_JOINING_YEAR);
        break;
      case StudentReportType.ACD_STD_GENERAL:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.ACD_STD_GENERAL);
        break;
      case StudentReportType.SIBLINGS:
        setIds([Number(InstId), entryId, categoryId]);
        setacd_std_query_type(StudentReportType.SIBLINGS);
        break;
      default:
        break;
    }
  }, [
    sectionId,
    semesterId,
    classId,
    branchId,
    departmentId,
    categoryId,
    InstId,
    queryType,
    allotedId,
    configData.data,
    configData.loading,
    testConductId,
    subjectId,
    instIdForCampus,
    state.acdCombinationId,
    entryId,
  ]);

  useEffect(() => {
    if (
      !activeAcademicYearData.loading &&
      activeAcademicYearData.data &&
      state.ActiveAcdYr &&
      acd_std_query_type &&
      ids.length
    ) {
      GetStudentsData();
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    queryType,
    GetStudentsData,
    state.ActiveAcdYr,
    str_data,
    acd_std_query_type,
    ids.length,
    status,
    // ageValues,
  ]);
  return {
    AcademicsStudentData: { data, loading, error, fetchMore },
  };
};

export default useAcdStudentsForAdmission;
