/**
 * This code snippet is a React functional component called 'Receipt'. It handles the generation of a student receipt. The component includes a form for selecting a student, adding fee items, selecting the mode of transaction, entering remarks, and generating the receipt. It uses various hooks and components from the React and Material-UI libraries.
 *
 * Example Usage:
 * <Receipt />
 *
 * Inputs:
 * - Student admission number
 * - Fee ledger options
 * - Bank transaction types
 * - Receipt details (mode of transaction, remarks, transaction reference number)
 * - Fee items (fee ledger, fee amount)
 *
 * Flow:
 * 1. The component renders a form for generating a student receipt.
 * 2. The user selects a student by entering their admission number.
 * 3. The component fetches and displays the student's information.
 * 4. The user can add fee items by selecting a fee ledger and entering the fee amount.
 * 5. The user can edit or delete fee items.
 * 6. The component calculates the total fee amount.
 * 7. The user selects the mode of transaction (cash or bank) and enters the transaction details.
 * 8. The user enters remarks for the receipt.
 * 9. The user can save or clear the receipt.
 * 10. When the user saves the receipt, the component sends a mutation request to generate the receipt.
 * 11. The component displays a success message and the generated receipt details.
 *
 * Outputs:
 * - Generated student receipt details
 */
import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import {
  DebitOrCredit,
  PageNumbers,
  ReceiptTypes,
  PageFor,
  VoucherBookKey,
  StudentListFor,
  StudentAcctReportType,
  BankOrCash,
  PredefinedDataTypes,
  Operation,
  Direction,
  SortBy,
  ModuleName,
  InstitutionType,
} from "../../../utils/Enum.types";
import ConfigurationSettings from "../../Master/configurations/general/Index";

import Modal from "react-modal";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { AppContext } from "../../../context/context";

import FeeLedger from "../FeeLedger/Index";

import {
  DateRange,
  formatter,
  handleMUISelectEvent,
  isOptionEqualToValue,
  NameOfTheDay,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_RESPONSETYPE,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import { AddAcctVoucherMasterAndDetails } from "../queries/receipts/mutation/Index";

import { AdvancesTitleProps } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Avatar from "../../../images/Avatar.svg";
import Home from "../Home/Index";

import LoadingModal from "../../../pages/LoadingModal";
import Settings from "../../../images/Settings.svg";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import DownArrow from "../../../images/DownArrow.svg";
import Enter from "../../../images/Enter.svg";
import Edit from "../../../images/Edit.svg";
import EditProfile from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import Close from "../../../images/Close.svg";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";

import useAcctLedgerData from "../hooks/useAcctLedgerData";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import { ledgerOptions, recepitsw } from "../StudentDetails/FeeReceipt/Index";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import MessageModal from "../../../pages/MessageModal";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import { payloadTypes } from "../../../context/reducer";
import useVoucherNumber from "../hooks/useVoucherNumber";
import StudentList from "../common/StudentList";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  AcctLedgerQueryType,
  DepositLedgerQueryType,
} from "../common/QueryTypes";
import { GetAcctVouchers, GetVoucherNumber } from "../queries/Vouchers/query";
import FeeReceiptPrint from "../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import { Data } from "../../Print/Accounts/FeeReceipts/Standard/Index";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../common/Enum.types";
import Configurations from "../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../HR/enums/Enum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import { accLedgerDetails } from "../../Transport/paginationTypes";
import TextArea from "../../../stories/TextArea/TextArea";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../Types/Accounting/mutations";
import { InstitutionPrintConfig } from "../../Print/Enum.types";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";
import useSwConfigInstType from "../../Academics/hooks/useSwConfigInstType";
const { AccountsTitles } = require("../json/title.json");

interface itemList {
  acctLdgr: accLedgerDetails;
  feeAmount: number;
  sl_no: number;
  cr_db: string;
}
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Receipt = ({ pageType, setModalFlag }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();

  const { InstId, studentId } = useParams<{
    InstId: string;
    studentId: string;
  }>();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};
  const { institutionType } = useSwConfigInstType();

  const { token } = useToken();
  //use States for modal
  const [studentModal, setStudentModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [printModal, SetPrintModal] = useState(false);

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [imageString, setImageString] = useState("");

  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const [printConfigModal, setPrintConfigModal] = useState(false);

  const { user_details } = useLoggedInUserDetails();
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.NON_DEMAND_FEE_PAYABLE_PAGE
  );
  const [admNo, setAdmNo] = useState("");
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.GENERAL
  );
  const [rcptDate, setRcptDate] = useState("");
  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();
  //usestate for input tag
  const [id, setId] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [feeAmount, setFeeAmount] = useState(0);
  const [account_ledger_id, SetAccountLedgerId] = useState<responseType | null>(
    null
  );
  const [searchData, setsearchData] = useState("");
  const [ledgerType, setLedgerType] = useState<optionsType | null>(null);
  const [cashAccount, setCashAccount] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);
  const [bankTransactionType, setBankTransactionType] =
    useState<optionsType | null>(null);
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const [referenceNumber, setReferenceNumber] = useState("");

  const feeamountref = useRef<HTMLInputElement>(null);
  const feeDescRef = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = feeDescRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const buttonRef = useRef<HTMLButtonElement>(null);

  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;

  const saveButtonRef = useRef<HTMLButtonElement>(null);

  //use state for arrays

  const [items, setItems] = useState<itemList[]>([]);

  const itemsSet = new Set(items.map((item) => item.acctLdgr.id));
  const [receipts, setreceipts] = useState<recepitsw[]>([]);

  let totalAmount = 0;
  const { InstDetails } = useInstDetails(1);

  //use queries
  const { studentData, studentFormData } = useStudentDatabyId();

  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
    InstitutionPrintConfig.PRINT_RECEIPT_BY_DEFAULT,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    let enableDefaultPrintWhilesaved = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_BY_DEFAULT:
            enableDefaultPrintWhilesaved = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
      enableDefaultPrintWhilesaved,
    };
  };
  const { editDate, enableDefaultPrintWhilesaved } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );

  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE,
    feeLedgerModal
  );
  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_CASH,
    ROWS_PER_PAGE
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING
  );
  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_ADVANCE_RECEIPT_BOOK
  );
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  //use mutation
  const [
    GenerateStudentNonDemandReceipts,
    { loading: GenerateStudentNonDemandReceiptsLoading },
  ] = useMutation<
    AddAcctVoucherMasterAndDetailsData,
    AddAcctVoucherMasterAndDetailsVars
  >(AddAcctVoucherMasterAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleItems = (e: React.FormEvent) => {
    const itemAlreadyExists = items.filter(
      (d) => account_ledger_id && d.acctLdgr.id === account_ledger_id.value
    );
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      setFeeAmount(0);
      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }
    const filterdRes = acctLedgers.responseType.find(
      (d) => account_ledger_id && d.value === account_ledger_id.value
    )!;

    if (filterdRes && feeAmount) {
      setItems((items) => [
        ...items,
        {
          sl_no: items.length + 1,
          feeAmount,
          cr_db: DebitOrCredit.CREDIT,
          acctLdgr: {
            id: filterdRes.value,
            ldgr_desc: filterdRes.label,
          },
        },
      ]);
      SetAccountLedgerId(null);
      setFeeAmount(0);
    }

    feeDescInputRef?.focus();
  };
  const handleReceipts = () => {
    if (transactionBookLedgerId)
      setreceipts((items) => [
        ...items,
        {
          acct_ldgr_id: Number(transactionBookLedgerId.value),
        },
      ]);
  };
  const handleItemEdit = (feeAmount: number, acct_ldgr_id: number) => {
    const res = acctLedgers.responseType.find(
      (ledger) => ledger.value === acct_ldgr_id
    );
    SetAccountLedgerId(res ? res : null);
    setFeeAmount(feeAmount);
  };
  const HandleEditItem = (sl_number: number) => {
    if (account_ledger_id) {
      const updatedData = items.map((item) => {
        if (item.sl_no === sl_number) {
          const newData = {
            ...item,
            feeAmount,
            account_ledger_id: account_ledger_id.value,
          };
          return newData;
        }
        return item;
      });
      setItems(updatedData);
      feeDescInputRef?.focus();

      setEdit(!edit);
      setFeeAmount(0);
      SetAccountLedgerId(null);
    }
  };

  const handleItemDeleted = (ids: number) => {
    const updatedItems = items.filter((item) => item.acctLdgr.id !== ids);

    setItems(updatedItems);
  };
  // eslint-disable-next-line
  items.map((item) => {
    totalAmount += item.feeAmount;
  });

  const handleStudentReceipts = () => {
    const res = bankTransactionTypes.find(
      ({ value }) => bankTransactionType && value === bankTransactionType.value
    );

    if (ledgerType === null ) {
      alert("Mode of transaction is required");
      return;
    }
    if(ledgerType && ledgerType.value === BankOrCash.CASH){
      if(transactionBookLedgerId === null){
        alert("Cash Ledger is required");
        return;
      }
    }
    else if(ledgerType && ledgerType.value === BankOrCash.BANK){
      if(bankTransactionType === null){
        alert("Bank transaction type is required");
        return;
      }
      if(transactionBookLedgerId === null){
        alert("Bank Ledger is required");
        return;
      }
      if(!referenceNumber){
        alert("Transaction Reference Number is required");
        return;
      }
    }

    if (transactionBookLedgerId === null) {
      alert("select cash or bank account");
      return;
    } else {
      GenerateStudentNonDemandReceipts({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: [
            {
              acct_voucher_master: {
                fin_yr: state.ActiveFinYr
                  ? state.ActiveFinYr.fin_yr
                  : EMPTY_STRING,
                v_type: ReceiptTypes.STUDENT_RECEIPT,
                v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
                v_date: toIsoDate(rcptDate),
                v_total_cr_amt: totalAmount,
                v_total_db_amt: totalAmount,
                v_std_deposit_adjusted: false,
                enquiry_student_id: 0,
                v_std_refund_deposit: false,
                v_std_enquiry: false,
                v_reconciled: false,

                v_reconciled_date: toIsoDate(DEFAULT_TIME),
                v_std_receipt: true,
                v_std_non_demand_receipt: false,
                v_std_refund: false,
                v_book_type: VoucherBookKey.STUDENT_ADVANCE_RECEIPT_BOOK,
                v_std_deposit: true,
                v_std_receipt_ob: false,
                v_std_receipt_anonymous: false,
                v_std_demand_receipt: false,
                v_std_scholarship_deposit: false,

                student_id: state.studentId,
                passout_student_id: 0,
                v_std_passout_receipt: false,
                class_id: studentData.data?.nodes[0].class.id,
                semester_id: studentData.data?.nodes[0].semester.id,
                v_std_amt_receipt: 0,
                v_std_amt_deposit: totalAmount,
                v_std_amt_fine: 0,
                v_std_amt_total: totalAmount,
                v_std_amt_refunded: 0,
                v_std_amt_adjusted: 0,
                v_transcation_type: res?.label ?? "Cash Book",
                v_transcation_cash_or_bank: res
                  ? BankOrCash.BANK
                  : BankOrCash.CASH,
                v_transcation_no: referenceNumber,
                v_transcation_date: toIsoDate(DEFAULT_TIME),
                v_transcation_narration: remarks,
                v_std_anonymous_deposit_adjusted: false,

                // paid_party_id: "",
                // party_bill_no: "",
                party_bill_date: toIsoDate(DEFAULT_TIME),
                // party_name: "",
                annx_yesno: false,
                // annx_id: Math.random() * 1000,
                is_vouch_multi_entry: false,
                acct_ldgr_id_cr: items[0].acctLdgr.id,
                acct_ldgr_id_db: transactionBookLedgerId.value,
              },
              acct_voucher_db: receipts.map((dbData, index) => ({
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: index + 1,
                vo_cr: 0,
                vo_db: totalAmount,
                vo_cr_total: 0,
                vo_db_total: totalAmount,
                acct_ldgr_id: dbData.acct_ldgr_id,
              })),
              acct_voucher_cr: items.map((crData, index) => ({
                vo_cr_db: crData.cr_db,
                vo_sl_no: index + 1,
                vo_cr: crData.feeAmount,
                vo_db: 0,
                vo_cr_total: totalAmount,
                vo_db_total: 0,
                acct_ldgr_id: crData.acctLdgr.id,
              })),
            },
          ],
        },
        refetchQueries: [
          {
            query: GetAcctStdDeposits,
            variables: {
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
              student_id: state.studentId,
              token,
            },
          },
          {
            query: GetAcctVouchers,
            variables: {
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              sortBy: SortBy.V_DATE,
              token,
              name: EMPTY_STRING,
              deposit: null,
              vTranscationType: EMPTY_STRING,
              partyName: EMPTY_STRING,
              vTranscationNo: EMPTY_STRING,
              vTranscationNarration: EMPTY_STRING,
              ldgrDesc: EMPTY_STRING,
              amount: null,
              input: {
                inst_id: InstId!,
                voucher_query_type: VoucherQueryTypes.BY_DATES,
                vo_end_date: toIsoDate(lastDay!),
                acct_ldgr_id: 0,
                vo_start_date: toIsoDate(firstDay!),
                vo_type: EMPTY_STRING,
              },
            },
          },
          {
            query: GetVoucherNumber,
            variables: {
              token,
              inst_id: InstId!,
              vo_book_key: VoucherBookKey.STUDENT_ADVANCE_RECEIPT_BOOK,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          const { v_no, v_date, id } = data.AddAcctVoucherMasterAndDetails[0];
          dispatch({
            type: payloadTypes.SET_RECEPIT_ID,
            payload: { receiptId: id },
          });

          setTableData(
            items
              .filter((item) => item.feeAmount > 0)
              .map((item) => ({
                particular: item.acctLdgr.ldgr_desc,
                amount: item.feeAmount,
              }))
          );
          set_v_Date(v_date);
          set_vo_no(v_no);

          enableDefaultPrintWhilesaved && !printModal
            ? SetPrintModal(!printModal)
            : handleClear();
          setMessage({
            message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const HandleSelectFeeType = (id: number) => {
    const filterdData = acctLedgers.responseType?.filter(
      ({ value }) => value === id
    );

    if (filterdData) SetAccountLedgerId(filterdData[0]);
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleClear = () => {
    setAdmNo("");
    setItems([]);
    SetAccountLedgerId(null);
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });
    setTransactionLedgerId(null);
    setLedgerType(null);
    setRemarks("");
    setTableData([]);
    setImageString(EMPTY_STRING);
  };

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  useEffect(() => {
    if (transactionBookLedgerId && Number(transactionBookLedgerId.value) > 0) {
      handleReceipts();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);
  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);
  const { branchLabel, classLabel, categoryLabel } = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.Advances.Titles.map((title: AdvancesTitleProps) => {
              return (
                <React.Fragment key={title.AdvanceReceipt}>
                  {title.AdvanceReceipt}
                </React.Fragment>
              );
            })}
          </Title>
        </div>
        <div className="configuration-settings">
          <>
            {USE_CONFIG_KEY && (
              <img
                src={Settings}
                alt="/"
                id="settings-icon"
                onClick={() => setConfigurationModal(!configurationModal)}
              />
            )}
          </>
        </div>
      </div>
      <div
        className={`row g-0 non-demandfee-payable${
          pageType === PageFor.MODAL ? "--modal" : ""
        }`}>
        <div className="col-4">
          <div className="non-demandfee-payable__grid">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={studentAddmissionNumber}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (admNo || state.studentId) {
                    feeDescInputRef?.focus();
                  }
                }
              }}
              isOptionEqualToValue={(option) =>
                (option as responseType).value === state.studentId
              }
              value={
                state.studentId
                  ? studentAddmissionNumber?.find(
                      ({ value }) => value === state.studentId
                    )!
                  : null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: {
                      studentId: (newValue as responseType)?.value,
                    },
                  });
                } else {
                  handleClear();
                }
              }}
              openOnFocus
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => setAdmNo(e.target.value)}
                  label="Admission No."
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  autoFocus
                  className={labelClasses.formControlRoot}
                />
              )}
            />

            <img
              className="data-fetch-icon"
              src={Edit}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>

          {institutionType !== InstitutionType.SCHOOL && (
            <TextField
              label="Register Number"
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData?.reg_number}
              disabled
            />
          )}
          {USE_CATEGORY_KEY && (
            <TextField
              label={categoryLabel}
              className="demand-fee__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={studentFormData?.category}
              disabled
            />
          )}
        </div>

        <div className="col">
          <TextField
            label="Name"
            className="demand-fee__textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData?.std_name}
            disabled
          />
          <TextField
            label={branchLabel}
            className="demand-fee__textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData?.branch}
            disabled
          />
          <TextField
            label={classLabel}
            className="demand-fee__textfield"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={studentFormData?.class}
            disabled
          />
        </div>
        <div className="col-3">
          <div className="non-demandfee-payable__label-grid">
            <TextField
              type="date"
              label="Date"
              className="demand-fee__textfield--date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                input: {
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                      : 0,

                    max: toInputStandardDate(TODAY_DATE),
                  },
                },
              }}
              value={toInputStandardDate(rcptDate)}
              onChange={(e) => setRcptDate(e.target.value)}
              disabled={editDate ? false : true}
            />
            <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>
          </div>
          <div className="non-demandfee-payable__label-gridbtn">
            <Button
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
              mode="ledger"
            />
          </div>
        </div>
        <div className="col-1 non-demandfee-payable__image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={`row g-0 non-demandfee-payable__tableblock${
          pageType === PageFor.MODAL ? "--modal" : ""
        }`}>
        <TableContainer className="non-demandfee-payable__table g-0">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.Receipts.NonDemand.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="non-demandfee-payable__table--select-row">
                <TableCell
                  id="td-center"
                  className="non-demandfee-payable__table--slno">
                  {items.length + 1}
                </TableCell>
                <TableCell className="non-demandfee-payable__table--desc">
                  <FormAutocomplete
                    className={formClasses.inputRoot}
                    options={acctLedgers.responseType.filter(
                      ({ value }) =>
                        (itemsSet.has(value) === false && !edit) || edit
                    )}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(
                        option as responseType,
                        account_ledger_id
                      )
                    }
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (
                          feeamountref.current &&
                          account_ledger_id &&
                          account_ledger_id.value !==
                            EMPTY_RESPONSETYPE[0].value
                        ) {
                          feeamountref.current?.focus();
                          feeamountref.current?.select();
                        }
                        if (
                          items.length >= 1 &&
                          account_ledger_id &&
                          account_ledger_id.value ===
                            EMPTY_RESPONSETYPE[0].value
                        ) {
                          modeOfTransactionInputRef?.focus();
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        SetAccountLedgerId(null);
                      }
                    }}
                    autoHighlight
                    openOnFocus
                    value={account_ledger_id}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        SetAccountLedgerId(newValue as responseType);
                      } else {
                        SetAccountLedgerId(null);
                      }
                    }}
                    ref={feeDescRef}
                    disabled={!state.studentId}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => setsearchData(e.target.value)}
                        className={formClasses.formControlRoot}
                      />
                    )}
                  />
                </TableCell>
                <TableCell
                  id="td-right"
                  className="non-demandfee-payable__table--balance">
                  <input
                    ref={feeamountref}
                    value={feeAmount}
                    id="td-right"
                    disabled={!account_ledger_id}
                    type="number"
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (feeAmount !== 0) {
                          buttonRef.current?.focus();
                        }
                      }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (Number(e.target.value) >= 0)
                        setFeeAmount(Number(e.target.value));
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className="non-demandfee-payable__table--balance">
                  <button
                    onClick={(e: React.FormEvent) =>
                      edit ? HandleEditItem(id) : HandleItems(e)
                    }
                    ref={buttonRef}>
                    {edit ? "Save" : <img src={Enter} alt="/" />}
                  </button>
                </TableCell>
              </TableRow>
              {items.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell id="td-center">{index + 1}</TableCell>
                    <TableCell>{item.acctLdgr.ldgr_desc}</TableCell>
                    <TableCell
                      id="td-right"
                      className="non-demandfee-payable__table--balance">
                      {format(item.feeAmount)}
                    </TableCell>
                    <TableCell id="td-center">
                      <img
                        src={EditProfile}
                        alt="/"
                        onClick={() => {
                          handleItemEdit(item.feeAmount, item.acctLdgr.id);
                          setEdit(!edit);
                          HandleSelectFeeType(item.acctLdgr.id);
                          setId(item.sl_no);
                        }}
                      />

                      {items.length ? (
                        <img
                          id="delete-profile"
                          src={Delete}
                          alt="/"
                          onClick={() => handleItemDeleted(item.acctLdgr.id)}
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} className="total">
                  Total :
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalAmount)}
                </TableCell>
                <TableCell id="tfoot-td"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <form className="row g-0 non-demandfee-payable__receiptblock">
        <div className="col account-frames  h-100">
          <div className="label-grid">
            <Label>Receipt No.</Label>
            <Input
              value={
                voucherNumber.data && state.studentId
                  ? voucherNumber.data?.GetVoucherNumber.vo_number
                  : EMPTY_STRING
              }
              disabled
            />
          </div>
          <div className="label-grid">
            <Label> Mode of Transaction</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={ledgerOptions}
              openOnFocus
              ref={modeOfTransactionRef}
              autoHighlight
              value={ledgerType}
              onChange={(e, newValue) => {
                if (newValue) {
                  setLedgerType(newValue as optionsType);
                  setTransactionLedgerId(null);

                  if ((newValue as optionsType).value === BankOrCash.BANK) {
                    setBankAccount(true);
                    setCashAccount(false);
                  } else {
                    setCashAccount(true);
                    setBankAccount(false);
                  }
                } else {
                  setLedgerType(null);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER && ledgerType) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setLedgerType(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        {cashAccount ? (
          <div className="col account-frames label-grid h-100">
            <Label>Cash Ledgers</Label>
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={CashLedgers.responseType!}
              openOnFocus
              autoHighlight
              value={transactionBookLedgerId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTransactionLedgerId(newValue as responseType);
                  setreceipts([]);
                } else {
                  setTransactionLedgerId(null);
                  setreceipts([]);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (transactionBookLedgerId && e.key === Keys.ENTER) {
                  handleMUISelectEvent(e);
                } else if (e.key === Keys.BACKSPACE) {
                  setTransactionLedgerId(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  onChange={(e) => setsearchData(e.target.value)}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {bankAccount ? (
          <div className="col account-frames h-100">
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label> Bank Transaction Type</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={bankTransactionTypes!}
                openOnFocus
                freeSolo
                autoHighlight
                value={bankTransactionType}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBankTransactionType(newValue as optionsType);
                  } else {
                    setBankTransactionType(null);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (ledgerType && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  } else if (e.key === Keys.BACKSPACE) {
                    setBankTransactionType(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label> Bank Ledgers</Label>
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={BankLedgers.responseType!}
                openOnFocus
                freeSolo
                autoHighlight
                value={transactionBookLedgerId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setTransactionLedgerId(newValue as responseType);
                    setreceipts([]);
                  } else {
                    setTransactionLedgerId(null);
                    setreceipts([]);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (transactionBookLedgerId && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  } else if (e.key === Keys.BACKSPACE) {
                    setTransactionLedgerId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setsearchData(e.target.value)}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
            </div>
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label>Transaction Reference Number</Label>
              <Input
                required
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                }}
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setReferenceNumber(e.target.value)
                }
              />
            </div>
          </div>
        ) : null}
        <div className="col account-frames label-grid h-100">
          <Label>Remarks :</Label>
          <TextArea
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === Keys.ENTER) {
                saveButtonRef.current?.focus();
              }
            }}
            rows={3}
          />
        </div>
      </form>
      <div className="non-demandfee-payable__buttons">
        <Button
          mode="save"
          disabled={!state.studentId || items.length === 0}
          onClick={handleStudentReceipts}
          buttonref={saveButtonRef}
        />

        <Button
          mode="clear"
          disabled={!state.studentId}
          onClick={handleClear}
        />
        {pageType === PageFor.MODAL ? (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        ) : (
          <Button mode="back" onClick={() => navigate(-1)} />
        )}
      </div>
      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.CURR}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentModal(!studentModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* fee ledger Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeLedger
              pageFor={PageFor.MODAL}
              setFeeledgerModal={setFeeledgerModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setFeeledgerModal(!feeLedgerModal)}
            />
          </div>
        </div>
      </Modal>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.ADVANCE_RECEIPT}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeReceiptPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={referenceNumber}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                if (enableDefaultPrintWhilesaved) SetPrintModal(!printModal);
                handleClear();
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfigModal)}
            />
          </div>
        </div>
      </Modal>
      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfigModal}
        style={PrintConfigModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.RECEIPT_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfigModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentNonDemandReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Receipt;
