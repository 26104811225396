//React
import React, { useContext, useEffect, useRef, useState } from "react";
//Material UI
import { TextField } from "@mui/material";
//HTML Hocs
import { Title } from "../../../stories/Title/Title";
import { LibraryTitleProps } from "../../../Types/Titles";
import Input from "../../../stories/Input/Input";
//Components
import Home from "../Home/Index";
import Modal from "react-modal";
//Custom Hooks
import useClassification from "../customHooks/useClassification";
import useLibMasterDataOptions, {
  LibMasterData,
} from "../customHooks/useLibMasterDataOptions";
//Types
import {
  BookListType,
  ColumnVisibilityFor,
  Direction,
  PageFor,
  PageNumbers,
  ReturnType,
  SortBy,
} from "../../../utils/Enum.types";
import { Keys } from "../../../utils/Enum.keys";
//Modals
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import BookList from "../BookEntry/Index";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import { ClassificationDetails, responseType } from "../../../utils/Form.types";
import { GetLibBookClassificationsByKeywordandInstId } from "../../../queries/Library/MasterData/Classification/list/byKeywordandInstId";
import { useLazyQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { BookClassificationEdge } from "../../../Types/Library/MasterData/Classification/paginationTypes";
import { Button } from "../../../stories/Button/Button";
import Close from "../../../images/Close.svg";
import Settings from "../../../images/Settings.svg";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
} from "../../../styles/ModalStyles";
import { AppContext } from "../../../context/context";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import ConfigurationSettings from "../../../Modules/Master/configurations/general/Index";
import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import { payloadTypes } from "../../../context/reducer";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../utils/types";
import useLibraryTableJson from "../json/useLibraryTableJson";

//Jsons
const { Library } = require("../../../json/title.json");

interface Props {
  pageType: PageFor;
  bookListType?: BookListType;
  setClassBookModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface ClassificationModal {
  flag: boolean;
  ClassificationDetails?: ClassificationDetails;
}
const Index = ({ pageType, setClassBookModal, bookListType }: Props) => {
  const [searchClassification, setSearchClassification] = useState("");
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [currentRepo, setCurrentRepo] = useState<BookClassificationEdge[]>();
  const [bookListModal, setBookListModal] = useState(false);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  //Styles

  //States
  const [searchAuthors, setSearchAuthors] = useState("");
  const [searchPublishers, setSearchPublishers] = useState("");
  const [searchSubjects, setSearchSubjects] = useState("");
  const [searchKeywords, setSearchKeywords] = useState("");
  const [searchMedia, setSearchMedia] = useState("");
  const [authorId, setAuthorId] = useState<number | null>(null);
  const [publisherId, setPublisherId] = useState<number | null>(null);
  const [subjectId, setSubjectId] = useState<number | null>(null);
  const [configurationModal, setConfigurationModal] = useState(false);
  // eslint-disable-next-line
  const [keywordId, setKeywordId] = useState<number | null>(0);
  const [mediaId, setMediaId] = useState<number | null>(null);
  //refs for enter key
  const authorRef = useRef<HTMLSelectElement>(null);
  const authorInputRef = authorRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const publisherRef = useRef<HTMLSelectElement>(null);
  const publisherInputRef = publisherRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const subjectRef = useRef<HTMLSelectElement>(null);
  const subjectInputRef = subjectRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const keywordRef = useRef<HTMLSelectElement>(null);
  const keywordInputRef = keywordRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const mediaRef = useRef<HTMLSelectElement>(null);
  const mediaInputRef = mediaRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;

  //Custom Hooks
  const {
    classificationsList: {
      queryData: {
        data: classificationData,
        loading: classificationLoading,
        fetchMore,
      },
    },
  } = useClassification(
    searchClassification,
    ROWS_PER_PAGE,
    ReturnType.QUERY_TYPE,
    authorId,
    publisherId,
    subjectId,
    keywordId,
    mediaId
  );
  const { medias } = useLibMasterDataOptions(LibMasterData.MEDIA, searchMedia);
  const { subjects } = useLibMasterDataOptions(
    LibMasterData.SUBJECT,
    searchSubjects
  );
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_ENTRY_LIST_PAGE
  );
  const { publishers } = useLibMasterDataOptions(
    LibMasterData.PUBLISHERS,
    searchPublishers
  );
  const { authors } = useLibMasterDataOptions(
    LibMasterData.AUTHORS,
    searchAuthors
  );
  const { keywords } = useLibMasterDataOptions(
    LibMasterData.KEYWORDS,
    searchKeywords
  );
 const{Library_Table}=useLibraryTableJson();

  const [
    GetClassificationByKeyword,
    {
      data: classificationsByKeywordData,
      loading: classificationsByKeywordLoading,
    },
  ] = useLazyQuery(GetLibBookClassificationsByKeywordandInstId);

 const dynamicHeaders: TableHeaderProps[] = Library_Table.BookSearch.Table_Headers.map(
   (header) => ({
     headerName: header.headerName,
     cellClassName: header.cellClassName,
     field: header.field,
     headerAlign: header.headerAlign as GridAlignment,
     align: header.align as GridAlignment,
     flex: header.flex,
     hideable: header.hideable,
     renderCell: (params: GridCellParams) => {
              const classificationId = params.row.classification_id;

       if (header.field === "classification_name") {
         return (
           <div
             onClick={() => {
               dispatch({
                 type: payloadTypes.SET_CLASSIFICATION_ID,
                 payload: {
                   classificationId: classificationId,
                 },
               });
               setBookListModal(!bookListModal);
             }}
           >
             {params.row.classification_name}
           </div>
         );
       }
       return params.value;
     },
   })
 );

  const columns: GridColDef[] = [
    ...dynamicHeaders
  ];

  const handleClear = () => {
    setSearchClassification("");
    setAuthorId(0);
    setPublisherId(0);
    setKeywordId(0);
    setMediaId(0);
  };
  useEffect(() => {
    if (classificationData && !classificationLoading) {
      const newData = classificationData.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newMedia) => {
          const filteredStudent = currentRepo?.find(
            (media) => media.node.id === newMedia.node.id
          );
          if (filteredStudent) {
            return {
              ...newMedia,
              node: {
                ...newMedia.node,
              },
            };
          }
          return newMedia;
        });
        setCurrentRepo(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            classification_no: node.classification_no,
            classification_name: node.classification_desc,
            classification_id: node.id,
            copies_available: node.classification_copies_avialable,
            author: node.author.author_name,
            publisher: node.publisher.publisher_name,
            media: node.media.media_desc,
            category: node.category.category_desc,
            subject: node.subject.subject_desc,
            language: node.language.language,
            attribute: node.attribute.book_attribute,
            published_year: node.book_published_year,
            edition: node.book_edition,
            books_available: node.classification_copies_on_shelf,
          }))
        );
      } else {
        setCurrentRepo(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            classification_id: node.id,
            classification_no: node.classification_no,
            classification_name: node.classification_desc,
            copies_available: node.classification_copies_avialable,
            author: node.author.author_name,
            publisher: node.publisher.publisher_name,
            media: node.media.media_desc,
            category: node.category.category_desc,
            subject: node.subject.subject_desc,
            language: node.language.language,
            attribute: node.attribute.book_attribute,
            published_year: node.book_published_year,
            edition: node.book_edition,
            books_available: node.classification_copies_on_shelf,
          }))
        );
      }
      setEndCursor(classificationData.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [classificationData, classificationLoading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !classificationLoading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges =
                fetchMoreResult.GetLibBookClassificationsByInstId.edges;
              const pageInfo =
                fetchMoreResult.GetLibBookClassificationsByInstId.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck =
                prevResult.GetLibBookClassificationsByInstId.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );
              if (duplicateCheck.length > 0) return prevResult;
              return {
                GetLibBookClassificationsByInstId: {
                  edges: [...currentRepo!, ...newEdges],
                  pageInfo,
                  totalCount: classificationData
                    ? classificationData.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [rows]);
  useEffect(() => {
    if (keywordId) {
      GetClassificationByKeyword({
        variables: {
          token,
          inst_id: InstId,
          keywordId,
          first: ROWS_PER_PAGE,
          after: null,
          sortBy: SortBy.CLASSIFICATION_DESC,
          direction: Direction.ASC,
        },
      }).then(({ data }) => {
        if (data) {
          setEndCursor(
            data?.GetLibBookClassificationsByInstId?.pageInfo?.endCursor
          );
          setCurrentRepo(data?.GetLibBookClassificationsByInstId?.edges);
        }
      });
    } else if (classificationData && !classificationLoading) {
      setEndCursor(classificationData?.pageInfo?.endCursor);
      setCurrentRepo(classificationData?.edges);
    }
  }, [
    keywordId,
    GetClassificationByKeyword,
    classificationData,
    classificationLoading,
    classificationsByKeywordData,
    classificationsByKeywordLoading,
    InstId,
    token,
  ]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      publisher: false,
      media: false,
      category: false,
      subject: false,
      language: false,
      attribute: false,
      published_year: false,
      edition: false,
    });

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.BOOK_SEARCH
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.BOOK_SEARCH,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {Library.Titles.BooksList.map(
              (title: LibraryTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.BooksList}</React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        {USE_CONFIG_KEY && pageType === PageFor.GENERAL ? (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        ) : null}
        {pageType === PageFor.GENERAL ? (
          <div className="col-1">
            <Button
              mode="addnew"
              autoFocus
              className="container__list--addnew"
              onClick={() => {
                navigate(`/${InstId}/library/newbookentry`);
              }}
            />
          </div>
        ) : null}
      </div>
      <div
        className={
          pageType === PageFor.GENERAL ? "book-search" : "book-search--modal"
        }
      >
        <div className="row g-0 book-search__details">
          <div className="col">
            <Input
              id="search"
              type="text"
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  authorInputRef?.focus();
                }
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchClassification(e.target.value);
              }}
              placeholder="Book Title"
              className="book-search__details--input"
            />
          </div>

          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={authors!}
              ref={authorRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  publisherInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setAuthorId(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setAuthorId((newValue as responseType)?.value!);
                  setHasNextPage(true);
                } else {
                  setAuthorId(null);
                }
              }}
              value={authors?.find(({ value }) => value === authorId) ?? null}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchAuthors(e.target.value);
                  }}
                  label="Search By Author"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={publishers!}
              ref={publisherRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  subjectInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setPublisherId(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setPublisherId((newValue as responseType)?.value!);
                  setHasNextPage(true);
                } else {
                  setPublisherId(null);
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchPublishers(e.target.value);
                  }}
                  label="Search By Publisher"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={subjects!}
              ref={subjectRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  keywordInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setSubjectId(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSubjectId((newValue as responseType)?.value!);
                  setHasNextPage(true);
                } else {
                  setSubjectId(null);
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchSubjects(e.target.value);
                  }}
                  label="Search By Subject"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={keywords!}
              ref={keywordRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  mediaInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setKeywordId(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setKeywordId((newValue as responseType)?.value ?? 0);
                  setHasNextPage(true);
                } else {
                  setKeywordId(null);
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchKeywords(e.target.value);
                  }}
                  label="Search By Keyword"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
          <div className="col">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={medias!}
              ref={mediaRef!}
              onChange={(e, newValue) => {
                if (newValue) {
                  setMediaId((newValue as responseType)?.value!);
                  setHasNextPage(true);
                } else {
                  setMediaId(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setMediaId(null);
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchMedia(e.target.value);
                  }}
                  label="Search By Media"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  id="outlined Departments"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className={`library__frame--block`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            hideFooter
          />
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          {pageType === PageFor.GENERAL ? (
            <div className="button-left">
              <Button mode="clear" onClick={handleClear} />

              <Button mode="back" onClick={() => navigate(-1)} />
            </div>
          ) : (
            <Button mode="cancel" onClick={() => setClassBookModal?.(false)} />
          )}
        </div>
        <div className="col-3 flex-end">
          <div className="student-total-count">
            Total Books{" "}
            <b>{classificationData && classificationData.totalCount}</b>
          </div>
        </div>
      </div>

      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_ENTRY_LIST_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>

      {/*  BookListmodal*/}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookListModal}
        style={DeleteLedgerModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BookList
              pageFor={PageFor.MODAL}
              bookListType={bookListType ?? BookListType.ON_SHELF}
              setClassBookModal={setClassBookModal}
              setBookListModal={setBookListModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setBookListModal(!bookListModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
