import React, { useMemo, useRef, useState } from "react";
import { responseType } from "../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { Button } from "../../../../stories/Button/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import View from "../../../../images/EyeWhite.svg";
import { InstitutionDetails, TableHeaders } from "../../../../utils/Enum.types";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../../utils/Enum.keys";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";

import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import {
  AssignComponentProp,
  TreeType,
} from "../../../Academics/DailyActivities/Home";
import useMstInstTreeByQueryType from "../../../../customhooks/general/useMstInstTreeByQueryType";
import { HYPHEN } from "../../../HR/constants";
import LoadingModal from "../../../../pages/LoadingModal";

const SectionInterchangeTree = () => {
  const navigate = useNavigate();

  //useStates
  const { InstId } = useParams();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );

  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  //useRefs
  const departmentRef = useRef<HTMLSelectElement>(null);

  //useMutations

  const { data, loading } = useMstInstTreeByQueryType(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    toIsoDate(TODAY_DATE),
    0
  );

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }
    if (USE_SECTION_KEY) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);
  const AssignComponent = ({ id, data }: AssignComponentProp) => {
    return data && data ? (
      <button
        className="daily-activities__home--table--view-button"
        onClick={() => {
          navigate(`/${InstId}/admissions/students/${id}/interchange`);
        }}>
        View <img src={View} alt="" />
      </button>
    ) : null;
  };

  let idsSet = new Set();

  const modifiedArrayForTable: TreeType[] = data
    ? data.GetAcdInstTree.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.flatMap((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          filterThis: false,
                          std_section_count_level2: level2.std_section_count,
                          std_section_count_level3: level3.std_section_count,
                          std_section_count_level4: level4.std_section_count,
                          std_section_count_level5: level5.std_section_count,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count,
                        level5_name: EMPTY_STRING,
                        level1_id: level1.acd_level_1_id,
                        level2_id: level2.acd_level_2_id,
                        level3_id: level3.acd_level_3_id,
                        level4_id: level4.acd_level_4_id,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),
                        std_section_count_level2: level2.std_section_count,
                        std_section_count_level3: level3.std_section_count,
                        std_section_count_level4: level4.std_section_count,
                        std_section_count_level5: 0,
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level5_name: EMPTY_STRING,
                    level1_id: level1.acd_level_1_id,
                    level2_id: level2.acd_level_2_id,
                    level3_id: level3.acd_level_3_id,
                    level4_id: 0,
                    level5_id: 0,

                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                    std_section_count_level2: level2.std_section_count,
                    std_section_count_level3: level3.std_section_count,
                    std_section_count_level4: 0,
                    std_section_count_level5: 0,
                  };

                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level5_name: EMPTY_STRING,
                level1_id: level1.acd_level_1_id,
                level2_id: level2.acd_level_2_id,
                level3_id: 0,
                level4_id: 0,
                level5_id: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                std_section_count_level2: level2.std_section_count,
                std_section_count_level3: 0,
                std_section_count_level4: 0,
                std_section_count_level5: 0,
              };

              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level5_name: EMPTY_STRING,
            level1_id: level1.acd_level_1_id,
            level2_id: 0,
            level3_id: 0,
            level4_id: 0,
            level5_id: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];

  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  const clearRestInstDetails = (type: InstitutionDetails) => {
    switch (type) {
      case InstitutionDetails.DEPARTMENT:
        setBranchSelected(null);
        setClassSelected(null);
        setSemesterSelected(null);
        break;
      case InstitutionDetails.BRANCH:
        setClassSelected(null);
        setSemesterSelected(null);
        break;
      case InstitutionDetails.CLASS:
        setSemesterSelected(null);
        break;
    }
  };

  return (
    <>
      <form className="row g-0 section-allocation__options">
        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, departmentSelected)
              }
              ref={departmentRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && departmentSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue as responseType);
                } else {
                  setDepartmentSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.DEPARTMENT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label={departmentLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}

        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && branchSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue as responseType);
                } else {
                  setBranchSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.BRANCH);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label={branchLabel}
                  id="outlined Branches"
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue as responseType);
                } else {
                  setClassSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.CLASS);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label={classLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={semesterDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && semesterSelected) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue as responseType);
                } else {
                  setSemesterSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.SEMESTER);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label={semesterLabel}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SECTION_KEY ? (
          <div className="col-1">
            <LabeledAutocomplete
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, sectionSelected)
              }
              className={labelClasses.inputRoot}
              options={sectionDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(null);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue as responseType);
                } else {
                  setSectionSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />
          </div>
        ) : null}
      </form>
      <div className="section-allocation__datablock frame-space">
        <>
          <div className="section-allocation__tableblock">
            <TableContainer className="section-allocation__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {USE_DEPARTMENT_KEY ? (
                      <TableCell>{departmentLabel}</TableCell>
                    ) : null}
                    {USE_BRANCH_KEY ? (
                      <TableCell>{branchLabel}</TableCell>
                    ) : null}
                    {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
                    {USE_SEMESTER_KEY ? (
                      <TableCell>{semesterLabel}</TableCell>
                    ) : null}
                    {USE_SECTION_KEY ? (
                      <TableCell>{sectionLabel}</TableCell>
                    ) : null}
                    <TableCell>No Of Students</TableCell>

                    <TableCell>{TableHeaders.ACTION}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                    if (data) {
                      return (
                        <TableRow key={i}>
                          {data.level1_name !== EMPTY_STRING ? (
                            <TableCell rowSpan={data.level1_rowspan}>
                              {data.level1_name}
                            </TableCell>
                          ) : null}
                          {data &&
                          (data.level2_name ||
                            data.level2_name !== EMPTY_STRING) ? (
                            <TableCell rowSpan={data.level2_rowspan}>
                              {data.level2_name}
                            </TableCell>
                          ) : null}
                          {data && data.level3_name !== EMPTY_STRING ? (
                            <TableCell rowSpan={data.level3_span}>
                              {data.level3_name}
                            </TableCell>
                          ) : null}
                          {data && data.level4_name !== EMPTY_STRING ? (
                            <TableCell rowSpan={data.level4_span}>
                              {data.level4_name}
                            </TableCell>
                          ) : null}
                          {data.level5_name !== EMPTY_STRING ? (
                            <TableCell>{data.level5_name}</TableCell>
                          ) : null}
                          <TableCell
                            className="lesson-planner__table--total"
                            id="td-center">
                            {last_level - 1 === 2 && data.level2_name
                              ? data.std_section_count_level2
                                ? data.std_section_count_level2
                                : HYPHEN
                              : last_level - 1 === 3 && data.level3_name
                              ? data.std_section_count_level3
                                ? data.std_section_count_level3
                                : HYPHEN
                              : last_level - 1 === 4 && data.level4_name
                              ? data.std_section_count_level4
                                ? data.std_section_count_level4
                                : HYPHEN
                              : data.std_section_count_level5
                              ? data.std_section_count_level5
                              : HYPHEN}
                          </TableCell>

                          <TableCell
                            id="td-center"
                            className="daily-activities__home--table--actions">
                            {last_level - 1 === 1 && data.level1_name && (
                              <AssignComponent id={data.level1_id} />
                            )}
                            {last_level - 1 === 2 && data.level2_name && (
                              <AssignComponent
                                id={data.level2_id}
                                data={data && data.std_section_count_level2}
                              />
                            )}
                            {last_level - 1 === 3 && data.level3_name && (
                              <AssignComponent
                                id={data.level3_id}
                                data={data && data.std_section_count_level3}
                              />
                            )}
                            {last_level - 1 === 4 && data.level4_name && (
                              <AssignComponent
                                id={data.level4_id}
                                data={data && data.std_section_count_level4}
                              />
                            )}
                            {last_level - 1 === 5 && data.level5_name && (
                              <AssignComponent
                                id={data.level5_id}
                                data={data && data.std_section_count_level5}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      </div>
      <div className="row g-0">
        <div className="col">
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <LoadingModal flag={loading} />
    </>
  );
};

export default SectionInterchangeTree;
