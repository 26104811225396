import React, { useContext, useEffect, useRef, useState } from "react";

import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Avatar from "../../../images/Avatar.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import useMasterTableJson, {
  TableColumn,
} from "../../../json/useMasterTableJson";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../customhooks/useToken";
import {
  GetStudentsData,
  StudentEdges,
  vars,
} from "../../../customhooks/useStudentsbyNewApi";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import { updateStudentById } from "../../../queries/students/mutations/update";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { Direction, SortBy } from "../../../utils/Enum.types";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { TableHeaderProps } from "../../../utils/types";
import { handleUploadAndDownloadFile } from "../../../utils/Upload";
import { GetStudentDetailsForRefetch } from "../../../queries/common";
import Modal from "react-modal";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import PicPreview from "./PicPreview";
import LoadingModal from "../../../pages/LoadingModal";
import Home from "../../Master/Home/Index";
import { GetStudents } from "../../../queries/students/list/newApi";
import { AppContext } from "../../../context/context";
import { studentQueryTypes } from "../../../utils/studentqueryEnum.types";
import Upload from "../../../images/Upload.svg";
import ExcelLoading from "../../../pages/ExcelLoading";
import { LoadingStyles } from "../../../styles/ModalStyles";
import Close from "../../../images/Close.svg";

export interface previewStudentType {
  adm_no: string;
  image: File | null;
  std_name: string;
  flag: boolean;
}
const BulkProfilePicUpload = () => {
  const { StudentsBulkPic } = useMasterTableJson();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [stateChange, setStateChange] = useState(false);
  const [fetchingProgress, setFetchingProgress] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(false);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const { InstDetails } = useInstDetails(1);
  const [progress, setProgress] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [updateStudent] = useMutation(updateStudentById);
  const [previewStudent, setPreviewStudent] = useState<previewStudentType>({
    adm_no: "",
    image: null,
    std_name: "",
    flag: false,
  });
  const totalRecords = rows.length;
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [adm_numbers, setAdmNumbers] = useState<Map<string, File>>(new Map());
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const arrayOfAdmNumber = Array.from(adm_numbers.keys());

  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetStudentsData,
    vars
  >(GetStudents, {
    variables: {
      after: null,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: ROWS_PER_PAGE,
      name: EMPTY_STRING,
      status: EMPTY_STRING,
      token,
      orderBy: [
        {
          direction: Direction.ASC,
          field: SortBy.FIRST_NAME,
        },
      ],
      input: {
        ids: [Number(InstId)],
        std_query_type: studentQueryTypes.STDS_BY_ADMISSION_NUMS,
        str_data: arrayOfAdmNumber,
        int_data: [],
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (arrayOfAdmNumber.length) {
      GetStudentsData();
    }
  }, [state.ActiveFinYr, InstId, arrayOfAdmNumber.length, GetStudentsData]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStudents.edges;
                const pageInfo = fetchMoreResult.GetStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },

    // eslint-disable-next-line
    [rows]
  );
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFetchingProgress(true);

    if (event.target.files) {
      const { files } = event.target;
      const tempAdmNumbers = Object.values(files).reduce((acc, element) => {
        const adm_number: string = (element as unknown as File).name.split(
          "."
        )[0];

        acc.set(adm_number, element);
        return acc;
      }, new Map() as Map<string, File>); // Handle selected files

      setAdmNumbers(tempAdmNumbers);
    }

    if (rows.length !== 0 && rows.length > 0) {
      setFetchingProgress(false);
    }
  };

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const enabledMasters = (ele: TableColumn) => {
    if (ele.field === "dept_desc") return USE_DEPARTMENT_KEY;
    else if (ele.field === "branch_desc") return USE_BRANCH_KEY;
    else if (ele.field === "class_desc") {
      return USE_CLASS_KEY;
    } else if (ele.field === "sem_desc") return USE_SEMESTER_KEY;
    else if (ele.field === "section_desc") return USE_SECTION_KEY;
    else return true;
  };

  const dynamicHeaders: TableHeaderProps[] =
    StudentsBulkPic.Table_Headers.filter(enabledMasters).map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.headerAlign as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-sl-no",
    },
    ...dynamicHeaders,
    {
      field: "std_profile_pic",
      headerName: "Profile Pic",
      cellClassName: "td-center",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "center" as GridAlignment,
      renderCell: (params) => {
        const foundStudent = adm_numbers.get(params.row.std_adm_no);
        if (foundStudent) {
          const imageUrl = URL.createObjectURL(foundStudent);
          return (
            <img
              src={imageUrl}
              alt={params.row.std_adm_no}
              onClick={() => {
                setPreviewStudent({
                  adm_no: params.row.std_adm_no,
                  flag: true,
                  image: foundStudent,
                  std_name: params.row.std_name,
                });
              }}
              className="profile-pic__image"
            />
          );
        } else {
          return <img src={Avatar} alt={params.row.std_adm_no} />;
        }
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);

        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
            parent_name: EMPTY_STRING,
            dept_desc: node.dept.dept_desc,
            branch_desc: node.branch.branch_desc,
            class_desc: node.class.class_desc,
            sem_desc: node.semester.sem_desc,
            section_desc: node.section.section_desc,
            std_status: node.std_status,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
            parent_name: EMPTY_STRING,
            dept_desc: node.dept.dept_desc,
            branch_desc: node.branch.branch_desc,
            class_desc: node.class.class_desc,
            sem_desc: node.semester.sem_desc,
            section_desc: node.section.section_desc,
            std_status: node.std_status,
          }))
        );
      }
      setEndCursor(data.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const changePic = (adm_no: string, image: File) => {
    const adm_nums = adm_numbers;
    adm_nums.set(adm_no, image);
    setAdmNumbers(adm_nums);
    setStateChange(!stateChange);
  };

  const uploadFiles = () => {
    setUploadingProgress(true);
    setImportModal(true);
    setSuccessCount(0);
    rows.forEach(async (item) => {
      const file = adm_numbers.get(item.std_adm_no);
      const file_type = file
        ? file.name.split(".")[file.name.split(".").length - 1]
        : EMPTY_STRING;
      const file_name = `${InstDetails.data?.nodes[0]?.inst_name}/students/${item.stdId}/std_profile_pic/std_profile_pic.${file_type}`;
      if (file_type && file) {
        await handleUploadAndDownloadFile(
          file,
          file_name,
          setProgress,
          false
        ).then((res) => {
          if (res) {
            updateStudent({
              variables: {
                id: item.stdId,
                token,
                inst_id: InstId!,
                user_details,
                input: {
                  std_profile_filename: file_name,
                },
              },
              refetchQueries: [
                {
                  query: GetStudentDetailsForRefetch,
                  variables: {
                    token,
                    ids: [item.stdId],
                  },
                },
              ],
            }).then(({ data }) => {
              if (data && data.UpdateStudentById) {
                if (data.UpdateStudentById.id === rows[rows.length - 1].stdId) {
                  setUploadingProgress(false);
                  setSuccessCount((prev) => prev + 1);
                }
              }
            });
          }
        });
      }
    });
    // setUploadingProgress(false);
  };

  useEffect(() => {
    if (
      (rows.length > 0 &&
        adm_numbers.size > 0 &&
        rows.length <= adm_numbers.size) ||
      (data && rows.length === 0) ||
      error
    ) {
      setFetchingProgress(false);
    }
  }, [rows, adm_numbers.size, data, error]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Bulk Profile Pic Upload</Title>
      <div className="bulk_pic_upload">
        <div className="bulk_pic_upload__file">
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleFileChange}
            multiple
          />

          <button type="button" onClick={handleFileClick}>
            <img src={Upload} alt="" /> Select Folder
          </button>

          <b>
            {" "}
            {adm_numbers.size
              ? `${adm_numbers.size} Image${
                  adm_numbers.size > 1 ? "s" : ""
                } Selected`
              : null}
          </b>
        </div>
        <div className={`bulk_pic_upload__tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            onCellClick={() => {}}
            disableRowSelectionOnClick
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
          />
        </div>

        <Button
          mode="save"
          onClick={uploadFiles}
          disabled={!rows.length ? true : false}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <PicPreview
        details={previewStudent}
        setPreviewStudent={setPreviewStudent}
        changePic={changePic}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={0}
          loading={uploadingProgress}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </Modal>
      <LoadingModal flag={fetchingProgress} />
    </>
  );
};

export default BulkProfilePicUpload;
