import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useToken from "../../../customhooks/useToken";
import { GetAcdTestMarksStatus } from "../queries/test/query";

import useActiveAcademicYear from "./useActiveAcademicYear";
import { GetAcdTestClassDetails } from "./useTestClassData";
import { responseType } from "../../../utils/Form.types";
import useCheckAllocationType from "./useCheckAllocationType";

export interface GetAcdTestMarksStatusDetails {
  id: number;
  no_subjects: number;
  is_marks_entry_completed: boolean;
  is_marks_entry_started: boolean;
  is_test_remarks_added: boolean;
  is_report_generated: boolean;
  publish_results: boolean;
  is_non_academic: boolean;
  acd_test_class_id: number;
  num_of_std_test_taken: number;
  test_class_details: GetAcdTestClassDetails;
}

type GetAcdTestMarksStatusData = {
  GetAcdTestMarksStatus: GetAcdTestMarksStatusDetails[];
};

export enum AcdTestMarksStatusQueryType {
  ALL_TESTS = "ALL_TESTS",
  FINALIZED_TESTS = "FINALIZED_TESTS",
  BY_TEST_CLASS_ID = "BY_TEST_CLASS_ID",
  NON_DERIVED_TESTS = "NON_DERIVED_TESTS",
  DERIVED_TESTS = "DERIVED_TESTS",
  FINALIZED_NON_DERIVED_TESTS = "FINALIZED_NON_DERIVED_TESTS",
  FINALIZED_DERIVED_TESTS = "FINALIZED_DERIVED_TESTS",
  SHOW_MARKS_IN_STD_LOGIN = "SHOW_MARKS_IN_STD_LOGIN",
  NON_ACADEMIC_TESTS = "NON_ACADEMIC_TESTS",
}
type GetAcdTestMarksStatusVars = {
  token: string;
  acd_yr_id: number;
  input: {
    acd_test_class_id: number;
    entry_id: number;
    entry_level: string;
    inst_id: string;
    test_type_id: number;
  };
  query_type: AcdTestMarksStatusQueryType;
  per_std_subj_allocation: boolean | undefined;
};
const useTestStatus = (
  query_type: AcdTestMarksStatusQueryType,
  entry_id: number
) => {
  const { InstId, testId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();

  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [classTests, setClassTests] = useState<responseType[]>([]);
  const { USE_SECTION_KEY, USE_SEMESTER_KEY, entry_level } =
    useInstitutionConfiguration();
  const { flag } = useCheckAllocationType();
  const [GetStatus, { data, loading, error }] = useLazyQuery<
    GetAcdTestMarksStatusData,
    GetAcdTestMarksStatusVars
  >(GetAcdTestMarksStatus, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      token &&
      entry_id &&
      state.ActiveAcdYr &&
      entry_level
    ) {
      GetStatus({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            acd_test_class_id:
              query_type === AcdTestMarksStatusQueryType.ALL_TESTS
                ? 0
                : Number(testId) || state.testConductId!,
            entry_id,
            entry_level,
            inst_id: InstId!,
            test_type_id: 0,
          },
          token,
          query_type,
          per_std_subj_allocation: flag ? flag : false,
        },
      }).then(({ data }) => {
        if (data) {
          setClassTests(
            data.GetAcdTestMarksStatus.map((test) => ({
              label: test.test_class_details.test_name_details.test_name,
              value: test.acd_test_class_id,
            }))
          );
        }
      });
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetStatus,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    InstId,
    query_type,
    testId,
    token,
    entry_id,
    entry_level,
    state.testConductId,
    state.ActiveAcdYr,
    flag,
  ]);
  return { statusOfSubject: { data, loading, error, classTests } };
};

export default useTestStatus;
