import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";

import { Title } from "../../../../stories/Title/Title";

import Home from "../../Home/Index";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatter,
  getHeaderRowStyle,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctLdgrYearlyDataSummation,
  GetAcctStudentDemandFeeSummary,
} from "../../queries/FeeLedgers/query/Byid";
import {
  GetAcctLdgrYearlyDataSummationData,
  GetAcctLdgrYearlyDataSummationVars,
  GetAcctStudentDemandFeeSummaryDetailsData,
} from "../../../../Types/Accounting";
import DownArrow from "../../../../images/DownArrow.svg";
import RightArrow from "../../../../images/ArrowRight.svg";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  I4_CELL,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import {
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  FileUploadParams,
  InstitutionConfigurationTypes,
} from "../../../../utils/Enum.types";
import { SummaryHeader, SummaryHeaderPdf } from "../../common/HeaderConsts";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useAcctTableJson from "../../json/useAcctTableJson";
import { EMPTY_STRING } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import {
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { GetFinYearByFinId } from "../../../../queries/institution/financialyear/list/byid";

const AllYearsLedgerSummary = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const { format } = formatter;
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const [finYearId, setFinYearId] = useState(0);
  const { Accounts_Table } = useAcctTableJson();
  const [GetAcctStdDemand, { data: Ledgers, loading: ledgerLoading }] =
    useLazyQuery<
      GetAcctStudentDemandFeeSummaryDetailsData,
      GetAcctLdgrYearlyDataSummationVars
    >(GetAcctStudentDemandFeeSummary, {
      variables: {
        fin_yr_id: finYearId ? finYearId : 0,
        inst_id: InstId!,
        token,
      },
      fetchPolicy: "network-only",
    });
  const [GetFinYearByNodes, { data: FinYearData }] =
    useLazyQuery(GetFinYearByFinId);
  const handleToggle = (index: number, id: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    setFinYearId(id);
  };
  const [GetAcctLdgrYr, { data, loading, error }] = useLazyQuery<
    GetAcctLdgrYearlyDataSummationData,
    GetAcctLdgrYearlyDataSummationVars
  >(GetAcctLdgrYearlyDataSummation, {
    fetchPolicy: "network-only",
  });
  const [finYearMap, setFinYearMap] = useState<Record<string, string>>({});
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData && configData.data?.GetSwConfigVariables[0].config_boolean_value;
  const summaryData =
    Ledgers &&
    Ledgers.GetAcctStudentDemandFeeSummary.map((edge, index) => ({
      SlNo: index + 1,
      LdgrDesc: edge.acct_ldgr_details.ldgr_desc,
      OB: edge.fee_ldgr_ob,
      DemandAmt: edge.fee_ldgr_demand,
      Concession: edge.fee_ldgr_concession,
      Receivable: edge.fee_ldgr_receivable,
      Received: edge.fee_ldgr_received,
      Balance: edge.fee_ldgr_bal,
      Refunds: edge.fee_ldgr_refunds,
      FeeBalance: edge.fee_ldgr_dropout_demand,
    }));

  useEffect(() => {
    if (token && finYearId) {
      GetAcctStdDemand();
    }
  }, [token, GetAcctStdDemand, finYearId, InstId]);

  useEffect(() => {
    if (token) {
      GetAcctLdgrYr({
        variables: {
          fin_yr_id: 0,
          inst_id: InstId!,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          GetFinYearByNodes({
            variables: {
              token,
              ids:
                data &&
                data.GetAcctLdgrYearlyDataSummation.map((res) => res.fin_yr_id),
            },
          });
        }
      });
    }
  }, [token, GetAcctLdgrYr, state.ActiveFinYr, GetFinYearByNodes, InstId]);
  useEffect(() => {
    if (FinYearData && FinYearData.nodes) {
      //@ts-ignore
      const mapping = FinYearData.nodes.reduce((acc, node) => {
        acc[node.id] = node.fin_yr;
        return acc;
      }, {} as Record<string, string>);
      setFinYearMap(mapping);
    }
  }, [FinYearData]);
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    40,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text("Ledger Summary", 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: SummaryHeaderPdf.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: summaryData,
                  foot: [
                    [
                      "",
                      "Closing Balance",
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_ob
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_demand
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_concession
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_receivable
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_received
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_bal
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_refunds
                      )}`,
                      `${format(
                        data!.GetAcctLdgrYearlyDataSummation[0]
                          .fee_ldgr_dropout_demand
                      )}`,
                    ],
                  ],
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                  columnStyles: {
                    3: { halign: "right" },
                    4: { halign: "right" },
                    5: { halign: "right" },
                    6: { halign: "right" },
                    7: { halign: "right" },
                    8: { halign: "right" },
                    9: { halign: "right" },
                    10: { halign: "right" },
                  },
                  footStyles: {
                    fillColor: [144, 238, 144],
                    textColor: [0, 0, 0],
                    fontSize: 10,
                    halign: "right",
                  },
                });
                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${state.ActiveFinYr?.fin_yr} `
                );
              });
            });
        });
      });
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.FEE_BALANCE);

    const dataRows = summaryData
      ? summaryData?.map((item) => [
          item.SlNo,
          item.LdgrDesc,
          item.OB,
          item.DemandAmt,
          item.Concession,
          item.Receivable,
          item.Received,
          item.Balance,
          item.Refunds,
          item.FeeBalance,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 25;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          if (configLogo) worksheet.addImage(imageV, "J1:J3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");

                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:J2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:J3");

                  const mergedHeader: Cell = worksheet.getCell(D4_CELL);
                  mergedHeader.value = ExcelPageHeader.FEE_BALANCE;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("D4:H4");
                  const mergedDate: Cell = worksheet.getCell(I4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("I4:J4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:C4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < SummaryHeader.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = SummaryHeader[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.RIGHT };
                      cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
                      row.getCell(2).font = {
                        name: ExcelFont.CENTURY_GOTHIC,
                        size: 9,
                      };
                      row.getCell(2).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    });
                  });

                  const footerRow = worksheet.addRow([]);
                  footerRow.getCell(2).value =
                    ExcelFooterHeader.CLOSING_BALANCE;
                  footerRow.getCell(2).font = {
                    name: ExcelFont.ARIAL,
                    size: 9,
                  };
                  footerRow.getCell(3).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_ob
                  );
                  footerRow.getCell(4).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_demand
                  );
                  footerRow.getCell(5).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_concession
                  );
                  footerRow.getCell(6).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_receivable
                  );
                  footerRow.getCell(7).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_received
                  );
                  footerRow.getCell(8).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_bal
                  );
                  footerRow.getCell(9).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0].fee_ldgr_refunds
                  );
                  footerRow.getCell(10).value = format(
                    data!.GetAcctLdgrYearlyDataSummation[0]
                      .fee_ldgr_dropout_demand
                  );

                  for (let i = 2; i <= 11; i++) {
                    footerRow.getCell(i).alignment = {
                      horizontal: ExcelAlignment.RIGHT,
                    };
                  }
                  worksheet.addConditionalFormatting({
                    ref: `C${footerRow.number}:J${footerRow.number}`,
                    rules: FOOTER_CSS,
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      ExcelSheetsNames.DEMAND_SUMMARY
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  if (error) return <>{error.message}</>;

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.AllYearsLedgerSummary.Table_Headers.map(
      (header) => ({
        headerName: header.headerName,
        className: header.cellClassName,
        field: header.field,
        headerAlign: header.headerAlign as GridAlignment,
        align: header.align as GridAlignment,
        flex: header.flex,
        hideable: header.hideable,
      })
    );
  const columns: GridColDef[] = [...dynamicHeaders];

  const rows: GridValidRowModel[] =
    (Ledgers ? Ledgers.GetAcctStudentDemandFeeSummary : []).map(
      (res, index) => {
        return {
          id: index + 1,
          ledger_desc: res.acct_ldgr_details.ldgr_desc,
          ob: format(res.fee_ldgr_ob),
          demand_amount: format(res.fee_ldgr_demand),
          concession: format(res.fee_ldgr_concession),
          receivable: format(res.fee_ldgr_receivable),
          received: format(res.fee_ldgr_received),
          refunds: format(res.fee_ldgr_refunds),
          balance: format(res.fee_ldgr_bal),
        };
      }
    ) || [];

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>All Years Ledger Summary</Title>
      <div className="ledger-summary">
        <div className={`ledger-summary__tableblock`}>
          {data
            ? data.GetAcctLdgrYearlyDataSummation.map((res, index) => {
                const isExpanded = expandedIndex === index;

                return (
                  <React.Fragment key={index}>
                    <div className="all-yr-fee__data--block">
                      <div
                        className="all-yr-fee__data--header "
                        onClick={() => handleToggle(index, res.fin_yr_id)}
                      >
                        <b className="all-yr-fee__data--header--b">
                          {finYearMap[res.fin_yr_id]}
                        </b>
                        <div className="all-yr-fee__data--header--flex">
                          <div className="all-yr-fee__data--header--grids">
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                OB
                              </span>
                              <b className="all-yr-fee__data--header--amount font-blue">
                                {res.fee_ldgr_ob ? format(res.fee_ldgr_ob) : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Demand
                              </span>
                              <b className="all-yr-fee__data--header--amount font-green">
                                {res.fee_ldgr_demand
                                  ? format(res.fee_ldgr_demand)
                                  : 0}
                              </b>
                            </div>

                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Concession
                              </span>
                              <b className="all-yr-fee__data--header--amount font-red">
                                {res.fee_ldgr_concession
                                  ? format(res.fee_ldgr_concession)
                                  : 0}
                              </b>
                            </div>

                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Receivable
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_receivable
                                  ? format(res.fee_ldgr_receivable)
                                  : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Received
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_received
                                  ? format(res.fee_ldgr_received)
                                  : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Refunds
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_refunds
                                  ? format(res.fee_ldgr_refunds)
                                  : 0}
                              </b>
                            </div>
                            <div className="all-yr-fee__data--header--block">
                              <span className="all-yr-fee__data--header--text">
                                Balance
                              </span>
                              <b className="all-yr-fee__data--header--amount font-grey">
                                {res.fee_ldgr_bal
                                  ? format(res.fee_ldgr_bal)
                                  : 0}
                              </b>
                            </div>
                          </div>
                          <div className="all-yr-fee__data--header--image">
                            {isExpanded ? (
                              <img src={DownArrow} alt="" />
                            ) : (
                              <img src={RightArrow} alt="" />
                            )}
                          </div>
                        </div>
                      </div>

                      {isExpanded ? (
                        <div className="all-yr-fee__data--contentAllYear">
                          <div className="all-yr-fee__data--contentAllYear--block">
                            <b>Ledger Summary</b>
                            <div
                              className={` all-yr-fee__data--contentAllYear--tableblock`}
                            >
                              <StyledDatagrid
                                columns={columns}
                                rows={rows}
                                disableRowSelectionOnClick
                                disableChildrenSorting
                                rowHeight={TABLE_ROW_HEIGHT}
                                hideFooter
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                );
              })
            : null}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button onClick={downloadPdf} mode="pdf" />

            <Button onClick={downloadExcel} mode="export" />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 ledger-summary__total">
            <div className="student-total-count">
              Total Years :&nbsp;
              <b>{data && data.GetAcctLdgrYearlyDataSummation.length!}</b>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal flag={loading || ledgerLoading} />
    </>
  );
};

export default AllYearsLedgerSummary;
