import { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AntSwitch } from "../../../pages/Switch";
import { toStandardCase, toStandardDate } from "../../../utils/UtilFunctions";
import { Button } from "../../../stories/Button/Button";
import { AppContext } from "../../../context/context";
import useEmpDetailsById from "../../HR/hooks/useEmpDetailsById";
import usePayRollMastersConfig from "../../HR/hooks/usePayRollMastersConfig";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddInstUserAccess, AddUserRights } from "../queries/mutation";
import useToken from "../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import { EduateModule, Operation } from "../../../utils/Enum.types";
import {
  MultipleUserRightsModalStyles,
  UserRightsModalStyles,
} from "../../../styles/ModalStyles";
import Modal from "react-modal";
import Close from "../../../images/Close.svg";
import Modules from "../../../images/Modules.svg";
import EditProfile from "../../../images/EditButton.svg";
import Delete from "../../../images/DeleteButton.svg";
import View from "../../../images/EyeWhite.svg";
import Add from "../../../images/Add.svg";
import MakeAsGlobalUser from "../../../images/MakeAsGlobalUser.svg";
import { UserRightsTypes } from "../constants/Enum.types";
import {
  GetMstInstActiveModulesData,
  GetMstInstActiveModulesDetails,
  GetMstInstActiveModulesVars,
} from "../hooks/useActiveInstModules";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../hooks/useAssignedInstbyEmpId";
import { GetMstInstActiveModules } from "../../../queries/customerModules/query";
import {
  GetUserRightsByEmpIdData,
  GetUserRightsByEmpIdVars,
} from "../hooks/useUserRightsByEmpId";
import useInstitutionsByCustId from "../../../customhooks/useInstitutionsByCustId";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import Input from "../../../stories/Input/Input";
import { GetInstUserAccess, GetUserRightsByEmpId } from "../queries/List";
import Edit from "../../../images/EditProfile.svg";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import ChatConfiguration from "./ChatConfiguration";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
import { payloadTypes } from "../../../context/reducer";

interface d extends GetMstInstActiveModulesData {
  enableModule: boolean;
  module_rights_type: string;
}

const Index = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [moduleId, setModuleId] = useState(0);
  const [modules, setModules] = useState<d[]>([]);
  const [moduleName, setModuleName] = useState("");
  const [instModal, setInstModal] = useState(false);
  const [eduinstModal, setEduInstModal] = useState(false);
  const { user_details } = useLoggedInUserDetails();
  const [is_inst_sysadmin, setIs_inst_sysadmin] = useState(false);

  const [assignedInstLoginUser, setAssignedInstLoginUser] = useState<
    responseType[]
  >([]);
  const [instunderCustomer, setInstunderCustomer] = useState<responseType[]>(
    []
  );
  // eslint-disable-next-line
  const [searchEmployee, setSearchEmployee] = useState("");
  const [openRightsModal, setOpenRightsModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const [instId, setInstId] = useState(0);

  const [GetActiveModules, { data, error, loading: AciveModulesLoading }] =
    useLazyQuery<GetMstInstActiveModulesDetails, GetMstInstActiveModulesVars>(
      GetMstInstActiveModules
    );

  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_INST_ID,
    0
  );

  const { InstDetails } = useInstDetails(1);
  const { Institutions } = useInstitutionsByCustId(
    InstDetails.data?.nodes[0].customer_details.id!,
    "",
    ROWS_PER_PAGE
  );
  const { MultipleInstitutions: InstitutionsAssignedForLoginUser } =
    useAssignedInstbyEmpId(
      InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
      state.empLoginId
    );

  const { MultipleInstitutions: InstitutionsAssignedForSelectedUser } =
    useAssignedInstbyEmpId(
      InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
      state.employeeId
    );

  const { employeeFormData } = useEmpDetailsById();
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();

  const userTypes: optionsType[] = Object.keys(UserRightsTypes).map((key) => ({
    label: toStandardCase(key),
    value: UserRightsTypes[key as keyof typeof UserRightsTypes],
  }));

  const filteredModule = modules?.find(({ id }) => id === moduleId);

  const [AddRights, { loading }] = useMutation(AddUserRights, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [AddAccess] = useMutation(AddInstUserAccess, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [GetDetails, { data: EmpData, loading: empLoading }] = useLazyQuery<
    GetUserRightsByEmpIdData,
    GetUserRightsByEmpIdVars
  >(GetUserRightsByEmpId, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (InstId && token && state.employeeId)
      GetDetails({
        variables: {
          inst_id: InstId!,
          pr_emp_id: state.employeeId,
          token,
        },
      });
  }, [InstId, GetDetails, state.employeeId, token]);
  const handleEnableModule = (moduleId: number) => {
    setModules((prevModules) =>
      prevModules.map((module) => {
        if (module.id === moduleId) {
          return {
            ...module,
            enableModule: !module.enableModule,
          };
        }
        return module;
      })
    );
    setModuleId(moduleId);
    setOpenRightsModal(true);
  };
  const handleAssignInstitution = (moduleId: number) => {
    setAssignedInstLoginUser((prevModules) =>
      prevModules.map((module) => {
        if (module.value === moduleId) {
          return {
            ...module,
            isChecked: !module.isChecked,
          };
        }
        return module;
      })
    );
  };
  const [modulRightType, setModuleRightType] = useState<
    UserRightsTypes | string
  >("");

  const handleImages = () => {
    switch (modulRightType) {
      case UserRightsTypes.SYSADMIN:
        return (
          <>
            <div>
              <img src={Add} alt="/" />
              <span>Add</span>
            </div>
            <div className="assign-user-rights__edit--icons--block--edit">
              <img src={EditProfile} alt="/" />
              <span>Edit</span>
            </div>
            <div>
              <img src={View} alt="/" />
              <span>View</span>
            </div>
            <div>
              <img src={Delete} alt="/" />
              <span>Delete</span>
            </div>
          </>
        );
      case UserRightsTypes.ADMIN:
        return (
          <>
            <div>
              <img src={Add} alt="/" />
              <span>Add</span>
            </div>
            <div className="assign-user-rights__edit--icons--block--edit">
              <img src={EditProfile} alt="/" />
              <span>Edit</span>
            </div>
            <div>
              <img src={View} alt="/" />
              <span>View</span>
            </div>
          </>
        );
      case UserRightsTypes.BASIC:
        return (
          <>
            <div>
              <img src={Add} alt="/" />
              <span>Add</span>
            </div>

            <div>
              <img src={View} alt="/" />
              <span>View</span>
            </div>
          </>
        );
      case UserRightsTypes.VIEW:
        return (
          <>
            <div>
              <img src={View} alt="/" />
              <span>View</span>
            </div>
          </>
        );
      default:
        break;
    }
  };
  const handleEduateAssignInstitution = (moduleId: number) => {
    setInstunderCustomer((prevModules) =>
      prevModules.map((module) => {
        if (module.value === moduleId) {
          return {
            ...module,
            isChecked: !module.isChecked,
          };
        }
        return module;
      })
    );
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    if (message.operation !== Operation.NONE) {
      setEduInstModal(false);
      setInstunderCustomer(
        instunderCustomer.map((i) => ({ ...i, isChecked: false }))
      );
    }
    // dispatch({
    //   type: payloadTypes.SET_EMPLOYEE_ID,
    //   payload: { employeeId: 0 },
    // });
  };

  const handleRights = () => {
    AddRights({
      variables: {
        token,
        inst_id: instId,
        user_details,
        pr_emp_id: state.employeeId,
        is_inst_sysadmin,
        input: modules
          .filter((module) => module.enableModule)
          .map(({ module_rights_type, id }) => ({
            module_rights_type,
            inst_module_id: id,
            is_default_module: false,
          })),
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully assigned rights",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleAssignInstitutions = (type: "EDU" | "INST") => {
    const inst_ids = (
      type === "EDU" ? instunderCustomer : assignedInstLoginUser
    )
      .filter((d) => d.isChecked)
      .map((d) => d.value);
    AddAccess({
      variables: {
        token,
        inst_ids,
        pr_emp_id: state.employeeId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetInstUserAccess,
          variables: {
            id: state.employeeId,
            query_type: InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
            token,
            after: null,
            first: ROWS_PER_PAGE,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully assigned rights",
          operation: Operation.CREATE,
        });
      }
    });
  };

  useEffect(() => {
    if (
      InstitutionsAssignedForLoginUser.data &&
      !InstitutionsAssignedForLoginUser.loading
    ) {
      setAssignedInstLoginUser(
        InstitutionsAssignedForLoginUser.data.GetInstUserAccess.edges.map(
          (d) => ({
            label: d.node.inst_details.inst_name,
            value: d.node.inst_details.id,
            isChecked: false,
          })
        )
      );
    }
  }, [
    InstitutionsAssignedForLoginUser.data,
    InstitutionsAssignedForLoginUser.loading,
  ]);

  useEffect(() => {
    if (Institutions.data && !Institutions.loading) {
      setInstunderCustomer(
        Institutions.data.GetInstsByCustId.edges.map((edge) => ({
          label: edge.node.inst_name,
          value: edge.node.id,
          isChecked: false,
        }))
      );
    }
  }, [Institutions.data, Institutions.loading]);

  useEffect(() => {
    if (instId) {
      GetActiveModules({
        variables: {
          inst_id: instId.toString(),
          token,
        },
      }).then(({ data }) => {
        if (data) {
          setModules(
            data.GetMstInstActiveModules.map((d) => ({
              ...d,
              enableModule: false,
              module_rights_type: "",
            }))
          );
        }
      });
    }
  }, [instId, token, GetActiveModules]);

  useEffect(() => {
    if (
      EmpData &&
      !empLoading &&
      data &&
      !AciveModulesLoading &&
      instId &&
      state.employeeId
    ) {
      const { user_rights_details } = EmpData.GetUserRightsByEmpId;
      const instModules = data?.GetMstInstActiveModules || [];

      const updatedModules = instModules.map((module) => {
        const matchingModule = user_rights_details.find(
          (detail) =>
            detail.inst_module_details.eduate_module_details.Name ===
            module.eduate_module_details.Name
        );

        return {
          ...module,
          enableModule: !!matchingModule,
          module_rights_type: matchingModule?.module_rights_type ?? "",
        };
      });

      setModules(updatedModules);
      setIs_inst_sysadmin(EmpData.GetUserRightsByEmpId.is_inst_sysadmin);
    }
  }, [
    EmpData,
    state.employeeId,
    data,
    instId,
    empLoading,
    AciveModulesLoading,
  ]);
  useEffect(() => {
    if (error) {
      setIs_inst_sysadmin(false);
    }
  }, [error]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>User Rights Configurations</Title>
      <div className="assign-user-rights">
        <div className="row g-0 assign-user-rights__select-options">
          <div className="col-4">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={MultipleInstitutions.employees}
              value={MultipleInstitutions.employees.find(
                ({ value }) => value === state.employeeId
              )}
              onChange={(e, newValue) => {
                if (newValue) {
                  dispatch({
                    type: payloadTypes.SET_EMPLOYEE_ID,
                    payload: { employeeId: (newValue as responseType).value },
                  });
                } else {
                  dispatch({
                    type: payloadTypes.SET_EMPLOYEE_ID,
                    payload: { employeeId: 0 },
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  label="Employee Name"
                  {...params}
                  onChange={(e) => {
                    setSearchEmployee(e.target.value);
                  }}
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={labelClasses.formControlRoot}
                />
              )}
            />

            <TextField
              label="Name."
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
              value={employeeFormData.emp_name}
              className="assign-user-rights__textfield"
            />
            {USE_HR_CATEGORY ? (
              <TextField
                label="Category."
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.category}
                className="assign-user-rights__textfield"
                disabled
              />
            ) : null}
          </div>
          <div className="col assign-user-rights__colspan">
            {USE_HR_DESIGNATION ? (
              <TextField
                label="Designation."
                className="assign-user-rights__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.designation}
                disabled
              />
            ) : null}
            {USE_HR_DEPARTMENT ? (
              <TextField
                className="assign-user-rights__textfield"
                label="Department."
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.department}
                disabled
              />
            ) : null}
            {USE_HR_JOBTYPE ? (
              <TextField
                label="Job Type."
                className="assign-user-rights__textfield"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={employeeFormData.job_type}
                disabled
              />
            ) : null}

            <TextField
              label="Date of Joining."
              className="assign-user-rights__textfield--date"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={
                toStandardDate(employeeFormData.emp_doj) ===
                toStandardDate(DEFAULT_TIME)
                  ? EMPTY_STRING
                  : toStandardDate(employeeFormData.emp_doj)
              }
              disabled
            />

            <TextField
              label="Years of Exp."
              className="assign-user-rights__textfield"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              value={employeeFormData.emp_experience}
              disabled
            />
            <TextField
              label="Qualification."
              className="assign-user-rights__textfield"
              value={employeeFormData.emp_qualification}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              disabled
            />
          </div>
        </div>

        <div className="row g-0 assign-user-rights__datablock">
          <div className="col assign-user-rights__datablock--left">
            <Title variant="subtitle1">Alloted Institutions</Title>
            <div className="assign-user-rights__tableblock">
              <TableContainer className="assign-user-rights__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="assign-user-rights__tableblock--table--th-slno">
                        Sl
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell className="assign-user-rights__tableblock--table--actions">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {InstitutionsAssignedForSelectedUser.data?.GetInstUserAccess.edges.map(
                      (edge, index) => {
                        const { inst_details } = edge.node;
                        return (
                          <TableRow key={inst_details.id}>
                            <TableCell
                              id="td-center"
                              className="assign-user-rights__tableblock--table--slno">
                              {index + 1}
                            </TableCell>
                            <TableCell>{inst_details.inst_name}</TableCell>
                            <TableCell
                              id="td-center"
                              className="assign-user-rights__tableblock--table--actions">
                              <img
                                src={Edit}
                                alt="/"
                                onClick={() => setInstId(inst_details.id)}
                              />
                              {/* <AntSwitch
                                checked={inst_details.id === instId}
                                onChange={() =>
                                
                                }
                              /> */}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="col assign-user-rights__datablock--right">
            <Title variant="subtitle1">
              <img src={MakeAsGlobalUser} alt="/" /> &nbsp; Make as Global User
              &nbsp;
              <AntSwitch
                checked={is_inst_sysadmin}
                onClick={() => setIs_inst_sysadmin(!is_inst_sysadmin)}
              />
            </Title>
            <div className="assign-user-rights__datablock--title">
              <img src={Modules} alt="/" />
              <b>Modules </b>
              {instunderCustomer.find((f) => f.value === instId)?.label}
            </div>
            <ul className="assign-user-rights__datablock--ul">
              {modules.map((module, index) => {
                const moduleKey = module.eduate_module_details.Name;
                return (
                  <li
                    key={module.id} // Use a unique identifier from the module object
                  >
                    <div className="assign-user-rights__datablock--ul--module-name">
                      <span>{`${index + 1})`}</span>
                      <b>{toStandardCase(moduleKey)} </b>
                      <span>
                        {`( ${
                          userTypes.find(
                            ({ value }) => value === module.module_rights_type
                          )?.label ?? ""
                        } )`}
                      </span>
                    </div>
                    <div>
                      <img
                        src={Edit}
                        alt="Edit Rights"
                        onClick={() => {
                          setModuleId(module.id);
                          setOpenRightsModal(!openRightsModal);
                          setModuleRightType(module.module_rights_type);
                          setModuleName(moduleKey);
                        }}
                      />
                      {/* <AntSwitch /> */}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <Button mode="save" onClick={handleRights} />
        {state.claims?.EMPLOYEE ? (
          <Button onClick={() => setInstModal(!instModal)}>
            Assign Institutions{" "}
          </Button>
        ) : null}
        {state.claims?.EDUATE ? (
          <Button onClick={() => setEduInstModal(!eduinstModal)}>
            Assign Institutions By Eduate
          </Button>
        ) : null}
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={openRightsModal}
        style={UserRightsModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="assign-user-rights__edit">
              <div className="assign-user-rights__edit--title">
                <b>
                  {toStandardCase(filteredModule?.eduate_module_details.Name!)}
                </b>
                <AntSwitch
                  checked={filteredModule?.enableModule}
                  onClick={() => handleEnableModule(moduleId)}
                />
              </div>
              <div className="assign-user-rights__edit--select">
                <LabeledAutocomplete
                  className={labelClasses.inputRoot}
                  options={userTypes}
                  value={userTypes.find(
                    ({ value }) =>
                      value ===
                      modules.find((module) => module.id === moduleId)
                        ?.module_rights_type
                  )}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setModules((prevModules) => {
                        const updatedModules = prevModules.map((module) => {
                          if (module.id === moduleId) {
                            return {
                              ...module,
                              module_rights_type: (newValue as optionsType)
                                .value,
                            };
                          }
                          return module;
                        });
                        return updatedModules;
                      });
                      setModuleRightType((newValue as optionsType).value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="User Rights"
                      {...params}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      className={labelClasses.formControlRoot}
                    />
                  )}
                />
              </div>
              <div className="assign-user-rights__edit--icons">
                {handleImages()}
              </div>

              <Button
                mode="okay"
                onClick={() => setOpenRightsModal(!openRightsModal)}
              />
              {moduleName === EduateModule.MESSAGEAPP && (
                <Button
                  onClick={() => {
                    setConfigurationModal(!configurationModal);
                  }}>
                  Module Configuration
                </Button>
              )}
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setOpenRightsModal(!openRightsModal);
                setModuleId(0);
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={instModal}
        style={MultipleUserRightsModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="assign-user-rights__for-multiple">
              <Title>Institution List</Title>
              <div className="row g-0">
                <div className="col-4">
                  <Input id="search" placeholder="Search..." />
                </div>
              </div>
              <div className="assign-user-rights__for-multiple--tableblock">
                <TableContainer className="assign-user-rights__for-multiple--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl</TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assignedInstLoginUser.map((edge, index) => {
                        return (
                          <TableRow key={edge.value}>
                            <TableCell
                              id="td-center"
                              className="assign-user-rights__for-multiple--table--slno">
                              {index + 1} &nbsp;
                              <Checkbox
                                onChange={() =>
                                  handleAssignInstitution(edge.value)
                                }
                                checked={edge.isChecked}
                                disabled={edge.value === state.InstId}
                              />
                            </TableCell>
                            <TableCell>{toStandardCase(edge.label)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Button
                mode="save"
                onClick={() => handleAssignInstitutions("INST")}
              />
              <Button mode="cancel" onClick={() => setInstModal(!instModal)} />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setInstModal(!instModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={eduinstModal}
        style={MultipleUserRightsModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="assign-user-rights__for-multiple">
              <Title>Institution List</Title>
              <div className="row g-0">
                <div className="col-4">
                  <Input id="search" placeholder="Search..." />
                </div>
              </div>
              <div className="assign-user-rights__for-multiple--tableblock">
                <TableContainer className="assign-user-rights__for-multiple--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl</TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {instunderCustomer.map((edge, index) => {
                        const found =
                          InstitutionsAssignedForSelectedUser.institutions.find(
                            (inst) => inst.value === edge.value
                          );
                        return (
                          <TableRow key={edge.value}>
                            <TableCell id="td-center">
                              {index + 1}
                              &nbsp;
                              <Checkbox
                                onChange={() =>
                                  handleEduateAssignInstitution(edge.value)
                                }
                                checked={found ? true : false || edge.isChecked}
                                disabled={
                                  edge.value === Number(InstId) || found
                                    ? true
                                    : false
                                }
                              />
                            </TableCell>
                            <TableCell>{toStandardCase(edge.label)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Button
                mode="save"
                disabled={!instunderCustomer.filter((i) => i.isChecked).length}
                onClick={() => handleAssignInstitutions("EDU")}
              />
              <Button
                mode="cancel"
                onClick={() => setEduInstModal(!eduinstModal)}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEduInstModal(!eduinstModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={MultipleUserRightsModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ChatConfiguration
              setModal={setConfigurationModal}
              empRightsId={state.employeeId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
