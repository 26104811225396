import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Title } from "../../../../stories/Title/Title";
import TotalStudents from "../../../../images/TotalMale.svg";
import Present from "../../../../images/Present.svg";
import Absent from "../../../../images/Absent.svg";
import AttendancePercentage from "../../../../images/AttendancePercentage.svg";
import Avatar from "../../../../images/Avatar.svg";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import Edit from "../../../../images/EditBlue.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { useNavigate, useParams } from "react-router-dom";
import { AntSwitch } from "../../../../pages/Switch";
import { PerStudentAttDrawer } from "../../../../styles/DrawerStyles";
import MonthlyOverview from "./MonthlyOverview";

import { AppContext } from "../../../../context/context";
import {
  AttendanceOverViewPageFor,
  AttendanceStatus,
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  EMPTY_STRING,
  muiCheckboxClassName,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import {
  getModifiedScrollHeight,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import { msgType } from "../../../../utils/Form.types";
import { MarkAttendanceSubjMonthly } from "../../queries/holidays/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import Home from "../../Home/Index";
import MessageModal from "../../../../pages/MessageModal";
import AbsenteesList from "./AbsenteesList";
import { payloadTypes } from "../../../../context/reducer";
import {
  GetAcdAttendanceSubjMonthly,
  GetAcdStdAttBySession,
  GetAcdStdsForAttendance,
} from "../../queries/holidays/list";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  StudentAcdNode,
  StudentEdges,
  StudentListEdges,
} from "../../hooks/useAcdStudentsData";
import CalendarForInput, {
  CustomisedInputFor,
} from "../../../../utils/CalendarForInput";
import useHolidaysList from "../../../../customhooks/useHolidaysList";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import Modal from "react-modal";
import { ViewPickedSubjectModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import SessionList from "../Attendance/SessionDetails/List";
import Compare from "../../../../images/Compare.svg";
import SessionsCompleted from "../../../../images/SessionsCompleted.svg";
import MarkAttendance from "../../../../images/MarkAttendance.svg";
import Success from "../../../../images/PositiveConfirmation.svg";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { GetAcdInstSessionsList } from "../../hooks/useAcdInstSessions";
import { PageInfo } from "../../../Channel/Types";
import { AcdSubjectMaster } from "../../types/test";
import {
  GetAcdAttSessionsBySubjectForTheDay,
  GetAcdMarkedSessionsForTheDay,
  GetAcdUnMarkedSessionsForTheDay,
  GetSubjAttPerSubjectPerSessionCount,
} from "../../queries/sessions/list";
import ViewPerStdAttendanceSession from "./ViewPerStdAttendanceSession";
import UnMarkedSessionList from "./SessionDetails/UnMarkedSessionList";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import {
  GetAcdStdsFromAttData,
  GetAcdStdsFromAttVars,
} from "../../types/attendance";
import { AttendenceQueryType } from "../../hooks/useAcdStudentAttendance";
import useAcdSubjectDetails from "../../hooks/useAcdSubjectDetails";
export interface GetAcdMarkedSessionsForTheDayList {
  session_id: number;
  session_desc: string;
  start_time: string;
  end_time: string;
  subj_master_id: number;
  subj_desc: string;
}
export interface GetAcdMarkedSessionsForTheDayData {
  GetAcdMarkedSessionsForTheDay: GetAcdMarkedSessionsForTheDayList[];
}
export interface GetAcdMarkedSessionsForTheDayVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  entry_id: string | number;
  entry_level: string;
  date_of_attendance: string;
  per_std_subj_allocation: boolean | undefined;
}
interface CustomDrawerProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}
const { Academics_Table } = require("../../json/table.json");
export interface SessionDetails {
  id: number;
  session_desc: string;
  start_time: string;
  end_time: string;
  session_idx: number;
}
export interface GetAcdAttendanceSubjMonthlyNode {
  id: number;
  cal_month: string;
  std_roll_no: number;
  day_1: string;
  day_2: string;
  day_3: string;
  day_4: string;
  day_5: string;
  day_6: string;
  day_7: string;
  day_8: string;
  day_9: string;
  day_10: string;
  day_11: string;
  day_12: string;
  day_13: string;
  day_14: string;
  day_15: string;
  day_16: string;
  day_17: string;
  day_18: string;
  day_19: string;
  day_20: string;
  day_21: string;
  day_22: string;
  day_23: string;
  day_24: string;
  day_25: string;
  day_26: string;
  day_27: string;
  day_28: string;
  day_29: string;
  day_30: string;
  day_31: string;
  acd_dept_id: number;
  acd_branch_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  student_id: number;
  acd_yr_id: number;
  subj_master_id: number;
  inst_mth_cal_id: number;
  inst_id: number;
  session_id: number;
  student_details: StudentAcdNode;
  subject_details: AcdSubjectMaster;
  session_details: SessionDetails;
}

export interface GetAcdAttendanceSubjMonthlyEdges {
  node: GetAcdAttendanceSubjMonthlyNode;
}
interface GetAcdAttendanceSubjMonthlyResult {
  totalCount: number;
  pageInfo: PageInfo;
  edges: GetAcdAttendanceSubjMonthlyEdges[];
}
export interface GetAcdAttendanceSubjMonthlyData {
  GetAcdAttendanceSubjMonthly: GetAcdAttendanceSubjMonthlyResult;
}
interface GetAcdAttendanceSubjMonthlyVars {
  token: string;
  input: {
    att_query_type: AcdStdAttSubjQueryType;
    entry_id: string;
    entry_level: string;
    cal_month: string;
    inst_id: string;
    acd_yr_id: number;
    subj_master_id: number;
    date_of_attendance: string;
    session_id: number;
    student_id: number;
  };
  name: string;
  per_std_subj_allocation: boolean | undefined;
  first: number | null;
  after: string | null;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
}
interface Props {
  pageType: AttendanceOverViewPageFor;
  selectedSubject: number;
}

interface GetAcdAttSessionsBySubjectForTheDayData {
  GetAcdAttSessionsBySubjectForTheDay: GetAcdInstSessionsList[];
}

interface GetAcdAttSessionsBySubjectForTheDayVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  subj_master_id: number;
  entry_level: string;
  entry_id: string;
  cal_month: string;
  date_of_attendance: string;
  per_std_subj_allocation: boolean | undefined;
}

interface GetSubjAttPerSubjectPerSessionCountData {
  GetSubjAttPerSubjectPerSessionCount: {
    total_students: number;
    total_present: number;
    total_absent: number;
    att_percentage: number;
    completed_session: number;
  };
}
interface GetSubjAttPerSubjectPerSessionCountVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  date_of_attendance: string;
  subj_master_id: number;
  session_id: number;
  entry_level: string;
  entry_id: number;
  per_std_subj_allocation: boolean | undefined;
}

export const enum AcdStdAttSubjQueryType {
  SUBJ_MONTHLY_ATT_PER_SUBJECT_PER_SESSION = "SUBJ_MONTHLY_ATT_PER_SUBJECT_PER_SESSION",
  SUBJ_MONTHLY_ATT_PER_STD = "SUBJ_MONTHLY_ATT_PER_STD",
  SUBJ_MONTHLY_ATT_PER_STD_PER_SUBJECT = "SUBJ_MONTHLY_ATT_PER_STD_PER_SUBJECT",
  SUBJ_MONTHLY_ATT_PER_STD_PER_SUBJECT_PER_SESSION = "SUBJ_MONTHLY_ATT_PER_STD_PER_SUBJECT_PER_SESSION",
  SUBJ_YEARLY_ATT_PER_STD = "SUBJ_YEARLY_ATT_PER_STD",
  SUBJ_YEARLY_ATT_PER_STD_PER_SUBJECT = "SUBJ_YEARLY_ATT_PER_STD_PER_SUBJECT",
  SUBJ_YEARLY_ATT_AT_ENTRY_LEVEL_PER_SUBJECT = "SUBJ_YEARLY_ATT_AT_ENTRY_LEVEL_PER_SUBJECT",
  OVER_ALL_ATT_AT_ENTRY_LEVEL = "OVER_ALL_ATT_AT_ENTRY_LEVEL",
  OVER_ALL_ATT_PER_STD = "OVER_ALL_ATT_PER_STD",
  ABSENTEES_AT_ENTRY_LEVEL = "ABSENTEES_AT_ENTRY_LEVEL",
  SOA_AT_ENTRY_LEVEL = "SOA_AT_ENTRY_LEVEL",
  SUBJ_MONTHLY_ATT_PER_SUBJECT = "SUBJ_MONTHLY_ATT_PER_SUBJECT",
}
const Index = ({ pageType, selectedSubject }: Props) => {
  const navigate = useNavigate();
  const { InstId, entryId, subjectId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state, dispatch } = useContext(AppContext);
  const { token } = useToken();
  const [editSelectSessionFlag, setEditSelectSessionFlag] = useState(false);
  const [sessionIdForUpdate, setSessionIdForUpdate] = useState(0);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [studentsWithAttendance, setStudentsWithAttendance] = useState<
    StudentListEdges[]
  >([]);

  const [studentsWithAtt, setStudentsWithAtt] = useState<
    GetAcdAttendanceSubjMonthlyEdges[]
  >([]);
  const { configData: absentFlagData } = useSwConfigData(
    InstitutionConfigurationTypes.OPTION_TO_MARK_ALL_STD_ABSENT
  );
  const { data: subjectData } = useAcdSubjectDetails(Number(subjectId!));

  const absentFlag =
    absentFlagData &&
    absentFlagData.data &&
    absentFlagData.data.GetSwConfigVariables.length &&
    absentFlagData.data.GetSwConfigVariables[0].config_boolean_value;
  const [markAllAbsent, setMarkAllAbsent] = useState(false);
  const [selectedSession, setSelectedSession] =
    useState<GetAcdInstSessionsList | null>(null);
  const [selectedQuerySession, setSelectedQuerySession] =
    useState<GetAcdInstSessionsList | null>(null);
  const [studentAttendanceDetails, setStudentAttendanceDetails] =
    useState<GetAcdAttendanceSubjMonthlyNode | null>(null);
  const [date, setDate] = useState(new Date());
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [attendanceFlag, setAttendanceFlag] = useState(false);
  const [monthlyOverView, setMonthlyOverView] = useState(false);
  const [absentiesList, setAbsentiesList] = useState(false);
  const [perStudentAttendance, setPerStudentAttendance] = useState(false);
  const [searchStudentName, setSearchStudentName] = useState("");
  const [selectSession, setSelectSession] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const studentRefs = useRef<HTMLButtonElement[]>([]);

  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enablePerSessionAtt = false;
    let enableTeacherAttEdit = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE:
            enablePerSessionAtt = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_TEACHER_TO_EDIT_ATTENDANCE:
            enableTeacherAttEdit = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enablePerSessionAtt,
      enableTeacherAttEdit,
    };
  };

  const { enablePerSessionAtt } = filterDataByConfigKey(
    configData.data ? configData.data.GetSwConfigVariables : []
  );
  const { flag } = useCheckAllocationType();

  const { serverDate } = useServerDateandTime();
  const { entry_level } = useInstitutionConfiguration();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const sessionsQuery = {
    query: GetAcdAttSessionsBySubjectForTheDay,
    variables: {
      token,
      date_of_attendance: toIsoDate(date.toString()),
      cal_month: toIsoDate(date.toString()),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: entryId!,
      entry_level: entry_level,
      inst_id: InstId!,
      per_std_subj_allocation: flag ? flag : false,
      subj_master_id: selectedSubject ? selectedSubject : Number(subjectId),
    },
  };
  const sessionUnmarkedQuery = {
    query: GetAcdUnMarkedSessionsForTheDay,
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_level: entry_level,
      entry_id: entryId ? entryId : 0,
      date_of_attendance: toIsoDate(date.toString()),
      per_std_subj_allocation: flag ? flag : false,
    },
  };
  const sessionAttBySession = {
    query: GetAcdStdAttBySession,
    variables: {
      token,
      inst_id: InstId,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      date_of_attendance: toIsoDate(date.toString()),
      student_id: state.studentId,
      session_id: selectedQuerySession ? selectedQuerySession.id : 0,
      subj_master_id: Number(subjectId),
      per_std_subj_allocation: flag ? flag : false,
    },
  };
  const sessionAttQuery = {
    query: GetAcdAttendanceSubjMonthly,
    variables: {
      token,
      input: {
        att_query_type:
          AcdStdAttSubjQueryType.SUBJ_MONTHLY_ATT_PER_SUBJECT_PER_SESSION,
        entry_id: entryId!,
        entry_level,
        cal_month: toIsoDate(date.toString()),
        inst_id: InstId!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        student_id: 0,
        subj_master_id: selectedSubject ? selectedSubject : Number(subjectId),
        date_of_attendance: toIsoDate(serverDate.toString()),
        session_id: selectedQuerySession ? selectedQuerySession.id : 0,
      },
      after: null,
      first: ROWS_PER_PAGE,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
    },
  };

  const [
    GetMarkedSessionData,
    { data: MarkedSessionData, error: markedSessionError },
  ] = useLazyQuery<
    GetAcdMarkedSessionsForTheDayData,
    GetAcdMarkedSessionsForTheDayVars
  >(GetAcdMarkedSessionsForTheDay, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_level: entry_level,
      entry_id: entryId ? entryId : 0,
      date_of_attendance: toIsoDate(date.toString()),
      per_std_subj_allocation: flag,
    },
  });
  const sessionAttQueryMarked = {
    query: GetAcdMarkedSessionsForTheDay,
    variables: {
      token,
      inst_id: InstId!,
      per_std_subj_allocation: flag ? flag : false,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_level: entry_level,
      entry_id: entryId ? entryId : 0,
      date_of_attendance: toIsoDate(date.toString()),
    },
  };

  const [GetStudentAcdList, { data: stdData, loading: stdLoading, error }] =
    useLazyQuery<GetAcdStdsFromAttData, GetAcdStdsFromAttVars>(
      GetAcdStdsForAttendance
    );
  const [
    GetAcdAttSubjMonthly,
    {
      data: monthlyAttData,
      loading: monthlyAttLoading,
      fetchMore: monthlyAttFetchMore,
    },
  ] = useLazyQuery<
    GetAcdAttendanceSubjMonthlyData,
    GetAcdAttendanceSubjMonthlyVars
  >(GetAcdAttendanceSubjMonthly, {
    variables: {
      token,
      name: searchStudentName,
      input: {
        att_query_type:
          AcdStdAttSubjQueryType.SUBJ_MONTHLY_ATT_PER_SUBJECT_PER_SESSION,
        entry_id: entryId!,
        entry_level,
        cal_month: toIsoDate(date.toString()),
        inst_id: InstId!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        student_id: 0,
        subj_master_id: selectedSubject ? selectedSubject : Number(subjectId),
        date_of_attendance: toIsoDate(serverDate.toString()),
        session_id: selectedQuerySession ? selectedQuerySession.id : 0,
      },
      per_std_subj_allocation: flag,
      after: null,
      first: null,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
    },
  });

  const dates = useHolidaysList(
    date,
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!
  );

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const [GetAcdAttSessionsByDate, { data: sessionData, error: sessionError }] =
    useLazyQuery<
      GetAcdAttSessionsBySubjectForTheDayData,
      GetAcdAttSessionsBySubjectForTheDayVars
    >(GetAcdAttSessionsBySubjectForTheDay, {
      variables: {
        token,
        date_of_attendance: toIsoDate(date.toString()),
        cal_month: toIsoDate(date.toString()),
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        entry_id: entryId!,
        entry_level: entry_level,
        inst_id: InstId!,
        subj_master_id: selectedSubject ? selectedSubject : Number(subjectId),
        per_std_subj_allocation: flag,
      },
    });

  const [GetSessionStats, { data: sessionStatesData }] = useLazyQuery<
    GetSubjAttPerSubjectPerSessionCountData,
    GetSubjAttPerSubjectPerSessionCountVars
  >(GetSubjAttPerSubjectPerSessionCount, {
    variables: {
      token,
      date_of_attendance: toIsoDate(date.toString()),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: Number(entryId),
      entry_level: entry_level,
      inst_id: InstId!,
      subj_master_id: selectedSubject ? selectedSubject : Number(subjectId),
      session_id: selectedQuerySession ? selectedQuerySession.id : 0,
      per_std_subj_allocation: flag,
    },
  });

  const [MarkAttendenceForSession, { loading: attendanceFrSessionLoading }] =
    useMutation(MarkAttendanceSubjMonthly, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !stdLoading) {
        if (
          selectedQuerySession &&
          selectedSession === null &&
          sessionData &&
          sessionData.GetAcdAttSessionsBySubjectForTheDay.length
        ) {
          monthlyAttFetchMore({
            variables: {
              first: ROWS_PER_PAGE,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges =
                fetchMoreResult.GetAcdAttendanceSubjMonthly.edges;
              const pageInfo =
                fetchMoreResult.GetAcdAttendanceSubjMonthly.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck =
                prevResult.GetAcdAttendanceSubjMonthly.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdAttendanceSubjMonthly: {
                  edges: [...studentsWithAtt!, ...newEdges],
                  pageInfo,
                  totalCount:
                    monthlyAttData?.GetAcdAttendanceSubjMonthly.totalCount!,
                },
              };
            },
          });
        }
      }
    }
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      studentRefs.current = [];
      handleClear();
      setAttendanceFlag(false);
      setMarkAllAbsent(false);
      if (pageType === AttendanceOverViewPageFor.ADMIN) {
        navigate(`/${InstId}/academics/attendance/foradmin`);
      }
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const AddRefs = (e: HTMLButtonElement) => {
    if (e && !studentRefs.current.includes(e)) {
      studentRefs.current.push(e);
    }
  };
  useEffect(() => {
    if (
      token &&
      entry_level &&
      activeAcademicYearData.data &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetAcdAttSessionsByDate();
    }
  }, [
    token,
    entry_level,
    activeAcademicYearData.data,
    selectedSubject,
    InstId,
    entryId,
    date,
    GetAcdAttSessionsByDate,
    state.ActiveAcdYr,
    flag,
  ]);
  const handleMarkingAttendence = () => {
    if (enablePerSessionAtt) {
      MarkAttendenceForSession({
        variables: {
          token,
          acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
          entry_level,
          entry_id: entryId,
          date_of_attendance: toIsoDate(date.toString()),
          absent_student_ids: studentsWithAttendance
            ?.filter((edge) => !edge.isChecked)
            .map((student) => student.id),
          emp_id: state.claims!.EDUATE ? 0 : state.empLoginId,
          marked_date: toIsoDate(serverDate),
          inst_id: InstId,
          user_details,
          session_id: selectedSession ? selectedSession.id : EMPTY_STRING,
          subj_master_id: selectedSubject ? selectedSubject : Number(subjectId),
          per_std_subj_allocation: flag,
        },
        refetchQueries: [
          sessionAttQuery,
          sessionsQuery,
          sessionAttQueryMarked,
          sessionUnmarkedQuery,
          sessionAttBySession,
        ],
      }).then(({ data }) => {
        if (data) {
          setSelectedSession(null);
          dispatch({
            type: payloadTypes.SET_SUBJECT_ID,
            payload: { subjectId: Number(subjectId!) },
          });

          setMessage({
            flag: true,
            message: `Sucessfully Updated Attendence for the day ${toStandardDate(
              date.toString()
            )}`,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (
      token &&
      state.ActiveAcdYr &&
      entryId &&
      subjectId &&
      entry_level &&
      flag !== undefined
    ) {
      GetStudentAcdList({
        variables: {
          token,
          input: {
            att_query_type: flag
              ? AttendenceQueryType.ATT_PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL
              : AttendenceQueryType.ATT_BY_ENTRY_LEVEL_AND_BY_SUBJ_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            subj_master_id: Number(subjectId),
            per_std_subj_allocation: flag!,
          },
        },
      });
    }
  }, [
    GetStudentAcdList,
    token,
    subjectId,
    InstId,
    state.ActiveAcdYr,
    entryId,
    flag,
    entry_level,
    searchStudentName,
    date,
  ]);

  const handleEditItem = (id: number) => {
    const index = studentsWithAttendance.findIndex((d) => d.id === id);
    if (index !== -1) {
      const newData: StudentAcdNode = {
        ...studentsWithAttendance[index],
        isChecked: !studentsWithAttendance[index].isChecked, // toggle isChecked property
      } as StudentAcdNode;
      const newStudents = [...studentsWithAttendance];
      newStudents[index] = newData;
      setStudentsWithAttendance(newStudents);
      // setMarkAllAbsent(false);
    }
  };
  useEffect(() => {
    if (serverDate) {
      setDate(new Date(serverDate));
    }
  }, [serverDate]);
  useEffect(() => {
    if (
      token &&
      entryId &&
      entry_level &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetMarkedSessionData();
    }
  }, [
    GetMarkedSessionData,
    token,
    entryId,
    entry_level,
    state.ActiveAcdYr,
    flag,
  ]);
  useEffect(() => {
    if (
      token &&
      activeAcademicYearData.data &&
      entry_level &&
      selectedQuerySession &&
      selectedSession === null &&
      state.ActiveAcdYr
    ) {
      GetAcdAttSubjMonthly();
    }
  }, [
    token,
    GetAcdAttSubjMonthly,
    activeAcademicYearData.data,
    entry_level,
    entryId,
    selectedSubject,
    date,
    selectedQuerySession,
    selectedSession,
    state.ActiveAcdYr,
    searchStudentName,
    flag,
  ]);
  useEffect(() => {
    if (stdData && !stdLoading && token) {
      const updatedStudents = stdData.GetAcdStdsForAttendance.map(
        (student: StudentListEdges) => ({
          ...student,
          isChecked: markAllAbsent ? false : true,
        })
      );
      setStudentsWithAttendance(updatedStudents);
    } // eslint-disable-next-line
  }, [stdData, stdLoading, token, markAllAbsent]);
  useEffect(() => {
    if (monthlyAttData && !monthlyAttLoading && token) {
      const newData = monthlyAttData.GetAcdAttendanceSubjMonthly.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: markAllAbsent ? false : true, // set default value of isChecked to true
          },
        })
      );

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) =>
              student.node.id ===
              (newStudent.node as GetAcdAttendanceSubjMonthlyNode).id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: markAllAbsent ? false : true,
              },
            };
          }
          return newStudent;
        });
        setStudentsWithAtt(
          updatedNewData as GetAcdAttendanceSubjMonthlyEdges[]
        );
      } else {
        setStudentsWithAtt(newData as GetAcdAttendanceSubjMonthlyEdges[]);
      }
      setEndCursor(
        monthlyAttData?.GetAcdAttendanceSubjMonthly.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [monthlyAttData, monthlyAttLoading, token, markAllAbsent]);

  const handleClear = () => {
    setDate(new Date(serverDate));
    setSelectedSession(null);
    setSelectedQuerySession(null);

    setStudents(
      students.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true,
        },
      }))
    );
  };

  const CustomDrawer = ({ open, onClose, children }: CustomDrawerProps) => {
    return (
      <PerStudentAttDrawer anchor="right" open={open} onClose={onClose}>
        {children}
      </PerStudentAttDrawer>
    );
  };
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(entryId!);
  useEffect(() => {
    if (
      token &&
      entry_level &&
      selectedQuerySession &&
      selectedSubject &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetSessionStats();
      setSelectedSession(null);
    }
  }, [
    token,
    entry_level,
    selectedQuerySession,
    entryId,
    InstId,
    activeAcademicYearData.data,
    date,
    selectedSubject,
    GetSessionStats,
    state.ActiveAcdYr,
    flag,
  ]);
  useEffect(() => {
    if (date) {
      setSelectedSession(null);
    }
  }, [date]);

  return (
    <>
      <div className="attendance-overview">
        {pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES ? null : (
          <Home DashBoardRequired={false} />
        )}

        {pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES ? (
          <div className="attendance-overview__title">
            <Title>Attendance OverView</Title>
            {sessionError ? (
              <b className="nodata">{sessionError.message}</b>
            ) : sessionData ? (
              sessionData.GetAcdAttSessionsBySubjectForTheDay.map((node) => {
                return (
                  <button
                    className={`attendance-overview__title--sessions${
                      selectedQuerySession &&
                      selectedQuerySession.id === node.id
                        ? "--active"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedQuerySession(node);
                    }}
                    disabled={selectedSession !== null}>
                    <img
                      src={Success}
                      alt=""
                      className="attendance-overview__title--sessions--success"
                    />{" "}
                    {node.session_desc}
                  </button>
                );
              })
            ) : null}
            {selectedSession ? (
              <button
                className={`attendance-overview__title--sessions--active`}>
                {selectedSession.session_desc}
                <img
                  src={Close}
                  alt=""
                  className="attendance-overview__title--sessions--close"
                  onClick={() => {
                    setSelectedSession(null);
                    setAttendanceFlag(false);
                  }}
                />
              </button>
            ) : null}
          </div>
        ) : (
          <>
            <Title>
              Attendance OverView &nbsp;
              {enablePerSessionAtt && subjectData && subjectData.node
                ? `(${subjectData.node.subj_desc} (${subjectData.node.subj_code}))`
                : EMPTY_STRING}
            </Title>
            <>
              <div className="attendance-overview__sessions">
                {sessionError ? (
                  <b className="nodata">{sessionError.message}</b>
                ) : sessionData ? (
                  sessionData.GetAcdAttSessionsBySubjectForTheDay.map(
                    (node) => {
                      return (
                        <button
                          className={`attendance-overview__title--sessions${
                            selectedQuerySession &&
                            selectedQuerySession.id === node.id
                              ? "--active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedQuerySession(node);
                          }}
                          disabled={selectedSession !== null}>
                          <img
                            src={Success}
                            alt=""
                            className="attendance-overview__title--sessions--success"
                          />{" "}
                          {node.session_desc}
                        </button>
                      );
                    }
                  )
                ) : null}
                {selectedSession ? (
                  <button
                    className={`attendance-overview__title--sessions--active`}>
                    {selectedSession.session_desc}
                    <img
                      src={Close}
                      alt=""
                      className="attendance-overview__title--sessions--close"
                      onClick={() => {
                        setSelectedSession(null);
                      }}
                    />
                  </button>
                ) : null}
              </div>
            </>
          </>
        )}

        <div
          className={
            pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES
              ? "row g-0 attendance-overview__data"
              : "row g-0 attendance-overview__data--admin"
          }>
          <div className="col h-100">
            {pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES ? (
              <div className="row g-0 attendance-overview__blocks">
                <div className="attendance-overview__blocks--cards">
                  <Title variant="subtitle1">
                    <img src={TotalStudents} alt="/" />
                    Total Students
                  </Title>
                  <b className="attendance-overview__blocks--cards--students">
                    {sessionStatesData
                      ? sessionStatesData.GetSubjAttPerSubjectPerSessionCount
                          .total_students
                      : stdData
                      ? stdData.GetAcdStdsForAttendance.length
                      : 0}
                  </b>
                </div>
                <div className="attendance-overview__blocks--cards">
                  <Title variant="subtitle1">
                    <img src={Present} alt="/" />
                    Total Present
                  </Title>
                  <b className="attendance-overview__blocks--cards--present">
                    {sessionStatesData
                      ? sessionStatesData.GetSubjAttPerSubjectPerSessionCount
                          .total_present
                      : 0}
                  </b>
                </div>
                <div className="attendance-overview__blocks--cards">
                  <Title variant="subtitle1">
                    <img src={Absent} alt="/" />
                    Total Absent
                  </Title>
                  <b className="attendance-overview__blocks--cards--absent">
                    {sessionStatesData
                      ? sessionStatesData.GetSubjAttPerSubjectPerSessionCount
                          .total_absent
                      : 0}
                  </b>
                </div>
                <div className="attendance-overview__blocks--cards">
                  <Title variant="subtitle1">
                    <img src={AttendancePercentage} alt="/" />
                    Atten. Percentage
                  </Title>
                  <b className="attendance-overview__blocks--cards--percentage">
                    {sessionStatesData
                      ? sessionStatesData.GetSubjAttPerSubjectPerSessionCount
                          .att_percentage
                      : 0}
                  </b>
                </div>
                {enablePerSessionAtt ? (
                  <div className="attendance-overview__blocks--cards">
                    <Title variant="subtitle1">
                      <img src={SessionsCompleted} alt="/" />
                      Completed Sessions
                    </Title>
                    <b className="attendance-overview__blocks--cards--sessions">
                      {sessionStatesData
                        ? sessionStatesData.GetSubjAttPerSubjectPerSessionCount
                            .completed_session
                        : 0}
                    </b>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="row g-0 attendance-overview__filters">
                {USE_DEPARTMENT_KEY && (
                  <div className="col-2">
                    <TextField
                      label={departmentLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_dept_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_BRANCH_KEY && (
                  <div className="col-2">
                    <TextField
                      label={branchLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_branch_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_CLASS_KEY && (
                  <div className="col-2">
                    <TextField
                      label={classLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_class_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_SEMESTER_KEY && (
                  <div className="col-2">
                    <TextField
                      label={semesterLabel}
                      value={
                        configdata?.GetAcdInstConfigNames.acd_semester_desc
                      }
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
                {USE_SECTION_KEY && (
                  <div className="col-2">
                    <TextField
                      label={sectionLabel}
                      value={configdata?.GetAcdInstConfigNames.acd_section_desc}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
              </div>
            )}

            <div className="row g-0">
              <div className="col-2">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => setSearchStudentName(e.target.value)}
                />
              </div>
              <div className="col">
                {enablePerSessionAtt ? (
                  <b className="daily-activities__select-block--title">
                    {selectedSession
                      ? `${selectedSession.session_desc} (${selectedSession.start_time} - ${selectedSession.end_time})`
                      : EMPTY_STRING}
                  </b>
                ) : null}
              </div>
              <div className="col flex-end">
                {enablePerSessionAtt && selectedSession && absentFlag ? (
                  <>
                    <b className="daily-activities__select-block--title">
                      Mark All Absent &nbsp;
                      <AntSwitch
                        checked={markAllAbsent}
                        onClick={() => setMarkAllAbsent(!markAllAbsent)}
                      />
                    </b>
                  </>
                ) : null}
              </div>
            </div>
            <div className="row g-0 attendance-overview__tableblock">
              {error ? (
                <b className="nodata">{error.message}</b>
              ) : (
                <TableContainer
                  className="attendance-overview__table"
                  onScroll={handleScroll}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {Academics_Table.DailyActivities.Attendance.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index}>{th.labelName}</TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedQuerySession &&
                      selectedSession === null &&
                      sessionData &&
                      sessionData.GetAcdAttSessionsBySubjectForTheDay.length
                        ? studentsWithAtt.map((edge, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className="attendance-overview__table--slno">
                                  {index + 1} &nbsp;{" "}
                                  <img src={Avatar} alt="/" />
                                </TableCell>
                                <TableCell className="attendance-overview__table--admno">
                                  {edge.node.student_details.std_adm_no}
                                </TableCell>
                                <TableCell className="attendance-overview__table--regno">
                                  {edge.node.student_details.std_reg_no}
                                </TableCell>
                                <TableCell
                                  className="attendance-overview__table--name"
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_STUDENT_ID,
                                      payload: {
                                        studentId: edge.node
                                          .student_id as number,
                                      },
                                    });
                                    setStudentAttendanceDetails(edge.node);
                                    setPerStudentAttendance(true);
                                  }}>
                                  {`${edge.node.student_details.first_name} ${edge.node.student_details.middle_name} ${edge.node.student_details.last_name}`}
                                </TableCell>

                                {
                                  <TableCell
                                    className={`attendance-overview__table--status font-${
                                      (
                                        edge.node as unknown as Record<
                                          string,
                                          string
                                        >
                                      )[`day_${date.getDate()}`] ===
                                      AttendanceStatus.P
                                        ? "green"
                                        : "red"
                                    }`}
                                    id="td-center">
                                    {
                                      (
                                        edge.node as unknown as Record<
                                          string,
                                          string
                                        >
                                      )[`day_${date.getDate()}`]
                                    }
                                  </TableCell>
                                }
                              </TableRow>
                            );
                          })
                        : studentsWithAttendance
                            .filter(
                              ({
                                first_name,
                                middle_name,
                                last_name,
                                std_adm_no,
                                std_reg_no,
                              }) =>
                                `${first_name} ${middle_name} ${last_name}`
                                  .toLocaleLowerCase()
                                  .includes(
                                    searchStudentName.toLocaleLowerCase()
                                  ) ||
                                std_adm_no
                                  .toLocaleLowerCase()
                                  .includes(
                                    searchStudentName.toLocaleLowerCase()
                                  ) ||
                                std_reg_no
                                  .toLocaleLowerCase()
                                  .includes(
                                    searchStudentName.toLocaleLowerCase()
                                  ) ||
                                searchStudentName === ""
                            )
                            .map((edge, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell className="attendance-overview__table--slno">
                                    {index + 1} &nbsp;{" "}
                                    <img src={Avatar} alt="/" />
                                  </TableCell>
                                  <TableCell className="attendance-overview__table--admno">
                                    {edge.std_adm_no}
                                  </TableCell>
                                  <TableCell className="attendance-overview__table--regno">
                                    {edge.std_reg_no}
                                  </TableCell>
                                  <TableCell className="attendance-overview__table--name">
                                    {`${edge.first_name} ${edge.middle_name} ${edge.last_name}`}
                                  </TableCell>

                                  {selectedSession ? (
                                    <TableCell
                                      className="attendance-overview__table--status"
                                      id="td-center">
                                      <AntSwitch
                                        checked={edge?.isChecked as boolean}
                                        onClick={() =>
                                          handleEditItem(edge?.id as number)
                                        }
                                        ref={AddRefs}
                                        onKeyDown={(e: React.KeyboardEvent) => {
                                          if (e.key === Keys.ENTER) {
                                            if (
                                              studentRefs.current[index + 1] &&
                                              index !== students.length - 1
                                            ) {
                                              (
                                                studentRefs.current[
                                                  index + 1
                                                ].getElementsByClassName(
                                                  muiCheckboxClassName
                                                )[0] as HTMLInputElement
                                              ).focus();
                                            }
                                          }
                                        }}
                                      />
                                    </TableCell>
                                  ) : (
                                    <TableCell id="td-center">-</TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>

          <div className="col-2 attendance-overview__rightblock">
            {/* <div className="attendance-overview__rightblock--date">
              <TextField
                       slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                inputProps={{
                  max: toInputStandardDate(serverDate),
                }}
                label="Date"
                type="date"
                value={toInputStandardDate(date.toString())}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDate(new Date(e.target.value));
                }}
                className="attendance-overview__rightblock--textfield"
              />
            </div> */}
            <CalendarForInput
              date={date}
              setDate={setDate}
              dates={dates}
              allowFutureDatesAccess={false}
              inputFor={CustomisedInputFor.GENERAL}
              label="Date"
            />
            {enablePerSessionAtt ? null : (
              <Button
                mode="absent"
                onClick={() => setAbsentiesList(!absentiesList)}>
                View Absenties List
              </Button>
            )}
            <Button
              mode="view-graph"
              onClick={() => setMonthlyOverView(!monthlyOverView)}>
              Monthly Overview
            </Button>
            <ul
              className={
                pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES
                  ? "attendance-overview__session-list"
                  : "attendance-overview__session-list--session"
              }>
              {markedSessionError ? (
                <b className="nodata">
                  {markedSessionError.message ===
                  "failed to find the session status"
                    ? "Attendance not marked for the day"
                    : markedSessionError.message}
                </b>
              ) : MarkedSessionData ? (
                MarkedSessionData.GetAcdMarkedSessionsForTheDay.map(
                  (
                    session: GetAcdMarkedSessionsForTheDayList,
                    index: number
                  ) => {
                    return (
                      <li className="attendance-overview__session-list--li">
                        <div className="attendance-overview__session-list--title">
                          <b>{session.session_desc}</b>
                          <img
                            src={Edit}
                            alt=""
                            onClick={() => {
                              setSessionIdForUpdate(session.session_id);
                              setEditSelectSessionFlag(true);
                            }}
                          />
                        </div>
                        <b>{`Subject :${session.subj_desc}`}</b>
                        <div className="attendance-overview__session-list--flex">
                          <TextField
                            label="Start Time"
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                              },
                            }}
                            className="attendance-overview__session-list--textfield"
                            value={session.start_time}
                          />
                          <img src={Compare} alt="" />
                          <TextField
                            label="End Time"
                            slotProps={{
                              inputLabel: {
                                shrink: true,
                              },
                            }}
                            className="attendance-overview__session-list--textfield"
                            value={session.end_time}
                          />
                        </div>
                      </li>
                    );
                  }
                )
              ) : null}
            </ul>
          </div>
        </div>
        <div
          className={
            pageType === AttendanceOverViewPageFor.DAILY_ACTIVITIES
              ? ""
              : "button-left"
          }>
          {!attendanceFlag && (
            <Button
              onClick={() => {
                setStudents(
                  students.map((edge) => ({
                    ...edge,
                    node: {
                      ...edge.node,
                      isChecked: true,
                    },
                  }))
                );
                setSelectSession(!selectSession);
              }}>
              <img src={MarkAttendance} alt="" /> Mark Attendance
            </Button>
          )}
          {attendanceFlag && (
            <Button
              mode="save"
              onClick={handleMarkingAttendence}
              disabled={
                (sessionData &&
                  sessionData.GetAcdAttSessionsBySubjectForTheDay.length ===
                    0 &&
                  selectedSession === null) ||
                selectedSession === null
              }>
              &nbsp;Attendance
            </Button>
          )}
          <Button
            mode="back"
            onClick={() => {
              handleClear();
              navigate(-1);
            }}
          />
        </div>
        <CustomDrawer
          open={monthlyOverView}
          onClose={() => setMonthlyOverView(!monthlyOverView)}>
          <MonthlyOverview
            selectedDate={date}
            setSelectedDate={setDate}
            setDrawerFlag={setMonthlyOverView}
            pageType={PageFor.GENERAL}
          />
        </CustomDrawer>
        <CustomDrawer
          open={absentiesList}
          onClose={() => setAbsentiesList(!absentiesList)}>
          <AbsenteesList setModalFlag={setAbsentiesList} date={date} />
        </CustomDrawer>
        <CustomDrawer
          open={perStudentAttendance}
          onClose={() => setPerStudentAttendance(!perStudentAttendance)}>
          <ViewPerStdAttendanceSession
            selectedDate={date}
            setSelectedDate={setDate}
            studentAttendanceDetails={studentAttendanceDetails}
            setPerStudentAttendance={setPerStudentAttendance}
            perStudentAttendance={perStudentAttendance}
            selectedQuerySession={selectedQuerySession}
          />
        </CustomDrawer>
      </div>
      <LoadingModal flag={stdLoading || attendanceFrSessionLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={selectSession}
        style={ViewPickedSubjectModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <UnMarkedSessionList
              setModalFlag={setSelectSession}
              selectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              attDate={date}
              setAttendanceFlag={setAttendanceFlag}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setSelectSession(false)} />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={editSelectSessionFlag}
        style={ViewPickedSubjectModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <SessionList
              setModalFlag={setEditSelectSessionFlag}
              selectedSession={sessionIdForUpdate}
              attDate={date}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setEditSelectSessionFlag(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
