import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { TextField } from "@mui/material";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../styles/DataGridTableStyles";
import Close from "../../../images/Close.svg";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdFeedbackFormsData,
  GetAcdFeedbackFormsVars,
  GetAcdFeedbackFromDetailsData,
  GetAcdFeedbackFromDetailsVars,
} from "../types/subject/Subject";
import {
  GetAcdFeedbackFormDetails,
  GetAcdFeedbackForms,
} from "../queries/general";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
} from "../../../utils/Enum.types";
import { isOptionEqualToValue, toIsoDate } from "../../../utils/UtilFunctions";
import { EMPTY_STRING, TODAY_DATE } from "../../../utils/constants";
import { responseType } from "../../../utils/Form.types";
import {
  GetAcdClassByInstIdData,
  GetAcdSemesterByInstIdData,
} from "../types/genreal";
import { DepartmentListVarsByInstId } from "../../../Types/Student";
import { GetAcdClassesByInstId } from "../queries/instdetails/class/byId";
import { GetAcdSemestersByInstId } from "../queries/instdetails/semester/byId";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../styles/AutocompleteListStyles";
interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const View = ({ setModalFlag }: props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const [classId, setClassId] = useState<responseType | null>(null);
  const [semId, setSemId] = useState<responseType | null>(null);
  const [formSelected, setFormSelected] = useState<responseType | null>(null);

  const { classLabel, semesterLabel } = useInstLabels();

  const [GetSemestersByInstId, { data: semData }] = useLazyQuery<
    GetAcdSemesterByInstIdData,
    DepartmentListVarsByInstId
  >(GetAcdSemestersByInstId);
  useEffect(() => {
    if (InstId && token) {
      GetSemestersByInstId({
        variables: {
          token,
          inst_id: InstId,
        },
      });
    }
  }, [InstId, token, GetSemestersByInstId]);
  const semDropDown =
    semData &&
    semData.GetAcdSemestersByInstId.map((data) => ({
      label: data.sem_desc,
      value: data.id,
    }));
  const [GetClassesByInstId, { data: classData }] = useLazyQuery<
    GetAcdClassByInstIdData,
    DepartmentListVarsByInstId
  >(GetAcdClassesByInstId);
  const classDropDown =
    classData &&
    classData.GetAcdClassesByInstId.map((data) => ({
      label: data.class_desc,
      value: data.id,
    }));
  useEffect(() => {
    if (InstId && token) {
      GetClassesByInstId({
        variables: {
          token,
          inst_id: InstId,
        },
      });
    }
  }, [InstId, token, GetClassesByInstId]);
  const columns: GridColDef[] = [
    {
      headerName: "Sl No.",
      field: "id",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",

      align: SLNO_TEXT_ALIGN,
    },

    {
      field: "rating_factor",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
      headerName: "Rating Factor",
      flex: 1,
      hideable: false,
    },
  ];

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const subjectAllocationLevel =
    configData && configData.data?.GetSwConfigVariables[0].config_string_value;
  const [GetAcdFeedbackFormDropDown, { data: FormDropDown }] = useLazyQuery<
    GetAcdFeedbackFormsData,
    GetAcdFeedbackFormsVars
  >(GetAcdFeedbackForms, {
    variables: {
      acd_yr_id: activeAcdId ? activeAcdId : 0,
      inst_id: InstId!,
      token,
      allotted_level: subjectAllocationLevel!,
      allotted_id:
        subjectAllocationLevel === AcdSubjectAllocationData.CLASS
          ? classId?.value!
          : subjectAllocationLevel === AcdSubjectAllocationData.SEMESTER
          ? semId?.value!
          : 0!,
      today_date: toIsoDate(TODAY_DATE),
      query_type: "ALL_FORMS",
      pr_emp_id: 0,
    },
  });
  const [GetAcdFeedbackFrom, { data }] = useLazyQuery<
    GetAcdFeedbackFromDetailsData,
    GetAcdFeedbackFromDetailsVars
  >(GetAcdFeedbackFormDetails, {
    variables: {
      acd_yr_id: activeAcdId ? activeAcdId : 0,
      inst_id: InstId!,
      token,
      form_name: formSelected ? formSelected.label : EMPTY_STRING,
    },
  });
  const rows: GridValidRowModel[] = (
    (data && data.GetAcdFeedbackFormDetails) ||
    []
  ).map((edge, index) => {
    return {
      id: edge.idx,
      rating_factor: edge.rating_factor,
    };
  });
  const formDropDown = FormDropDown
    ? FormDropDown.GetAcdFeedbackForms.map((data) => ({
        label: data.form_name,
        value: data.id,
      }))
    : [];
  useEffect(() => {
    if ((classId || semId) && subjectAllocationLevel) {
      GetAcdFeedbackFormDropDown();
    }
  }, [classId, semId, subjectAllocationLevel, GetAcdFeedbackFormDropDown]);
  useEffect(() => {
    if ((classId || semId) && formSelected && subjectAllocationLevel) {
      GetAcdFeedbackFrom();
    }
  }, [
    classId,
    semId,
    formSelected,
    subjectAllocationLevel,
    GetAcdFeedbackFrom,
  ]);
  return (
    <>
      <div className="view-rating-factor">
        <div className="view-rating-factor__title">
          <Title>View Rating Factor</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>

        <div className="row g-0 view-rating-factor__select">
          {subjectAllocationLevel === AcdSubjectAllocationData.CLASS && (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={classDropDown!}
                openOnFocus
                forcePopupIcon
                value={classId}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, classId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassId({
                      label: (newValue as responseType).label,
                      value: (newValue as responseType).value,
                      isChecked: true,
                    });
                  } else {
                    setClassId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={classLabel}
                  />
                )}
              />
            </div>
          )}
          {subjectAllocationLevel === AcdSubjectAllocationData.SEMESTER && (
            <div className="col-2">
              <LabeledAutocomplete
                className={labelClasses.inputRoot}
                options={semDropDown!}
                openOnFocus
                forcePopupIcon
                value={semId}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option as responseType, semId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemId({
                      label: (newValue as responseType).label,
                      value: (newValue as responseType).value,
                      isChecked: true,
                    });
                  } else {
                    setSemId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={labelClasses.formControlRoot}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    label={semesterLabel}
                  />
                )}
              />
            </div>
          )}

          {/* <div className="col-2">
                {subjectAllocationLevel ===
                  AcdSubjectAllocationData.SECTION && (
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={semDropDown}
                    openOnFocus
                    forcePopupIcon
                    value={semId}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, semId)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSemId({
                          label: newValue.label,
                          value: newValue.value,
                          isChecked: true,
                        });
                      } else {
                        setSemId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className={labelClasses.formControlRoot}
                               slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
                        label={semesterLabel}
                      />
                    )}
                  />
                )}
              </div> */}
          <div className="col-2">
            <LabeledAutocomplete
              className={labelClasses.inputRoot}
              options={formDropDown!}
              openOnFocus
              value={formSelected}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option as responseType, semId)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setFormSelected({
                    label: (newValue as responseType).label,
                    value: (newValue as responseType).value,
                    isChecked: true,
                  });
                } else {
                  setFormSelected(null);
                }
              }}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={labelClasses.formControlRoot}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  label="Form Name"
                />
              )}
            />
          </div>
        </div>
        <div className="feed-back__total">
          <span className="feed-back__total--left">0</span>
          <span>/</span>

          <span className="feed-back__total--right">15</span>
        </div>
        <div className={`view-rating-factor__tableblock`}>
          <StyledDatagrid
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
          />
        </div>

        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default View;
