import React, { useContext, useEffect, useState } from "react";
import List from "./List";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import InstDetails from "../../DailyActivities/InstDetails";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import {
  GetnumberOfDaysInMonth,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import Input from "../../../../stories/Input/Input";
import {
  GetAcdStdSubjAttOverAll,
  GetAcdStudentsAttendance,
} from "../../queries/holidays/list";
import { useLazyQuery } from "@apollo/client";
import {
  AttendenceQueryType,
  GetAcdStdSubjAttOverAllData,
} from "../../hooks/useAcdStudentAttendance";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import {
  GetAcdAttendanceMonthlyForStdsData,
  GetAcdStudentsAttendanceVars,
} from "../../DailyActivities/Attendance/AbsenteesList";
import { msgType } from "../../../../utils/Form.types";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";

import MessageModal from "../../../../pages/MessageModal";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { GetAcdStdSubjAttOverAllVars } from "../../types/holidays";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
const SubjectListView = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const [date, setMonthDate] = useState(new Date());
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { serverDate } = useServerDateandTime();

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const numberOfDaysInMonth = GetnumberOfDaysInMonth(date.toString());

  const daysArray = Array.from({ length: numberOfDaysInMonth }, (_, i) => {
    const dayIndex =
      (i + new Date(date.getFullYear(), date.getMonth(), 1).getDay()) % 7;
    return { day: days[dayIndex], date: dayIndex };
  });
  const { entry_level, USE_SECTION_KEY, USE_CLASS_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();

  const [GetStdDataAttendance, { data: stdAttData, loading }] = useLazyQuery<
    GetAcdAttendanceMonthlyForStdsData,
    GetAcdStudentsAttendanceVars
  >(GetAcdStudentsAttendance);
  const [
    GetOverAllSubjectAttendance,
    { data: OverSubjectData, loading: SubjectLoading },
  ] = useLazyQuery<GetAcdStdSubjAttOverAllData, GetAcdStdSubjAttOverAllVars>(
    GetAcdStdSubjAttOverAll,
    {
      variables: {
        input: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          entry_id: Number(entryId),
          entry_level: entry_level,
          inst_id: state.InstId!,
          cal_month: toIsoDate(serverDate),
          subj_master_id: 0,
          session_id: 0,
          student_id: 0,
          att_query_type: "SUBJ_YEARLY_ATT_AT_ENTRY_LEVEL_PER_SUBJECT",
          date_of_attendance: toIsoDate(serverDate),
        },
        token,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.STD_ROLL_NO,
        },
        after: null,
        first: null,
        name: EMPTY_STRING,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (token && state.ActiveAcdYr) {
      GetOverAllSubjectAttendance();
    }
  }, [GetOverAllSubjectAttendance, token, state.ActiveAcdYr]);
  useEffect(() => {
    if (token && entryId) {
      GetStdDataAttendance({
        variables: {
          token,
          input: {
            att_query_type: USE_SECTION_KEY
              ? AttendenceQueryType.ATT_STD_BY_SECTION_ID
              : USE_SEMESTER_KEY
              ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
              : USE_CLASS_KEY
              ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
              : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(TODAY_DATE),
            subj_master_id: 0,
            per_std_subj_allocation: false,
          },
        },
      });
    }
  }, [token, entryId, GetStdDataAttendance, date]);

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "std_name",
      headerName: "Student Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      hideable: false,
    },
    {
      field: "std_adm_no",
      headerName: "Student Name",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
    },
  ];

  const rows: GridValidRowModel[] =
    (stdAttData?.GetAcdStudentsAttendance || []).map((data, index) => {
      return {
        id: index + 1,
        std_name:
          data.student_details.first_name +
          " " +
          data.student_details.middle_name +
          " " +
          data.student_details.last_name,
        std_adm_no: data.student_details.std_adm_no,
      };
    }) || [];

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="view-overall-att">
        <div className="view-overall-att__left">
          <List />
        </div>
        <div className="view-overall-att__right">
          <InstDetails />
          <div className="view-overall-att__select row g-0">
            <div className="col-2">
              <Input id="search" placeholder="Search..." />
            </div>
            <div className="col"> </div>
            <div className="col-2 flex-end">
              <div className="student-total-count">
                Total Student :{" "}
                <b>{stdAttData?.GetAcdStudentsAttendance.length}</b>
              </div>
            </div>
          </div>

          <div className={`view-overall-att__monthly`}>
            <StyledDatagrid
              columns={columns}
              rows={rows}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
            />
          </div>
          <Button mode="excel">Export</Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default SubjectListView;
