import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useMutation, useLazyQuery } from "@apollo/client";

import Modal from "react-modal";
import React, { useEffect, useState } from "react";

import { Title } from "../../../../stories/Title/Title";

import { msgType, semesterTypes } from "../../../../utils/Form.types";
import InstitutionTabs from "../../routes/InstitutionTabs";
import { AddInstSemester } from "../../../../queries/institution/mutations/new";
import {
  ReOrderInstSemester,
  UpdateInstSemester,
} from "../../../../queries/institution/mutations/update";

import Input from "../../../../components/common/Input/Index";
import { Button } from "../../../../stories/Button/Button";
import {
  SemestersByClassIds,
  SemesterDetailsByNode,
} from "../../../../queries/institution/sem/list/byid";

import { Form, Formik } from "formik";
import { semValidation } from "../../../../utils/validationRules";
import { Operation, PageNumbers } from "../../../../utils/Enum.types";

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import {
  removeMoreSpace,
  reOrderProcess,
} from "../../../../utils/UtilFunctions";
import { ByNode as DeptByDeptId } from "../../../../queries/institution/department/list/byid";
import { BranchDetailsByNode } from "../../../../queries/institution/branch/list/byid";
import { ByNode as ClassBySemId } from "../../../../queries/institution/classes/list/byid";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { InstitutionDetailsFormLabelsType } from "../../../../Types/Institution/json";

import { TableHeaderProps } from "../../../../Types/Tables";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../../styles/ModalStyles";

import { Breadcrumbs } from "@mui/material";
import LoadingModal from "../../../../pages/LoadingModal";
import Edit from "../../../../images/EditProfile.svg";
import DeleteImg from "../../../../images/Delete.svg";
import { DeleteInstSemesterById } from "../../../../queries/institution/mutations/delete";

import { NavLink } from "react-router-dom";
import DeleteModal from "../../../../pages/DeleteModal";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import Settings from "../../../../images/Settings.svg";
import ConfigurationSettings from "../../configurations/general/Index";
import Close from "../../../../images/Close.svg";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import {
  SemesterList,
  SemesterListDataByClassId,
  SemesterListVarsByClassId,
} from "../../../../Types/Student";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import TreeView from "../TreeView";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
const { SemesterFormLabels } = require("../../../../json/config.json");

const Semester = () => {
  //useParam, GlobalStates and useNavigate
  const { InstId, deptId, branchId, classId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();

  const { USE_ADMISSIONS } = useUserRightsByEmpId();
  const { user_details } = useLoggedInUserDetails();

  //useStates for Data
  const [formData, setFormData] = useState<semesterTypes>({
    sem_desc: "",
    shortdescription: "",
    indexnumber: "0",
  });
  const nameRef = document.getElementsByName("sem_desc")[0] as HTMLInputElement;
  const [semesterId, setSemesterId] = useState(0);
  const [localItems, setLocalItems] = useState<SemesterList[]>([]);
  // eslint-disable-next-line
  const [item, setitems] = useState<SemesterList[]>([]);

  //useStates for Flags
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reOrder, setReOrder] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);

  const [configurationModal, setConfigurationModal] = useState(false);

  //flags for sucess model
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //Queries
  const [GetDepartments, { data: DeptByNodeId, loading: DeptByNodeIdLoading }] =
    useLazyQuery(DeptByDeptId, {
      variables: { dept_id: deptId, token },
    });
  const [
    GetBranches,
    { data: BranchByNodeId, loading: BranchByNodeIdLoading },
  ] = useLazyQuery(BranchDetailsByNode, {
    variables: { branch_id: branchId, token },
  });
  const [GetClasses, { data: ClassByNodeId, loading: ClassByNodeIdLoading }] =
    useLazyQuery(ClassBySemId, {
      variables: { class_id: classId, token },
    });
  //Lazy Queries
  const [SemesterDataById, { data, loading }] = useLazyQuery(
    SemesterDetailsByNode
  );

  //Mutation
  const [NewSemester, { loading: creationLoading }] = useMutation(
    AddInstSemester,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateSem, { loading: updationLoading }] = useMutation(
    UpdateInstSemester,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [reorderSem, { loading: reorderLoading }] = useMutation(
    ReOrderInstSemester,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [deleteSem, { loading: deleteLoading }] = useMutation(
    DeleteInstSemesterById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [
    GetSemestersByClass,
    { data: semesterData, loading: semesterLoading },
  ] = useLazyQuery<SemesterListDataByClassId, SemesterListVarsByClassId>(
    SemestersByClassIds,
    {
      variables: {
        token,
        class_ids: [Number(classId)],
      },
    }
  );

  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY, USE_CLASS_KEY, USE_SECTION_KEY } =
    useInstitutionConfiguration();

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(PageNumbers.CATEGORY_PAGE);

  useEffect(() => {
    if (!loading && data && operation === Operation.UPDATE) {
      const { sem_desc, sem_idx, sem_short_desc } = data.node;
      setFormData({
        sem_desc: sem_desc,
        shortdescription: sem_short_desc,
        indexnumber: sem_idx,
      });
    }
  }, [loading, data, operation]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setitems(temp);
      return temp;
    });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const HandleSubmit = async () => {
    // backend api call
    await NewSemester({
      variables: {
        token,
        input: {
          inst_id: InstId,
          class_id: classId,
          sem_desc: removeMoreSpace(formData.sem_desc),
          sem_short_desc: removeMoreSpace(formData.shortdescription),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: SemestersByClassIds,
          variables: {
            token,
            class_ids: [Number(classId)!],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Semester Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setFormData({
      sem_desc: "",
      shortdescription: "",
      indexnumber: "0",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const HandleEdit = async () => {
    // backend api call
    await updateSem({
      variables: {
        token,
        id: semesterId,
        sem_desc: removeMoreSpace(formData.sem_desc),
        sem_short_desc: removeMoreSpace(formData.shortdescription),
        sem_idx: Number(formData.indexnumber),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: SemestersByClassIds,
          variables: {
            token,
            class_ids: [classId!],
          },
        },
        {
          query: SemesterDetailsByNode,
          variables: {
            token,
            semester_id: semesterId,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Semester Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setFormData({
      sem_desc: "",
      shortdescription: "",
      indexnumber: "0",
    });
    setOperation(Operation.CREATE);
    setAddModal(!addModal);
  };
  const HandleDelete = async (sem_id: number) => {
    setDeleteModal(!deleteModal);
    // backend api call
    await deleteSem({
      variables: {
        token,
        sem_id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: SemestersByClassIds,
          variables: {
            token,
            class_ids: [Number(classId)!],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Semester Deleted Successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setOperation(Operation.CREATE);
    handleClear();
  };

  const handleSaveOrder = () => {
    // eslint-disable-next-line
    reorderSem({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          id: res.id,
          sem_idx: index + 1,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: SemestersByClassIds,
          variables: {
            token,
            class_ids: [Number(classId)!],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re-Order Successful",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setReOrder(!reOrder);
  };

  const handleClose = () => {
    //enableAdd and enableEdit should stay false when you close the success modal
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    nameRef?.focus();
  };
  const handleClear = () => {
    setFormData({
      sem_desc: "",
      shortdescription: "",
      indexnumber: "0",
    });
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };

  useEffect(() => {
    if (semesterData && !semesterLoading && token)
      setLocalItems(
        semesterData?.GetSemestersByClassIds?.filter((f) => f !== null)
      );
    // eslint-disable-next-line
  }, [semesterData, semesterLoading, token]);
  useEffect(() => {
    if (token) {
      GetDepartments();
    }
  }, [token, GetDepartments]);
  useEffect(() => {
    if (token) {
      GetBranches();
    }
  }, [token, GetBranches]);
  useEffect(() => {
    if (token) {
      GetClasses();
    }
  }, [token, GetClasses]);
  useEffect(() => {
    if (token) {
      GetSemestersByClass();
    }
  }, [token, GetSemestersByClass]);
  const { semesterLabel } = useInstLabels();
  return (
    <>
      <InstitutionTabs />

      <div className="row g-0">
        <div className="col">
          <Title>{semesterLabel}</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>

      <div className="row g-0">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb" className="inst-breadcrumbs">
            <NavLink to={`/${InstId}/admissions/department`}>
              {USE_DEPARTMENT_KEY &&
                !DeptByNodeIdLoading &&
                DeptByNodeId &&
                DeptByNodeId.node.dept_desc}
            </NavLink>
            <NavLink
              to={`/${InstId}/admissions/department/${DeptByNodeId?.node.id}/branch`}
            >
              {USE_BRANCH_KEY &&
                !BranchByNodeIdLoading &&
                BranchByNodeId &&
                BranchByNodeId.node.branch_desc}
            </NavLink>
            <NavLink
              to={`/${InstId}/admissions/department/${DeptByNodeId?.node.id}/branch/${BranchByNodeId?.node.id}/class`}
            >
              {USE_CLASS_KEY &&
                !ClassByNodeIdLoading &&
                ClassByNodeId &&
                ClassByNodeId.node.class_desc}
            </NavLink>
          </Breadcrumbs>
        </div>
      </div>

      <div className="masterconfig">
        <div className="row g-0 masterconfig__details">
          <div className="col booktype-left h-100">
            <Title variant="subtitle1">List of {semesterLabel}</Title>

            <TableContainer className="masterconfig__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {reOrder ? <TableCell></TableCell> : null}
                    {Masters_Table.InstitutionDetails.Semesters.Table_Headers.filter(
                      ({ labelName }: TableHeaderProps) =>
                        !(labelName === "Actions" && reOrder)
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <TableCell className={th.className}>
                            {th.labelName}
                          </TableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {localItems?.map((response, index: number) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!reOrder}
                          >
                            {(
                              draggableProvided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => {
                              return (
                                <React.Fragment key={index}>
                                  <TableRow
                                    key={index}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                  >
                                    {reOrder ? (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                        id="td-center"
                                        className="institution__table--slno"
                                      >
                                        =
                                      </TableCell>
                                    ) : null}
                                    <TableCell
                                      id="td-center"
                                      {...draggableProvided.dragHandleProps}
                                      className="institution__table--slno"
                                    >
                                      {index + 1}
                                    </TableCell>

                                    {USE_SECTION_KEY ? (
                                      <TableCell
                                        {...draggableProvided.dragHandleProps}
                                        onClick={() =>
                                          navigate(`${response.id}/section`)
                                        }
                                        className="institution__table--name"
                                      >
                                        {response.sem_desc}
                                      </TableCell>
                                    ) : (
                                      <TableCell>{response.sem_desc}</TableCell>
                                    )}
                                    {reOrder === false ? (
                                      <TableCell
                                        id="td-center"
                                        {...draggableProvided.dragHandleProps}
                                        className="institution__table--action"
                                      >
                                        <>
                                          {USE_ADMISSIONS.details?.edit && (
                                            <img
                                              src={Edit}
                                              alt="/"
                                              onClick={() => {
                                                setSemesterId(response.id);
                                                SemesterDataById({
                                                  variables: {
                                                    token,
                                                    semester_id: response.id,
                                                  },
                                                });
                                                setOperation(Operation.UPDATE);
                                                setAddModal(!addModal);
                                              }}
                                            />
                                          )}
                                          {USE_ADMISSIONS.details?.delete && (
                                            <img
                                              src={DeleteImg}
                                              alt="/"
                                              onClick={() => {
                                                setSemesterId(response.id);
                                                setDeleteModal(!deleteModal);
                                              }}
                                            />
                                          )}
                                        </>
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                </React.Fragment>
                              );
                            }}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </div>

          <div className="col booktype-right h-100">
            <TreeView />
          </div>
        </div>

        <div className="masterconfig__buttons">
          <Button mode="addnew" onClick={() => setAddModal(!addModal)} autoFocus/>
          {localItems?.length > 1 ? (
            reOrder ? (
              <>
                <Button
                  mode="save-order"
                  type="button"
                  onClick={handleSaveOrder}
                />
                <Button
                  mode="clear"
                  type="button"
                  onClick={() => {
                    setReOrder(!reOrder);
                  }}
                />
              </>
            ) : (
              <Button
                mode="reorder"
                type="button"
                onClick={() => setReOrder(!reOrder)}
              />
            )
          ) : null}

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={semesterId}
      />
      <LoadingModal
        flag={
          creationLoading || updationLoading || deleteLoading || reorderLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.SEMESTER_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Formik
              initialValues={formData}
              validationSchema={semValidation}
              onSubmit={
                operation === Operation.UPDATE ? HandleEdit : HandleSubmit
              }
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form>
                    <Title variant="subtitle1">
                      {operation === Operation.UPDATE
                        ? `Update ${semesterLabel}`
                        : `Add ${semesterLabel}`}
                    </Title>
                    {SemesterFormLabels.map(
                      (
                        sem: InstitutionDetailsFormLabelsType,
                        index: React.Key
                      ) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              name={sem.inputName}
                              values={formData[sem.inputName]}
                              required={sem.required}
                              autoFocus={sem.autoFocus}
                              LabelName={sem.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}

                    <div className="masterconfig__buttons">
                      <Button type="submit" mode="save" />

                      <Button
                        mode="clear"
                        type="button"
                        onClick={handleClear}
                      />

                      <Button
                        mode="cancel"
                        onClick={() => {
                          setAddModal(!addModal);
                          handleClear();
                        }}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image ">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setAddModal(!addModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Semester;
