import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { SalaryProcessTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import SalaryProcess from "./SalaryProcess";

import { useNavigate, useParams } from "react-router-dom";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import Modal from "react-modal";
import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
  MonthName,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  StudentModalStyles,
  SuccessModalCustomStyles,
} from "../../../../styles/ModalStyles";
import { Label } from "../../../../stories/Label/Label";

import Save from "../../../../images/Save.svg";
import {
  GetPayRollCurrentMonthCalendarData,
  GetPayRollCurrentMonthCalendarVars,
} from "../../Types/paginationTypes";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import useSalaryMkWorkByMonth from "../../hooks/useSalaryMkWorkByMonth";
import {
  Direction,
  Operation,
  PageFor,
  PrMkSalQueryType,
  SalaryDetailsModalType,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";

import { Keys } from "../../../../utils/Enum.keys";
import useEmpMasterData from "../../hooks/useEmpMasterData";
import {
  PayRollEmpSalaryStructDetails,
  Rules,
} from "../SalaryStructure/AssignSalaryStructure";
import { idWithModalType } from "./EmpAttendance";
import { GetPayRollCurrentMonthCalendar } from "../../queries/holidays/query";
import {
  UpdatePayRollSalaryMkEmpMaster,
  UpdatePrSalWorkSalStructComplete,
} from "../../queries/salary/mutation";
import MessageModal from "../../../../pages/MessageModal";
import useSalaryMkEmpMaster from "../../hooks/useSalaryMkEmpMaster";
import SalaryStructDetailsModal from "./SalaryStructDetailsModal";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import {
  EarnedSalaryMethod,
  PayRollConfigKeys,
  SalaryLedgerType,
} from "../../enums/Enum.types";
import {
  GetPayRollSalaryMkEmpMaster,
  GetPayRollSalaryMkWorkByMonthId,
  GetPayRollSalaryProcessCount,
} from "../../queries/salary/list";
import Input from "../../../../stories/Input/Input";
import {
  calculateDeduction,
  calculateEarning,
  calculateMonthlyBasicGrossGroupLedgers,
  checkForFlags,
  findEarningsForExpressionString,
  getGroupIds,
  getSalCalDaysMethod,
} from "../../utils/Functions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { empQueryTypes } from "../../hooks/useEmployee";
import Close from "../../../../images/Close.svg";
import EmployeeList from "../../Employee/List/Index";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { ESI } from "../../constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");

interface TableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface LedgersDetails {
  emp_sal_sl: number;
  emp_sal_ldgr_value: number;
  emp_sal_ldgr_sl: number;
  emp_sal_earn_ded: string;
  emp_sal_earn_type: string;
  emp_sal_earn_flat_rate: number;
  emp_sal_earn_is_UDM: boolean;
  emp_sal_earn_UDM_val: number;
  emp_sal_earn_restrict_to: number;
  emp_sal_earn_to_min_basic: number;
  emp_sal_earn_rule_apply: boolean;
  emp_sal_earn_rule: string;
  emp_sal_group_id_heads: string;
  emp_sal_remaining_amt_rule: boolean;
  emp_sal_ded_is_UDM: boolean;
  emp_sal_ded_UDM_value: number;
  emp_sal_ded_is_applied_on_heads: boolean;
  emp_sal_ded_on_heads: string;
  emp_sal_ded_to_max_value: number;
  emp_sal_ded_rule: string;
  emp_sal_cur_month_amt: number;
  emp_sal_cur_month_earned_amt: number;
  pr_sal_ldgr_id: number;
}
interface inputType {
  id: number;
  pr_emp_id: number;
  emp_sal_mk_earned_days: number;
  emp_sal_mk_basic: number;
  emp_sal_mk_other_earn: number;
  emp_sal_mk_gross: number;
  emp_sal_mk_others_ded: number;
  emp_sal_mk_net: number;
  sal_mk_emp_details: LedgersDetails[];
}
export interface fixed_ledger_details {
  id: number;
  ledger_desc: string;
  fixed_amount: number;
  fixed_gross: number;
}
export interface EmpDetailsType {
  id: number;
  emp_id: number;
  emp_card_id: string;
  emp_title: string;
  emp_name: string;
  doj: string;
  worked_days: number;
  fixedGross: number;
  fixedBasic: number;
  basicSalary: number;
  grossSalary: number;
  netSalary: number;
  emp_sal_mk_completed: boolean;
  earned_days: number;
  total_deductions: number;
  emp_lwp: number;
  fixed_salary_details: fixed_ledger_details[];
  salary_str_details: PayRollEmpSalaryStructDetails[];
}

const ConfirmEmployeeSalary = () => {
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  const navigate = useNavigate();
  const { InstId, monthId } = useParams();

  const [saveModal, setSaveModal] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [salaryDetailsModal, setSalaryDetailsModal] = useState<idWithModalType>(
    {
      id: 0,
      flag: false,
    }
  );
  const [input, setInput] = useState<inputType[]>([]);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const selectedArraySet = new Set(input);
  const [employeesList, setEmployeesList] = useState<EmpDetailsType[]>([]);
  const [category, setCategory] = useState<responseType | null>(null);
  const [department, setDepartment] = useState<responseType | null>(null);
  const [designation, setDesignation] = useState<responseType | null>(null);
  const [grade, setGrade] = useState<responseType | null>(null);
  const [jobtype, setJobType] = useState<responseType | null>(null);

  const [rejectedEmpModal, setRejectedEmpModal] = useState(false);

  const {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  } = useEmpMasterData();
  const { configData: salRoundOffDecimal } = useSwConfigData(
    PayRollConfigKeys.ROUND_TO_DECIMAL
  );
  const salRoundOffKey = salRoundOffDecimal.data
    ? salRoundOffDecimal.data?.GetSwConfigVariables[0].config_boolean_value
    : false;

  const { configData: salNoOfDaysConfig } = useSwConfigData(
    PayRollConfigKeys.EARNED_SAL_CAL
  );
  const salNoOfDaysType = salNoOfDaysConfig.data
    ? salNoOfDaysConfig.data?.GetSwConfigVariables[0].config_string_value
    : EarnedSalaryMethod.MONTH_DAYS;

  const EnhancedTableHead = (props: TableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
            {TableHeaders.SLNO}
          </TableCell>
          {HR_Table.SalaryProcess.ConfirmEmpSalary.Table_Headers.map(
            (th: TableHeaderProps, index: React.Key) => {
              return (
                <TableCell key={index} align="center">
                  {th.labelName}
                </TableCell>
              );
            }
          )}
        </TableRow>
      </TableHead>
    );
  };
  const { serverDate } = useServerDateandTime();
  const { monthlyData, pr_acd_yr } = useSalaryMkWorkByMonth(monthId!);
  const { sal_wrk_for_month, sal_wrk_job_completed } =
    monthlyData?.data?.node || {};

  const [GetMonthHolidays, { data }] = useLazyQuery<
    GetPayRollCurrentMonthCalendarData,
    GetPayRollCurrentMonthCalendarVars
  >(GetPayRollCurrentMonthCalendar, {
    variables: {
      token,
      pr_acd_yr_id: pr_acd_yr?.id!,
      inst_id: InstId!,
      cal_month: sal_wrk_for_month!,
    },
  });

  const { total_no_of_workdays, no_of_days, no_of_weekend_days } =
    data?.GetPayRollCurrentMonthCalendar || {};
  const {
    salaryMkEmployeeData: {
      data: employees,
      error: employeesError,
      loading: employeesLoading,
      fetchMore,
    },
  } = useSalaryMkEmpMaster(
    pr_acd_yr?.id!,
    PrMkSalQueryType.FOR_MK_SAL_PROCESS,
    department ? department.value : 0,
    designation ? designation.value : 0,
    jobtype ? jobtype.value : 0,
    grade ? grade.value : 0,
    category ? category.value : 0,
    searchEmployee
  );
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const [ConfirmSalary] = useMutation(UpdatePayRollSalaryMkEmpMaster, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });
  const [CloseSalProcess] = useMutation(UpdatePrSalWorkSalStructComplete, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });
  const employeeDetails = employees?.GetPayRollSalaryMkEmpMaster.edges.find(
    ({ node }) => node.id === salaryDetailsModal?.id
  );

  const isSelected = (id: number) =>
    selectedArraySet.has(input.find((row) => row.id === id)!);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number,
    pr_emp_id: number,
    earned_days: number,
    fixed_basic: number,
    earned_basic: number,
    ledger_details: PayRollEmpSalaryStructDetails[]
  ) => {
    let totalEarnings = 0;
    let totalDeductions = 0;
    ledger_details.forEach((ledger) => {
      totalEarnings +=
        ledger.emp_sal_earn_ded === SalaryLedgerType.EARNING
          ? calcEarningsAndDeduction(ledger, earned_basic)
          : 0;
      totalDeductions +=
        ledger.emp_sal_earn_ded === SalaryLedgerType.DEDUCTION
          ? calcEarningsAndDeduction(ledger, earned_basic)
          : 0;
    });

    const { checked } = event.target as HTMLInputElement;
    if (checked) {
      setInput((prevValues) => {
        return [
          ...prevValues,
          {
            id,
            pr_emp_id,
            emp_sal_mk_earned_days: earned_days,
            emp_sal_mk_basic: fixed_basic,
            emp_sal_mk_earned_basic: earned_basic,
            emp_sal_mk_other_earn: totalEarnings,
            emp_sal_mk_gross: totalEarnings,
            emp_sal_mk_others_ded: totalDeductions,
            emp_sal_mk_net: totalEarnings - totalDeductions,
            sal_mk_emp_details: ledger_details.map((ledger, index) => ({
              emp_sal_sl: index,
              emp_sal_ldgr_value: ledger.emp_sal_ldgr_value,
              emp_sal_ldgr_sl: ledger.emp_sal_ldgr_sl,
              emp_sal_earn_ded: ledger.emp_sal_earn_ded,
              emp_sal_earn_type: ledger.emp_sal_earn_type,
              emp_sal_earn_flat_rate: ledger.emp_sal_earn_flat_rate,
              emp_sal_earn_is_UDM: ledger.emp_sal_earn_is_UDM,
              emp_sal_earn_UDM_val: ledger.emp_sal_earn_UDM_val,
              emp_sal_group_id_heads: ledger.emp_sal_group_id_heads,
              emp_sal_earn_restrict_to: ledger.emp_sal_earn_restrict_to,
              emp_sal_remaining_amt_rule: ledger.emp_sal_remaining_amt_rule,
              emp_sal_earn_to_min_basic: ledger.emp_sal_earn_to_min_basic,
              emp_sal_earn_rule_apply: ledger.emp_sal_earn_rule_apply,
              emp_sal_earn_rule: ledger.emp_sal_earn_rule,
              emp_sal_ded_is_UDM: ledger.emp_sal_ded_is_UDM,
              emp_sal_ded_UDM_value: ledger.emp_sal_earn_UDM_val,
              emp_sal_ded_is_applied_on_heads:
                ledger.emp_sal_ded_is_applied_on_heads,
              emp_sal_ded_on_heads: ledger.emp_sal_ded_on_heads,
              emp_sal_ded_to_max_value: ledger.emp_sal_ded_to_max_value,
              emp_sal_ded_rule: ledger.emp_sal_ded_rule,
              emp_sal_cur_month_amt: ledger.emp_sal_ldgr_value,
              emp_sal_cur_month_earned_amt: ledger.emp_sal_ldgr_value,
              pr_sal_ldgr_id: ledger.pr_sal_ldgr_id,
            })),
          },
        ];
      });
    } else {
      setInput(input.filter((data) => data.id !== id));
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setInput([]);
      employeesList?.forEach((data) => {
        setInput((prevValues) => [
          ...prevValues,
          {
            id: data.id,
            pr_emp_id: data.emp_id,
            emp_sal_mk_basic: data.fixedBasic,
            emp_sal_mk_earned_basic: data.basicSalary,
            emp_sal_mk_other_earn: data.grossSalary,
            emp_sal_mk_gross: data.grossSalary,
            emp_sal_mk_others_ded: data.total_deductions,
            emp_sal_mk_net: data.netSalary,
            emp_sal_mk_earned_days: data.earned_days,
            sal_mk_emp_details: data.salary_str_details.map(
              (ledger, index) => ({
                emp_sal_sl: index,
                emp_sal_ldgr_value: ledger.emp_sal_ldgr_value,
                emp_sal_ldgr_sl: ledger.emp_sal_ldgr_sl,
                emp_sal_earn_is_UDM: ledger.emp_sal_earn_is_UDM,
                emp_sal_group_id_heads: ledger.emp_sal_group_id_heads,
                emp_sal_earn_ded: ledger.emp_sal_earn_ded,
                emp_sal_earn_type: ledger.emp_sal_earn_type,
                emp_sal_earn_flat_rate: ledger.emp_sal_earn_flat_rate,
                emp_sal_earn_UDM_val: ledger.emp_sal_earn_UDM_val,
                emp_sal_earn_restrict_to: ledger.emp_sal_earn_restrict_to,
                emp_sal_earn_to_min_basic: ledger.emp_sal_earn_to_min_basic,
                emp_sal_remaining_amt_rule: ledger.emp_sal_remaining_amt_rule,
                emp_sal_earn_rule_apply: ledger.emp_sal_earn_rule_apply,
                emp_sal_earn_rule: ledger.emp_sal_earn_rule,
                emp_sal_ded_is_UDM: ledger.emp_sal_ded_is_UDM,
                emp_sal_ded_UDM_value: ledger.emp_sal_earn_UDM_val,
                emp_sal_ded_is_applied_on_heads:
                  ledger.emp_sal_ded_is_applied_on_heads,
                emp_sal_ded_on_heads: ledger.emp_sal_ded_on_heads,
                emp_sal_ded_to_max_value: ledger.emp_sal_ded_to_max_value,
                emp_sal_ded_rule: ledger.emp_sal_ded_rule,
                emp_sal_cur_month_amt: ledger.emp_sal_ldgr_value,
                emp_sal_cur_month_earned_amt: ledger.emp_sal_ldgr_value,
                pr_sal_ldgr_id: ledger.pr_sal_ldgr_id,
              })
            ),
          },
        ]);
      });

      return;
    } else {
      setInput([]);
    }
  };

  const calcEarningsAndDeduction = (
    data: PayRollEmpSalaryStructDetails,
    amount: number
  ) => {
    if (
      data.emp_sal_earn_flat_rate > 0 &&
      data.emp_sal_earn_type === Rules.FLR
    ) {
      let newEarning = 0;
      let newBasicSalary = 0;
      newBasicSalary = amount;
      if (
        data.emp_sal_earn_to_min_basic > 0 &&
        amount > data.emp_sal_earn_to_min_basic
      ) {
        newBasicSalary = data.emp_sal_earn_to_min_basic;
      }
      if (data.emp_sal_earn_flat_rate >= 0) {
        newEarning = (data.emp_sal_earn_flat_rate / 100) * newBasicSalary;
        if (
          data.emp_sal_earn_restrict_to > 0 &&
          newEarning > data.emp_sal_earn_restrict_to
        ) {
          newEarning = data.emp_sal_earn_restrict_to;
        }
        return newEarning;
      }
    }
    if (
      data.emp_sal_earn_type === Rules.RULE_ON_SALARY &&
      data.emp_sal_earn_ded === SalaryLedgerType.EARNING
    ) {
      return findEarningsForExpressionString(
        data.emp_sal_earn_rule,
        amount,
        salRoundOffKey
      );
    }
    if (data.emp_sal_earn_ded === SalaryLedgerType.DEDUCTION) {
      return findEarningsForExpressionString(
        data.emp_sal_ded_rule,
        amount,
        salRoundOffKey
      );
    }
    return 0;
  };

  const approveSalary = () => {
    ConfirmSalary({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input,
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkEmpMaster,
          variables: {
            token,
            inst_id: InstId!,
            pr_acd_yr_id: pr_acd_yr?.id,
            input: {
              pr_mk_sal_query: PrMkSalQueryType.FOR_MK_SAL_PROCESS,
              ids: [Number(monthId)],
            },
            first: ROWS_PER_PAGE,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.EMP_SAL_MK_MONTH,
            department: department ? department.value : null,
            designation: designation ? designation.value : null,
            jobtype: jobtype ? jobtype.value : null,
            grade: grade ? grade.value : null,
            category: category ? category.value : null,
            name: searchEmployee,
          },
        },
        {
          query: GetPayRollSalaryProcessCount,
          variables: {
            inst_id: InstId!,
            pr_acd_yr_id: pr_acd_yr?.id!,
            token,
            pr_sal_mk_month_id: monthId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          operation: Operation.UPDATE,
          message: "Salary Approved Successfully",
          flag: true,
        });
        if (input.length === 0)
          setSalaryDetailsModal({
            id: 0,
            flag: false,
          });
      }
    });
  };
  const closeSalaryProcess = () => {
    CloseSalProcess({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_sal_mk_month_id: monthlyData.data?.node.id,
        month_start_date: toIsoDate(serverDate),
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkWorkByMonthId,
          variables: {
            token,
            inst_id: InstId!,
            id: Number(monthId),
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setSaveModal(!saveModal);
        navigate(
          `/${InstId}/hr/payroll/salaryprocess/${monthlyData.data?.node.id}/provisionalsalarydetails/detailed`
        );
      }
    });
  };
  const employeeHasUdv = (employee: PayRollEmpSalaryStructDetails[]) => {
    return (
      employee.filter(
        ({ emp_sal_earn_is_UDM, emp_sal_ded_is_UDM }) =>
          emp_sal_earn_is_UDM || emp_sal_ded_is_UDM
      ).length > 0
    );
  };
  const hanldeClose = () => {
    setMessage({
      message: "",
      operation: Operation.NONE,
      flag: false,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !employeesLoading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollSalaryMkEmpMaster.edges;
            const pageInfo =
              fetchMoreResult.GetPayRollSalaryMkEmpMaster.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollSalaryMkEmpMaster.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollSalaryMkEmpMaster: {
                edges: [
                  ...prevResult.GetPayRollSalaryMkEmpMaster.edges,
                  ...newEdges,
                ],
                pageInfo,
                totalCount: employees?.GetPayRollSalaryMkEmpMaster.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (pr_acd_yr && sal_wrk_for_month && token) {
      GetMonthHolidays();
    }
  }, [pr_acd_yr, sal_wrk_for_month, GetMonthHolidays, token]);

  useEffect(() => {
    if (employees && !employeesLoading && no_of_days && total_no_of_workdays) {
      const numOfDaysBasedOnCOnfiguration = getSalCalDaysMethod(
        salNoOfDaysType as EarnedSalaryMethod,
        no_of_days,
        no_of_weekend_days!
      );

      setEmployeesList(
        employees?.GetPayRollSalaryMkEmpMaster.edges?.map(({ node }) => {
          const { monthlyBasic, monthlyGross } =
            calculateMonthlyBasicGrossGroupLedgers(
              node.emp_sal_mk_basic,
              node.emp_sal_mk_fixed_gross,
              node.emp_sal_mk_earned_days - node.emp_sal_mk_lwp,
              node.confirmed_salary_details,
              numOfDaysBasedOnCOnfiguration!,
              salRoundOffKey,
              node.emp_sal_mk_remaining_ldgr_bal
            );

          const totalDeductions = node.confirmed_salary_details
            .filter(
              ({ emp_sal_earn_ded }) =>
                emp_sal_earn_ded === SalaryLedgerType.DEDUCTION
            )
            .reduce((acc, ledger) => {
              const {
                monthlyBasic: basicAmt,
                monthlyGross: grossAmt,
                monthlyGroupSalary: groupSal,
              } = calculateMonthlyBasicGrossGroupLedgers(
                node.emp_sal_mk_basic,
                node.emp_sal_mk_fixed_gross,
                node.emp_sal_mk_earned_days - node.emp_sal_mk_lwp,
                node.confirmed_salary_details,
                numOfDaysBasedOnCOnfiguration!!,
                salRoundOffKey,
                node.emp_sal_mk_remaining_ldgr_bal,
                new Set(getGroupIds(ledger.emp_sal_group_id_heads))
              );

              acc += ledger.emp_sal_ded_is_UDM
                ? ledger.emp_sal_ded_UDM_value
                : calculateDeduction(
                    getGroupIds(ledger.emp_sal_group_id_heads),
                    ledger.emp_sal_ded_rule,
                    basicAmt,
                    grossAmt,
                    groupSal,
                    salRoundOffKey,
                    ledger.sal_ldgr_details.sal_ldgr_type === ESI &&
                      node.emp_sal_mk_fixed_gross > 21000
                  );
              return acc;
            }, 0);

          return {
            id: node.id,
            emp_id: node.emp_details.id,
            emp_card_id: node.emp_details.emp_id,
            emp_title: node.emp_details.emp_title,
            emp_name: `${node.emp_details.emp_first_name} ${node.emp_details.emp_middle_name} ${node.emp_details.emp_last_name}`,
            doj: toStandardDate(node.emp_details.emp_doj),
            worked_days: node.emp_sal_mk_earned_days,
            fixedGross: node.emp_sal_mk_fixed_gross,
            fixedBasic: node.emp_sal_mk_basic,
            basicSalary: monthlyBasic,
            grossSalary: monthlyGross,
            netSalary: monthlyGross - totalDeductions,
            total_deductions: totalDeductions,
            emp_sal_mk_completed: node.emp_sal_mk_completed,
            earned_days: node.emp_sal_mk_earned_days,
            emp_lwp: node.emp_sal_mk_lwp,
            fixed_salary_details:
              node.fixed_salary_details.salary_str_details.map((ledger) => ({
                id: ledger.sal_ldgr_details.id,
                ledger_desc: ledger.sal_ldgr_details.sal_ldgr_desc,
                fixed_amount: ledger.emp_sal_ldgr_value,
                fixed_gross:
                  node.fixed_salary_details.emp_sal_str_total_earnings,
              })),
            salary_str_details: node.confirmed_salary_details.map((ledger) => {
              const { monthlyBasic, monthlyGross, monthlyGroupSalary } =
                calculateMonthlyBasicGrossGroupLedgers(
                  node.emp_sal_mk_basic,
                  node.emp_sal_mk_fixed_gross,
                  node.emp_sal_mk_earned_days - node.emp_sal_mk_lwp,
                  node.confirmed_salary_details,
                  numOfDaysBasedOnCOnfiguration!!,
                  salRoundOffKey,
                  node.emp_sal_mk_remaining_ldgr_bal,
                  new Set(getGroupIds(ledger.emp_sal_group_id_heads))
                );

              return {
                ...ledger,
                emp_sal_str_ldgr_value:
                  node.emp_sal_mk_lwp! > 0
                    ? ledger.emp_sal_earn_ded === SalaryLedgerType.EARNING
                      ? calculateEarning(monthlyBasic, salRoundOffKey, ledger)
                      : checkForFlags(
                          ledger.sal_ldgr_details,
                          salRoundOffKey,
                          calculateDeduction(
                            getGroupIds(ledger.emp_sal_group_id_heads),
                            ledger.emp_sal_ded_rule,
                            monthlyBasic,
                            monthlyGross,
                            monthlyGroupSalary,
                            salRoundOffKey,
                            ledger.sal_ldgr_details.sal_ldgr_type === ESI &&
                              node.emp_sal_mk_fixed_gross > 21000
                          )
                        )
                    : ledger.emp_sal_ldgr_value,
              };
            }),
          };
        }) ?? []
      );
    }
  }, [
    salRoundOffKey,
    employees,
    employeesLoading,
    no_of_days,
    total_no_of_workdays,
    hasNextPage,
    endCursor,
    no_of_weekend_days,
    salNoOfDaysType,
  ]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryProcess.Titles.map(
          (title: SalaryProcessTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.ConfirmEmpSalary}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="salary-process">
        <div className="salary-process__data">
          <div className="salary-process__data--left-block">
            <SalaryProcess monthDetails={monthlyData.data?.node!} />
          </div>

          <div className="salary-process__data--right-block">
            <div className="row g-0 salary-process__confirm-salary--select">
              <div className="col-2">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search Name..."
                  value={searchEmployee}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchEmployee(e.target.value);
                  }}
                />
              </div>
              {USE_HR_DEPARTMENT ? (
                <div className="col-1">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={departmentDropDown!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, department)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setDepartment(newValue as responseType);
                        setHasNextPage(true);
                      } else {
                        setDepartment(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setDepartment(null);
                      }
                    }}
                    openOnFocus
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Department"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_DESIGNATION ? (
                <div className="col-1">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={designationDropDown!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, designation)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setDesignation(newValue as responseType);
                        setHasNextPage(true);
                      } else {
                        setDesignation(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setDesignation(null);
                      }
                    }}
                    openOnFocus
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Designation"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_JOBTYPE ? (
                <div className="col-1">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={jobTypeDropDown!}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setJobType(newValue as responseType);
                        setHasNextPage(true);
                      } else {
                        setJobType(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setJobType(null);
                      }
                    }}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, jobtype)
                    }
                    openOnFocus
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="JobType"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_GRADE ? (
                <div className="col-1">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={gradeDropDown!}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setGrade(newValue as responseType);
                        setHasNextPage(true);
                      } else {
                        setGrade(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setGrade(null);
                      }
                    }}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, grade)
                    }
                    openOnFocus
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grade"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_CATEGORY ? (
                <div className="col-1">
                  <LabeledAutocomplete
                    className={labelClasses.inputRoot}
                    options={categoryDropDown!}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setCategory(newValue as responseType);
                        setHasNextPage(true);
                      } else {
                        setCategory(null);
                      }
                    }}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option as responseType, category)
                    }
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setCategory(null);
                      }
                    }}
                    openOnFocus
                    freeSolo
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                        fullWidth
                        className={labelClasses.formControlRoot}
                      />
                    )}
                  />
                </div>
              ) : null}
              <div className="col"></div>
              <div className="salary-process__confirm-salary--details col-2">
                <TextField
                  label="Salary Process Month"
                  disabled
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  value={MonthName(
                    monthlyData.data?.node.sal_wrk_for_month!
                  ).toString()}
                  className="salary-process__confirm-salary--select--textfield"
                />
              </div>
            </div>
            <div className="salary-process__confirm-salary--tableblock">
              {employeesError ? (
                <b className="nodata">{employeesError.message}</b>
              ) : null}
              {employees?.GetPayRollSalaryMkEmpMaster.edges.length! > 0 ? (
                <TableContainer
                  onScroll={handleScroll}
                  className={`salary-process__confirm-salary--table`}
                >
                  <Table stickyHeader>
                    <EnhancedTableHead
                      numSelected={input.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={employeesList?.length!}
                    />
                    <TableBody>
                      {employeesList &&
                        employeesList.map((node, index) => {
                          const isItemSelected = isSelected(node.id);

                          return (
                            <TableRow key={index}>
                              <TableCell id="td-center">
                                {node.emp_sal_mk_completed ? (
                                  "Approved"
                                ) : employeeHasUdv(node.salary_str_details) ? (
                                  "UDV"
                                ) : (
                                  <Checkbox
                                    checked={isItemSelected}
                                    onClick={(event) => {
                                      handleClick(
                                        event,
                                        node.id,
                                        node.emp_id,
                                        node.earned_days,
                                        node.fixedBasic,
                                        node.basicSalary,
                                        node.salary_str_details
                                      );
                                    }}
                                    disabled={employeeHasUdv(
                                      node.salary_str_details
                                    )}
                                  />
                                )}
                                {index + 1}
                              </TableCell>
                              <TableCell>{node.emp_card_id}</TableCell>
                              <TableCell>{node.emp_title}</TableCell>
                              <TableCell
                                className={`salary-process__confirm-salary--table--name `}
                                onClick={() => {
                                  setSalaryDetailsModal({
                                    id: node.id,
                                    flag: true,
                                  });
                                }}
                              >
                                {node.emp_name}
                              </TableCell>

                              <TableCell id="td-center">{node.doj}</TableCell>
                              <TableCell id="td-center">
                                {node.earned_days - node.emp_lwp}
                              </TableCell>
                              <TableCell id="td-right">
                                {node.fixedGross}
                              </TableCell>
                              <TableCell id="td-right">
                                {node.basicSalary}
                              </TableCell>
                              <TableCell id="td-right">
                                {node.grossSalary}
                              </TableCell>
                              <TableCell id="td-right">
                                {node.total_deductions}
                              </TableCell>

                              <TableCell id="td-right">
                                {node.netSalary}
                              </TableCell>
                              <TableCell
                                className={
                                  node.emp_sal_mk_completed
                                    ? `font-green `
                                    : `font-red `
                                }
                              >
                                {node.emp_sal_mk_completed
                                  ? "Approved"
                                  : "Not-Approved"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <b className="nodata">No data found</b>
              )}
            </div>
          </div>
        </div>
        <Button
          onClick={() => setSaveModal(!saveModal)}
          disabled={sal_wrk_job_completed}
        >
          <img src={Save} alt="/" />
          Close Salary for Employees
        </Button>
        <Button
          onClick={approveSalary}
          disabled={input.length === 0 || sal_wrk_job_completed}
        >
          <img src={Save} alt="/" />
          Approve Salary for Employees
        </Button>
        <Button onClick={() => setRejectedEmpModal(!rejectedEmpModal)}>
          <img src={Save} alt="/" />
          Rejected Employees
        </Button>
        <Button mode="print" />
        <Button mode="export" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* confirmation modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={saveModal}
        style={SuccessModalCustomStyles}
        ariaHideApp={false}
      >
        <Title>Salary Confirmed</Title>
        <div>
          <Label>Employee Salary Has Been Confirmed Successfully</Label>
        </div>
        <Button mode="okay" onClick={closeSalaryProcess} />
        <Button mode="cancel" onClick={() => setSaveModal(!saveModal)} />
      </Modal>

      {/* salary-details */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={salaryDetailsModal.flag}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <SalaryStructDetailsModal
          employeeDetails={employeeDetails!}
          salaryDetailsModalType={SalaryDetailsModalType.CONFIRM_EMP_SALARY}
          monthlyData={monthlyData?.data?.node!}
          setSalaryDetailsModal={setSalaryDetailsModal}
          monthDays={data?.GetPayRollCurrentMonthCalendar!}
          pr_acd_yr={pr_acd_yr!}
        />
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={rejectedEmpModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EmployeeList
              pageType={PageFor.MODAL}
              setModalFlag={setRejectedEmpModal}
              queryType={empQueryTypes.EMPS_NOT_ADDED_FOR_SAL_MK_MONTH}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setRejectedEmpModal(!rejectedEmpModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        handleClose={hanldeClose}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default ConfirmEmployeeSalary;
