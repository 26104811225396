import { useLazyQuery, useMutation } from "@apollo/client";
import Modal from "react-modal";
import { useContext, useEffect, useState } from "react";
import {
  Operation,
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import { AppContext } from "../../../../context/context";

import StudentList from "../../Student/List";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { studentAcademicYearFormDetails } from "../../../../Types/Accounting";
import { Button } from "../../../../stories/Button/Button";
import { ListParentStudentAssocByParentId } from "../../../../queries/parent/list/byId";
import { Form, Formik } from "formik";
import { parentStudentSchema } from "../../../../utils/validationRules";
import { AddSiblings } from "../../../../queries/parent/mutations/new";
import {
  parentStudentAssociationByParentIdData,
  parentStudentAssociationByParentIdVars,
} from "../../../../Types/Student";
import { TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../../utils/constants";
import LoadingModal from "../../../../pages/LoadingModal";
import Close from "../../../../images/Close.svg";
import Delete from "../../../../images/Delete.svg";
import OpenIcon from "../../../../images/OpenIcon.svg";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { GetStudentDetails } from "../../../../queries/common";
import {
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../../../../Types/Student";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import MessageModal from "../../../../pages/MessageModal";
import Home from "../../Home/Index";
import DownArrow from "../../../../images/DownArrow.svg";
import useStudentAdmissionNumber from "../../../Accounts/hooks/UseStudentAdmissionNumber";
import { payloadTypes } from "../../../../context/reducer";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import {
  labelClasses,
  LabeledAutocomplete,
} from "../../../../styles/AutocompleteListStyles";
import { Title } from "../../../../stories/Title/Title";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import {
  GridColDef,
  GridValidRowModel,
  GridAlignment,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../../utils/types";
export interface StudentDetails {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  std_reg_no: string;
  std_adm_no: string;
  std_father_name: string;
  std_mother_name: string;
  class: ClassList;
  branch: BranchesList;
  dept: DepartmentList;
  semester: SemesterList;
  section: SectionList;
  category: CategoryList;
  parents: [
    {
      parent_id: number;
    }
  ];
}
export interface StudentData {
  nodes: StudentDetails[];
}
export interface StudentDetailsvars {
  token: string;
  ids: number[] | number;
}
const SiblingAssociation = () => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId, studentId } = useParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [admNo, setAdmNo] = useState("");
  const [studentModal, setStudentModal] = useState(false);

  // eslint-disable-next-line
  const [studentDetails, setStudentDetails] =
    useState<studentAcademicYearFormDetails>({
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    });
  const [studentList, setStudentList] = useState<
    studentAcademicYearFormDetails[]
  >([
    {
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    },
  ]);

  const [ParentStudentList, setParentStudentList] = useState<
    studentAcademicYearFormDetails[]
  >([
    {
      std_id: "",
      std_adm_no: "",
      std_reg_no: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dept_desc: "",
      branch_desc: "",
      class_desc: "",
      cat_desc: "",
      std_father_name: "",
    },
  ]);

  const { user_details } = useLoggedInUserDetails();
  const [GetStudentDetailsByIds, { data }] = useLazyQuery<
    StudentData,
    StudentDetailsvars
  >(GetStudentDetails, {
    fetchPolicy: "network-only",
  });
  const parentIdStd =
    data && data.nodes.length && data.nodes[0].parents.length > 0
      ? data.nodes[0].parents[0].parent_id
      : 0;
  useEffect(() => {
    if (token && studentId) {
      GetStudentDetailsByIds({
        variables: {
          token,
          ids: [Number(studentId)],
        },
      });
    }
  }, [GetStudentDetailsByIds, token, studentId]);
  const [
    GetAssociationData,
    {
      data: ParentStudentAssociationData,
      loading: ParentStudentAssociationLoading,
    },
  ] = useLazyQuery<
    parentStudentAssociationByParentIdData,
    parentStudentAssociationByParentIdVars
  >(ListParentStudentAssocByParentId, {
    variables: {
      token,
      parent_id: parentIdStd,
    },
  });

  const { studentAddmissionNumber } = useStudentAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL,
    0
  );
  const [AddParentStudentAssoci, { loading: creationLoading }] = useMutation(
    AddSiblings,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetStudentsAcademicDetails] = useLazyQuery<
    StudentData,
    StudentDetailsvars
  >(GetStudentDetails);
  const { studentData } = useStudentDatabyId();

  useEffect(() => {
    if (state.studentId) {
      // eslint-disable-next-line
      studentData?.data?.nodes.map((data) => {
        if (
          !studentList.find(
            (response: studentAcademicYearFormDetails) =>
              response.std_id === state.studentId
          )
        ) {
          setStudentList((prevValues) => [
            {
              std_id: state.studentId,
              std_adm_no: data.std_adm_no,
              first_name: data.first_name,
              middle_name: data.middle_name,
              last_name: data.last_name,
              std_reg_no: data.std_reg_no,
              std_father_name: data.std_father_name,
              branch_desc: data.branch.branch_desc,
              class_desc: data.class.class_desc,
              dept_desc: data.dept.dept_desc,
              cat_desc: data.category.cat_desc,
            },
            ...prevValues,
          ]);
        }
      });
      setStudentModal(false);
    } // eslint-disable-next-line
  }, [studentData]);

  useEffect(() => {
    if (ParentStudentAssociationData && !ParentStudentAssociationLoading) {
      const studentIds =
        ParentStudentAssociationData.GetParentStudentAssociByParentId?.map(
          (response: any) => response.student_id
        );
      GetStudentsAcademicDetails({
        variables: {
          token,
          ids: studentIds,
        },
      })
        .then(({ data }) => {
          data?.nodes
            .filter((Res) => Res.id !== state.studentId)
            .map(
              (
                studentData,
                index: number
                // eslint-disable-next-line
              ) => {
                setStudentList((prevValues) => [
                  {
                    std_id: studentIds[index],
                    std_adm_no: studentData.std_adm_no,
                    first_name: studentData.first_name,
                    middle_name: studentData.middle_name,
                    last_name: studentData.last_name,
                    std_reg_no: studentData.std_reg_no,
                    std_father_name: studentData.std_father_name,
                    branch_desc: studentData.branch.branch_desc,
                    class_desc: studentData.class.class_desc,
                    dept_desc: studentData.dept.dept_desc,
                    cat_desc: studentData.category.cat_desc,
                  },
                  ...prevValues,
                ]);
                setParentStudentList((prevValues) => [
                  {
                    std_id: studentIds[index],
                    std_adm_no: studentData.std_adm_no,
                    first_name: studentData.first_name,
                    middle_name: studentData.middle_name,
                    last_name: studentData.last_name,
                    std_reg_no: studentData.std_reg_no,
                    std_father_name: studentData.std_father_name,
                    branch_desc: studentData.branch.branch_desc,
                    class_desc: studentData.class.class_desc,
                    dept_desc: studentData.dept.dept_desc,
                    cat_desc: studentData.category.cat_desc,
                  },
                  ...prevValues,
                ]);
              }
            );
        })
        .catch(({ error }) => console.log(error));
      setStudentList([studentDetails]);
    }
    // eslint-disable-next-line
  }, [ParentStudentAssociationData, ParentStudentAssociationLoading]);
  const submitForm = () => {
    const filteredStudentIdArray = studentList.filter(
      (response: studentAcademicYearFormDetails) => response.std_id !== ""
    );
    AddParentStudentAssoci({
      variables: {
        token,
        primary_student_id: studentId,
        inst_id: InstId!,
        user_details,
        sibling_ids: filteredStudentIdArray.map((std) => std.std_id),
      },
      //   refetchQueries: [
      //     {
      //       query: ListParentStudentAssocByParentId,
      //       variables: {
      //         token,
      //         parent_id: parentId,
      //       },
      //     },
      //     {
      //       query: GetParents,
      //       variables: {
      //         after: null,
      //         direction: Direction.ASC,
      //         first: ROWS_PER_PAGE,
      //         inst_id: InstId!,
      //         queryType: ParentQueryType.PARENTS_BY_INST_ID,
      //         token,
      //       },
      //     },
      //   ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Sibling Added Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    // const input = filteredStudentIdArray.map((student) => ({
    //   student_id: student.std_id,
    //   relationship: data && data?.node.parent_type,
    // }));
  };

  const removeStudentFromList = (index: number) => {
    studentList.splice(index, 1);
    setStudentList((prevStudents) => [...prevStudents]);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      navigate(`/${InstId}/admissions/students/siblingList`);
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  document.onkeydown = (e) => {
    if (e.key === Keys.ENTER && document.activeElement?.tagName === "BODY") {
      setStudentModal(!studentModal);
    }
  };
  useEffect(() => {
    if (token) {
      GetAssociationData();
    }
  }, [token, GetAssociationData]);

  const { Masters_Table } = useMasterTableJson();
  const dynamicHeaders: TableHeaderProps[] =
    Masters_Table.Students.Siblings.map((header) => ({
      headerName: header.headerName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
      hideable: header.hideable,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      headerName: TableHeaders.ACTION,
      field: "actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params) => {
        return (
          <>
            <img
              src={Delete}
              alt="/"
              onClick={() => removeStudentFromList(params.row.std_id)}
              className="delete-icon"
            />
          </>
        );
      },
    },
  ];
  const rows: GridValidRowModel[] = studentList
    .filter((res) => res.std_id !== data && data?.nodes[0].id)
    ?.filter((x: studentAcademicYearFormDetails) => x.first_name !== "")
    ?.map((response: studentAcademicYearFormDetails, index: number) => {
      return {
        id: index + 1,
        std_name:
          response.first_name +
          " " +
          response.middle_name +
          " " +
          response.last_name,
        branch_desc: response.branch_desc,
        class_desc: response.class_desc,
        std_id: index,
      };
    });
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Sibling Associations</Title>

      <Formik
        initialValues={studentDetails}
        validationSchema={parentStudentSchema}
        onSubmit={submitForm}
        validateOnChange={false}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className={"sibling-association"}>
              <div className="row g-0 sibling-association__select">
                <div className="col-3">
                  <TextField
                    disabled
                    value={
                      data
                        ? data.nodes[0].first_name +
                          " " +
                          data.nodes[0].middle_name +
                          " " +
                          data.nodes[0].last_name
                        : EMPTY_STRING
                    }
                    label="Student Name:"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className="parentReg__textfield"
                  />

                  <div className="sibling-association__select--flex">
                    <LabeledAutocomplete
                      className={labelClasses.inputRoot}
                      options={studentAddmissionNumber! ?? [EMPTY_STRING]}
                      value={
                        studentAddmissionNumber?.find(({ label }) =>
                          studentData.data
                            ? label === studentData.data.nodes[0].std_adm_no
                            : label === admNo
                        )! ?? null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: {
                              studentId: Number(
                                (newValue as responseType)?.value
                              ),
                            },
                          });
                        } else {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: {
                              studentId: 0,
                            },
                          });
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.BACKSPACE) {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: {
                              studentId: 0,
                            },
                          });
                        }
                      }}
                      freeSolo
                      autoHighlight
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setAdmNo(e.target.value)}
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                          fullWidth
                          className={labelClasses.formControlRoot}
                          label="Add Student / Siblings AdmNo"
                        />
                      )}
                    />

                    <img
                      className="data-fetch-icon"
                      src={OpenIcon}
                      alt="Loading"
                      onClick={() => setStudentModal(!studentModal)}
                    />
                  </div>
                </div>
              </div>

              <div className="sibling-association__tableblock">
                <StyledDatagrid
                  columns={columns}
                  rows={rows}
                  disableRowSelectionOnClick
                  disableChildrenSorting
                  rowHeight={TABLE_ROW_HEIGHT}
                  hideFooter
                />
              </div>
              <Button mode="save" type="submit" onClick={submitForm} />

              <Button mode="back" type="button" onClick={() => navigate(-1)} />
            </Form>
          );
        }}
      </Formik>
      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.GENERAL}
              queryType={StudentReportType.GENERAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SiblingAssociation;
