import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Direction,
  InstitutionConfigurationTypes,
  SortBy,
  StudentReportType,
} from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import { studentQueryTypes } from "../../../utils/studentqueryEnum.types";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import { GetAcdStudentsByAdmNo } from "../../Academics/queries/students/Index";
import {
  AcdStudentsvars,
  GetStudentAcdData,
} from "../../Academics/hooks/useAcdStudentsData";
import { StudentAcdType } from "../../../utils/studentAcdEnum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";

interface StudentNode {
  id: number;
  std_adm_no: string;
  first_name: string;
  middle_name: string;
  last_name: string;
}

interface StudentPageInfo {
  endCursor: string;
}
interface StudentEdges {
  node: StudentNode;
}
interface GetStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}

export interface GetStudentsData {
  GetStudents: GetStudentsList;
}

const useStudentAdmissionNumber = (
  admNo: string,
  rowsPerPage: number,
  queryType: StudentReportType,
  instSelected?: number
) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { configData: SearchFlagData } = useSwConfigData(
    InstitutionConfigurationTypes.OPTION_SEARCH_STD
  );
  const searchFlag =
    SearchFlagData &&
    SearchFlagData.data &&
    SearchFlagData.data.GetSwConfigVariables.length &&
    SearchFlagData.data.GetSwConfigVariables[0].config_string_value;

  const [studentAddmissionNumber, setStudentList] = useState<responseType[]>(
    []
  );
  const [acd_std_query_type, setstd_query_type] = useState("");

  useEffect(() => {
    switch (queryType) {
      case StudentReportType.CAMPUS:
        if (instSelected) {
          setstd_query_type(studentQueryTypes.BY_INST_ID);
        }
        break;
      case StudentReportType.GENERAL:
        if (InstId) {
          setstd_query_type(StudentAcdType.BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.ELIGIBLE:
        if (InstId) {
          setstd_query_type(StudentAcdType.CUR_BY_ACD_INST_ID);
        }
        break;
      case StudentReportType.NON_ELIGIBLE:
        if (InstId) {
          // setstd_query_type(StudentAcdType.STATUS_NEQ_CUR_BY_INST_ID);
        }
        break;
      default:
        if (queryType) setstd_query_type(queryType);
        break;
    }
  }, [InstId, queryType, instSelected]);

  const [GetStudentsData] = useLazyQuery<GetStudentAcdData, AcdStudentsvars>(
    GetAcdStudentsByAdmNo,
    {
      variables: {
        after: null,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        first: rowsPerPage,
        name: admNo,
        token,
        orderBy: [
          {
            direction: Direction.ASC,
            field: SortBy.FIRST_NAME,
          },
        ],
        input: {
          ids: [instSelected ? instSelected : Number(InstId)!],
          acd_std_query_type,
          str_data: [],
        },
      },
    }
  );

  useEffect(() => {
    if (state.ActiveFinYr && (InstId || instSelected) && acd_std_query_type) {
      GetStudentsData().then(({ data }) => {
        if (data) {
          const result = data.GetAcdStudents.edges.map((edge) => ({
            label: `(${
              searchFlag === "REG" ? edge.node.std_reg_no : edge.node.std_adm_no
            } ) ${edge.node.first_name} ${edge.node.middle_name} ${
              edge.node.last_name
            }`,
            value: edge.node.id,
          }));
          setStudentList(result);
        }
        if (!data) {
          setStudentList([]);
        }
      });
    }
  }, [
    GetStudentsData,
    admNo,
    state.ActiveFinYr,
    InstId,
    instSelected,
    acd_std_query_type,
    searchFlag,
  ]);
  return { studentAddmissionNumber };
};

export default useStudentAdmissionNumber;
