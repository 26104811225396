import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Pointer from "../../../../images/Pointer.svg";
import { EMPTY_STRING } from "../../../../utils/constants";

interface ArrayDataType {
  label: string;
  link: string;
  id: string;
}
const List = () => {
  const { InstId } = useParams();
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  const studentFeeReport: ArrayDataType[] = [
    {
      id: "1",
      label: "Balance Report",
      link: `/${InstId}/accounts/feebalancereport/balancereport`,
    },
    {
      id: "2",
      label: " Completely Paid Report",
      link: `/${InstId}/accounts/feebalancereport/completelypaid`,
    },
    {
      id: "3",
      label: "Partially paid",
      link: `/${InstId}/accounts/feebalancereport/partiallypaid`,
    },
    {
      id: "4",
      label: "Not Paid",
      link: `/${InstId}/accounts/feebalancereport/notpaid`,
    },
    {
      id: "5",
      label: "Agent Student Report",
      link: `/${InstId}/accounts/feebalancereport/agent`,
    },
  ];

  return (
    <>
      <TableContainer className="balance-reports__table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Balance Reports</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentFeeReport.map((report) => {
              return (
                <TableRow
                  key={report.id}
                  selected={selected === report.id}
                  className={
                    selected === report.id ? "selected-row" : EMPTY_STRING
                  }>
                  <TableCell
                    onClick={() => {
                      navigate(report.link);
                      setSelected(report.id);
                    }}
                    className="bank-book__table--ledger">
                    {selected === report.id ? (
                      <img src={Pointer} alt="/" className="pointer-icon" />
                    ) : (
                      EMPTY_STRING
                    )}
                    {report.label}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;
