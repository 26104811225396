import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { Title } from "../../../../stories/Title/Title";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";

import { AppContext } from "../../../../context/context";
import {
  DateRange,
  filterVouchersByType,
  formatter,
  getModifiedScrollHeight,
  toInputStandardDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { useLazyQuery } from "@apollo/client";
import { nodevars, VouchernodeData } from "../../../../Types/Accounting";
import { StudentDetailsByMasterVoucherId } from "../../../../queries/common";
import { ReceiptTypes, studentData } from "../../../../utils/Enum.types";
import useToken from "../../../../customhooks/useToken";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../hooks/useAcctVoucherDetailsByDates";

import { payloadTypes } from "../../../../context/reducer";
import { VoucherQueryTypes } from "../../common/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import useAcctTableJson from "../../json/useAcctTableJson";
import {
  GridColDef,
  GridValidRowModel,
  GridAlignment,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import {
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../styles/DataGridTableStyles";
interface Props {
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudentDemandReceipt = ({ setStudentModal }: Props) => {
  const { dispatch } = useContext(AppContext);
  let DefaultDate = new Date();
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const [v_no, setV_no] = useState("");
  const dates = DateRange(DefaultDate.toString());
  const [startDate, setStartDate] = useState(
    toInputStandardDate(dates?.firstDay!)
  );
  const [endDate, setEndDate] = useState(toInputStandardDate(dates?.lastDay!));

  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );

  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { token } = useToken();

  //queries
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE,
    endDate,
    startDate,
    VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS,
    v_no
  );
  const { data, fetchMore, loading } = VoucherDetails;

  const filteredStudentsReceipts = filterVouchersByType(
    ReceiptTypes.STUDENT_RECEIPT,
    VoucherDetails.data!
  );
  const [GetStudentDetails, { data: studetDetailsData }] = useLazyQuery<
    VouchernodeData,
    nodevars
  >(StudentDetailsByMasterVoucherId);

  const FetchStudentDetails = (
    node: voucherMasterDetails,
    type: studentData
  ) => {
    const student = studentDetails.find(
      (studentDetail) => studentDetail?.id === node.id
    );
    if (student)
      switch (type) {
        case studentData.name:
          return (
            student.mst_student.first_name +
            " " +
            student.mst_student.middle_name +
            " " +
            student.mst_student.last_name +
            " " +
            `(${node.branch_details.branch_desc} / ${node.class_details.class_desc} / ${student.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return student.mst_student.std_adm_no!;
        case studentData.parent:
          return student.mst_student.std_father_name!;
        case studentData.reg:
          return student.mst_student.std_reg_no!;
        case studentData.id:
          return student.mst_student.id!;
        default:
          break;
      }
    else return EMPTY_STRING;
  };

  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [
    GetStudentDetails,
    filteredStudentsReceipts?.length,
    studetDetailsData,
    data,
    token,
  ]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = vouchers.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setVouchers(updatedNewData);
      } else {
        setVouchers(newData);
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Receipts.InterChangeReceipt.StudentDemandReceipts.Table_Headers.map(
      (header) => ({
        headerName: header.headerName,
        cellClassName: header.cellClassName,
        field: header.field,
        headerAlign: header.headerAlign as GridAlignment,
        align: header.align as GridAlignment,
        flex: header.flex,
        hideable: header.hideable,
      })
    );
  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVouchers?.edges;
                const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcctVouchers: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctVouchers.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && vouchers && vouchers.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [vouchers]
  );

  const tableData = vouchers.map((repo, index) => ({
    id: index + 1,
    date: toStandardDate(repo.node.v_date),
    receipt_no: repo.node.v_no,
    name: FetchStudentDetails(repo.node, studentData.name),
    adm_number: FetchStudentDetails(repo.node, studentData.admNo),
    parent_name: FetchStudentDetails(repo.node, studentData.parent),
    reg_no: FetchStudentDetails(repo.node, studentData.reg),
    amount: format(repo.node.v_std_amt_total),
    nodeId: repo.node.id,
    node: repo.node,
  }));

  return (
    <>
      <Title>Student Demand Receipt</Title>
      <div className="row g-0">
        <div className="col-2">
          <TextField
            className="student-demand-receipt__textfield"
            label="Receipt Number"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            placeholder=" E.G  001"
            onChange={(e) => setV_no(e.target.value)}
          />
        </div>
        <div className="col"></div>
        <div className="col-3 student-demand-receipt__select-options">
          <TextField
            className="student-demand-receipt__textfield--date"
            label="From"
            type="date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          &nbsp;
          <TextField
            label="To"
            className="student-demand-receipt__textfield--date"
            type="date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>

      <div className="student-demand-receipt__tableblock h-120">
        {!loading && !vouchers?.length ? (
          <b className="nodata">Sorry, no results.</b>
        ) : (
          <StyledDatagrid
            columns={columns}
            rows={tableData}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            onCellClick={(params) => {
              if (params.field === "name") {
                dispatch({
                  type: payloadTypes.SET_RECEPIT_ID,
                  payload: {
                    receiptId: params.row.nodeId,
                  },
                });
                dispatch({
                  type: payloadTypes.SET_RECEIPT_STUDENT_ID,
                  payload: {
                    receiptStudentId: Number(
                      FetchStudentDetails(params.row.node, studentData.id)
                    ),
                  },
                });
                setStudentModal(false);
              }
            }}
          />
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          <Button mode="cancel" onClick={() => setStudentModal(false)} />
        </div>
        <div className="col-2 student-demand-receipt__total flex-end">
          <StudentTotalCount totalCount={data?.GetAcctVouchers.totalCount!} />
        </div>
      </div>
    </>
  );
};

export default StudentDemandReceipt;
