import React, { useContext, useEffect, useState } from "react";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  StyledDatagrid,
  TABLE_ROW_HEIGHT,
} from "../../../../../styles/DataGridTableStyles";
import useStudentTestMarks, {
  AcdStdMarksQueryType,
  TestMarksEdge,
  TestMarksNode,
} from "../../../hooks/useStudentTestMarks";
import { EMPTY_STRING, FETCH_MORE_DATA } from "../../../../../utils/constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  ExportType,
  FileUploadParams,
  InstitutionConfigurationTypes,
  InstitutionType,
  PageFor,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import useTestDetails from "../../../hooks/useTestDetails";
import {
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
  WaiveOffModalStyles,
} from "../../../../../styles/ModalStyles";
import { responseType } from "../../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../../hooks/useTestStatus";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Modal from "react-modal";
import Close from "../../../../../images/Close.svg";
import Eduate from "../../../../../images/Eduate_Logo_image.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { payloadTypes } from "../../../../../context/reducer";
import { AppContext } from "../../../../../context/context";
import ParticularStudentResults from "../../Test/Modal/ParticularStudentResults";
import { Button } from "../../../../../stories/Button/Button";
import Print from "../../../../../images/Print.svg";
import PrintReceipts from "../../../../../images/PrintProgressCard.svg";
import ProgressReport from "../../Progress/Print";
import { Checkbox } from "@mui/material";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_ALIGNMENT_LEFT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../../Library/Constants";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { GetAcdComboSubjectOrderWithMarksDetails } from "../../../../../queries/customallocation/combination/queries";
import {
  GetAcdComboSubjectOrderWithMarksDetailsData,
  GetAcdComboSubjectOrderWithMarksDetailsVars,
} from "../../../../../Types/Combinations/queries";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import Settings from "../../../../../images/Settings.svg";
import PerTestConfig from "../../Progress/PerTestConfigurations";
import useSwConfigInstType from "../../../hooks/useSwConfigInstType";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
export type GridAlignment = "left" | "center" | "right";
interface Props {
  testConductId: responseType | null;
  pageType: PageFor;
  selectedCombination: responseType | null;
  forCombination?: boolean;
}

const ViewParticularTestReportTable = ({
  testConductId,
  pageType,
  selectedCombination,
  forCombination,
}: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { institutionType } = useSwConfigInstType();

  const [selectedStudentDetails, setSelectedStudentDetails] =
    useState<any>(null);
  const { testId, InstId, entryId } = useParams();
  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(entryId!);
  console.log(configdata, "configdata");
  const entryIdname =
    configdata && configdata.GetAcdInstConfigNames.acd_section_desc;

  const navigate = useNavigate();
  const [printPreview, setPrintPreview] = useState(false);
  const [excelModal, setExcelModal] = useState(false);

  const [configuration, setConfiguration] = useState(false);
  const [testConductDetailsId, setTestConductDetailsId] =
    useState<responseType | null>(null);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { serverDate } = useServerDateandTime();
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<TestMarksEdge[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [selectedOption, setSelectedOption] = useState("MARKS");
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const [selectAll, setSelectAll] = useState(false);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [studentIdSet, setStudentIdSet] = useState<Set<number>>(new Set());
  const { InstDetails } = useInstDetails(1);

  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.ALL_TESTS,
    Number(entryId)
  );
  const testName =
    statusOfSubject &&
    statusOfSubject.classTests.find((res) => res.value === Number(testId))
      ?.label;
  const {
    testMarks: { data, fetchMore, loading },
  } = useStudentTestMarks(
    forCombination
      ? AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID
      : AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
    null,
    EMPTY_STRING
  );

  const { testConductDetails } = useTestDetails();

  const [GetAcdSubjects, { data: comboSubjectsList }] = useLazyQuery<
    GetAcdComboSubjectOrderWithMarksDetailsData,
    GetAcdComboSubjectOrderWithMarksDetailsVars
  >(GetAcdComboSubjectOrderWithMarksDetails, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_id: testId!,
      acd_subj_combo_id: selectedCombination ? selectedCombination.value : 0,
    },
  });

  const status =
    statusOfSubject &&
    statusOfSubject.data &&
    statusOfSubject.data.GetAcdTestMarksStatus?.find(
      (d) => d?.acd_test_class_id === Number(testId) || testConductId?.value
    );
  const isNonAcademic =
    statusOfSubject &&
    statusOfSubject.data &&
    statusOfSubject.data.GetAcdTestMarksStatus?.find(
      (d) => d?.acd_test_class_id === testConductId?.value
    )?.is_non_academic;

  const subjectsLabels =
    testConductDetails?.subjectsOptions?.map((res) => res.label) || [];

  const combinedLabels = subjectsLabels.flatMap((subject) => [
    subject,
    ...(selectedOption === ExportType.BOTH ? ["Grade"] : []),
  ]);

  const StudentHeaders = [
    "SlNo",
    "Admission No",
    "Reg No",
    "Student Name",
    ...combinedLabels,
    selectedOption === ExportType.GRADES ? "Overall Grade" : "Total Marks",
    selectedOption === ExportType.MARKS ? "Total Percentage" : "",
    selectedOption === ExportType.BOTH ? "Overall Grade" : null,
    selectedOption === ExportType.BOTH ? "Overall Test Remarks" : null,
  ];

  const StudentHeadersForPdf = [
    { title: "SlNo", field: "SlNo" },
    { title: "Admission No", field: "Admission No" },
    { title: "Reg No", field: "Reg No" },
    { title: "Student Name", field: "Student Name" },
    ...subjectsLabels.map((label) => ({ title: label, field: label })),
    {
      title:
        selectedOption === ExportType.GRADES ? "OverAll Grade" : "Total Marks",
      field: "Total Marks",
    },
  ];

  //Headers
  const dynamicHeaders = forCombination
    ? (comboSubjectsList
        ? comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails
        : []
      ).map((th, index) => ({
        field: `subj_marks_scored_total_${index + 1}`,
        headerName: `${th.subj_desc}  (${
          th.subj_total_max_marks + "/" + th.subj_total_min_marks
        })`,
        headerAlign: HEADER_TEXT_ALIGN as GridAlignment,
        align: MARKS_TEXT_ALIGN as GridAlignment,
        cellClassName: "td-marks",
        renderCell: (params: any) => {
          return (
            <div
              className={
                params.row[`subj_marks_scored_total_${index + 1}`] === "-"
                  ? `bg-grey`
                  : ""
              }
              style={{ width: "100%" }}>
              {params.row[`subj_marks_scored_total_${index + 1}`]}
            </div>
          );
        },
      }))
    : (testConductDetails.data?.node.class_subject_details || []).flatMap(
        (th, index) => {
          const isNonAcademic = th.subject_master_details.subj_is_non_academic;
          const baseColumn = {
            field: isNonAcademic
              ? `scored_grade_${index + 1}`
              : `subj_marks_scored_total_${index + 1}`,
            headerName: `${th.subject_master_details.subj_desc} (${
              th.subj_total_max_marks + "/" + th.subj_total_min_marks
            })`,
            headerAlign: HEADER_TEXT_ALIGN as GridAlignment,
            align: MARKS_TEXT_ALIGN as GridAlignment,
            cellClassName: "td-marks",
            renderCell: (params: any) => {
              return (
                <div
                  className={
                    params.row[
                      isNonAcademic
                        ? `scored_grade_${index + 1}`
                        : `subj_marks_scored_total_${index + 1}`
                    ] === "-"
                      ? `bg-grey`
                      : ""
                  }
                  style={{ width: "100%" }}>
                  {
                    params.row[
                      isNonAcademic
                        ? `scored_grade_${index + 1}`
                        : `subj_marks_scored_total_${index + 1}`
                    ]
                  }
                </div>
              );
            },
          };

          const remarksColumn = isNonAcademic
            ? {
                field: `teacher_comments_${index + 1}`,
                headerName: "Remarks",
                headerAlign: HEADER_TEXT_ALIGN as GridAlignment,
                align: MARKS_TEXT_ALIGN as GridAlignment,
                cellClassName: "td-marks",
                renderCell: (params: any) => {
                  return (
                    <div
                      className={
                        params.row[`teacher_comments_${index + 1}`] === "-"
                          ? `bg-grey`
                          : ""
                      }
                      style={{ width: "100%" }}>
                      {params.row[`teacher_comments_${index + 1}`]}
                    </div>
                  );
                },
              }
            : null;

          return [baseColumn, remarksColumn].filter(Boolean);
        }
      ) || [];
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "std_adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    // @ts-ignore
    ...(institutionType !== InstitutionType.SCHOOL
      ? [
          {
            headerName: TableHeaders.REGISTER_NUMBER,
            field: "std_reg_no",
            headerAlign: HEADER_TEXT_ALIGN,
            align: SLNO_TEXT_ALIGN,
            cellClassName: "td-adm-no",
          },
        ]
      : []),
    // @ts-ignore
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-onClick td-name",
      flex: 1,
      hideable: false,
    },
    // @ts-ignore
    ...dynamicHeaders,
    // @ts-ignore
    {
      field: "total_marks",
      headerName: "Total Marks ",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-marks",
      align: MARKS_TEXT_ALIGN,
    },
    // @ts-ignore
    ...(isNonAcademic
      ? []
      : [
          {
            field: "total_percent",
            headerName: "Total Percentage",
            headerAlign: HEADER_TEXT_ALIGN,
            cellClassName: "td-marks",
            align: MARKS_TEXT_ALIGN,
          },
        ]),
    // @ts-ignore
    {
      field: "print",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      renderHeader: (params) => {
        return (
          <>
            <Checkbox checked={selectAll} onChange={() => handleSelectAll()} />
            Print Reports
            <img src={Print} alt="" />
          </>
        );
      },
      renderCell: (params) => {
        const stdId = params.row?.std_id || "";
        return (
          <>
            {studentIdSet.has(stdId)}
            <Checkbox
              checked={selectAll || studentIdSet.has(Number(stdId))}
              onChange={(e) => hanldeStudentClick(params, e)}
            />
            <img src={PrintReceipts} alt="" />
          </>
        );
      },
    },
  ];

  //tabledata
  const getDynamicDataForTable = (node: TestMarksNode) => {
    let marks: Record<string, string | number> = {};
    if (forCombination) {
      comboSubjectsList &&
        comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.forEach(
          ({ subj_sl, subj_master_id }, index) => {
            const fieldName = `subj_marks_scored_total_${subj_sl}`;
            const statusField = `subj_marks_status_${subj_sl}`;
            const subjectId = `elective_subj_id_${subj_sl}`;

            const res = node[fieldName as keyof TestMarksNode];
            const resSubjectId = node[subjectId as keyof TestMarksNode];

            const marksEntryStatus = status && [
              // @ts-ignore
              status[statusField] as boolean,
            ];

            const subjMarks =
              subj_master_id === resSubjectId &&
              marksEntryStatus &&
              marksEntryStatus[0]
                ? // @ts-ignore
                  res
                : resSubjectId === 0
                ? // @ts-ignore
                  res
                : "-";
            // @ts-ignore
            marks = {
              ...marks,
              [`subj_marks_scored_total_${index + 1}`]:
                subjMarks === "-1" ? "A" : subjMarks,
            };
          }
        );
    } else {
      testConductDetails.data &&
        testConductDetails.data.node.class_subject_details.forEach(
          ({ subj_sl, subject_master_details }, index) => {
            const fieldName = `subj_marks_scored_total_${subj_sl}`;
            const gradeFieldName = `scored_grade_${subj_sl}`;
            const commentsFieldName = `teacher_comments_${subj_sl}`;
            const subjectIdField = `elective_subj_id_${subj_sl}`;

            const resMarks = node[fieldName as keyof TestMarksNode];
            const resGrade = node[gradeFieldName as keyof TestMarksNode];
            const resComments = node[commentsFieldName as keyof TestMarksNode];
            const resSubjectId = node[subjectIdField as keyof TestMarksNode];

            const marksKey = `subj_marks_scored_total_${index + 1}`;
            const gradeKey = `scored_grade_${index + 1}`;
            const commentsKey = `teacher_comments_${index + 1}`;
            if (subject_master_details.subj_is_non_academic) {
              // @ts-ignore
              marks[gradeKey] = resGrade;
              // @ts-ignore
              marks[commentsKey] = resComments || "";
            } else {
              if (
                subject_master_details.id === resSubjectId ||
                resSubjectId === 0
              ) {
                // @ts-ignore
                marks[marksKey] = resMarks >= 0 ? Number(resMarks) : "A";
                // @ts-ignore
                marks[gradeKey] = resGrade;
                // @ts-ignore
                marks[commentsKey] = resComments || "";
              } else {
                marks[marksKey] = "";
                marks[gradeKey] = "";
                marks[commentsKey] = "";
              }
            }
          }
        );

      marks["total_marks"] = node.total_scored_marks
        ? node.total_scored_marks
        : node.overall_grade;
      marks["total_percent"] = `${node.total_percent} %`;

      return marks;
    }
  };
  const getDynamicDataForNonAcdTable = (node: TestMarksNode) => {
    let marks: Record<string, string | number> = {};
    if (forCombination) {
      comboSubjectsList &&
        comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.forEach(
          ({ subj_sl, subj_master_id }, index) => {
            const fieldName = `subj_marks_scored_total_${subj_sl}`;
            const statusField = `subj_marks_status_${subj_sl}`;
            const subjectId = `elective_subj_id_${subj_sl}`;

            const res = node[fieldName as keyof TestMarksNode];
            const resSubjectId = node[subjectId as keyof TestMarksNode];

            const marksEntryStatus = status && [
              // @ts-ignore
              status[statusField] as boolean,
            ];

            const subjMarks =
              subj_master_id === resSubjectId &&
              marksEntryStatus &&
              marksEntryStatus[0]
                ? // @ts-ignore
                  res
                : resSubjectId === 0
                ? // @ts-ignore
                  res
                : "-";
            // @ts-ignore
            marks = {
              ...marks,
              [`subj_marks_scored_total_${index + 1}`]:
                subjMarks === "-1" ? "A" : subjMarks,
            };
          }
        );
    } else {
      testConductDetails.data &&
        testConductDetails.data.node.class_subject_details.forEach(
          ({ subj_sl, subject_master_details }, index) => {
            const fieldName = `subj_marks_scored_total_${subj_sl}`;
            const gradeFieldName = `scored_grade_${subj_sl}`;
            const commentsFieldName = `teacher_comments_${subj_sl}`;
            const subjectIdField = `elective_subj_id_${subj_sl}`;

            const resMarks = node[fieldName as keyof TestMarksNode];
            const resGrade = node[gradeFieldName as keyof TestMarksNode];
            const resComments = node[commentsFieldName as keyof TestMarksNode];
            const resSubjectId = node[subjectIdField as keyof TestMarksNode];

            const marksKey = `subj_marks_scored_total_${index + 1}`;
            const gradeKey = `scored_grade_${index + 1}`;
            const commentsKey = `teacher_comments_${index + 1}`;
            if (subject_master_details.subj_is_non_academic) {
              // @ts-ignore
              marks[gradeKey] =
                resGrade + " " + (resComments ? `(${resComments})` : "");
              // @ts-ignore
            } else {
              if (
                subject_master_details.id === resSubjectId ||
                resSubjectId === 0
              ) {
                // @ts-ignore
                marks[marksKey] = resMarks >= 0 ? Number(resMarks) : "A";
                // @ts-ignore
                marks[gradeKey] = resGrade;
                // @ts-ignore
                marks[commentsKey] = resComments || "";
              } else {
                marks[marksKey] = "";
                marks[gradeKey] = "";
                marks[commentsKey] = "";
              }
            }
          }
        );

      marks["total_marks"] = node.total_scored_marks
        ? node.total_scored_marks
        : node.overall_grade;

      return marks;
    }
  };
  const getDynamicData = (node: TestMarksNode) => {
    let marks: Record<string, string | number> = {};
    if (forCombination) {
      comboSubjectsList &&
        comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.forEach(
          ({ subj_sl, subj_master_id }, index) => {
            const fieldName = `subj_marks_scored_total_${subj_sl}`;
            const statusField = `subj_marks_status_${subj_sl}`;
            const subjectId = `elective_subj_id_${subj_sl}`;

            const res = node[fieldName as keyof TestMarksNode];
            const resSubjectId = node[subjectId as keyof TestMarksNode];

            const marksEntryStatus = status && [
              // @ts-ignore
              status[statusField] as boolean,
            ];

            const subjMarks =
              subj_master_id === resSubjectId &&
              marksEntryStatus &&
              marksEntryStatus[0]
                ? // @ts-ignore
                  res
                : resSubjectId === 0
                ? // @ts-ignore
                  res
                : "-";
            // @ts-ignore
            marks = {
              ...marks,
              [`subj_marks_scored_total_${index + 1}`]:
                subjMarks === "-1" ? "A" : subjMarks,
            };
          }
        );
    } else {
      testConductDetails.data &&
        testConductDetails.data.node.class_subject_details.forEach(
          ({ subj_sl, subject_master_details }, index) => {
            const fieldName = `subj_marks_scored_total_${subj_sl}`;
            const gradeFieldName = `scored_grade_${subj_sl}`;
            const subjectIdField = `elective_subj_id_${subj_sl}`;

            const resMarks = node[fieldName as keyof TestMarksNode];
            const resGrade = node[gradeFieldName as keyof TestMarksNode];
            const resSubjectId = node[subjectIdField as keyof TestMarksNode];

            const marksKey = `subj_marks_scored_total_${index + 1}`;
            const gradeKey = `scored_grade_${index + 1}`;

            if (
              subject_master_details.id === resSubjectId ||
              resSubjectId === 0
            ) {
              if (
                selectedOption === ExportType.BOTH ||
                selectedOption === ExportType.MARKS
              ) {
                // @ts-ignore
                marks[marksKey] = resMarks >= 0 ? Number(resMarks) : "A";
              }
              if (
                selectedOption === ExportType.BOTH ||
                selectedOption === ExportType.GRADES
              ) {
                // @ts-ignore
                marks[gradeKey] = resGrade;
              }
            } else {
              marks[marksKey] = "";
              marks[gradeKey] = "";
            }
          }
        );
    }
    if (
      selectedOption === ExportType.BOTH ||
      selectedOption === ExportType.MARKS
    ) {
      marks["total_marks"] = node.total_scored_marks;
    }
    if (selectedOption === ExportType.MARKS) {
      marks["total_percent"] = `${node.total_percent} %`;
    }
    if (
      selectedOption === ExportType.BOTH ||
      selectedOption === ExportType.GRADES
    ) {
      marks["overall_grade"] = node.overall_grade;
    }

    return marks;
  };

  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "std_name") {
      const selectedRow = rows.find((row) => row.id === params.id);

      if (selectedRow) {
        const stdId = selectedRow.std_id;

        setSelectedStudentDetails(selectedRow);
        dispatch({
          type: payloadTypes.SET_STUDENT_ID,
          payload: {
            studentId: stdId!,
          },
        });
      }
    }
  };
  const getMarksRowData = (item: TestMarksEdge, index: number): any[] => {
    const studentDetails = item.node.student_details;
    const fullName = `${studentDetails.first_name} ${studentDetails.middle_name} ${studentDetails.last_name}`;
    const marksData = getDynamicData(item.node);

    return [
      index + 1,
      studentDetails.std_adm_no,
      studentDetails.std_reg_no,
      fullName,
      ...Object.values(marksData),
      selectedOption === ExportType.BOTH ? item.node.test_remarks : null,
    ];
  };
  const tableDataForExcelAndPdf =
    data &&
    data.GetStdTestMarks.edges &&
    data.GetStdTestMarks.edges.map((item: TestMarksEdge, index) =>
      getMarksRowData(item, index)
    );

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${entryIdname} ${testName}`);

    const dataRows = tableDataForExcelAndPdf;

    worksheet.views = [{ state: "frozen", ySplit: 4 }];

    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;

    for (let i = 1; i <= 30; i++) {
      worksheet.getColumn(i).width = 25;
    }

    const getColumnName = (index: any) => {
      let columnName = "";
      while (index > 0) {
        let remainder = (index - 1) % 26;
        columnName = String.fromCharCode(65 + remainder) + columnName;
        index = Math.floor((index - 1) / 26);
      }
      return columnName;
    };
    //@ts-ignore
    const embedLogo = (url, position) => {
      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              //@ts-ignore
              const base64 = reader.result.toString();
              const imageId = workbook.addImage({
                base64,
                extension: "png",
              });
              worksheet.addImage(imageId, position);
              //@ts-ignore
              resolve();
            };
          });
        });
    };

    const addHeaderDetails = () => {
      const instDetails = InstDetails.data?.nodes[0];
      const headerStyle = getHeaderRowStyle();

      worksheet.mergeCells("A1:H1");
      const nameCell = worksheet.getCell("A1");
      nameCell.value = instDetails?.inst_name || "Institution Name";
      nameCell.font = headerStyle[0].font;
      nameCell.fill = HEADER_CSS;
      nameCell.alignment = HEADER_ALIGNMENT;

      worksheet.mergeCells("A2:G2");
      const addressCell = worksheet.getCell("A2");
      addressCell.value = instDetails?.inst_address || "Institution Address";
      addressCell.font = ADDRESS_FONT;
      addressCell.fill = HEADER_CSS;
      addressCell.alignment = HEADER_ALIGNMENT;

      worksheet.mergeCells("A3:H3");
      const placeCell = worksheet.getCell("A3");
      placeCell.value = `${instDetails?.inst_place || "Place"} - ${
        instDetails?.inst_pin || "PIN"
      }`;
      placeCell.font = ADDRESS_FONT;
      placeCell.fill = HEADER_CSS;
      placeCell.alignment = ADDRESS_ALIGNMENT;

      worksheet.mergeCells("C4:E4");
      const reportCell = worksheet.getCell("C4");
      reportCell.value = `Academics Test/Exam Reports  ${entryIdname} ${testName}`;
      reportCell.font = FILE_NAME_FONT;
      reportCell.fill = FILE_NAME_CSS;
      reportCell.alignment = HEADER_ALIGNMENT_CENTER;

      worksheet.mergeCells("F4:H4");
      const dateCell = worksheet.getCell("F4");
      dateCell.value = `Date: ${toStandardDate(serverDate)}`;
      dateCell.font = FIN_YEAR_FONT;
      dateCell.fill = FILE_NAME_CSS;
      dateCell.alignment = HEADER_ALIGNMENT_CENTER;

      worksheet.mergeCells("A4:B4");
      const yearCell = worksheet.getCell("A4");
      yearCell.value = `Year: ${state.ActiveFinYr?.fin_yr || "N/A"}`;
      yearCell.font = FIN_YEAR_FONT;
      yearCell.fill = FILE_NAME_CSS;
      yearCell.alignment = HEADER_ALIGNMENT_LEFT;
    };

    addHeaderDetails();

    StudentHeaders.forEach((header, index) => {
      const columnName = getColumnName(index + 1);
      const cell = worksheet.getCell(`${columnName}5`);
      cell.value = header;
      cell.fill = TABLE_HEADER_CSS;
      cell.border = BORDER_DATA;
      cell.font = ACC_HEADER_FONT;
      cell.alignment = { horizontal: ExcelAlignment.CENTER };
    });

    dataRows &&
      dataRows.forEach((rowData) => {
        const row = worksheet.addRow(rowData);
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = { horizontal: ExcelAlignment.LEFT };
        });
      });

    Promise.all([
      embedLogo(Eduate, "H1:H3"),
      embedLogo(LogoOrSign.defaultLogo, "B1:B3"),
    ]).then(() => {
      // Save workbook
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${entryIdname} ${testName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    });
  };

  const downloadExcelForNonAcademic = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Academics Test Reports");
    const getMarksRowDataForNonAcd = (
      item: TestMarksEdge,
      index: number
    ): any[] => {
      const studentDetails = item.node.student_details;
      const fullName = `${studentDetails.first_name} ${studentDetails.middle_name} ${studentDetails.last_name}`;
      const marksData = getDynamicDataForNonAcdTable(item.node);

      return [
        index + 1,
        studentDetails.std_adm_no,
        studentDetails.std_reg_no,
        fullName,
        ...Object.values(marksData!),
      ];
    };
    const tableData =
      data &&
      data.GetStdTestMarks.edges &&
      data.GetStdTestMarks.edges.map((item: TestMarksEdge, index) =>
        getMarksRowDataForNonAcd(item, index)
      );

    const dataRows = tableData;

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 25;
    worksheet.getColumn(11).width = 25;
    worksheet.getColumn(12).width = 25;
    worksheet.getColumn(13).width = 25;
    worksheet.getColumn(14).width = 25;
    worksheet.getColumn(15).width = 25;
    worksheet.getColumn(16).width = 25;
    worksheet.getColumn(17).width = 25;
    worksheet.getColumn(18).width = 25;
    worksheet.getColumn(19).width = 25;
    worksheet.getColumn(20).width = 25;
    worksheet.getColumn(21).width = 25;
    worksheet.getColumn(22).width = 25;
    worksheet.getColumn(23).width = 25;
    worksheet.getColumn(24).width = 25;
    worksheet.getColumn(25).width = 25;
    worksheet.getColumn(26).width = 25;
    worksheet.getColumn(27).width = 25;
    worksheet.getColumn(28).width = 25;
    worksheet.getColumn(29).width = 25;
    worksheet.getColumn(30).width = 25;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "H1:H3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, 30);
                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;
                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:G2");
                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:H3");
                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Academics Test/Exam Reports";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell(F4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("F4:H4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");
                  let Char = FIRST_INDEX;
                  for (let i = 0; i < StudentHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = StudentHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Academics Test/Exam Reports`, 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: StudentHeadersForPdf.map((col) => ({
                    ...col,
                    dataKey: col.field,
                    styles: { fontSize: 18 },
                  })),

                  body: tableDataForExcelAndPdf,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
  };

  const hanldeStudentClick = (
    params: GridCellParams,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (params.field === "print") {
      const selectedRow = rows.find((row) => row.id === params.id);
      const tempSet: Set<number> = new Set(studentIdSet);
      const checked = e.target.checked;
      if (selectedRow && selectedRow.std_id) {
        if (checked) {
          tempSet.add(selectedRow.std_id);
        } else {
          tempSet.delete(selectedRow.std_id);
        }
      }
      setStudentIdSet(tempSet);
      setSelectedStudents(Array.from(tempSet)); // Update selected students
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allStudentIds = rows
        .map((student) => student.std_id)
        .filter((id) => Number.isInteger(id)) as number[];
      setSelectedStudents(allStudentIds);
      setSelectAll(true);
    } else {
      setSelectedStudents([]);
      setSelectAll(false);
    }
  };
  useEffect(() => {
    if (testId && testConductDetailsId === null) {
      setTestConductDetailsId(
        statusOfSubject.classTests.find(
          ({ value }) => value === Number(testId)
        ) ?? null
      );
    }
  }, [testId, statusOfSubject.classTests]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStdTestMarks.edges;
                const pageInfo = fetchMoreResult.GetStdTestMarks.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetStdTestMarks.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetStdTestMarks: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetStdTestMarks.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStdTestMarks.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id && row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            ...getDynamicDataForTable(node),
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            ...getDynamicDataForTable(node),
          }))
        );
      }
      setEndCursor(data.GetStdTestMarks?.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    data,
    loading,
    testConductDetails.data,
    forCombination,
    comboSubjectsList,
  ]);
  useEffect(() => {
    if (token) {
      GetAcdSubjects();
    }
  }, [InstId, selectedCombination, state.ActiveAcdYr, token, GetAcdSubjects]);
  return (
    <>
      <div className={`acd-reports__per-test--tableblock `}>
        <StyledDatagrid
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          onCellClick={(params: GridCellParams, studentId) => {
            handleCellClick(params);
          }}
          onColumnHeaderClick={() => handleSelectAll()}
          disableRowSelectionOnClick
          hideFooter
        />
      </div>
      <Button
        mode="print"
        disabled={selectedStudents.length === 0}
        onClick={() => {
          setPrintPreview(!printPreview);
        }}
      />
      {!isNonAcademic && (
        <Button
          mode="excel"
          type="button"
          onClick={() => setExcelModal(!excelModal)}>
          Export
        </Button>
      )}
      {isNonAcademic && (
        <Button
          mode="excel"
          type="button"
          onClick={downloadExcelForNonAcademic}>
          Export
        </Button>
      )}
      <Button mode="pdf" type="button" onClick={downloadPdf} />
      {pageType === PageFor.GENERAL ? (
        <Button mode="back" onClick={() => navigate(-1)} />
      ) : // <Button mode="cancel" onClick={() => setModal?.(false)} />
      null}

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={selectedStudentDetails}
        ariaHideApp={false}
        style={StudentModalStyles}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ParticularStudentResults
              setModalFlag={setSelectedStudentDetails}
              testConductId={testConductId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setSelectedStudentDetails(!selectedStudentDetails)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printPreview}
        style={PrintModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ProgressReport
              testConductId={testConductId}
              setModalFlag={setPrintPreview}
              selectedStudents={selectedStudents}
              setStudentIdSet={setStudentIdSet}
              forCombination={forCombination}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => {
                setPrintPreview(!printPreview);
                setStudentIdSet(new Set());
              }}
            />
            <img
              src={Settings}
              alt="/"
              onClick={() => setConfiguration(!configuration)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={excelModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}>
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <form>
              <div>
                <input
                  type="radio"
                  id="withMarks"
                  name="exportOption"
                  value={ExportType.MARKS}
                  checked={selectedOption === ExportType.MARKS}
                  onChange={handleOptionChange}
                />
                &nbsp;
                <label htmlFor="withMarks">Export with Marks Only</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="withGrades"
                  name="exportOption"
                  value={ExportType.GRADES}
                  checked={selectedOption === ExportType.GRADES}
                  onChange={handleOptionChange}
                />
                &nbsp;
                <label htmlFor="withGrades">Export with Grades Only</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="both"
                  name="exportOption"
                  value={ExportType.BOTH}
                  checked={selectedOption === ExportType.BOTH}
                  onChange={handleOptionChange}
                />
                &nbsp;
                <label htmlFor="both">Export Both</label>
              </div>
            </form>
            <Button
              mode="save"
              disabled={!selectedOption}
              onClick={downloadExcel}
            />
            <Button mode="cancel" onClick={() => setExcelModal(false)} />
          </div>

          <div className="modal-flex__image">
            <img
              src={Close}
              alt="Close"
              onClick={() => setExcelModal(!excelModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        style={PrintConfigModalStyles}
        isOpen={configuration}>
        <PerTestConfig setModalFlag={setConfiguration} />
      </Modal>
    </>
  );
};

export default ViewParticularTestReportTable;
