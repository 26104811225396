import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { PassOutStudentNode } from "../queries/common";
import {
  AcademicYear,
  BranchesList,
  CategoryList,
  ClassList,
  DepartmentList,
  FinancialYear,
  SectionList,
  SemesterList,
} from "../Types/Student";
import {
  IObjectKeys,
  studentPersonalData,
  studentPreviousSchoolData,
} from "../utils/Form.types";
import {
  acct_std_demand,
  acct_std_demand_details,
} from "../Modules/Accounts/hooks/useStudentsbyDemandAmount";
import useToken from "./useToken";
import { InstitutionNode } from "./useInstitutionsByCustId";
import { PassOutStudentDetails } from "../Types/Accounting";
import { CreatedByNamesDataVars } from "../Modules/Channel/Types";
import { EMPTY_STRING } from "../utils/constants";

export interface parentData extends IObjectKeys {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  parent_address: string;
  parent_place: string;
  parent_state: string;
  parent_pin: string;
  parent_email: string;
  parent_mobile: string;
  parent_occup: string;
  parent_qualification: string;
  parent_aadhar: string;
  parent_type: string;
}
export interface Parents {
  relationship: string;
  parent: parentData;
}
interface perstudentDetamdDetails extends acct_std_demand {
  demand_details: acct_std_demand_details[];
}
export interface StudentDetails {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  std_reg_no: string;
  std_adm_no: string;
  acd_yr: AcademicYear;
  fin_yr: FinancialYear;
  std_father_name: string;
  std_studying: boolean;
  std_sex: string;
  std_doa: string;
  std_fresher: boolean;
  std_mobile: string;
  std_status: string;
  std_email: string;
  std_mother_name: string;
  std_dob: string;
  std_profile_filename: string;
  class: ClassList;
  acd_class: ClassList;
  branch: BranchesList;
  dept: DepartmentList;
  semester: SemesterList;
  acd_semester: SemesterList;

  section: SectionList;
  acd_section: SectionList;
  category: CategoryList;
  std_other_details: studentPreviousSchoolData;
  std_details: studentPersonalData;
  parents: Parents[];
  acct_std_demand: perstudentDetamdDetails;
  inst_details: InstitutionNode;
}
export interface StudentData {
  nodes: StudentDetails[];
}
export interface StudentDetailsvars {
  token: string;
  ids: number[] | number;
}
export interface Istudentform extends IObjectKeys {
  std_name: string;
  std_passout_yr: string;
  std_email: string;
  std_mobile: string;
  class_desc: string;
  branch_desc?: string;
  branch_id?: number;
}
const usePassedOutStudent = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [passedOutStudentFormData, setPassedOutStudentFormData] =
    useState<Istudentform>({
      std_email: "",
      std_mobile: "",
      std_passout_yr: "",
      std_name: "",
      class_desc: "",
      branch_desc: "",
      branch_id: 0,
    });
  const [GetAluminiStudent, GetAluminiStudentData] = useLazyQuery<
    PassOutStudentDetails,
    CreatedByNamesDataVars
  >(PassOutStudentNode, {
    fetchPolicy: "network-only",
  });

  const handleClear = () => {
    setPassedOutStudentFormData({
      std_name: "",
      std_passout_yr: "",
      std_email: "",
      std_mobile: "",
      class_desc: "",
      branch_desc: "",
      branch_id: 0,
    });
  };
  useEffect(() => {
    if (!state.aluminiStudentId) {
      handleClear();
    }
  }, [state.aluminiStudentId]);

  useEffect(() => {
    if (state.aluminiStudentId && token) {
      GetAluminiStudent({
        variables: {
          token,
          ids: [state.aluminiStudentId],
        },
      }).then(({ data }) => {
        if (data) {
          const {
            std_name,
            std_passout_yr,
            std_email,
            std_mobile,
            class_details,
            branch_details,
          } = data.nodes[0];
          setPassedOutStudentFormData({
            std_name,
            std_passout_yr,
            std_email,
            std_mobile,
            class_desc: class_details ? class_details.class_desc : EMPTY_STRING,
            branch_desc: branch_details
              ? branch_details.branch_desc
              : EMPTY_STRING,
            branch_id: branch_details ? branch_details.id : 0,
          });
        }
      });
    }
  }, [state.aluminiStudentId, GetAluminiStudent, token]);
  return {
    studentData: GetAluminiStudentData,
    passedOutStudentFormData,
  };
};

export default usePassedOutStudent;
