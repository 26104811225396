import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import TextArea from "../../../stories/TextArea/TextArea";
import Close from "../../../images/Close.svg";
import { Button } from "../../../stories/Button/Button";
import { Operation } from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddAcdLessonSubjectObjective,
  DeleteAcdLessonSubjectObjective,
  UpdateAcdLessonSubjectObjective,
} from "../queries/lesson_planner/mutation";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { SubjectType } from "../types/subject/Subject";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { EMPTY_STRING, emptyMessageType } from "../../../utils/constants";
import MessageModal from "../../../pages/MessageModal";
import {
  GetAcdLessonPlannerMain,
  GetAcdLessonSubjectObjectives,
} from "../queries/lesson_planner/list";
import { GetAcdSubjectForEntryLevel } from "../queries/subjects/List.tsx/Index";

import { editDetailsType } from "./List";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
import {
  GetAcdLessonPlannerMainVars,
  GetAcdLessonSubjectObjectivesData,
} from "../types/lessonplanner/queries";
import { AppContext } from "../../../context/context";
import DeleteModal from "../../../pages/DeleteModal";
interface Props {
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  selectedSubject: SubjectType | null;
  editDetails: editDetailsType;
  setOperation: React.Dispatch<React.SetStateAction<Operation>>;
}
const AddSubjectDetail = ({
  setDrawer,
  operation,
  selectedSubject,
  editDetails,
  setOperation,
}: Props) => {
  const { flag } = useCheckAllocationType();

  const [AddAcdSubjectObjective] = useMutation(AddAcdLessonSubjectObjective);

  const [UpdateAcdSubjectObjective] = useMutation(
    UpdateAcdLessonSubjectObjective
  );

  const [DeleteAcdSubjectObjective] = useMutation(
    DeleteAcdLessonSubjectObjective
  );
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const [subjectObjectives, setSubjectObjectives] = useState("");
  const [learningOutcome, setLearningOutcome] = useState("");
  const [referenceBooks, setReferenceBooks] = useState("");
  const [youtubeLinks, setYoutubeLinks] = useState("");
  const [powerPointLinks, setPowerPointLinks] = useState("");
  const [caseStudies, setCaseStudies] = useState("");
  const [researchLinks, setResearchLinks] = useState("");
  const [otherLinks, setotherLinks] = useState("");
  const [objectiveId, setObjectiveId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const [message, setMessage] = useState(emptyMessageType);
  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(Number(entryId));
  const { user_details } = useLoggedInUserDetails();
  const [GetAcdLessonSubjectObjective] = useLazyQuery<
    GetAcdLessonSubjectObjectivesData,
    GetAcdLessonPlannerMainVars
  >(GetAcdLessonSubjectObjectives);
  useEffect(() => {
    if (
      token &&
      state.ActiveAcdYr &&
      alloted.id &&
      alloted.level &&
      selectedSubject
    )
      GetAcdLessonSubjectObjective({
        variables: {
          token,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          allotted_id: alloted.id,
          allotted_level: alloted.level,
          inst_id: InstId!,
          subj_master_id:
            selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
        },
      }).then(({ data }) => {
        if (data && data.GetAcdLessonSubjectObjectives) {
          setSubjectObjectives(
            data && data.GetAcdLessonSubjectObjectives.subj_objective
          );
          setLearningOutcome(
            data && data.GetAcdLessonSubjectObjectives.learning_outcome
          );
          setReferenceBooks(
            data && data.GetAcdLessonSubjectObjectives.ref_books
          );
          setYoutubeLinks(
            data && data.GetAcdLessonSubjectObjectives.ref_youtube_links
          );
          setPowerPointLinks(
            data && data.GetAcdLessonSubjectObjectives.ref_ppt_links
          );
          setCaseStudies(
            data && data.GetAcdLessonSubjectObjectives.case_studies
          );
          setResearchLinks(
            data && data.GetAcdLessonSubjectObjectives.research_paper_links
          );
          setotherLinks(data && data.GetAcdLessonSubjectObjectives.other_links);
          setObjectiveId(data && data.GetAcdLessonSubjectObjectives.id);
        }
      });
  }, [
    GetAcdLessonSubjectObjective,
    token,
    state.ActiveAcdYr,
    alloted.id,
    alloted.level,
    selectedSubject,
    InstId,
  ]);
  const HandleDelete = (id: Number) => {
    DeleteAcdSubjectObjective({
      variables: {
        token,
        subj_objective_id: id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdLessonPlannerMain,
          variables: {
            token,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            allotted_id: alloted.id,
            allotted_level: alloted.level,
            inst_id: InstId!,
            subj_master_id: selectedSubject ? selectedSubject.id : 0,
          },
        },
        {
          query: GetAcdSubjectForEntryLevel,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            entry_id: Number(entryId)!,
            entry_level: entry_level,
            per_std_subj_allocation: flag,
          },
        },
        {
          query: GetAcdLessonSubjectObjectives,
          variables: {
            token,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            allotted_id: alloted.id,
            allotted_level: alloted.level,
            inst_id: InstId!,
            subj_master_id:
              selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Subject Detail Deleted successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleSubmit = () => {
    if (operation === Operation.CREATE)
      AddAcdSubjectObjective({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: activeAcademicYearData.data
            ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
            : 0,

          allotted_level: alloted.level,
          allotted_id: alloted.id,
          subj_master_id:
            selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
          user_details,
          input: {
            subj_objective: subjectObjectives
              ? subjectObjectives
              : EMPTY_STRING,
            learning_outcome: learningOutcome ? learningOutcome : EMPTY_STRING,
            ref_books: referenceBooks ? referenceBooks : EMPTY_STRING,
            ref_youtube_links: youtubeLinks
              ? youtubeLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            ref_ppt_links: powerPointLinks
              ? powerPointLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            case_studies: caseStudies ? caseStudies : EMPTY_STRING,
            research_paper_links: researchLinks
              ? researchLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            other_links: otherLinks
              ? otherLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
          },

          per_std_subj_allocation: flag!,
        },
        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject ? selectedSubject.id : 0,
            },
          },
          {
            query: GetAcdSubjectForEntryLevel,
            variables: {
              token,
              inst_id: InstId!,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              per_std_subj_allocation: flag,
            },
          },
          {
            query: GetAcdLessonSubjectObjectives,
            variables: {
              token,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id:
                selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Subject Detail created successfully",
            operation: Operation.CREATE,
          });
        }
      });

    if (operation === Operation.UPDATE) {
      UpdateAcdSubjectObjective({
        variables: {
          token,
          subj_objective_id: objectiveId,
          inst_id: InstId!,
          user_details,
          input: {
            subj_objective: subjectObjectives
              ? subjectObjectives
              : EMPTY_STRING,
            learning_outcome: learningOutcome ? learningOutcome : EMPTY_STRING,
            ref_books: referenceBooks ? referenceBooks : EMPTY_STRING,
            ref_youtube_links: youtubeLinks
              ? youtubeLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            ref_ppt_links: powerPointLinks
              ? powerPointLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            case_studies: caseStudies ? caseStudies : EMPTY_STRING,
            research_paper_links: researchLinks
              ? researchLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
            other_links: otherLinks
              ? otherLinks
                  .split(",")
                  .map((link) => link.trim())
                  .join("\n")
              : EMPTY_STRING,
          },
        },

        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject ? selectedSubject.id : 0,
            },
          },
          {
            query: GetAcdSubjectForEntryLevel,
            variables: {
              token,
              inst_id: InstId!,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              per_std_subj_allocation: flag,
            },
          },
          {
            query: GetAcdLessonSubjectObjectives,
            variables: {
              token,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id:
                selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Subject Detail updated successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDrawer(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="create-main-chapter">
        <div className="create-main-chapter__title">
          <Title>
            {operation === Operation.UPDATE
              ? "Update Subject Details"
              : operation === Operation.CREATE
              ? "Add Subject Details"
              : "View Subject Details"}
          </Title>
          <img src={Close} alt="" onClick={() => setDrawer(false)} />
        </div>

        <div className="create-main-chapter--frame">
          <div className="label-grid">
            <Label>Subject Objectives</Label>
            <TextArea
              rows={4}
              autoFocus
              disabled={operation === Operation.VIEW ? true : false}
              value={subjectObjectives}
              onChange={(e) => {
                setSubjectObjectives(e.target.value);
              }}
            />
          </div>
          <div className="label-grid">
            <Label>Learning Outcome</Label>
            <TextArea
              rows={4}
              autoFocus
              disabled={operation === Operation.VIEW ? true : false}
              value={learningOutcome}
              onChange={(e) => {
                setLearningOutcome(e.target.value);
              }}
            />
          </div>
          <div className="label-grid">
            <Label>Reference Books</Label>
            <TextArea
              rows={4}
              autoFocus
              disabled={operation === Operation.VIEW ? true : false}
              value={referenceBooks}
              onChange={(e) => {
                setReferenceBooks(e.target.value);
              }}
            />
          </div>
          <div className="label-grid">
            <Label>Youtube Links</Label>
            <TextArea
              rows={4}
              autoFocus
              disabled={operation === Operation.VIEW ? true : false}
              value={youtubeLinks}
              onChange={(e) => {
                setYoutubeLinks(e.target.value);
              }}
            />
          </div>
          <div className="label-grid">
            <Label>PowerPoint Links</Label>
            <TextArea
              rows={4}
              autoFocus
              disabled={operation === Operation.VIEW ? true : false}
              value={powerPointLinks}
              onChange={(e) => {
                setPowerPointLinks(e.target.value);
              }}
            />
          </div>
          <div className="label-grid">
            <Label>Case Studies</Label>
            <TextArea
              rows={4}
              autoFocus
              disabled={operation === Operation.VIEW ? true : false}
              value={caseStudies}
              onChange={(e) => {
                setCaseStudies(e.target.value);
              }}
            />
          </div>
          <div className="label-grid">
            <Label>Research Paper Links</Label>
            <TextArea
              rows={4}
              autoFocus
              disabled={operation === Operation.VIEW ? true : false}
              value={researchLinks}
              onChange={(e) => {
                setResearchLinks(e.target.value);
              }}
            />
          </div>
          <div className="label-grid">
            <Label>Other Links</Label>
            <TextArea
              rows={4}
              disabled={operation === Operation.VIEW ? true : false}
              autoFocus
              value={otherLinks}
              onChange={(e) => {
                setotherLinks(e.target.value);
              }}
            />
          </div>
        </div>
        {operation !== Operation.CREATE && (
          <Button
            mode="edit"
            disabled={operation === Operation.UPDATE ? true : false}
            onClick={() => setOperation(Operation.UPDATE)}
          />
        )}

        {operation !== Operation.VIEW && (
          <Button mode="save" onClick={handleSubmit} />
        )}
        {operation === Operation.VIEW && (
          <Button mode="delete" onClick={() => setDeleteModal(!deleteModal)} />
        )}

        <Button mode="cancel" onClick={() => setDrawer(false)} />
      </div>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={objectiveId}
      />
    </>
  );
};

export default AddSubjectDetail;
