import React, { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../../stories/Button/Button";
import Modal from "react-modal";
import isBetween from "dayjs/plugin/isBetween";
import { useMutation } from "@apollo/client";
import { Label } from "../../../../../stories/Label/Label";
import { Title } from "../../../../../stories/Title/Title";
import { FinyearByInstId } from "../../../../../queries/institution/financialyear/list/byid";
import dayjs from "dayjs";
import { AddFinyearbyInstId } from "../../../../../queries/institution/financialyear/mutations";
import { ConfigurationsModalStyles } from "../../../../../styles/ModalStyles";
import LoadingModal from "../../../../../pages/LoadingModal";
import useToken from "../../../../../customhooks/useToken";
import MessageModal from "../../../../../pages/MessageModal";
import { msgType } from "../../../../../utils/Form.types";
import { TextField } from "@mui/material";
import { Keys } from "../../../../../utils/Enum.keys";
import useDisplayConfigIcon from "../../../../../customhooks/useDisplayConfigIcon";
import {
  LicenseTypes,
  Operation,
  PageNumbers,
} from "../../../../../utils/Enum.types";
import Settings from "../../../../../images/Settings.svg";
import ConfigurationSettings from "../../../../Master/configurations/general/Index";
import Close from "../../../../../images/Close.svg";
import { EMPTY_STRING } from "../../../../../utils/constants";
import {
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import Home from "../../../Home/Index";
import Steps from "../Steps";
import { AppContext } from "../../../../../context/context";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import Input from "../../../../../stories/Input/Input";
import FinancialYearList from "./FinancialYearList";
import {
  FormAutocomplete,
  formClasses,
} from "../../../../../styles/AutocompleteStyles";

interface Props {
  operation: Operation;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
dayjs.extend(isBetween);
const FinancialYear = ({ operation, setModal }: Props) => {
  const { InstId, custId } = useParams();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();

  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [configurationModal, setConfigurationModal] = useState(false);
  //userefs
  const saveRef = useRef<HTMLButtonElement>(null);

  const chooseFinYearRef = useRef<HTMLSelectElement>(null);
  const finYrInputRef = RefsByTagName(chooseFinYearRef);

  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");

  //lazy queries

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.FINANCIAL_YEAR_PAGE
  );

  const [newFinancialYear, { loading: creationLoading }] = useMutation(
    AddFinyearbyInstId,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const upcommingFinancialYears = () => {
    var currentYear = new Date().getFullYear();

    return [...new Array(2).keys()].map((i) => {
      const start = dayjs(`04-01-${currentYear}`).add(i, "year").format();
      const endYear = currentYear + 1 + i;
      const end = dayjs(`03-31-${endYear}`).format();

      return {
        label: `${dayjs(start).format("YYYY")}-${dayjs(end).format("YYYY")}`,
        value: {
          startDate: start,
          endDate: end,
        },
      };
    });
  };

  const OldFinancialYears = () => {
    var currentYear = new Date().getFullYear();

    return [...new Array(15).keys()].map((i) => {
      const start = dayjs(`04-01-${currentYear - 1}`)
        .subtract(i, "year")
        .format();
      const endYear = currentYear - i;
      const end = dayjs(`03-31-${endYear}`).format();

      return {
        label: `${dayjs(start).format("YYYY")}-${dayjs(end).format("YYYY")}`,
        value: {
          startDate: start,
          endDate: end,
        },
      };
    });
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });

    finYrInputRef?.focus();
  };

  const years = [...upcommingFinancialYears(), ...OldFinancialYears()];
  const result = years.filter(({ label }) => label === selectedFinancialYear);

  years.sort(
    (a, b) =>
      new Date(a.value.startDate).valueOf() -
      new Date(b.value.startDate).valueOf()
  );

  const HandleNewFinancialYear = (e: React.FormEvent) => {
    e.preventDefault();
    newFinancialYear({
      variables: {
        token,
        inst_id: InstId || state.InstId,
        fin_yr: result[0].label,
        fin_st_date: result[0].value.startDate,
        fin_end_date: toIsoDate(endDate),
        user_details,
      },
      refetchQueries: [
        {
          query: FinyearByInstId,
          variables: { token, inst_id: InstId! ?? state.InstId },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Financial Year Added Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setSelectedFinancialYear("");
  };

  return (
    <>
      {operation === Operation.CREATE && (
        <>
          <Home
            DashBoardRequired={false}
            NavType={LicenseTypes.EDUATE_CUSTOMER}
          />
          <Steps step={2} />
        </>
      )}
      <form
        className={
          operation === Operation.UPDATE
            ? "fin-masters-frame__modal"
            : "fin-masters-frame"
        }
        onSubmit={HandleNewFinancialYear}
      >
        <div className="row g-0 fin-masters-frame__title">
          <div className="col">
            <Title>Financial Year</Title>
          </div>
          {USE_CONFIG_KEY && (
            <div className="configuration-settings">
              <img
                src={Settings}
                alt="/"
                id="settings-icon"
                onClick={() => setConfigurationModal(!configurationModal)}
              />
            </div>
          )}
        </div>

        <div className="fin-masters-frame__datablock row g-0">
          <div className="col booktype-left">
            <Title variant="subtitle1">Add Financial Year</Title>
            <div className="fin-masters-frame__tableblock">
              <div className="label-grid">
                <Label>Choose Financial Year</Label>
                <FormAutocomplete
                  className={formClasses.inputRoot}
                  ref={chooseFinYearRef}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();

                      if (selectedFinancialYear) {
                        saveRef?.current?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSelectedFinancialYear(EMPTY_STRING);
                    }
                  }}
                  options={years}
                  openOnFocus
                  forcePopupIcon
                  value={
                    years?.find(
                      ({ label }) => label === selectedFinancialYear
                    ) ?? null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedFinancialYear(
                        (newValue as { label: string }).label
                      );
                      const selectedStartDate = (
                        newValue as { value: { startDate: string } }
                      ).value.startDate;
                      setStartDate(toInputStandardDate(selectedStartDate));

                      let endDate = dayjs(selectedStartDate).add(364, "day");

                      const year = dayjs(selectedStartDate).year();
                      const leapDay = dayjs(`${year}-02-29`);
                      if (
                        leapDay.isValid() &&
                        leapDay.isBetween(
                          selectedStartDate,
                          endDate,
                          null,
                          "[]"
                        )
                      ) {
                        endDate = endDate.add(1, "day");
                      }

                      setEndDate(endDate.format("YYYY-MM-DD"));
                    } else {
                      setSelectedFinancialYear(EMPTY_STRING);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      className={formClasses.formControlRoot}
                    />
                  )}
                />
              </div>

              <div className="label-grid">
                <Label>Start Date</Label>
                <Input
                  type="date"
                  value={startDate}
                  required
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setEndDate(
                      dayjs(e.target.value).add(364, "day").format("YYYY-MM-DD")
                    );
                  }}
                />
              </div>

              <div className="label-grid">
                <Label>End Date</Label>
                <Input
                  type="date"
                  value={selectedFinancialYear ? endDate : ""}
                  required
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col h-100 booktype-right">
          <FinancialYearList onlyTable={true} />
          </div>
         
        </div>
        <Button mode="save" type="submit" buttonref={saveRef} />
        {operation === Operation.CREATE && (
          <>
            <Button
              mode="move"
              onClick={() =>
                navigate(`/eduate/${custId}/${InstId}/academicyear`)
              }
            >
              &nbsp;next
            </Button>
            <Button
              mode="clear"
              type="button"
              onClick={() => setSelectedFinancialYear("")}
            />

            <Button
              mode="back"
              onClick={() =>
                navigate(`/eduate/${custId}/${InstId}/addInstData`)
              }
            />
          </>
        )}
        {operation === Operation.UPDATE && (
          <Button mode="cancel" onClick={() => setModal(false)} />
        )}
      </form>

      <LoadingModal flag={creationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.FINANCIAL_YEAR_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FinancialYear;
