import { useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import {
  AddAnonymousStudent,
  DeleteAnonymousStudent,
  UpdateAnonymousStudent,
} from "../../../queries/students/mutations/new";
import { Button } from "../../../stories/Button/Button";
import Close from "../../../images/Close.svg";

import { Title } from "../../../stories/Title/Title";

import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import Modal from "react-modal";

import {
  Direction,
  Operation,
  StudentAcctReportType,
} from "../../../utils/Enum.types";
import { msgType, optionsType } from "../../../utils/Form.types";
import Home from "../Home/Index";
import { AcctStudentType } from "./Enum.types";
import { TableHeaderProps } from "../../../Types/Tables";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../hooks/useStudentsbyDemandAmount";
import { EditModalCustomStyles } from "../../../styles/ModalStyles";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import { GetAcctStdDemand } from "../../../queries/students/list/newApi";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useMasterTableJson from "../../../json/useMasterTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  FormAutocomplete,
  formClasses,
} from "../../../styles/AutocompleteStyles";

const CreateAcctStudent = () => {
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();

  const [hasNextPage, setHasNextPage] = useState(true);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { Masters_Table } = useMasterTableJson();
  const { user_details } = useLoggedInUserDetails();

  const { state } = useContext(AppContext);

  const [createStudent, { loading: creationLoading }] = useMutation(
    AddAnonymousStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateStudent, { loading: updationLoading }] = useMutation(
    UpdateAnonymousStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [deleteStudent] = useMutation(DeleteAnonymousStudent, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [name, setName] = useState("");

  const [studentType, setStudentType] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [studentTypeUpdate, setStudentTypeUpdate] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [stdId, setStdId] = useState(0);
  const [nameUpdate, setnameUpdate] = useState("");

  const [editModal, setEditModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const separatorType = [
    {
      label: "Anonymous Reconciled",
      value: AcctStudentType.AnonymousRecolied,
    },
    { label: "Social Welfare", value: AcctStudentType.SocialWelfare },
    { label: "Agent", value: AcctStudentType.Agent },
  ];
  const { students: StudentsData } = UseStudentsbyDemandAmount(
    0,
    0,
    0,
    0,
    0,
    0,
    EMPTY_STRING,
    ROWS_PER_PAGE,
    StudentAcctReportType.ALL_ANONYMOUS_STUDENTS
  );
  const { data, fetchMore, loading } = StudentsData;

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({
                node: {
                  mst_student: { id },
                },
              }) =>
                newEdges.findIndex(
                  ({
                    node: {
                      mst_student: { id: newId },
                    },
                  }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    setName("");
    setStudentType(EMPTY_OPTIONSTYPE_OBJECT);
  };
  const handleStudent = () => {
    if (enableEdit) {
      updateStudent({
        variables: {
          token,
          student_id: stdId,
          inst_id: InstId,
          name: nameUpdate,
          student_type: studentTypeUpdate.value,
          user_details,
        },
        refetchQueries: [
          {
            query: GetAcctStdDemand,
            variables: {
              EMPTY_STRING,
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              input: {
                ids: InstId,
                std_demand_query_type:
                  StudentAcctReportType.ALL_ANONYMOUS_STUDENTS,
              },
              name: EMPTY_STRING,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: `Successfully Updated Student`,
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      createStudent({
        variables: {
          token,
          inst_id: InstId,
          name,
          user_details,
          student_type: studentType.value,
        },
        refetchQueries: [
          {
            query: GetAcctStdDemand,
            variables: {
              EMPTY_STRING,
              after: null,
              direction: Direction.ASC,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              input: {
                ids: InstId,
                std_demand_query_type:
                  StudentAcctReportType.ALL_ANONYMOUS_STUDENTS,
              },
              name: EMPTY_STRING,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: `Successfully Created Student`,
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: number) => {
    deleteStudent({
      variables: {
        token,
        student_id: id,
        inst_id: InstId,
      },
      refetchQueries: [
        {
          query: GetAcctStdDemand,
          variables: {
            EMPTY_STRING,
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr : 0,
            first: ROWS_PER_PAGE,
            input: {
              ids: InstId,
              std_demand_query_type:
                StudentAcctReportType.ALL_ANONYMOUS_STUDENTS,
            },
            name: EMPTY_STRING,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Student  Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) =>
              student.node.mst_student.id === newStudent.node.mst_student.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Create Social Welfare/Agents</Title>
      <div className="anonymous-std">
        <div className="row g-0 anonymous-std__select">
          <div className="col-2">
            <TextField
              label="Name"
              value={name}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
              className="anonymous-std__select--textfield"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-3">
            <FormAutocomplete
              className={formClasses.inputRoot}
              options={separatorType}
              openOnFocus
              value={studentType}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStudentType(newValue as optionsType);
                  setHasNextPage(true);
             
                }
                else{
                    setStudentType(EMPTY_OPTIONSTYPE_OBJECT)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="For Suspense/Deposit Suspense Ledger"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className={formClasses.formControlRoot}
                />
              )}
            />
          </div>
        </div>
        <div className="anonymous-std__tableblock">
          <TableContainer
            className="anonymous-std__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Masters_Table.Students.AnonymousStudent.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, index) => {
                  return (
                    <TableRow key={student.node.mst_student.id}>
                      <TableCell
                        id="td-center"
                        className="anonymous-std__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {student.node.mst_student.first_name}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="anonymous-std__table--ledger"
                      >
                        {student.node.mst_student.std_type ===
                        AcctStudentType.AnonymousRecolied
                          ? "Anonymous Reconciled"
                          : student.node.mst_student.std_type ===
                            AcctStudentType.SocialWelfare
                          ? "Social Welfare"
                          : student.node.mst_student.std_type ===
                            AcctStudentType.Agent
                          ? "Agent"
                          : EMPTY_STRING}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="anonymous-std__table--actions"
                      >
                        <img
                          src={Edit}
                          onClick={() => {
                            setEnableEdit(true);
                            setEditModal(!editModal);
                            setStdId(student.node.mst_student.id);
                            setnameUpdate(student.node.mst_student.first_name);
                            setStudentTypeUpdate({
                              label:
                                student.node.mst_student.std_type ===
                                AcctStudentType.AnonymousRecolied
                                  ? "Anonymous Reconciled"
                                  : student.node.mst_student.std_type ===
                                    AcctStudentType.SocialWelfare
                                  ? "Social Welfare"
                                  : student.node.mst_student.std_type ===
                                    AcctStudentType.Agent
                                  ? "Agent"
                                  : EMPTY_STRING,
                              value: student.node.mst_student.std_type,
                            });
                          }}
                          alt=""
                        />
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => {
                            setStdId(student.node.mst_student.id);
                            setDeleteModal(!deleteModal);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode="save"
          onClick={handleStudent}
          disabled={!name || !studentType.value}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={editModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="col">
              <Title>Update Social Welfare / Agents</Title>

              <TextField
                label="Name"
                value={nameUpdate}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
                className="anonymous-std__select--textfield"
                onChange={(e) => setnameUpdate(e.target.value)}
              />
              <FormAutocomplete
                className={formClasses.inputRoot}
                options={separatorType}
                openOnFocus
                value={studentTypeUpdate}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStudentTypeUpdate(newValue as optionsType);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="For Suspense/Deposit Suspense Ledger"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    className={formClasses.formControlRoot}
                  />
                )}
              />
              <Button mode="save" onClick={handleStudent} />
              <Button mode="cancel" onClick={() => setEditModal(!editModal)} />
            </div>
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setEditModal(!editModal)} />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={stdId}
      />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default CreateAcctStudent;
